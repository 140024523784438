<div class="da-file-upload">
    <ngx-file-drop
        dropZoneLabel="Drop files here"
        (onFileDrop)="dropped($event)"
        (onFileOver)="fileOver($event)"
        (onFileLeave)="fileLeave($event)">

        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div class="da-upload-label-wrapper">
                <lib-header-text
                    size="18px"
                    [convertMobile]="true"
                    label="{{'FILE_UPLOAD.TILE_TEXT' | translate}}">
                </lib-header-text>
                <lib-header-text
                    size="12px"
                    [convertMobile]="true"
                    [thin]="true"
                    label="{{'FILE_UPLOAD.SUB_TEXT' | translate}}">
                </lib-header-text>
            </div>
            <button type="button" class="da-file-upload-button" (click)="openFileSelector()">
                <div class="da-file-upload-button-wrapper">
                    <svg-icon 
                        src="assets/images/fileArrowUp.svg"
                        class="da-file-upload-icon">
                    </svg-icon>
                </div>
                <lib-tile-text
                    size="14px"
                    label="{{'FILE_UPLOAD.BUTTON_TEXT' | translate}}"
                >
                </lib-tile-text>
            </button>
        </ng-template>
    </ngx-file-drop>
    <div class="da-uploaded-files-header" *ngIf="!loading && fileList.length">
            <svg-icon src="assets/images/folderOpen.svg"></svg-icon>
            <lib-tile-text
                size="20px"
                label="{{'FILE_UPLOAD.FILE_HEADER' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(fileInfo)"
            >
            </lib-tile-text>
    </div>
    <div class="da-file-loading" *ngIf="loading"><mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner></div> 
    <div class="da-file-uploaded-files" *ngIf="!loading && fileList.length">
       
        <div class="da-file-uploaded-file" *ngFor="let file of fileList; let i=index" [ngClass]="{'error' : file.state === 'error'}">
            <lib-row
                [upload]="true"
                [label]="file.name"
                [type]="file.name"
                [frontIcon]="file.state === 'error' ? 'assets/images/fileCircleXmark.svg' : 'assets/images/fileCheck.svg'"
                [error]="file.state === 'error'"
                errorText="{{file.errorText | translate}}"
                [icons]="deleteIcon"
                (buttonValue)="getButtonValue($event)">
            </lib-row>
        </div>
    </div>
</div>
<ng-template #fileInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FILE_UPLOAD.FILE_INFO_HEADER' | translate}}" text="{{'FILE_UPLOAD.FILE_INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>