<div class="login-light-view-container">
    <video [muted]="'muted'" autoplay preload loop playsinline 
           id="login-light-video"
           [src]="videoSrc"
           [ngClass]="{'xs-device': deviceIsMobile(), 's-device': deviceIsMobileOrSmallTablet()}">
        <source [src]="videoSrc" type="video/mp4">
        Your browser does not support HTML5 video.
    </video>
    <div class="initial-blue-placeholder" [ngClass]="{'s-device': deviceIsMobileOrSmallTablet()}"></div>
    <div class="login-light-logo-container" [ngClass]="{'xs-device': deviceIsMobile(), 's-device': deviceIsMobileOrSmallTablet()}">
        <img class="login-light-logo" [src]="logo" />
        <span class="login-light-logo-text">{{name}}</span>
    </div>
    
    <div class="login-light-container" [ngClass]="{'s-device': deviceIsMobileOrSmallTablet()}">
        <div class="login-light-sidebar"
             [ngClass]="{'s-device': deviceIsMobileOrSmallTablet(),
                         'extended': mobileSidebarIsExtended,
                         'is-loaded': sidebarIsLoaded}">
            <div class="s-device-toggle-sidebar" *ngIf="deviceIsMobileOrSmallTablet()" (click)="toggleMobileSidebar()">
                <svg-icon src="assets/images/chevronUp.svg" class="toggle-sidebar-icon"></svg-icon>
            </div>
            <div class="login-light-sidebar-header" [ngClass]="{'s-device': deviceIsMobileOrSmallTablet()}">
                <lib-header-text
                    class="da-title"
                    label="{{'LOGIN_LIGHT.TITLE' | translate}}"
                    size="52px"
                    [light]="true"
                    [convertMobile]="true">
                </lib-header-text>
                <div *ngIf="!getUuid()" class="login-light-subtitle">
                    {{'LOGIN_LIGHT.TEXTS_LOGIN.TEXT1' | translate}}
                </div>
            </div>
            <div class="info-container" [ngClass]="{'s-device': deviceIsMobileOrSmallTablet()}">
                <div *ngIf="pinRequestSuccessful && !evaluatePin && !isLoading">
                    {{'LOGIN_LIGHT.TEXTS_LOGIN.TEXT2' | translate}}
                </div>
                <div *ngIf="loginComplete">
                    {{'LOGIN_LIGHT.TEXTS_LOGIN.TEXT3' | translate}}
                </div>
                <div *ngIf="evaluatePin && !evaluatePinSuccessful && !isLoading">
                    {{'LOGIN_LIGHT.TEXTS_LOGIN.TEXT4' | translate}}
                </div>
                <div *ngIf="accessLinkRequestSuccessful">
                    {{'LOGIN_LIGHT.TEXTS_LOGIN.TEXT5' | translate}}
                </div>
                <div *ngIf="accessLinkRequested && !accessLinkRequestSuccessful">
                    {{'LOGIN_LIGHT.TEXTS_LOGIN.TEXT6' | translate}}
                </div>
                <div *ngIf="pinRequested && !pinRequestSuccessful && !isLoading">
                    {{'LOGIN_LIGHT.TEXTS_LOGIN.TEXT7' | translate}}
                </div>
                <div *ngIf="getUuid() && isLoading && !loginComplete">
                    {{'LOGIN_LIGHT.TEXTS_LOGIN.TEXT8' | translate}}
                </div>
            </div>
            <div *ngIf="!getUuid()" class="login-light-link-request" [ngClass]="{'s-device': deviceIsMobileOrSmallTablet()}">               
                <form *ngIf="!accessLinkRequestSuccessful" class="link-request-container" (ngSubmit)="getLoginLightLink()">
                    <lib-input
                        #objectEmail
                        label="{{'LOGIN_LIGHT.FORM.LABEL_EMAIL' | translate}}"
                        [placeholder]="'mustermann@domain.de'"
                        validationType="email"
                        inputmode="email"
                        errorText="{{'LOGIN_LIGHT.ERRORS.EMAIL' | translate}}"
                        [error]="false"
                        value=""
                        [form]="userDataForm"
                        name="mail"
                        [light]="true"
                        [autoWidth]="true"
                        [isCurrency]="false"
                        [skinnyLabel]="true"
                        [doneTypingInterval]="5000"
                        info="true"
                        (inputValue)="validateEmail($event, objectEmail)"
                        (infoValue)="onEmailInfoButtonClick(emailInfo)">
                    </lib-input>
                    <div class="form-submit-container long-btn">
                        <lib-button
                            value="{{'LOGIN_LIGHT.BUTTONS.LINK' | translate}}"
                            type="submit"
                            [light]="true"
                            [disabled]="!emailIsValid"
                            [autoWidth]="true">
                        </lib-button>
                    </div>
                </form>
            </div>
            <form *ngIf="getUuid()" class="login-light-login-form" (ngSubmit)="onSubmit()" [ngClass]="{'s-device': deviceIsMobileOrSmallTablet()}">
                <div class="login-container"
                     *ngIf="!isLoading && !loginComplete && !accessLinkRequested"
                     [ngClass]="{'s-device': deviceIsMobileOrSmallTablet()}">
                    <div class="email-container" *ngIf="!pinRequestSuccessful && !evaluatePin">
                        <lib-input
                            #objectEmailLogin
                            label="{{'LOGIN_LIGHT.FORM.LABEL_EMAIL' | translate}}"
                            [placeholder]="'mustermann@domain.de'"
                            value=""
                            [form]="userDataForm"
                            name="mail"
                            validationType="email"
                            inputmode="email"
                            errorText="{{'LOGIN_LIGHT.ERRORS.EMAIL' | translate}}"
                            [light]="true"
                            [autoWidth]="true"
                            [isCurrency]="false"
                            [skinnyLabel]="true"
                            [doneTypingInterval]="5000"
                            (inputValue)="validateEmail($event, objectEmailLogin)"
                            info="true"
                            (infoValue)="onEmailInfoButtonClick(emailInfo)">
                        </lib-input>
                    </div>
                    <div class="pin-container" *ngIf="pinRequestSuccessful || (evaluatePin && !evaluatePinSuccessful)">
                        <div class="login-pin-content">
                            <label class="login-pin-label"><span>{{'LOGIN_LIGHT.FORM.LABEL_PIN' | translate}}</span></label>
                            <div class="pin-input-container">
                                <code-input [isCodeHidden]="true"
                                            [codeLength]="4"
                                            (codeChanged)="onPinChanged($event)"
                                            (codeCompleted)="onPinCompleted($event)">
                                </code-input>
                                <lib-info-button [light]="true"></lib-info-button>
                            </div>
                        </div>
                    </div>
                    <div class="form-submit-container">
                        <lib-button
                            *ngIf="!pinRequestSuccessful"
                            value="{{'LOGIN_LIGHT.BUTTONS.LOGIN' | translate}}"
                            type="submit"
                            [light]="true"
                            [disabled]="!emailIsValid"
                            [autoWidth]="true">
                        </lib-button>
                        <lib-button
                            *ngIf="pinRequestSuccessful"
                            value="{{'LOGIN_LIGHT.BUTTONS.LOGIN' | translate}}"
                            type="submit"
                            [light]="true"
                            [autoWidth]="true">
                        </lib-button>
                    </div>
                </div>
            </form>
            <div class="login-light-nav" [ngClass]="{'s-device': deviceIsMobileOrSmallTablet()}">
                <a [href]="impressum" target="_blank">{{'LOGIN_LIGHT.LINKS.TEXTS.IMPRESSUM' | translate}}</a>
                <a [href]="agb" target="_blank">{{'LOGIN_LIGHT.LINKS.TEXTS.AGB' | translate}}</a>
                <a [href]="datenschutz" target="_blank">{{'LOGIN_LIGHT.LINKS.TEXTS.DATEN' | translate}}</a>
                <!-- <a [href]="cookies" target="_blank">{{'LOGIN_LIGHT.LINKS.TEXTS.COOKIES' | translate}}</a> -->
            </div>
        </div>
        
    </div>
</div>
<ng-template #emailInfo let-modal>
    <app-info-sidebar 
        [modal]="modal"
        header="{{'LOGIN_LIGHT.LOGIN_AREA.INFO_HEADER' | translate}}"
        text="{{'LOGIN_LIGHT.LOGIN_AREA.INFO_TEXT' | translate}}">
    </app-info-sidebar>
</ng-template>
<div #style></div>