<div class="da-start-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
    <ng-container *ngIf="!screen.isSmallest()">
        <div class="da-start-image">
            <!-- <img src="{{grafik_start}}"/> -->
            <img alt="digitaler assistent startbild" [src]="grafik">
        </div>
        <div class="da-start-text">
            <div class="da-start-header">
                <lib-header-text
                    label="{{'START_B.TITLE' | translate}}"
                    size="38px"
                    class="da-start-header"
                ></lib-header-text>
            </div>
            <lib-tile-text
                label="{{'START_B.SUBTITLE' | translate}}"
                size="20px"
                info="true"
                (infoValue)="getInfo(startInfo)"
                [ngStyle]="{'text-align': 'left'}"
            >
            </lib-tile-text>
            <div class="da-start-buttons">
                <div class="da-start-button-wrapper">
                    <div class="da-start-button">
                        <div class="da-start-button-label">
                            <p>{{'START_B.BUTTON_LABEL' | translate}}</p>
                        </div>
                        <lib-button 
                            name="start button"
                            value="{{'START_B.BUTTON_10' | translate}}" 
                            (buttonValue)="goTo('10')"
                            width="200"
                            iconRight="assets/images/chevronRight.svg"
                        ></lib-button>
                        <div class="da-start-button-subtext">
                            <lib-tile-text
                                label="{{'START_B.BUTTON_10_SUBTEXT' | translate}}"
                                size="12px"
                                [thin]="true">
                            </lib-tile-text>
                        </div>
                    </div>
                    <div class="da-start-button">
                        <lib-button 
                            name="start button"
                            value="{{'START_B.BUTTON_3' | translate}}" 
                            (buttonValue)="goTo('3')"
                            width="200"
                            iconRight="assets/images/chevronRight.svg"
                        ></lib-button>
                        <div class="da-start-button-subtext">
                            <lib-tile-text
                                label="{{'START_B.BUTTON_3_SUBTEXT' | translate}}"
                                size="12px"
                                [thin]="true">
                            </lib-tile-text>
                        </div>
                    </div>
                </div>

                <div class="da-start-fasttrack">
                    <div class="da-start-fasttrack-icon">
                        <svg-icon src="assets/images/personRunningFast.svg"></svg-icon>
                    </div>
                    <div class="da-start-fasttrack-text-wrapper">
                        <p class="da-start-fasttrack-text">
                            {{ 'START_B.FASTTRACK_1' | translate }} <br>
                            {{'START_B.FASTTRACK_2' | translate  }} <a href="/login-light" class="da-start-fasttrack-link">{{ 'START_B.FASTTRACK_BUTTON' | translate }}</a> {{ 'START_B.FASTTRACK_3' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="screen.isSmallest()">
        <div class="da-start-text mobile" >
            <div class="da-start-header mobile">
                <lib-header-text
                    label="{{'START_B.TITLE' | translate}}"
                    size="38px"
                    [convertMobile]="true"
                ></lib-header-text>
            </div>
            <lib-tile-text
                label="{{'START_B.SUBTITLE' | translate}}"
                size="20px"
                info="true"
                [convertMobile]="true"
                (infoValue)="getInfo(startInfo)"
                [ngStyle]="{'text-align': 'left'}"
            >
            </lib-tile-text>
            <div class="da-start-buttons mobile">
                <div class="da-start-button-wrapper mobile">
                    <div class="da-start-button">
                        <div class="da-start-button-label mobile">
                            <p>{{'START_B.BUTTON_LABEL' | translate}}</p>
                        </div>
                        <lib-button 
                            name="start button"
                            value="{{'START_B.BUTTON_10' | translate}}" 
                            (buttonValue)="goTo('10')"
                            width="200"
                            iconRight="assets/images/chevronRight.svg"
                        ></lib-button>
                        <div class="da-start-button-subtext mobile">
                            <lib-tile-text
                                label="{{'START_B.BUTTON_10_SUBTEXT' | translate}}"
                                size="12px"
                                [thin]="true">
                            </lib-tile-text>
                        </div>
                    </div>
                    <div class="da-start-button">
                        <lib-button 
                            name="start button"
                            value="{{'START_B.BUTTON_3' | translate}}" 
                            (buttonValue)="goTo('3')"
                            width="200"
                            iconRight="assets/images/chevronRight.svg"
                        ></lib-button>
                        <div class="da-start-button-subtext mobile">
                            <lib-tile-text
                                label="{{'START_B.BUTTON_3_SUBTEXT' | translate}}"
                                size="12px"
                                [thin]="true">
                            </lib-tile-text>
                        </div>
                    </div>
                </div>
                <div class="da-start-fasttrack">
                    <div class="da-start-fasttrack-icon">
                        <svg-icon src="assets/images/personRunningFast.svg"></svg-icon>
                    </div>
                    <div class="da-start-fasttrack-text-wrapper">
                        <p class="da-start-fasttrack-text">
                            {{ 'START_B.FASTTRACK_1' | translate }} <br>
                            {{'START_B.FASTTRACK_2' | translate  }} <a href="/login-light" class="da-start-fasttrack-link">{{ 'START_B.FASTTRACK_BUTTON' | translate }}</a> {{ 'START_B.FASTTRACK_3' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="da-start-image mobile">
            <!-- <img src="{{grafik_start}}"/> -->
            <img alt="digitaler assistent startbild" [src]="grafik">
        </div>
    </ng-container>

</div>
<ng-template #startInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'START_B.INFO_HEADER' | translate}}" text="{{'START_B.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>

