<div class="da-lightbox" [ngClass]="{'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet()}">
    <div class="da-close-icon" [ngClass]="{'mobile' : screen.isSmallest()}" (click)="dismiss()">
        <svg-icon
            src="assets/images/chevronRight.svg"
        >
        </svg-icon>
    </div>
    <div class="da-modal-body">
        <div class="da-header-icon">
            <svg-icon
                src="assets/images/grid2.svg"
            >
            </svg-icon>
        </div>
        <lib-header-text
            class="da-lightbox-header"
            size="38px"
            [convertMobile]="true"
            label="{{'OVERVIEW.HEADER' | translate}}"
        ></lib-header-text>
        <div class="da-lightbox-content">
            <ng-container *ngFor="let item of chapters">
                <div class="da-overview-accordion-item" *ngIf="item">
                    <!-- THE ACCORDION -->
                    <div class="da-overview-accordion-item-wrapper" (click)="setActive(item)">
                        <div 
                            class="da-overview-accordion-item-title"
                            [ngClass]="{'da-active' : item.active }">
                            <svg-icon class="da-accordion-item-icon" [src]="item.icon" [ngClass]="{ 'isSmallest' : screen.isSmallest() }"></svg-icon>
                            <div class="da-accordion-item-text">
                                <div class="da-accordion-item-header" [ngClass]="{ 'isSmallest' : screen.isSmallest() }">
                                    {{ item.title | translate }} 
                                </div>
                                <div class="da-accordion-item-subheader" *ngIf="item.subtitle" [ngClass]="{ 'isSmallest' : screen.isSmallest() }">
                                    {{ item.subtitle | translate }} 
                                </div>

                            </div>
                        </div>
                        <svg-icon class="da-accordion-icon" *ngIf="item.active" src="assets/images/chevronUp.svg"></svg-icon>
                        <svg-icon class="da-accordion-icon" *ngIf="!item.active" src="assets/images/chevronDown.svg"></svg-icon>
                    </div>
                    
                    <!-- THE ITEMS -->
                    <div class="da-overview-accordion-item-body" [ngClass]="{'middle': item.id !== 0 }" [@slideUpDown]="item.active">
                        <ng-container *ngIf="item.id === 0" >
                            <app-tasks (buttonValue)="getButtons($event)" [class]="item.className"></app-tasks>
                        </ng-container>
                        <ng-container *ngIf="item.id === 1">
                            <app-upload (nextItem)="setActive(chapters[1], true)" [class]="item.className"></app-upload>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
