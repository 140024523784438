<div class="da-master">
    <button 
        (click)="open(digitalAssistantB, 'digitalAssistantB')" 
        id="start-digital-assistant"> test b
    </button>
    <ng-template #digitalAssistantB let-modal>
        <app-frame webappType="b"></app-frame>
    </ng-template>
    <div #style></div>
</div>
