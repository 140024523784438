<lib-header-text
    class="da-title"
    label="{{'FINANCE_REQUIREMENTS.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<lib-tile-text
    class="da-title"
    label="{{'FINANCE_REQUIREMENTS.SUB_TITLE' | translate}}"
    size="18px"
    [convertMobile]="true"
    [ngStyle]="{'display': 'flex', 'justify-content': 'center'}"
></lib-tile-text>
<div class="da-tiles-wrapper" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet() }">
    <!-- CONTAINER LINKS -->
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'isSmallest' : screen.isSmallest() }">
        <lib-state-icon [state]="container1State" [ngStyle]="{'left': '0', 'top': '0', 'position': 'absolute'}"></lib-state-icon>
        <!-- KAUFPREIS -->
        <div class="da-container-row" *ngIf="contentService.getValue('projectPlan') !== 'NEUBAU'">
            <lib-tile-text
                class="da-tile-header"
                size="18px"
                [convertMobile]="true"
                label="{{'FINANCE_REQUIREMENTS.LABELS.LABEL1' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(kaufpreisInfo)"
            ></lib-tile-text>
            <lib-input
                #kaufpreis
                #inputs
                class="da-tile-field"
                label=""
                name="kaufpreis"
                [error]="false"
                [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                (click)="testValid = true"
                errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                placeholder="0,00"
                unit="€"
                [touched]="!!contentService.getValue('kaufpreis')"
                [value]="kaufpreisBetrag ? kaufpreisBetrag : ''"
                (change)="validateInput($event, kaufpreis, 'container1')"
            ></lib-input>
        </div>
        <!-- GRUNDSTUECK -->
        <div class="da-container-row" *ngIf="contentService.getValue('projectPlan') === 'NEUBAU' && (!contentService.getValue('useOwnLand') || contentService.getValue('useOwnLand') === 'nein')">
            <lib-tile-text
                class="da-tile-header"
                size="18px"
                [convertMobile]="true"
                label="{{'FINANCE_REQUIREMENTS.LABELS.LABEL2' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(grundstueckInfo)"
            ></lib-tile-text>
            <lib-input
                #grundstueck
                #inputs
                class="da-tile-field"
                label=""
                name="grundstueck"
                [error]="false"
                [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                (click)="testValid = true"
                errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                placeholder="0,00"
                unit="€"
                [touched]="!!contentService.getValue('grundstueckspreis')"
                [value]="!!contentService.getValue('grundstueckspreis') ? contentService.getValue('grundstueckspreis') : ''"
                (change)="validateInput($event, grundstueck, 'container1')"
            ></lib-input>
        </div>
        <!-- HERSTELLUNG -->
        <div class="da-container-row" *ngIf="contentService.getValue('projectPlan') === 'NEUBAU'">
            <lib-tile-text
                class="da-tile-header"
                size="18px"
                [convertMobile]="true"
                label="{{'FINANCE_REQUIREMENTS.LABELS.LABEL4' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(herstellungInfo)"
            ></lib-tile-text>
            <div class="da-container-input">
                <svg-icon class="da-container-icon" src="assets/images/plus.svg"></svg-icon>
                <lib-input
                    #herstellung
                    #inputs
                    class="da-tile-field"
                    label=""
                    name="herstellung"
                    [error]="false"
                    [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                    (click)="testValid = true"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [touched]="!!contentService.getValue('herstellungskosten')"
                    [value]="!!contentService.getValue('herstellungskosten') ? contentService.getValue('herstellungskosten') : ''"
                    (change)="validateInput($event, herstellung, 'container1')"
                ></lib-input>
            </div>
        </div>
        <!-- AUSSENANLAGEN -->
        <div class="da-container-row" *ngIf="contentService.getValue('projectPlan') === 'NEUBAU'">
            <lib-tile-text
                class="da-tile-header"
                size="18px"
                [convertMobile]="true"
                label="{{'FINANCE_REQUIREMENTS.LABELS.LABEL5' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(aussenanlagenInfo)"
            ></lib-tile-text>
            <div class="da-container-input">
                <svg-icon class="da-container-icon" src="assets/images/plus.svg"></svg-icon>
                <lib-input
                    #aussenanlagen
                    #inputs
                    class="da-tile-field"
                    label=""
                    name="aussenanlagen"
                    [error]="false"
                    [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                    (click)="testValid = true"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [touched]="!!contentService.getValue('aussenanlagenkosten')"
                    [value]="!!contentService.getValue('aussenanlagenkosten') ? contentService.getValue('aussenanlagenkosten') : ''"
                    (change)="validateInput($event, aussenanlagen, 'container1')"
                ></lib-input>
            </div>
        </div>
        <!-- BAUNEBENKOSTEN -->
        <div class="da-container-row" *ngIf="contentService.getValue('projectPlan') === 'NEUBAU'">
            <lib-tile-text
                class="da-tile-header"
                size="18px"
                [convertMobile]="true"
                label="{{'FINANCE_REQUIREMENTS.LABELS.LABEL6' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(baunebenkostenInfo)"
            ></lib-tile-text>
            <div class="da-container-input">
                <svg-icon class="da-container-icon" src="assets/images/plus.svg"></svg-icon>
                <lib-input
                    #baunebenkosten
                    #inputs
                    class="da-tile-field"
                    label=""
                    name="baunebenkosten"
                    [error]="false"
                    [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                    (click)="testValid = true"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [touched]="!!contentService.getValue('baunebenkosten')"
                    [value]="!!contentService.getValue('baunebenkosten') ? contentService.getValue('baunebenkosten') : ''"
                    (change)="validateInput($event, baunebenkosten, 'container1')"
                ></lib-input>
            </div>
        </div>
        <!-- ERSCHLIESSUNG -->
        <div class="da-container-row" *ngIf="contentService.getValue('projectPlan') === 'NEUBAU'">
            <lib-tile-text
                class="da-tile-header"
                size="18px"
                [convertMobile]="true"
                label="{{'FINANCE_REQUIREMENTS.LABELS.LABEL7' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(erschliessungInfo)"
            ></lib-tile-text>
            <div class="da-container-input">
                <svg-icon class="da-container-icon" src="assets/images/plus.svg"></svg-icon>
                <lib-input
                    #erschliessung
                    #inputs
                    class="da-tile-field"
                    label=""
                    name="erschliessung"
                    [error]="false"
                    [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                    (click)="testValid = true"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [touched]="!!contentService.getValue('erschliessungkosten')"
                    [value]="!!contentService.getValue('erschliessungkosten') ? contentService.getValue('erschliessungkosten') : ''"
                    (change)="validateInput($event, erschliessung, 'container1')"
                ></lib-input>
            </div>
        </div>
        <!-- NEBENKOSTEN -->
        <div class="da-container-row">
            <lib-tile-text
                class="da-tile-header"
                size="18px"
                [convertMobile]="true"
                label="{{'FINANCE_REQUIREMENTS.LABELS.LABEL3' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(nebenkostenInfo)"
            ></lib-tile-text>
            <div class="da-container-input">
                <svg-icon class="da-container-icon" src="assets/images/plus.svg"></svg-icon>
                <lib-input-button
                    #nebenkosten
                    #inputs
                    label=""
                    unit="€"
                    [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                    (click)="testValid = true"
                    [error]="contentService.getValue('nebenkostenInValid')"
                    name="nebenkosten"
                    type="nebenkosten"
                    [value]="!!contentService.getValue('nebenkosten') ? contentService.getValue('nebenkosten') : '0,00'"
                    [touched]="!!contentService.getValue('nebenkostenAktiv')"
                    (inputValue)="screen.openInfo(nebenkostenInput)"
                ></lib-input-button>
            </div>
        </div>
        <!-- MODERNISIERUNG -->
        <div class="da-container-row" *ngIf="contentService.getValue('projectPlan') !== 'NEUBAU' && contentService.getValue('projectPlan') !== 'KAUF_NEUBAU_VOM_BAUTRAEGER' && contentService.getValue('projectPlan') !== 'KAPITALBESCHAFFUNG'">
            <lib-tile-text
                class="da-tile-header"
                size="18px"
                [convertMobile]="true"
                label="{{'FINANCE_REQUIREMENTS.LABELS.LABEL8' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(modernisierungInfo)"
            ></lib-tile-text>
            <div class="da-container-input">
                <svg-icon class="da-container-icon" src="assets/images/plus.svg"></svg-icon>
                <lib-input
                    #modernisierung
                    #inputs
                    class="da-tile-field"
                    label=""
                    name="modernisierung"
                    [error]="false"
                    [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                    (click)="testValid = true"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [touched]="!!contentService.getValue('modernisierungkosten')"
                    [value]="!!contentService.getValue('modernisierungkosten') ? contentService.getValue('modernisierungkosten') : ''"
                    (change)="validateInput($event, modernisierung, 'container1')"
                ></lib-input>
            </div>
        </div>
        <!-- MOBILIAR -->
        <div class="da-container-row">
            <lib-tile-text
                class="da-tile-header"
                size="18px"
                [convertMobile]="true"
                label="{{'FINANCE_REQUIREMENTS.LABELS.LABEL9' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(mobiliarInfo)"
            ></lib-tile-text>
            <div class="da-container-input">
                <svg-icon class="da-container-icon" src="assets/images/plus.svg"></svg-icon>
                <lib-input
                    #mobiliar
                    #inputs
                    class="da-tile-field"
                    label=""
                    name="mobiliar"
                    [error]="false"
                    [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                    (click)="testValid = true"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [touched]="!!contentService.getValue('mobiliarkosten')"
                    [value]="!!contentService.getValue('mobiliarkosten') ? contentService.getValue('mobiliarkosten') : ''"
                    (change)="validateInput($event, mobiliar, 'container1')"
                ></lib-input>
            </div>
        </div>
        <!-- SONSTIGES -->
        <div class="da-container-row" *ngIf="contentService.getValue('projectPlan') === 'NEUBAU'">
            <lib-tile-text
                class="da-tile-header"
                size="18px"
                [convertMobile]="true"
                label="{{'FINANCE_REQUIREMENTS.LABELS.LABEL10' | translate}}"
            ></lib-tile-text>
            <div class="da-container-input">
                <svg-icon class="da-container-icon" src="assets/images/plus.svg"></svg-icon>
                <lib-input
                    #sonstiges
                    #inputs
                    class="da-tile-field"
                    label=""
                    name="sonstiges"
                    [error]="false"
                    [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                    (click)="testValid = true"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [touched]="!!contentService.getValue('sonstigeskosten')"
                    [value]="!!contentService.getValue('sonstigeskosten') ? contentService.getValue('sonstigeskosten') : ''"
                    (change)="validateInput($event, sonstiges, 'container1')"
                ></lib-input>
            </div>
            
        </div>
    </div>
    <!-- CONTAINER RECHTS -->
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'isSmallest' : screen.isSmallest() }">
        <lib-state-icon [state]="container2State" [ngStyle]="{'left': '0', 'top': '0', 'position': 'absolute'}"></lib-state-icon>
        <!-- BEGLICHENE KOSTEN -->
        <div class="da-container-row" *ngIf="contentService.getValue('projectPlan') === 'NEUBAU'">
            <lib-tile-text
                class="da-tile-header"
                size="18px"
                [convertMobile]="true"
                label="{{'FINANCE_REQUIREMENTS.LABELS.LABEL11' | translate}}"
            ></lib-tile-text>
            <div class="da-container-input">
                <svg-icon class="da-container-icon" src="assets/images/minus.svg"></svg-icon>
                <lib-input
                    #beglichenes
                    #inputs
                    class="da-tile-field"
                    label=""
                    name="beglichenes"
                    [error]="false"
                    [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                    (click)="testValid = true"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [touched]="!!contentService.getValue('beglicheneskosten')"
                    [value]="!!contentService.getValue('beglicheneskosten') ? contentService.getValue('beglicheneskosten') : ''"
                    (change)="validateInput($event, beglichenes, 'container2')"
                ></lib-input>
            </div>
        </div>
        <!-- EIGENKAPITAL -->
        <div class="da-container-row">
            <lib-tile-text
                class="da-tile-header"
                size="18px"
                [convertMobile]="true"
                label="{{'FINANCE_REQUIREMENTS.LABELS.LABEL12' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(eigenkapitalInfo)"
            ></lib-tile-text>
            <div class="da-container-input">
                <svg-icon class="da-container-icon" src="assets/images/minus.svg"></svg-icon>
                <lib-input-button
                    #eigenkapital
                    #inputs
                    label=""
                    [error]="contentService.getValue('eigenkapitalInValid')"
                    unit="€"
                    [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                    (click)="testValid = true"
                    name="eigenkapital"
                    type="eigenkapital"
                    [value]="!!contentService.getValue('eigenkapitalAktiv') ? contentService.getValue('eigenkapitalAktiv') : '0,00'"
                    [touched]="!!contentService.getValue('eigenkapitalAktiv')"
                    (inputValue)="screen.openInfo(eigenkapitalInput)"
                ></lib-input-button>
            </div>
        </div>
        <!-- EIGENLEISTUNGEN -->
        <div class="da-container-row" *ngIf="(!!contentService.getValue('modernisierungkosten') && contentService.getValue('modernisierungkosten') !== '0,00') || contentService.getValue('projectPlan') === 'NEUBAU'">
            <lib-tile-text
                class="da-tile-header"
                size="18px"
                [convertMobile]="true"
                label="{{'FINANCE_REQUIREMENTS.LABELS.LABEL13' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(eigenleistungInfo)"
            ></lib-tile-text>
            <div class="da-container-input">
                <!-- <svg-icon class="da-container-icon" src="assets/images/minus.svg"></svg-icon> -->
                <lib-input-button
                    #eigenleistung
                    #inputs
                    label=""
                    unit="€"
                    [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                    (click)="testValid = true"
                    name="eigenleistung"
                    type="eigenleistung"
                    [error]="contentService.getValue('eigenleistungInValid')"
                    [value]="!!contentService.getValue('eigenleistungValue') ? contentService.getValue('eigenleistungValue') : '0,00'"
                    [touched]="!!contentService.getValue('eigenleistungValue')"
                    (inputValue)="screen.openInfo(eigenleistungInput)"
                ></lib-input-button>
            </div>
        </div>
    </div>
    <div class="da-container darlehen" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'isSmallest' : screen.isSmallest() }">
        <div class="da-container-row"></div>
        <div class="da-container-row">
            <lib-tile-text
                class="da-tile-header"
                size="18px"
                [convertMobile]="true"
                label="{{'FINANCE_REQUIREMENTS.LABELS.LABEL15' | translate}}"
            ></lib-tile-text>
            <div class="da-container-input">
                <svg-icon class="da-container-icon" src="assets/images/equals.svg"></svg-icon>
                <lib-input
                    #darlehensbedarf
                    #inputs
                    class="da-tile-field"
                    label=""
                    [inputDisabled]="true"
                    name="darlehensbedarf"
                    [error]="darlehenInValid"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [touched]="!!contentService.getValue('darlehensbedarf')"
                    [value]="contentService.getValue('darlehensbedarf')"
                ></lib-input>
            </div>
        </div>
    </div>
</div>
<div class="da-buttons-wrapper" *ngIf="!hasAccess">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}" [ngClass]="{'withDashboard' : !testValid}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [zertifikat]="webappType === 'a' ? true : false"
            [dashboard]="webappType !== 'a' ? true : false"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            (validate)="runValidation($event)"
            (slideTo)="setNav($event)"
            >
        </app-nav-buttons>
    </div>
</div>

<div class="da-margin" [ngStyle]="{'margin-bottom': '40px'}"></div>

<ng-template #kaufpreisInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS.INFOS.HEADER1' | translate}}" text="{{'FINANCE_REQUIREMENTS.INFOS.TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #grundstueckInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS.INFOS.HEADER2' | translate}}" text="{{'FINANCE_REQUIREMENTS.INFOS.TEXT2' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #herstellungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS.INFOS.HEADER3' | translate}}" text="{{'FINANCE_REQUIREMENTS.INFOS.TEXT3' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #aussenanlagenInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS.INFOS.HEADER4' | translate}}" text="{{'FINANCE_REQUIREMENTS.INFOS.TEXT4' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #erschliessungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS.INFOS.HEADER5' | translate}}" text="{{'FINANCE_REQUIREMENTS.INFOS.TEXT5' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #modernisierungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS.INFOS.HEADER6' | translate}}" text="{{'FINANCE_REQUIREMENTS.INFOS.TEXT6' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #mobiliarInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS.INFOS.HEADER7' | translate}}" text="{{'FINANCE_REQUIREMENTS.INFOS.TEXT7' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #nebenkostenInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS.INFOS.HEADER8' | translate}}" text="{{'FINANCE_REQUIREMENTS.INFOS.TEXT8' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #eigenkapitalInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS.INFOS.HEADER9' | translate}}" text="{{'FINANCE_REQUIREMENTS.INFOS.TEXT9' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #eigenleistungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS.INFOS.HEADER10' | translate}}" text="{{'FINANCE_REQUIREMENTS.INFOS.TEXT10' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #baunebenkostenInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS.INFOS.HEADER11' | translate}}" text="{{'FINANCE_REQUIREMENTS.INFOS.TEXT11' | translate}}"></app-info-sidebar>
</ng-template>

<ng-template #nebenkostenInput let-modal>
    <app-nebenkosten-sidebar 
    [modal]="modal" 
    (nebenkostenDone)="calculateSum()"
    [noTax]="noTax"
    [base]="base"
    ></app-nebenkosten-sidebar>
</ng-template>

<ng-template #eigenkapitalInput let-modal>
    <app-eigenkapital-sidebar 
    [modal]="modal" 
    (eigenkapitalDone)="calculateSum()"
    ></app-eigenkapital-sidebar>
</ng-template>

<ng-template #eigenleistungInput let-modal>
    <app-eigenleistung-sidebar 
    [modal]="modal" 
    ></app-eigenleistung-sidebar>
</ng-template>

<ng-template #uebergabeA let-modal>
    <app-uebergabe-sidebar                  
    [modal]="modal" 
    [webappType]="webappType"
    (uebergabeDone)="setStateChange()"
    ></app-uebergabe-sidebar>
</ng-template>
