<lib-header-text
    class="da-title"
    label="{{'FINANCE_PREFERENCES.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<lib-tile-text
    class="da-title"
    label="{{'FINANCE_PREFERENCES.SUBTITLE' | translate}}"
    size="20px"
    [convertMobile]="true"
    info="true"
    (infoValue)="screen.openInfo(preferencesInfo)"
    [ngStyle]="{'display': 'flex', 'justify-content': 'center'}"
></lib-tile-text>
<div class="da-index">
    <app-index 
        [tiles]="fakeTiles" 
        [selectedTile]="selectedTile"
        [slideFactor]="tileSlideFactor"
        [slidePos]="tilePosition"
        (indexValue)="getIndex($event)">
    </app-index>
</div>
<div class="da-content-extended">
    <div class="da-content-extended-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest()}">
        <div 
            class="da-tiles-wrapper with-slider" 
            [ngClass]="{'mobile-wrap' : screen.isSmallest()}"
            [ngStyle]="{'right': 'calc('+ screenPosition +' + '+ tilePosition +'px)'}">
        
             <!-- TILE1 - ZINSBINDUNG -->
             <div
                class="da-container" 
                (click)="getIndex('Zinsbindung')"
                [ngClass]="{ 'mobile' : screen.isSmallest() }"
                >
                <lib-state-icon [state]="fakeTiles[0].state"></lib-state-icon>
                <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <svg-icon class="da-tile-icon" [src]="fakeTiles[0].image"></svg-icon>
                    <lib-tile-text
                        class="da-tile-header"
                        size="19px"
                        [convertMobile]="true"
                        label="{{'FINANCE_PREFERENCES.TILES.TILE1.TITLE1' | translate}}"
                        info="true"
                        (infoValue)="screen.openInfo(zinsbindungInfo)"
                    ></lib-tile-text>
                    <lib-toggle
                        #zinsbindung
                        label1="{{'COMMON.TRUE' | translate}}"
                        label2="{{'COMMON.FALSE' | translate}}"
                        name="zinsbindung"
                        value1="zinsbindungJa"
                        value2="zinsbindungNein"
                        [localValue]="contentService.getValue('zinsbindungPref')"
                        (switchValue)="setSwitch($event, zinsbindung, 'Zinsbindung')"
                    ></lib-toggle>
                    <ng-container *ngIf="contentService.getValue('zinsbindungPref') === 'zinsbindungJa'">
                        <lib-tile-text
                            class="da-tile-header"
                            size="19px"
                            [convertMobile]="true"
                            label="{{'FINANCE_PREFERENCES.TILES.TILE1.TITLE2' | translate}}"
                        ></lib-tile-text>
                        <lib-toggle-vertical
                            #zinsbindungValue
                            [options]="switchesZinsen"
                            [localValue]="contentService.getValue('zinsbindungValuePref')"
                            name="zinsbindungValue"
                            (switchValue)="setSwitch($event, zinsbindungValue, 'Zinsbindung')"
                        ></lib-toggle-vertical>
                    </ng-container>
                </div>
            </div>
            <!-- TILE2 - RATE -->
            <div
                class="da-container" 
                (click)="getIndex('Rate')"
                [ngClass]="{ 'mobile' : screen.isSmallest() }"
                >
                <lib-state-icon [state]="fakeTiles[1].state"></lib-state-icon>
                <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <svg-icon class="da-tile-icon" [src]="fakeTiles[1].image"></svg-icon>
                    <lib-tile-text
                        class="da-tile-header"
                        size="19px"
                        [convertMobile]="true"
                        label="{{'FINANCE_PREFERENCES.TILES.TILE2.TITLE1' | translate}}"
                        info="true"
                        (infoValue)="screen.openInfo(rateInfo)"
                    ></lib-tile-text>
                    <lib-toggle
                        #rate
                        label1="{{'COMMON.TRUE' | translate}}"
                        label2="{{'COMMON.FALSE' | translate}}"
                        name="rate"
                        value1="rateJa"
                        value2="rateNein"
                        [localValue]="contentService.getValue('ratePref')"
                        (switchValue)="setSwitch($event, rate, 'Rate')"
                    ></lib-toggle>
                    <ng-container *ngIf="contentService.getValue('ratePref') === 'rateJa'">
                        <lib-tile-text
                            class="da-tile-header"
                            size="19px"
                            [convertMobile]="true"
                            label="{{'FINANCE_PREFERENCES.TILES.TILE2.TITLE2' | translate}}"
                        ></lib-tile-text>
                        <lib-toggle-vertical
                            #rateValue
                            [options]="switchesRate"
                            [localValue]="contentService.getValue('rateValuePref')"
                            name="rateValue"
                            (switchValue)="setSwitch($event, rateValue, 'Rate')"
                        ></lib-toggle-vertical>
                    </ng-container>
                    <div class="da-pref-inputs" *ngIf="contentService.getValue('rateValuePref') == 4">
                        <lib-input
                            #rateNumber
                            class="da-tile-field"
                            label="{{'FINANCE_PREFERENCES.TILES.TILE2.LABEL1' | translate}}"
                            name="rateNumber"
                            [error]="false"
                            errorText="{{errorMax | translate}}"
                            placeholder="0,00"
                            unit="€"
                            [value]="contentService.getValue('rateNumberPref')"
                            [touched]="!!contentService.getValue('rateNumberPref')"                               
                            (inputValue)="validateInput($event, rateNumber, 'Rate')"
                        ></lib-input>
                    </div>
                </div>
            </div>
            <!-- TILE3 - AUFBAU -->
            <div
                class="da-container" 
                (click)="getIndex('Aufbau')"
                [ngClass]="{ 'mobile' : screen.isSmallest() }"
                >
                <lib-state-icon [state]="fakeTiles[2].state"></lib-state-icon>
                <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <svg-icon class="da-tile-icon" [src]="fakeTiles[2].image"></svg-icon>
                    <lib-tile-text
                        class="da-tile-header"
                        size="19px"
                        [convertMobile]="true"
                        label="{{'FINANCE_PREFERENCES.TILES.TILE3.TITLE1' | translate}}"
                        info="true"
                        (infoValue)="screen.openInfo(aufbauInfo)"
                    ></lib-tile-text>
                    <lib-toggle
                        #aufbau
                        label1="{{'COMMON.TRUE' | translate}}"
                        label2="{{'COMMON.FALSE' | translate}}"
                        name="aufbau"
                        value1="aufbauJa"
                        value2="aufbauNein"
                        [localValue]="contentService.getValue('aufbauPref')"
                        (switchValue)="setSwitch($event, aufbau, 'Aufbau')"
                    ></lib-toggle>
                    <ng-container *ngIf="contentService.getValue('aufbauPref') === 'aufbauJa'">
                        <lib-tile-text
                            class="da-tile-header"
                            size="19px"
                            [convertMobile]="true"
                            label="{{'FINANCE_PREFERENCES.TILES.TILE3.TITLE2' | translate}}"
                        ></lib-tile-text>
                        <lib-toggle-vertical
                            #aufbauValue
                            [options]="switchesAufbau"
                            [multiLine]="true"
                            [localValue]="contentService.getValue('aufbauValuePref')"
                            name="aufbauValue"
                            (switchValue)="setSwitch($event, aufbauValue, 'Aufbau')"
                        ></lib-toggle-vertical>
                    </ng-container>
                </div>
            </div>
            <!-- TILE4 - SONDERTILGUNG -->
            <div
                class="da-container" 
                (click)="getIndex('Sondertilgung')"
                [ngClass]="{ 'mobile' : screen.isSmallest() }"
                >
                <lib-state-icon [state]="fakeTiles[3].state"></lib-state-icon>
                <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <svg-icon class="da-tile-icon" [src]="fakeTiles[3].image"></svg-icon>
                    <lib-tile-text
                        class="da-tile-header"
                        size="19px"
                        [convertMobile]="true"
                        label="{{'FINANCE_PREFERENCES.TILES.TILE4.TITLE1' | translate}}"
                        info="true"
                        (infoValue)="screen.openInfo(sondertilgungInfo)"
                    ></lib-tile-text>
                    <lib-toggle
                        #sondertilgung
                        label1="{{'COMMON.TRUE' | translate}}"
                        label2="{{'COMMON.FALSE' | translate}}"
                        name="sondertilgung"
                        value1="sondertilgungJa"
                        value2="sondertilgungNein"
                        [localValue]="contentService.getValue('sondertilgungPref')"
                        (switchValue)="setSwitch($event, sondertilgung, 'Sondertilgung')"
                    ></lib-toggle>
                    <ng-container *ngIf="contentService.getValue('sondertilgungPref') === 'sondertilgungJa'">
                        <lib-tile-text
                            class="da-tile-header"
                            size="19px"
                            [convertMobile]="true"
                            label="{{'FINANCE_PREFERENCES.TILES.TILE4.TITLE2' | translate}}"
                        ></lib-tile-text>
                        <lib-toggle-vertical
                            #sondertilgungValue
                            [options]="switchesSondertilgung"
                            [multiLine]="true"
                            [localValue]="contentService.getValue('sondertilgungValuePref')"
                            name="sondertilgungValue"
                            (switchValue)="setSwitch($event, sondertilgungValue, 'Sondertilgung')"
                        ></lib-toggle-vertical>
                    </ng-container>
                </div>
            </div>
            <!-- TILE5 - LAUFZEIT -->
            <div
                class="da-container" 
                (click)="getIndex('Laufzeit')"
                [ngClass]="{ 'mobile' : screen.isSmallest() }"
                >
                <lib-state-icon [state]="fakeTiles[4].state"></lib-state-icon>
                <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <svg-icon class="da-tile-icon" [src]="fakeTiles[4].image"></svg-icon>
                    <lib-tile-text
                        class="da-tile-header"
                        size="19px"
                        [convertMobile]="true"
                        label="{{'FINANCE_PREFERENCES.TILES.TILE5.TITLE1' | translate}}"
                        info="true"
                        (infoValue)="screen.openInfo(laufzeitInfo)"
                    ></lib-tile-text>
                    <lib-toggle
                        #laufzeit
                        label1="{{'COMMON.TRUE' | translate}}"
                        label2="{{'COMMON.FALSE' | translate}}"
                        name="laufzeit"
                        value1="laufzeitJa"
                        value2="laufzeitNein"
                        [localValue]="contentService.getValue('laufzeitPref')"
                        (switchValue)="setSwitch($event, laufzeit, 'Laufzeit')"
                    ></lib-toggle>
                    <ng-container *ngIf="contentService.getValue('laufzeitPref') === 'laufzeitJa'">
                        <lib-tile-text
                            class="da-tile-header"
                            size="19px"
                            [convertMobile]="true"
                            label="{{'FINANCE_PREFERENCES.TILES.TILE5.TITLE2' | translate}}"
                        ></lib-tile-text>
                        <lib-toggle-vertical
                            #laufzeitValue
                            [options]="switchesLaufzeit"
                            [multiLine]="true"
                            [localValue]="contentService.getValue('laufzeitValuePref')"
                            name="laufzeitValue"
                            (switchValue)="setSwitch($event, laufzeitValue, 'Laufzeit')"
                        ></lib-toggle-vertical>
                        <div class="da-pref-input" *ngIf="contentService.getValue('laufzeitValuePref') == 4">
                            <lib-input
                                #laufzeitVariable
                                class="da-tile-field"
                                label="{{'FINANCE_PREFERENCES.TILES.TILE5.LABEL' | translate}}"
                                name="laufzeitVariable"
                                [error]="false"
                                validationType="numbers"
                                errorText="{{'COMMON.ERRORS.YEAR_LAUFZEIT' | translate}}"
                                placeholder="2030"
                                unit=""
                                [isCurrency]="false"
                                [value]="contentService.getValue('laufzeitVariablePref')"
                                [touched]="!!contentService.getValue('laufzeitVariablePref')"                               
                                (keyup)="validateInput($event, laufzeitVariable, 'Laufzeit')"
                            ></lib-input>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- TILE6 - WECHSEL -->
            <!-- <div
                class="da-container" 
                (click)="getIndex('Wechsel')"
                [ngClass]="{ 'mobile' : screen.isSmallest() }"
                >
                <lib-state-icon [state]="fakeTiles[5].state"></lib-state-icon>
                <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <svg-icon class="da-tile-icon" [src]="fakeTiles[5].image"></svg-icon>
                    <lib-tile-text
                        class="da-tile-header"
                        size="19px"
                        [convertMobile]="true"
                        label="{{'FINANCE_PREFERENCES.TILES.TILE6.TITLE' | translate}}"
                        info="true"
                        (infoValue)="screen.openInfo(wechselInfo)"
                    ></lib-tile-text>
                    <lib-toggle
                        #wechsel
                        label1="{{'COMMON.TRUE' | translate}}"
                        label2="{{'COMMON.FALSE' | translate}}"
                        name="wechsel"
                        value1="wechselJa"
                        value2="wechselNein"
                        [localValue]="contentService.getValue('wechselPref')"
                        (switchValue)="setSwitch($event, wechsel, 'Wechsel')"
                    ></lib-toggle>
                </div>
            </div> -->
            <!-- TILE7 - BEWILLIGUNG -->
            <div
                class="da-container" 
                (click)="getIndex('Bewilligung')"
                [ngClass]="{ 'mobile' : screen.isSmallest() }"
                >
                <lib-state-icon [state]="fakeTiles[5].state"></lib-state-icon>
                <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <svg-icon class="da-tile-icon" [src]="fakeTiles[5].image"></svg-icon>
                    <lib-tile-text
                        class="da-tile-header"
                        size="19px"
                        [convertMobile]="true"
                        label="{{'FINANCE_PREFERENCES.TILES.TILE7.TITLE' | translate}}"
                        info="true"
                        (infoValue)="screen.openInfo(bewilligungInfo)"
                    ></lib-tile-text>
                    <lib-toggle-vertical
                        #bewilligung
                        [options]="switchesBewilligung"
                        [localValue]="contentService.getValue('bewilligungPref')"
                        name="bewilligung"
                        (switchValue)="setSwitch($event, bewilligung, 'Bewilligung')"
                    ></lib-toggle-vertical>
                </div>
            </div>
            <!-- TILE8 - AUSZAHLUNG -->
            <div
                class="da-container" 
                (click)="getIndex('Auszahlung')"
                [ngClass]="{ 'mobile' : screen.isSmallest() }"
                >
                <lib-state-icon [state]="fakeTiles[6].state"></lib-state-icon>
                <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <svg-icon class="da-tile-icon" [src]="fakeTiles[6].image"></svg-icon>
                    <lib-tile-text
                        class="da-tile-header"
                        size="19px"
                        [convertMobile]="true"
                        label="{{'FINANCE_PREFERENCES.TILES.TILE8.TITLE' | translate}}"
                        info="true"
                        (infoValue)="screen.openInfo(auszahlungInfo)"
                    ></lib-tile-text>
                    <lib-toggle-vertical
                        #auszahlung
                        [options]="switchesAuszahlung"
                        [localValue]="contentService.getValue('auszahlungPref')"
                        name="auszahlung"
                        (switchValue)="setSwitch($event, auszahlung, 'Auszahlung')"
                    ></lib-toggle-vertical>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="da-assembly-button-wrapper" [ngClass]="{'isSmallest' : screen.isSmallest() }">
    <div class="da-assembly-button" *ngIf="!screen.isMobile()">
        <lib-button 
            name="reset button"
            value="{{'FINANCE_ASSEMBLY.BUTTON.RESET' | translate}}" 
            (buttonValue)="resetBausteine()"
            width="300"
            [disabled]="false"
        ></lib-button>
    </div>
    <div class="da-assembly-button">
        <lib-button 
            name="weiter button"
            value="{{'FINANCE_ASSEMBLY.BUTTON.LABEL' | translate}}" 
            (buttonValue)="getResults()"
            width="300"
            [disabled]="disabled"
        ></lib-button>
        <div class="da-button-subtext">
            <lib-tile-text
                class="da-button-text"
                size="12px"
                [thin]="true"
                [convertMobile]="false"
                label="{{'FINANCE_ASSEMBLY.BUTTON.SUBTITLE' | translate}}"
            ></lib-tile-text>              
        </div>
    </div>
</div>

<ng-template #auszahlungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_PREFERENCES.TILES.TILE8.INFO_HEADER' | translate}}" text="{{'FINANCE_PREFERENCES.TILES.TILE8.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #bewilligungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_PREFERENCES.TILES.TILE7.INFO_HEADER' | translate}}" text="{{'FINANCE_PREFERENCES.TILES.TILE7.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #wechselInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_PREFERENCES.TILES.TILE6.INFO_HEADER' | translate}}" text="{{'FINANCE_PREFERENCES.TILES.TILE6.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #laufzeitInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_PREFERENCES.TILES.TILE5.INFO_HEADER' | translate}}" text="{{'FINANCE_PREFERENCES.TILES.TILE5.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #sondertilgungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_PREFERENCES.TILES.TILE4.INFO_HEADER' | translate}}" text="{{'FINANCE_PREFERENCES.TILES.TILE4.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #aufbauInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_PREFERENCES.TILES.TILE3.INFO_HEADER' | translate}}" text="{{'FINANCE_PREFERENCES.TILES.TILE3.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #rateInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_PREFERENCES.TILES.TILE2.INFO_HEADER' | translate}}" text="{{'FINANCE_PREFERENCES.TILES.TILE2.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #zinsbindungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_PREFERENCES.TILES.TILE1.INFO_HEADER' | translate}}" text="{{'FINANCE_PREFERENCES.TILES.TILE1.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #preferencesInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_PREFERENCES.INFO_HEADER' | translate}}" text="{{'FINANCE_PREFERENCES.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
