import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { DaClickpath } from './da-clickpath.model';
import { DaClickpathStore } from './da-clickpath.store';
import { DaClickpathQuery } from './da-clickpath.query';
import { DataService } from '../data/data.service';
import { DaNextSlideService } from '../next-slide/da-next-slide.service';

@Injectable({ providedIn: 'root' })
export class DaClickpathService {

  constructor(
    private daClickpathStore: DaClickpathStore,
    private http: HttpClient,
    private daClickpathQuery: DaClickpathQuery,
    private daNextSlideService: DaNextSlideService,
    private daService: DataService) {
  }


  get() {
    return this.http.get<DaClickpath[]>('https://api.com').pipe(tap(entities => {
      this.daClickpathStore.set(entities);
    }));
  }

  add(daClickpath: DaClickpath) {
    this.daClickpathStore.add(daClickpath);
  }

  /**
   * update clickpath in redux store
   * @param  {} id
   * @param  {Partial<DaClickpath>} daClickpath
   */
  update(id: number, daClickpath: Partial<DaClickpath>) {
    this.daClickpathStore.update(id, daClickpath);
    daClickpath && daClickpath.clickpath ? this.daService.setClickpath(daClickpath.clickpath) : this.daService.setClickpath([]);
  }
  
  /**
   * general merge function to update clickpath
   * @param  {string} slide
   * @returns void
   */
  updateClickPath(slide: string): void {
    const clickpath = this.daClickpathQuery.getEntity(1);
    if (clickpath && clickpath.clickpath && clickpath.clickpath.indexOf(slide) === -1) {
      this.update(1, {clickpath: [...clickpath.clickpath, slide]});

      if (clickpath.clickpath.length > 0) {
        this.daService.update(1, { lastCompletedSlide: slide });
        // this.daService.update(1, { lastCompletedSlide: clickpath.clickpath.slice(-1)[0] });
      }
    }
  }

  /**
   * go back to last slide
   * @returns number
   */
  goBack(): any {
    const clickpath = this.daClickpathQuery.getEntity(1)?.clickpath;
    let convertClickPath = Array.from(clickpath as string[]);
    let lastSlide = convertClickPath.pop();
    // update redux
    this.update(1, {clickpath: convertClickPath});
    this.daNextSlideService.update(1, { next: lastSlide, timestamp: new Date });
    if (!lastSlide) {
      lastSlide = '';
    }
    return lastSlide;
  }

  remove(id: ID) {
    this.daClickpathStore.remove(id);
  }

  setCurrentSlide(slideName: string) {
    this.daService.setCurrentSlide(slideName);
  }

  getCurrentSlide() {
    return this.daService.getNextSlide();
  }
}
