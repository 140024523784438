import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { switchesContent } from 'src/assets/content/switches';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-house-category',
  templateUrl: './house-category.component.html'
})
export class HouseCategoryComponent implements OnInit {
  public slideName: string = 'house-category';
  next: string = 'address';
  localValue!: string;
  disabled: boolean = true;
  tiles = tilesContent['HOUSE_CATEGORY'].items;
  switchesZwei = switchesContent['ZWEIFAMILIENHAUS'].items;
  switchesReihe = switchesContent['REIHENHAUS'].items;
  switchesMehr = switchesContent['MEHRFAMILIENHAUS'].items;

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('contentZwei') contentZwei!: TemplateRef<any>;
  @ViewChild('contentReihe') contentReihe!: TemplateRef<any>;
  @ViewChild('contentMehr') contentMehr!: TemplateRef<any>;

  constructor(
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public daNextSlideService: DaNextSlideService,
    public contentService: ContentService
  ) {}

  ngOnInit() {
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.setStatus();
    });
  }

  setStatus() {
    this.disabled = true;
    const parentType = this.dataQuery.getEntity(1)?.['houseType'];
    const category = this.dataQuery.getEntity(1)?.['houseCategory'];
    const objectType = this.dataQuery.getEntity(1)?.['objectType'];

    if (!!parentType) {
      this.disabled = false;
      this.localValue = parentType;

      this.tiles.map(tile => {
        if (tile.value === parentType) {
          tile.state = 'success';
          tile.checked = true;
          if (tile.value !== 'EINFAMILIENHAUS' && tile.value !== 'DOPPELHAUSHAELFTE') {
            tile.state = !!category ? 'success' : 'warning';
            this.disabled = !!category ? false : true;
          }
        }  else {
          tile.state = 'warning';
          tile.checked = false;
        }
      });
    } 

    if(!objectType || objectType === '') {
      this.daService.update(1, { objectType: 'Haus' });
    }
    this.daNextSlideService.update(1, { disabled: this.disabled});
  }
 
  setValue(e: string, parent: string = '') {
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
    this.tiles = this.contentService.setTileStatus(this.tiles, e, 'success', 'warning');

    switch (e) {
      case 'ZWEIFAMILIENHAUS':
      case 'REIHENHAUS':
      case 'MEHRFAMILIENHAUS':
        this.localValue = e;
        this.setContent();
        this.daService.resetValues({ houseCategory: '' });
        this.daService.update(1, { houseType: e });
        this.daNextSlideService.update(1, { disabled: true});
        this.tiles = this.contentService.setTileStatusChecked(this.tiles, e, 'warning', true);
        this.contentService.setSlideIndexState(this.slideName, false, 'warning');
        this.stateChange.emit(true);
        break;
      case 'EINFAMILIENHAUS':
      case 'DOPPELHAUSHAELFTE':
        this.localValue = e;
        this.setHouseType(e, true)
        break;
      default:
        this.tiles = this.contentService.setTileStatusChecked(this.tiles, parent, 'success', true);
        this.setHouseType(e);
        break;
    }
  }

  setContent() {
    this.tiles = this.contentService.setTilesContent(this.tiles, 'ZWEIFAMILIENHAUS', this.contentZwei);
    this.tiles = this.contentService.setTilesContent(this.tiles, 'REIHENHAUS', this.contentReihe);
    this.tiles = this.contentService.setTilesContent(this.tiles, 'MEHRFAMILIENHAUS', this.contentMehr);
  }


  setHouseType(e: string, removeType?: boolean) {
    if (removeType) { 
      this.daService.resetValues({ houseCategory: '' }); 
      this.daService.update(1, { houseType: e });
    } else {
      this.daService.update(1, { houseCategory: e });
    }
    this.contentService.setSlideIndexState(this.slideName, false, 'success');
    this.daNextSlideService.update(1, { disabled: false});
    // update Navigation
    this.contentService.setNav('next', 'house-category', this.slideTo, this.next);
  };
}
