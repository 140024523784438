import { Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DaClickpathQuery } from 'src/app/state/click-path/da-clickpath.query';
import { DaClickpathService } from 'src/app/state/click-path/da-clickpath.service';
import { daBSliderIndex } from 'src/app/state/da-b-slide-index';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideQuery } from 'src/app/state/next-slide/da-next-slide.query';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { UserService } from 'src/app/state/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-da-b',
  templateUrl: './da-b.component.html',
  styleUrls: ['./da-b.component.scss']
})
export class DaBComponent implements OnInit{
  data: any = {};
  environment = environment;
  buttonText: string = 'Slide-Shortcut anzeigen';
  hideSlideShortcut: boolean = true;
  percentageValue: number = 2;
  sortSliderIndex: any[] = Object.entries(daBSliderIndex);

  @Input() name!: string;
  @Input() userIsLoggedIn!: boolean;
  @Output() public currentSlide: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('loginLightSidebar') loginLightSidebar!: TemplateRef<any>;

  constructor(
    private dataService: DataService,
    private dataQuery: DataQuery,
    private daNextSlideQuery: DaNextSlideQuery,
    private daClickpathService: DaClickpathService,
    private daClickpathQuery: DaClickpathQuery,
    private daNextSlideService: DaNextSlideService,
    public screen: LayoutService,
    public userService: UserService,
    public contentService: ContentService
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
    this.loadAnimation();
  }

  loadAnimation() {
    setTimeout(() => {
      this.percentageValue++;
      if (this.percentageValue < 100) {
        this.loadAnimation();
      }
    }, 30);
  }

  switchSlide(e?: string) {
    this.data = this.dataQuery.getEntity(1);
    if (e) {
      if (e === 'back') {
        this.name = this.daClickpathService.goBack();
      } else if (e === 'save') {
        this.screen.openInfo(this.loginLightSidebar);
      } else if (e === 'load') {
        console.log('load...');
      }
    }
    if (e !== 'save') {
      this.name = !!this.daNextSlideQuery.getEntity(1)?.['next'] ? this.daNextSlideQuery.getEntity(1)?.['next'] as string : '';
      this.screen.scrollToTop();
      this.setCurrentSlide(this.name);
    }
  }

  setCurrentSlide(value: string) {
    setTimeout(() => {
      let progress = this.daClickpathQuery.getEntity(1)?.slideIndex;
      this.stateChange.emit(progress);
    }, 50);
    this.currentSlide.emit(value);
  }

  setProgress(e?: any) {
    setTimeout(() => {
      if (e && e === 'endItem') {
        this.stateChange.emit(e);
      } else {
        let progress = this.daClickpathQuery.getEntity(1)?.slideIndex;
        this.stateChange.emit(progress);
      }
    }, 50);
  }

  hideShortcut() {
    this.hideSlideShortcut = !this.hideSlideShortcut;
    if (this.hideSlideShortcut) {
      this.buttonText = 'Slide-Shortcut anzeigen';
    } else {
      this.buttonText = 'Slide-Shortcut verstecken';
    }
  }
  setShortcut(e: any) {
    if (!this.dataQuery.getEntity(1)) {
      this.dataService.add({ id: 1 });
    }

    this.daNextSlideService.update(1, { next: e.target.value, timestamp: new Date });
    this.name = e.target.value;
  }
}
