<div class="da-content" [ngClass]="{ 'mobile' : screen.isSmallest() }">
  <div class="da-loading-screen transparent" *ngIf="contentService.getValue('expressSent')"></div>
    <button *ngIf="environment.buildEnv === 'local' || environment.buildEnv === 'development'" class="version-info-button" (click)="hideShortcut()">{{buttonText}}</button>
    <div *ngIf="(environment.buildEnv === 'local' || environment.buildEnv === 'development') && !hideSlideShortcut" class="version-info">
        <div class="version-info--headline version-info--headline_shortcut">Slide Shortcut</div>
        <select (change)="setShortcut($event)">
          <option *ngFor="let slides of sortSliderIndex" value="{{slides[1].id}}">
            {{slides[1].id}}
          </option>
        </select>
    </div>
    <div class="da-loading-screen" *ngIf="!name && this.userService.isLoggedInForLoginLight()">
      <div class="da-loading-bar">
          <div 
              class="da-loading-bar-value"
              [ngStyle]="{'width.%': percentageValue}">
          </div>
      </div>
      <!-- <mat-progress-spinner mode="indeterminate" diameter="150" class="da-start-loader"
          [ngStyle]="{'stroke': '#D2D2D2'}"></mat-progress-spinner> -->
  </div>
    <ng-container [ngSwitch]="name" *ngIf="name || !this.userService.isLoggedInForLoginLight()">
        <app-start-a *ngSwitchDefault (slideNext)="switchSlide()"></app-start-a>
        <app-start-a *ngSwitchCase="'start-da'" (slideNext)="switchSlide()"></app-start-a>
        <app-estate-status *ngSwitchCase="'estate-status'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-estate-status>
        <app-project-plan *ngSwitchCase="'project-plan'" (slideTo)="switchSlide($event)"></app-project-plan>
        <app-info-error *ngSwitchCase="'info-error-project'" type='project' (slideTo)="switchSlide($event)"></app-info-error>
        <app-status-spec *ngSwitchCase="'status-spec'" (slideTo)="switchSlide($event)"></app-status-spec>
        <app-info-success *ngSwitchCase="'info-success-25'" type='25' (slideTo)="switchSlide($event)"></app-info-success>
        <app-residence *ngSwitchCase="'residence'" (slideTo)="switchSlide($event)"></app-residence>
        <app-info-error *ngSwitchCase="'info-error-residence'" type='residence' (slideTo)="switchSlide($event)"></app-info-error>
        <app-schufa *ngSwitchCase="'schufa'" (slideTo)="switchSlide($event)"></app-schufa>
        <app-info-error *ngSwitchCase="'info-error-schufa'" type='schufa' (slideTo)="switchSlide($event)"></app-info-error>
        <app-participants *ngSwitchCase="'participants'" (slideTo)="switchSlide($event)"></app-participants>
        <app-object-category *ngSwitchCase="'object-category'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-object-category>
        <app-house-category *ngSwitchCase="'house-category'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-house-category>
        <app-address *ngSwitchCase="'address'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-address>
        <app-house-detail *ngSwitchCase="'house-detail'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-house-detail>
        <app-mf-house-detail *ngSwitchCase="'mf-house-detail'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-mf-house-detail>
        <app-object-detail *ngSwitchCase="'object-detail'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-object-detail>
        <app-object-nutzung *ngSwitchCase="'object-nutzung'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-object-nutzung>
        <app-condition-estate *ngSwitchCase="'condition-estate'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-condition-estate>
        <app-modernization *ngSwitchCase="'modernization'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-modernization>
        <app-bauweise *ngSwitchCase="'bauweise'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-bauweise>
        <app-energy *ngSwitchCase="'energy'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-energy>
        <app-parking *ngSwitchCase="'parking'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-parking>
        <app-erbbaurecht *ngSwitchCase="'erbbaurecht'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-erbbaurecht>
        <!-- <app-expose *ngSwitchCase="'expose'" (slideTo)="switchSlide($event)"></app-expose> -->
        <app-info-success *ngSwitchCase="'info-success-70'" type='70' (slideTo)="switchSlide($event)"></app-info-success>
        <app-personal *ngSwitchCase="'personal'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-personal>
        <app-personal-partner *ngSwitchCase="'personal-partner'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-personal-partner>
        <!-- <app-income *ngSwitchCase="'upload-income'" (slideTo)="switchSlide($event)"></app-income> -->
        <app-additional-income-single *ngSwitchCase="'additional-income-single'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-additional-income-single>
        <app-additional-income *ngSwitchCase="'additional-income'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-additional-income>
        <app-additional-income-partner *ngSwitchCase="'additional-income-partner'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-additional-income-partner>
        <app-children *ngSwitchCase="'children'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-children>
        <app-privatkredit *ngSwitchCase="'privatkredit'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-privatkredit>
        <app-miete *ngSwitchCase="'miete'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-miete>
        <app-health-insurance *ngSwitchCase="'health-insurance'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-health-insurance>
        <app-vehicles *ngSwitchCase="'vehicles'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-vehicles>
        <app-financial-assets *ngSwitchCase="'financial-assets'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-financial-assets>
        <app-property-information *ngSwitchCase="'property-information'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-property-information>
        <app-property-change *ngSwitchCase="'property-change'" (slideTo)="switchSlide($event)" (stateChange)="setProgress()"></app-property-change>
        <app-info-success *ngSwitchCase="'info-success-90'" type='90' (slideTo)="switchSlide($event)"></app-info-success>
        <app-finance-requirements *ngSwitchCase="'finance-requirements'" (slideTo)="switchSlide($event)" (stateChange)="setProgress($event)"></app-finance-requirements>
    </ng-container>
</div>

<ng-template #loginLightSidebar let-modal>
    <app-login-light-sidebar 
    [modal]="modal" 
    [showLoginLightExit]="false" 
    [updateLoginLightProgress]="userService.isLoggedInForLoginLight()"
    ></app-login-light-sidebar>
</ng-template>