<lib-header-text
    class="da-title"
    label="{{'ERBBAURECHT.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
    
></lib-header-text>
<lib-tile-text
    class="da-title"
    label="{{'ERBBAURECHT.SUB_TITLE' | translate}}"
    size="20px"
    [convertMobile]="true"
    [ngStyle]="{'display': 'flex', 'justify-content': 'center'}"
></lib-tile-text>
<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
>
<ng-template #content>
    <lib-tile-text
        size="16px"
        label="{{'ERBBAURECHT.TILE_TEXT1' | translate}}"
        info="true"
        (infoValue)="screen.openInfo(zinsInfo)"
        [light]="true"
    ></lib-tile-text>
    <lib-input
        #objectZins
        name="zins"
        label="{{'COMMON.LABELS.SUMME' | translate}}"
        placeholder="235,00"
        [error]="false"
        [inputDisabled]="inputDisabled"
        errorText="{{zinsError | translate}}"
        (click)="testValid = true"
        [touched]="!!contentService.getValue('objectErbbauzinsJaehrlich')"
        [value]="!!contentService.getValue('objectErbbauzinsJaehrlich') ? contentService.getValue('objectErbbauzinsJaehrlich') : ''"
        (change)="validateInputValue($event, objectZins)"
        [light]="true"
    ></lib-input>
    <lib-tile-text
        size="16px"
        label="{{'ERBBAURECHT.TILE_TEXT2' | translate}}"
        info="true"
        [ngStyle]="{'margin-top': '20px', 'max-width': '80%'}"
        (infoValue)="screen.openInfo(dateInfo)"
        [light]="true"
    ></lib-tile-text>
    <lib-input
        #objectDate
        name="laufzeit"
        label="{{'COMMON.LABELS.YEAR_SHORT' | translate}}"
        placeholder="2051"
        validationType="numbers"
        [error]="false"
        [inputDisabled]="inputDisabled"
        errorText="{{'COMMON.ERRORS.YEAR_FUTURE' | translate: {today: todayYear} }}"
        [touched]="!!contentService.getValue('objectErbbauLaufzeitBis')"
        [isCurrency]="false"
        [value]="!!contentService.getValue('objectErbbauLaufzeitBis') ? contentService.getValue('objectErbbauLaufzeitBis') : ''"
        (change)="validateYear($event, objectDate)"
        (click)="testValid = true"
        [ngStyle]="{'position': 'relative', 'z-index': '3'}"
        [light]="true"
    ></lib-input>
    <lib-tile-text
        size="16px"
        label="{{'ERBBAURECHT.TILE_TEXT3' | translate}}"
        [light]="true"
        [ngStyle]="{'margin-top': '20px', 'margin-bottom': '-10px', 'max-width': '80%'}"
    ></lib-tile-text>
    
    <lib-toggle-vertical
        #objectOwner
        [options]="switches"
        [localValue]="contentService.getValue('objectErbbauGrundstueckseigentuemer')"
        name="owner"
        (switchValue)="setSwitch($event, 'owner')"
    ></lib-toggle-vertical>
</ng-template>
</lib-tile-group>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

<ng-template #zinsInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'ERBBAURECHT.INFO_HEADER1' | translate}}" text="{{'ERBBAURECHT.INFO_TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #dateInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'ERBBAURECHT.INFO_HEADER2' | translate}}" text="{{'ERBBAURECHT.INFO_TEXT2' | translate}}"></app-info-sidebar>
</ng-template>
