<div class="da-lightbox" [ngClass]="{'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet()}">
    <div class="da-close-icon" [ngClass]="{'mobile' : screen.isSmallest()}" (click)="dismiss()">
        <svg-icon
            src="assets/images/chevronRight.svg"
        >
        </svg-icon>
    </div>
    <div class="da-modal-body">
        <div class="da-header-icon">
            <svg-icon
                src="assets/images/coins.svg"
            >
            </svg-icon>
        </div>
        <lib-header-text
            class="da-lightbox-header"
            size="38px"
            [convertMobile]="true"
            [light]="true"
            label="{{'EIGENKAPITAL.TITLE' | translate}}"
        ></lib-header-text>
        <div class="da-lightbox-content">
            <div class="da-content-wrapper">
                <!-- Bank- und Sparguthaben -->
                <div class="da-content-wrapper-row" 
                    [ngClass]="{ 'mobile' : screen.isSmallest() }" 
                    *ngIf="!!contentService.getValue('sparguthaben') && contentService.getValue('sparguthaben') === 'sparguthabenVorhanden' ">
                    <div class="da-input-box">
                        <lib-tile-text
                            class="da-lightbox-content-headline"
                            label="{{'EIGENKAPITAL.TEXT1' | translate}}"
                            [ngStyle]="{'margin-bottom': !screen.isSmallest() ? '-20px' : ''}"
                            [light]="true"
                            size="20px"
                        ></lib-tile-text>
                        <lib-tile-text
                            class="da-lightbox-content-subline"
                            label="{{'EIGENKAPITAL.TEXT1_2' | translate}}"
                            [ngClass]="{ 'mobile' : screen.isSmallest() }" 
                            [light]="true"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                    <div class="da-content-row-right verbindlichkeit">
                        <div></div>
                        <div>
                            <lib-input
                                #sparguthaben
                                label="{{'EIGENKAPITAL.LABELS.LABEL1' | translate}}"
                                name="sparguthaben"
                                [error]="false"
                                [touched]="false"
                                [inputDisabled]="true"
                                [value]="getSparen()"         
                                unit="€"
                                [autoWidth]="false"
                                [light]="true"
                            ></lib-input>
                        </div>
                        <svg-icon class="da-container-icon" [ngClass]="{'mobile' : screen.isSmallest()}" src="assets/images/arrowDownBigSmall.svg"></svg-icon>
                        <div>
                            <lib-input
                                #sparenbetrag
                                label="{{'EIGENKAPITAL.LABELS.LABEL2' | translate}}"
                                name="sparenbetrag"
                                [error]="false"
                                placeholder="0,00"
                                validatePattern="currency"
                                errorText="{{errorSparen | translate}}"
                                [touched]="!!contentService.getValue('sparenMaximalEinzusetzenderBetrag')"
                                [value]="!!contentService.getValue('sparenMaximalEinzusetzenderBetrag') ? contentService.getValue('sparenMaximalEinzusetzenderBetrag') : '0,00'"
                                (inputValue)="validateInput($event, 'sparenbetrag')"
                                unit="€"
                                [autoWidth]="false"
                                [light]="true"
                            ></lib-input>
                        </div>
                    </div>
                </div>
                <!-- Wertpapiere -->
                <div class="da-content-wrapper-row" 
                [ngClass]="{ 'mobile' : screen.isSmallest() }" 
                *ngIf="!!contentService.getValue('wertpapiere') && contentService.getValue('wertpapiere') === 'wertpapiereVorhanden' ">
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'EIGENKAPITAL.TEXT2' | translate}}"
                        [ngStyle]="{'margin-bottom': !screen.isSmallest() ? '-20px' : ''}"
                        [light]="true"
                        size="20px"
                    ></lib-tile-text>
                    <div class="da-content-row-right verbindlichkeit">
                        <div></div>
                        <div>
                            <lib-input
                                #wertpapiere
                                label="{{'EIGENKAPITAL.LABELS.LABEL1' | translate}}"
                                name="wertpapiere"
                                [error]="false"
                                [touched]="false"
                                [inputDisabled]="true"
                                [value]="contentService.getValue('wertpapiereBetrag')"
                                unit="€"
                                [autoWidth]="false"
                                [light]="true"
                            ></lib-input>
                        </div>
                        <svg-icon class="da-container-icon" [ngClass]="{'mobile' : screen.isSmallest()}" src="assets/images/arrowDownBigSmall.svg"></svg-icon>
                        <div>
                            <lib-input
                                #wertpapierebetrag
                                label="{{'EIGENKAPITAL.LABELS.LABEL2' | translate}}"
                                name="wertpapierebetrag"
                                [error]="false"
                                placeholder="0,00"
                                validatePattern="currency"
                                errorText="{{errorWertpapiere | translate}}"
                                [touched]="!!contentService.getValue('wertpapiereMaximalEinzusetzenderBetrag')"
                                [value]="!!contentService.getValue('wertpapiereMaximalEinzusetzenderBetrag') ? contentService.getValue('wertpapiereMaximalEinzusetzenderBetrag') : '0,00'"
                                (inputValue)="validateInput($event, 'wertpapierebetrag')"
                                unit="€"
                                [inputDisabled]="false"
                                [autoWidth]="false"
                                [light]="true"
                            ></lib-input>
                        </div>
                    </div>
                </div>
                <!-- Bausparverträge -->
                <div class="da-content-wrapper-row" 
                    [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'EIGENKAPITAL.TEXT3' | translate}}"
                        [ngStyle]="{'margin-bottom': '-20px'}"
                        [light]="true"
                        size="20px"
                    ></lib-tile-text>
                </div>
                <ng-template #keinBausparen>
                    <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet() }" >
                        <lib-tile-text
                            class="da-lightbox-content-headline"
                            label="{{'EIGENKAPITAL.TEXT7' | translate}}"
                            [ngStyle]="{'margin-bottom': '-20px'}"
                            [light]="true"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                </ng-template>
                <ng-container *ngIf="!!contentService.getValue('bausparguthaben') && contentService.getValue('bausparguthaben') === 'bausparguthabenVorhanden'; else keinBausparen ">
                    <div class="da-content-wrapper-row" 
                        [ngClass]="{ 'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet() }" 
                        *ngFor="let item of contentService.getValue('bausparvertraege', true); let num = index">
                        <lib-tile-text
                            class="da-lightbox-content-headline"
                            label="{{'EIGENKAPITAL.TEXT4' | translate: {number: item.id} }}"
                            [ngStyle]="{'margin-bottom': !screen.isSmallest() ? '-20px' : ''}"
                            [light]="true"
                            size="16px"
                        ></lib-tile-text>
                        <div class="da-input-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet()}" >
                            <div class="da-input-box">
                                <lib-tile-text
                                    class="da-lightbox-content-label"
                                    label="{{'EIGENKAPITAL.LABELS.LABEL3' | translate }}"
                                    [ngStyle]="{'margin-bottom': '-12px', 'margin-left': '20px'}"
                                    [light]="true"
                                    size="14px"
                                ></lib-tile-text>
                                <div class="da-input-toggle">
                                    <lib-toggle
                                        #bausparenAufloesen
                                        label1="{{'COMMON.TRUE' | translate}}"
                                        label2="{{'COMMON.FALSE' | translate}}"
                                        name="bausparenAufloesen{{num}}"
                                        value1="true"
                                        value2="false"
                                        [localValue]="setLocalValue(num, 'verwendung', 'bausparvertraege', 'aufloesen')"
                                        (switchValue)="validateInput($event, 'bausparenAufloesen', num, 'bausparvertraege')"
                                    ></lib-toggle>
                                </div>
                            </div>
                            <!-- aufloesen -->
                            <ng-container *ngIf="setLocalValue(num, 'verwendung', 'bausparvertraege', 'aufloesen') === 'true'">
                                <div class="da-content-row" [ngClass]="{ 'mobile' : screen.isSmallest() }" >
                                    <div>
                                        <lib-input
                                            #bausparenbetrag
                                            label="{{'EIGENKAPITAL.LABELS.LABEL4' | translate}}"
                                            name="bausparenbetrag{{num}}"
                                            [error]="false"
                                            [touched]="false"
                                            [inputDisabled]="true"
                                            [value]="setLocalValue(num, 'angesparterBetrag', 'bausparvertraege', 'bausparenbetrag')"
                                            unit="€"
                                            [autoWidth]="false"
                                            [light]="true"
                                        ></lib-input>
                                    </div>
                                    <svg-icon class="da-container-icon" [ngClass]="{'mobile' : screen.isSmallest()}" src="assets/images/arrowDownBigSmall.svg"></svg-icon>
                                    <div>
                                        <lib-input
                                            #bausparenEinsetzen
                                            label="{{'EIGENKAPITAL.LABELS.LABEL2' | translate}}"
                                            name="bausparenEinsetzen{{num}}"
                                            [error]="false"
                                            placeholder="0,00"
                                            errorText="{{errorBausparen | translate}}"
                                            [touched]="!!setLocalValue(num, 'maximalEinzusetzenderBetrag', 'bausparvertraege', 'bausparenEinsetzen')"
                                            [value]="setLocalValue(num, 'maximalEinzusetzenderBetrag', 'bausparvertraege', 'bausparenEinsetzen')"
                                            (inputValue)="validateInput($event, 'bausparenEinsetzen', num)"
                                            unit="€"
                                            [inputDisabled]="false"
                                            [autoWidth]="false"
                                            [light]="true"
                                        ></lib-input>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- abtreten -->
                            <ng-container *ngIf="setLocalValue(num, 'verwendung', 'bausparvertraege', 'aufloesen') === 'false'">
                                <div class="da-content-row" [ngClass]="{ 'mobile' : screen.isSmallest() }" >
                                    <div class="da-input-box">
                                        <lib-tile-text
                                            class="da-lightbox-content-label"
                                            label="{{'EIGENKAPITAL.LABELS.LABEL6' | translate }}"
                                            [ngStyle]="{'margin-bottom': '-12px', 'margin-left': '20px'}"
                                            [light]="true"
                                            size="14px"
                                        ></lib-tile-text>
                                        <div class="da-input-toggle">
                                            <lib-toggle
                                                #bausparenAbtreten
                                                label1="{{'COMMON.TRUE' | translate}}"
                                                label2="{{'COMMON.FALSE' | translate}}"
                                                name="bausparenAbtreten{{num}}"
                                                value1="true"
                                                value2="false"
                                                [localValue]="setLocalValue(num, 'verwendung', 'bausparvertraege', 'abtreten')"
                                                (switchValue)="validateInput($event, 'bausparenAbtreten', num, 'bausparvertraege')"
                                            ></lib-toggle>
                                        </div>
                                    </div>
                                    <svg-icon class="da-container-icon" [ngClass]="{'mobile' : screen.isSmallest()}" src="assets/images/arrowDownBigSmall.svg" *ngIf="setLocalValue(num, 'verwendung', 'bausparvertraege', 'abtreten') === 'true'"></svg-icon>
                                    <div *ngIf="setLocalValue(num, 'verwendung', 'bausparvertraege', 'abtreten') === 'true'">
                                        <lib-input
                                            #bausparenbetragPassiv
                                            label="{{'EIGENKAPITAL.LABELS.LABEL7' | translate}}"
                                            name="bausparenbetragPassiv{{num}}"
                                            [error]="false"
                                            [touched]="false"
                                            [inputDisabled]="true"
                                            [value]="setLocalValue(num, 'angesparterBetrag', 'bausparvertraege', 'bausparenbetrag')"
                                            unit="€"
                                            [autoWidth]="false"
                                            [light]="true"
                                        ></lib-input>
                                    </div>
                                    <div *ngIf="setLocalValue(num, 'verwendung', 'bausparvertraege', 'abtreten') === 'false'" class="da-content-filler"></div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <!-- Lebensversicherungen -->
                <div class="da-content-wrapper-row" 
                    [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'EIGENKAPITAL.TEXT5' | translate}}"
                        [ngStyle]="{'margin-bottom': '-20px'}"
                        [light]="true"
                        size="20px"
                    ></lib-tile-text>
                </div>
                <ng-template #keineVersicherungen>
                    <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet() }" >
                        <lib-tile-text
                            class="da-lightbox-content-headline"
                            label="{{'EIGENKAPITAL.TEXT7' | translate}}"
                            [ngStyle]="{'margin-bottom': '-20px'}"
                            [light]="true"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                </ng-template>
                <ng-container *ngIf="!!contentService.getValue('versicherung') && contentService.getValue('versicherung') === 'versicherungVorhanden'; else keineVersicherungen ">
                    <div class="da-content-wrapper-row" 
                        [ngClass]="{ 'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet() }" 
                        *ngFor="let item of contentService.getValue('versicherungsvertraege', true); let num = index">
                        <lib-tile-text
                            class="da-lightbox-content-headline"
                            label="{{'EIGENKAPITAL.TEXT6' | translate: {number: item.id} }}"
                            [ngStyle]="{'margin-bottom': !screen.isSmallest() ? '-20px' : ''}"
                            [light]="true"
                            size="16px"
                        ></lib-tile-text>
                        <div class="da-input-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet()}" >
                            <div class="da-input-box">
                                <lib-tile-text
                                    class="da-lightbox-content-label"
                                    label="{{'EIGENKAPITAL.LABELS.LABEL3' | translate }}"
                                    [ngStyle]="{'margin-bottom': '-12px', 'margin-left': '20px'}"
                                    [light]="true"
                                    size="14px"
                                ></lib-tile-text>
                                <div class="da-input-toggle">
                                    <lib-toggle
                                        #versicherungAufloesen
                                        label1="{{'COMMON.TRUE' | translate}}"
                                        label2="{{'COMMON.FALSE' | translate}}"
                                        name="versicherungAufloesen{{num}}"
                                        value1="true"
                                        value2="false"
                                        [localValue]="setLocalValue(num, 'verwendung', 'versicherungsvertraege', 'aufloesen')"
                                        (switchValue)="validateInput($event, 'versicherungAufloesen', num, 'versicherungsvertraege')"
                                    ></lib-toggle>
                                </div>
                            </div>
                            <!-- aufloesen -->
                            <ng-container *ngIf="setLocalValue(num, 'verwendung', 'versicherungsvertraege', 'aufloesen') === 'true'">
                                <div class="da-content-row" [ngClass]="{ 'mobile' : screen.isSmallest() }" >
                                    <div>
                                        <lib-input
                                            #versicherungbetrag
                                            label="{{'EIGENKAPITAL.LABELS.LABEL5' | translate}}"
                                            name="versicherungbetrag{{num}}"
                                            [error]="false"
                                            [touched]="false"
                                            [inputDisabled]="true"
                                            [value]="setLocalValue(num, 'rueckkaufswert', 'versicherungsvertraege', 'versicherungbetrag')"
                                            unit="€"
                                            [autoWidth]="false"
                                            [light]="true"
                                        ></lib-input>
                                    </div>
                                    <svg-icon class="da-container-icon" [ngClass]="{'mobile' : screen.isSmallest()}" src="assets/images/arrowDownBigSmall.svg"></svg-icon>
                                    <div>
                                        <lib-input
                                            #versicherungEinsetzen
                                            label="{{'EIGENKAPITAL.LABELS.LABEL2' | translate}}"
                                            name="versicherungEinsetzen{{num}}"
                                            [error]="false"
                                            placeholder="0,00"
                                            errorText="{{errorVersicherung | translate}}"
                                            [touched]="!!setLocalValue(num, 'maximalEinzusetzenderBetrag', 'versicherungsvertraege', 'versicherungEinsetzen')"
                                            [value]="setLocalValue(num, 'maximalEinzusetzenderBetrag', 'versicherungsvertraege', 'versicherungEinsetzen')"
                                            (inputValue)="validateInput($event, 'versicherungEinsetzen', num)"
                                            unit="€"
                                            [inputDisabled]="false"
                                            [autoWidth]="false"
                                            [light]="true"
                                        ></lib-input>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- abtreten -->
                            <ng-container *ngIf="setLocalValue(num, 'verwendung', 'versicherungsvertraege', 'aufloesen') === 'false'">
                                <div class="da-content-row" [ngClass]="{ 'mobile' : screen.isSmallest() }" >
                                    <div class="da-input-box">
                                        <lib-tile-text
                                            class="da-lightbox-content-label"
                                            label="{{'EIGENKAPITAL.LABELS.LABEL6' | translate }}"
                                            [ngStyle]="{'margin-bottom': '-12px', 'margin-left': '20px'}"
                                            [light]="true"
                                            size="14px"
                                        ></lib-tile-text>
                                        <div class="da-input-toggle">
                                            <lib-toggle
                                                #versicherungAbtreten
                                                label1="{{'COMMON.TRUE' | translate}}"
                                                label2="{{'COMMON.FALSE' | translate}}"
                                                name="versicherungAbtreten{{num}}"
                                                value1="true"
                                                value2="false"
                                                [localValue]="setLocalValue(num, 'verwendung', 'versicherungsvertraege', 'abtreten')"
                                                (switchValue)="validateInput($event, 'versicherungAbtreten', num, 'versicherungsvertraege')"
                                            ></lib-toggle>
                                        </div>
                                    </div>
                                    <svg-icon class="da-container-icon" [ngClass]="{'mobile' : screen.isSmallest()}" src="assets/images/arrowDownBigSmall.svg" *ngIf="setLocalValue(num, 'verwendung', 'versicherungsvertraege', 'abtreten') === 'true'"></svg-icon>
                                    <div *ngIf="setLocalValue(num, 'verwendung', 'versicherungsvertraege', 'abtreten') === 'true'">
                                        <lib-input
                                            #versicherungbetragPassiv
                                            label="{{'EIGENKAPITAL.LABELS.LABEL7' | translate}}"
                                            name="versicherungbetragPassiv{{num}}"
                                            [error]="false"
                                            [touched]="false"
                                            [inputDisabled]="true"
                                            [value]="setLocalValue(num, 'rueckkaufswert', 'versicherungsvertraege', 'versicherungbetrag')"
                                            unit="€"
                                            [autoWidth]="false"
                                            [light]="true"
                                        ></lib-input>
                                    </div>
                                    <div *ngIf="setLocalValue(num, 'verwendung', 'versicherungsvertraege', 'abtreten') === 'false'" class="da-content-filler"></div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <!-- Bestandsimmobilien -->
                <div class="da-content-wrapper-row" 
                    [ngClass]="{ 'mobile' : screen.isSmallest() }" 
                    >
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'EIGENKAPITAL.TEXT8' | translate}}"
                        [ngStyle]="{'margin-bottom': '-20px'}"
                        [light]="true"
                        size="20px"
                    ></lib-tile-text>
                </div>
                <ng-template #keineImmobilien>
                    <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet() }" >
                        <lib-tile-text
                            class="da-lightbox-content-headline"
                            label="{{'EIGENKAPITAL.TEXT7' | translate}}"
                            [ngStyle]="{'margin-bottom': '-20px'}"
                            [light]="true"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                </ng-template>
                <ng-container *ngIf="(!!contentService.getValue('immobilien') && contentService.getValue('immobilien') === 'VORHANDENE_BESTEHENDE_IMMOBILIEN') || (contentService.getValue('familienvermoegen') === 'familienvermoegenVorhanden' && contentService.getValue('familienImmobilie') === 'familienImmobilieVorhanden') ; else keineImmobilien ">
                    <div class="da-content-wrapper-row" 
                        [ngClass]="{ 'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet() }" 
                        *ngFor="let item of contentService.getValue('bestehendeImmobilien', true); let num = index">
                            <!-- Info-box (Adresse, Restschuld, Marktwert) -->
                            <ng-container *ngIf="item.name !== 'PROPERTY_INFORMATION.PROPERTY.FAMILY'">
                                <div class="da-immo-adresse">
                                    <lib-tile-text
                                        class="da-lightbox-content-headline"
                                        label="{{item.name | translate: { count : num + 1 } }}"
                                        [ngStyle]="{'margin-top': !screen.isSmallest() ? '-20px' : ''}"
                                        [light]="true"
                                        size="16px"
                                    ></lib-tile-text>
                                    <div class="da-immo-adresse-box" [ngClass]="{ 'mobile' : screen.isSmallest() }" >
                                        <div class="da-immo-adresse-row">
                                            <lib-tile-text
                                                class="da-immo-adresse-headline"
                                                label="{{'EIGENKAPITAL.LABELS.LABEL8' | translate}}"
                                                [ngStyle]="{'line-height': '0'}"
                                                [light]="true"
                                                size="12px"
                                            ></lib-tile-text>
                                            <div class="da-immo-adresse-adresse" [innerHTML]="getAddress(num + 1)"></div>
                                        </div>
                                        <div class="da-immo-adresse-row">
                                            <lib-tile-text
                                                class="da-immo-adresse-headline"
                                                label="{{'EIGENKAPITAL.LABELS.LABEL9' | translate}}"
                                                [ngStyle]="{'line-height': '0'}"
                                                [light]="true"
                                                size="12px"
                                            ></lib-tile-text>
                                            <lib-tile-text
                                                class="da-immo-adresse-headline"
                                                [label]="!!item.marktwert ? item.marktwert  + ',00 €' : '--'"
                                                [ngStyle]="{'line-height': '0'}"
                                                [light]="true"
                                                size="12px"
                                            ></lib-tile-text>
                                        </div>
                                        <div class="da-immo-adresse-row">
                                            <lib-tile-text
                                                class="da-immo-adresse-headline"
                                                label="{{'EIGENKAPITAL.LABELS.LABEL10' | translate}}"
                                                [ngStyle]="{'line-height': '0'}"
                                                [light]="true"
                                                size="12px"
                                            ></lib-tile-text>
                                            <lib-tile-text
                                                class="da-immo-adresse-headline"
                                                [label]="!!item.restschuld ? item.restschuld  + ' €' : '--'"
                                                [ngStyle]="{'line-height': '0'}"
                                                [light]="true"
                                                size="12px"
                                            ></lib-tile-text>
                                        </div>
                                    </div>
                                </div>
                            <div class="da-input-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet()}" >
                                <div class="da-input-box">
                                    <lib-tile-text
                                        class="da-lightbox-content-label"
                                        label="{{'EIGENKAPITAL.LABELS.LABEL11' | translate }}"
                                        [ngStyle]="{'margin-bottom': '-12px', 'margin-left': '20px'}"
                                        [light]="true"
                                        size="14px"
                                    ></lib-tile-text>
                                    <!-- verkaufen -->
                                    <div class="da-input-toggle">
                                        <lib-toggle
                                            #immobilieVerkaufen
                                            label1="{{'COMMON.TRUE' | translate}}"
                                            label2="{{'COMMON.FALSE' | translate}}"
                                            name="immobilieVerkaufen{{num}}"
                                            value1="true"
                                            value2="false"
                                            [localValue]="setLocalValue(num, 'change', 'bestehendeImmobilien', 'verkaufen')"
                                            (switchValue)="validateInput($event, 'immobilieVerkaufen', num, 'bestehendeImmobilien')"
                                        ></lib-toggle>
                                    </div>
                                </div>
                                <!-- verkaufserlös -->
                                <div class="da-content-row" [ngClass]="{ 'mobile' : screen.isSmallest() }" >
                                    <div class="da-input-box" *ngIf="setLocalValue(num, 'change', 'bestehendeImmobilien', 'verkaufen') === 'true'">
                                        <lib-tile-text
                                            class="da-lightbox-content-label"
                                            label="{{'EIGENKAPITAL.LABELS.LABEL13' | translate }}"
                                            [ngStyle]="{'margin-bottom': '-12px', 'margin-left': '20px'}"
                                            [light]="true"
                                            size="14px"
                                        ></lib-tile-text>
                                        <div class="da-input-toggle">
                                            <lib-toggle
                                                #verkaufserloes
                                                label1="{{'COMMON.TRUE' | translate}}"
                                                label2="{{'COMMON.FALSE' | translate}}"
                                                name="verkaufserloes{{num}}"
                                                value1="true"
                                                value2="false"
                                                [localValue]="setLocalValue(num, 'erloesVerwenden', 'bestehendeImmobilien', 'erloesVerwenden')"
                                                (switchValue)="validateInput($event, 'erloesVerwenden', num, 'bestehendeImmobilien')"
                                            ></lib-toggle>
                                        </div>
                                    </div>
                                    <!-- Zusatzsicherheit -->
                                    <div class="da-input-box" *ngIf="setLocalValue(num, 'change', 'bestehendeImmobilien', 'verkaufen') === 'false'">
                                        <lib-tile-text
                                            class="da-lightbox-content-label"
                                            label="{{'EIGENKAPITAL.LABELS.LABEL12' | translate }}"
                                            [ngStyle]="{'margin-bottom': '-12px', 'margin-left': '20px'}"
                                            [light]="true"
                                            size="14px"
                                        ></lib-tile-text>
                                        <div class="da-input-toggle">
                                            <lib-toggle
                                                #zusatzsicherheitImmo
                                                label1="{{'COMMON.TRUE' | translate}}"
                                                label2="{{'COMMON.FALSE' | translate}}"
                                                name="zusatzsicherheitImmo{{num}}"
                                                value1="ja"
                                                value2="nein"
                                                [localValue]="setLocalValue(num, 'zusatzsicherheit', 'bestehendeImmobilien', 'zusatzsicherheit')"
                                                (switchValue)="validateInput($event, 'zusatzsicherheit', num, 'bestehendeImmobilien')"
                                            ></lib-toggle>
                                        </div>
                                    </div>
                                    <svg-icon 
                                        class="da-container-icon" 
                                        [ngClass]="{'mobile' : screen.isSmallest()}" 
                                        src="assets/images/arrowDownBigSmall.svg" 
                                    ></svg-icon>
                                    <div>
                                        <lib-input
                                            *ngIf="setLocalValue(num, 'change', 'bestehendeImmobilien', 'verkaufen') === 'true'"
                                            #immobiliebetragAktiv
                                            label="{{'EIGENKAPITAL.LABELS.LABEL2' | translate}}"
                                            name="immobiliebetragAktiv{{num}}"
                                            [error]="false"
                                            [touched]="!!setLocalValue(num, 'erloesVerwenden', 'bestehendeImmobilien', 'erloesVerwenden') && setLocalValue(num, 'erloesVerwenden', 'bestehendeImmobilien', 'erloesVerwenden') === 'true'"
                                            errorText="{{errorImmobilienbetrag | translate}}"
                                            (inputValue)="validateInput($event, 'immobiliebetragAktiv', num, 'bestehendeImmobilien')"
                                            [value]="getImmobilienbetragAktiv(num)"
                                            unit="€"
                                            [autoWidth]="false"
                                            [light]="true"
                                        ></lib-input>
                                        <lib-input
                                            *ngIf="setLocalValue(num, 'change', 'bestehendeImmobilien', 'verkaufen') === 'false'"
                                            #immobiliebetragPassiv
                                            label="{{'EIGENKAPITAL.LABELS.LABEL7' | translate}}"
                                            name="immobiliebetragPassiv{{num}}"
                                            [error]="false"
                                            [touched]="false"
                                            [inputDisabled]="true"
                                            [value]="getImmobilienbetragPassiv(num)"
                                            unit="€"
                                            [autoWidth]="false"
                                            [light]="true"
                                        ></lib-input>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <!-- FAMILIEN IMMOBILIE -->
                        <ng-container *ngIf="item.name === 'PROPERTY_INFORMATION.PROPERTY.FAMILY' && contentService.getValue('familienImmobilie') === 'familienImmobilieVorhanden'">
                            <div class="da-immo-adresse">
                                <lib-tile-text
                                    class="da-lightbox-content-headline"
                                    label="{{item.name | translate }}"
                                    [ngStyle]="{'margin-top': !screen.isSmallest() ? '-20px' : ''}"
                                    [light]="true"
                                    size="16px"
                                ></lib-tile-text>
                                <div class="da-immo-adresse-box" [ngClass]="{ 'mobile' : screen.isSmallest() }" >
                                    <div class="da-immo-adresse-row">
                                        <lib-tile-text
                                            class="da-immo-adresse-headline"
                                            label="{{'EIGENKAPITAL.LABELS.LABEL8' | translate}}"
                                            [ngStyle]="{'line-height': '0'}"
                                            [light]="true"
                                            size="12px"
                                        ></lib-tile-text>
                                        <div class="da-immo-adresse-adresse" [innerHTML]="getAddress(num + 1)"></div>
                                    </div>
                                    <div class="da-immo-adresse-row">
                                        <lib-tile-text
                                            class="da-immo-adresse-headline"
                                            label="{{'EIGENKAPITAL.LABELS.LABEL9' | translate}}"
                                            [ngStyle]="{'line-height': '0'}"
                                            [light]="true"
                                            size="12px"
                                        ></lib-tile-text>
                                        <lib-tile-text
                                            class="da-immo-adresse-headline"
                                            [label]="!!item.marktwert ? item.marktwert  + ',00 €' : '--'"
                                            [ngStyle]="{'line-height': '0'}"
                                            [light]="true"
                                            size="12px"
                                        ></lib-tile-text>
                                    </div>
                                    <div class="da-immo-adresse-row">
                                        <lib-tile-text
                                            class="da-immo-adresse-headline"
                                            label="{{'EIGENKAPITAL.LABELS.LABEL10' | translate}}"
                                            [ngStyle]="{'line-height': '0'}"
                                            [light]="true"
                                            size="12px"
                                        ></lib-tile-text>
                                        <lib-tile-text
                                            class="da-immo-adresse-headline"
                                            [label]="!!item.restschuld ? item.restschuld  + ' €' : '--'"
                                            [ngStyle]="{'line-height': '0'}"
                                            [light]="true"
                                            size="12px"
                                        ></lib-tile-text>
                                    </div>
                                </div>
                            </div>
                        <div class="da-input-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet()}" >
                            <div class="da-input-box">
                                <lib-tile-text
                                    class="da-lightbox-content-label"
                                    label="{{'EIGENKAPITAL.LABELS.LABEL11' | translate }}"
                                    [ngStyle]="{'margin-bottom': '-12px', 'margin-left': '20px'}"
                                    [light]="true"
                                    size="14px"
                                ></lib-tile-text>
                                <!-- verkaufen -->
                                <div class="da-input-toggle">
                                    <lib-toggle
                                        #immobilieVerkaufen
                                        label1="{{'COMMON.TRUE' | translate}}"
                                        label2="{{'COMMON.FALSE' | translate}}"
                                        name="immobilieVerkaufen{{num}}"
                                        value1="true"
                                        value2="false"
                                        [inputDisabled]="true"
                                        [localValue]="setLocalValue(num, 'change', 'bestehendeImmobilien', 'verkaufen')"
                                        (switchValue)="validateInput($event, 'immobilieVerkaufen', num, 'bestehendeImmobilien')"
                                    ></lib-toggle>
                                </div>
                            </div>
                            <div class="da-content-row" [ngClass]="{ 'mobile' : screen.isSmallest() }" >
                                <!-- Zusatzsicherheit -->
                                <div class="da-input-box">
                                    <lib-tile-text
                                        class="da-lightbox-content-label"
                                        label="{{'EIGENKAPITAL.LABELS.LABEL12' | translate }}"
                                        [ngStyle]="{'margin-bottom': '-12px', 'margin-left': '20px'}"
                                        [light]="true"
                                        size="14px"
                                    ></lib-tile-text>
                                    <div class="da-input-toggle">
                                        <lib-toggle
                                            #zusatzsicherheitImmo
                                            label1="{{'COMMON.TRUE' | translate}}"
                                            label2="{{'COMMON.FALSE' | translate}}"
                                            name="zusatzsicherheitImmo{{num}}"
                                            value1="ja"
                                            value2="nein"
                                            [localValue]="setLocalValue(num, 'zusatzsicherheit', 'bestehendeImmobilien', 'zusatzsicherheit')"
                                            (switchValue)="validateInput($event, 'zusatzsicherheit', num, 'bestehendeImmobilien')"
                                        ></lib-toggle>
                                    </div>
                                </div>
                                <svg-icon 
                                    class="da-container-icon" 
                                    [ngClass]="{'mobile' : screen.isSmallest()}" 
                                    src="assets/images/arrowDownBigSmall.svg" 
                                ></svg-icon>
                                <div>
                                    <lib-input
                                        #immobiliebetragPassiv
                                        label="{{'EIGENKAPITAL.LABELS.LABEL7' | translate}}"
                                        name="immobiliebetragPassiv{{num}}"
                                        [error]="false"
                                        [touched]="false"
                                        [inputDisabled]="true"
                                        [value]="getImmobilienbetragPassiv(num)"
                                        unit="€"
                                        [autoWidth]="false"
                                        [light]="true"
                                    ></lib-input>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    </div>
                </ng-container>
            </div>
            <div class="da-content-wrapper">
                <!-- Gesamt -->
                <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'EIGENKAPITAL.TEXT10' | translate}}"
                        [ngStyle]="{'margin-bottom': !screen.isSmallest() ? '-20px' : '0', 'margin-top': !screen.isSmallest() ? '0' : '-45px'}"
                        [light]="true"
                        size="20px"
                    ></lib-tile-text>
                    <div class="da-content-row-right">
                        <div class="small" *ngIf="!screen.isSmallest()">
                        </div>
                        <svg-icon class="da-container-icon no-rotate" src="assets/images/equals.svg" *ngIf="!screen.isSmallest()"></svg-icon>
                        <div [ngStyle]="{'margin-bottom': !screen.isSmallest() ? '-0' : '20px'}">
                            <lib-input
                                #gesamtbetragPassiv
                                label=""
                                name="gesamtbetragPassiv"
                                [error]="false"
                                label="{{'EIGENKAPITAL.LABELS.LABEL14' | translate}}"
                                placeholder="0,00"
                                [touched]="!!contentService.getValue('eigenkapitalPassiv')"
                                [value]="!!contentService.getValue('eigenkapitalPassiv') ? contentService.getValue('eigenkapitalPassiv') : ''"
                                unit="€"
                                [inputDisabled]="true"
                                [autoWidth]="!screen.isSmallest() ? true : false"
                                [light]="true"
                            ></lib-input>
                        </div>
                    </div>
                    <div class="da-content-row-right">
                        <div class="small" *ngIf="!screen.isSmallest()">
                        </div>
                        <svg-icon class="da-container-icon no-rotate" src="assets/images/equals.svg" *ngIf="!screen.isSmallest()"></svg-icon>
                        <div>
                            <lib-input
                                #gesamtbetragAktiv
                                label=""
                                name="gesamtbetragAktiv"
                                [error]="false"
                                placeholder="0,00"
                                label="{{'EIGENKAPITAL.LABELS.LABEL15' | translate}}"
                                [touched]="!!contentService.getValue('eigenkapitalAktiv')"
                                [value]="!!contentService.getValue('eigenkapitalAktiv') ? contentService.getValue('eigenkapitalAktiv') : ''"
                                unit="€"
                                [inputDisabled]="true"
                                [autoWidth]="!screen.isSmallest() ? true : false"
                                [light]="true"
                            ></lib-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="da-address-button-wrapper" *ngIf="showButtons">
            <div class="da-sidebar-button-bg">
                <lib-button 
                    name="eigenkapital button"
                    value="{{'EIGENKAPITAL.BUTTON' | translate}}" 
                    [disabled]="!inputValid"
                    [light]="true"
                    width="230"
                    (buttonValue)="finishInput()"
                    iconRight="assets/images/disk.svg"
                ></lib-button>
            </div>
        </div>
    </div>
</div>

