import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { variationPlacements } from "@popperjs/core";
import { environment } from "src/environments/environment";
import { ConfigurationQuery } from "../state/configuration/configuration.query";
import { ContentService } from "./content.service";
import { DataQuery } from "../state/data/data.query";

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private http: HttpClient,
    private contentService: ContentService,
    private configurationQuery: ConfigurationQuery,
    private dataQuery: DataQuery
  ) { }

  async getEmailTemplate(type: string) {
    let name = '';
    this.configurationQuery.configuration.subscribe((res: any) => {
      if (res) {
        res.emailtemplates.map((template: any) => {
          const shortName = template.templatename.substr(0, template.templatename.indexOf('-'));
          if (shortName === type) { name = template.templatename; }
        });
      }
    });
    return name
  }

  async sendEmail(type: string, mail: string = '', withSaveUuid: boolean = false) {
    const header = new HttpHeaders({ 'finsoliotenantuuid': this.contentService.getTenantUuid() });
    let uuid = '';
    if (withSaveUuid) {
      uuid = !!this.contentService.getValue('uuid') ? this.contentService.getValue('uuid') : '';
    } else {
      uuid = !!this.contentService.getValue('anfrageUuid') ? this.contentService.getValue('anfrageUuid') : '';
    }

    const request: any = {
      dametadata: {
        receiver: !!mail ? mail : this.contentService.getValue('mail'),
        templatename: type,
        referenceuuid: uuid
      },
    }
    return this.http.post(`${environment.backendHost}/message`, request, { headers: header })
  }

  async sendEmailWithParams(type: string, mail: string = '', customerid: string = '', withSaveUuid: boolean = false, data?: any, isLoginLight: boolean = false) {
    const header = new HttpHeaders({ 'finsoliotenantuuid': this.contentService.getTenantUuid() });
    let uuid = '';
    if (withSaveUuid) {
      uuid = !!this.contentService.getValue('uuid') ? this.contentService.getValue('uuid') : '';
    } else {
      uuid = !!this.contentService.getValue('anfrageUuid') ? this.contentService.getValue('anfrageUuid') : '';
    }

    let customdata: any = {}

    let baustein: any = {
      institut: '',
      art: '',
      darlehenssumme: '',
      anfaenglichetilgung: 0,
      sollzins: 0,
      zinsbindung: 0,
      sondertilgungsoption: 0,
      annahmefrist: '',
      machbarkeit: ''
    }

    let dametadata: any = {
      receiver: !!mail ? mail : this.contentService.getValue('mail'),
      templatename: type,
    }

    if (!!customerid) {
      customdata = { ...customdata, kundennummer: customerid }
      if (data && data.metadata) {
        let anrede = data.metadata.anrede.toLowerCase();
        anrede = anrede.charAt(0).toUpperCase() + anrede.slice(1);
        customdata = { ...customdata, anrede: anrede, vorname: data.metadata.vorname, nachname: data.metadata.nachname }
      }
      dametadata = { ...dametadata, referenceuuid: uuid }
    } else if (type.includes('l06')) {
      if (data.darlehen) {
        const bausteine: any[] = [];
        data.darlehen.map((item: any) => {
          baustein = {
            institut: item.bankName,
            art: item.typ,
            darlehenssumme: item.darlehensBetrag,
            anfaenglichetilgung: !!item.anfaenglicheTilgung ? this.contentService.convertStringNumber(item.anfaenglicheTilgung, 2) : 0,
            sollzins: !!item.sollZins ? this.contentService.convertStringNumber(item.sollZins, 2) : 0,
            zinsbindung: !!item.zinsBindung ? this.contentService.convertStringNumber(item.zinsBindung) : 0,
            sondertilgungsoption: !!item.optionaleTilgung ? this.contentService.convertStringNumber(item.optionaleTilgung, 2) : 0,
            annahmefrist: item.dateGueltig,
            machbarkeit: item.machbarkeit,
          }
          bausteine.push(baustein);
        });
        customdata = { ...customdata, bausteine: bausteine };
        dametadata = { ...dametadata, referenceuuid: uuid }
      }
    } else if(isLoginLight) {
      customdata = { ...customdata, action: data};
    }

    let request: any = {
      dametadata: dametadata,
      customdata: customdata,
    }

    return this.http.post(`${environment.backendHost}/message`, request, { headers: header })
  }

  setEmail(type: string, mail: string = '', customerid: string = '', withUuid: boolean = false, data?: any, isLoginLight: boolean = false) {
    console.log('EMAIL-TEMPLATE VERSCHICKT: ', type)
    this.getEmailTemplate(type).then((name: string) => {
      if (!!name) {
        if (data) {
          this.sendEmailWithParams(name, mail, customerid, withUuid, data, isLoginLight).then((result: any) => {
            result.subscribe({
              next: (data: any) => {
                console.log(data)
                // return data
              },
              error: (error: any) => {
                console.log(error)
                // return error
              }
            })
          });
        } else {
          this.sendEmail(name, mail, withUuid).then((result: any) => {
            result.subscribe({
              next: (data: any) => {
                console.log(data)
                return data
              },
              error: (error: any) => {
                console.log(error)
                return error
              }
            })
          });
        }
      }
    })
  }
}
