import { Component, Output, EventEmitter, HostListener, AfterViewInit } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';


@Component({
  selector: 'app-verbindlichkeiten-forward',
  templateUrl: './verbindlichkeiten-forward.component.html',
  styleUrls: ['./verbindlichkeiten-forward.component.scss']
})
export class VerbindlichkeitenForwardComponent implements AfterViewInit {
  @Output() sum: EventEmitter<string> = new EventEmitter();
  error: boolean = false;
  isVisible: boolean = true;
  containerState: string = 'success';

  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    public dataQuery: DataQuery,
    public dataService: DataService
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngAfterViewInit() {
    const status = this.dataQuery.getEntity(1);

    if(status) {
      if(status['verbindlichkeiten']) {
        if(!status['abloeseGesamt']) {
          this.calculateSum();
        }
      }
    }
  }

  setLocalValue(num: number, field: string, isDate: boolean = false) {
    let parsedValue: any = this.contentService.getNestedValue('verbindlichkeiten', field, num) ?? '';
    return parsedValue;
  }

  setSwitch(e: string, name: string, id: number) {
    this.storeValue(e, name, id);
    setTimeout(() => {
      this.calculateSum();
    }, 200)
  }

  calculateSum() {
    let sum = 0;

    const status = this.dataQuery.getEntity(1)?.['verbindlichkeiten'];
      if (status) {
        status.map((verbindlichkeit: any) => {
          if (verbindlichkeit.abloesen === 'true') {
            sum = sum + this.contentService.convertStringNumber(verbindlichkeit.restschuld, 2)
          }
        });
      }
    setTimeout(() => {
      this.storeValue(this.contentService.convertNumberString(sum), 'abloeseGesamt');
      this.sum.emit()
    }, 200)
  }

  storeValue(e: any, name: string, id?: number) {
    let value = e.target?.value ? e.target?.value : e;
    if(name.includes('abloesen')) {
      let verbindlichkeiten: any[] = [];
      const status = this.dataQuery.getEntity(1)?.['verbindlichkeiten'];
      if (status) {
        status.map((verbindlichkeit: any) => {
          if (verbindlichkeit.id === id) {
            verbindlichkeit = { ...verbindlichkeit, 'abloesen': value };
          }
          verbindlichkeiten.push(verbindlichkeit)
        });
      }
      this.dataService.update(1, { verbindlichkeiten: verbindlichkeiten });
    } else {
      this.dataService.update(1, { abloeseGesamt: value });
    }

  }

}
