import { trigger, state, style, transition, group, animate } from '@angular/animations';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { EmailService } from 'src/app/services/email.service';
import { LayoutService } from 'src/app/services/layout.service';
import { MappingService } from 'src/app/services/mapping.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideQuery } from 'src/app/state/next-slide/da-next-slide.query';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { UserQuery } from 'src/app/state/user/user.query';
import { UserService } from 'src/app/state/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-finance-requirements',
  templateUrl: './finance-requirements.component.html',
  styleUrls: ['./finance-requirements.component.scss'],
  animations: [
    trigger('slideUpDown', [
      state('true', style({
        'max-height': '100%', opacity: '1', visibility: 'visible', marginBottom: '0px'
      })),
      state('false', style({
        'max-height': '0px', opacity: '0', visibility: 'hidden', marginBottom: '0px'
      })),
      transition('true => false', [group([
        animate('800ms ease-in-out', style({
          opacity: '0'
        })),
        animate('800ms ease-in-out', style({
          'max-height': '0px'
        })),
        animate('800ms ease-in-out', style({
          visibility: 'hidden'
        })),
        animate('800ms ease-in-out', style({
          'marginBottom': '0px'
        }))
      ]
      )]),
      transition('false => true', [group([
        animate('800ms ease-in-out', style({
          visibility: 'visible'
        })),
        animate('800ms ease-in-out', style({
          'max-height': '100%'
        })),
        animate('800ms ease-in-out', style({
          opacity: '1'
        })),
        animate('800ms ease-in-out', style({
          'marginBottom': '0px'
        }))
      ]
      )])
    ])
  ]
})
export class FinanceRequirementsComponent implements OnInit {
  public slideName: string = 'finance-requirements';
  next: string = 'info-success-95';
  disabled: boolean = true;
  hasResults: boolean = false;
  isLoading: boolean = false;

  chapters: any = [];

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('path') path!: ElementRef;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daNextSlideQuery: DaNextSlideQuery,
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService,
    private mappingService: MappingService,
    private translate: TranslateService,
    private emailService: EmailService,
    private configurationQuery: ConfigurationQuery,
    private userService: UserService,
    private userQuery: UserQuery
  ) {
    this.initChapterContent();
  }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
    this.daService.setCurrentSlide(this.slideName);
  }

  initChapterContent() {

    this.chapters = [
      {
        id: 0,
        title: 'FINANCE_CONTINUE.TABS.TAB1',
        className: 'da-finance-needs',
        active: true,
      },
      {
        id: 1,
        title: 'FINANCE_CONTINUE.TABS.TAB2',
        className: 'da-finance-path',
        active: true,
      },
      {
        id: 2,
        title: this.contentService.getValue('ermittlungsweg') === 'ai' ? 'FINANCE_CONTINUE.TABS.TAB3' : 'FINANCE_CONTINUE.TABS.TAB4',
        className: this.contentService.getValue('ermittlungsweg') === 'ai' ? 'da-finance-preference' : 'da-finance-assembly',
        active: false,
      },
      {
        id: 3,
        title: 'FINANCE_CONTINUE.TABS.TAB5',
        className: 'da-finance-results',
        active: false,
      },
    ]
  }

  setActive(data: { id: number, active: boolean; title: string; className: string; }, isPath: boolean = false) {
    setTimeout(() => {
      data.active = isPath ? true : !data.active;

      if (data.id === 2) {
        data.title = this.contentService.getValue('ermittlungsweg') === 'ai' ? 'FINANCE_CONTINUE.TABS.TAB3' : 'FINANCE_CONTINUE.TABS.TAB4';
        data.className = this.contentService.getValue('ermittlungsweg') === 'ai' ? 'da-finance-preference' : 'da-finance-assembly';
        this.chapters[3].active = true;
      }
    }, 500);
    setTimeout(() => {
      if (data.active) {
        this.screen.scrollToElement(data.className, 'end');
      }
    }, 1000);
  }

  resetChapters() {
    this.setActive(this.chapters[2], true);
    this.mappingService.mapReduxDataUpdate().then((resultOne) => {
      resultOne.subscribe((value: any) => {
        console.log('RESULT FROM EUROPACE (VORGANG AKTUALISIEREN): ', value);
        this.configurationQuery.configuration.subscribe((res: any) => {
          if (res) {
            this.updateUser('l09', res.kontaktemailkunden);
          }
        });
      });
    });
  }

  getEvent(e: any) {
    this.daService.update(1, { resultsLoading: false });
    this.hasResults = false;
    this.daService.update(1, { results: [] });
    this.daService.update(1, { resultsDone: false });
    setTimeout(() => {
      this.slideTo.emit(e);
    });
  }

  setNav(event: string) {
    switch (event) {
      case 'up': {
        this.screen.scrollToElement('da-content', 'start');
        break;
      }
      case 'save': {
        this.daService.update(1, { resultsLoading: false });
        this.hasResults = false;
        this.daService.update(1, { results: [] });
        this.daService.update(1, { resultsDone: false });
        setTimeout(() => {
          this.contentService.setNav(event, this.slideName, this.slideTo);
        });
        break;
      }
      case 'down': {
        this.screen.scrollToElement('da-content', 'end');
        break;
      }
    }
  }

  updateUser(type: string, mail: string) {
    const data = { ...this.dataQuery.getEntity(1) };
    const userData = { ...this.userQuery.getEntity(1) };

    this.userService.getStoreData(data['uuid'], { ...userData, uuid: data['uuid'] }).then((storeDataResult) => {
      // console.log('save', storeDataResult);
      if (storeDataResult) {
        this.userService.updateProgress(storeDataResult).then((saveProgressResult: any) => {
          if (!!this.contentService.getValue('uuid')) {
            this.emailService.setEmail(type, mail, this.contentService.getValue('uuid'), true, storeDataResult);
          }
        });
      }
    });
  }

  async getResults(e: any) {

    this.daService.update(1, { resultsLoading: true });
    this.hasResults = false;
    this.daService.update(1, { results: [] });

    await this.mappingService.mapReduxDataUpdate().then((resultOne) => {
      resultOne.subscribe((value: any) => {
        console.log('RESULT FROM EUROPACE (VORGANG AKTUALISIEREN): ', value);
        if (e === 'assemblyDone') {
          if (value.finanzierungsanfrageuuid) {
            setTimeout(() => {
              this.mappingService.getResults().then((result) => {
                result.subscribe((value: any) => {
                  console.log('Die Response von Angebote-API mit Vorgangsnummer: ', value);
                  this.parseResults(value, 'nonAi');
                });
              });
            }, 1000);
          }
        } else {
          let adresse = this.contentService.getValue('objectAddress');
          let plz = !!adresse.plz ? adresse.plz : '10117';
          this.mappingService.getAiResults(plz).then((resultOne: any) => {
            resultOne.subscribe((value: any) => {
              console.log('RESULT FROM EUROPACE / PASST (PRÄFERENZEN): ', value);
              this.parseResults(value, 'ai');
            });
          });
        }
      });
    });
  }

  parseResults(data: any, type: string) {

    if (!this.daNextSlideQuery.getEntity(1)?.['L03Sent']) {
      this.configurationQuery.configuration.subscribe((res: any) => {
        if (res) {
          this.emailService.setEmail('l03', res.kontaktemailkunden);
        }
        this.daNextSlideService.update(1, { L03Sent: true });
      });
    }

    let parsedDarlehen = {
      id: 0,
      typ: '',
      darlehensBetrag: '',
      sollZins: '',
      effektivZins: '',
      anfaenglicheTilgung: '',
      zinsBindung: '',
      rateMonatlich: '',
      restSchuld: '',
      laufzeit: '',
      dateGueltig: '',
      hinweisDate: '',
      optionaleTilgung: '',
      zinsfreieMonate: '',
      wechselTilgung: '',
      beginnTilgung: '',
      dauer: '',
      bankName: '',
      bankLogo: '',
      rating: 2.5,
    }
    let parsedDarlehenPlusMeta = {
      id: 0,
      isReserved: false,
      isVisible: false,
      darlehen: [],
      fakeTiles: [],
      selectedTile: '0',
      tilePosition: '0',
      matrix: '',
      logos: [],
      mischzins: '',
      gesamtrate: '',
      gesamttilgung: '',
      gesamtzinsbindung: ''
    }
    let allDarlehenOneOffer: any = [];
    const allDarlehenAllOffer: any = [];
    if (type === 'nonAi') { // TRADITIONELL
      if (data.ergebnisse && data.ergebnisse.length > 0) {
        let num = 0;
        data.ergebnisse.map((darlehen: any) => { // alle Angebote
          let numBaustein = 0;
          let rate = 0;
          let allLogos: any = [];
          let allTilgung: any[] = [];
          let allZinsbindung: any[] = [];
          darlehen.darlehen.map((baustein: any) => { // alle darlehen (bausteine) in den Angeboten
            if (!!darlehen.machbarkeit && darlehen.machbarkeit === 'MACHBAR') {
              let name = '';
              this.translate.get(`FINANCE_RESULTATE.LABELS.TYP.${baustein.typ}`).subscribe((res: string) => {
                name = res;
              });
              let laufzeit = '0';
              const tilgung = baustein.tilgung.anfaenglicheTilgung;
              const sollzins = baustein.sollZins;
              const betrag = baustein.darlehensBetrag;
              let monate = baustein.gesamtlaufzeitInMonaten ? baustein.gesamtlaufzeitInMonaten : 0;
              let logoName = baustein.produktAnbieter._links.logo.href;
              logoName = logoName.substring(logoName.lastIndexOf('/'), logoName.length);

              if (monate > 0) {
                monate = monate / 12
                laufzeit = monate.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 0 });
              } else {
                laufzeit = this.berechneLaufzeit(betrag, sollzins / 100, tilgung / 100).toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 0 });
              }
              rate = rate + baustein.rateMonatlich;
              allLogos = this.parseAllLogos(allLogos, baustein.produktAnbieter.produktAnbieterId, `${environment.backendHost}/produktanbieter-logos/logo${logoName}`);
              allTilgung.push(tilgung);
              if (!!baustein.zinsBindung?.jahre) {
                allZinsbindung.push(baustein.zinsBindung?.jahre);
              }

              parsedDarlehen = {
                id: numBaustein,
                typ: name,
                darlehensBetrag: baustein.darlehensBetrag.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                sollZins: baustein.sollZins.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                effektivZins: baustein.effektivZins.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                anfaenglicheTilgung: baustein.tilgung.anfaenglicheTilgung.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                zinsBindung: baustein.zinsBindung?.jahre ? baustein.zinsBindung.jahre.toString() : '--',
                rateMonatlich: baustein.rateMonatlich.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                restSchuld: baustein.zinsBindung?.restschuldNachZinsBindungsEnde ? baustein.zinsBindung.restschuldNachZinsBindungsEnde.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '--',
                laufzeit: laufzeit,
                dateGueltig: new Date(this.contentService.convertDate(darlehen.annahmeFrist)).toLocaleDateString('de-DE'),
                hinweisDate: this.contentService.subtractDay(new Date(darlehen.annahmeFrist), 1),
                optionaleTilgung: baustein.tilgung?.sonderTilgungJaehrlich ? baustein.tilgung.sonderTilgungJaehrlich.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : baustein.tilgung.anfaenglicheTilgung ? baustein.tilgung.anfaenglicheTilgung.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '--',
                zinsfreieMonate: baustein.bereitstellung.bereitstellungsZinsfreieZeitInMonaten,
                wechselTilgung: 'ja',
                beginnTilgung: baustein.tilgung?.tilgungsBeginn ? new Date(baustein.tilgung.tilgungsBeginn).toLocaleDateString('de-DE') : '--',
                dauer: '5',
                bankName: baustein.produktAnbieter.name,
                bankLogo: `${environment.backendHost}/produktanbieter-logos/logo${logoName}`,
                rating: 2.5,
              }
              allDarlehenOneOffer.push(parsedDarlehen)
              numBaustein++;
            }
          });
          if (allDarlehenOneOffer.length > 0) {
            const fakeTiles: any = [];
            for (let i = 0; i < allDarlehenOneOffer.length; i++) {
              fakeTiles.push({
                "value": i.toString()
              })
            }
            let gesamtZinsbindung = '';
            if (Math.min(...allZinsbindung) === Math.max(...allZinsbindung)) {
              gesamtZinsbindung = Math.min(...allZinsbindung).toLocaleString('de-DE', { maximumFractionDigits: 0, minimumFractionDigits: 0 });
            } else {
              gesamtZinsbindung = `${Math.min(...allZinsbindung).toLocaleString('de-DE', { maximumFractionDigits: 0, minimumFractionDigits: 0 })}-${Math.max(...allZinsbindung).toLocaleString('de-DE', { maximumFractionDigits: 0, minimumFractionDigits: 0 })}`;
            }

            parsedDarlehenPlusMeta = {
              ...parsedDarlehenPlusMeta,
              id: num++,
              logos: allLogos,
              mischzins: darlehen.sollZins.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
              gesamtrate: rate.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
              gesamttilgung: `${Math.min(...allTilgung).toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 0 })}-${Math.max(...allTilgung).toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 0 })}`,
              gesamtzinsbindung: gesamtZinsbindung,
              darlehen: allDarlehenOneOffer,
              fakeTiles: fakeTiles,
            };
            allDarlehenAllOffer.push(parsedDarlehenPlusMeta);
            allDarlehenOneOffer = [];
          }
        });
      }
    } else { // AI
      if (data.vorschlaege && data.vorschlaege.length > 0) {
        let num = 0;
        data.vorschlaege.map((darlehen: any) => { // alle Angebote
          let numBaustein = 0;
          let rate = 0;
          let allLogos: any = [];
          let allTilgung: any[] = [];
          let allZinsbindung: any[] = [];
          darlehen.finanzierungsbausteine.map((baustein: any) => { // alle darlehen (bausteine) in den Angeboten
            if (!!darlehen.machbarkeit && darlehen.machbarkeit === 100) {
              let name = '';
              let details: any;
              this.translate.get(`FINANCE_RESULTATE.LABELS.TYP.${baustein['@type']}`).subscribe((res: string) => {
                name = res;
              });
              if (baustein['@type'] === 'ANNUITAETENDARLEHEN') {
                details = baustein.annuitaetendetails;
              } else if (baustein['@type'] === 'PRIVATDARLEHEN') {
                details = {
                  zinsbindungInJahren: baustein.zinsbindungInJahren,
                }
              }
              let laufzeit = '0';
              // const tilgung = details.tilgung.anfaenglicheTilgung;
              const sollzins = baustein.sollZins;
              const betrag = baustein.darlehensbetrag;
              let monate = baustein.anzahlRaten ? baustein.anzahlRaten : 0;
              let logoName = baustein['@type'] !== 'KFW_DARLEHEN' ? `${baustein.produktAnbieter}.svg` : 'Kfw.svg';
              // logoName = logoName.substring(logoName.lastIndexOf('/'), logoName.length);

              if (monate > 0) {
                monate = monate / 12
                laufzeit = monate.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 0 });
              }
              rate = rate + baustein.rateMonatlich;
              allLogos = this.parseAllLogos(allLogos, baustein.produktAnbieter, `${environment.backendHost}/produktanbieter-logos/logo/${logoName}`);
              if (!!details?.zinsbindungInJahren) {
                allZinsbindung.push(details?.zinsbindungInJahren);
              }
              parsedDarlehen = {
                id: numBaustein,
                typ: name,
                darlehensBetrag: baustein.darlehensbetrag.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                sollZins: baustein.sollZins.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                effektivZins: baustein.effektivZins.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                anfaenglicheTilgung: baustein.tilgungssatzInProzent ? baustein.tilgungssatzInProzent.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '--',
                zinsBindung: details?.zinsbindungInJahren ? details?.zinsbindungInJahren.toString() : '--',
                rateMonatlich: baustein.rateMonatlich.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                restSchuld: baustein.restschuldNachZinsbindungsEnde ? baustein.restschuldNachZinsbindungsEnde.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '--',
                laufzeit: laufzeit,
                dateGueltig: new Date(this.contentService.convertDate(darlehen.annahmeFrist)).toLocaleDateString('de-DE'),
                hinweisDate: this.contentService.subtractDay(new Date(darlehen.annahmeFrist), 1),
                optionaleTilgung: details?.sondertilgungJaehrlich ? details?.sondertilgungJaehrlich.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : baustein['@type'] === 'KFW_DARLEHEN' ? 0 : '--',
                zinsfreieMonate: baustein.bereitstellungszinsfreieZeitInMonaten ? baustein.bereitstellungszinsfreieZeitInMonaten : baustein['@type'] === 'KFW_DARLEHEN' ? '12' : '--',
                wechselTilgung: 'Bitte anfragen',
                beginnTilgung: baustein.tilgungsBeginn ? new Date(baustein.tilgungsBeginn).toLocaleDateString('de-DE') : details?.tilgung?.tilgungsbeginn ? new Date(details?.tilgung?.tilgungsbeginn).toLocaleDateString('de-DE') : '--',
                dauer: 'Bitte anfragen',
                bankName: baustein.produktAnbieter ? baustein.produktAnbieter : baustein['@type'] === 'KFW_DARLEHEN' ? 'ING' : '',
                bankLogo: `${environment.backendHost}/produktanbieter-logos/logo/${logoName}`,
                rating: 2.5,
              }
              allDarlehenOneOffer.push(parsedDarlehen)
              numBaustein++;
            }
          });
          if (allDarlehenOneOffer.length > 0) {
            const fakeTiles: any = [];
            for (let i = 0; i < allDarlehenOneOffer.length; i++) {
              fakeTiles.push({
                "value": i.toString()
              })
            }
            let gesamtZinsbindung = '';
            if (Math.min(...allZinsbindung) === Math.max(...allZinsbindung)) {
              gesamtZinsbindung = Math.min(...allZinsbindung).toLocaleString('de-DE', { maximumFractionDigits: 0, minimumFractionDigits: 0 });
            } else {
              gesamtZinsbindung = `${Math.min(...allZinsbindung).toLocaleString('de-DE', { maximumFractionDigits: 0, minimumFractionDigits: 0 })}-${Math.max(...allZinsbindung).toLocaleString('de-DE', { maximumFractionDigits: 0, minimumFractionDigits: 0 })}`;
            }

            parsedDarlehenPlusMeta = {
              ...parsedDarlehenPlusMeta,
              id: num++,
              logos: allLogos,
              mischzins: darlehen.sollZins.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
              gesamtrate: rate.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
              gesamttilgung: `${Math.min(...allTilgung).toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 0 })}-${Math.max(...allTilgung).toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 0 })}`,
              gesamtzinsbindung: gesamtZinsbindung,
              darlehen: allDarlehenOneOffer,
              fakeTiles: fakeTiles,
              matrix: darlehen.vorschlagsOption ? darlehen.vorschlagsOption : ''
            };
            allDarlehenAllOffer.push(parsedDarlehenPlusMeta);
            allDarlehenOneOffer = [];
          }
        });
      }
    }

    this.daService.update(1, { resultsDone: true });
    this.daService.update(1, { results: allDarlehenAllOffer });

    setTimeout(() => {
      this.daService.update(1, { resultsLoading: false });
      this.hasResults = true;
      this.configurationQuery.configuration.subscribe((res: any) => {
        if (res) {
          this.updateUser('l09', res.kontaktemailkunden);
        }
      });
    }, 100)
  }

  parseAllLogos(logos: any, id: string, link: string) {
    if (logos.length > 0) {
      let count = 0;
      logos.map((logoItem: any) => {
        if (id === logoItem.id) {
          count++
        }
      });
      if (count === 0) {
        logos.push({
          id: id,
          link: link,
        });
      }
    } else {
      logos.push({
        id: id,
        link: link,
      });
    }
    return logos
  }

  berechneLaufzeit(darlehenshoehe: number, zinssatz: number, anfaenglicheTilgung: number) {
    const annuitaet = darlehenshoehe * (zinssatz + anfaenglicheTilgung);
    const n = Math.log(annuitaet / (annuitaet - darlehenshoehe * zinssatz)) / Math.log(1 + zinssatz);
    return n;
  }

  setValid(data: any) {
    this.disabled = true;
    this.daNextSlideService.update(1, { disabled: this.disabled });
  }

}
