<div class="da-footer-menu" [ngClass]="{ 'mobile' : screen.isSmallest() }">
    <ng-container *ngFor="let item of menuItems">
        <lib-tile-text
            (click)="openLink(item.link)"
            *ngIf="item.link !== 'gender' && item.link !== 'cookie'"
            label="{{item.label | translate}}"
            placement="bottom" 
            [ngbTooltip]="link"
            size="14px"
            [ngStyle]="{'white-space' : 'nowrap'}"
        >
            <ng-template #link>
                <app-link-label [label]="getLinkInfo(item.link)"></app-link-label>
            </ng-template>
        </lib-tile-text>
        <lib-tile-text
            (click)="cookies.emit(true)"
            *ngIf="item.link === 'cookie'"
            label="{{item.label | translate}}"
            placement="bottom" 
            [ngbTooltip]="link"
            size="14px"
            [ngStyle]="{'white-space' : 'nowrap'}"
        >
            <ng-template #link>
                <app-link-label label="{{'FOOTER.LABEL_COOKIES' | translate}}"></app-link-label>
            </ng-template>
        </lib-tile-text>
        <lib-tile-text
            (click)="gender.emit(true)"
            *ngIf="item.link === 'gender'"
            label="{{item.label | translate}}"
            placement="bottom" 
            [ngbTooltip]="link"
            size="14px"
            [ngStyle]="{'white-space' : 'nowrap'}"
        >
            <ng-template #link>
                <app-link-label label="{{'FOOTER.LABEL_GENDER' | translate}}"></app-link-label>
            </ng-template>
        </lib-tile-text>
    </ng-container>

</div>
