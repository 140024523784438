<div class="da-lightbox" [ngClass]="{'cookies' : category === 'cookies'}">
    <div class="da-modal-body">
        <div class="da-close-icon start-point" [ngClass]="{'mobile' : screen.isSmallest()}" (click)="dismiss()">
            <svg-icon
                src="assets/images/chevronRight.svg"
            >
            </svg-icon>
        </div>

        <!-- INFO SCREENS -->
        <ng-container *ngIf="info">
            <div class="da-info-icon">
                <svg-icon src="assets/images/circleInfo.svg"></svg-icon>
            </div>
            <lib-header-text
                class="da-info-header"
                size="38px"
                [convertMobile]="true"
                [light]="true"
                label="Info"
            ></lib-header-text>
            <div class="da-info-content">
                <lib-tile-text
                    class="da-info-content-headline"
                    [label]="header"
                    [light]="true"
                    size="20px"
                ></lib-tile-text>
                <lib-tile-text
                    *ngIf="!sonderText"
                    class="da-info-content-text"
                    [label]="text"
                    [thin]="true"
                    [light]="true"
                    size="16px"
                ></lib-tile-text>
                <div *ngIf="sonderText" class="da-info-sondertext" [innerHTML]="sonderText"></div>
            </div>
        </ng-container>

        <!-- COOKIES SCREENS -->
        <ng-container *ngIf="category ==='cookies'">
            <div id="da-cookies"></div>
        </ng-container>

        <!-- OTHER SCREENS -->
        <ng-container *ngIf="!info && category !=='cookies'">
            <div class="da-info-icon" [ngClass]="{'green' : isGreen}">
                <svg-icon [src]="icon"></svg-icon>
            </div>
            <lib-header-text
                class="da-info-header"
                size="38px"
                [convertMobile]="true"
                [light]="true"
                [label]="category"
            ></lib-header-text>
            <div class="da-info-content">
                <lib-tile-text
                    class="da-info-content-headline"
                    [label]="header"
                    [light]="true"
                    size="20px"
                ></lib-tile-text>
                <lib-tile-text
                    *ngIf="text !== 'sicherheit'"
                    class="da-info-content-text"
                    [label]="text"
                    [thin]="true"
                    [light]="true"
                    size="16px"
                ></lib-tile-text>
                <!-- SECURITY SCREEN -->
                <ng-container *ngIf="text === 'sicherheit'" >
                    <div class="da-other-sondertext">
                        <div class="da-other-headline">
                            <svg-icon src="assets/images/award.svg"></svg-icon>
                            <lib-header-text
                                class="da-other-header"
                                size="16px"
                                [convertMobile]="false"
                                [light]="true"
                                label="{{'HEADER.SECURITY.PARAGRAPHS.HEADER1' | translate}}"
                            ></lib-header-text>
                        </div>
                        <lib-tile-text
                            class="da-other-text"
                            label="{{'HEADER.SECURITY.PARAGRAPHS.TEXT1' | translate}}"
                            [thin]="true"
                            [light]="true"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                    <div class="da-other-sondertext">
                        <div class="da-other-headline">
                            <svg-icon src="assets/images/lock.svg"></svg-icon>
                            <lib-header-text
                                class="da-other-header"
                                size="16px"
                                [convertMobile]="false"
                                [light]="true"
                                label="{{'HEADER.SECURITY.PARAGRAPHS.HEADER2' | translate}}"
                            ></lib-header-text>
                        </div>
                        <lib-tile-text
                            class="da-other-text"
                            label="{{'HEADER.SECURITY.PARAGRAPHS.TEXT2' | translate}}"
                            [thin]="true"
                            [light]="true"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                    <div class="da-other-sondertext">
                        <div class="da-other-headline">
                            <svg-icon src="assets/images/locationDot.svg"></svg-icon>
                            <lib-header-text
                                class="da-other-header"
                                size="16px"
                                [convertMobile]="false"
                                [light]="true"
                                label="{{'HEADER.SECURITY.PARAGRAPHS.HEADER3' | translate}}"
                            ></lib-header-text>
                        </div>
                        <lib-tile-text
                            class="da-other-text"
                            label="{{'HEADER.SECURITY.PARAGRAPHS.TEXT3' | translate}}"
                            [thin]="true"
                            [light]="true"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                    <div class="da-other-sondertext">
                        <div class="da-other-headline">
                            <svg-icon src="assets/images/shieldHalved.svg"></svg-icon>
                            <lib-header-text
                                class="da-other-header"
                                size="16px"
                                [convertMobile]="false"
                                [light]="true"
                                label="{{'HEADER.SECURITY.PARAGRAPHS.HEADER4' | translate}}"
                            ></lib-header-text>
                        </div>
                        <lib-tile-text
                            class="da-other-text"
                            label="{{'HEADER.SECURITY.PARAGRAPHS.TEXT4' | translate}}"
                            [thin]="true"
                            [light]="true"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                    <div class="da-other-sondertext">
                        <div class="da-other-headline">
                            <svg-icon src="assets/images/eyeSlash.svg"></svg-icon>
                            <lib-header-text
                                class="da-other-header"
                                size="16px"
                                [convertMobile]="false"
                                [light]="true"
                                label="{{'HEADER.SECURITY.PARAGRAPHS.HEADER5' | translate}}"
                            ></lib-header-text>
                        </div>
                        <lib-tile-text
                            class="da-other-text"
                            label="{{'HEADER.SECURITY.PARAGRAPHS.TEXT5' | translate}}"
                            [thin]="true"
                            [light]="true"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                    <div class="da-delete-wrapper">
                        <lib-tile-text
                            size="24px"
                            [convertMobile]="true"
                            [light]="true"
                            label="{{'HEADER.SECURITY.SUB_HEADER' | translate }}"
                        ></lib-tile-text>
                        <div class="da-delete-row">
                            <div class="da-delete-checkbox">
                                <lib-toggle-checkbox 
                                    name="agreeDelete"
                                    label="" 
                                    [light]="true"
                                    (change)="setDelete($event)"
                                ></lib-toggle-checkbox>
                            </div>
                            <lib-tile-text
                                size="16px"
                                [convertMobile]="true"
                                [light]="true"
                                [thin]="true"
                                label="{{'HEADER.SECURITY.TEXT' | translate }}"
                            ></lib-tile-text>
                        </div>
                    </div>
                    <div class="da-delete-button-container">
                        <lib-button 
                            name="delete button"
                            value="{{'HEADER.SECURITY.BUTTON' | translate}}" 
                            [light]="true"
                            width="230"
                            [disabled]="!agreed"
                            (buttonValue)="deleteAccount()"
                            iconRight="assets/images/trashXmark.svg"
                        ></lib-button>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>