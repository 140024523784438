<div class="da-master">
    <button 
        (click)="open(digitalAssistantC, 'digitalAssistantC')" 
        id="start-digital-assistant"> test c
    </button>
    <ng-template #digitalAssistantC let-modal>
        <app-frame webappType="c"></app-frame>
    </ng-template>
    <div #style></div>
</div>
