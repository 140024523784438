<lib-header-text
    class="da-title resultate-title"
    label="{{headerText | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<app-warning-bar 
    *ngIf="inComplete && (contentService.getValue('results') && contentService.getValue('results').length > 0)" 
    [showButton]="showButton"
    [text]="incompleteText"
    [buttonText]="incompleteButton"
    type="incomplete"
    (slideTo)="slideTo.emit($event)"
></app-warning-bar>
<app-warning-bar 
    [showButton]="showButton"
    [ngStyle]="{'z-index': contentService.getValue('expressSent') ? '12' : 'inherited', 'position': 'relative'}"
    [text]="uploadText"
    [buttonText]="uploadButton"
    type="upload"
    (uploadScreen)="screen.openInfo(overview)"
></app-warning-bar>
<div class="da-bausteine-container resultate" [ngClass]="{ 'isSmallest' : screen.isSmallest() }">
    <ng-container *ngIf="ergebnisse && ergebnisse.length > 0">
        <ng-container *ngFor="let ergebnis of ergebnisse; let num = index">
            <div 
                class="da-resultat-container" 
                *ngIf="num >= pageStart && num < pageEnd" 
                [ngClass]="{ 'isSmallest' : screen.isSmallest() }"
                [ngStyle]="{'z-index': contentService.getValue('expressSent') ? '12' : 'inherited', 'position': 'relative'}">
                <!-- DESKTOP -->
                <!-- header-row -->
                <div class="da-container" *ngIf="!screen.isSmallest()" [ngClass]="{ 'is920' : screen.isSecondResultBreakpoint()}">
                    <!--mehrere Bausteine-->
                    <ng-container *ngIf="ergebnis.darlehen.length > 1">
                        <div class="da-container-header-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                            <div class="da-container-part">
                                <!-- <div class="da-container-reservieren" [ngClass]="{ 'is1024' : screen.isMobileOrSmallTablet() }">
                                <svg-icon 
                                        class="da-reservieren-icon" 
                                        (click)="switchMeta(ergebnis.id, 'reserved')"
                                        [ngClass]="{'red' : ergebnis.isReserved}"
                                        src="assets/images/heart.svg">
                                    </svg-icon>
                                    <lib-tile-text
                                        class="da-button-text"
                                        size="12px"
                                        [thin]="true"
                                        [convertMobile]="false"
                                        [infoSmall]="true"
                                        info="true"
                                        (infoValue)="screen.openInfo(reserveInfo)"
                                        label="{{'FINANCE_RESULTATE.LABELS.RESERVE' | translate}}"
                                    ></lib-tile-text> 
                                </div> -->
                                <div class="da-container-bausteine">
                                    <div class="da-container-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet(), 'is920' : screen.isSecondResultBreakpoint() }">
                                        <!-- logos -->
                                        <div class="da-container-baustein logos" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                                            <svg-icon 
                                            *ngFor="let item of ergebnis.logos"
                                            class="da-external-svg" 
                                            [src]="item.link"></svg-icon>
                                        </div>
                                        <div class="da-container-bausteine-wrapper" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet(), 'is920' : screen.isSecondResultBreakpoint() }">
                                            <!-- sollzins -->
                                            <app-container-baustein 
                                                item="Ø {{ergebnis.mischzins}} % p.a." 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.SOLLZINS_ALL' | translate}}"
                                                (info)="screen.openInfo(sollzinsInfo)"
                                                >
                                            </app-container-baustein>
                                            <!-- rate -->
                                            <app-container-baustein 
                                                item="{{ergebnis.gesamtrate}} €" 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.RATE_ALL' | translate}}"
                                                (info)="screen.openInfo(rateInfo)"
                                                >
                                            </app-container-baustein>
                                            <!-- tilgung -->
                                            <app-container-baustein 
                                                item="{{ergebnis.gesamttilgung}} %" 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_ALL' | translate}}"
                                                (info)="screen.openInfo(tilgungInfo)"
                                                >
                                            </app-container-baustein>
                                            <!-- jahre -->
                                            <app-container-baustein 
                                                item="{{ergebnis.gesamtzinsbindung}} J." 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.ZINSBINDUNG_ALL' | translate}}"
                                                (info)="screen.openInfo(zinsbindungInfo)"
                                                >
                                            </app-container-baustein>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="da-container-part end" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                                <!-- Button -->
                                <div class="da-container-baustein-button">
                                    <lib-button 
                                        name="weiter button"
                                        value="{{'FINANCE_RESULTATE.BUTTONS.EXPRESS' | translate}}" 
                                        (buttonValue)="getExpressAccess(ergebnis.id, expressInput)"
                                        iconRight="assets/images/chevronRight.svg"
                                        width="200"
                                        [disabled]="inComplete || contentService.getValue('expressSent')"
                                    ></lib-button>
                                </div>
                            </div>  
                        </div>
                    </ng-container>
                    <!-- reservieren -->
                    <div class="da-container-single-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                        <div class="da-container-part">
                            <!-- <div class="da-container-reservieren" [ngClass]="{ 'is1024' : screen.isMobileOrSmallTablet() }">
                            <svg-icon 
                                *ngIf="ergebnis.darlehen.length === 1"
                                class="da-reservieren-icon" 
                                (click)="switchMeta(ergebnis.id, 'reserved')"
                                [ngClass]="{'red' : ergebnis.isReserved}"
                                src="assets/images/heart.svg">
                            </svg-icon>
                            <lib-tile-text
                                *ngIf="ergebnis.darlehen.length === 1"
                                class="da-button-text"
                                size="12px"
                                [thin]="true"
                                [convertMobile]="false"
                                [infoSmall]="true"
                                info="true"
                                (infoValue)="screen.openInfo(reserveInfo)"
                                label="{{'FINANCE_RESULTATE.LABELS.RESERVE' | translate}}"
                            ></lib-tile-text> 
                            </div> -->
                            <div class="da-container-bausteine">
                                <div class="da-container-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'isMultiple' : ergebnis.darlehen.length > 1, 'is920' : screen.isSecondResultBreakpoint() }" *ngFor="let item of ergebnis.darlehen">
                                    <!-- logo -->
                                    <div class="da-container-baustein logo" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                                        <svg-icon class="da-external-svg" [src]="item.bankLogo"></svg-icon>
                                    </div>
                                    <div class="da-container-bausteine-wrapper" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet(), 'is920' : screen.isSecondResultBreakpoint() }">
                                        <!-- empfehlen -->
                                        <!-- <div class="da-container-baustein" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                                            <star-rating 
                                                [starType]="'svg'"
                                                size="medium"
                                                [numOfStars]="3" 
                                                [rating]="item.rating"
                                                [hoverEnabled]="true"
                                                (starClickChange)="getValue($event)"
                                                ></star-rating>
                                            <lib-tile-text
                                                class="da-button-text"
                                                size="12px"
                                                [thin]="true"
                                                [convertMobile]="false"
                                                [infoSmall]="true"
                                                info="true"
                                                (infoValue)="screen.openInfo(recommendInfo)"
                                                label="{{'FINANCE_RESULTATE.LABELS.RECOMMEND' | translate}}"
                                            ></lib-tile-text> 
                                        </div> -->
                                        <!-- sollzins -->
                                        <app-container-baustein 
                                            item="{{item.sollZins}} % p.a." 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.SOLLZINS' | translate}}"
                                            (info)="screen.openInfo(sollzinsInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- rate -->
                                        <app-container-baustein 
                                            item="{{item.rateMonatlich}} €" 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.RATE' | translate}}"
                                            (info)="screen.openInfo(rateInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- tilgung -->
                                        <app-container-baustein 
                                            item="{{item.anfaenglicheTilgung}} %" 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG' | translate}}"
                                            (info)="screen.openInfo(tilgungInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- jahre -->
                                        <app-container-baustein 
                                            item="{{item.zinsBindung}} J." 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.ZINSBINDUNG' | translate}}"
                                            (info)="screen.openInfo(zinsbindungInfo)"
                                            >
                                        </app-container-baustein>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="da-container-part end" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                            <!-- Button -->
                            <div class="da-container-baustein-button">
                                <lib-button 
                                    *ngIf="ergebnis.darlehen.length === 1"
                                    name="weiter button"
                                    value="{{'FINANCE_RESULTATE.BUTTONS.EXPRESS' | translate}}" 
                                    (buttonValue)="getExpressAccess(ergebnis.id, expressInput)"
                                    iconRight="assets/images/chevronRight.svg"
                                    width="200"
                                    [disabled]="inComplete || contentService.getValue('expressSent')"
                                ></lib-button>
                            </div>
                            <div class="da-bausteine-accordion-button" (click)="switchMeta(ergebnis.id, 'visible')">
                                <svg-icon *ngIf="ergebnis.isVisible" src="assets/images/chevronUp.svg"></svg-icon>
                                <svg-icon *ngIf="!ergebnis.isVisible" src="assets/images/chevronDown.svg"></svg-icon>
                            </div>
                        </div> 
                    </div>               
                </div>

                <!-- MOBILE -->
                <!-- header-row -->
                <div class="da-container-mobile" *ngIf="screen.isSmallest()">
                    <!--mehrere Bausteine-->
                    <ng-container *ngIf="ergebnis.darlehen.length > 1">
                        <div class="da-container-header-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                            <div class="da-container-part top">
                                <!-- <div class="da-container-reservieren" [ngClass]="{ 'is1024' : screen.isMobileOrSmallTablet() }">
                                <svg-icon 
                                        class="da-reservieren-icon" 
                                        (click)="switchMeta(ergebnis.id, 'reserved')"
                                        [ngClass]="{'red' : ergebnis.isReserved}"
                                        src="assets/images/heart.svg">
                                    </svg-icon>
                                    <lib-tile-text
                                        class="da-button-text"
                                        size="12px"
                                        [thin]="true"
                                        [convertMobile]="false"
                                        [infoSmall]="true"
                                        info="true"
                                        (infoValue)="screen.openInfo(reserveInfo)"
                                        label="{{'FINANCE_RESULTATE.LABELS.RESERVE' | translate}}"
                                    ></lib-tile-text> 
                                </div> -->
                                <!-- logos -->
                                <div class="da-container-baustein logos" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                                    <svg-icon 
                                    *ngFor="let item of ergebnis.logos"
                                    class="da-external-svg" 
                                    [src]="item.link"></svg-icon>
                                </div>
                            </div>
                            <div class="da-container-part">
                                <div class="da-container-bausteine">
                                    <!-- sollzins -->
                                    <app-container-baustein 
                                        item="Ø {{ergebnis.mischzins}} % p.a." 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.SOLLZINS_ALL' | translate}}"
                                        (info)="screen.openInfo(sollzinsInfo)"
                                        >
                                    </app-container-baustein>
                                    <!-- rate -->
                                    <app-container-baustein 
                                        item="{{ergebnis.gesamtrate}} €" 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.RATE_ALL' | translate}}"
                                        (info)="screen.openInfo(rateInfo)"
                                        >
                                    </app-container-baustein>
                                    <!-- tilgung -->
                                    <app-container-baustein 
                                        item="{{ergebnis.gesamttilgung}} %" 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_ALL' | translate}}"
                                        (info)="screen.openInfo(tilgungInfo)"
                                        >
                                    </app-container-baustein>
                                    <!-- jahre -->
                                    <app-container-baustein 
                                        item="{{ergebnis.gesamtzinsbindung}} J." 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.ZINSBINDUNG_ALL' | translate}}"
                                        (info)="screen.openInfo(zinsbindungInfo)"
                                        >
                                    </app-container-baustein>
                                </div>
                            </div>
                            <div class="da-container-part bottom">
                                <!-- Button -->
                                <div class="da-container-baustein-button">
                                    <lib-button 
                                        name="weiter button"
                                        value="{{'FINANCE_RESULTATE.BUTTONS.EXPRESS' | translate}}" 
                                        (buttonValue)="getExpressAccess(ergebnis.id, expressInput)"
                                        iconRight="assets/images/chevronRight.svg"
                                        width="300"
                                        [disabled]="inComplete || contentService.getValue('expressSent')"
                                    ></lib-button>
                                </div>
                            </div>  
                        </div>
                    </ng-container>
                    <!-- reservieren -->
                    <div class="da-container-single-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }" *ngFor="let item of ergebnis.darlehen">
                        <div class="da-container-part-top">
                            <!-- <div class="da-container-reservieren" *ngIf="ergebnis.darlehen.length === 1" [ngClass]="{ 'is1024' : screen.isMobileOrSmallTablet() }">
                                <svg-icon 
                                    class="da-reservieren-icon" 
                                    (click)="switchMeta(ergebnis.id, 'reserved')"
                                    [ngClass]="{'red' : ergebnis.isReserved}"
                                    src="assets/images/heart.svg">
                                </svg-icon>
                                <lib-tile-text
                                    class="da-button-text"
                                    size="12px"
                                    [thin]="true"
                                    [convertMobile]="false"
                                    [infoSmall]="true"
                                    info="true"
                                    (infoValue)="screen.openInfo(reserveInfo)"
                                    label="{{'FINANCE_RESULTATE.LABELS.RESERVE' | translate}}"
                                ></lib-tile-text> 
                            </div> -->
                            <!-- logo -->
                            <div class="da-container-baustein" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                                <svg-icon class="da-external-svg" [src]="item.bankLogo"></svg-icon>
                            </div>
                            <!-- empfehlen -->
                            <!-- <div class="da-container-baustein" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                                <star-rating 
                                    [starType]="'svg'"
                                    size="medium"
                                    [numOfStars]="3" 
                                    [rating]="item.rating"
                                    [hoverEnabled]="true"
                                    (starClickChange)="getValue($event)"
                                    ></star-rating>
                                <lib-tile-text
                                    class="da-button-text"
                                    size="12px"
                                    [thin]="true"
                                    [convertMobile]="false"
                                    [infoSmall]="true"
                                    info="true"
                                    (infoValue)="screen.openInfo(recommendInfo)"
                                    label="{{'FINANCE_RESULTATE.LABELS.RECOMMEND' | translate}}"
                                ></lib-tile-text> 
                            </div> -->
                        </div>
                        <div class="da-container-part-bottom">
                            <app-container-baustein 
                                item="{{item.sollZins}} % p.a." 
                                itemText="{{'FINANCE_RESULTATE.LABELS.SOLLZINS' | translate}}"
                                (info)="screen.openInfo(sollzinsInfo)"
                                >
                            </app-container-baustein>
                            <app-container-baustein 
                                item="{{item.zinsBindung}} J." 
                                itemText="{{'FINANCE_RESULTATE.LABELS.ZINSBINDUNG' | translate}}"
                                (info)="screen.openInfo(zinsbindungInfo)"
                                >
                            </app-container-baustein>
                            <app-container-baustein 
                                item="{{item.rateMonatlich}} €" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.RATE' | translate}}"
                                (info)="screen.openInfo(rateInfo)"
                                >
                            </app-container-baustein>
                            <app-container-baustein 
                                item="{{item.anfaenglicheTilgung}} %" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG' | translate}}"
                                (info)="screen.openInfo(tilgungInfo)"
                                >
                            </app-container-baustein>
                            <div class="da-container-baustein-button">
                                <lib-button 
                                    *ngIf="ergebnis.darlehen.length === 1"
                                    name="weiter button"
                                    value="{{'FINANCE_RESULTATE.BUTTONS.EXPRESS' | translate}}" 
                                    (buttonValue)="getExpressAccess(ergebnis.id, expressInput)"
                                    iconRight="assets/images/chevronRight.svg"
                                    width="300"
                                    [disabled]="inComplete || contentService.getValue('expressSent')"
                                ></lib-button>
                            </div>
                        </div>
                        <div class="da-container-part">
                            <div 
                                class="da-bausteine-accordion-button" 
                                (click)="switchMeta(ergebnis.id, 'visible')"
                                *ngIf="ergebnis.darlehen.length === 1 || item.id === ergebnis.darlehen.length - 1"
                                >
                                <svg-icon *ngIf="ergebnis.isVisible" src="assets/images/chevronUp.svg"></svg-icon>
                                <svg-icon *ngIf="!ergebnis.isVisible" src="assets/images/chevronDown.svg"></svg-icon>
                            </div>
                        </div> 
                    </div>               
                </div>

                <!-- DESKTOP -->
                <!-- DETAILS -->
                <div 
                    class="da-container-body"
                    *ngIf="ergebnis.isVisible && !screen.isSmallest()">
                    <!-- EIN BAUSTEIN -->
                    <ng-container *ngIf="ergebnis.darlehen.length === 1">
                        <ng-container *ngFor="let item of ergebnis.darlehen">
                            <div class="da-container-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                                <div class="da-container-inrow-row">
                                    <!-- darlehen -->
                                    <app-container-baustein 
                                        item="{{item.darlehensBetrag}} €" 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.DARLEHEN' | translate}}"
                                        (info)="screen.openInfo(darlehenInfo)"
                                        >
                                    </app-container-baustein>
                                    <!-- Zins -->
                                    <app-container-baustein 
                                        item="{{item.effektivZins}} % p.a." 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.ZINS' | translate}}"
                                        (info)="screen.openInfo(zinsInfo)"
                                        >
                                    </app-container-baustein>
                                    <!-- Restschuld -->
                                    <app-container-baustein 
                                        item="{{item.restSchuld}} €" 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.RESTSCHULD' | translate: {jahre: item.zinsBindung} }}"
                                        (info)="screen.openInfo(restschuldInfo)"
                                        >
                                    </app-container-baustein>
                                    <!-- Laufzeit -->
                                    <app-container-baustein 
                                        item="{{item.laufzeit}} J." 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.LAUFZEIT' | translate}}"
                                        (info)="screen.openInfo(laufzeitInfo)"
                                        >
                                    </app-container-baustein>
                                    <!-- Gueltig -->
                                    <app-container-baustein 
                                        item="{{item.dateGueltig}}" 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.DATE_GUELTIG' | translate}}"
                                        (info)="screen.openInfo(gueltigInfo)"
                                        >
                                    </app-container-baustein>
                                </div>
                                <div class="da-container-inrow-row">
                                    <!-- tilgung -->
                                    <app-container-baustein 
                                        item="{{item.optionaleTilgung}} % p.a." 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_OPTIONAL' | translate}}"
                                        (info)="screen.openInfo(tilgungOptionalInfo)"
                                        >
                                    </app-container-baustein>
                                    <!-- monate -->
                                    <app-container-baustein 
                                        item="{{item.zinsfreieMonate}}" 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.ZINSFREI' | translate}}"
                                        (info)="screen.openInfo(zinsfreiInfo)"
                                        >
                                    </app-container-baustein>
                                    <!-- wechsel -->
                                    <app-container-baustein 
                                        item="{{item.wechselTilgung}}" 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_WECHSEL' | translate}}"
                                        (info)="screen.openInfo(tilgungWechselInfo)"
                                        >
                                    </app-container-baustein>
                                    <!-- bearbeitung -->
                                    <app-container-baustein 
                                        item="{{item.dauer}}" 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.DAUER' | translate}}"
                                        (info)="screen.openInfo(dauerInfo)"
                                        >
                                    </app-container-baustein>
                                    <!-- tilgung beginn -->
                                    <app-container-baustein 
                                        item="{{item.beginnTilgung}}" 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_DATE' | translate}}"
                                        (info)="screen.openInfo(tilgungDauerInfo)"
                                        >
                                    </app-container-baustein>
                                </div>
                                <div class="da-container-inrow-row">
                                    <!-- type -->
                                    <app-container-baustein 
                                        item="{{item.typ}}" 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.TYP.LABEL' | translate}}"
                                        (info)="screen.openInfo(typInfo)"
                                        >
                                    </app-container-baustein>
                                    <!-- name -->
                                    <app-container-baustein 
                                        item="{{item.bankName}}" 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.NAME' | translate}}"
                                        (info)="screen.openInfo(nameInfo)"
                                        >
                                    </app-container-baustein>
                                    <!-- hinweis -->
                                    <app-container-baustein 
                                        [ngClass]="'long'"
                                        item="{{'FINANCE_RESULTATE.LABELS.TIP' | translate}}" 
                                        itemText="{{'FINANCE_RESULTATE.LABELS.TIP_TEXT' | translate: {date: item.hinweisDate} }}"
                                        showInfo="false"
                                        >
                                    </app-container-baustein>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                        <!-- MEHRERE BAUSTEINE -->
                    <ng-container *ngIf="ergebnis.darlehen.length > 1">
                        <app-index 
                            [tiles]="ergebnis.fakeTiles" 
                            [selectedTile]="ergebnis.selectedTile"
                            [slideFactor]="tileSlideFactor"
                            [slidePos]="ergebnis.tilePosition"
                            (indexValue)="getIndex(ergebnis.id, $event, ergebnis.fakeTiles, ergebnis.tilePosition)">
                        </app-index>
                        <div class="da-content-extended">
                            <div class="da-content-extended-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest(), 'small': screen.isSmallerThanChildContainer()}">
                                <div 
                                    class="da-tiles-wrapper" 
                                    [ngClass]="{'mobile-wrap' : screen.isSmallest()}"
                                    [ngStyle]="{'right': 'calc(-'+ screenPosition +' + '+ ergebnis.tilePosition +'px)'}">
                                    <div class="da-container-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }" *ngFor="let item of ergebnis.darlehen">
                                        <div class="da-container-inrow-row">
                                            <!-- darlehen -->
                                            <app-container-baustein 
                                                item="{{item.darlehensBetrag}} €" 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.DARLEHEN' | translate}}"
                                                (info)="screen.openInfo(darlehenInfo)"
                                                >
                                            </app-container-baustein>
                                            <!-- Zins -->
                                            <app-container-baustein 
                                                item="{{item.effektivZins}} % p.a." 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.ZINS' | translate}}"
                                                (info)="screen.openInfo(zinsInfo)"
                                                >
                                            </app-container-baustein>
                                            <!-- Restschuld -->
                                            <app-container-baustein 
                                                item="{{item.restSchuld}} €" 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.RESTSCHULD' | translate: {jahre: item.zinsBindung} }}"
                                                (info)="screen.openInfo(restschuldInfo)"
                                                >
                                            </app-container-baustein>
                                            <!-- Laufzeit -->
                                            <app-container-baustein 
                                                item="{{item.laufzeit}} J." 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.LAUFZEIT' | translate}}"
                                                (info)="screen.openInfo(laufzeitInfo)"
                                                >
                                            </app-container-baustein>
                                            <!-- Gueltig -->
                                            <app-container-baustein 
                                                item="{{item.dateGueltig}}" 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.DATE_GUELTIG' | translate}}"
                                                (info)="screen.openInfo(gueltigInfo)"
                                                >
                                            </app-container-baustein>
                                        </div>
                                        <div class="da-container-inrow-row">
                                            <!-- tilgung -->
                                            <app-container-baustein 
                                                item="{{item.optionaleTilgung}} % p.a." 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_OPTIONAL' | translate}}"
                                                (info)="screen.openInfo(tilgungOptionalInfo)"
                                                >
                                            </app-container-baustein>
                                            <!-- monate -->
                                            <app-container-baustein 
                                                item="{{item.zinsfreieMonate}}" 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.ZINSFREI' | translate}}"
                                                (info)="screen.openInfo(zinsfreiInfo)"
                                                >
                                            </app-container-baustein>
                                            <!-- wechsel -->
                                            <app-container-baustein 
                                                item="{{item.wechselTilgung}}" 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_WECHSEL' | translate}}"
                                                (info)="screen.openInfo(tilgungWechselInfo)"
                                                >
                                            </app-container-baustein>
                                            <!-- bearbeitung -->
                                            <app-container-baustein 
                                                item="{{item.dauer}}" 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.DAUER' | translate}}"
                                                (info)="screen.openInfo(dauerInfo)"
                                                >
                                            </app-container-baustein>
                                            <!-- tilgung beginn -->
                                            <app-container-baustein 
                                                item="{{item.beginnTilgung}}" 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_DATE' | translate}}"
                                                (info)="screen.openInfo(tilgungDauerInfo)"
                                                >
                                            </app-container-baustein>
                                        </div>
                                        <div class="da-container-inrow-row">
                                            <!-- type -->
                                            <app-container-baustein 
                                                item="{{item.typ}}" 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.TYP.LABEL' | translate}}"
                                                (info)="screen.openInfo(typInfo)"
                                                >
                                            </app-container-baustein>
                                            <!-- name -->
                                            <app-container-baustein 
                                                item="{{item.bankName}}" 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.NAME' | translate}}"
                                                (info)="screen.openInfo(nameInfo)"
                                                >
                                            </app-container-baustein>
                                            <!-- hinweis -->
                                            <app-container-baustein 
                                                [ngClass]="'long'"
                                                item="{{'FINANCE_RESULTATE.LABELS.TIP' | translate}}" 
                                                itemText="{{'FINANCE_RESULTATE.LABELS.TIP_TEXT' | translate: {date: item.hinweisDate} }}"
                                                showInfo="false"
                                                >
                                            </app-container-baustein>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="da-container-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                        <div class="da-container-inrow-row docs">
                            <div class="da-results-docs-wrapper" (click)="docDownload('type1')">
                                <svg-icon class="da-results-docs-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <lib-tile-text
                                    class="da-button-text"
                                    size="16px"
                                    [convertMobile]="false"
                                    label="{{'FINANCE_RESULTATE.DOCUMENTS.DOC1' | translate}}"
                                ></lib-tile-text> 
                            </div>
                            <div class="da-results-docs-wrapper" (click)="docDownload('type2')">
                                <svg-icon class="da-results-docs-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <lib-tile-text
                                    class="da-button-text"
                                    size="16px"
                                    [convertMobile]="false"
                                    label="{{'FINANCE_RESULTATE.DOCUMENTS.DOC2' | translate}}"
                                ></lib-tile-text> 
                            </div>
                            <div class="da-results-docs-wrapper" (click)="docDownload('type3')">
                                <svg-icon class="da-results-docs-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <lib-tile-text
                                    class="da-button-text"
                                    size="16px"
                                    [convertMobile]="false"
                                    label="{{'FINANCE_RESULTATE.DOCUMENTS.DOC3' | translate}}"
                                ></lib-tile-text> 
                            </div>
                            <div class="da-results-docs-wrapper" (click)="docDownload('type4')">
                                <svg-icon class="da-results-docs-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <lib-tile-text
                                    class="da-button-text"
                                    size="16px"
                                    [convertMobile]="false"
                                    label="{{'FINANCE_RESULTATE.DOCUMENTS.DOC4' | translate}}"
                                ></lib-tile-text> 
                            </div>
                            <!-- <div class="da-results-docs-wrapper" (click)="docDownload('type5')">
                                <svg-icon class="da-results-docs-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <lib-tile-text
                                    class="da-button-text"
                                    size="16px"
                                    [convertMobile]="false"
                                    label="{{'FINANCE_RESULTATE.DOCUMENTS.DOC5' | translate}}"
                                ></lib-tile-text> 
                            </div> -->
                        </div>
                    </div>
                </div>
            
                <!-- MOBILE -->
                <!-- DETAILS -->
                <div 
                    class="da-container-body-mobile"
                    *ngIf="ergebnis.isVisible && screen.isSmallest()">
                    <!-- EIN BAUSTEIN -->
                    <ng-container *ngIf="ergebnis.darlehen.length === 1">
                        <ng-container *ngFor="let item of ergebnis.darlehen">
                            <div class="da-container-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                                <!-- darlehen -->
                                <app-container-baustein 
                                    item="{{item.darlehensBetrag}} €" 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.DARLEHEN' | translate}}"
                                    (info)="screen.openInfo(darlehenInfo)"
                                    >
                                </app-container-baustein>
                                <!-- Zins -->
                                <app-container-baustein 
                                    item="{{item.effektivZins}} % p.a." 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.ZINS' | translate}}"
                                    (info)="screen.openInfo(zinsInfo)"
                                    >
                                </app-container-baustein>
                                <!-- Restschuld -->
                                <app-container-baustein 
                                    item="{{item.restSchuld}} €" 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.RESTSCHULD' | translate: {jahre: item.zinsBindung} }}"
                                    (info)="screen.openInfo(restschuldInfo)"
                                    >
                                </app-container-baustein>
                                <!-- Laufzeit -->
                                <app-container-baustein 
                                    item="{{item.laufzeit}} J." 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.LAUFZEIT' | translate}}"
                                    (info)="screen.openInfo(laufzeitInfo)"
                                    >
                                </app-container-baustein>
                                <!-- Gueltig -->
                                <app-container-baustein 
                                    item="{{item.dateGueltig}}" 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.DATE_GUELTIG' | translate}}"
                                    (info)="screen.openInfo(gueltigInfo)"
                                    >
                                </app-container-baustein>
                                <!-- tilgung -->
                                <app-container-baustein 
                                    item="{{item.optionaleTilgung}} % p.a." 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_OPTIONAL' | translate}}"
                                    (info)="screen.openInfo(tilgungOptionalInfo)"
                                    >
                                </app-container-baustein>
                                <!-- monate -->
                                <app-container-baustein 
                                    item="{{item.zinsfreieMonate}}" 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.ZINSFREI' | translate}}"
                                    (info)="screen.openInfo(zinsfreiInfo)"
                                    >
                                </app-container-baustein>
                                <!-- wechsel -->
                                <app-container-baustein 
                                    item="{{item.wechselTilgung}}" 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_WECHSEL' | translate}}"
                                    (info)="screen.openInfo(tilgungWechselInfo)"
                                    >
                                </app-container-baustein>
                                <!-- bearbeitung -->
                                <app-container-baustein 
                                    item="{{item.dauer}}" 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.DAUER' | translate}}"
                                    (info)="screen.openInfo(dauerInfo)"
                                    >
                                </app-container-baustein>
                                <!-- tilgung beginn -->
                                <app-container-baustein 
                                    item="{{item.beginnTilgung}}" 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_DATE' | translate}}"
                                    (info)="screen.openInfo(tilgungDauerInfo)"
                                    >
                                </app-container-baustein>
                                <!-- type -->
                                <app-container-baustein 
                                    [ngClass]="'long'"
                                    item="{{item.typ}}" 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.TYP.LABEL' | translate}}"
                                    (info)="screen.openInfo(typInfo)"
                                    >
                                </app-container-baustein>
                                <!-- name -->
                                <app-container-baustein 
                                    [ngClass]="'long'"
                                    item="{{item.bankName}}" 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.NAME' | translate}}"
                                    (info)="screen.openInfo(nameInfo)"
                                    >
                                </app-container-baustein>
                                <!-- hinweis -->
                                <app-container-baustein 
                                    [ngClass]="'long'"
                                    item="{{'FINANCE_RESULTATE.LABELS.TIP' | translate}}" 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.TIP_TEXT' | translate: {date: item.hinweisDate} }}"
                                    showInfo="false"
                                    >
                                </app-container-baustein>
                            </div>
                        </ng-container>
                    </ng-container>

                    <!-- MEHRERE BAUSTEINE -->
                    <ng-container *ngIf="ergebnis.darlehen.length > 1">
                        <app-index 
                            [tiles]="ergebnis.fakeTiles" 
                            [selectedTile]="ergebnis.selectedTile"
                            [slideFactor]="tileSlideFactor"
                            [slidePos]="ergebnis.tilePosition"
                            (indexValue)="getIndex(ergebnis.id, $event, ergebnis.fakeTiles, ergebnis.tilePosition)">
                        </app-index>
                        <div class="da-content-extended">
                            <div class="da-content-extended-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest(), 'small': screen.isSmallerThanChildContainer()}">
                                <div 
                                    class="da-tiles-wrapper" 
                                    [ngClass]="{'mobile-wrap' : screen.isSmallest()}"
                                    [ngStyle]="{'right': 'calc(-'+ screenPosition +' + '+ ergebnis.tilePosition +'px)'}">
                                    <div class="da-container-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }" *ngFor="let item of ergebnis.darlehen">
                                        <!-- darlehen -->
                                        <app-container-baustein 
                                            item="{{item.darlehensBetrag}} €" 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.DARLEHEN' | translate}}"
                                            (info)="screen.openInfo(darlehenInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- Zins -->
                                        <app-container-baustein 
                                            item="{{item.effektivZins}} % p.a." 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.ZINS' | translate}}"
                                            (info)="screen.openInfo(zinsInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- Restschuld -->
                                        <app-container-baustein 
                                            item="{{item.restSchuld}} €" 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.RESTSCHULD' | translate: {jahre: item.zinsBindung} }}"
                                            (info)="screen.openInfo(restschuldInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- Laufzeit -->
                                        <app-container-baustein 
                                            item="{{item.laufzeit}} J." 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.LAUFZEIT' | translate}}"
                                            (info)="screen.openInfo(laufzeitInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- Gueltig -->
                                        <app-container-baustein 
                                            item="{{item.dateGueltig}}" 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.DATE_GUELTIG' | translate}}"
                                            (info)="screen.openInfo(gueltigInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- tilgung -->
                                        <app-container-baustein 
                                            item="{{item.optionaleTilgung}} % p.a." 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_OPTIONAL' | translate}}"
                                            (info)="screen.openInfo(tilgungOptionalInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- monate -->
                                        <app-container-baustein 
                                            item="{{item.zinsfreieMonate}}" 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.ZINSFREI' | translate}}"
                                            (info)="screen.openInfo(zinsfreiInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- wechsel -->
                                        <app-container-baustein 
                                            item="{{item.wechselTilgung}}" 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_WECHSEL' | translate}}"
                                            (info)="screen.openInfo(tilgungWechselInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- bearbeitung -->
                                        <app-container-baustein 
                                            item="{{item.dauer}}" 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.DAUER' | translate}}"
                                            (info)="screen.openInfo(dauerInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- tilgung beginn -->
                                        <app-container-baustein 
                                            item="{{item.beginnTilgung}}" 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_DATE' | translate}}"
                                            (info)="screen.openInfo(tilgungDauerInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- type -->
                                        <app-container-baustein 
                                            [ngClass]="'long'"
                                            item="{{item.typ}}" 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.TYP.LABEL' | translate}}"
                                            (info)="screen.openInfo(typInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- name -->
                                        <app-container-baustein 
                                            [ngClass]="'long'"
                                            item="{{item.bankName}}" 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.NAME' | translate}}"
                                            (info)="screen.openInfo(nameInfo)"
                                            >
                                        </app-container-baustein>
                                        <!-- hinweis -->
                                        <app-container-baustein 
                                            [ngClass]="'long'"
                                            item="{{'FINANCE_RESULTATE.LABELS.TIP' | translate}}" 
                                            itemText="{{'FINANCE_RESULTATE.LABELS.TIP_TEXT' | translate: {date: item.hinweisDate} }}"
                                            showInfo="false"
                                            >
                                        </app-container-baustein>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="da-container-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                        <div class="da-container-inrow-row docs">
                            <div class="da-results-docs-wrapper" (click)="docDownload('type1')">
                                <svg-icon class="da-results-docs-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <lib-tile-text
                                    class="da-button-text"
                                    size="16px"
                                    [convertMobile]="false"
                                    label="{{'FINANCE_RESULTATE.DOCUMENTS.DOC1' | translate}}"
                                ></lib-tile-text> 
                            </div>
                            <div class="da-results-docs-wrapper" (click)="docDownload('type2')">
                                <svg-icon class="da-results-docs-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <lib-tile-text
                                    class="da-button-text"
                                    size="16px"
                                    [convertMobile]="false"
                                    label="{{'FINANCE_RESULTATE.DOCUMENTS.DOC2' | translate}}"
                                ></lib-tile-text> 
                            </div>
                            <div class="da-results-docs-wrapper" (click)="docDownload('type3')">
                                <svg-icon class="da-results-docs-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <lib-tile-text
                                    class="da-button-text"
                                    size="16px"
                                    [convertMobile]="false"
                                    label="{{'FINANCE_RESULTATE.DOCUMENTS.DOC3' | translate}}"
                                ></lib-tile-text> 
                            </div>
                            <div class="da-results-docs-wrapper" (click)="docDownload('type4')">
                                <svg-icon class="da-results-docs-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <lib-tile-text
                                    class="da-button-text"
                                    size="16px"
                                    [convertMobile]="false"
                                    label="{{'FINANCE_RESULTATE.DOCUMENTS.DOC4' | translate}}"
                                ></lib-tile-text> 
                            </div>
                            <!-- <div class="da-results-docs-wrapper" (click)="docDownload('type5')">
                                <svg-icon class="da-results-docs-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <lib-tile-text
                                    class="da-button-text"
                                    size="16px"
                                    [convertMobile]="false"
                                    label="{{'FINANCE_RESULTATE.DOCUMENTS.DOC5' | translate}}"
                                ></lib-tile-text> 
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>



    <!-- KEINE RESULTATE -->
    <ng-container *ngIf="(!ergebnisse || ergebnisse.length == 0) && resultsDone" >
        <div class="da-no-results">{{'FINANCE_RESULTATE.NO_RESULTS' | translate}}</div> 
    </ng-container>
    <!-- LADEN -->
    <ng-container *ngIf="contentService.getValue('resultsLoading')">
        <app-loader text="FINANCE_RESULTATE.LOADER.TEXT" button="FINANCE_RESULTATE.LOADER.BUTTON" (abort)="stopResults()"></app-loader>
        <div class="da-no-results">Bitte warten ...</div> 
    </ng-container>
    <!-- PLACEHOLDER ERGEBNISSE -->
    <ng-container *ngIf="!contentService.getValue('resultsLoading') && !resultsDone && (!ergebnisse || ergebnisse.length == 0)">
        <!-- fake ergebnisse -->
        <ng-container *ngFor="let count of fakeErgebnisse">
            <!-- header-row -->
            <div class="da-container" *ngIf="!screen.isSmallest()">
                <!-- reservieren -->
                <div class="da-container-single-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                    <div class="da-container-part">
                        <!-- <div class="da-container-reservieren" [ngClass]="{ 'is1024' : screen.isMobileOrSmallTablet() }">
                        <svg-icon 
                            class="da-reservieren-icon" 
                            src="assets/images/heart.svg">
                        </svg-icon>
                        <lib-tile-text
                            class="da-button-text"
                            size="12px"
                            [thin]="true"
                            [convertMobile]="false"
                            label="{{'FINANCE_RESULTATE.LABELS.RESERVE' | translate}}"
                        ></lib-tile-text> 
                        </div> -->
                        <div class="da-container-bausteine">
                            <div class="da-container-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                                <!-- logo -->
                                <div class="da-container-baustein" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                                    <svg-icon class="da-external-svg" src="assets/images/ellipsis.svg"></svg-icon>
                                </div>
                                <!-- empfehlen -->
                                <!-- <div class="da-container-baustein" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                                    <star-rating 
                                        [starType]="'svg'"
                                        size="medium"
                                        [numOfStars]="3" 
                                        [rating]="2"
                                        [hoverEnabled]="true"
                                        (starClickChange)="getValue($event)"
                                        ></star-rating>
                                    <lib-tile-text
                                        class="da-button-text"
                                        size="12px"
                                        [thin]="true"
                                        [convertMobile]="false"
                                        label="{{'FINANCE_RESULTATE.LABELS.RECOMMEND' | translate}}"
                                    ></lib-tile-text> 
                                </div> -->
                                <!-- sollzins -->
                                <app-container-baustein 
                                    item="... % p.a." 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.SOLLZINS' | translate}}"
                                    (info)="screen.openInfo(sollzinsInfo)"
                                    >
                                </app-container-baustein>
                                <!-- rate -->
                                <app-container-baustein 
                                    item="... €" 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.RATE' | translate}}"
                                    (info)="screen.openInfo(rateInfo)"
                                    >
                                </app-container-baustein>
                                <!-- tilgung -->
                                <app-container-baustein 
                                    item="... %" 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG' | translate}}"
                                    (info)="screen.openInfo(tilgungInfo)"
                                    >
                                </app-container-baustein>
                                <!-- jahre -->
                                <app-container-baustein 
                                    item="... J." 
                                    itemText="{{'FINANCE_RESULTATE.LABELS.ZINSBINDUNG' | translate}}"
                                    (info)="screen.openInfo(zinsbindungInfo)"
                                    >
                                </app-container-baustein>
                            </div>
                        </div>
                    </div>
                    <div class="da-container-part">
                        <!-- Button -->
                        <div class="da-container-baustein-button">
                            <lib-button 
                                name="weiter button"
                                value="{{'FINANCE_RESULTATE.BUTTONS.EXPRESS' | translate}}" 
                                (buttonValue)="getExpressAccess(0, expressInput)"
                                iconRight="assets/images/chevronRight.svg"
                                width="200"
                                [disabled]="true"
                            ></lib-button>
                        </div>
                        <div class="da-bausteine-accordion-button">
                            <svg-icon  src="assets/images/chevronDown.svg"></svg-icon>
                        </div>
                    </div> 
                </div> 
            </div>
            <!-- MOBILE -->
            <div class="da-container-mobile" *ngIf="screen.isSmallest()">
                <!-- reservieren -->
                <div class="da-container-single-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                    <div class="da-container-part-top">
                        <!-- <div class="da-container-reservieren">
                            <svg-icon 
                                class="da-reservieren-icon" 
                                src="assets/images/heart.svg">
                            </svg-icon>
                            <lib-tile-text
                                class="da-button-text"
                                size="12px"
                                [thin]="true"
                                [convertMobile]="false"
                                [infoSmall]="true"
                                info="true"
                                (infoValue)="screen.openInfo(reserveInfo)"
                                label="{{'FINANCE_RESULTATE.LABELS.RESERVE' | translate}}"
                            ></lib-tile-text> 
                        </div> -->
                        <!-- logo -->
                        <div class="da-container-baustein" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                            <svg-icon class="da-external-svg" src="assets/images/ellipsis.svg"></svg-icon>
                        </div>
                        <!-- empfehlen -->
                        <!-- <div class="da-container-baustein" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                            <star-rating 
                                [starType]="'svg'"
                                size="medium"
                                [numOfStars]="3" 
                                [rating]="2"
                                [hoverEnabled]="false"
                                (starClickChange)="getValue($event)"
                                ></star-rating>
                            <lib-tile-text
                                class="da-button-text"
                                size="12px"
                                [thin]="true"
                                [convertMobile]="false"
                                [infoSmall]="true"
                                info="true"
                                (infoValue)="screen.openInfo(recommendInfo)"
                                label="{{'FINANCE_RESULTATE.LABELS.RECOMMEND' | translate}}"
                            ></lib-tile-text> 
                        </div> -->
                    </div>
                    <div class="da-container-part-bottom">
                        <app-container-baustein 
                            item="... % p.a." 
                            itemText="{{'FINANCE_RESULTATE.LABELS.SOLLZINS' | translate}}"
                            (info)="screen.openInfo(sollzinsInfo)"
                            >
                        </app-container-baustein>
                        <app-container-baustein 
                            item="... J." 
                            itemText="{{'FINANCE_RESULTATE.LABELS.ZINSBINDUNG' | translate}}"
                            (info)="screen.openInfo(zinsbindungInfo)"
                            >
                        </app-container-baustein>
                        <app-container-baustein 
                            item="... €" 
                            itemText="{{'FINANCE_RESULTATE.LABELS.RATE' | translate}}"
                            (info)="screen.openInfo(rateInfo)"
                            >
                        </app-container-baustein>
                        <app-container-baustein 
                            item="... %" 
                            itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG' | translate}}"
                            (info)="screen.openInfo(tilgungInfo)"
                            >
                        </app-container-baustein>
                        <div class="da-container-baustein-button">
                            <lib-button 
                                name="weiter button"
                                value="{{'FINANCE_RESULTATE.BUTTONS.EXPRESS' | translate}}" 
                                (buttonValue)="getExpressAccess(0, expressInput)"
                                iconRight="assets/images/chevronRight.svg"
                                width="200"
                                [disabled]="true"
                            ></lib-button>
                        </div>
                    </div>
                    <div class="da-container-part">
                        <div class="da-bausteine-accordion-button">
                            <svg-icon src="assets/images/chevronDown.svg"></svg-icon>
                        </div>
                    </div> 
                </div>
            </div>
        </ng-container>
    </ng-container>
    <!-- Page Button -->
    <ng-container *ngIf="ergebnisse && ergebnisse.length > pageFactor">
        <div class="da-page-button-wrapper" *ngIf="!screen.isSmallest()">
            <lib-button 
                name="pagezurueck button"
                value="{{'BUTTONS.BACK' | translate}}" 
                (buttonValue)="moveTo('back')"
                iconLeft="assets/images/chevronLeft.svg"
                [disabled]="pageNumber === 1"
            ></lib-button>
            <lib-tile-text
                class="da-button-text"
                size="16px"
                label="{{'FINANCE_RESULTATE.LABELS.PAGE' | translate: {number: pageNumber, gesamt: pageGesamt} }}"
            ></lib-tile-text>
            <lib-button 
                name="pageweiter button"
                value="{{'BUTTONS.FORWARD' | translate}}" 
                (buttonValue)="moveTo('next')"
                iconRight="assets/images/chevronRight.svg"
                [disabled]="pageNumber === pageGesamt"
            ></lib-button> 
        </div>
        <div class="da-page-button-wrapper-mobile" *ngIf="screen.isSmallest()">
            <lib-tile-text
                class="da-button-text"
                size="16px"
                label="{{'FINANCE_RESULTATE.LABELS.PAGE' | translate: {number: pageNumber, gesamt: pageGesamt} }}"
            ></lib-tile-text>
            <div class="da-page-button-wrapper">
                <lib-button 
                    name="pagezurueck button"
                    value="{{'BUTTONS.BACK' | translate}}" 
                    (buttonValue)="moveTo('back')"
                    iconLeft="assets/images/chevronLeft.svg"
                    [disabled]="pageNumber === 1"
                ></lib-button>
                <lib-button 
                    name="pageweiter button"
                    value="{{'BUTTONS.FORWARD' | translate}}" 
                    (buttonValue)="moveTo('next')"
                    iconRight="assets/images/chevronRight.svg"
                    [disabled]="pageNumber === pageGesamt"
                ></lib-button> 
            </div>
        </div>
    </ng-container>
    
</div>
<ng-template #reserveInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.RESERVE' | translate}}" text="{{'FINANCE_RESULTATE.INFO.RESERVE_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #recommendInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.RECOMMEND' | translate}}" text="{{'FINANCE_RESULTATE.INFO.RECOMMEND_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #sollzinsInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.SOLLZINS' | translate}}" text="{{'FINANCE_RESULTATE.INFO.SOLLZINS_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #rateInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.RATE' | translate}}" text="{{'FINANCE_RESULTATE.INFO.RATE_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #tilgungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.TILGUNG' | translate}}" text="{{'FINANCE_RESULTATE.INFO.TILGUNG_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #zinsbindungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.ZINSBINDUNG' | translate}}" text="{{'FINANCE_RESULTATE.INFO.ZINSBINDUNG_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #darlehenInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.DARLEHEN' | translate}}" text="{{'FINANCE_RESULTATE.INFO.DARLEHEN_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #zinsInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.ZINS' | translate}}" text="{{'FINANCE_RESULTATE.INFO.ZINS_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #restschuldInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.RESTSCHULD' | translate}}" text="{{'FINANCE_RESULTATE.INFO.RESTSCHULD_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #laufzeitInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.LAUFZEIT' | translate}}" text="{{'FINANCE_RESULTATE.INFO.LAUFZEIT_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #gueltigInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.DATE_GUELTIG' | translate}}" text="{{'FINANCE_RESULTATE.INFO.DATE_GUELTIG_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #tilgungOptionalInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.TILGUNG_OPTIONAL' | translate}}" text="{{'FINANCE_RESULTATE.INFO.TILGUNG_OPTIONAL_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #zinsfreiInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.ZINSFREI' | translate}}" text="{{'FINANCE_RESULTATE.INFO.ZINSFREI_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #tilgungWechselInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.TILGUNG_WECHSEL' | translate}}" text="{{'FINANCE_RESULTATE.INFO.TILGUNG_WECHSEL_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #dauerInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.DAUER' | translate}}" text="{{'FINANCE_RESULTATE.INFO.DAUER_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #tilgungDauerInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.TILGUNG_DATE' | translate}}" text="{{'FINANCE_RESULTATE.INFO.TILGUNG_DATE_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #typInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.TYP' | translate}}" text="{{'FINANCE_RESULTATE.INFO.TYP_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #nameInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_RESULTATE.INFO.NAME' | translate}}" text="{{'FINANCE_RESULTATE.INFO.NAME_TEXT' | translate}}"></app-info-sidebar>
</ng-template>

<ng-template #expressInput let-modal>
    <app-express-sidebar 
    [modal]="modal" 
    [resultId]="resultId"
    ></app-express-sidebar>
</ng-template>

<ng-template #overview let-modal>
    <app-overview 
    [modal]="modal" 
    [chapter]="1"
    ></app-overview>
</ng-template>

