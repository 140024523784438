import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-bubble-nav',
  templateUrl: './bubble-nav.component.html',
  styleUrls: ['./bubble-nav.component.scss']
})
export class BubbleNavComponent implements OnInit {

  @Input() buttons: any = [];
  @Output() public buttonValue: EventEmitter<any> = new EventEmitter<string>();

  constructor(
    public screen: LayoutService
  ) { }

  ngOnInit(): void {
  }

  sendValue(value: string, isEnabled: boolean) {
    if(isEnabled) {
      this.buttonValue.emit(value);
    }
  }

}
