export const federalStates = {
    "01": "6,5", // Schleswig-Holstein
    "02": "5,5", // Hamburg
    "03": "5", // Niedersachsen
    "04": "5", // Bremen
    "05": "6,5", // Nordrhein-Westfalen
    "06": "6", // Hessen
    "07": "5", // Rheinland-Pfalz
    "08": "5", // Baden-Württemberg
    "09": "4,5", // Bayern
    "10": "6,5", // Saarland
    "11": "6", // Berlin
    "12": "6,5", // Brandenburg
    "13": "6", // Mecklenburg-Vorpommern
    "14": "5,5", // Sachsen
    "15": "5", // Sachsen-Anhalt
    "16": "6,5" // Thüringen
}