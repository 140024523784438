import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';

@Component({
  selector: 'app-darlehen',
  templateUrl: './darlehen.component.html',
  styleUrls: ['./darlehen.component.scss']
})
export class DarlehenComponent implements OnChanges {
  reset: boolean = false;

  label = 'FINANCE_ASSEMBLY.BAUSTEINE.DARLEHEN.TITLE';

  @Input() num!: number;
  @Input() type!: string;
  @Input() error!: boolean;
  @Input() errorDarlehen!: string;
  @Input() isSingle!: boolean;
  @Input() darlehensbedarf!: string;
  @Output() public darlehenHoehe: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('darlehenHoehe') darlehen!: any;

  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private dataQuery: DataQuery,
    private daService: DataService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(!!this.type && this.type === 'VARIABLES_DARLEHEN') {
      this.label = 'FINANCE_ASSEMBLY.BAUSTEINE.DARLEHEN.TITLE2';
    } else {
      this.label = 'FINANCE_ASSEMBLY.BAUSTEINE.DARLEHEN.TITLE';
    }

    if(this.isSingle) {
      setTimeout(() => { this.validateInput(this.contentService.getValue('darlehensbedarf'), this.darlehen); }, 100);
    }
  }

  validateInput(e: any, element: any) {
    setTimeout(() => {
      const value = typeof e === 'string' ? e : e[0];
      element.touched = true;
      element.placeholder = '';
      this.darlehenHoehe.emit({value: value, i: this.num} );
    }, 100);

    setTimeout(() => {
      this.reset = false;
    }, 3000);
  }

}
