<div class="da-lightbox" [ngClass]="{'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet()}">
    <div class="da-close-icon" [ngClass]="{'mobile' : screen.isSmallest()}" (click)="dismiss()">
        <svg-icon
            src="assets/images/chevronRight.svg"
        >
        </svg-icon>
    </div>
    <div class="da-modal-body">
        <div class="da-header-icon">
            <svg-icon
                src="assets/images/coins.svg"
            >
            </svg-icon>
        </div>
        <lib-header-text
            class="da-lightbox-header"
            size="38px"
            [convertMobile]="true"
            [light]="true"
            label="{{'EIGENLEISTUNG.TITLE' | translate}}"
        ></lib-header-text>
        <div class="da-lightbox-content">
            <lib-tile-group
                class="da-tiles-wrapper"
                [tiles]="tiles"
                [localValue]="localValue"
                (tileValue)="setValue($event)"
                >
                <ng-template #content>
                    <div class="da-tile-content-wrapper">
                        <lib-tile-text
                            [ngStyle]="{'text-align': 'center'}"
                            size="16px"
                            label="{{'EIGENLEISTUNG.TEXT1' | translate}}"
                        ></lib-tile-text>
                        <lib-counter
                            #tage
                            [value]="contentService.getValue('eigenleistungTage', true)"
                            name="tage"
                            [min]="1"
                            [max]="100"
                            (counterValue)="setCounter($event, value)"
                        ></lib-counter>
                        <svg-icon class="da-container-icon" src="assets/images/equals.svg"></svg-icon>
                        <div class="da-input-background">
                            <lib-input
                                #value
                                label=""
                                name="value"
                                [error]="false"
                                [touched]="false"
                                errorText="{{'COMMON.ERRORS.EIGENLEISTUNG' | translate: {number: contentService.getValue('modernisierungkosten')} }}"
                                [inputDisabled]="true"
                                [value]="contentService.getValue('eigenleistungValue', true)"         
                                unit="€"
                                [light]="true"
                                [autoWidth]="false"
                            ></lib-input>
                        </div>
                    </div>
                </ng-template>
            </lib-tile-group>
        </div>
        <div class="da-address-button-wrapper" *ngIf="showButtons">
            <div class="da-sidebar-button-bg">
                <lib-button 
                    name="eigenleistung button"
                    value="{{'EIGENLEISTUNG.BUTTON' | translate}}" 
                    [disabled]="disabled"
                    [light]="true"
                    width="230"
                    (buttonValue)="finishInput()"
                    iconRight="assets/images/disk.svg"
                ></lib-button>
            </div>
        </div>
    </div>
</div>
