<lib-header-text
    class="da-title"
    label="{{'CONDITION_ESTATE.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<div class="da-tiles-wrapper">
    <ng-container *ngFor="let tile of tiles; let num = index">
        <lib-tile-rating
            [label]="tile.label"
            [name]="tile.name"
            [stars]="tile.stars"
            [info]="tile.info"
            [success]="tile.success"
            [rating]="tile.rating"
            (infoValue)="getInfo($event)"
            (ratingValue)="getRating($event)"
        ></lib-tile-rating>
    </ng-container>
</div>
<div class="da-buttons-wrapper">
    <app-nav-buttons
        class="da-buttons-group"
        [save]="true"
        [nextDisabled]="daNextSlideService.getDisabled()"
        [current]="slideName"
        [next]="next"
        (slideTo)="contentService.setNav($event, slideName, slideTo)"
        >
    </app-nav-buttons>
</div>

<ng-template #conditionInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'CONDITION_ESTATE.INFO1.INFO_HEADER' | translate}}" text="{{'CONDITION_ESTATE.INFO1.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #equipmentInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'CONDITION_ESTATE.INFO2.INFO_HEADER' | translate}}" text="{{'CONDITION_ESTATE.INFO2.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
