import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DaClickpathQuery } from 'src/app/state/click-path/da-clickpath.query';
import { DataQuery } from 'src/app/state/data/data.query';

@Component({
  selector: 'app-container-multiple',
  templateUrl: './container-multiple.component.html',
  styleUrls: ['./container-multiple.component.scss']
})
export class ContainerMultipleComponent implements OnInit, OnChanges {

  basePosition: number = 0;
  screenPosition: string = '0px';
  tileSlideFactor: number = 0;
  tileIndex: number = 0;

  @Input() parentWidth!: number;
  @Input() ergebnis: any;
  @Output() public switchMeta: EventEmitter<any> = new EventEmitter<any>();
  @Output() public express: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public contentService: ContentService,
    public screen: LayoutService,
    private dataQuery: DataQuery,
    private daClickpathQuery: DaClickpathQuery
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
    if(this.parentWidth) {
      this.tileSlideFactor = this.parentWidth < 480 ? this.parentWidth - 55 : this.parentWidth - 96;
    }
  }

  ngOnInit(): void {
    // this.tileSlideFactor = parent.innerWidth > 1279 ? parent.innerWidth - 82 : parent.innerWidth - 55;
    // console.log(parent, parent.innerWidth)
  }

  ngOnChanges(chnages: SimpleChanges) {
    if(this.parentWidth) {
      this.tileSlideFactor = this.parentWidth < 480 ? this.parentWidth - 55 : this.parentWidth - 96;
    }
  }

  setSwitchMeta(type: string, value: string = '') {
    if(type === 'reserved') {
      this.ergebnis = {... this.ergebnis, isReserved: !this.ergebnis.isReserved}
    } else if (type === 'visible'){
      this.ergebnis = {... this.ergebnis, isVisible: !this.ergebnis.isVisible}
    } else if (type === 'index') {
      this.ergebnis = {... this.ergebnis, selectedTile: value}
    } else if (type === 'tilePos') {
      this.ergebnis = {... this.ergebnis, tilePosition: value}
    }
  }

  getIndex(e: string, tiles: any, tilePos: string) {
    this.setSwitchMeta('index', e);
    const tilePosition = this.screen.calculateNewPos(tiles, e, this.tileSlideFactor, tilePos, this.basePosition);
    // console.log(tilePosition)
    this.setSwitchMeta('tilePos', tilePosition);
  };

  getValue(e: any) {

  }

}
