<lib-header-text
    class="da-title"
    label="{{'ADDITIONAL_INCOME.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>


<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
>
    <ng-template #content>
        <div class="da-tile-content-wrapper">

            <!-- NEBENJOB -->
            <lib-tile-text
                size="20px"
                label="{{'ADDITIONAL_INCOME.TILE_TEXT1' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(jobInfo)"
                [light]="true"
            ></lib-tile-text>
            <lib-toggle
                #nebentaetigkeit
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="job"
                [light]="true"
                value1="NebentaetigkeitBesteht"
                value2="KeineNebentaetigkeit"
                [localValue]="contentService.getValue('nebentaetigkeit')"
                (switchValue)="setSwitch($event, nebentaetigkeit)"
            ></lib-toggle>
            <ng-container *ngIf="contentService.getValue('nebentaetigkeit') === 'NebentaetigkeitBesteht'">
                <lib-tile-text
                    size="20px"
                    [convertMobile]="true"
                    label="{{'ADDITIONAL_INCOME.TILE_TEXT2' | translate}}"
                    [light]="true"
                ></lib-tile-text>
                <lib-input
                    #nebentaetigkeitGeld
                    #inputs
                    label="{{'COMMON.LABELS.FEE' | translate}}"
                    name="nebentaetigkeitGeld"
                    [error]="false"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="500,00"
                    [light]="true"
                    unit="€"
                    [inputDisabled]="inputDisabled"
                    (click)="testValid = true"
                    [touched]="!!contentService.getValue('einkuenfteAusNebentaetigkeit')"       
                    [value]="!!contentService.getValue('einkuenfteAusNebentaetigkeit') ? contentService.getValue('einkuenfteAusNebentaetigkeit') : ''"
                    (change)="validateInput($event, nebentaetigkeitGeld)"
                ></lib-input>
                <lib-tile-text
                    size="20px"
                    [convertMobile]="true"
                    label="{{'ADDITIONAL_INCOME.TILE_TEXT3' | translate}}"
                    [light]="true"
                ></lib-tile-text>
                <lib-input-date
                    class="less-margin-bottom"
                    #nebentaetigkeitDate
                    #inputs
                    [maxDate]="today"
                    [minDate]="beforeDate"
                    [light]="true"
                    [inputDisabled]="inputDisabled"
                    (click)="testValid = true"
                    label="{{'COMMON.LABELS.DATE' | translate}}"
                    name="nebentaetigkeitDate"
                    [value]="!!contentService.getValue('nebentaetigkeitDate') ? contentService.getValue('nebentaetigkeitDate') : ''"
                    [touched]="!!contentService.getValue('nebentaetigkeitDate')"
                    [error]="false"
                    errorText="{{'COMMON.ERRORS.DATE' | translate}}"
                    placeholder="tt.mm.jjjj"
                    (dateValue)="validateDate($event, nebentaetigkeitDate)"
                ></lib-input-date>
            </ng-container>

            <!-- RENTE -->
            <lib-tile-text
                size="20px"
                label="{{'ADDITIONAL_INCOME.TILE_TEXT4' | translate}}"
                [light]="true"
            ></lib-tile-text>
            <lib-toggle
                #rente
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="rente"
                [light]="true"
                value1="RenteBesteht"
                value2="KeineRente"
                [localValue]="contentService.getValue('rente')"
                (switchValue)="setSwitch($event, rente)"
            ></lib-toggle>
            <ng-container *ngIf="contentService.getValue('rente') === 'RenteBesteht'">
                <lib-tile-text
                    size="20px"
                    [convertMobile]="true"
                    [light]="true"
                    label="{{'ADDITIONAL_INCOME.TILE_TEXT5' | translate}}"
                ></lib-tile-text>
                <lib-input
                    #renteGeld
                    #inputs
                    label="{{'COMMON.LABELS.FEE' | translate}}"
                    name="renteGeld"
                    [error]="false"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="500,00"
                    unit="€"
                    [inputDisabled]="inputDisabled"
                    (click)="testValid = true"
                    [light]="true"
                    [touched]="!!contentService.getValue('summeUnbefristeteZusatzrentenMonatlich')"
                    [value]="!!contentService.getValue('summeUnbefristeteZusatzrentenMonatlich') ? contentService.getValue('summeUnbefristeteZusatzrentenMonatlich') : ''"
                    (change)="validateInput($event, renteGeld)"
                ></lib-input>
            </ng-container>

            <!-- PROVISION -->
            <lib-tile-text
                size="20px"
                label="{{'ADDITIONAL_INCOME.TILE_TEXT6' | translate}}"
                [light]="true"
            ></lib-tile-text>
            <lib-toggle
                #provision
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="provision"
                [light]="true"
                value1="ProvisionBesteht"
                value2="KeineProvision"
                [localValue]="contentService.getValue('provision')"
                (switchValue)="setSwitch($event, provision)"
            ></lib-toggle>
            <ng-container *ngIf="contentService.getValue('provision') === 'ProvisionBesteht'">
                <lib-tile-text
                    size="20px"
                    [convertMobile]="true"
                    label="{{'ADDITIONAL_INCOME.TILE_TEXT7' | translate}}"
                    [light]="true"
                ></lib-tile-text>
                <lib-input
                    #provisionGeld
                    #inputs
                    label="{{'COMMON.LABELS.FEE' | translate}}"
                    name="provisionGeld"
                    [error]="false"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="500,00"
                    unit="€"
                    [inputDisabled]="inputDisabled"
                    (click)="testValid = true"
                    [light]="true"
                    [touched]="!!contentService.getValue('summeSonstigeEinnahmenMonatlich')"
                    [value]="!!contentService.getValue('summeSonstigeEinnahmenMonatlich') ? contentService.getValue('summeSonstigeEinnahmenMonatlich') : ''"
                    (change)="validateInput($event, provisionGeld)"
                ></lib-input>
            </ng-container>
        </div>
    </ng-template>
</lib-tile-group>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

<ng-template #jobInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'ADDITIONAL_INCOME.INFO_HEADER' | translate}}" text="{{'ADDITIONAL_INCOME.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
