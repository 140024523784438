import { contentInterface } from "./tiles";

export const dropdownsContent:contentInterface = {
    "VERWENDUNG": {
        "items": [
            {
                "viewValue": "FINANCE_ASSEMBLY.BAUSTEINE.VERWENDUNG.DROPDOWN.ITEM1",
                "value": "VERKAUF_EINES_ANDEREN_OBJEKTS",
            },
            {
                "viewValue": "FINANCE_ASSEMBLY.BAUSTEINE.VERWENDUNG.DROPDOWN.ITEM2",
                "value": "VORFINANZIERUNG_OEFFENTLICHER_MITTEL",
            },
            {
                "viewValue": "FINANCE_ASSEMBLY.BAUSTEINE.VERWENDUNG.DROPDOWN.ITEM3",
                "value": "SONSTIGE_VERWENDUNG",
            },
        ]
    },
    "BAUSTEINE": {
        "items": [
            {
                "viewValue": "FINANCE_ASSEMBLY.BAUSTEINE.DROPDOWN.ITEM1",
                "value": "ANNUITAETENDARLEHEN",
            },
            {
                "viewValue": "FINANCE_ASSEMBLY.BAUSTEINE.DROPDOWN.ITEM2",
                "value": "FORWARDDARLEHEN",
            },
            {
                "viewValue": "FINANCE_ASSEMBLY.BAUSTEINE.DROPDOWN.ITEM3",
                "value": "KFW_DARLEHEN",
            },
            {
                "viewValue": "FINANCE_ASSEMBLY.BAUSTEINE.DROPDOWN.ITEM4",
                "value": "PRIVATDARLEHEN",
            },
            {
                "viewValue": "FINANCE_ASSEMBLY.BAUSTEINE.DROPDOWN.ITEM5",
                "value": "ZWISCHENFINANZIERUNG",
            },
            // {
            //     "viewValue": "FINANCE_ASSEMBLY.BAUSTEINE.DROPDOWN.ITEM6",
            //     "value": "VARIABLES_DARLEHEN",
            // },
        ]
    },
    "STEUER": {
        "items": [
            {
                "viewValue": "Baden-Württemberg",
                "value": "5",
            },
            {
                "viewValue": "Bayern",
                "value": "4,5",
            },
            {
                "viewValue": "Berlin",
                "value": "6",
            },
            {
                "viewValue": "Brandenburg",
                "value": "6,5",
            },
            {
                "viewValue": "Bremen",
                "value": "5",
            },
            {
                "viewValue": "Hamburg",
                "value": "5,5",
            },
            {
                "viewValue": "Hessen",
                "value": "6",
            },
            {
                "viewValue": "Mecklenburg-Vorpommern",
                "value": "6",
            },
            {
                "viewValue": "Niedersachsen",
                "value": "5",
            },
            {
                "viewValue": "Nordrhein-Westfalen",
                "value": "6,5",
            },
            {
                "viewValue": "Rheinland-Pfalz",
                "value": "5",
            },
            {
                "viewValue": "Saarland",
                "value": "6,5",
            },
            {
                "viewValue": "Sachsen",
                "value": "5,5",
            },
            {
                "viewValue": "Sachsen-Anhalt",
                "value": "5",
            },
            {
                "viewValue": "Schleswig-Holstein",
                "value": "6,5",
            },
            {
                "viewValue": "Thüringen",
                "value": "6,5",
            },
            {
                "viewValue": "TILES.ERBBAURECHT.SWITCH2",
                "value": "0,0",
            }
        ]
    },
    "OBJECT_VORHABEN": {
        "items": [
            {
                "viewValue": "TILES.OBJECT_VORHABEN.DROPDOWN1",
                "value": "ANSCHLUSSFINANZIERUNG",
            },
            {
                "viewValue": "TILES.OBJECT_VORHABEN.DROPDOWN2",
                "value": "MODERNISIERUNG_UMBAU_ANBAU",
            },
            {
                "viewValue": "TILES.OBJECT_VORHABEN.DROPDOWN3",
                "value": "KAPITALBESCHAFFUNG",
            },
            {
                "viewValue": "TILES.OBJECT_VORHABEN.DROPDOWN4",
                "value": "MIXED",
            }
        ]
    },
    "OBJECT_NUTZUNG": {
        "items": [
            {
                "viewValue": "TILES.OBJECT_NUTZUNG.DROPDOWN1",
                "value": "EIGENGENUTZT",
            },
            {
                "viewValue": "TILES.OBJECT_NUTZUNG.DROPDOWN2",
                "value": "TEILVERMIETET",
            },
            {
                "viewValue": "TILES.OBJECT_NUTZUNG.DROPDOWN3",
                "value": "VERMIETET",
            }
        ]
    },
    "PROPERTY_TYPE": {
        "items": [
            {
                "viewValue": "TILES.OBJECT_CATEGORY.TILE2_2",
                "value": "BAUGRUNDSTUECK",
            },
            {
                "viewValue": "TILES.OBJECT_CATEGORY.TILE1",
                "value": "EIGENTUMSWOHNUNG",
            },
            {
                "viewValue": "TILES.HOUSE_CATEGORY.TILE1",
                "value": "EINFAMILIENHAUS",
            },
            {
                "viewValue": "TILES.HOUSE_CATEGORY.TILE2",
                "value": "DOPPELHAUSHAELFTE",
            },
            {
                "viewValue": "TILES.HOUSE_CATEGORY.TILE3",
                "value": "ZWEIFAMILIENHAUS",
            },
            {
                "viewValue": "TILES.HOUSE_CATEGORY.TILE4",
                "value": "REIHENHAUS",
            },
            {
                "viewValue": "TILES.HOUSE_CATEGORY.TILE5",
                "value": "MEHRFAMILIENHAUS",
            },
        ]
    },
    "FAMILY": {
        "items": [
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN1",
                "value": "LEDIG",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN2",
                "value": "LEBENSPARTNERSCHAFT",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN3",
                "value": "VERHEIRATET",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN4",
                "value": "GETRENNT_LEBEND",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN5",
                "value": "GESCHIEDEN",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN6",
                "value": "VERWITWET",
            },
        ]
    },
    "VISUM": {
        "items": [
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN7",
                "value": "VISUM",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN8",
                "value": "NIEDERLASSUNGSERLAUBNIS",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN9",
                "value": "DAUERAUFENTHALT_EU",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN10",
                "value": "AUFENTHALTSERLAUBNIS",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN21",
                "value": "BLAUEKARTE_EU",
            },
        ]
    },
    "GEHALT": {
        "items": [
            {
                "viewValue": "12",
                "value": "ZWOELF",
            },
            {
                "viewValue": "12,5",
                "value": "ZWOELF_EINHALB",
            },
            {
                "viewValue": "13",
                "value": "DREIZEHN",
            },
            {
                "viewValue": "13,5",
                "value": "DREIZEHN_EINHALB",
            },
            {
                "viewValue": "14",
                "value": "VIERZEHN",
            },
            {
                "viewValue": "14,5",
                "value": "VIERZEHN_EINHALB",
            },
            {
                "viewValue": "15",
                "value": "FUENFZEHN",
            },
        ]
    },
    "JOB": {
        "items": [
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN11",
                "value": "BEAMTER",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN12",
                "value": "ANGESTELLTER",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN13",
                "value": "HAUSHALTENDE_PERSON",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN14",
                "value": "ARBEITER",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN15",
                "value": "SELBSTSTAENDIGER",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN16",
                "value": "FREIBERUFLER",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN17",
                "value": "RENTNER",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN18",
                "value": "ARBEITSLOSER",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN19",
                "value": "SCHUELER",
            },
            {
                "viewValue": "TILES.PERSONAL.DROPDOWN20",
                "value": "AUSZUBILDENDER",
            },
        ]
    },
    "VORWAHL": {
        "items": [
            {
                "viewValue": "01511",
                "value": "01511",
            },
            {
                "viewValue": "01512",
                "value": "01512",
            },
            {
                "viewValue": "01514",
                "value": "01514",
            },
            {
                "viewValue": "01515",
                "value": "01515",
            },
            {
                "viewValue": "01516",
                "value": "01516",
            },
            {
                "viewValue": "01517",
                "value": "01517",
            },
            {
                "viewValue": "01520",
                "value": "01520",
            },
            {
                "viewValue": "01521",
                "value": "01521",
            },
            {
                "viewValue": "01522",
                "value": "01522",
            },
            {
                "viewValue": "01523",
                "value": "01523",
            },
            {
                "viewValue": "01525",
                "value": "01525",
            },
            {
                "viewValue": "01526",
                "value": "01526",
            },
            {
                "viewValue": "01529",
                "value": "01529",
            },
            {
                "viewValue": "015566",
                "value": "015566",
            },
            {
                "viewValue": "01570",
                "value": "01570",
            },
            {
                "viewValue": "01573",
                "value": "01573",
            },
            {
                "viewValue": "01575",
                "value": "01575",
            },
            {
                "viewValue": "01577",
                "value": "01577",
            },
            {
                "viewValue": "01578",
                "value": "01578",
            },
            {
                "viewValue": "01579",
                "value": "01579",
            },
            {
                "viewValue": "01590",
                "value": "01590",
            },
            {
                "viewValue": "0160",
                "value": "0160",
            },
            {
                "viewValue": "0162",
                "value": "0162",
            },
            {
                "viewValue": "0163",
                "value": "0163",
            },
            {
                "viewValue": "0170",
                "value": "0170",
            },
            {
                "viewValue": "0171",
                "value": "0171",
            },
            {
                "viewValue": "0172",
                "value": "0172",
            },
            {
                "viewValue": "0173",
                "value": "0173",
            },
            {
                "viewValue": "0174",
                "value": "0174",
            },
            {
                "viewValue": "0175",
                "value": "0175",
            },
            {
                "viewValue": "0176",
                "value": "0176",
            },
            {
                "viewValue": "0177",
                "value": "0177",
            },
            {
                "viewValue": "0178",
                "value": "0178",
            },
            {
                "viewValue": "0179",
                "value": "0179",
            },
        ]
    },
    "STAAT": {
        "items": [
            {
                "viewValue": "Deutschland",
                "value": "DE",
            },
            {
                "viewValue": "Frankreich",
                "value": "FR",
            },
            {
                "viewValue": "Niederlande",
                "value": "NL",
            },
            {
                "viewValue": "Österreich",
                "value": "AT",
            },
            {
                "viewValue": "Schweiz",
                "value": "CH",
            },
            {
                "viewValue": "Türkei",
                "value": "TR",
            },
            {
                "viewValue": "Großbritannien und Nordirland",
                "value": "GB",
            },
            {
                "viewValue": "Afghanistan",
                "value": "AF",
            },
            {
                "viewValue": "Ägypten",
                "value": "EG",
            },
            {
                "viewValue": "Åland",
                "value": "AX",
            },
            {
                "viewValue": "Albanien",
                "value": "AL",
            },
            {
                "viewValue": "Algerien",
                "value": "DZ",
            },
            {
                "viewValue": "Amerikanische Jungferninseln",
                "value": "VI",
            },
            {
                "viewValue": "Amerikanisch-Ozeanien",
                "value": "UM",
            },
            {
                "viewValue": "Amerikanisch-Samoa",
                "value": "AS",
            },
            {
                "viewValue": "Andorra",
                "value": "AD",
            },
            {
                "viewValue": "Angola",
                "value": "AO",
            },
            {
                "viewValue": "Anguilla",
                "value": "AI",
            },
            {
                "viewValue": "Antarktis",
                "value": "AQ",
            },
            {
                "viewValue": "Antigua und Barbuda",
                "value": "AG",
            },
            {
                "viewValue": "Äquatorialguinea",
                "value": "GQ",
            },
            {
                "viewValue": "Argentinien",
                "value": "AR",
            },
            {
                "viewValue": "Armenien",
                "value": "AM",
            },
            {
                "viewValue": "Aruba",
                "value": "AW",
            },
            {
                "viewValue": "Aserbaidschan",
                "value": "AZ",
            },
            {
                "viewValue": "Äthiopien",
                "value": "ET",
            },
            {
                "viewValue": "Australien",
                "value": "AU",
            },
            {
                "viewValue": "Bahamas",
                "value": "BS",
            },
            {
                "viewValue": "Bahrain",
                "value": "BH",
            },
            {
                "viewValue": "Bangladesch",
                "value": "BD",
            },
            {
                "viewValue": "Barbados",
                "value": "BB",
            },
            {
                "viewValue": "Belarus (Weißrussland)",
                "value": "BY",
            },
            {
                "viewValue": "Belgien",
                "value": "BE",
            },
            {
                "viewValue": "Belize",
                "value": "BZ",
            },
            {
                "viewValue": "Benin",
                "value": "BJ",
            },
            {
                "viewValue": "Bermuda",
                "value": "BM",
            },
            {
                "viewValue": "Bhutan",
                "value": "BT",
            },
            {
                "viewValue": "Bolivien",
                "value": "BO",
            },
            {
                "viewValue": "Bonaire, Sint Eustatius und Saba",
                "value": "BQ",
            },
            {
                "viewValue": "Bosnien und Herzegowina",
                "value": "BA",
            },
            {
                "viewValue": "Botswana",
                "value": "BW",
            },
            {
                "viewValue": "Bouvetinsel",
                "value": "BV",
            },
            {
                "viewValue": "Brasilien",
                "value": "BR",
            },
            {
                "viewValue": "Britische Jungferninseln",
                "value": "VG",
            },
            {
                "viewValue": "Britisches Territorium im Indischen Ozean",
                "value": "IO",
            },
            {
                "viewValue": "Brunei Darussalam",
                "value": "BN",
            },
            {
                "viewValue": "Bulgarien",
                "value": "BG",
            },
            {
                "viewValue": "Burkina Faso",
                "value": "BF",
            },
            {
                "viewValue": "Burundi",
                "value": "BI",
            },
            {
                "viewValue": "Chile",
                "value": "CL",
            },
            {
                "viewValue": "China",
                "value": "CN",
            },
            {
                "viewValue": "Cookinseln",
                "value": "CK",
            },
            {
                "viewValue": "Costa Rica",
                "value": "CR",
            },
            {
                "viewValue": "Curaçao",
                "value": "CW",
            },
            {
                "viewValue": "Dänemark",
                "value": "DK",
            },
            {
                "viewValue": "Dominica",
                "value": "DM",
            },
            {
                "viewValue": "Dominikanische Republik",
                "value": "DO",
            },
            {
                "viewValue": "Dschibuti",
                "value": "DJ",
            },
            {
                "viewValue": "Ecuador",
                "value": "EC",
            },
            {
                "viewValue": "El Salvador",
                "value": "SV",
            },
            {
                "viewValue": "Elfenbeinküste",
                "value": "CI",
            },
            {
                "viewValue": "Eritrea",
                "value": "ER",
            },
            {
                "viewValue": "Estland (Reval)",
                "value": "EE",
            },
            {
                "viewValue": "Falklandinseln (Malwinen)",
                "value": "FK",
            },
            {
                "viewValue": "Färöer",
                "value": "FO",
            },
            {
                "viewValue": "Fidschi",
                "value": "FJ",
            },
            {
                "viewValue": "Finnland",
                "value": "FI",
            },
            {
                "viewValue": "Französische Süd- und Antarktisgebiete",
                "value": "TF",
            },
            {
                "viewValue": "Französisch-Guayana",
                "value": "GF",
            },
            {
                "viewValue": "Französisch-Polynesien",
                "value": "PF",
            },
            {
                "viewValue": "Gabun",
                "value": "GA",
            },
            {
                "viewValue": "Gambia",
                "value": "GM",
            },
            {
                "viewValue": "Georgien",
                "value": "GE",
            },
            {
                "viewValue": "Ghana",
                "value": "GH",
            },
            {
                "viewValue": "Gibraltar",
                "value": "GI",
            },
            {
                "viewValue": "Grenada",
                "value": "GD",
            },
            {
                "viewValue": "Griechenland",
                "value": "GR",
            },
            {
                "viewValue": "Grönland",
                "value": "GL",
            },
            {
                "viewValue": "Guadeloupe",
                "value": "GP",
            },
            {
                "viewValue": "Guam",
                "value": "GU",
            },
            {
                "viewValue": "Guatemala",
                "value": "GT",
            },
            {
                "viewValue": "Guernsey (Kanalinsel)",
                "value": "GG",
            },
            {
                "viewValue": "Guinea",
                "value": "GN",
            },
            {
                "viewValue": "Guinea-Bissau",
                "value": "GW",
            },
            {
                "viewValue": "Guyana",
                "value": "GY",
            },
            {
                "viewValue": "Haiti",
                "value": "HT",
            },
            {
                "viewValue": "Heard- und McDonald-Inseln",
                "value": "HM",
            },
            {
                "viewValue": "Honduras",
                "value": "HN",
            },
            {
                "viewValue": "Hongkong",
                "value": "HK",
            },
            {
                "viewValue": "Indien",
                "value": "IN",
            },
            {
                "viewValue": "Indonesien",
                "value": "ID",
            },
            {
                "viewValue": "Isle of Man",
                "value": "IM",
            },
            {
                "viewValue": "Irak",
                "value": "IQ",
            },
            {
                "viewValue": "Iran",
                "value": "IR",
            },
            {
                "viewValue": "Irland",
                "value": "IE",
            },
            {
                "viewValue": "Island",
                "value": "IS",
            },
            {
                "viewValue": "Israel",
                "value": "IL",
            },
            {
                "viewValue": "Italien",
                "value": "IT",
            },
            {
                "viewValue": "Jamaika",
                "value": "JM",
            },
            {
                "viewValue": "Japan",
                "value": "JP",
            },
            {
                "viewValue": "Jemen",
                "value": "YE",
            },
            {
                "viewValue": "Jersey (Kanalinsel)",
                "value": "JE",
            },
            {
                "viewValue": "Jordanien",
                "value": "JO",
            },
            {
                "viewValue": "Kaimaninseln",
                "value": "KY",
            },
            {
                "viewValue": "Kambodscha",
                "value": "KH",
            },
            {
                "viewValue": "Kamerun",
                "value": "CM",
            },
            {
                "viewValue": "Kanada",
                "value": "CA",
            },
            {
                "viewValue": "Kap Verde",
                "value": "CV",
            },
            {
                "viewValue": "Kasachstan",
                "value": "KZ",
            },
            {
                "viewValue": "Katar",
                "value": "QA",
            },
            {
                "viewValue": "Kenia",
                "value": "KE",
            },
            {
                "viewValue": "Kirgisistan",
                "value": "KG",
            },
            {
                "viewValue": "Kiribati",
                "value": "KI",
            },
            {
                "viewValue": "Kokosinseln (Keelinginseln)",
                "value": "CC",
            },
            {
                "viewValue": "Kolumbien",
                "value": "CO",
            },
            {
                "viewValue": "Komoren",
                "value": "KM",
            },
            {
                "viewValue": "Kongo",
                "value": "CD",
            },
            {
                "viewValue": "Kroatien",
                "value": "HR",
            },
            {
                "viewValue": "Kuba",
                "value": "CU",
            },
            {
                "viewValue": "Kuwait",
                "value": "KW",
            },
            {
                "viewValue": "Laos",
                "value": "LA",
            },
            {
                "viewValue": "Lesotho",
                "value": "LS",
            },
            {
                "viewValue": "Lettland",
                "value": "LV",
            },
            {
                "viewValue": "Libanon",
                "value": "LB",
            },
            {
                "viewValue": "Liberia",
                "value": "LR",
            },
            {
                "viewValue": "Libyen",
                "value": "LY",
            },
            {
                "viewValue": "Liechtenstein",
                "value": "LI",
            },
            {
                "viewValue": "Litauen",
                "value": "LT",
            },
            {
                "viewValue": "Luxemburg",
                "value": "LU",
            },
            {
                "viewValue": "Macau",
                "value": "MO",
            },
            {
                "viewValue": "Madagaskar",
                "value": "MG",
            },
            {
                "viewValue": "Malawi",
                "value": "MW",
            },
            {
                "viewValue": "Malaysia",
                "value": "MY",
            },
            {
                "viewValue": "Malediven",
                "value": "MV",
            },
            {
                "viewValue": "Mali",
                "value": "ML",
            },
            {
                "viewValue": "Malta",
                "value": "MT",
            },
            {
                "viewValue": "Marokko",
                "value": "MA",
            },
            {
                "viewValue": "Marshallinseln",
                "value": "MH",
            },
            {
                "viewValue": "Martinique",
                "value": "MQ",
            },
            {
                "viewValue": "Mauretanien",
                "value": "MR",
            },
            {
                "viewValue": "Mauritius",
                "value": "MU",
            },
            {
                "viewValue": "Mayotte",
                "value": "YT",
            },
            {
                "viewValue": "Mazedonien",
                "value": "MK",
            },
            {
                "viewValue": "Mexiko",
                "value": "MX",
            },
            {
                "viewValue": "Mikronesien",
                "value": "FM",
            },
            {
                "viewValue": "Moldawien",
                "value": "MD",
            },
            {
                "viewValue": "Monaco",
                "value": "MC",
            },
            {
                "viewValue": "Mongolei",
                "value": "MN",
            },
            {
                "viewValue": "Montenegro",
                "value": "ME",
            },
            {
                "viewValue": "Montserrat",
                "value": "MS",
            },
            {
                "viewValue": "Mosambik",
                "value": "MZ",
            },
            {
                "viewValue": "Myanmar (Burma)",
                "value": "MM",
            },
            {
                "viewValue": "Namibia",
                "value": "NA",
            },
            {
                "viewValue": "Nauru",
                "value": "NR",
            },
            {
                "viewValue": "Nepal",
                "value": "NP",
            },
            {
                "viewValue": "Neukaledonien",
                "value": "NC",
            },
            {
                "viewValue": "Neuseeland",
                "value": "NZ",
            },
            {
                "viewValue": "Nicaragua",
                "value": "NI",
            },
            {
                "viewValue": "Niederländische Antillen",
                "value": "AN",
            },
            {
                "viewValue": "Niger",
                "value": "NE",
            },
            {
                "viewValue": "Nigeria",
                "value": "NG",
            },
            {
                "viewValue": "Niue",
                "value": "NU",
            },
            {
                "viewValue": "Nordkorea",
                "value": "KP",
            },
            {
                "viewValue": "Nördliche Marianen",
                "value": "MP",
            },
            {
                "viewValue": "Norfolkinsel",
                "value": "NF",
            },
            {
                "viewValue": "Norwegen",
                "value": "NO",
            },
            {
                "viewValue": "Oman",
                "value": "OM",
            },
            {
                "viewValue": "Pakistan",
                "value": "PK",
            },
            {
                "viewValue": "Palästina",
                "value": "PS",
            },
            {
                "viewValue": "Palau",
                "value": "PW",
            },
            {
                "viewValue": "Panama",
                "value": "PA",
            },
            {
                "viewValue": "Papua-Neuguinea",
                "value": "PG",
            },
            {
                "viewValue": "Paraguay",
                "value": "PY",
            },
            {
                "viewValue": "Peru",
                "value": "PE",
            },
            {
                "viewValue": "Philippinen",
                "value": "PH",
            },
            {
                "viewValue": "Pitcairninseln",
                "value": "PN",
            },
            {
                "viewValue": "Polen",
                "value": "PL",
            },
            {
                "viewValue": "Portugal",
                "value": "PT",
            },
            {
                "viewValue": "Puerto Rico",
                "value": "PR",
            },
            {
                "viewValue": "Republik Kongo",
                "value": "CG",
            },
            {
                "viewValue": "Réunion",
                "value": "RE",
            },
            {
                "viewValue": "Ruanda",
                "value": "RW",
            },
            {
                "viewValue": "Rumänien",
                "value": "RO",
            },
            {
                "viewValue": "Russische Föderation",
                "value": "RU",
            },
            {
                "viewValue": "Saint-Barthélemy",
                "value": "BL",
            },
            {
                "viewValue": "Saint-Martin (franz. Teil)",
                "value": "MF",
            },
            {
                "viewValue": "Salomonen",
                "value": "SB",
            },
            {
                "viewValue": "Sambia",
                "value": "ZM",
            },
            {
                "viewValue": "Samoa",
                "value": "WS",
            },
            {
                "viewValue": "San Marino",
                "value": "SM",
            },
            {
                "viewValue": "São Tomé und Príncipe",
                "value": "ST",
            },
            {
                "viewValue": "Saudi-Arabien",
                "value": "SA",
            },
            {
                "viewValue": "Schweden",
                "value": "SE",
            },
            {
                "viewValue": "Senegal",
                "value": "SN",
            },
            {
                "viewValue": "Serbien",
                "value": "RS",
            },
            {
                "viewValue": "Seychellen",
                "value": "SC",
            },
            {
                "viewValue": "Sierra Leone",
                "value": "SL",
            },
            {
                "viewValue": "Simbabwe",
                "value": "ZW",
            },
            {
                "viewValue": "Singapur",
                "value": "SG",
            },
            {
                "viewValue": "Sint Maarten (niederl. Teil)",
                "value": "SX",
            },
            {
                "viewValue": "Slowakei",
                "value": "SK",
            },
            {
                "viewValue": "Slowenien",
                "value": "SI",
            },
            {
                "viewValue": "Somalia",
                "value": "SO",
            },
            {
                "viewValue": "Spanien",
                "value": "ES",
            },
            {
                "viewValue": "Sri Lanka",
                "value": "LK",
            },
            {
                "viewValue": "St. Helena",
                "value": "SH",
            },
            {
                "viewValue": "St. Kitts und Nevis",
                "value": "KN",
            },
            {
                "viewValue": "St. Lucia",
                "value": "LC",
            },
            {
                "viewValue": "St. Pierre und Miquelon",
                "value": "PM",
            },
            {
                "viewValue": "St. Vincent und die Grenadinen",
                "value": "VC",
            },
            {
                "viewValue": "Südafrika",
                "value": "ZA",
            },
            {
                "viewValue": "Sudan",
                "value": "SD",
            },
            {
                "viewValue": "Südgeorgien und die Südl. Sandwichinseln",
                "value": "GS",
            },
            {
                "viewValue": "Südkorea",
                "value": "KR",
            },
            {
                "viewValue": "Südsudan",
                "value": "SS",
            },
            {
                "viewValue": "Suriname",
                "value": "SR",
            },
            {
                "viewValue": "Svalbard und Jan Mayen",
                "value": "SJ",
            },
            {
                "viewValue": "Swasiland",
                "value": "SZ",
            },
            {
                "viewValue": "Syrien",
                "value": "SY",
            },
            {
                "viewValue": "Tadschikistan",
                "value": "TJ",
            },
            {
                "viewValue": "Taiwan",
                "value": "TW",
            },
            {
                "viewValue": "Tansania",
                "value": "TZ",
            },
            {
                "viewValue": "Thailand",
                "value": "TH",
            },
            {
                "viewValue": "Timor-Leste",
                "value": "TL",
            },
            {
                "viewValue": "Togo",
                "value": "TG",
            },
            {
                "viewValue": "Tokelau",
                "value": "TK",
            },
            {
                "viewValue": "Tonga",
                "value": "TO",
            },
            {
                "viewValue": "Trinidad und Tobago",
                "value": "TT",
            },
            {
                "viewValue": "Tschad",
                "value": "TD",
            },
            {
                "viewValue": "Tschechische Republik",
                "value": "CZ",
            },
            {
                "viewValue": "Tunesien",
                "value": "TN",
            },
            {
                "viewValue": "Turkmenistan",
                "value": "TM",
            },
            {
                "viewValue": "Turks- und Caicosinseln",
                "value": "TC",
            },
            {
                "viewValue": "Tuvalu",
                "value": "TV",
            },
            {
                "viewValue": "Uganda",
                "value": "UG",
            },
            {
                "viewValue": "Ukraine",
                "value": "UA",
            },
            {
                "viewValue": "Ungarn",
                "value": "HU",
            },
            {
                "viewValue": "Uruguay",
                "value": "UY",
            },
            {
                "viewValue": "Usbekistan",
                "value": "UZ",
            },
            {
                "viewValue": "Vanuatu",
                "value": "VU",
            },
            {
                "viewValue": "Vatikanstadt",
                "value": "VA",
            },
            {
                "viewValue": "Venezuela",
                "value": "VE",
            },
            {
                "viewValue": "Vereinigte Arabische Emirate",
                "value": "AE",
            },
            {
                "viewValue": "Vereinigte Staaten von Amerika",
                "value": "US",
            },
            {
                "viewValue": "Vietnam",
                "value": "VN",
            },
            {
                "viewValue": "Wallis und Futuna",
                "value": "WF",
            },
            {
                "viewValue": "Weihnachtsinsel",
                "value": "CX",
            },
            {
                "viewValue": "Westsahara",
                "value": "EH",
            },
            {
                "viewValue": "Zentralafrikanische Republik",
                "value": "CF",
            },
            {
                "viewValue": "Zypern",
                "value": "CY",
            }
        ]
    }
}
