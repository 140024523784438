<lib-header-text
    class="da-title"
    label="{{'PERSONAL.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<lib-tile-text
    *ngIf="showPartner"
    class="da-title"
    label="{{'PERSONAL.SUB_TITLE' | translate}}"
    size="20px"
    [convertMobile]="true"
    [ngStyle]="{'display': 'flex', 'justify-content': 'center'}"
></lib-tile-text>
<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
></lib-tile-group>
<lib-header-text
    *ngIf="showFakeTiles"
    class="da-title"
    label="{{'PERSONAL.TITLE2' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<div class="da-index">
    <app-index 
        *ngIf="screen.isSmallerThanMinWidth() && showFakeTiles"
        [tiles]="fakeTiles" 
        [selectedTile]="selectedTile"
        [slideFactor]="tileSlideFactor"
        [slidePos]="tilePosition"
        (indexValue)="getIndex($event)">
    </app-index>
</div>
<div class="da-content-extended" *ngIf="showFakeTiles">
    <div class="da-content-extended-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest(), 'no-slider' : !screen.isSmallerThanMinWidth()}">
        <div 
            class="da-tiles-wrapper" 
            [ngClass]="{ 'with-slider' : screen.isSmallerThanMinWidth(), 'mobile-wrap' : screen.isSmallest()}"
            [ngStyle]="{'right': 'calc('+ screenPosition +' + '+ tilePosition +'px)'}">

            <!-- TILE1 -->
            <div #birthdateContainer
                class="da-container" 
                (click)="getIndex('Birthdate')"
                [ngClass]="{ 'mobile' : screen.isSmallest() }"
                >
                <lib-state-icon [state]="fakeTiles?.[0].state"></lib-state-icon>
                <div class="da-tile-wrapper date" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <lib-tile-text
                        class="da-tile-header"
                        size="20px"
                        [convertMobile]="true"
                        label="{{'PERSONAL.TILES.TILE1.TITLE' | translate}}"
                    ></lib-tile-text>
                    <lib-input-date
                        #birthdate
                        class="less-margin-bottom"
                        label="{{'COMMON.LABELS.DATE' | translate}}"
                        name="birthdate"
                        [maxDate]="maxDateBirth"
                        [minDate]="minDateBirth"
                        [value]="!!contentService.getValue('geburtsdatum') ? contentService.getValue('geburtsdatum') : ''"
                        [touched]="!!contentService.getValue('geburtsdatum')"
                        [error]="false"
                        [inputDisabled]="inputDisabled"
                        (click)="testValid = true"
                        errorText="{{errorBirthdate | translate}}"
                        placeholder="tt.mm.jjjj"
                        (dateValue)="validateDate($event, birthdate, 'Birthdate')"
                        (dateInputValue)="validateDate($event, birthdate, 'Birthdate', true)"
                    ></lib-input-date>
                </div>
            </div>

            <!-- TILE2 -->
            <div #plzContainer
                class="da-container" 
                (click)="getIndex('PostalCode')"
                [ngClass]="{ 'mobile' : screen.isSmallest() }"
                >
                <lib-state-icon [state]="fakeTiles?.[1].state"></lib-state-icon>
                <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <lib-tile-text
                        class="da-tile-header"
                        size="20px"
                        [convertMobile]="true"
                        label="{{'PERSONAL.TILES.TILE2.TITLE' | translate}}"
                    ></lib-tile-text>
                    <lib-input
                        #plz
                        label="{{'COMMON.LABELS.PLZ' | translate}}"
                        name="plz"
                        [error]="false"
                        validationType="numbers"
                        errorText="{{'ADDRESS.ERRORS.PLZ' | translate}}"
                        placeholder="12345"
                        [isCurrency]="false"
                        [inputDisabled]="inputDisabled"
                        (click)="testValid = true"
                        [touched]="!!contentService.getValue('wohnsituationPlz')"
                        [value]="!!contentService.getValue('wohnsituationPlz') ? contentService.getValue('wohnsituationPlz') : ''"
                        (change)="validateInput($event, plz, 'PostalCode')"
                    ></lib-input>
                </div>
            </div>

            <!-- TILE3 -->
            <div #familyContainer
                class="da-container" 
                (click)="getIndex('Family')"
                [ngClass]="{ 'mobile' : screen.isSmallest() }"
                >
                <lib-state-icon [state]="fakeTiles?.[2].state"></lib-state-icon>
                <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <lib-tile-text
                        class="da-tile-header more-margin"
                        size="20px"
                        [convertMobile]="true"
                        label="{{'PERSONAL.TILES.TILE3.TITLE' | translate}}"
                    ></lib-tile-text>
                    <lib-dropdown 
                        #family
                        name="family"
                        [selectItems]="familyItems" 
                        [selectedItem]="family.selectedItem ?? selectedFamilyItem"
                        label="{{'COMMON.LABELS.STATUS' | translate}}"
                        (selectValue)="getSelectedValue($event, family, 'Family')"
                        [autoWidth]="true">
                    </lib-dropdown>
                </div>
            </div>

            <!-- TILE4 -->
            <div #stateContainer
                class="da-container" 
                (click)="getIndex('State')"
                [ngClass]="{ 'mobile' : screen.isSmallest() }"
                >
                <lib-state-icon [state]="fakeTiles?.[3].state"></lib-state-icon>
                <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <lib-tile-text
                        class="da-tile-header more-margin"
                        size="20px"
                        [convertMobile]="true"
                        label="{{'PERSONAL.TILES.TILE4.TITLE' | translate}}"
                    ></lib-tile-text>
                    <lib-dropdown 
                        #staat
                        name="staat"
                        [selectItems]="staatItems" 
                        [selectedItem]="staat.selectedItem ?? selectedStaatItem"
                        label="{{'COMMON.LABELS.LAND' | translate}}"
                        (selectValue)="getSelectedValue($event, staat, 'State')"
                        [autoWidth]="true">
                    </lib-dropdown>
                <ng-container *ngIf="showStaatDate">
                    <lib-tile-text
                        size="20px"
                        [convertMobile]="true"
                        label="{{'PERSONAL.TILES.TILE4.TEXT1' | translate}}"
                    ></lib-tile-text>
                    <lib-input-date
                        class="less-margin-bottom"
                        #staatdate
                        [maxDate]="today"
                        label="{{'COMMON.LABELS.DATE' | translate}}"
                        name="staatdate"
                        [touched]="!!contentService.getValue('inDeutschlandSeit')"
                        [error]="false"
                        [value]="contentService.getValue('inDeutschlandSeit') ? contentService.getValue('inDeutschlandSeit') : ''"
                        errorText="{{'COMMON.ERRORS.DATE' | translate}}"
                        placeholder="tt.mm.jjjj"
                        [inputDisabled]="inputDisabled"
                        (click)="testValid = true"
                        (dateInputValue)="validateDate($event, staatdate, 'State', true)"
                        (dateValue)="validateDate($event, staatdate, 'State')"
                    ></lib-input-date>
                    <lib-tile-text
                        size="20px"
                        [convertMobile]="true"
                        label="{{'PERSONAL.TILES.TILE4.TEXT2' | translate}}"
                    ></lib-tile-text>
                    <lib-dropdown 
                        class="padding-top"
                        #visum
                        name="visum"
                        [selectItems]="visumItems" 
                        [selectedItem]="visum.selectedItem ?? selectedVisumItem"
                        label="{{'COMMON.LABELS.STATUS' | translate}}"
                        (selectValue)="getSelectedValue($event, visum, 'State')"
                        [autoWidth]="true">
                    </lib-dropdown>
                    <ng-container *ngIf="showVisumDate">
                        <lib-tile-text
                            size="20px"
                            [convertMobile]="true"
                            label="{{'PERSONAL.TILES.TILE4.TEXT3' | translate}}"
                        ></lib-tile-text>
                        <lib-input-date
                            class="less-margin-bottom"
                            #visumdate
                            [minDate]="today"
                            label="{{'COMMON.LABELS.DATE' | translate}}"
                            name="visumdate"
                            [value]="!!contentService.getValue('visumBefristetBis') ? contentService.getValue('visumBefristetBis') : ''"
                            [touched]="!!contentService.getValue('visumBefristetBis')"
                            [error]="false"
                            [inputDisabled]="inputDisabled"
                            (click)="testValid = true"
                            errorText="{{'COMMON.ERRORS.DATE' | translate}}"
                            placeholder="tt.mm.jjjj"
                            (dateInputValue)="validateDate($event, visumdate, 'State', true)"
                            (dateValue)="validateDate($event, visumdate, 'State')"
                        ></lib-input-date>
                        <ng-container *ngIf="contentService.getValue('aufenthaltstitel') !== 'BLAUEKARTE_EU'">
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'PERSONAL.TILES.TILE4.TEXT4' | translate}}"
                            ></lib-tile-text>
                            <lib-toggle
                                #permit
                                label1="{{'COMMON.TRUE' | translate}}"
                                label2="{{'COMMON.FALSE' | translate}}"
                                name="permit"
                                value1="ARBEITSERLAUBNIS_VORHANDEN"
                                value2="KEINE_ARBEITSERLAUBNIS"
                                [localValue]="contentService.getValue('arbeitserlaubnis')"
                                (switchValue)="setSwitch($event, permit, 'State')"
                            ></lib-toggle>
                        </ng-container>
                        <ng-container *ngIf="showPermitDate">
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'PERSONAL.TILES.TILE4.TEXT5' | translate}}"
                            ></lib-tile-text>
                            <lib-input-date
                                class="less-margin-bottom"
                                #permitdate
                                [minDate]="today"
                                label="{{'COMMON.LABELS.DATE' | translate}}"
                                name="permitdate"
                                [touched]="!!contentService.getValue('arbeitserlaubnisBefristetBis')"
                                [value]="!!contentService.getValue('arbeitserlaubnisBefristetBis') ? contentService.getValue('arbeitserlaubnisBefristetBis') : ''"
                                [error]="false"
                                [inputDisabled]="inputDisabled"
                                (click)="testValid = true"
                                errorText="{{'COMMON.ERRORS.DATE' | translate}}"
                                placeholder="tt.mm.jjjj"
                                (dateInputValue)="validateDate($event, permitdate, 'State', true)"
                                (dateValue)="validateDate($event, permitdate, 'State')"
                            ></lib-input-date>
                        </ng-container>
                    </ng-container>
                </ng-container>
                </div>
            </div>

            <!-- TILE5 -->
            <div #jobContainer
                class="da-container" 
                (click)="getIndex('Job')"
                [ngClass]="{ 'mobile' : screen.isSmallest() }"
                >
                <lib-state-icon [state]="fakeTiles?.[4].state"></lib-state-icon>
                <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <lib-tile-text
                        class="da-tile-header more-margin"
                        size="20px"
                        [convertMobile]="true"
                        label="{{'PERSONAL.TILES.TILE5.TITLE' | translate}}"
                    ></lib-tile-text>
                    <lib-dropdown 
                        #job
                        name="job"
                        [selectItems]="jobItems" 
                        [selectedItem]="job.selectedItem ?? selectedJobItem"
                        label="{{'COMMON.LABELS.JOB_TYPE' | translate}}"
                        (selectValue)="getSelectedValue($event, job, 'Job')"
                        [autoWidth]="true">
                    </lib-dropdown>
                    <ng-container *ngIf="showJobDetail">
                        <lib-tile-text
                            size="20px"
                            [convertMobile]="true"
                            label="{{'PERSONAL.TILES.TILE5.TEXT1' | translate}}"
                        ></lib-tile-text>
                        <lib-input
                            #jobDescription
                            label="{{'COMMON.LABELS.JOB' | translate}}"
                            name="jobDescription"
                            [error]="false"
                            validationType="string"
                            inputmode=""
                            errorText="{{'COMMON.ERRORS.JOB' | translate}}"
                            placeholder="Lehrer"
                            [inputDisabled]="inputDisabled"
                            (click)="testValid = true"
                            [touched]="!!contentService.getValue('beruf')"
                            [value]="!!contentService.getValue('beruf') ? contentService.getValue('beruf') : ''"
                            (change)="validateInput($event, jobDescription, 'Job')"
                        ></lib-input>
                        <lib-tile-text
                            size="20px"
                            [convertMobile]="true"
                            label="{{ tile5Text2 | translate}}"
                        ></lib-tile-text>
                        <lib-input-date
                            class="less-margin-bottom"
                            #jobdate
                            [maxDate]="today"
                            label="{{'COMMON.LABELS.DATE' | translate}}"
                            name="jobdate"
                            [value]="!!contentService.getValue('beschaeftigtSeit') ? contentService.getValue('beschaeftigtSeit') : ''"
                            [touched]="!!contentService.getValue('beschaeftigtSeit')"
                            [error]="false"
                            [inputDisabled]="inputDisabled"
                            (click)="testValid = true"
                            errorText="{{'COMMON.ERRORS.DATE' | translate}}"
                            placeholder="tt.mm.jjjj"
                            (dateInputValue)="validateDate($event, jobdate, 'Job', true)"
                            (dateValue)="validateDate($event, jobdate, 'Job')"
                        ></lib-input-date>
                        <ng-container *ngIf="job.selectedItem.value !== 'BEAMTER'">
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'PERSONAL.TILES.TILE5.TEXT3' | translate}}"
                            ></lib-tile-text>
                            <lib-toggle
                                #probezeit
                                label1="{{'COMMON.TRUE' | translate}}"
                                label2="{{'COMMON.FALSE' | translate}}"
                                name="probezeit"
                                value1="true"
                                value2="false"
                                [localValue]="contentService.getValue('probezeit')"
                                (switchValue)="setSwitch($event, probezeit, 'Job')"
                            ></lib-toggle>
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'PERSONAL.TILES.TILE5.TEXT4' | translate}}"
                            ></lib-tile-text>
                            <lib-toggle
                                #befristet
                                label1="{{'COMMON.TRUE' | translate}}"
                                label2="{{'COMMON.FALSE' | translate}}"
                                name="befristet"
                                value1="BEFRISTET"
                                value2="UNBEFRISTET"
                                [localValue]="contentService.getValue('beschaeftigungsstatus')"
                                (switchValue)="setSwitch($event, befristet, 'Job')"
                            ></lib-toggle>
                        </ng-container>
                        <lib-tile-text
                            size="20px"
                            [convertMobile]="true"
                            label="{{tile5Text5 | translate}}"
                        ></lib-tile-text>
                        <lib-input
                            #gehalt
                            label="{{'COMMON.LABELS.FEE' | translate}}"
                            name="gehalt"
                            [error]="false"
                            errorText="{{'COMMON.ERRORS.NUMBER' | translate}}"
                            placeholder="0,00"
                            unit="€"
                            [inputDisabled]="inputDisabled"
                            (click)="testValid = true"
                            [touched]="!!contentService.getValue('gehalt')"        
                            [value]="!!contentService.getValue('gehalt') ? contentService.getValue('gehalt') : ''"
                            (change)="validateInput($event, gehalt, 'Job')"
                        ></lib-input>
                        <lib-tile-text
                            size="20px"
                            [convertMobile]="true"
                            label="{{tile5Text6 | translate}}"
                        ></lib-tile-text>
                        <lib-dropdown 
                            #gehaltNumber
                            name="gehaltNumber"
                            [selectItems]="gehaltItems" 
                            [selectedItem]="gehaltNumber.selectedItem ?? selectedGehaltItem"
                            label=""
                            (selectValue)="getSelectedValue($event, gehaltNumber, 'Job')"
                            [autoWidth]="true">
                        </lib-dropdown>
                        <ng-container *ngIf="job.selectedItem.value === 'BEAMTER' || job.selectedItem.value === 'ANGESTELLTER'">
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'PERSONAL.TILES.TILE5.TEXT1_ELTERN' | translate}}"
                            ></lib-tile-text>
                            <lib-toggle
                                #eltern
                                label1="{{'COMMON.TRUE' | translate}}"
                                label2="{{'COMMON.FALSE' | translate}}"
                                name="eltern"
                                value1="ElternzeitBesteht"
                                value2="KeineElternzeit"
                                [localValue]="contentService.getValue('elternzeit')"
                                (switchValue)="setSwitch($event, eltern, 'Job')"
                            ></lib-toggle>
                            <ng-container *ngIf="contentService.getValue('elternzeit') === 'ElternzeitBesteht'">
                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'PERSONAL.TILES.TILE5.TEXT2_ELTERN' | translate}}"
                                ></lib-tile-text>
                                <lib-input
                                    #elterngeld
                                    label="{{'COMMON.LABELS.FEE' | translate}}"
                                    name="elterngeld"
                                    [error]="false"
                                    errorText="{{'COMMON.ERRORS.NUMBER' | translate}}"
                                    placeholder="0,00"
                                    unit="€"
                                    [inputDisabled]="inputDisabled"
                                    (click)="testValid = true"
                                    [touched]="!!contentService.getValue('elterngeld')"
                                    [value]="!!contentService.getValue('elterngeld') ? contentService.getValue('elterngeld') : ''"
                                    (change)="validateInput($event, elterngeld, 'Job')"
                                ></lib-input>
                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'PERSONAL.TILES.TILE5.TEXT3_ELTERN' | translate}}"
                                ></lib-tile-text>
                                <lib-input-date
                                    class="less-margin-bottom"
                                    #elternzeit
                                    [minDate]="today"
                                    label="{{'COMMON.LABELS.DATE' | translate}}"
                                    name="elternzeit"
                                    [value]="!!contentService.getValue('elternzeitBis') ? contentService.getValue('elternzeitBis') : ''"
                                    [touched]="!!contentService.getValue('elternzeitBis')"
                                    [error]="false"
                                    [inputDisabled]="inputDisabled"
                                    (click)="testValid = true"
                                    errorText="{{'COMMON.ERRORS.DATE' | translate}}"
                                    placeholder="tt.mm.jjjj"
                                    (dateInputValue)="validateDate($event, elternzeit, 'Job', true)"
                                    (dateValue)="validateDate($event, elternzeit, 'Job')"
                                ></lib-input-date>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="showIndependent">
                        <lib-tile-text
                            size="20px"
                            [convertMobile]="true"
                            label="{{'PERSONAL.TILES.TILE5.INDEPENDENT.TEXT1' | translate}}"
                        ></lib-tile-text>
                        <lib-input
                            #taetigkeit
                            label="{{'COMMON.LABELS.GEWERBE' | translate}}"
                            name="taetigkeit"
                            [error]="false"
                            [inputDisabled]="inputDisabled"
                            (click)="testValid = true"
                            validationType="string"
                            inputmode=""
                            errorText="{{'COMMON.ERRORS.JOB' | translate}}"
                            placeholder="Restaurateur"
                            [touched]="!!contentService.getValue('SelbststaendigeTaetigkeit')"
                            [value]="!!contentService.getValue('SelbststaendigeTaetigkeit') ? contentService.getValue('SelbststaendigeTaetigkeit') : ''"
                            (change)="validateInput($event, taetigkeit, 'Job')"
                        ></lib-input>
                        <lib-tile-text
                            size="20px"
                            [convertMobile]="true"
                            label="{{'PERSONAL.TILES.TILE5.INDEPENDENT.TEXT2' | translate}}"
                        ></lib-tile-text>
                        <lib-input-date
                            class="less-margin-bottom"
                            #independentdate
                            [maxDate]="today"
                            label="{{'COMMON.LABELS.DATE' | translate}}"
                            name="independentdate"
                            [value]="!!contentService.getValue('taetigSeit') ? contentService.getValue('taetigSeit') : ''"
                            [touched]="!!contentService.getValue('taetigSeit')"
                            [error]="false"
                            [inputDisabled]="inputDisabled"
                            (click)="testValid = true"
                            errorText="{{'COMMON.ERRORS.DATE' | translate}}"
                            placeholder="tt.mm.jjjj"
                            (dateInputValue)="validateDate($event, independentdate, 'Job', true)"
                            (dateValue)="validateDate($event, independentdate, 'Job')"
                        ></lib-input-date>
                        <lib-tile-text
                            size="20px"
                            [convertMobile]="true"
                            label="{{'PERSONAL.TILES.TILE5.INDEPENDENT.TEXT3' | translate}}"
                        ></lib-tile-text>
                        <lib-input
                            #gewinnCurrent
                            label="{{'COMMON.LABELS.FEE' | translate}}"
                            name="gewinnCurrent"
                            [error]="false"
                            errorText="{{'COMMON.ERRORS.NUMBER' | translate}}"
                            placeholder="0,00"
                            unit="€"
                            [inputDisabled]="inputDisabled"
                            (click)="testValid = true"
                            [touched]="!!contentService.getValue('gewinnAktuellesJahr')"
                            [value]="!!contentService.getValue('gewinnAktuellesJahr') ? contentService.getValue('gewinnAktuellesJahr') : ''"
                            (change)="validateInput($event, gewinnCurrent, 'Job')"
                        ></lib-input>
                        <lib-tile-text
                            size="20px"
                            [convertMobile]="true"
                            label="{{'PERSONAL.TILES.TILE5.INDEPENDENT.TEXT4' | translate}}"
                        ></lib-tile-text>
                        <lib-input
                            #gewinnPrevious
                            label="{{'COMMON.LABELS.FEE' | translate}}"
                            name="gewinnPrevious"
                            [error]="false"
                            errorText="{{'COMMON.ERRORS.NUMBER' | translate}}"
                            placeholder="0,00"
                            unit="€"
                            [inputDisabled]="inputDisabled"
                            (click)="testValid = true"
                            [touched]="!!contentService.getValue('gewinnLetztesJahr')"
                            [value]="!!contentService.getValue('gewinnLetztesJahr') ? contentService.getValue('gewinnLetztesJahr') : ''"
                            (change)="validateInput($event, gewinnPrevious, 'Job')"
                        ></lib-input>
                        <lib-tile-text
                            size="20px"
                            [convertMobile]="true"
                            label="{{'PERSONAL.TILES.TILE5.INDEPENDENT.TEXT5' | translate}}"
                        ></lib-tile-text>
                        <lib-input
                            #gewinnPrePrevious
                            label="{{'COMMON.LABELS.FEE' | translate}}"
                            name="gewinnPrePrevious"
                            [error]="false"
                            errorText="{{'COMMON.ERRORS.NUMBER' | translate}}"
                            placeholder="0,00"
                            unit="€"
                            [inputDisabled]="inputDisabled"
                            (click)="testValid = true"
                            [touched]="!!contentService.getValue('gewinnVorletztesJahr')"
                            [value]="!!contentService.getValue('gewinnVorletztesJahr') ? contentService.getValue('gewinnVorletztesJahr') : ''"
                            (change)="validateInput($event, gewinnPrePrevious, 'Job')"
                        ></lib-input>
                    </ng-container>
                    <ng-container *ngIf="showRentner">
                        <lib-tile-text
                            size="20px"
                            [convertMobile]="true"
                            label="{{'PERSONAL.TILES.TILE5.RENTNER.TEXT2' | translate}}"
                        ></lib-tile-text>
                        <lib-input
                            #renteBetrag
                            label="{{'COMMON.LABELS.FEE' | translate}}"
                            name="renteBetrag"
                            [error]="false"
                            errorText="{{'COMMON.ERRORS.NUMBER' | translate}}"
                            placeholder="0,00"
                            unit="€"
                            [inputDisabled]="inputDisabled"
                            (click)="testValid = true"
                            [touched]="!!contentService.getValue('monatlicheRente')"
                            [value]="!!contentService.getValue('monatlicheRente') ? contentService.getValue('monatlicheRente') : ''"
                            (change)="validateInput($event, renteBetrag, 'Job')"
                        ></lib-input>
                        <ng-container *ngIf="contentService.getValue('monatlicheRente')">
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'PERSONAL.TILES.TILE5.RENTNER.TEXT1' | translate}}"
                            ></lib-tile-text>
                            <lib-toggle-vertical
                                #typRente
                                [options]="switches"
                                [localValue]="contentService.getValue('typRente')"
                                name="typRente"
                                (switchValue)="setSwitch($event, typRente, 'Job')"
                            ></lib-toggle-vertical>
                        </ng-container>
                        <ng-container *ngIf="contentService.getValue('typRente') === 'Altersrente'">
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'PERSONAL.TILES.TILE5.RENTNER.TEXT3' | translate}}"
                            ></lib-tile-text>
                            <lib-input
                                #rentePrivatBetrag
                                label="{{'COMMON.LABELS.FEE' | translate}}"
                                name="rentePrivatBetrag"
                                [error]="false"
                                errorText="{{errorRentePrivat | translate}}"
                                placeholder="0,00"
                                unit="€"
                                [inputDisabled]="inputDisabled"
                                (click)="testValid = true"
                                [touched]="!!contentService.getValue('AnteilPrivatRente')"
                                [value]="!!contentService.getValue('AnteilPrivatRente') ? contentService.getValue('AnteilPrivatRente') : ''"
                                (change)="validateInput($event, rentePrivatBetrag, 'Job')"
                            ></lib-input>
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'PERSONAL.TILES.TILE5.RENTNER.TEXT4' | translate}}"
                            ></lib-tile-text>
                            <lib-input
                                #renteBetrieblichBetrag
                                label="{{'COMMON.LABELS.FEE' | translate}}"
                                name="renteBetrieblichBetrag"
                                [error]="false"
                                errorText="{{errorRenteBetrieblich | translate}}"
                                placeholder="0,00"
                                unit="€"
                                [inputDisabled]="inputDisabled"
                                (click)="testValid = true"
                                [touched]="!!contentService.getValue('AnteilBetriebsRente')"
                                [value]="!!contentService.getValue('AnteilBetriebsRente') ? contentService.getValue('AnteilBetriebsRente') : ''"
                                (change)="validateInput($event, renteBetrieblichBetrag, 'Job')"
                            ></lib-input>
                        </ng-container>
                        <ng-container *ngIf="contentService.getValue('typRente') === 'Erwerbsminderungsrente'">
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'PERSONAL.TILES.TILE5.RENTNER.TEXT5' | translate}}"
                            ></lib-tile-text>
                            <lib-toggle
                                #renteFrist
                                label1="{{'COMMON.TRUE' | translate}}"
                                label2="{{'COMMON.FALSE' | translate}}"
                                name="renteFrist"
                                value1="befristet"
                                value2="unbefristet"
                                [localValue]="contentService.getValue('RenteBefristet')"
                                (switchValue)="setSwitch($event, renteFrist, 'Job')"
                            ></lib-toggle>
                            <ng-container *ngIf="contentService.getValue('RenteBefristet') === 'befristet'">
                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'PERSONAL.TILES.TILE5.RENTNER.TEXT6' | translate}}"
                                ></lib-tile-text>
                                <lib-input-date
                                    class="less-margin-bottom"
                                    #rentedate
                                    [minDate]="today"
                                    label="{{'COMMON.LABELS.DATE' | translate}}"
                                    name="rentedate"
                                    [value]="!!contentService.getValue('RenteBefristetBis') ? contentService.getValue('RenteBefristetBis') : ''"
                                    [touched]="!!contentService.getValue('RenteBefristetBis')"
                                    [error]="false"
                                    [inputDisabled]="inputDisabled"
                                    (click)="testValid = true"
                                    errorText="{{'COMMON.ERRORS.DATE' | translate}}"
                                    placeholder="tt.mm.jjjj"
                                    (dateInputValue)="validateDate($event, rentedate, 'Job', true)"
                                    (dateValue)="validateDate($event, rentedate, 'Job')"
                                ></lib-input-date>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>
