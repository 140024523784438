<div class="login-light-exit" [ngClass]="{'xs-device': isMobile()}">
    <div class="da-info-icon">
        <svg-icon src="assets/images/diskSave.svg"></svg-icon>
    </div>
    <div class="title">
        <lib-header-text
            class="da-info-header"
            size="38px"
            [convertMobile]="true"
            [light]="false"
            label="{{'LOGIN_LIGHT.FORM.LABEL_PROGRESS' | translate}}"
        ></lib-header-text>
    </div>
    <div class="da-info-content">
        <app-login-light-info></app-login-light-info>
        <div class="exit-content">
            <div class="buttons">
                <div class="button-container">
                    <lib-button value="{{ 'BUTTONS.CLOSE' | translate }}"
                                [autoWidth]="true"
                                iconLeft="assets/images/chevronLeft.svg"
                                (click)="onCloseLoginLightSidebar()">
                    </lib-button>
                </div>
                <div class="button-container">
                    <lib-button value="{{ 'BUTTONS.SAVE' | translate }}"
                                [autoWidth]="true"
                                iconRight="assets/images/chevronRight.svg"
                                (click)="handleSaveButton()">
                    </lib-button>
                </div>
            </div>
            <div class="horizontal-line">
                <p><span>{{ 'COMMON.OR' | translate }}</span></p>
            </div>
            <div>
                <lib-header-text
                    class="da-info-header"
                    size="34px"
                    [convertMobile]="true"
                    [light]="false"
                    label="{{'LOGIN_LIGHT.INFO_HEADER_EXIT' | translate}}"
                ></lib-header-text>
                <div class="flex-center">
                    <div class="booking-container">
                        <lib-button value="{{ 'BUTTONS.BOOK' | translate }}"
                                    [autoWidth]="true"
                                    iconRight="assets/images/chevronRight.svg"
                                    (click)="onBookConsultationAppointment()">
                        </lib-button>
                        <div class="booking-container-info">
                            <p>{{ 'BUTTONS.SUB_TEXT' | translate }}</p>
                        </div>
                    </div>
                </div>
                <div class="exit-info">
                    <lib-tile-text
                        class="da-info-content-headline"
                        label="{{'LOGIN_LIGHT.INFO_TEXT_EXIT' | translate}}"
                        [light]="false"
                        size="20px"
                    ></lib-tile-text>
                    <div class="list">
                        <div class="list-element">
                            <div class="list-element-icon">
                                <svg-icon src="assets/images/userHeadsetSolid.svg"></svg-icon>
                            </div>
                            <div class="da-info-list-element-text">
                                <a href="tel:{{tel}}">{{tel}}</a>
                            </div>
                        </div>
                        <div class="list-element">
                            <div class="list-element-icon">
                                <svg-icon src="assets/images/envelopeSolid.svg"></svg-icon>
                            </div>
                            <div class="da-info-list-element-text">
                                <a href="mailto:{{email}}">{{email}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>