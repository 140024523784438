<!-- Reihe 1 -->
<lib-header-text
    class="da-title"
    label="{{'MF_HOUSE_DETAIL.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<div class="da-tiles-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container1State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'MF_HOUSE_DETAIL.TILES.TILE1.TITLE' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(estateInfo)"
            >
            </lib-tile-text>
            <lib-input
                #objectEstateSize
                label="{{'COMMON.LABELS.SIZE' | translate}}"
                name="estateSize"
                [error]="false"
                placeholder="1.000,00"
                unit="m²"
                (click)="testValid = true"
                [inputDisabled]="inputDisabled"
                errorText="{{sizeError | translate}}"
                [touched]="!!contentService.getValue('objectGrundstuecksgroesse')"
                [value]="!!contentService.getValue('objectGrundstuecksgroesse') ? contentService.getValue('objectGrundstuecksgroesse') : ''"
                (change)="validateInputValue($event, objectEstateSize)"
            ></lib-input>
        </div>
    </div>
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container2State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'MF_HOUSE_DETAIL.TILES.TILE2.TITLE' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(yearInfo)"
            >
            </lib-tile-text>
            <lib-input
                #objectYear
                label="{{'COMMON.LABELS.YEAR' | translate}}"
                name="year"
                [error]="false"
                placeholder="1992"
                (click)="testValid = true"
                [inputDisabled]="inputDisabled"
                validationType="numbers"
                errorText="{{'COMMON.ERRORS.YEAR' | translate}}"
                [touched]="!!contentService.getValue('objectBaujahr')"
                [value]="!!contentService.getValue('objectBaujahr') ? contentService.getValue('objectBaujahr') : ''"
                (change)="validateYear($event, objectYear)"
                [isCurrency]="false"
            ></lib-input>
        </div>
    </div>




    <!-- WOHNEINHEITEN -->
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container3State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'MF_HOUSE_DETAIL.TILES.TILE3.TITLE' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(wohneinheitenInfo)"
            ></lib-tile-text>
            <lib-counter
                #objectWohneinheiten
                [value]="wohneinheiten"
                name="wohneinheiten"
                [max]="99"
                (counterValue)="setCounter($event, objectWohneinheiten)"
            ></lib-counter>
            <ng-container *ngIf="contentService.getValue('objectAnzahlDerWohneinheiten') > 0">
                <lib-tile-text
                    size="20px"
                    [convertMobile]="true"
                    label="{{'MF_HOUSE_DETAIL.TILES.TILE3.TEXT1' | translate}}"
                    info="true"
                    (infoValue)="screen.openInfo(houseInfo)"
                ></lib-tile-text>
                <lib-input
                    #objectHouseSize
                    label="{{'COMMON.LABELS.SIZE' | translate}}"
                    name="houseSize"
                    [error]="false"
                    placeholder="500,00"
                    unit="m²"
                    (click)="testValid = true"
                    [inputDisabled]="inputDisabled"
                    errorText="{{houseError | translate}}"
                    [touched]="!!contentService.getValue('objectGesamtflaecheWohnen')"
                    [value]="!!contentService.getValue('objectGesamtflaecheWohnen') ? contentService.getValue('objectGesamtflaecheWohnen') : ''"
                    (change)="validateInputValue($event, objectHouseSize)"
                ></lib-input>
                <ng-container *ngIf="!!contentService.getValue('objectGesamtflaecheWohnen')">
                    <lib-tile-text
                        size="20px"
                        [convertMobile]="true"
                        label="{{'MF_HOUSE_DETAIL.TILES.TILE3.TEXT2' | translate}}"
                        info="true"
                        (infoValue)="screen.openInfo(houseChangeInfo)"
                    ></lib-tile-text>
                    <lib-toggle
                        #objectWohnenChange
                        label1="{{'COMMON.TRUE' | translate}}"
                        label2="{{'COMMON.FALSE' | translate}}"
                        name="wohnenChange"
                        value1="true"
                        value2="false"
                        [inputDisabled]="inputDisabled"
                        [localValue]="contentService.getValue('objectWohnenChange')"
                        (switchValue)="storeInputValue(objectWohnenChange, $event)"
                    ></lib-toggle>
                    <ng-container *ngIf="!!contentService.getValue('objectWohnenChange')">
                        <lib-tile-text
                            *ngIf="contentService.getValue('objectWohnenChange') === 'true'"
                            size="20px"
                            [convertMobile]="true"
                            label="{{'MF_HOUSE_DETAIL.TILES.COMMON.SOLL.TEXT1' | translate}}"
                            info="true"
                            (infoValue)="screen.openInfo(sollInfo)"
                        ></lib-tile-text>
                        <lib-tile-text
                            *ngIf="contentService.getValue('objectWohnenChange') === 'false'"
                            size="20px"
                            [convertMobile]="true"
                            label="{{'MF_HOUSE_DETAIL.TILES.COMMON.IST.TEXT1' | translate}}"
                            info="true"
                            (infoValue)="screen.openInfo(istInfo)"
                        ></lib-tile-text>
                        <lib-toggle-vertical
                            #objectNutzungwohnflaeche
                            [options]="contentService.getValue('objectWohnenChange') === 'true' ? switchesYes : switchesNoWohnen"
                            [multiLine]="true"
                            [inputDisabled]="inputDisabled"
                            [localValue]="contentService.getValue('objectNutzungwohnflaeche')"
                            name="nutzungwohnflaeche"
                            (switchValue)="storeInputValue(objectNutzungwohnflaeche, $event)"
                        ></lib-toggle-vertical>
                        <ng-container *ngIf="contentService.getValue('objectNutzungwohnflaeche') === 'TEILVERMIETET'">
                            <lib-tile-text
                                *ngIf="contentService.getValue('objectWohnenChange') === 'true'"
                                size="20px"
                                [convertMobile]="true"
                                label="{{'MF_HOUSE_DETAIL.TILES.TILE3.SOLL.TEXT1' | translate}}"
                                info="true"
                                (infoValue)="screen.openInfo(rentedWohnenSollInfo)"
                            ></lib-tile-text>
                            <lib-tile-text
                                *ngIf="contentService.getValue('objectWohnenChange') === 'false'"
                                size="20px"
                                [convertMobile]="true"
                                label="{{'MF_HOUSE_DETAIL.TILES.TILE3.IST.TEXT1' | translate}}"
                                info="true"
                                (infoValue)="screen.openInfo(rentedWohnenIstInfo)"
                            ></lib-tile-text>
                            <lib-input
                                #objectRentedSizeWohnen
                                label="{{'COMMON.LABELS.SIZE' | translate}}"
                                name="rentedSizeWohnen"
                                [error]="false"
                                placeholder="200,00"
                                unit="m²"
                                (click)="testValid = true"
                                [inputDisabled]="inputDisabled"
                                errorText="{{rentedWError | translate}}"
                                [touched]="!!contentService.getValue('objectVermietetewohnflaeche')"
                                [value]="!!contentService.getValue('objectVermietetewohnflaeche') ? contentService.getValue('objectVermietetewohnflaeche') : ''"
                                (change)="validateInputValue($event, objectRentedSizeWohnen)"
                            ></lib-input>
                        </ng-container>
                        <ng-container *ngIf="contentService.getValue('objectNutzungwohnflaeche') === 'TEILVERMIETET' || contentService.getValue('objectNutzungwohnflaeche') === 'VERMIETET'">
                            <lib-tile-text
                                *ngIf="contentService.getValue('objectWohnenChange') === 'true'"
                                size="20px"
                                [convertMobile]="true"
                                label="{{'MF_HOUSE_DETAIL.TILES.COMMON.SOLL.TEXT2' | translate}}"
                                info="true"
                                (infoValue)="screen.openInfo(rentSollInfo)"
                            ></lib-tile-text>
                            <lib-tile-text
                                *ngIf="contentService.getValue('objectWohnenChange') === 'false'"
                                size="20px"
                                [convertMobile]="true"
                                label="{{'MF_HOUSE_DETAIL.TILES.COMMON.IST.TEXT2' | translate}}"
                                info="true"
                                (infoValue)="screen.openInfo(rentIstInfo)"
                            ></lib-tile-text>
                            <lib-input
                                #objectRentWohnen
                                label="{{'COMMON.LABELS.SUMME' | translate}}"
                                name="rentWohnen"
                                [error]="false"
                                placeholder="1.500,00"
                                unit="€"
                                (click)="testValid = true"
                                [inputDisabled]="inputDisabled"
                                errorText="{{rentWError | translate}}"
                                [touched]="!!contentService.getValue('objectMieteinnahmenwohnflaeche')"
                                [value]="!!contentService.getValue('objectMieteinnahmenwohnflaeche') ? contentService.getValue('objectMieteinnahmenwohnflaeche') : ''"
                                (change)="validateInputValue($event, objectRentWohnen)"
                            ></lib-input>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>



    <!-- GEWERBEEINHEITEN -->
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container4State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'MF_HOUSE_DETAIL.TILES.TILE4.TITLE' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(gewerbeeinheitenInfo)"
            ></lib-tile-text>
            <lib-counter
                #objectGewerbeeinheiten
                [value]="gewerbeeinheiten"
                name="gewerbeeinheiten"
                [max]="99"
                (counterValue)="setCounter($event, objectGewerbeeinheiten)"
            ></lib-counter>   
            <ng-container *ngIf="contentService.getValue('objectAnzahlDerGewerbeeinheiten') > 0">
                <lib-tile-text
                    size="20px"
                    [convertMobile]="true"
                    label="{{'MF_HOUSE_DETAIL.TILES.TILE4.TEXT1' | translate}}"
                    info="true"
                    (infoValue)="screen.openInfo(gewerbeInfo)"
                ></lib-tile-text>
                <lib-input
                    #objectGewerbeSize
                    label="{{'COMMON.LABELS.SIZE' | translate}}"
                    name="gewerbeSize"
                    [error]="false"
                    placeholder="500,00"
                    unit="m²"
                    (click)="testValid = true"
                    [inputDisabled]="inputDisabled"
                    errorText="{{gewerbeError | translate}}"
                    [touched]="!!contentService.getValue('objectGesamtflaecheGewerbe')"
                    [value]="!!contentService.getValue('objectGesamtflaecheGewerbe') ? contentService.getValue('objectGesamtflaecheGewerbe') : ''"
                    (change)="validateInputValue($event, objectGewerbeSize)"
                ></lib-input>
                <ng-container *ngIf="!!contentService.getValue('objectGesamtflaecheGewerbe')">
                    <lib-tile-text
                        size="20px"
                        [convertMobile]="true"
                        label="{{'MF_HOUSE_DETAIL.TILES.TILE4.TEXT2' | translate}}"
                        info="true"
                        (infoValue)="screen.openInfo(gewerbeChangeInfo)"
                    ></lib-tile-text>
                    <lib-toggle
                        #objectGewerbeChange
                        label1="{{'COMMON.TRUE' | translate}}"
                        label2="{{'COMMON.FALSE' | translate}}"
                        name="gewerbeChange"
                        [inputDisabled]="inputDisabled"
                        value1="true"
                        value2="false"
                        [localValue]="contentService.getValue('objectGewerbeChange')"
                        (switchValue)="storeInputValue(objectGewerbeChange, $event)"
                    ></lib-toggle>
                    <ng-container *ngIf="!!contentService.getValue('objectGewerbeChange')">
                        <lib-tile-text
                            *ngIf="contentService.getValue('objectGewerbeChange') === 'true'"
                            size="20px"
                            [convertMobile]="true"
                            label="{{'MF_HOUSE_DETAIL.TILES.COMMON.SOLL.TEXT1' | translate}}"
                            info="true"
                            (infoValue)="screen.openInfo(sollInfo)"
                        ></lib-tile-text>
                        <lib-tile-text
                            *ngIf="contentService.getValue('objectGewerbeChange') === 'false'"
                            size="20px"
                            [convertMobile]="true"
                            label="{{'MF_HOUSE_DETAIL.TILES.COMMON.IST.TEXT1' | translate}}"
                            info="true"
                            (infoValue)="screen.openInfo(istInfo)"
                        ></lib-tile-text>
                        <lib-toggle-vertical
                            #objectNutzunggewerbeflaeche
                            [options]="contentService.getValue('objectGewerbeChange') === 'true' ? switchesYes : switchesNoGewerbe"
                            [multiLine]="true"
                            [inputDisabled]="inputDisabled"
                            [localValue]="contentService.getValue('objectNutzunggewerbeflaeche')"
                            name="nutzunggewerbeflaeche"
                            (switchValue)="storeInputValue(objectNutzunggewerbeflaeche, $event)"
                        ></lib-toggle-vertical>
                        <ng-container *ngIf="contentService.getValue('objectNutzunggewerbeflaeche') === 'TEILVERMIETET'">
                            <lib-tile-text
                                *ngIf="contentService.getValue('objectGewerbeChange') === 'true'"
                                size="20px"
                                [convertMobile]="true"
                                label="{{'MF_HOUSE_DETAIL.TILES.TILE4.SOLL.TEXT1' | translate}}"
                                info="true"
                                (infoValue)="screen.openInfo(rentedGewerbeSollInfo)"
                            ></lib-tile-text>
                            <lib-tile-text
                                *ngIf="contentService.getValue('objectGewerbeChange') === 'false'"
                                size="20px"
                                [convertMobile]="true"
                                label="{{'MF_HOUSE_DETAIL.TILES.TILE4.IST.TEXT1' | translate}}"
                                info="true"
                                (infoValue)="screen.openInfo(rentedGewerbeIstInfo)"
                            ></lib-tile-text>
                            <lib-input
                                #objectRentedSizeGewerbe
                                label="{{'COMMON.LABELS.SIZE' | translate}}"
                                name="rentedSizeGewerbe"
                                [error]="false"
                                placeholder="200,00"
                                unit="m²"
                                (click)="testValid = true"
                                [inputDisabled]="inputDisabled"
                                errorText="{{rentedGError | translate}}"
                                [touched]="!!contentService.getValue('objectVermietetegewerbeflaeche')"
                                [value]="!!contentService.getValue('objectVermietetegewerbeflaeche') ? contentService.getValue('objectVermietetegewerbeflaeche') : ''"
                                (change)="validateInputValue($event, objectRentedSizeGewerbe)"
                            ></lib-input>
                        </ng-container>
                        <ng-container *ngIf="contentService.getValue('objectNutzunggewerbeflaeche') === 'TEILVERMIETET' || contentService.getValue('objectNutzunggewerbeflaeche') === 'VERMIETET'">
                            <lib-tile-text
                                *ngIf="contentService.getValue('objectGewerbeChange') === 'true'"
                                size="20px"
                                [convertMobile]="true"
                                label="{{'MF_HOUSE_DETAIL.TILES.COMMON.SOLL.TEXT2' | translate}}"
                                info="true"
                                (infoValue)="screen.openInfo(rentSollInfo)"
                            ></lib-tile-text>
                            <lib-tile-text
                                *ngIf="contentService.getValue('objectGewerbeChange') === 'false'"
                                size="20px"
                                [convertMobile]="true"
                                label="{{'MF_HOUSE_DETAIL.TILES.COMMON.IST.TEXT2' | translate}}"
                                info="true"
                                (infoValue)="screen.openInfo(rentIstInfo)"
                            ></lib-tile-text>
                            <lib-input
                                #objectRentGewerbe
                                label="{{'COMMON.LABELS.SUMME' | translate}}"
                                name="rentGewerbe"
                                [error]="false"
                                placeholder="1.500,00"
                                unit="€"
                                (click)="testValid = true"
                                [inputDisabled]="inputDisabled"
                                errorText="{{rentGError | translate}}"
                                [touched]="!!contentService.getValue('objectMieteinnahmengewerbeflaeche')"
                                [value]="!!contentService.getValue('objectMieteinnahmengewerbeflaeche') ? contentService.getValue('objectMieteinnahmengewerbeflaeche') : ''"
                                (change)="validateInputValue($event, objectRentGewerbe)"
                            ></lib-input>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>    
    </div>
</div>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

<ng-template #estateInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.TILE1.INFO_HEADER' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.TILE1.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #yearInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.TILE2.INFO_HEADER' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.TILE2.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #wohneinheitenInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.TILE3.INFO_HEADER' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.TILE3.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #gewerbeeinheitenInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.TILE4.INFO_HEADER' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.TILE4.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #houseInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.TILE3.INFO_HEADER_TEXT1' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.TILE3.INFO_TEXT_TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #gewerbeInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.TILE4.INFO_HEADER_TEXT1' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.TILE4.INFO_TEXT_TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #houseChangeInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.TILE3.INFO_HEADER_TEXT2' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.TILE3.INFO_TEXT_TEXT2' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #gewerbeChangeInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.TILE4.INFO_HEADER_TEXT2' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.TILE4.INFO_TEXT_TEXT2' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #istInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.COMMON.IST.INFO_HEADER_TEXT1' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.COMMON.IST.INFO_TEXT_TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #sollInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.COMMON.SOLL.INFO_HEADER_TEXT1' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.COMMON.SOLL.INFO_TEXT_TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #rentedWohnenIstInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.TILE3.IST.INFO_HEADER1' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.TILE3.IST.INFO_TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #rentedWohnenSollInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.TILE3.SOLL.INFO_HEADER1' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.TILE3.SOLL.INFO_TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #rentedGewerbeIstInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.TILE4.IST.INFO_HEADER1' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.TILE4.IST.INFO_TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #rentedGewerbeSollInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.TILE4.SOLL.INFO_HEADER1' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.TILE4.SOLL.INFO_TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #rentIstInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.COMMON.IST.INFO_HEADER_TEXT2' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.COMMON.IST.INFO_TEXT_TEXT2' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #rentSollInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MF_HOUSE_DETAIL.TILES.COMMON.SOLL.INFO_HEADER_TEXT2' | translate}}" text="{{'MF_HOUSE_DETAIL.TILES.COMMON.SOLL.INFO_TEXT_TEXT2' | translate}}"></app-info-sidebar>
</ng-template>
