import { AfterViewInit, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { testDaC, testDaCShortcut, testDataTotal, testExpress } from 'src/assets/content/test-redux';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements AfterViewInit {
  public slideName: string = 'vehicles';
  next: string = 'financial-assets';
  localValue!: string;
  disabled: boolean = true;
  title: string = 'VEHICLES.TITLE';
  tiles = tilesContent['VEHICLES'].items;
  testRedux = testDaCShortcut;

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('content') content!: TemplateRef<any>;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public contentService: ContentService,
  ) { }

  ngAfterViewInit(): void {
    // this.daService.update(1, this.testRedux);
    setTimeout(() => {
      this.setStatus();
    });
  }

  setStatus() {

    const participants = this.dataQuery.getEntity(1)?.['participants'];

    if (!!participants && participants !== 'allein') {
      this.title = 'VEHICLES.TITLE_PARTNER';
    }
    this.disabled = true;
    const parent = this.dataQuery.getEntity(1)?.['haushaltPersonFahrzeuge'];
    const status = this.dataQuery.getEntity(1);

    if (!!parent) {
      this.localValue = parent;
      this.tiles = this.contentService.setTileChecked(this.tiles, parent);
      if (parent === 'VorhandeneFahrzeuge') {
        this.tiles = this.contentService.setTilesContent(this.tiles, parent, this.content);
        this.setSwitch(status?.['countFahrzeuge']);
        this.setTileStatus();
      } else {
        this.disabled = false;
        this.contentService.setSlideIndexState(this.slideName, false, 'success');
        this.stateChange.emit(true);
      }
    }
    this.daNextSlideService.update(1, { disabled: this.disabled });
  }

  setValue(e: string) {
    this.localValue = e;
    this.daService.update(1, { haushaltPersonFahrzeuge: e });
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
    if (e === 'VorhandeneFahrzeuge') {
      this.contentService.setTilesContent(this.tiles, e, this.content);
      this.setTileStatus();
    } else {
      this.disabled = false;
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.stateChange.emit(true);
      this.contentService.setNav('next', 'vehicles', this.slideTo, this.next);
    }
    this.daNextSlideService.update(1, { disabled: this.disabled });
  }

  setSwitch(e: string) {
    this.daService.update(1, { countFahrzeuge: e });
    this.setTileStatus();
  }

  setTileStatus() {
    const status = this.dataQuery.getEntity(1);
    this.disabled = true;

    if (!!status && !!status['countFahrzeuge']) {
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, this.localValue, 'success');
      this.disabled = false;
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
    } else {
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
    }
    this.stateChange.emit(true);
    this.daNextSlideService.update(1, { disabled: this.disabled });
  }

}
