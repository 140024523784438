<div class="da-forward-darlehen-wrapper">
    <lib-state-icon [state]="containerState" [ngStyle]="{'left': '0', 'top': '0', 'position': 'absolute'}"></lib-state-icon>
    <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}">
        <svg-icon class="da-forward-arrow" src="assets/images/minus.svg" ></svg-icon>
        <div class="da-forward-darlehen-row-part left">
            <lib-tile-text
                class="da-title"
                [ngClass]="{'more-margin-top': !screen.isSmallest()}"
                label="{{'FINANCE_REQUIREMENTS_FORWARD.TILGUNG.LABEL' | translate }}"
                size="16px"
                info="true"
                (infoValue)="screen.openInfo(eigenkapitalInfo)"
            ></lib-tile-text>
        </div>
        <div class="da-forward-darlehen-row-part right">
            <lib-input
                #betragPassiv
                #inputs
                label="{{'FINANCE_REQUIREMENTS_FORWARD.TILGUNG.LABEL1' | translate}}"
                name="betragPassiv"
                [error]="false"
                errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                placeholder="0,00"
                unit="€"
                [inputDisabled]="true"
                [touched]="!!contentService.getValue('eigenkapitalPassiv')"
                [value]="!!contentService.getValue('eigenkapitalPassiv') ? contentService.getValue('eigenkapitalPassiv') : '0,00'"
            ></lib-input>
            <lib-input-button
                #betragAktiv
                #inputs
                label="{{'FINANCE_REQUIREMENTS_FORWARD.TILGUNG.LABEL2' | translate}}"
                [error]="contentService.getValue('eigenkapitalInValid')"
                unit="€"
                [inputDisabled]="inputDisabled"
                (click)="testValid = true"
                name="betragAktiv"
                type="eigenkapital"
                [value]="!!contentService.getValue('eigenkapitalAktiv') ? contentService.getValue('eigenkapitalAktiv') : '0,00'"
                [touched]="!!contentService.getValue('eigenkapitalAktiv')"
                (inputValue)="screen.openInfo(eigenkapitalInput)"
            ></lib-input-button>
        </div>
    </div>
</div>
<ng-template #eigenkapitalInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS_FORWARD.TILGUNG.INFOS.HEADER' | translate}}" text="{{'FINANCE_REQUIREMENTS_FORWARD.TILGUNG.INFOS.TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #eigenkapitalInput let-modal>
    <app-eigenkapital-sidebar 
    [modal]="modal" 
    (eigenkapitalDone)="setValid()"
    ></app-eigenkapital-sidebar>
</ng-template>