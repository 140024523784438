<lib-header-text
    class="da-title"
    label="{{'ESTATE_STATUS.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>

<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
>
<ng-template #content>
    <lib-tile-text
        size="16px"
        label="{{'ESTATE_STATUS.TEXT' | translate}}"
        [light]="true"
    ></lib-tile-text>
    <lib-toggle
        #count
        label1="{{'COMMON.TRUE' | translate}}"
        label2="{{'COMMON.FALSE' | translate}}"
        name="count"
        value1="true"
        value2="false"
        [localValue]="contentService.getValue('projectInDeutschland')"
        (switchValue)="setSwitch($event)"
        [light]="true"
    ></lib-toggle>
</ng-template>
<ng-template #umleitung>
    <lib-tile-text
        size="16px"
        label="{{'ESTATE_STATUS.UMLEITUNG.TEXT' | translate}}"
        [light]="true"
    ></lib-tile-text>
    <lib-button 
        name="umleitung button"
        value="{{'ESTATE_STATUS.UMLEITUNG.BUTTON' | translate}}" 
        (buttonValue)="goToDaC()"
        [light]="true"
        [autoWidth]="true"
        [disabled]="false"
    ></lib-button>
</ng-template>
</lib-tile-group>
<div class="da-buttons-wrapper">
    <app-nav-buttons
        class="da-buttons-group"
        [save]="false"
        [nextDisabled]="disabled"
        [current]="slideName"
        [next]="next"
        (slideTo)="setNav($event)"
        >
    </app-nav-buttons>
</div>

