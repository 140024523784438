import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { testDataTotal, testEigentum, testEigentumswohnung, testMfh, testRequirements, testShortcut, testExpress, testDaC } from 'src/assets/content/test-redux';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-finance-needs-forward',
  templateUrl: './finance-needs-forward.component.html',
  styleUrls: ['./finance-needs-forward.component.scss']
})
export class FinanceNeedsForwardComponent implements OnInit {
  public slideName: string = 'finance-requirements-forward';
  testRedux = testDaC;
  darlehenCount: number = 0;
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  webappType = environment.webappType;
  hasAccess: boolean = false;

  validDarlehen: boolean = false;
  validModernisierung: boolean = false;
  validKapital: boolean = false;
  validTilgung: boolean = false;

  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public nextItem: EventEmitter<any> = new EventEmitter<string>();
  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('uebergabeA') uebergabe!: any;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
    this.daService.update(1, { ermittlungsweg: 'traditionell' });
    // this.daService.update(1, this.testRedux);
    setTimeout(() => {
      if(!!this.contentService.getValue('projectPlan')) {
        if(this.contentService.getValue('projectPlan') === 'ANSCHLUSSFINANZIERUNG') {
          if(!this.contentService.getValue('darlehencounterForward')) {
            this.setSwitch(1, 'darlehencounter');
            this.darlehenCount = 1;
          }
        }
        if(this.contentService.getValue('projectPlan') !== 'MODERNISIERUNG_UMBAU_ANBAU') {
          this.validModernisierung = true;
        }
        if(this.contentService.getValue('projectPlan') !== 'KAPITALBESCHAFFUNG') {
          this.validKapital = true;
        }
      }

      if (!this.contentService.getValue('eigenleistungValue')) {
        this.daService.update(1, { eigenleistungValue: '0,00' });
      }

      if (this.contentService.getValue('uebergabe')) {
        this.hasAccess = true;
      }

      // this.setValid('', 'init');
    }, 200)
  }

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'dashboard') {
        if (this.allState !== 'success') {
          this.showState = true;
        }
        const status = this.dataQuery.getEntity(1);
        this.setValid(status, 'test');
      } else {
        this.setNav(e);
      }
    }, 50)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  setSwitch(e: number, type: string) {
    switch (type) {
      case 'darlehencounter': {
        this.daService.update(1, { darlehencounterForward: e });
        setTimeout(() => {
          this.calculateGesamt();
        }, 100)
      }
        break;
    }
  }

  calculateGesamt() {
    let bedarf = 0;
    setTimeout(() => {
      if (this.contentService.getValue('darlehencounterForward', true) && this.contentService.getValue('darlehencounterForward', true) > 0) {
        const count = this.contentService.getValue('darlehencounterForward', true);
        const darlehen = this.contentService.getValue('darlehenForward');
        if(darlehen) {
          for(let i = 0; i < count; i++) {
            let amount = !!darlehen[i].darlehenBetrag ? this.contentService.convertStringNumber(darlehen[i].darlehenBetrag) : 0;
            bedarf = bedarf + amount;
            amount = 0;
          }
        }
      }

      if (!!this.contentService.getValue('modernisierungGesamtBetrag')) {
        bedarf = bedarf + this.contentService.convertStringNumber(this.contentService.getValue('modernisierungGesamtBetrag'));
      }

      if (!!this.contentService.getValue('abloeseGesamt')) {
        bedarf = bedarf + this.contentService.convertStringNumber(this.contentService.getValue('abloeseGesamt'));
      }

      if (!!this.contentService.getValue('kapitalbeschaffungBetrag')) {
        bedarf = bedarf + this.contentService.convertStringNumber(this.contentService.getValue('kapitalbeschaffungBetrag'));
      }

      if (!!this.contentService.getValue('eigenkapitalAktiv')) {
        bedarf = bedarf - this.contentService.convertStringNumber(this.contentService.getValue('eigenkapitalAktiv'));
      }

      this.daService.update(1, { darlehensbedarf: this.contentService.convertNumberString(bedarf) });
    }, 200)
  }

  setValid(event: any, type: string = 'default') {

    if(!this.contentService.getValue('darlehencounterForward', true) || this.contentService.getValue('darlehencounterForward', true) === 0) {
      this.validDarlehen = true;
    }

    if(event.type) {
      switch (event.type) {
        case 'darlehen': {
          this.validDarlehen = false;
          if(event.state === 'success') {
            const allDarlehen = this.contentService.getValue('darlehenForward');
            let isNotValid = 0;
            if(allDarlehen) {
              allDarlehen.map((item: any) => {
                if (!item.valid) {
                  isNotValid++
                }
              });
            }
            if(isNotValid === 0) {
              this.validDarlehen = true;
            }
          };
          break;
        }
        case 'modernisierung': {
          this.validModernisierung = false;
          if(event.state === 'success' || event.state === 'optional') {
            this.validModernisierung = true; 
          };
          break;
        }
        case 'kapital': {
          this.validKapital = false;
          if(event.state === 'success' || event.state === 'optional') {
            this.validKapital = true; 
          };
          break;
        }
        case 'tilgung': {
          this.validTilgung = false;
          if(event.state === 'success') {
            this.validTilgung = true; 
          };
          break;
        }
      }
    }
    
    if(this.validTilgung && this.validKapital && this.validModernisierung && this.validDarlehen) {
      this.disabled = false;
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.setAllState('success', 'COMPLETE');
    } else {
      this.disabled = true;
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.setAllState('warning', 'INCOMPLETE');
    }

    if (event.state === 'error') {
      this.contentService.setSlideIndexState(this.slideName, false, 'error');
      this.setAllState('error', 'ERROR');
    }

    this.stateChange.emit(true);

    if (!this.disabled) {
      if (type === 'test') {
        this.inputDisabled = true;
        this.testValid = false;
      }
    } else {
      this.testValid = true;
    }

    if (this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }

  setNav(e: string) {
    if (e === 'dashboard') {
      this.screen.openInfo(this.uebergabe);
    } else {
      this.slideTo.emit(e);
    }
  }

  setStateChange() {
    this.hasAccess = true;
    this.stateChange.emit('endItem');
    this.nextItem.emit();
  }

}
