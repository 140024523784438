<lib-header-text
    class="da-title"
    label="{{title | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>

<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
>
    <ng-template #content>
        <div class="da-tile-content-wrapper">
            <lib-tile-text
            size="16px"
            label="{{'CHILDREN.TILE_TEXT1' | translate}}"
            info="true"
            (infoValue)="screen.openInfo(childrenInfo)"
            [light]="true"
        ></lib-tile-text>
        <lib-counter
            #kinder
            [value]="contentService.getValue('kinderAnzahl', true)"
            name="kinder"
            [min]="1"
            [max]="8"
            (counterValue)="setCounter($event)"
            [light]="true"
        ></lib-counter>
        </div>
    </ng-template>
</lib-tile-group>
<ng-container *ngIf="showKids">
    <app-index 
        [tiles]="fakeTiles" 
        [selectedTile]="selectedTile"
        [slideFactor]="tileSlideFactor"
        [slidePos]="tilePosition"
        (indexValue)="getIndex($event, true)">
    </app-index>
    <div class="da-content-extended">
        <div class="da-content-extended-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest(), 'small': screen.isSmallerThanChildContainer()}">
            <div 
                class="da-tiles-wrapper" 
                [ngClass]="{'mobile-wrap' : screen.isSmallest()}"
                [ngStyle]="{'right': 'calc(-'+ screenPosition +' + '+ tilePosition +'px)'}">
                <div *ngFor="let item of contentService.getValue('kinderAnzahl', true) | fill; let num = index"
                    class="da-container-wide" 
                    [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <div class="da-edit-label-container" [ngClass]="{'is-editing' : labelEdit}">
                        <lib-header-text
                            *ngIf="!labelEdit"
                            class="da-title"
                            [label]="setLocalValue(num, 'name')"
                            size="38px"
                            [convertMobile]="true"
                        ></lib-header-text>
                        <lib-input
                            #name
                            *ngIf="labelEdit"
                            class="da-title"
                            label="{{'CHILDREN.LABEL_EDIT' | translate}}"
                            name="name{{num}}"
                            inputmode=""
                            [value]="setLocalValue(num, 'name')"
                            (change)="editLabel($event, 'name', num)"
                        ></lib-input>
                        <div class="da-edit-label" (click)="labelEdit = !labelEdit">
                            <svg-icon *ngIf="!labelEdit" src="assets/images/pen.svg" class="da-edit-label-icon"></svg-icon>
                            <svg-icon *ngIf="labelEdit" src="assets/images/diskSave.svg" class="da-edit-label-icon"></svg-icon>
                        </div>
                    </div>
                    <div class="da-child-container" #childContainer>
                        <div #geburtstagContainer
                            class="da-container" 
                            [ngClass]="{ 'mobile' : screen.isSmallest() }"
                            >
                            <lib-state-icon [state]="fakeTiles[num].birthdateState ? fakeTiles[num].birthdateState : 'warning'"></lib-state-icon>
                            <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                                <lib-tile-text
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'CHILDREN.TILE_TEXT0' | translate}}"
                                ></lib-tile-text>
                                <lib-input-date
                                    #birthdate
                                    #inputs
                                    class="less-margin-bottom"
                                    name="birthdate{{num}}"
                                    [maxDate]="maxDateBirth"
                                    [minDate]="minDateBirth"
                                    [value]="!!contentService.getNestedObject('kinder', 'geburtsdatum', num) ? contentService.getNestedObject('kinder', 'geburtsdatum', num) : ''"
                                    [touched]="!!contentService.getNestedObject('kinder', 'geburtsdatum', num)"
                                    [error]="false"
                                    [inputDisabled]="inputDisabled"
                                    (click)="testValid = true"
                                    errorText="{{errorBirthdate | translate}}"
                                    placeholder="tt.mm.jjjj"
                                    (dateValue)="validateDate($event, num)"
                                    (dateInputValue)="validateDate($event, num, true)"
                                ></lib-input-date>
                            </div>
                        </div>
                        <!-- Kindergeld Container -->
                        <div #kindergeldContainer
                            class="da-container" 
                            [ngClass]="{ 'mobile' : screen.isSmallest() }"
                            >
                            <lib-state-icon [state]="fakeTiles[num].kindergeldState ? fakeTiles[num].kindergeldState : 'warning'"></lib-state-icon>
                            <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                                <lib-tile-text
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'CHILDREN.TILE_TEXT2' | translate}}"
                                ></lib-tile-text>
                                <lib-toggle
                                    #kindergeld
                                    label1="{{'COMMON.TRUE' | translate}}"
                                    label2="{{'COMMON.FALSE' | translate}}"
                                    name="kindergeld{{num}}"
                                    value1="true"
                                    value2="false"
                                    [localValue]="setLocalValue(num, 'kindergeldWirdBezogen')"
                                    (switchValue)="validateInput($event, 'kindergeld', num)"
                                ></lib-toggle>
                            </div>
                        </div>

                        <!-- Unterhalt plus Container -->
                        <div #unterhaltplusContainer
                            class="da-container" 
                            [ngClass]="{ 'mobile' : screen.isSmallest() }"
                            >
                            <lib-state-icon [state]="fakeTiles[num].unterhaltplusState ? fakeTiles[num].unterhaltplusState : 'warning'"></lib-state-icon>
                            <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                                <lib-tile-text
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'CHILDREN.TILE_TEXT4' | translate}}"
                                ></lib-tile-text>
                                <lib-toggle
                                    #unterhaltplus
                                    label1="{{'COMMON.TRUE' | translate}}"
                                    label2="{{'COMMON.FALSE' | translate}}"
                                    name="unterhaltplus{{num}}"
                                    value1="true"
                                    value2="false"
                                    [localValue]="setLocalValue(num, 'unterhaltPlus')"
                                    (switchValue)="validateInput($event, 'unterhaltplus', num)"
                                ></lib-toggle>
                                <ng-container *ngIf="setLocalValue(num, 'unterhaltPlus') === 'true'">
                                    <lib-tile-text
                                        size="20px"
                                        [convertMobile]="true"
                                        label="{{'CHILDREN.TILE_TEXT3' | translate}}"
                                    ></lib-tile-text>
                                    <lib-input
                                        #unterhaltplusBetrag
                                        #inputs
                                        class="da-tile-field"
                                        label="{{'COMMON.LABELS.FEE' | translate}}"
                                        name="unterhaltplusBetrag{{num}}"
                                        [error]="false"
                                        errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                                        placeholder="0,00"
                                        unit="€"
                                        [inputDisabled]="inputDisabled"
                                        (click)="testValid = true"
                                        [touched]="!!setLocalValue(num, 'unterhaltPlusBetrag')"                                
                                        [value]="!!setLocalValue(num, 'unterhaltPlusBetrag') ? setLocalValue(num, 'unterhaltPlusBetrag') : ''"
                                        (change)="validateInput($event, 'unterhaltplusBetrag', num)"
                                    ></lib-input>
                                </ng-container>
                            </div>
                        </div>
                        <!-- Unterhalt minus Container -->
                        <div #unterhaltminusContainer
                            class="da-container" 
                            [ngClass]="{ 'mobile' : screen.isSmallest() }"
                            >
                            <lib-state-icon [state]="fakeTiles[num].unterhaltminusState ? fakeTiles[num].unterhaltminusState : 'warning'"></lib-state-icon>
                            <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                                <lib-tile-text
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'CHILDREN.TILE_TEXT5' | translate}}"
                                ></lib-tile-text>
                                <lib-toggle
                                    #unterhaltminus
                                    label1="{{'COMMON.TRUE' | translate}}"
                                    label2="{{'COMMON.FALSE' | translate}}"
                                    name="unterhaltminus{{num}}"
                                    value1="true"
                                    value2="false"
                                    [localValue]="setLocalValue(num, 'unterhaltMinus')"
                                    (switchValue)="validateInput($event, 'unterhaltminus', num)"
                                ></lib-toggle>
                                <ng-container *ngIf="setLocalValue(num, 'unterhaltMinus') === 'true'">
                                    <lib-tile-text
                                        size="20px"
                                        [convertMobile]="true"
                                        label="{{'CHILDREN.TILE_TEXT3' | translate}}"
                                    ></lib-tile-text>
                                    <lib-input
                                        #unterhaltminusBetrag
                                        #inputs
                                        class="da-tile-field"
                                        label="{{'COMMON.LABELS.FEE' | translate}}"
                                        name="unterhaltminusBetrag{{num}}"
                                        [error]="false"
                                        errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                                        placeholder="0,00"
                                        unit="€"
                                        [inputDisabled]="inputDisabled"
                                        (click)="testValid = true"
                                        [touched]="!!setLocalValue(num, 'unterhaltMinusBetrag')"
                                        [value]="!!setLocalValue(num, 'unterhaltMinusBetrag') ? setLocalValue(num, 'unterhaltMinusBetrag') : ''"
                                        (change)="validateInput($event, 'unterhaltminusBetrag', num)"
                                    ></lib-input>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

<ng-template #childrenInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'CHILDREN.INFO_HEADER' | translate}}" text="{{'CHILDREN.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
