
<div class="da-darlehen-wrapper" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'isSmallest' : screen.isSmallest() }">
    <lib-tile-text
        class="da-tile-header"
        size="16px"
        [convertMobile]="false"
        label="{{label | translate}}"
        info="true"
        (infoValue)="screen.openInfo(darlehenHoeheInfo)"
    ></lib-tile-text>
    <lib-input
        #darlehenHoehe
        class="da-tile-field"
        label="{{'COMMON.LABELS.SUMME' | translate}}"
        name="darlehenHoehe{{num}}"
        [error]="error"
        [inputDisabled]="isSingle"
        [value]="!isSingle && !!contentService.getNestedObject('darlehenBausteine', 'darlehensbetrag', num) ? contentService.getNestedObject('darlehenBausteine', 'darlehensbetrag', num) : isSingle ? contentService.getValue('darlehensbedarf') : ''"
        errorText="{{errorDarlehen | translate}}"
        placeholder="0,00"
        unit="€"
        [touched]="!!contentService.getNestedObject('darlehenBausteine', 'darlehensbetrag', num)"                               
        (inputValue)="validateInput($event, darlehenHoehe)"
    ></lib-input>
</div>

<ng-template #darlehenHoeheInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_ASSEMBLY.BAUSTEINE.DARLEHEN.INFO_HEADER' | translate}}" text="{{'FINANCE_ASSEMBLY.BAUSTEINE.DARLEHEN.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
