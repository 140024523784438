import { Component, EventEmitter, HostListener, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { dropdownsContent } from 'src/assets/content/dropdowns';
import { switchesContent } from 'src/assets/content/switches';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-property-information',
  templateUrl: './property-information.component.html',
  styleUrls: ['./property-information.component.scss']
})
export class PropertyInformationComponent implements OnInit {
  public slideName: string = 'property-information';
  next: string = 'property-change';
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  fakeTiles: any[] = [];
  buttons: any = [
    {
      'iconLeft': 'assets/images/chevronLeft.svg',
      'value': this.screen.isSmallest() ? 'COMMON.FALSE' : 'BUTTONS.CANCEL',
      'autoWidth': true,
      'type': 'back'
    },
    {
      'iconRight': 'assets/images/chevronRight.svg',
      'value': this.screen.isSmallest() ? 'COMMON.TRUE' : 'BUTTONS.SUBMIT',
      'autoWidth': true,
      'type': 'next'
    }
  ]
  selectedTile: number = environment.webappType === 'c' ? 0 : 1;
  selectedTileValue: string = environment.webappType === 'c' ? 'PROPERTY_INFORMATION.PROPERTY.MAIN' : 'PROPERTY_INFORMATION.PROPERTY.DEFAULT 1';
  tilePosition: string = '122';
  basePosition: number = 122;
  screenPosition: string = '-50%';
  tileSlideFactor: number = 266;

  asset: any = {};
  isOwner: boolean = environment.webappType === 'c' ? true : false;
  dropdownObjektnutzung = dropdownsContent['OBJECT_NUTZUNG'].items;
  dropdownVorhaben = dropdownsContent['OBJECT_VORHABEN'].items;
  dropdownObjektArt = dropdownsContent['PROPERTY_TYPE'].items;
  switchUmschulden = switchesContent['UMSCHULDUNG'].items;

  objectNumber!: number;

  errorDarlehen: string = 'COMMON.ERRORS.FEE';

  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public contentService: ContentService,
    private translate: TranslateService,
  ) { }

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('restschuldVorhanden') restschuldVorhanden!: any;
  @ViewChild('objectNutzung') objectNutzung!: any;
  @ViewChild('content') content!: TemplateRef<any>;

  @ViewChildren('objectArt') objectArt!: QueryList<any>;
  @ViewChildren('marktwert') marktwert!: QueryList<any>;
  @ViewChildren('kaltmiete') kaltmiete!: QueryList<any>;
  @ViewChildren('adresse') adresse!: QueryList<any>;
  @ViewChildren('finanzierung') finanzierung!: QueryList<any>;
  @ViewChildren('restschuld') restschuld!: QueryList<any>;
  @ViewChildren('darlehen') darlehen!: QueryList<any>;
  @ViewChildren('rate') rate!: QueryList<any>;
  @ViewChildren('useForProject') useForProject!: QueryList<any>;
  @ViewChildren('umschulden') umschulden!: QueryList<any>;
  @ViewChildren('inputs') inputsList!: QueryList<any>;

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
    this.setTilePos();
  }

  ngOnInit(): void {
    this.setTilePos();
    this.setStatus();
  }

  setStatus() {
    const status = this.dataQuery.getEntity(1);
    this.fakeTiles = [];
    if (!!status) {
      // User ist schon Besitzer der Imobilie, die finanziert werden soll
      if (this.isOwner) {
        let objectState = !!status['restschuldVorhanden'] ? 'success' : 'warning';
        this.fakeTiles.push(this.fillFakeTiles('PROPERTY_INFORMATION.PROPERTY.MAIN', 0, objectState, '', false, false, true));
        // ausfüllen der Informationen für das Finanzierungsobjekt
        if (!!status['objectType']) {
          if (status['objectType'] !== 'Haus') {
            let objectType = status['objectType'][0].toUpperCase() + status['objectType'].slice(1).toLowerCase();
            objectType = objectType.replaceAll('ue', 'ü');
            this.asset = {
              ... this.asset,
              type: objectType,
            }
          } else {
            let houseType = status['houseType'][0].toUpperCase() + status['houseType'].slice(1).toLowerCase();
            houseType = houseType.replaceAll('ae', 'ä');
            this.asset = {
              ... this.asset,
              type: houseType,
            }
          }
        }
        if (status['objectAddress']) {
          if (status['objectAddressMitStrasse']) {
            if (status['objectAddressMitStrasse'] === 'true') {
              this.asset = {
                ... this.asset,
                address: `${status['objectAddress'].plz} ${status['objectAddress'].ort},<br>${status['objectAddress'].strasse} ${status['objectAddress'].hausnummer}`,
              }
            } else {
              this.asset = {
                ... this.asset,
                address: `${status['objectAddress'].plz} ${status['objectAddress'].ort}`,
              }
            }
          }
        }
        if (!!status['objectMarkwert']) {
          this.asset = {
            ... this.asset,
            marktwert: status['objectMarkwert'],
          }
        }

        if (!!status['objectNutzungwohnflaeche']) {
          this.dropdownObjektnutzung.map((item: any) => {
            if (item.value === status['objectNutzungwohnflaeche']) {
              this.asset = {
                ... this.asset,
                nutzung: item.viewValue,
              }
            }
          });
        }
        if (!!status['projectPlan']) {
          this.dropdownVorhaben.map((item: any) => {
            if (item.value === status['projectPlan']) {
              this.asset = {
                ... this.asset,
                vorhaben: item.viewValue,
              }
            }
          });
        }
      } else {
        this.fakeTiles.push(this.fillFakeTiles('', 0, 'success', '', false, true));
      }
    }
    // Befüllen der Bestandsimmobilien
    this.setFakeTiles(status, 'init');
  }

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'next') {
        if (this.allState !== 'success') {
          this.showState = true;
          this.setDisabled();
        } else {
          this.setDisabled('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 50)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  setFakeTiles(status: any, type: string = 'default') {
    let count = 1;

    if(this.fakeTiles.length > 0) {
      this.fakeTiles.splice(1, this.fakeTiles.length);
    }

    // init - no Bestandsimmobilien Details yet
    if (!status['bestehendeImmobilien']) {
      if (status['immobilienEigengenutztAnzahl']) {
        for (let i = 0; i < status['immobilienEigengenutztAnzahl']; i++) {
          this.fakeTiles.push(this.fillFakeTiles('', count, 'warning', 'Eigennutzung', false));
          this.getValue('EIGENGENUTZT', 'objektnutzung', count, "PROPERTY_INFORMATION.PROPERTY.DEFAULT", type);
          count++
        }
      }
      if (status['immobilienTeilVermietetAnzahl']) {
        for (let i = 0; i < status['immobilienTeilVermietetAnzahl']; i++) {
          this.fakeTiles.push(this.fillFakeTiles('', count, 'warning', 'Teilvermietet', false));
          this.getValue('TEILVERMIETET', 'objektnutzung', count, "PROPERTY_INFORMATION.PROPERTY.DEFAULT", type);
          count++
        }
      }
      if (status['immobilienVermietetAnzahl']) {
        for (let i = 0; i < status['immobilienVermietetAnzahl']; i++) {
          this.fakeTiles.push(this.fillFakeTiles('', count, 'warning', 'Vermietet', false));
          this.getValue('VERMIETET', 'objektnutzung', count, "PROPERTY_INFORMATION.PROPERTY.DEFAULT", type);
          count++
        }
      }
      if (status['familienImmobilie'] && status['familienImmobilie'] === 'familienImmobilieVorhanden') {
        this.fakeTiles.push(this.fillFakeTiles('', count, 'warning', '', true));
        this.getValue('PROPERTY_INFORMATION.PROPERTY.FAMILY', 'name', count, 'PROPERTY_INFORMATION.PROPERTY.FAMILY', type);
      }
      // there are Bestandsimmobilien Details already
    } else {
      let eigengenutzt = 0;
      let teilvermietet = 0;
      let vermietet = 0;
      let familie = 0;
      let newCount = status['bestehendeImmobilien'].length + 1;

      status['bestehendeImmobilien'].map((item: any) => {
        let nutzung = item.objektnutzung === 'EIGENGENUTZT' ? 'Eigennutzung' : item.objektnutzung === 'TEILVERMIETET' ? 'Teilvermietet' : 'Vermietet';
        let family = item.name === 'PROPERTY_INFORMATION.PROPERTY.FAMILY' ? true : false;
        nutzung = family ? '' : nutzung;
        this.fakeTiles.push(this.fillFakeTiles(item.name === 'PROPERTY_INFORMATION.PROPERTY.FAMILY' ? item.name : '', item.id, 'warning', nutzung, family));

        this.getValue(item.name === 'PROPERTY_INFORMATION.PROPERTY.FAMILY' ? 'PROPERTY_INFORMATION.PROPERTY.FAMILY' : item.objektnutzung, item.name === 'PROPERTY_INFORMATION.PROPERTY.FAMILY' ? 'name' : 'objektnutzung', item.id, item.name, type);
        if (item.name === 'PROPERTY_INFORMATION.PROPERTY.FAMILY') {
          familie++
        } else {
          if (item.objektnutzung === 'EIGENGENUTZT') {
            eigengenutzt++
          }
          if (item.objektnutzung === 'TEILVERMIETET') {
            teilvermietet++
          }
          if (item.objektnutzung === 'VERMIETET') {
            vermietet++
          }
        }
      });
   
      if (status['immobilienEigengenutztAnzahl'] && status['immobilienEigengenutztAnzahl'] > eigengenutzt) {
        if (status['immobilienEigengenutztAnzahl'] > eigengenutzt) {
          let newEigengenutzt = eigengenutzt;
          for (newEigengenutzt; newEigengenutzt < status['immobilienEigengenutztAnzahl']; newEigengenutzt++) {
            this.fakeTiles.push(this.fillFakeTiles('', newCount, 'warning', 'Eigennutzung', false));

            this.getValue('EIGENGENUTZT', 'objektnutzung', newCount, "PROPERTY_INFORMATION.PROPERTY.DEFAULT", type);
            newCount++
          }
        }
      }
      if (status['immobilienTeilVermietetAnzahl'] && status['immobilienTeilVermietetAnzahl'] > teilvermietet) {
        let newTeilvermietet = teilvermietet;
        for (newTeilvermietet; newTeilvermietet < status['immobilienTeilVermietetAnzahl']; newTeilvermietet++) {
          this.fakeTiles.push(this.fillFakeTiles('', newCount, 'warning', 'Teilvermietet', false));

          this.getValue('TEILVERMIETET', 'objektnutzung', newCount, "PROPERTY_INFORMATION.PROPERTY.DEFAULT", type);
          newCount++
        }
      }
      if (status['immobilienVermietetAnzahl'] && status['immobilienVermietetAnzahl'] > vermietet) {
        let newVermietet = vermietet;
        for (newVermietet; newVermietet < status['immobilienVermietetAnzahl']; newVermietet++) {
          this.fakeTiles.push(this.fillFakeTiles('', newCount, 'warning', 'Vermietet', false));

          this.getValue('VERMIETET', 'objektnutzung', newCount, "PROPERTY_INFORMATION.PROPERTY.DEFAULT", type);
          newCount++
        }
      }
      if (!!status['familienImmobilie'] && status['familienImmobilie'] === 'familienImmobilieVorhanden') {
        if (familie === 0) {
          this.fakeTiles.push(this.fillFakeTiles('', newCount, 'warning', '', true));

          this.getValue('PROPERTY_INFORMATION.PROPERTY.FAMILY', 'name', newCount, 'PROPERTY_INFORMATION.PROPERTY.FAMILY', type);
        }
      }
    }
  }

  fillFakeTiles(
    name: string = '',
    count: number, 
    state: string, 
    nutzung: string, 
    family: boolean,
    invisible: boolean = false,
    owner: boolean = false,
    selectItem: any = { "viewValue": '', "value": '' },
    ) {
      let tile = {
        "value": !!name ? name : family ? "PROPERTY_INFORMATION.PROPERTY.FAMILY" : `PROPERTY_INFORMATION.PROPERTY.DEFAULT ${count}` ,
        "image": family ? "assets/images/house.svg" : "assets/images/houseUser.svg",
        "state": state,
        "nutzung": nutzung,
        "selectFamilyItem": family ? selectItem : {},
        "selectItem": owner ? {} : selectItem,
        "owner": owner,
        "family": family,
        "id": count,
        "invisible": invisible
      }
      return tile;
  }

  showDeleteDialog(i: number) {

    let value = this.contentService.getNestedObject('bestehendeImmobilien', 'loeschen', i) === true ? false : true;
    this.getValue(value, 'loeschen', i, "PROPERTY_INFORMATION.PROPERTY.DEFAULT");
  }

  deleteImmobilie(e: any, i: number) {

    if (e === 'next') {
      const status = this.dataQuery.getEntity(1)?.['bestehendeImmobilien'];
      if (!!status) {
        let nutzung = this.contentService.getNestedValue('bestehendeImmobilien', 'objektnutzung', i);
        let name = this.contentService.getNestedValue('bestehendeImmobilien', 'name', i);
        if (!name.includes('FAMILY')) {
          if (nutzung === 'EIGENGENUTZT') {
            this.daService.update(1, { immobilienEigengenutztAnzahl: this.dataQuery.getEntity(1)?.['immobilienEigengenutztAnzahl'] - 1 });
          } else if (nutzung === 'TEILVERMIETET') {
            this.daService.update(1, { immobilienTeilVermietetAnzahl: this.dataQuery.getEntity(1)?.['immobilienTeilVermietetAnzahl'] - 1 });
          } else if (nutzung === 'VERMIETET') {
            this.daService.update(1, { immobilienVermietetAnzahl: this.dataQuery.getEntity(1)?.['immobilienVermietetAnzahl'] - 1 });
          }
        } else {
          this.daService.update(1, { familienImmobilie: 'keineFamilienImmobilie' });
        }

        let newImmobilien = status.filter((item: any) => {
          return item.id !== i
        })
        let parseId: any[] = [];
        newImmobilien.map((item: any) => {
          if (item.id > i) {
            item = { ...item, 'id': item.id - 1 };
          }
          parseId.push(item);
        })
        this.daService.update(1, { bestehendeImmobilien: parseId });
        setTimeout(() => {
          this.setFakeTiles(this.dataQuery.getEntity(1));
        }, 200);
      }
    } else {
      this.showDeleteDialog(i);
    }
  }

  setTilePos() {
    if (this.screen.isSmallerThanMinWidth()) {
      if (this.screen.isSmallest()) {
        this.screenPosition = '-25px';
        this.tilePosition = '0';
        this.tileSlideFactor = window.innerWidth;
      } else {
        this.screenPosition = '-50%';
        this.tilePosition = '122';
        this.tileSlideFactor = 266;
      }
    } else {
      this.screenPosition = '-50%';
      this.tilePosition = '122';
      this.tileSlideFactor = 266;
    }
    this.getIndex(this.selectedTile);
  }

  getIndex(i: number) {
    this.fakeTiles.map((item: any) =>{
      if(item.id === i) {
        this.selectedTileValue = item.value;
      }
    })
    this.tilePosition = this.screen.calculateNewPosById(!this.isOwner ? i - 1 : i, this.tileSlideFactor, this.tilePosition, this.basePosition);
  };

  // the toggles
  setSwitch(e: string, name: string, parent: string, num: number) {
    this.inputDisabled = false;
    this.testValid = true;
    switch (name) {
      case 'restschuldVorhanden': {
        this.daService.update(1, { restschuldVorhanden: e });
        this.setFakeTileStatus(parent, 0);
        break
      }
      default: {
        this.getValue(e, name, num, parent);
        break
      }
    }
  }

  // the dropdown
  getSelectedValue(e: string, num: number, parent: string, nutzung?: boolean) {
    this.getValue(e, nutzung ? 'objektnutzung' : 'objektArt', num, parent);
  }

  getSelectedItem(num: number, nutzung?: boolean) {
    const savedValue = this.contentService.getNestedValue('bestehendeImmobilien', nutzung ? 'objektnutzung' : 'objektArt', num);
    let selected;
    if (!nutzung) {
      this.dropdownObjektArt.map((item: any) => {
        if (item.value === savedValue) {
          selected = item;
        }
      });
    } else {
      this.dropdownObjektnutzung.map((item: any) => {
        if (item.value === savedValue) {
          selected = item;
        }
      });
    }

    return selected
  }

  // the inputs 
  validateInput(e: any, name: string, num: number, parent: string, subname?: string) {
    setTimeout(() => {
      let value = typeof e === 'string' ? e : e.srcElement.value ? e.srcElement.value : '';
      let element: any;
      switch (name) {
        case 'marktwert': element = this.marktwert.get(num - 1);
          break;
        case 'restschuld': {
          const allRestschuld = this.restschuld.toArray();
          allRestschuld.map((item) => {
            if (item.name === subname) {
              element = item
            }
          });
        }
          break;
        case 'darlehen': {
          const allDarlehen = this.darlehen.toArray();
          allDarlehen.map((item) => {
            if (item.name === subname) {
              element = item
            }
          });
        }
          break;
        case 'rate': {
          const allRate = this.rate.toArray();
          allRate.map((item) => {
            if (item.name === subname) {
              element = item
            }
          });
        }
          break;
        case 'kaltmiete': {
          const allKaltmiete = this.kaltmiete.toArray();
          allKaltmiete.map((item) => {
            if (item.name === subname) {
              element = item
            }
          });
        }
          break;
      }

      element.touched = true;
      element.placeholder = '';
      element.value = value;

      if (element.value === '') {
        element.error = true;
        this.getValue('', name, num, parent);
        return
      }

      let isValidAmount: any = true;
      let isValid = this.contentService.validatePattern(element.value, element.validationType);

      if (name === 'darlehen') {
        const restschuld = this.contentService.convertStringNumber(this.setLocalValue(num, 'restschuld'));
        isValidAmount = this.contentService.minMax(value, restschuld ? restschuld : 1, 999999);
        element.errorText = isValidAmount?.tooHigh ? this.translate.instant('COMMON.ERRORS.TOO_HIGH') : isValidAmount?.tooLow ? this.translate.instant('COMMON.ERRORS.DARLEHEN') : this.translate.instant(this.errorDarlehen);
      } else {
        isValidAmount = this.contentService.minMax(value, 1, 999999);
      }

      element.error = isValid && isValidAmount === true ? false : true;
      if (!element.error) {
        this.getValue(value, name, num, parent);
      } else {
        this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, parent, 'error');
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.setAllState('error', 'ERROR');
        this.stateChange.emit(true);
        setTimeout(() => { this.setDisabled() });
      }

    }, 100)

  }
  // the input-button (adresse)
  getAdressLightbox(num: number, lightbox: any, parent: string) {
    if (!this.setLocalValue(num, 'adresse')) {
      this.getValue('', 'adresse', num, parent);
    }
    this.objectNumber = num;
    setTimeout(() => {
      this.screen.openInfo(lightbox);
    });
  }

  getAdresse(num: number) {
    if (this.setLocalValue(num, 'adresse') && this.setLocalValue(num, 'adresse') !== '') {
      const adresse = this.setLocalValue(num, 'adresse') as any;
      const parsedAdresse = `${adresse.plz} ${adresse.ort},<br>${adresse.strasse} ${adresse.hausnummer}`;
      return parsedAdresse;
    } else {
      return ''
    }

  }

  // store values in Redux store
  getValue(e: any, name: string, i: number, parent: string, type: string = 'default') {

    let value = e.target?.value ? e.target?.value : e;
    let immobilien: {
      id: number;
      name: string;
      objektArt: string;
      marktwert: string;
      objektnutzung: string;
      kaltmiete: string;
      adresse: any;
      finanzierung: string;
      restschuld: string;
      darlehen: string;
      rate: string;
      useForProject: string;
      umschulden: string;
      erloesVerwenden: string;
      betragEinsetzen: string;
      realValue: string;
      change: string;
      zwischenfinanzierung: string,
      zusatzsicherheit: string,
      loeschen: boolean,
    }[] = [];
    const status = this.dataQuery.getEntity(1)?.['bestehendeImmobilien'];
    if (!status) {
      immobilien.push({
        'id': i,
        'name': name === 'name' ? value : 'PROPERTY_INFORMATION.PROPERTY.DEFAULT',
        'objektArt': name === 'objektArt' ? value : '',
        'marktwert': name === 'marktwert' ? value : '',
        'objektnutzung': name === 'objektnutzung' ? value : '',
        'kaltmiete': name === 'kaltmiete' ? value : '',
        'adresse': name === 'adresse' ? value : '',
        'finanzierung': name === 'finanzierung' ? value : '',
        'restschuld': name === 'restschuld' ? value : '',
        'darlehen': name === 'darlehen' ? value : '',
        'rate': name === 'rate' ? value : '',
        'useForProject': name === 'useForProject' ? value : '',
        'umschulden': name === 'umschulden' ? value : '',
        'erloesVerwenden': '',
        'realValue': '',
        'betragEinsetzen': '',
        'change': '',
        'zwischenfinanzierung': '',
        'zusatzsicherheit': value.includes('FAMILY') ? 'ja' : '',
        'loeschen': name === 'loeschen' ? true : false
      })
    } else {
      let count = 0;
      status.map((immobilie: any) => {
        if (immobilie.id === i) {
          count++;
          switch (name) {
            case 'name': {
              immobilie = { ...immobilie, 'name': value };
              break
            }
            case 'objektArt': {
              immobilie = { ...immobilie, 'objektArt': value };
              break
            }
            case 'marktwert': {
              if (!!immobilie.restschuld) {
                immobilie = { ...immobilie, 'realValue': this.getRealValue(value, immobilie.restschuld) };
              } else {
                immobilie = { ...immobilie, 'realValue': value };
              }
              immobilie = { ...immobilie, 'marktwert': value };
              break
            }
            case 'objektnutzung': {
              immobilie = { ...immobilie, 'objektnutzung': value };
              break
            }
            case 'kaltmiete': {
              immobilie = { ...immobilie, 'kaltmiete': value };
              break
            }
            case 'adresse': {
              immobilie = { ...immobilie, 'adresse': value };
              break
            }
            case 'finanzierung': {
              immobilie = { ...immobilie, 'finanzierung': value };
              break
            }
            case 'restschuld': {
              if (!!immobilie.marktwert) {
                immobilie = { ...immobilie, 'realValue': this.getRealValue(immobilie.marktwert, value) };
              }
              immobilie = { ...immobilie, 'restschuld': value };
              break
            }
            case 'darlehen': {
              immobilie = { ...immobilie, 'darlehen': value };
              break
            }
            case 'rate': {
              immobilie = { ...immobilie, 'rate': value };
              break
            }
            case 'useForProject': {
              immobilie = { ...immobilie, 'useForProject': value };
              break
            }
            case 'umschulden': {
              immobilie = { ...immobilie, 'umschulden': value };
              break
            }
            case 'loeschen': {
              immobilie = { ...immobilie, 'loeschen': value };
              break
            }
          }
        }
        immobilien.push(immobilie);
      });
      if (count === 0) {
        immobilien.push({
          'id': i,
          'name': name === 'name' ? value : 'PROPERTY_INFORMATION.PROPERTY.DEFAULT',
          'objektArt': name === 'objektArt' ? value : '',
          'marktwert': name === 'marktwert' ? value : '',
          'objektnutzung': name === 'objektnutzung' ? value : '',
          'kaltmiete': name === 'kaltmiete' ? value : '',
          'adresse': name === 'adresse' ? value : '',
          'finanzierung': name === 'finanzierung' ? value : '',
          'restschuld': name === 'restschuld' ? value : '',
          'darlehen': name === 'darlehen' ? value : '',
          'rate': name === 'rate' ? value : '',
          'useForProject': name === 'useForProject' ? value : '',
          'umschulden': name === 'umschulden' ? value : '',
          'erloesVerwenden': '',
          'realValue': '',
          'betragEinsetzen': '',
          'change': '',
          'zwischenfinanzierung': '',
          'zusatzsicherheit': value.includes('FAMILY') ? 'ja' : '',
          'loeschen': name === 'loeschen' ? true : false,
        })
      }
    }
    this.daService.update(1, { bestehendeImmobilien: immobilien });
    if ((name === 'finanzierung' && value === 'VORHANDENE_BESTEHENDE_DARLEHEN') && !this.contentService.getValue('erfahrungImmobilienFinanzierung')) {
      this.daService.update(1, { erfahrungImmobilienFinanzierung: true });
    }
    if (name === 'useForProject') { this.daService.update(1, { useOwnLand: value }); }
    if (name === 'adresse') {
      this.fakeTiles.map((tile: any) => {
        if (tile.id === i) {
          this.setFakeTileStatus(tile.value, i, type);
        }
      });
    } else {
      this.setFakeTileStatus(parent, i, type);
    }

  }

  getRealValue(total: any, debt: any) {
    const parsedTotal = total.replaceAll('.', '').replaceAll(',', '.');
    const parsedDebt = debt.replaceAll('.', '').replaceAll(',', '.');
    const value = parseInt(parsedTotal) - parseFloat(parsedDebt);
    return value.toLocaleString('de-DE', { maximumFractionDigits: 0 });
  }

  setFakeTileStatus(parent: string, i: number, type: string = 'default') {
    const status = this.dataQuery.getEntity(1)?.['bestehendeImmobilien'];
    const ownerStatus = this.dataQuery.getEntity(1)?.['restschuldVorhanden'];
    let state = 'warning';
    let num = i - 1;

    if (parent.includes('FAMILY')) {
      if (!!status?.[num]?.['objektArt'] && !!status?.[num]?.['marktwert'] && !!status?.[num]?.['objektnutzung'] && (status?.[num]?.['adresse'] && status?.[num]?.['adresse'] !== '') && !!status?.[num]?.['finanzierung']) {
        state = 'success';
      }
      if (status?.[num]?.['finanzierung'] === 'VORHANDENE_BESTEHENDE_DARLEHEN') {
        state = !!status?.[num]?.['restschuld'] && !!status?.[num]?.['darlehen'] ? 'success' : 'warning'
      }
    } else {
      if (!!status?.[num]?.['objektArt'] && !!status?.[num]?.['marktwert'] && !!status?.[num]?.['objektnutzung'] && (status?.[num]?.['adresse'] && status?.[num]?.['adresse'] !== '') && !!status?.[num]?.['finanzierung']) {
        state = 'success';
      }
      if (status?.[num]?.['objektnutzung'] !== 'EIGENGENUTZT') {
        state = !!status?.[num]?.['kaltmiete'] ? 'success' : 'warning';
      }
      if (status?.[num]?.['finanzierung'] === 'VORHANDENE_BESTEHENDE_DARLEHEN') {
        state = !!status?.[num]?.['restschuld'] && !!status?.[num]?.['darlehen'] && !!status?.[num]?.['rate'] ? 'success' : 'warning'
      }

      if (status?.[num]?.['objektArt'] === 'BAUGRUNDSTUECK' && (!!this.dataQuery.getEntity(1)?.['projectPlan'] && this.dataQuery.getEntity(1)?.['projectPlan'] === 'NEUBAU')) {
        if (!!status?.[num]?.['useForProject']) {
          if (status?.[num]?.['useForProject'] === 'ja') {
            if (status?.[num]?.['finanzierung'] === 'VORHANDENE_BESTEHENDE_DARLEHEN') {
              state = !!status?.[num]['umschulden'] ? 'success' : 'warning';
            }
          }
        } else {
          state = 'warning';
        }
      }
    }

    if (parent === 'PROPERTY_INFORMATION.PROPERTY.MAIN') {
      state = !!ownerStatus ? 'success' : 'warning';
    }

    this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, parent, state);
    setTimeout(() => { this.setDisabled(type) });
  }

  setDisabled( type: string = 'default') {
    const inputFields = this.inputsList.toArray();
    this.disabled = true;
    let isNotValid = true;
    let hasWarning = 0;
    let hasError = 0;
    let state = 'warning';
    let stateText = 'INCOMPLETE';

    this.fakeTiles?.map((tile: any) => {
      if (tile.state === 'warning') {
        hasWarning++;
      }
      if (tile.state === 'error') {
        hasError++;
      }
    });

    isNotValid = hasWarning > 0 || hasError > 0;
    state = hasError > 0 ? 'error' : hasWarning > 0 ? 'warning' : 'success';
    stateText = hasError > 0 ? 'ERROR' : hasWarning > 0 ? 'INCOMPLETE' : 'COMPLETE';

    this.contentService.setSlideIndexState(this.slideName, false, state);
    this.setAllState(state, stateText);
    this.stateChange.emit(true);

    if (inputFields?.length > 0) {
      inputFields.map((item: any) => {
        if (item.error) {
          this.contentService.setSlideIndexState(this.slideName, false, 'error');
          this.setAllState('error', 'ERROR');
          this.stateChange.emit(true);
          isNotValid = true;
        }
      })
    }

    if (!isNotValid) {
      this.disabled = false;
      if (type === 'test') {
        this.inputDisabled = true;
        this.testValid = false;
      }
    } else {
      this.disabled = true;
      this.testValid = true;
    }

    if(this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }

  setLocalValue(num: number, field: string) {
    let parsedValue = this.contentService.getNestedValue('bestehendeImmobilien', field, num) ?? '';
    return parsedValue;
  }
}
