<div class="da-lightbox-login-light">
    <div class="da-close-icon">
        <app-circle-button 
            name="close button"
            orientation="top-right" 
            [svg]="'assets/images/chevronLeft.svg'"
            [background]="'header-base'"
            (buttonValue)="dismiss()"
        ></app-circle-button>
    </div>
    <div class="da-modal-body">
        <app-login-light-exit 
            *ngIf="!isLoading && showLoginLightExit && !showLoginLightSaveInfo"
            [ngClass]="{'xs-device': isMobile(), 's-device': isMobileOrSmallTablet()}"
            [userIsLoggedIn]="userIsLoggedIn()"
            (saveLoginLightProgress)="onShowLoginLightForm()"
            (updateLoginLightProgress)="onUpdateLoginLightProgress()"
            (bookConsultationAppointment)="onBookConsultationAppointment(calendly)"
            (logout)="onLogout()">
        </app-login-light-exit>
        <app-login-light-form
            *ngIf="!isLoading && !showLoginLightExit && !showLoginLightSaveInfo"
            (saveLoginLightProgress)="onSaveLoginLightProgress($event)">
        </app-login-light-form>
        <app-login-light-save
            *ngIf="!isLoading && showLoginLightSaveInfo && firstSave"
            (logout)="onLogout()">
        </app-login-light-save>
        <app-login-light-save-loggedin *ngIf="!isLoading && showLoginLightSaveInfo && !firstSave">
    </app-login-light-save-loggedin>
        <div class="da-file-loading" *ngIf="isLoading">
            <mat-progress-spinner mode="indeterminate" diameter="100"></mat-progress-spinner>
        </div>
        <div *ngIf="isMobileOrSmallTablet()" class="mobile-spacer"></div>
    </div>
</div>
<ng-template #calendly let-modal>
    <app-calendly 
    [modal]="modal" 
    ></app-calendly>
</ng-template>