import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { Debounce } from 'lodash-decorators';
import { DaClickpathQuery } from 'src/app/state/click-path/da-clickpath.query';
import { ContentService } from 'src/app/services/content.service';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { DataQuery } from 'src/app/state/data/data.query';

@Component({
  selector: 'app-progression',
  templateUrl: './progression.component.html',
  styleUrls: ['./progression.component.scss']
})
export class ProgressionComponent implements OnInit, OnChanges {

  @Input() daType!: string;
  @Input() currentSlide!: string;
  @Input() progressItems!: any;
  @Input() accessChange!: boolean;
  @Output() public progressValue: EventEmitter<any> = new EventEmitter<string>();

  includedItems: number = 0;
  tileIndex: number = 0;
  tilePosition: string = '0';
  tileSlideFactor: number = 80;
  arrowRight: boolean = true;
  arrowLeft: boolean = true;

  endItem: any = {
    id: 'dashboard',
    icon: '/assets/images/barsProgress.svg',
    label: 'PROGRESS.LABELS.DASHBOARD',
    state: 'closed',
    excluded: false,
    disabled: true,
  }
  endItemState: string = 'closed';

  constructor(
    public screen: LayoutService,
    private daClickpathQuery: DaClickpathQuery,
    public contentService: ContentService,
    private daService: DataService,
    private dataQuery: DataQuery,
    private daNextSlideService: DaNextSlideService
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.setStatus();
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['daType']) {
      this.getDaType();
    }
    if (changes && changes['accessChange'] && changes['accessChange'].currentValue === true) {
      this.setEndItem();
    }
    if (changes && (changes['currentSlide'] || changes['progressItems'])) {
      this.setCurrentSlide();
    }
    if (changes && changes['currentSlide']) {
      this.daService.setCurrentSlide(changes['currentSlide'].currentValue);
    }
  }

  setStatus() {
    const nextSlide = this.daNextSlideService.getNextSlide();
    if(!!nextSlide) {
      this.daService.setCurrentSlide(nextSlide);
      this.currentSlide = nextSlide;
    }
    if(this.contentService.getValue('uebergabe')) {
      this.setEndItem();
    }
  }

  setEndItem() {
    this.endItem = {... this.endItem, disabled: false, state: 'open' };
    this.endItemState = 'open';
  }

  setCurrentSlide() {
    const newProgress: any[] = [];
    let index;
    this.progressItems.map((item: any) => {
      if (item.id === this.currentSlide) {
        item = { ...item, disabled: false }
          index = item.progressIndex;
      }
      newProgress.push(item);
    });
    this.progressItems = newProgress;
    this.getIndexProgress();
    if (index) {
      this.moveOne(this.currentSlide);
    }
  }

  getDaType() {
    if (this.daType === 'a') {
      this.endItem = {
        id: 'zertifikat',
        icon: '/assets/images/fileCertificateOutline.svg',
        label: 'PROGRESS.LABELS.CERTIFICATE',
        state: 'closed',
        excluded: false,
        disabled: true,
      }
    } 
    this.progressItems = this.daClickpathQuery.getEntity(1)?.slideIndex;
    this.getIndexProgress();
  }

  getIndexProgress() {
    let lengthIncluded = 0;
      this.progressItems.map((item: any) => {
        if(!item.excluded) {
          lengthIncluded++
        }
      });
      this.progressItems = this.progressItems.filter((item: { excluded: boolean; }) => !item.excluded);
      this.includedItems = lengthIncluded - 7;
  }

  moveOne(type: string, isDirection?: string) {
    // setTimeout(() => {
    //   const oldResult = this.dataQuery.getEntity(1)?.['results'];
    //   console.log('PROGRESSION', oldResult, this.contentService.getValue('expressSent'))
    //   this.daService.update(1, { resultsLoading: false });
    //   this.daService.update(1, { results: this.contentService.getValue('expressSent') ? oldResult : [] });
    //   this.daService.update(1, { resultsDone: false });
    // }, 500)

    if (!type) { type = 'start'; }
    let baseWidth = 0;
    this.progressItems.map((tile: { id: string; disabled: boolean }) => {
      if (tile.id === type) {
        let index = this.progressItems.indexOf(tile);
        let slideLength;
        if (isDirection) {
          if (isDirection === 'right') {
            this.tileIndex = this.tileIndex + 1;
          } else if (isDirection === 'left' && this.tileIndex > 0) {
            this.tileIndex = this.tileIndex - 1;
          }
          slideLength = this.tileSlideFactor * this.tileIndex;
          const newPos = baseWidth + slideLength;
          this.tilePosition = newPos.toString();
          this.progressValue.emit(type);
        } else {
          this.tileIndex = index - 1;
          slideLength = this.tileSlideFactor * this.tileIndex;
          if (!tile.disabled) {
            const newPos = baseWidth + slideLength;
            this.tilePosition = newPos.toString();
            this.progressValue.emit(type);
          }
        }
      }
    });
  }

  clickEnd(e: any) {
    if(this.daType === 'c') {
      this.progressValue.emit('finance-requirements-forward');
      this.currentSlide = 'finance-requirements-forward';
    } else {
      this.progressValue.emit('finance-requirements');
      this.currentSlide = 'finance-requirements';
    }
    this.moveOne(this.currentSlide);
  }
}
