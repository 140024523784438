<div class="da-bausteine-tile" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'isSmallest' : screen.isSmallest() }">
    <lib-header-text
    label="{{'FINANCE_ASSEMBLY.BAUSTEINE.TILGUNG.TITLE' | translate}}"
    info="true"
    (infoValue)="screen.openInfo(tilgungInfo)"
    size="20px"
    [convertMobile]="false"
></lib-header-text>
<lib-toggle
    #raten
    label1="{{'FINANCE_ASSEMBLY.BAUSTEINE.TILGUNG.SWITCH.LABEL1' | translate}}"
    label2="{{'FINANCE_ASSEMBLY.BAUSTEINE.TILGUNG.SWITCH.LABEL2' | translate}}"
    name="raten{{num}}"
    value1="RATE"
    value2="TILGUNG_IN_PROZENT"
    [localValue]="contentService.getNestedObject('darlehenBausteine', 'tilgungsWunsch', num)"
    (switchValue)="setSwitch($event)"
></lib-toggle>
<lib-input
    *ngIf="contentService.getNestedObject('darlehenBausteine', 'tilgungsWunsch', num) === 'RATE'"
    #rate
    class="da-tile-field"
    label="{{'FINANCE_ASSEMBLY.BAUSTEINE.TILGUNG.LABEL1' | translate}}"
    name="rate{{num}}"
    [error]="false"
    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
    placeholder="0,00"
    [value]="contentService.getNestedObject('darlehenBausteine', 'rate', num)"
    unit="€"
    [touched]="false"                               
    (inputValue)="validateInput($event, rate)"
></lib-input>
<lib-input
    *ngIf="contentService.getNestedObject('darlehenBausteine', 'tilgungsWunsch', num) === 'TILGUNG_IN_PROZENT'"
    #percent
    class="da-tile-field"
    label="{{'FINANCE_ASSEMBLY.BAUSTEINE.TILGUNG.LABEL2' | translate}}"
    name="percent{{num}}"
    [error]="false"
    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
    placeholder="0"
    [value]="contentService.getNestedObject('darlehenBausteine', 'tilgungssatzInProzent', num)"
    unit="%"
    [touched]="false"                               
    (inputValue)="validateInput($event, percent)"
></lib-input>
</div>

<ng-template #tilgungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_ASSEMBLY.BAUSTEINE.TILGUNG.INFO_HEADER' | translate}}" text="{{'FINANCE_ASSEMBLY.BAUSTEINE.TILGUNG.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
