<ng-container *ngFor="let item of chapters">
    <div class="da-accordion-item" *ngIf="item">
        <div class="da-accordion-item-body" [ngClass]="{'middle': item.id !== 0 }" [@slideUpDown]="item.active">

            <!-- THE ITEMS -->
            <ng-container *ngIf="item.id === 0" >
                <app-finance-needs-forward (nextItem)="setActive(chapters[1], true)" [class]="item.className" (slideTo)="slideTo.emit($event)" (stateChange)="stateChange.emit($event)"></app-finance-needs-forward>
            </ng-container>
            <!-- <ng-container *ngIf="item.id === 1">
                <div #path [ngStyle]="{'position': 'relative'}">
                    <app-finance-path [class]="item.className" (nextItem)="setActive(chapters[2], true)"></app-finance-path>
                    
                </div>
            </ng-container> -->
            <!-- <ng-container *ngIf="item.id === 2 && contentService.getValue('ermittlungsweg') === 'ai'">
                <app-finance-preference [class]="item.className" (nextItem)="getResults($event)" (previousItem)="resetChapters()"></app-finance-preference>
            </ng-container> -->
            <ng-container *ngIf="item.id === 1  && contentService.getValue('ermittlungsweg') !== 'ai'">
                <app-finance-assembly [class]="item.className" (nextItem)="getResults($event)" (previousItem)="resetChapters()"></app-finance-assembly>
                <app-preview type="chapter1" *ngIf="!contentService.getValue('uebergabe')"></app-preview>
            </ng-container>
            <ng-container *ngIf="item.id === 2">
                <div #path [ngStyle]="{'position': 'relative'}">
                    <app-finance-results 
                        [class]="item.className" 
                        [resultsDone]="contentService.getValue('results') && contentService.getValue('results').length > 0" 
                        [expressDone]="contentService.getValue('expressSent')" 
                        (slideTo)="getEvent($event)">
                    </app-finance-results>
                    <app-preview type="chapter2" *ngIf="!contentService.getValue('resultsDone') && !contentService.getValue('expressSent')"></app-preview>
                </div>
            </ng-container>
        </div>
        <!-- THE ACCORDION -->
        <div 
            class="da-accordion-item-title"
            [ngClass]="{'da-active' : item.active }"
            (click)="setActive(item)">
            <div class="da-accordion-item-text" [ngClass]="{ 'isSmallest' : screen.isSmallest() }">
                {{ item.title | translate }} 
                <span *ngIf="item.active">{{ 'FINANCE_CONTINUE.TABS.HIDE' | translate }}</span>
                <span *ngIf="!item.active">{{ 'FINANCE_CONTINUE.TABS.SHOW' | translate}}</span>
            </div>
            <svg-icon *ngIf="item.active" src="assets/images/chevronUp.svg"></svg-icon>
            <svg-icon *ngIf="!item.active" src="assets/images/chevronDown.svg"></svg-icon>
        </div>
    </div>
</ng-container>
<div class="da-buttons-wrapper" *ngIf="contentService.getValue('uebergabe')">
    <app-nav-buttons
        class="da-buttons-group"
        [save]="true"
        [done]="true"
        [current]="slideName"
        (slideTo)="setNav($event)"
        >
    </app-nav-buttons>
</div>
