<div class="da-info-icon">
    <svg-icon src="assets/images/diskSave.svg"></svg-icon>
    <div class="disk-check">
        <svg-icon src="assets/images/checkSolid.svg"></svg-icon>
    </div>
</div>
<div class="title" id="da-conversion-1">
    <lib-header-text
        class="da-info-header"
        size="38px"
        [convertMobile]="true"
        [light]="false"
        label="{{'LOGIN_LIGHT.SAVE.LABELS.FIRST_SAVE' | translate}}"
    ></lib-header-text>
</div>
<div class="da-info-content">
    <div class="list">
        <div class="list-element">
            <div class="list-element-icon">
                <svg-icon src="assets/images/checkSolid.svg"></svg-icon>
            </div>
            <div class="da-info-list-element-text">
                {{'LOGIN_LIGHT.SAVE.TEXT1' | translate}}
            </div>
        </div>
        <div class="list-element">
            <div class="list-element-icon">
                <svg-icon src="assets/images/checkSolid.svg"></svg-icon>
            </div>
            <div class="da-info-list-element-text">
                {{'LOGIN_LIGHT.SAVE.TEXT2' | translate}}
            </div>
        </div>
        <div class="list-element">
            <div class="list-element-icon">
                <svg-icon src="assets/images/checkSolid.svg"></svg-icon>
            </div>
            <div class="da-info-list-element-text">
                {{'LOGIN_LIGHT.SAVE.TEXT3' | translate}}
            </div>
        </div>
    </div>
    <div class="button-container">
        <lib-button [value]="'Webassistenten beenden'"
                    iconRight="assets/images/chevronRight.svg"
                    [autoWidth]="true"
                    (click)="onCloseLoginLightSidebar()">
        </lib-button>
    </div>
</div>