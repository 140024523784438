<div class="da-progression" [ngClass]="{'mobile' : screen.isSmallest()}">
    <div class="da-progression-wrapper">
        <div class="fmo-slick-arrow fmo-slick-left" [ngClass]="{'visible' : arrowLeft}">
            <button 
                *ngIf="((!!currentSlide && currentSlide !== 'start') || tileIndex > 0) || !contentService.getValue('expressSent')"
                (click)="moveOne(currentSlide, 'left')">
                <svg-icon *ngIf="arrowLeft" src="assets/images/chevronLeftSolid.svg"></svg-icon>
            </button>
        </div>
        <div class="fmo-progress-items" [ngClass]="{'mobile' : screen.isSmallest()}">
            <div 
                class="fmo-progress-items-wrapper" 
                [ngClass]="{'mobile' : screen.isSmallest()}"
                [ngStyle]="{'right': 'calc('+ tilePosition +'px)'}" >

                <ng-container *ngFor="let item of progressItems">
                    <div  class="tile" *ngIf="!item.excluded">
                        <lib-progress-button
                            [label]="item.label"
                            [state]="item.state"
                            [disabled]="item.disabled || contentService.getValue('expressSent')"
                            [icon]="item.icon"
                            (click)="moveOne(item.id)">
                        </lib-progress-button>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="fmo-slick-arrow fmo-slick-right" [ngClass]="{'visible' : arrowRight}">
            <button 
                *ngIf="(!!currentSlide && tileIndex < includedItems) || !contentService.getValue('expressSent')"
                (click)="moveOne(currentSlide, 'right')">
                <svg-icon *ngIf="arrowRight" src="assets/images/chevronRight.svg"></svg-icon>
            </button>
        </div>
    </div>
    <div class="da-progression-enditem">
        <lib-progress-button
            *ngIf="endItemState === 'closed'"
            [label]="endItem.label"
            state="closed"
            [disabled]="endItem.disabled || contentService.getValue('expressSent')"
            [icon]="endItem.icon">
        </lib-progress-button>
        <lib-progress-button
            *ngIf="endItemState === 'open'"
            [label]="endItem.label"
            state="open"
            [disabled]="endItem.disabled || contentService.getValue('expressSent')"
            [icon]="endItem.icon"
            (click)="clickEnd($event)">
        </lib-progress-button>
    </div>
</div>


