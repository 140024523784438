<lib-header-text
    class="da-title"
    label="{{'UPLOAD_INCOME.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>

<div class="da-tiles-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
    <div class="da-container upload" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container1State"></lib-state-icon>
        <div class="da-tile-wrapper upload" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'EXPOSE.TILE_TEXT2' | translate}}"
            >
            </lib-tile-text>
        </div>
        <div class="da-tile-wrapper upload" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <app-file-upload type="income" (fileUploaded)="setContainerStatus($event)"></app-file-upload>
        </div>
    </div>
</div>
<div class="da-buttons-wrapper">
    <app-nav-buttons
        class="da-buttons-group"
        [save]="true"
        [nextDisabled]="daNextSlideService.getDisabled()"
        [current]="slideName"
        [next]="next"
        (slideTo)="contentService.setNav($event, slideName, slideTo)"
        >
    </app-nav-buttons>
</div>

