import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { MappingService } from 'src/app/services/mapping.service';
import { DaClickpathQuery } from 'src/app/state/click-path/da-clickpath.query';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { UserService } from 'src/app/state/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  @ViewChild('loginLightSidebar') loginLightSidebar!: TemplateRef<any>;
  @Input() currentSlide!: string;
  @Input() stateChange!: any;
  @Input() accessChange!: boolean;
  @Input() daType!: string;
  showLoginLightExit: boolean = false;
  showLoginLightForm: boolean = false;
  updateLoginLightProgress: boolean = false;
  customContent: any;
  name!: string;
  logo!: string;
  wortmarke!: boolean;
  background: string = 'header-base';
  userIsLoggedIn: boolean = false;
  userHasAccess: boolean = false;
  userCanSaveProgress: boolean = false;
  isDE: boolean = true;
  progressItems: any;
  environment = environment;

  @Output() public buttonValue:EventEmitter<any> = new EventEmitter<string>();
  @Output() public progressValue: EventEmitter<any> = new EventEmitter<string>();
  
  constructor(
    public screen: LayoutService,
    private configurationQuery: ConfigurationQuery,
    private modalService: NgbModal,
    private daService: DataService,
    private dataQuery: DataQuery,
    public userService: UserService,
    private translate: TranslateService,
    private daClickpathQuery: DaClickpathQuery,
    private mappingService: MappingService,
    public contentService: ContentService
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
    onWindowResize() {
      this.screen.updateScreenSizes();
  }

  ngOnInit() {
    this.configurationQuery.configuration.subscribe((res: any) => {
      this.customContent = res;
      this.name = this.customContent.unternehmenseinstellungen.unternehmensname_header;
      this.logo = this.customContent.kontaktheaderlogo;
      this.wortmarke = this.customContent.Wortmarke;
    });

    this.userIsLoggedIn = this.userService.isLoggedInForLoginLight();

    this.daService.getCurrentProgressIndex().subscribe((index) => {
      if (index >= 4) {
        this.userCanSaveProgress = true;
      } else {
        this.userCanSaveProgress = false;
      }
    });
    this.progressItems = this.daClickpathQuery.getEntity(1)?.slideIndex;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['accessChange'] && changes['accessChange'].currentValue === true) {
      this.userHasAccess = true;
    }
  }

  getButtonValue(event: string) {
    this.buttonValue.emit(event);
  }

  getLanguage(e: any) {
    const language = e === 'de' ? 'de-DE' : 'en-GB';
    this.translate.use(language);
    this.daService.update(1, { language: language });
    this.isDE = !this.isDE;
  }

  onSave(showLoginLightForm: boolean = false, showLoginLightExit: boolean = false) { 
    const oldResult = this.dataQuery.getEntity(1)?.['results'];
    this.daService.update(1, { resultsLoading: false });
    this.daService.update(1, { results: this.contentService.getValue('expressSent') ? oldResult : [] });
    this.daService.update(1, { resultsDone: false });
    setTimeout(()=> {
      this.modalService.dismissAll();
      this.showLoginLightExit = showLoginLightExit;
      this.showLoginLightForm = showLoginLightForm;
      this.updateLoginLightProgress = !showLoginLightExit && !showLoginLightForm ? true : false;
      this.screen.openInfo(this.loginLightSidebar);
    });
  }
  
  processRequest() {
    setTimeout(() => {
      this.daService.update(1, { uebergabe: true });
      this.mappingService.mapReduxData().then((result: any) => {
        result.subscribe({
          next: (data: any) => {
            this.daService.update(1, { anfrageUuid: data.finanzierungsanfrageuuid });
          },
          error: (error: any) => console.log(error)
        });
      });
    }, 300)
}
}
