<lib-header-text
    class="da-title"
    label="{{'BAUWEISE.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>

<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
>
<ng-template #content>
    <div class="da-tile-content-wrapper">
        <lib-tile-text
            size="16px"
            label="{{'BAUWEISE.TILE_TEXT' | translate}}"
            info="true"
            (infoValue)="screen.openInfo(anzahlGeschosseInfo)"
            [light]="true"
        ></lib-tile-text>
        <lib-counter
            #objectAnzahlGeschosse
            [value]="contentService.getValue('objectAnzahlGeschosse', true)"
            name="anzahlGeschosse"
            [min]="1"
            (counterValue)="setSwitch($event, 'geschosse')"
            [light]="true"
        ></lib-counter>
        <ng-container *ngIf="contentService.getValue('objectType') !== 'EIGENTUMSWOHNUNG'">
            <lib-tile-text
                class="da-tile-text"
                size="16px"
                label="{{'BAUWEISE.TILE_TEXT2' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(dachgeschossInfo)"
                [light]="true"
            ></lib-tile-text>
            <lib-toggle-vertical
                #objectDachgeschoss
                [options]="switchesDach"
                [localValue]="contentService.getValue('objectDachgeschoss')"
                name="dach"
                [light]="true"
                (switchValue)="setSwitch($event, 'dach')"
            ></lib-toggle-vertical>
            <lib-tile-text
                class="da-tile-text"
                size="16px"
                label="{{'BAUWEISE.TILE_TEXT3' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(kellerInfo)"
                [light]="true"
            ></lib-tile-text>
            <lib-toggle-vertical
                #objectKeller
                [options]="switches"
                [localValue]="contentService.getValue('objectUnterkellerung')"
                name="keller"
                [light]="true"
                (switchValue)="setSwitch($event, 'keller')"
            ></lib-toggle-vertical>
        </ng-container>
    </div>
</ng-template>
</lib-tile-group>
<div class="da-buttons-wrapper">
    <app-nav-buttons
        class="da-buttons-group"
        [save]="true"
        [nextDisabled]="daNextSlideService.getDisabled()"
        [current]="slideName"
        [next]="next"
        (slideTo)="contentService.setNav($event, slideName, slideTo)"
        >
    </app-nav-buttons>
</div>

<ng-template #anzahlGeschosseInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'BAUWEISE.INFO_HEADER' | translate}}" text="{{'BAUWEISE.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #dachgeschossInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'BAUWEISE.INFO_HEADER2' | translate}}" text="{{'BAUWEISE.INFO_TEXT2' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #kellerInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'BAUWEISE.INFO_HEADER3' | translate}}" text="{{'BAUWEISE.INFO_TEXT3' | translate}}"></app-info-sidebar>
</ng-template>

