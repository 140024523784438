<div class="da-forward-darlehen-wrapper" [ngClass]="{'mobile': screen.isSmallest()}">
    <lib-state-icon [state]="containerState" [ngStyle]="{'left': '0', 'top': '0', 'position': 'absolute'}"></lib-state-icon>
    <lib-header-text
        class="da-title"
        label="{{'FINANCE_REQUIREMENTS_FORWARD.VERBINDLICHKEITEN.HEADER' | translate }}"
        size="38px"
        [convertMobile]="true"
    ></lib-header-text>
    <button class="da-forward-arrow" [ngClass]="{'mobile': screen.isSmallest()}" (click)="isVisible = !isVisible">
        <svg-icon src="assets/images/chevronUp.svg" *ngIf="isVisible"></svg-icon>
        <svg-icon src="assets/images/chevronDown.svg" *ngIf="!isVisible"></svg-icon>
    </button>
    <ng-container *ngIf="isVisible">
        <ng-container *ngFor="let item of contentService.getValue('verbindlichkeiten', true); let num = index">
            <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}" *ngIf="item.verbindlichkeitTyp !== 'leasing'">
                <div class="da-forward-darlehen-row-part left">
                    <lib-tile-text
                        [ngStyle]="{'margin-top': !screen.isSmallest() ? '20px' : ''}"
                        class="da-title"
                        label="{{'FINANCE_REQUIREMENTS_FORWARD.VERBINDLICHKEITEN.LABELS.LABEL1' | translate: {name: item.name} }}"
                        size="16px"
                    ></lib-tile-text>
                    <lib-toggle 
                        [ngStyle]="{'margin-top': !screen.isSmallest() ? '20px' : ''}"
                        #abloesen
                        label1="{{'COMMON.TRUE' | translate}}"
                        label2="{{'COMMON.FALSE' | translate}}"
                        value1="true"
                        value2="false"
                        name="abloesen{{num}}"
                        [localValue]="setLocalValue(num, 'abloesen')"
                        (switchValue)="setSwitch($event, 'abloesen', num)"
                    ></lib-toggle>
                </div>
                <div class="da-forward-darlehen-row-part">
                    <ng-container *ngIf="setLocalValue(num, 'abloesen') === 'true'">
                        <lib-input
                            #abloesenBetrag
                            #inputs
                            label="{{'FINANCE_REQUIREMENTS_FORWARD.VERBINDLICHKEITEN.LABELS.LABEL0' | translate}}"
                            name="abloesenBetrag{{num}}"
                            [error]="false"
                            [inputDisabled]="true"
                            placeholder="0,00"
                            unit="€"
                            [touched]="!!setLocalValue(num, 'restschuld')"
                            [value]="setLocalValue(num, 'restschuld')"
                        ></lib-input>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}">
            <div class="da-forward-darlehen-row-part left">
                <lib-tile-text
                    class="da-title"
                    label="{{'FINANCE_REQUIREMENTS_FORWARD.VERBINDLICHKEITEN.LABELS.LABEL2' | translate }}"
                    size="16px"
                ></lib-tile-text>
            </div>
            <div class="da-forward-darlehen-row-part">
                <lib-input
                    #abloesenGesamt
                    #inputs
                    label=""
                    name="abloesenGesamt"
                    [error]="false"
                    [inputDisabled]="true"
                    placeholder="0,00"
                    unit="€"
                    [touched]="!!contentService.getValue('abloeseGesamt')"
                    [value]="contentService.getValue('abloeseGesamt')"
                ></lib-input>
            </div>
        </div>
    </ng-container>
</div>