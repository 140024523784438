import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { switchesContent } from 'src/assets/content/switches';

@Component({
  selector: 'app-object-detail',
  templateUrl: './object-detail.component.html',
  styleUrls: ['./object-detail.component.scss']
})
export class ObjectDetailComponent implements OnInit {
  public slideName: string = 'object-detail';
  next: string = 'object-nutzung';
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  sizeError: string = 'COMMON.ERRORS.NUMBER';

  einheiten: number = 0;
  gewerbeeinheiten: number = 0;
  switchesUnit = switchesContent['OBJEKT_EINHEIT'].items;
  headerSize = 'OBJECT_DETAIL.TILES.TILE2.TITLE';

  container1State: string = 'warning';
  container2State: string = 'warning';
  container3State: string = 'warning';
  container4State: string = 'optional';
  container5State: string = 'optional';
  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('objectEstateSize') objectEstateSize!: any;
  @ViewChild('objectUnitType') objectUnitType!: any;
  @ViewChild('objectYear') objectYear!: any;
  @ViewChild('objectEinheiten') objectEinheiten!: any;
  @ViewChild('objectGewerbeEinheiten') objectGewerbeEinheiten!: any;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService
  ) {}

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit() {
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.setStatus();
    });
  }

  setStatus() {
    const status = this.dataQuery.getEntity(1);

    if (!!status) {
      if (status['objectAnzahlDerWohneinheiten']) {
        this.einheiten = status['objectAnzahlDerWohneinheiten'];
      }
      if (status['objectAnzahlDerGewerbeEinheiten']) {
        this.gewerbeeinheiten = status['objectAnzahlDerGewerbeEinheiten'];
      }
      if (status['objectGesamtflaeche']) {
        this.objectEstateSize = this.contentService.validateReduxValue(status['objectGesamtflaeche'], this.objectEstateSize);
      }
      if (status['objectBaujahr']) {
        this.objectYear = this.contentService.validateReduxValue(status['objectBaujahr'], this.objectYear);
      }
      if (!!status['objectUnitType']) {
        this.headerSize = status['objectUnitType'] === 'Gewerbeflaeche' ? 'OBJECT_DETAIL.TILES.TILE2.TITLE3' : 'OBJECT_DETAIL.TILES.TILE2.TITLE2'
      }
    }

    setTimeout(() => { this.setContainerState('init') });
  }

  runValidation(e: string) {
    setTimeout(() => {
      if(e === 'next') {
        if(this.allState !== 'success') {
          this.showState = true;
          this.setContainerState();
        } else {
          this.setContainerState('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 100)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  validateInputValue(e: any, element: any) {
    setTimeout(() => {
      let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
      element.touched = !!value ? true : false;
      element.placeholder = '';
      const isValid = this.contentService.validatePattern(value, element.validationType);
      const isValidAmount: any = this.contentService.minMax(value, 25, 999);
      this.sizeError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';

      element.error = isValid && isValidAmount === true ? false : true;


      this.storeInputValue(element);
    });
  }

  validateYear(year: any, element: any) {
    setTimeout(() => {
      this.objectYear.value = year.target ? year.target.value : year;

      this.objectYear = this.contentService.validateYearValue(year.target ? year.target.value : year, element);
      if (!this.objectYear.error) {
        this.storeInputValue(element);
      }

      setTimeout(() => { this.setContainerState() });
    });
  }

  storeInputValue(element: any, e?: any) {
    switch (element.name) {
      case 'estateSize': this.daService.update(1, { objectGesamtflaeche: element.value });
        break;
      case 'unitType': {
        this.daService.update(1, { objectUnitType: e });
        this.headerSize = e === 'Gewerbeflaeche' ? 'OBJECT_DETAIL.TILES.TILE2.TITLE3' : 'OBJECT_DETAIL.TILES.TILE2.TITLE2';
      }
        break;
      case 'year': this.daService.update(1, { objectBaujahr: element.value });
        break;
      case 'einheiten': {
        this.daService.update(1, { objectAnzahlDerWohneinheiten: e });
        this.einheiten = e;
      }
        break;
      case 'gewerbeeinheiten': {
        this.daService.update(1, { objectAnzahlDerGewerbeEinheiten: e });
        this.gewerbeeinheiten = e;
      }
        break;
    }

    setTimeout(() => { this.setContainerState() });
  }

  setContainerState(type: string = 'default') {
    const status = this.dataQuery.getEntity(1);

    this.container1State = !!status?.['objectUnitType'] ? 'success' : 'warning';
    this.container2State = !!status?.['objectGesamtflaeche'] && this.objectEstateSize.touched ? 'success' : 'warning';
    this.container3State = !!status?.['objectBaujahr'] && this.objectYear.touched ? 'success' : 'warning';
    this.container4State = status?.['objectAnzahlDerWohneinheiten'] > 0 ? 'success' : 'optional';
    this.container5State = status?.['objectAnzahlDerGewerbeEinheiten'] > 0 ? 'success' : 'optional';

    this.container2State = this.objectEstateSize.error ? 'error' : this.container2State;
    this.container3State = this.objectYear.error ? 'error' : this.container3State;

    if (this.container1State === 'warning' ||
      this.container2State === 'warning' ||
      this.container3State === 'warning') {
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.setAllState('warning', 'INCOMPLETE');
      this.stateChange.emit(true);
    }

    if (this.container1State === 'error' ||
      this.container2State === 'error' ||
      this.container3State === 'error') {
      this.contentService.setSlideIndexState(this.slideName, false, 'error');
      this.setAllState('error', 'ERROR');
      this.stateChange.emit(true);
    }

    this.setDisabled(status, type);
  }

  setDisabled(storeStatus: any, type: string = 'default') {
    this.disabled = true;

    if (this.container1State === 'success' &&
      this.container2State === 'success' &&
      this.container3State === 'success') {
      this.disabled = false;
      if( type === 'test') {
        this.testValid = false;
        this.inputDisabled = true;
      }
      if(storeStatus?.['objectUnitType'] === 'Wohnflaeche') {
        this.contentService.setSlideIndexState(this.slideName, false, storeStatus?.['objectAnzahlDerWohneinheiten'] > 0 ? 'success' : 'warning');
        this.setAllState(storeStatus?.['objectAnzahlDerWohneinheiten'] > 0 ? 'success' : 'warning', storeStatus?.['objectAnzahlDerWohneinheiten'] > 0 ? 'COMPLETE' : 'INCOMPLETE');
      } else {
        this.contentService.setSlideIndexState(this.slideName, false, storeStatus?.['objectAnzahlDerGewerbeEinheiten'] > 0 ? 'success' : 'warning');
        this.setAllState(storeStatus?.['objectAnzahlDerGewerbeEinheiten'] > 0 ? 'success' : 'warning', storeStatus?.['objectAnzahlDerGewerbeEinheiten'] > 0 ? 'COMPLETE' : 'INCOMPLETE');
      }
      this.stateChange.emit(true);
    } else {
      this.testValid = type === 'init' ? this.testValid : true;
    }

    if(this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }
}
