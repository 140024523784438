<div class="da-container-baustein" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint() }">
    <lib-tile-text
        class="da-main-text"
        size="18px"
        [convertMobile]="false"
        label="{{item}}"
    ></lib-tile-text> 
    <lib-tile-text
        *ngIf="showInfo === 'true'"
        class="da-button-text"
        size="12px"
        [thin]="true"
        [convertMobile]="false"
        [infoSmall]="true"
        [info]="showInfo"
        (infoValue)="info.emit('info')"
        label="{{itemText}}"
    ></lib-tile-text> 
    <lib-tile-text
        *ngIf="showInfo !== 'true'"
        class="da-button-text"
        size="12px"
        [thin]="true"
        [convertMobile]="false"
        label="{{itemText}}"
    ></lib-tile-text> 
</div>
