import { Component, Input, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-link-label',
  templateUrl: './link-label.component.html',
  styleUrls: ['./link-label.component.scss']
})
export class LinkLabelComponent implements OnInit {
  @Input() label!: string;
  @Input() top: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
