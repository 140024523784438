import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-circle-button',
  templateUrl: './circle-button.component.html',
  styleUrls: ['./circle-button.component.scss']
})
export class CircleButtonComponent implements OnInit {
  @Input() icon: any;
  @Input() svg!: string;
  @Input() type!: string;
  @Input() orientation!: string;
  @Input() background!: string;
  @Output() public buttonValue:EventEmitter<any> = new EventEmitter<string>();

  constructor(
    public screen: LayoutService
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
  }

  getValue() {
    this.buttonValue.emit(this.type);
  }

}
