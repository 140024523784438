<lib-header-text
    class="da-title"
    label="{{'ENERGY.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
    info="true"
    (infoValue)="screen.openInfo(energyInfo)"
></lib-header-text>

<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
    >
    <ng-template #content>
        <!-- Ermittlungsmethode -->
        <lib-tile-text
            size="16px"
            label="{{'ENERGY.TILE_TEXT1' | translate}}"
            [light]="true"
            info="true"
            (infoValue)="screen.openInfo(energy2Info)"
        ></lib-tile-text>
        <lib-toggle-vertical
            #objectEnergyType
            [options]="switchesType"
            [localValue]="contentService.getValue('objectEnergieEffizienzAusweistyp')"
            name="energyType"
            (switchValue)="setSwitch($event, 'energyType')"
        ></lib-toggle-vertical>

        <!-- ENERGIE BEKANNT -->
        <ng-container *ngIf="energyType.length > 0">
            <lib-tile-text
                size="16px"
                label="{{ energyType | translate}}"
                [light]="true"
            ></lib-tile-text>
            <lib-toggle
                #objectEnergyKnown
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="energyKnown"
                value1="true"
                value2="false"
                [localValue]="contentService.getValue('objectEnergyUseKnown')"
                (switchValue)="setSwitch($event, 'energyKnown')"
                [light]="true"
            ></lib-toggle>

            <!-- Input feld -->
            <ng-container *ngIf="contentService.getValue('objectEnergyUseKnown') === 'true'">
                <lib-tile-text
                    size="14px"
                    label="{{energy | translate}}"
                    [light]="true"
                    [ngStyle]="{'margin-top': '20px', 'margin-bottom': '-10px', 'max-width': '80%'}"
                ></lib-tile-text>
                <lib-input
                    #objectEnergy
                    name="energy"
                    placeholder="235,00"
                    [error]="false"
                    [inputDisabled]="inputDisabled"
                    errorText="{{energyError | translate}}"
                    [touched]="!!contentService.getValue('objectEndEnergie')"
                    (click)="testValid = true"
                    [value]="!!contentService.getValue('objectEndEnergie') ? contentService.getValue('objectEndEnergie') : ''"
                    (change)="validateInputValue($event, objectEnergy)"
                    [light]="true"
                ></lib-input>
            </ng-container>
            <!-- No Input feld -->
            <ng-container *ngIf="contentService.getValue('objectEnergyUseKnown') === 'false'">
                <lib-tile-text
                    size="16px"
                    label="{{ 'ENERGY.TILE_TEXT4' | translate}}"
                    [light]="true"
                ></lib-tile-text>
                <lib-toggle
                    #objectEnergyClassKnown
                    label1="{{'COMMON.TRUE' | translate}}"
                    label2="{{'COMMON.FALSE' | translate}}"
                    name="energyClassKnown"
                    value1="true"
                    value2="false"
                    [localValue]="contentService.getValue('objectEnergyClassKnown')"
                    (switchValue)="setSwitch($event, 'energyClassKnown')"
                    [light]="true"
                ></lib-toggle>
                <!-- Energieausweis YES -->
                <ng-container *ngIf="contentService.getValue('objectEnergyClassKnown') === 'true'" >
                    <lib-toggle-vertical
                        class="more-margin-top"
                        #objectEnergyClass
                        [options]="switches"
                        [localValue]="contentService.getValue('objectEnergieEffizienzklasse')"
                        name="energyClass"
                        [light]="true"
                        (switchValue)="setSwitch($event, 'energyClass')"
                    ></lib-toggle-vertical>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-template>
</lib-tile-group>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

<ng-template #energyInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'ENERGY.INFO_HEADER1' | translate}}" text="{{'ENERGY.INFO_TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #energy2Info let-modal>
    <app-info-sidebar [modal]="modal" header="{{'ENERGY.INFO_HEADER2' | translate}}" text="{{'ENERGY.INFO_TEXT2' | translate}}"></app-info-sidebar>
</ng-template>
