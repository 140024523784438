import { Component, EventEmitter, HostListener, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-parking',
  templateUrl: './parking.component.html',
  styleUrls: ['./parking.component.scss']
})
export class ParkingComponent implements OnInit {
  public slideName: string = 'parking';
  next: string = 'erbbaurecht';
  disabled: boolean = true;
  tiles = tilesContent['PARKING'].items;
  selectedTile: string = 'KEINE_STELLPLAETZE';
  tilePosition: string = '122';
  basePosition: number = 122;
  screenPosition: string = '-50vw';
  tileSlideFactor: number = 266;
  tileIndex: number = 0;

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('contentStellplatz') contentStellplatz!: TemplateRef<any>;
  @ViewChild('contentCarport') contentCarport!: TemplateRef<any>;
  @ViewChild('contentGarage') contentGarage!: TemplateRef<any>;
  @ViewChild('contentDoppelgarage') contentDoppelgarage!: TemplateRef<any>;
  @ViewChild('contentTiefgarage') contentTiefgarage!: TemplateRef<any>;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService
  ) {}

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit() {
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.setStatus();
    });
  }

  setStatus() {
    if (this.screen.isSmallest()) { 
      this.tilePosition = '0';
      this.screenPosition = '-14px';
      this.tileSlideFactor = window.innerWidth - 15;
    }
    this.disabled = true;
    const state = this.dataQuery.getEntity(1);

    if(state && state['objectParking']) {
      if (state['objectParking'] === 'false') {
        this.tiles = this.contentService.setTileStatusChecked(this.tiles, 'KEINE_STELLPLAETZE', 'success', true);
      } else {
        this.selectedTile = 'STELLPLATZ';
        this.setContent();
        const newTiles: any[] = [];
        this.tiles.map(tile => {
          if(tile.value !== 'KEINE_STELLPLAETZE') {
            newTiles.push(tile);
          }
        });
        this.tiles = newTiles;

        if (state['objectAnzahlStellplatz'] !== undefined) {
          this.tiles = this.contentService.setTileStatusChecked(this.tiles, 'STELLPLATZ', state['objectAnzahlStellplatz'] > 0 ? 'success' : 'neutral', state['objectAnzahlStellplatz'] > 0 ? true : false);
        }
        if (state['objectAnzahlCarport'] !== undefined) {
          this.tiles = this.contentService.setTileStatusChecked(this.tiles, 'CARPORT', state['objectAnzahlCarport'] > 0 ? 'success' : 'neutral', state['objectAnzahlCarport'] > 0 ? true : false);
        }
        if (state['objectAnzahlGarage'] !== undefined) {
          this.tiles = this.contentService.setTileStatusChecked(this.tiles, 'GARAGE', state['objectAnzahlGarage'] > 0 ? 'success' : 'neutral', state['objectAnzahlGarage'] > 0 ? true : false);
        }
        if (state['objectAnzahlDoppelgarage'] !== undefined) {
          this.tiles = this.contentService.setTileStatusChecked(this.tiles, 'DOPPEL_GARAGE', state['objectAnzahlDoppelgarage'] > 0 ? 'success' : 'neutral', state['objectAnzahlDoppelgarage'] > 0 ? true : false);
        }
        if (state['objectAnzahlTiefgarage'] !== undefined) {
          this.tiles = this.contentService.setTileStatusChecked(this.tiles, 'TIEFGARAGEN_STELLPLATZ', state['objectAnzahlTiefgarage'] > 0 ? 'success' : 'neutral', state['objectAnzahlTiefgarage'] > 0 ? true : false);
        }
      }
    }
    setTimeout(() => {this.setDisabled()});
  }

  setValue(e: any) {
    this.tiles = this.contentService.setTileStatusChecked(this.tiles, e.value, e.checked ? 'warning' : 'neutral', e.checked ? true : false);
    this.selectedTile = e.value;

    if(e.value !== 'KEINE_STELLPLAETZE') {
      this.setContent();
      this.daService.update(1, { objectParking: 'true' });
      this.getCounterValue(0, e.value, e.checked);
      this.tiles = this.manageNoParkingTile();
    } else {
      this.daService.update(1, { objectParking: 'false' });
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, e.value, e.checked ? 'success' : 'neutral');
      this.contentService.setNav('next', 'parking', this.slideTo, this.next);
    }
    this.tilePosition = this.screen.calculateNewPos(this.tiles, e.value, this.tileSlideFactor, this.tilePosition, this.basePosition);
    setTimeout(() => {this.setDisabled()});
  }

  manageNoParkingTile() {
    const newTiles: any[] = [];
    let checkedCount = 0;

    this.tiles.map(tile => {
      if(tile.value !== 'KEINE_STELLPLAETZE') {
        newTiles.push(tile);
      }
    });
      
    newTiles.map(tile => {
      if(tile.checked) {checkedCount++}
    });
    
    if(checkedCount < 1) {
      newTiles.unshift({
        "label": "keine vorhanden",
        "name": "parking",
        "value": "KEINE_STELLPLAETZE",
        "image": 'assets/images/circleXmark.svg',
        "state": "neutral",
        "checked": false,
      });
    }
    return newTiles
  }

  setContent() {
    this.tiles.map(tile => {
      switch (tile.value) {
        case 'STELLPLATZ': tile.content = this.contentStellplatz;
        break;
        case 'CARPORT': tile.content = this.contentCarport;
        break;
        case 'GARAGE': tile.content = this.contentGarage;
        break;
        case 'DOPPEL_GARAGE': tile.content = this.contentDoppelgarage;
        break;
        case 'TIEFGARAGEN_STELLPLATZ': tile.content = this.contentTiefgarage;
        break;
      }
    });
  };

  getCounterValue(e: number, type: string, checked?: boolean) {

    switch (type) {
      case 'STELLPLATZ': this.daService.update(1, { objectAnzahlStellplatz: e });
      break;
      case 'CARPORT': this.daService.update(1, { objectAnzahlCarport: e });
      break;
      case 'GARAGE': this.daService.update(1, { objectAnzahlGarage: e });
      break;
      case 'DOPPEL_GARAGE': this.daService.update(1, { objectAnzahlDoppelgarage: e });
      break;
      case 'TIEFGARAGEN_STELLPLATZ': this.daService.update(1, { objectAnzahlTiefgarage: e });
      break;
    };

    setTimeout(() => {this.fillStellplaetze()});

    this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, type, e > 0 ? 'success' : checked ? 'warning' : 'neutral');
    setTimeout(() => {this.setDisabled()});
  }

  fillStellplaetze() {
    let stellplaetze = [];
    const status = this.dataQuery.getEntity(1);

    if (!!status) {
      if(status?.['objectAnzahlStellplatz'] && status?.['objectAnzahlStellplatz'] > 0) {
        for (let i = 0; i < status?.['objectAnzahlStellplatz']; i++) {
          stellplaetze.push({typ: 'STELLPLATZ'});
        }
      }
      if(status?.['objectAnzahlCarport'] && status?.['objectAnzahlCarport'] > 0) {
        for (let i = 0; i < status?.['objectAnzahlCarport']; i++) {
          stellplaetze.push({typ: 'CARPORT'});
        }
      }
      if(status?.['objectAnzahlGarage'] && status?.['objectAnzahlGarage'] > 0) {
        for (let i = 0; i < status?.['objectAnzahlGarage']; i++) {
          stellplaetze.push({typ: 'GARAGE'});
        }
      }
      if(status?.['objectAnzahlDoppelgarage'] && status?.['objectAnzahlDoppelgarage'] > 0) {
        for (let i = 0; i < status?.['objectAnzahlDoppelgarage']; i++) {
          stellplaetze.push({typ: 'DOPPEL_GARAGE'});
        }
      }
      if(status?.['objectAnzahlTiefgarage'] && status?.['objectAnzahlTiefgarage'] > 0) {
        for (let i = 0; i < status?.['objectAnzahlTiefgarage']; i++) {
          stellplaetze.push({typ: 'TIEFGARAGEN_STELLPLATZ'});
        }
      }
    }
    this.daService.update(1, { objectStellplaetze: stellplaetze });
  }

  getIndex(e: string) {
    this.tilePosition = this.screen.calculateNewPos(this.tiles, e, this.tileSlideFactor, this.tilePosition, this.basePosition);
  };

  setDisabled() {
    const state = this.dataQuery.getEntity(1);
    this.disabled = true;
    this.contentService.setSlideIndexState(this.slideName, false, 'warning');
    if(state && !!state['objectParking']) {
      if (state['objectParking'] === 'false') {
        this.contentService.setSlideIndexState(this.slideName, false, 'success');
        this.disabled = false;
      } else {
        if ((state['objectAnzahlStellplatz'] && state['objectAnzahlStellplatz'] > 0) ||
            (state['objectAnzahlCarport'] && state['objectAnzahlCarport'] > 0) ||
            (state['objectAnzahlGarage'] && state['objectAnzahlGarage'] > 0) ||
            (state['objectAnzahlDoppelgarage'] && state['objectAnzahlDoppelgarage'] > 0) ||
            (state['objectAnzahlTiefgarage'] && state['objectAnzahlTiefgarage'] > 0)) {
              this.contentService.setSlideIndexState(this.slideName, false, 'success');
              this.disabled = false;
            }
      }
    }
    this.stateChange.emit(true);
    this.daNextSlideService.update(1, { disabled: this.disabled});
  }
}
