<lib-header-text
    class="da-title"
    label="{{'PARTICIPANTS.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
></lib-tile-group>
<div class="da-buttons-wrapper">
    <app-nav-buttons
        class="da-buttons-group"
        [save]="true"
        [nextDisabled]="disabled"
        [current]="slideName"
        [next]="next"
        (slideTo)="setNav($event)"
        >
    </app-nav-buttons>
</div>
