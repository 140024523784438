import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    AbloesungBestehendesdarlehen,
    Arbeitserlaubnis,
    Aufenthalt,
    Aufenthaltstitel,
    BausparvertragAlsVermoegen,
    BestehendeImmobilie,
    BestehendesDarlehenDesFinanzierungsobjektes,
    DametaData,
    Depotvermoegen,
    EnergieEffizienzAusweis,
    FinanzielleSituation,
    Finanzierungsbaustein,
    Finanzierungsbedarf,
    Finanzierungsobjekt,
    Gebaeude,
    Guthaben,
    Haushalt,
    KfwProgramm,
    Kind,
    Kunde,
    LebensOderRentenversicherung,
    Nutzflaechen,
    Nutzung,
    requestData,
    requestDataTwo,
    Root,
    SonstigesVermoegen,
    Verbindlichkeit
} from "src/assets/content/mappings";
import { DataQuery } from "../state/data/data.query";
import { ContentService } from "./content.service";
import { environment } from "src/environments/environment";
import { tilesContent } from "src/assets/content/tiles";
import { dropdownsContent } from "src/assets/content/dropdowns";
import { TranslateService } from "@ngx-translate/core";
import { DataService } from "../state/data/data.service";
import {
    BestehendesDarlehenDesFinanzierungsobjektesAi,
    FinanzierungsobjektAi,
    VorschlagAiRoot,
    VorschlagKonventionellRoot
} from "src/assets/content/mapping-vorgangsfrei";

@Injectable({
    providedIn: 'root'
})
export class MappingService {

    private reduxData: any;
    private zusatzText!: string;
    private request = requestData;
    private rootObject!: Root;
    private haushalt = this.request.haushalte[0];
    private kunde = this.request.haushalte[0].kunden;
    private finanzierungsobjekt = this.request.finanzierungsobjekt;
    private finanzierungsbedarf = this.request.finanzierungsbedarf;

    constructor(
        private http: HttpClient,
        private dataQuery: DataQuery,
        private contentService: ContentService,
        private translate: TranslateService,
        private daService: DataService,
    ) {
        this.reduxData = this.dataQuery.getEntity(1);
    }

    collectAdditionalInformation(data: any) {
        const schufa = !!data.schufa && data.schufa === 'schufaOk' ? 'ist als einwandfrei angegeben worden' : 'Kunde hat angegeben, dass sein Score unter 90% liegt bzw. negative Einträge vorliegen';
        const elternzeitDN1 = !!data.elternzeit && data.elternzeit === 'ElternzeitBesteht' ? 'ja' : 'nein';
        const elternzeitDN2 = !!data.elternzeitPartner && data.elternzeitPartner === 'ElternzeitBesteht' ? 'ja' : 'nein';
        const elternzeit = (!!elternzeitDN1 && elternzeitDN1 === 'ja') || (!!elternzeitDN2 && elternzeitDN2 === 'ja') ? 'ja' : 'nein';
        const kinderKKV = (!!data.kinderKkv1 && data.kinderKkv1 === 'true') || (!!data.kinderKkv2 && data.kinderKkv2 === 'true') ? 'ja' : 'nein';
        const anzahlGehaelter = dropdownsContent['GEHALT'].items;
        let anzahlGehaelterDN1;
        let anzahlGehaelterDN2;
        if (!!elternzeitDN1 && elternzeitDN1 === 'ja') {
            if (!!data.anzahlGehaelterProJahr && data.anzahlGehaelterProJahr !== 'ZWOELF') {
                anzahlGehaelter.map((item: any) => {
                    if (item.value === data.anzahlGehaelterProJahr) {
                        anzahlGehaelterDN1 = item.viewValue
                    }
                });
            }
        }
        if (!!elternzeitDN2 && elternzeitDN2 === 'ja') {
            if (!!data.anzahlGehaelterProJahrPartner && data.anzahlGehaelterProJahrPartner !== 'ZWOELF') {
                anzahlGehaelter.map((item: any) => {
                    if (item.value === data.anzahlGehaelterProJahrPartner) {
                        anzahlGehaelterDN2 = item.viewValue
                    }
                });
            }
        }
        const elternzeitDateDN1 = !!data.elternzeitBis ? data.elternzeitBis.toLocaleDateString('de-DE') : '';
        const elternzeitDateDN2 = !!data.elternzeitBisPartner ? data.elternzeitBisPartner.toLocaleDateString('de-DE') : '';
        const energy = !!data.objectEnergieEffizienzklasse ? data.objectEnergieEffizienzklasse : '';
        const miete1 = !!data.miete1 ? data.miete1 : '';
        const miete2 = !!data.miete2 ? data.miete2 : '';
        const exposeLink = !!data.objectExposeLink ? data.objectExposeLink : 'kein Exposé hochgeladen';
        let unterhalt = false;
        if (!!data.KinderErfassung && data.KinderErfassung === 'VORHANDENE_KINDER') {
            data.kinder.map((kind: any) => {
                if (kind.unterhaltMinus === 'true') {
                    unterhalt = true;
                }
            });
        }
        const futureChanges = !!data.objectNutzungFuture && data.objectNutzungFuture === 'true' ? 'ja' : 'nein';
        let changes;
        if (futureChanges === 'ja') {
            switch (data.objectNutzungwohnflaeche) {
                case 'EIGENGENUTZT': {
                    changes = 'vollständige Eigennutzung ist geplant';
                    break;
                }
                case 'TEILVERMIETET': {
                    changes = 'soll teilweise vermietet werden';
                    break;
                }
                case 'VERMIETET': {
                    changes = 'soll vollständig vermietet werden';
                    break;
                }
            }
        } else {
            switch (data.objectNutzungwohnflaeche) {
                case 'EIGENGENUTZT': {
                    changes = 'unveränderte Eigennutzung';
                    break;
                }
                case 'TEILVERMIETET': {
                    changes = 'unverändert, bleibt teilweise vermietet';
                    break;
                }
                case 'VERMIETET': {
                    changes = 'unverändert, bleibt vollständig vermietet';
                    break;
                }
            }
        }
        let statusSpec;
        switch (data.statusSpec) {
            case 'Vorbereitung': {
                statusSpec = 'Kunde ist in der Vorbereitungsphase';
                break;
            }
            case 'Besichtigung': {
                statusSpec = 'Kunde ist in der Objektbesichtungsphase';
                break;
            }
            case 'Verhandlung': {
                statusSpec = 'Kunde befindet sich in der Kaufverhandlungsphase';
                break;
            }
            case 'Vor Notartermin': {
                statusSpec = 'Notartermin steht an';
                break;
            }
            case 'Kaufvertrag': {
                statusSpec = 'Kunde hat bereits den Kaufvertrag unterschrieben';
                break;
            }
        }

        const statusItems = tilesContent['ESTATE_STATUS'].items;
        let status;
        statusItems.map((item: any) => {
            if (item.value === data.estateStatus) {
                this.translate.get(item.label).subscribe((res: string) => {
                    status = res;
                });
            }
        });

        const modernisierung = !!data.objectModernisierungErfassung && data.objectModernisierungErfassung === 'unbekannt' ? 'Kunde gab an, dass Ihm aktuell nicht bekannt ist, ob Modernisierungen erfolgt sind' : '';

        this.zusatzText = `Schufa Angabe: ${schufa} | Stand des Bauvorhabens: ${status} | Status: ${statusSpec} | Link zum Expose: ${exposeLink} | zukünftige Objektnutzung des Kaufobjektes: ${changes}`

        if (data.projectPlan === 'MIXED') {
            this.zusatzText = `${this.zusatzText} | Bauvorhaben: Ein Mix aus mehreren Vorhaben (Kapitalbeschaffung, Modernisierung und / oder Anschlussfinanzierung)`;
        }
        if (data.objectType === "Gewerbe") {
            this.zusatzText = `${this.zusatzText} | Typ des Objekts: Gewerbe`;
        }
        if (!!data.objectEnergieEffizienzklasse) {
            this.zusatzText = `${this.zusatzText} | Die Energieeffizienzklasse des Objekts: ${energy}`
        }
        if (!!data.objectModernisierungErfassung && data.objectModernisierungErfassung === 'unbekannt') {
            this.zusatzText = `${this.zusatzText} | Modernisierung: ${modernisierung}`
        }
        if (data.Beschaeftigung === 'SELBSTSTAENDIGER' || data.Beschaeftigung === 'FREIBERUFLER') {
            this.zusatzText = `${this.zusatzText} | Gewinn aktuelles Jahr: ${data.gewinnAktuellesJahr}, Gewinn letztes Jahr: ${data.gewinnLetztesJahr}, Gewinn vorletztes Jahr: ${data.gewinnVorletztesJahr}`;
        }

        if (data.Beschaeftigung === 'SCHUELER') {
            this.zusatzText = `${this.zusatzText} | Beschäftigung: Schüler / Student`;
        }

        if (data.participants !== 'allein') {
            if (data.BeschaeftigungPartner === 'SELBSTSTAENDIGER' || data.BeschaeftigungPartner === 'FREIBERUFLER') {
                this.zusatzText = `${this.zusatzText} | DN2: Gewinn aktuelles Jahr: ${data.gewinnAktuellesJahrPartner}, Gewinn letztes Jahr: ${data.gewinnLetztesJahrPartner}, Gewinn vorletztes Jahr: ${data.gewinnVorletztesJahrPartner}`;
            }
            if (data.BeschaeftigungPartner === 'SCHUELER') {
                this.zusatzText = `${this.zusatzText} | DN2: Beschäftigung: Schüler / Student`;
            }
        }

        this.zusatzText = `${this.zusatzText} | Es besteht eine Elternzeit: ${elternzeit}`;

        if (elternzeit === 'ja') {
            if (elternzeitDN1 === 'ja') {
                this.zusatzText = `${this.zusatzText} | Ende der Elternzeit (DN1): ${elternzeitDateDN1}, Gehalt nach Elternzeit (DN1): ${data.gehalt}`;
            }
            if (!!anzahlGehaelterDN1) {
                this.zusatzText = `${this.zusatzText}, Anzahl der Jahresgehälter (DN1): ${anzahlGehaelterDN1}`;
            }
            if (elternzeitDN2 === 'ja') {
                this.zusatzText = `${this.zusatzText} | Ende der Elternzeit (DN2): ${elternzeitDateDN2}, Gehalt nach Elternzeit (DN2): ${data.gehaltPartner}`;
            }
            if (!!anzahlGehaelterDN2) {
                this.zusatzText = `${this.zusatzText}, Anzahl der Jahresgehälter (DN2): ${anzahlGehaelterDN2}`;
            }
        }

        if (!!data.KinderErfassung && data.KinderErfassung === 'VORHANDENE_KINDER' && !unterhalt) {
            this.zusatzText = `${this.zusatzText} | Es bestehen keine Unterhaltsverpflichtungen für vorhandene Kinder`;
        }

        if (!!data.countMiete) {
            if (data.countMiete === 'one') {
                this.zusatzText = `${this.zusatzText} | Es gibt 1 Mietvertrag. Mietbetrag monatlich: ${miete1}`
            }
            if (data.countMiete === 'two') {
                this.zusatzText = `${this.zusatzText} | Es gibt 2 Mietverträge. Mietbetrag monatlich für die erste Wohnung: ${miete1}, Mietbetrag monatlich für die zweite Wohnung: ${miete2}`
            }
        }

        if (environment.webappType === 'a') {
            const zustellservice = data.sendCertificate ? 'ja' : 'nein';
            const zusteller = !!data.sendCertificateTo ? data.sendCertificateTo : ''
            const zustellEmail = !!data.sendCertificateToEmail ? data.sendCertificateToEmail : ''

            this.zusatzText = `${this.zusatzText} | Zustellservice: ${zustellservice}`;

            if (zustellservice === 'ja') {
                this.zusatzText = `${this.zusatzText}, Zustellservice an: ${zusteller}, Zustell E-Mail-Adresse: ${zustellEmail}`;
            }

            const anrede = data.haushaltPerson !== 'FRAU' ? 'Herr' : 'Frau';
            const prof = data.zahlendePersonTitleProf ? 'Prof.' : '';
            const dr = data.zahlendePersonTitleDr ? 'Dr.' : '';

            this.zusatzText = `${this.zusatzText} | zahlungspflichtige Person: ${anrede} ${prof} ${dr} ${data.zahlendePersonFirstName} ${data.zahlendePersonLastName}, E-Mail: ${data.zahlendePersonEmail}`;
        }

        if(data.lead && !!data.lead) {
            this.zusatzText = `${this.zusatzText} | Leadquelle: ${data.lead}`;
        }

        return this.zusatzText
    }

    async mapReduxData() {
        this.reduxData = this.dataQuery.getEntity(1);

        const withPartner = this.reduxData.participants === 'allein' ? false : true;
        const schufa = !!this.reduxData.schufa && this.reduxData.schufa === 'schufaOk' ? true : false;
        const zusatztext = this.collectAdditionalInformation(this.reduxData);

        if (withPartner) {
            this.request = requestDataTwo;
            this.haushalt = requestDataTwo.haushalte[0];
            this.kunde = requestDataTwo.haushalte[0].kunden;
        }
        console.log('this.request', this.request)
        this.request.haushalte[0] = this.mapHaushalt(this.haushalt, withPartner);
        this.request.finanzierungsbedarf = this.mapFinanzierungsBedarf(this.finanzierungsbedarf);
        this.request.finanzierungsobjekt = this.mapFinanzierungsObjekt(this.finanzierungsobjekt);
        let daMeta: DametaData = {
            dataformat: 'damodelv3',
            precheckstore: this.reduxData.expressRequest ? false : true
        }
        if (this.reduxData.uuid) {
            daMeta = { ...daMeta, referenceuuid: this.reduxData.uuid }
        }
        this.rootObject = {
            dametadata: daMeta,
            europacedata: {
                kundenangaben: this.request,
                zusaetzlicherereignistext: zusatztext
            },
            internaldata: {
                schufascorepositive: schufa
            },
            documents: this.reduxData.documents ? this.reduxData.documents : []
        }
        return this.sendEuropaceRequest(this.rootObject);
    }

    async mapReduxDataUpdate() {
        this.reduxData = this.dataQuery.getEntity(1);

        const withPartner = this.reduxData.participants === 'allein' ? false : true;
        const schufa = !!this.reduxData.schufa && this.reduxData.schufa === 'schufaOk' ? true : false;
        const zusatztext = this.collectAdditionalInformation(this.reduxData);

        if (withPartner) {
            this.request = requestDataTwo;
            this.haushalt = requestDataTwo.haushalte[0];
            this.kunde = requestDataTwo.haushalte[0].kunden;
        }

        this.request.haushalte[0] = this.mapHaushalt(this.haushalt, withPartner);
        this.request.finanzierungsbedarf = this.mapFinanzierungsBedarf(this.finanzierungsbedarf);
        this.request.finanzierungsobjekt = this.mapFinanzierungsObjekt(this.finanzierungsobjekt);
        this.rootObject = {
            dametadata: {
                dataformat: 'damodelv3',
                precheckstore: this.reduxData.expressRequest ? false : true
            },
            europacedata: {
                kundenangaben: this.request,
                zusaetzlicherereignistext: zusatztext
            },
            internaldata: {
                schufascorepositive: schufa
            },
            documents: this.reduxData.documents ? this.reduxData.documents : []
        }
        return this.patchEuropaceRequest(this.rootObject);
    }

    mapHaushalt(data: Haushalt, twoPeople: boolean) {
        // PERSONALDATEN
        // ONE PERSON Or PERSON A
        let dataOne = {
            haushaltPerson: this.reduxData.haushaltPerson,
            geburtsdatum: this.reduxData.geburtsdatum,
            familienstand: this.reduxData.familienstand,
            wohnsituationPlz: this.reduxData.wohnsituationPlz,
            wohnsituationOrt: this.reduxData.wohnsituationOrt,
            staatsangehoerigkeit: this.reduxData.staatsangehoerigkeit,
            arbeitserlaubnis: this.reduxData.arbeitserlaubnis,
            arbeitserlaubnisBefristetBis: this.reduxData.arbeitserlaubnisBefristetBis,
            aufenthaltstitel: this.reduxData.aufenthaltstitel,
            visumBefristetBis: this.reduxData.visumBefristetBis,
            inDeutschlandSeit: this.reduxData.inDeutschlandSeit,
            Beschaeftigung: this.reduxData.Beschaeftigung,
            beruf: this.reduxData.beruf,
            gehalt: this.reduxData.gehalt,
            probezeit: this.reduxData.probezeit,
            anzahlGehaelterProJahr: this.reduxData.anzahlGehaelterProJahr,
            beschaeftigtSeit: this.reduxData.beschaeftigtSeit,
            beschaeftigungsstatus: this.reduxData.beschaeftigungsstatus,
            elternzeit: this.reduxData.elternzeit,
            elterngeld: this.reduxData.elterngeld,
            gewinnAktuellesJahr: this.reduxData.gewinnAktuellesJahr,
            gewinnLetztesJahr: this.reduxData.gewinnLetztesJahr,
            gewinnVorletztesJahr: this.reduxData.gewinnVorletztesJahr,
            SelbststaendigeTaetigkeit: this.reduxData.SelbststaendigeTaetigkeit,
            taetigSeit: this.reduxData.taetigSeit,
            typRente: this.reduxData.typRente,
            monatlicheRente: this.reduxData.monatlicheRente,
            RenteBefristet: this.reduxData.RenteBefristet,
            RenteBefristetBis: this.reduxData.RenteBefristetBis,
            nebentaetigkeit: this.reduxData.nebentaetigkeit,
            einkuenfteAusNebentaetigkeit: this.reduxData.einkuenfteAusNebentaetigkeit,
            nebentaetigkeitDate: this.reduxData.nebentaetigkeitDate,
            rente: this.reduxData.rente,
            summeUnbefristeteZusatzrentenMonatlich: this.reduxData.summeUnbefristeteZusatzrentenMonatlich,
            provision: this.reduxData.provision,
            summeSonstigeEinnahmenMonatlich: this.reduxData.summeSonstigeEinnahmenMonatlich,
            VerbindlichkeitenErfassung: this.reduxData.VerbindlichkeitenErfassung,
            verbindlichkeiten: this.reduxData.verbindlichkeiten,
            haushaltPersonMiete: this.reduxData.haushaltPersonMiete,
            countMiete: this.reduxData.countMiete,
            mieteBetrag1: this.reduxData.miete1,
            mieteEntfaellt1: this.reduxData.abloeseMiete1,
            mieteBetrag2: this.reduxData.miete2,
            mieteEntfaellt2: this.reduxData.abloeseMiete2,
            haushaltPersonKKV: this.reduxData.haushaltPersonKKV,
            kkv1: this.reduxData.kkv1,
            kkv2: this.reduxData.kkv2,
            kkv3: this.reduxData.kkv3,
            kkv4: this.reduxData.kkv4,
            hasAssets: this.reduxData.hasAssets,
            sparguthabenVorhanden: this.reduxData.sparguthaben,
            sparguthabenBetrag: this.reduxData.sparguthabenBetrag,
            sparenVerwendung: this.reduxData.sparenVerwendung,
            sparenMaximalEinzusetzenderBetrag: this.reduxData.sparenMaximalEinzusetzenderBetrag,
            bausparguthabenVorhanden: this.reduxData.bausparguthaben,
            bausparvertraege: this.reduxData.bausparvertraege,
            wertpapiereVorhanden: this.reduxData.wertpapiere,
            wertpapiereBetrag: this.reduxData.wertpapiereBetrag,
            wertpapiereVerwendung: this.reduxData.wertpapiereVerwendung,
            wertpapiereMaximalEinzusetzenderBetrag: this.reduxData.wertpapiereMaximalEinzusetzenderBetrag,
            immobilienVorhanden: this.reduxData.immobilien,
            familienvermoegen: this.reduxData.familienvermoegen,
            familienSparvermoegen: this.reduxData.familienSparvermoegen,
            familienvermoegenBetrag: this.reduxData.familienvermoegenBetrag,
            versicherungVorhanden: this.reduxData.versicherung,
            versicherungsvertraege: this.reduxData.versicherungsvertraege,
            bestehendeImmobilien: this.reduxData.bestehendeImmobilien,
            haushaltPersonTitleDr: this.reduxData.haushaltPersonTitleDr,
            zahlendePersonTitleDr: this.reduxData.zahlendePersonTitleDr,
            haushaltPersonTitleProf: this.reduxData.haushaltPersonTitleProf,
            zahlendePersonTitleProf: this.reduxData.zahlendePersonTitleProf,
            haushaltPersonFirstName: this.reduxData.haushaltPersonFirstName,
            zahlendePersonFirstName: this.reduxData.zahlendePersonFirstName,
            haushaltPersonLastName: this.reduxData.haushaltPersonLastName,
            zahlendePersonLastName: this.reduxData.zahlendePersonLastName,
            haushaltPersonVorwahl: this.reduxData.haushaltPersonVorwahl,
            haushaltPersonTelefon: this.reduxData.haushaltPersonTelefon,
            haushaltPersonEmail: this.reduxData.haushaltPersonEmail,
            zahlendePersonEmail: this.reduxData.zahlendePersonEmail,
            haushaltPersonStrasse: this.reduxData.haushaltPersonStrasse,
            haushaltPersonStrasseNr: this.reduxData.haushaltPersonStrasseNr,
        }
        data.kunden[0] = this.mapPeople(this.kunde[0], dataOne);

        if (dataOne.Beschaeftigung === 'RENTNER') {
            if (dataOne.typRente === 'Erwerbsminderungsrente') {
                if (dataOne.RenteBefristet === 'unbefristet') {
                    data.finanzielleSituation.einnahmen.summeUnbefristeteZusatzrentenMonatlich = {
                        betrag: this.contentService.convertStringNumber(dataOne.monatlicheRente)
                    }
                } else {
                    data.finanzielleSituation.einnahmen.summeSonstigeEinnahmenMonatlich = {
                        betrag: this.contentService.convertStringNumber(dataOne.monatlicheRente),
                        kommentar: `die Erwerbsminderungsrente ist befristet bis: ${this.contentService.convertDate(dataOne.RenteBefristetBis)}`
                    }
                }
            }
        }
        data.finanzielleSituation = this.mapFinanzielleSituation(data.finanzielleSituation, dataOne);

        // PERSON B
        if (twoPeople) {
            let dataTwo = {
                haushaltPerson: this.reduxData.haushaltPersonPartner,
                geburtsdatum: this.reduxData.geburtsdatumPartner,
                familienstand: this.reduxData.familienstandPartner,
                wohnsituationPlz: this.reduxData.wohnsituationPlzPartner,
                wohnsituationOrt: this.reduxData.wohnsituationOrtPartner,
                staatsangehoerigkeit: this.reduxData.staatsangehoerigkeitPartner,
                arbeitserlaubnis: this.reduxData.arbeitserlaubnisPartner,
                arbeitserlaubnisBefristetBis: this.reduxData.arbeitserlaubnisBefristetBisPartner,
                aufenthaltstitel: this.reduxData.aufenthaltstitelPartner,
                visumBefristetBis: this.reduxData.visumBefristetBisPartner,
                inDeutschlandSeit: this.reduxData.inDeutschlandSeitPartner,
                Beschaeftigung: this.reduxData.BeschaeftigungPartner,
                beruf: this.reduxData.berufPartner,
                gehalt: this.reduxData.gehaltPartner,
                probezeit: this.reduxData.probezeitPartner,
                anzahlGehaelterProJahr: this.reduxData.anzahlGehaelterProJahrPartner,
                beschaeftigtSeit: this.reduxData.beschaeftigtSeitPartner,
                beschaeftigungsstatus: this.reduxData.beschaeftigungsstatusPartner,
                elternzeit: this.reduxData.elternzeitPartner,
                elterngeld: this.reduxData.elterngeldPartner,
                gewinnAktuellesJahr: this.reduxData.gewinnAktuellesJahrPartner,
                gewinnLetztesJahr: this.reduxData.gewinnLetztesJahrPartner,
                gewinnVorletztesJahr: this.reduxData.gewinnVorletztesJahrPartner,
                SelbststaendigeTaetigkeit: this.reduxData.SelbststaendigeTaetigkeitPartner,
                taetigSeit: this.reduxData.taetigSeitPartner,
                typRente: this.reduxData.typRentePartner,
                monatlicheRente: this.reduxData.monatlicheRentePartner,
                RenteBefristet: this.reduxData.RenteBefristetPartner,
                RenteBefristetBis: this.reduxData.RenteBefristetBisPartner,
                nebentaetigkeit: this.reduxData.nebentaetigkeitPartner,
                einkuenfteAusNebentaetigkeit: this.reduxData.einkuenfteAusNebentaetigkeitPartner,
                nebentaetigkeitDate: this.reduxData.nebentaetigkeitDatePartner,
                rente: this.reduxData.rentePartner,
                summeUnbefristeteZusatzrentenMonatlich: this.reduxData.summeUnbefristeteZusatzrentenMonatlichPartner,
                provision: this.reduxData.provisionPartner,
                summeSonstigeEinnahmenMonatlich: this.reduxData.summeSonstigeEinnahmenMonatlichPartner,
                haushaltPersonTitleProf: this.reduxData.haushaltPersonTitleProfPartner,
                haushaltPersonTitleDr: this.reduxData.haushaltPersonTitleDrPartner,
                haushaltPersonFirstName: this.reduxData.haushaltPersonFirstNamePartner,
                haushaltPersonLastName: this.reduxData.haushaltPersonLastNamePartner,
                haushaltPersonVorwahl: this.reduxData.haushaltPersonVorwahlPartner,
                haushaltPersonTelefon: this.reduxData.haushaltPersonTelefonPartner,
                haushaltPersonEmail: this.reduxData.haushaltPersonEmailPartner,
                haushaltPersonStrasse: !!this.reduxData.haushaltPersonStrassePartner ? this.reduxData.haushaltPersonStrassePartner : this.reduxData.haushaltPersonStrasse,
                haushaltPersonStrasseNr: !!this.reduxData.haushaltPersonStrasseNrPartner ? this.reduxData.haushaltPersonStrasseNrPartner : this.reduxData.haushaltPersonStrasseNr
            }
            data.kunden[1] = this.mapPeople(this.kunde[1], dataTwo);

            if (dataTwo.Beschaeftigung === 'RENTNER') {
                if (dataTwo.typRente === 'Erwerbsminderungsrente') {
                    if (dataTwo.RenteBefristet === 'unbefristet') {
                        let rente = data.finanzielleSituation.einnahmen.summeUnbefristeteZusatzrentenMonatlich?.betrag && data.finanzielleSituation.einnahmen.summeUnbefristeteZusatzrentenMonatlich?.betrag > 0 ?
                            data.finanzielleSituation.einnahmen.summeUnbefristeteZusatzrentenMonatlich.betrag + this.contentService.convertStringNumber(dataTwo.monatlicheRente) :
                            this.contentService.convertStringNumber(dataTwo.monatlicheRente);

                        data.finanzielleSituation.einnahmen.summeUnbefristeteZusatzrentenMonatlich = {
                            betrag: rente
                        }
                    } else {
                        let rente = data.finanzielleSituation.einnahmen.summeSonstigeEinnahmenMonatlich?.betrag && data.finanzielleSituation.einnahmen.summeSonstigeEinnahmenMonatlich?.betrag > 0 ?
                            data.finanzielleSituation.einnahmen.summeSonstigeEinnahmenMonatlich.betrag + this.contentService.convertStringNumber(dataTwo.monatlicheRente) :
                            this.contentService.convertStringNumber(dataTwo.monatlicheRente);

                        data.finanzielleSituation.einnahmen.summeSonstigeEinnahmenMonatlich = {
                            betrag: rente,
                            kommentar: `die Erwerbsminderungsrente ist befristet bis: ${this.contentService.convertDate(dataTwo.RenteBefristetBis)}`
                        }
                    }
                }
            }
            data.finanzielleSituation = this.mapFinanzielleSituation(data.finanzielleSituation, dataTwo);
        }
        // else {
        //     data.kunden.pop();
        // }

        // erfahrungImmobilien
        data.erfahrungImmobilienFinanzierung = !!this.reduxData.erfahrungImmobilienFinanzierung ? this.reduxData.erfahrungImmobilienFinanzierung : false;

        // KINDER
        if (!!this.reduxData.KinderErfassung) {
            if (this.reduxData.KinderErfassung === 'VORHANDENE_KINDER') {
                let kinder: Kind[] = [];
                let unterhalt = 0;
                this.reduxData.kinder.map((kind: any) => {
                    if (kind.unterhaltPlus === 'true') {
                        kinder.push({
                            name: kind.name,
                            geburtsdatum: kind.geburtsdatum ? this.contentService.convertDate(kind.geburtsdatum) : undefined,
                            kindergeldWirdBezogen: kind.kindergeldWirdBezogen === 'true' ? true : false,
                            unterhalt: this.contentService.convertStringNumber(kind.unterhaltPlusBetrag, 2)
                        })
                    } else {
                        kinder.push({
                            name: kind.name,
                            geburtsdatum: kind.geburtsdatum ? this.contentService.convertDate(kind.geburtsdatum) : undefined,
                            kindergeldWirdBezogen: kind.kindergeldWirdBezogen === 'true' ? true : false,
                        })
                    }
                    if (kind.unterhaltMinus === 'true') {
                        unterhalt = unterhalt + this.contentService.convertStringNumber(kind.unterhaltMinusBetrag, 2);
                    }

                });
                let bestehenderUnterhalt = data.finanzielleSituation.ausgaben.unterhaltsverpflichtungenMonatlich
                bestehenderUnterhalt?.push(unterhalt);
                data.finanzielleSituation.ausgaben.unterhaltsverpflichtungenMonatlich = bestehenderUnterhalt;
                data.kinderErfassung = {
                    '@type': this.reduxData.KinderErfassung,
                    kinder: kinder
                }
            } else {
                data.kinderErfassung = {
                    '@type': this.reduxData.KinderErfassung,
                }
            }
        }

        if (!!this.reduxData.haushaltPersonFahrzeuge && this.reduxData.haushaltPersonFahrzeuge === 'VorhandeneFahrzeuge') {
            let kfzAnzahl = { kfzAnzahl: 0 };
            if (!!this.reduxData.countFahrzeuge) {
                kfzAnzahl = { kfzAnzahl: this.reduxData.countFahrzeuge === 'one' ? 1 : 2 };
            }
            data.zusatzangaben = kfzAnzahl;
        }
        return data;
    }

    mapFinanzielleSituation(finanzen: FinanzielleSituation, data: any) {
        let rente = finanzen.einnahmen.summeUnbefristeteZusatzrentenMonatlich ? finanzen.einnahmen.summeUnbefristeteZusatzrentenMonatlich : { betrag: 0, kommentar: '' };
        let provision = finanzen.einnahmen.summeVariablerEinkuenfteMonatlich ? finanzen.einnahmen.summeVariablerEinkuenfteMonatlich : 0;
        let nebentaetigkeit = finanzen.einnahmen.einkuenfteAusNebentaetigkeit ? finanzen.einnahmen.einkuenfteAusNebentaetigkeit : [];

        // FINANZEN: EINNAHMEN
        if (!!data.nebentaetigkeit && data.nebentaetigkeit === 'NebentaetigkeitBesteht') {
            nebentaetigkeit?.push({
                betragMonatlich: this.contentService.convertStringNumber(data.einkuenfteAusNebentaetigkeit),
                beginnDerTaetigkeit: this.contentService.convertDate(data.nebentaetigkeitDate)
            });
            finanzen.einnahmen.einkuenfteAusNebentaetigkeit = nebentaetigkeit;
        }

        if (!!data.rente && data.rente === 'RenteBesteht') {
            if (rente?.betrag) {
                if (rente.betrag > 0) {
                    rente.betrag = rente.betrag + this.contentService.convertStringNumber(data.summeUnbefristeteZusatzrentenMonatlich);
                } else {
                    rente.betrag = this.contentService.convertStringNumber(data.summeUnbefristeteZusatzrentenMonatlich);
                }
            } else {
                rente = {
                    betrag: this.contentService.convertStringNumber(data.summeUnbefristeteZusatzrentenMonatlich)
                }
            }
            finanzen.einnahmen.summeUnbefristeteZusatzrentenMonatlich = rente;
        }

        if (!!data.provision && data.provision === 'ProvisionBesteht') {
            if (provision && provision > 0) {
                provision = provision + this.contentService.convertStringNumber(data.summeSonstigeEinnahmenMonatlich);
            } else {
                provision = this.contentService.convertStringNumber(data.summeSonstigeEinnahmenMonatlich);
            }
            finanzen.einnahmen.summeVariablerEinkuenfteMonatlich = provision;
        }

        // FINANZEN: VERBINDLICHKEITEN
        if (!!data.VerbindlichkeitenErfassung && data.VerbindlichkeitenErfassung === 'VorhandeneVerbindlichkeiten') {
            let ratenkredite: Verbindlichkeit[] = [];
            let privatdarlehen: Verbindlichkeit[] = [];
            let sonstiges: Verbindlichkeit = {};

            if (data.verbindlichkeiten) {
                data.verbindlichkeiten.map((verbindlichkeit: any) => {
                    if (verbindlichkeit.verbindlichkeitTyp === 'leasing') {
                        ratenkredite.push({
                            rateMonatlich: this.contentService.convertStringNumber(verbindlichkeit.rate),
                            wirdAbgeloest: false,
                            kommentar: verbindlichkeit.name
                        })
                    } else if (verbindlichkeit.verbindlichkeitTyp === 'ratenkredite') {
                        ratenkredite.push({
                            rateMonatlich: this.contentService.convertStringNumber(verbindlichkeit.rate),
                            restschuld: this.contentService.convertStringNumber(verbindlichkeit.restschuld),
                            wirdAbgeloest: verbindlichkeit.abloesen === 'true' ? true : false,
                            kommentar: verbindlichkeit.name
                        })
                    } else if (verbindlichkeit.verbindlichkeitTyp === 'privatdarlehen') {
                        privatdarlehen.push({
                            rateMonatlich: this.contentService.convertStringNumber(verbindlichkeit.rate),
                            restschuld: this.contentService.convertStringNumber(verbindlichkeit.restschuld),
                            wirdAbgeloest: verbindlichkeit.abloesen === 'true' ? true : false,
                            kommentar: verbindlichkeit.name
                        })
                    } else {
                        sonstiges = {
                            rateMonatlich: this.contentService.convertStringNumber(verbindlichkeit.rate),
                            restschuld: this.contentService.convertStringNumber(verbindlichkeit.restschuld),
                            wirdAbgeloest: verbindlichkeit.abloesen === 'true' ? true : false,
                            kommentar: verbindlichkeit.name
                        }
                    }
                })
            }
            finanzen.verbindlichkeiten.ratenkredite = ratenkredite;
            finanzen.verbindlichkeiten.privatdarlehen = privatdarlehen;
            if (sonstiges.rateMonatlich) { finanzen.verbindlichkeiten.sonstigeVerbindlichkeit = sonstiges; }
        }
        // FINANZEN: AUSGABEN
        if (!!data.haushaltPersonMiete && data.haushaltPersonMiete === 'VorhandeneMiete') {
            if (!!data.countMiete) {
                if (data.countMiete === 'one') {
                    finanzen.ausgaben.summeMietausgaben = {
                        betragMonatlich: this.contentService.convertStringNumber(data.mieteBetrag1),
                        entfaelltMitFinanzierung: data.mieteEntfaellt1 === 'true' ? true : false
                    }
                } else {
                    let miete = data.mieteEntfaellt1 === 'false' ? this.contentService.convertStringNumber(data.mieteBetrag1) : 0;
                    miete = data.mieteEntfaellt2 === 'false' ? this.contentService.convertStringNumber(data.mieteBetrag2) + miete : miete;

                    if (miete > 0) {
                        finanzen.ausgaben.summeMietausgaben = {
                            betragMonatlich: miete,
                            entfaelltMitFinanzierung: false
                        }
                    }
                }
            }
        }
        if (!!data.haushaltPersonKKV && data.haushaltPersonKKV !== 'Nein') {
            let kkv = 0;
            if (data.kkv1) {
                kkv = kkv + this.contentService.convertStringNumber(data.kkv1);
            }
            if (data.kkv2) {
                kkv = kkv + this.contentService.convertStringNumber(data.kkv2);
            }
            if (data.kkv3) {
                kkv = kkv + this.contentService.convertStringNumber(data.kkv3);
            }
            if (data.kkv4) {
                kkv = kkv + this.contentService.convertStringNumber(data.kkv4);
            }
            finanzen.ausgaben.summePrivaterKrankenversicherungenMonatlich = kkv;
        }

        // FINANZEN: VERMOEGEN
        // Sparen
        if (!!data.sparguthabenVorhanden && data.sparguthabenVorhanden === 'sparguthabenVorhanden') {
            let sparen: Guthaben = {};
            if (data.sparguthabenBetrag) {
                sparen.guthaben = this.contentService.convertStringNumber(data.sparguthabenBetrag);
            }
            if (data.sparenVerwendung) {
                if (data.sparenVerwendung === 'AUFLOESUNG_ALS_VERWENDUNG') {
                    sparen.verwendung = {
                        '@type': data.sparenVerwendung,
                        maximalEinzusetzenderBetrag: this.contentService.convertStringNumber(data.sparenMaximalEinzusetzenderBetrag)
                    }
                } else {
                    sparen.verwendung = {
                        '@type': 'KEINE_VERWENDUNG'
                    }
                }

            }
            finanzen.vermoegen.summeBankUndSparguthaben = sparen
        }
        // Wertpapiere
        if (!!data.wertpapiereVorhanden && data.wertpapiereVorhanden === 'wertpapiereVorhanden') {
            let wertpapiere: Depotvermoegen = {};
            if (data.wertpapiereBetrag) {
                wertpapiere.depotwert = this.contentService.convertStringNumber(data.wertpapiereBetrag);
            }
            if (data.wertpapiereVerwendung) {
                if (data.wertpapiereVerwendung === 'AUFLOESUNG_ALS_VERWENDUNG') {
                    wertpapiere.verwendung = {
                        '@type': data.wertpapiereVerwendung,
                        maximalEinzusetzenderBetrag: this.contentService.convertStringNumber(data.wertpapiereMaximalEinzusetzenderBetrag)
                    }
                } else {
                    wertpapiere.verwendung = {
                        '@type': 'KEINE_VERWENDUNG'
                    }
                }

            }
            finanzen.vermoegen.summeDepotvermoegen = wertpapiere
        }
        // Bausparverträge
        let bausparen: BausparvertragAlsVermoegen[] = [];
        if (!!data.bausparguthabenVorhanden && data.bausparguthabenVorhanden === 'bausparguthabenVorhanden') {
            if (data.bausparvertraege && data.bausparvertraege.length > 0) {
                data.bausparvertraege.map((item: any) => {
                    let vertrag = {
                        angesparterBetrag: this.contentService.convertStringNumber(item.angesparterBetrag),
                        verwendung: {
                            '@type': item.verwendung,
                            maximalEinzusetzenderBetrag: !!item.maximalEinzusetzenderBetrag ? this.contentService.convertStringNumber(item.maximalEinzusetzenderBetrag) : 0,
                        }
                    };
                    bausparen.push(vertrag);
                })

            }
        }
        if (this.reduxData.darlehencounterForward && this.reduxData.darlehencounterForward > 0) {
            if (this.reduxData.darlehenForward && this.reduxData.darlehenForward.length > 0) {
                for(let i = 0; i < this.reduxData.darlehencounterForward; i++) {
                    if (this.reduxData.darlehenForward[i].darlehenType === 'BESTEHENDES_BAUSPARDARLEHEN') {
                        let vertrag = {
                            angesparterBetrag: !!this.reduxData.darlehenForward[i].sparenBetrag ? this.contentService.convertStringNumber(this.reduxData.darlehenForward[i].sparenBetrag) : 0,
                            zuteilungsdatum: !!this.reduxData.darlehenForward[i].abloesedate ? this.contentService.convertDate(this.reduxData.darlehenForward[i].abloesedate) : '',
                            bausparsumme: !!this.reduxData.darlehenForward[i].restschuldBetrag ? this.contentService.convertStringNumber(this.reduxData.darlehenForward[i].restschuldBetrag) : 0,
                            verwendung: {
                                '@type': 'AUFLOESUNG_ALS_VERWENDUNG',
                                maximalEinzusetzenderBetrag: !!this.reduxData.darlehenForward[i].sparenBetrag ? this.contentService.convertStringNumber(this.reduxData.darlehenForward[i].sparenBetrag) : 0,
                            }
                        };
                        bausparen.push(vertrag);
                    }
                }
            }
        }
        finanzen.vermoegen.bausparvertraege = bausparen;
        // Versicherungen
        if (!!data.versicherungVorhanden && data.versicherungVorhanden === 'versicherungVorhanden') {
            let versicherung: LebensOderRentenversicherung[] = [];
            if (data.versicherungsvertraege && data.versicherungsvertraege.length > 0) {
                data.versicherungsvertraege.map((item: any) => {
                    let vertrag = {
                        rueckkaufswert: this.contentService.convertStringNumber(item.rueckkaufswert),
                        verwendung: {
                            '@type': item.verwendung,
                            maximalEinzusetzenderBetrag: !!item.maximalEinzusetzenderBetrag ? this.contentService.convertStringNumber(item.maximalEinzusetzenderBetrag) : 0,
                        }
                    };
                    versicherung.push(vertrag);
                })

            }
            finanzen.vermoegen.lebensOderRentenversicherungen = versicherung
        }

        // FamilienSparvermögen
        if (!!data.familienSparvermoegen && data.familienSparvermoegen === 'familienSparvermoegenVorhanden') {
            let vermoegen: SonstigesVermoegen = {};
            if (data.familienvermoegenBetrag) {
                vermoegen.aktuellerWert = this.contentService.convertStringNumber(data.familienvermoegenBetrag);
                vermoegen.verwendung = { "@type": 'KEINE_VERWENDUNG', kommentar: 'Vermögen stammt aus der Familie' };
            }
            finanzen.vermoegen.summeSonstigesVermoegen = vermoegen
        }

        // FINANZEN: BESTEHENDE IMMOBILIEN
        if (!!data.immobilienVorhanden) {
            if (data.immobilienVorhanden === 'KEINE_BESTEHENDEN_IMMOBILIEN') {
                finanzen.bestehendeImmobilienErfassung = { '@type': 'KEINE_BESTEHENDEN_IMMOBILIEN' }
            } else {
                let immobilien: BestehendeImmobilie[] = [];
                if (data.bestehendeImmobilien && data.bestehendeImmobilien.length > 0) {
                    data.bestehendeImmobilien.map((item: any) => {
                        let einsatzData = !!item.change && item.change === 'VERKAUFEN' ? 'VERKAUFEN' : !!item.zusatzsicherheit && item.zusatzsicherheit === 'ja' ? 'ALS_ZUSATZSICHERHEIT' : 'KEIN_EINSATZ';
                        let immobilieData = {
                            adresse: item.adresse,
                            typ: {
                                '@type': item.objektArt,
                                gebaeude: {
                                    nutzung: {
                                        wohnen: {
                                            nutzungsart: {
                                                '@type': item.objektnutzung,
                                                mieteinnahmenNettoKaltMonatlich: item.kaltmiete ? this.contentService.convertStringNumber(item.kaltmiete) : 0,
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        let darlehen: any;
                        if (item.finanzierung !== 'VORHANDENE_BESTEHENDE_DARLEHEN') {
                            darlehen = {
                                '@type': item.finanzierung,
                            }
                        } else {
                            darlehen = {
                                '@type': item.finanzierung,
                                darlehensliste: [{
                                    '@type': 'BESTEHENDES_IMMOBILIENDARLEHEN',
                                    grundschuld: item.darlehen ? this.contentService.convertStringNumber(item.darlehen) : 0,
                                    darlehensbetrag: item.darlehen ? this.contentService.convertStringNumber(item.darlehen) : 0,
                                    rateMonatlich: item.rate ? this.contentService.convertStringNumber(item.rate) : 0,
                                    restschuld: item.restschuld ? { aktuell: this.contentService.convertStringNumber(item.restschuld) } : {}
                                }]
                            }

                        }

                        let immobilie = {
                            bezeichnung: item.name,
                            immobilie: immobilieData,
                            marktwert: this.contentService.convertStringNumber(item.marktwert),
                            einsatz: einsatzData,
                            maximalEinzusetzenderBetragBeiVerkauf: einsatzData === 'VERKAUFEN' ? this.contentService.convertStringNumber(item.marktwert) : undefined,
                            darlehenslisteErfassung: darlehen
                        }
                        immobilien.push(immobilie);
                    });
                    finanzen.bestehendeImmobilienErfassung = {
                        '@type': 'VORHANDENE_BESTEHENDE_IMMOBILIEN',
                        bestehendeImmobilien: immobilien
                    }
                }
            }
        }
        return finanzen;
    }

    mapPeople(person: Kunde, data: any) {

        // PERSÖNLICHE ANGABEN
        if (data.haushaltPerson) { person.personendaten.person.anrede = data.haushaltPerson === 'FRAU' ? 'FRAU' : 'HERR'; }
        if (data.haushaltPerson === 'DIVERS') {
            person.kontakt.weitereKontaktmoeglichkeiten = 'Kunde möchte neutral angesprochen werden.'
        }
        if (data.haushaltPersonFirstName) { person.personendaten.person.vorname = data.haushaltPersonFirstName; }
        if (data.haushaltPersonLastName) { person.personendaten.person.nachname = data.haushaltPersonLastName; }
        if (data.haushaltPersonEmail) { person.kontakt.email = data.haushaltPersonEmail; }
        if (data.haushaltPersonVorwahl && data.haushaltPersonTelefon) { person.kontakt.telefonnummer = { vorwahl: data.haushaltPersonVorwahl, nummer: data.haushaltPersonTelefon }; }
        if (data.haushaltPersonTitleProf || data.haushaltPersonTitleDr) { person.personendaten.person.titel = { prof: data.haushaltPersonTitleProf, dr: data.haushaltPersonTitleDr }; }
        if (data.geburtsdatum) { person.personendaten.geburtsdatum = this.contentService.convertDate(data.geburtsdatum); }
        if (data.familienstand) { person.personendaten.familienstand = { '@type': data.familienstand }; }
        if (data.wohnsituationPlz && data.wohnsituationOrt) { person.wohnsituation.anschrift = { plz: data.wohnsituationPlz, ort: data.wohnsituationOrt, strasse: data.haushaltPersonStrasse, hausnummer: data.haushaltPersonStrasseNr }; }
        if (data.staatsangehoerigkeit) { person.personendaten.staatsangehoerigkeit = data.staatsangehoerigkeit; }

        // NICHT-EU BUERGER
        if (this.contentService.euCountries.indexOf(data.staatsangehoerigkeit) < 0) {
            let aufenthaltstitel: Aufenthaltstitel = {};
            let arbeitserlaubnis: Arbeitserlaubnis = data.arbeitserlaubnis === 'ARBEITSERLAUBNIS_VORHANDEN' ? {
                '@type': data.arbeitserlaubnis,
                befristetBis: this.contentService.convertDate(data.arbeitserlaubnisBefristetBis)
            } : {
                '@type': data.arbeitserlaubnis,
            }
            aufenthaltstitel['@type'] = data.aufenthaltstitel;

            if (data.aufenthaltstitel === 'VISUM' || data.aufenthaltstitel === 'AUFENTHALTSERLAUBNIS') {
                aufenthaltstitel = {
                    '@type': data.aufenthaltstitel,
                    befristetBis: this.contentService.convertDate(data.visumBefristetBis),
                    arbeitserlaubnis: arbeitserlaubnis
                }
            }

            if (data.aufenthaltstitel === 'BLAUEKARTE_EU') {
                aufenthaltstitel = {
                    '@type': data.aufenthaltstitel,
                    befristetBis: this.contentService.convertDate(data.visumBefristetBis)
                }
            }

            let aufenthalt: Aufenthalt = {
                inDeutschlandSeit: this.contentService.convertDate(data.inDeutschlandSeit),
                aufenthaltstitel: aufenthaltstitel
            };
            person.personendaten.nichtEuBuerger = aufenthalt;
        }
        // BESCHAEFTIGUNG
        if (data.Beschaeftigung !== 'SCHUELER') {
            person.finanzielles.beschaeftigung = {
                '@type': data.Beschaeftigung
            }
            // ARBEITSLOSER
            if (data.Beschaeftigung === 'ARBEITSLOSER') {
                person.finanzielles.beschaeftigung = {
                    ...
                    person.finanzielles.beschaeftigung,
                    beruf: data.beruf,
                }
            }
            // HAUSHALTENDE_PERSON
            if (data.Beschaeftigung === 'HAUSHALTENDE_PERSON') {
                person.finanzielles.beschaeftigung = {
                    ...
                    person.finanzielles.beschaeftigung,
                    beruf: data.beruf,
                }
            }
            // ANGESTELLTER
            if (data.Beschaeftigung === 'ANGESTELLTER') {
                person.finanzielles.einkommenNetto = this.contentService.convertStringNumber(data.gehalt);
                person.finanzielles.beschaeftigung = {
                    ...
                    person.finanzielles.beschaeftigung,
                    beruf: data.beruf,
                    beschaeftigungsverhaeltnis: {
                        arbeitgeber: {
                            inDeutschland: true
                        },
                        probezeit: data.probezeit === 'true' ? true : false,
                        anzahlGehaelterProJahr: data.anzahlGehaelterProJahr,
                        beschaeftigtSeit: this.contentService.convertDate(data.beschaeftigtSeit),
                    },
                    beschaeftigungsstatus: data.beschaeftigungsstatus
                }
            }
            // BEAMTER
            if (data.Beschaeftigung === 'BEAMTER') {
                person.finanzielles.einkommenNetto = this.contentService.convertStringNumber(data.gehalt);
                person.finanzielles.beschaeftigung = {
                    ...
                    person.finanzielles.beschaeftigung,
                    beruf: data.beruf,
                    beschaeftigungsverhaeltnis: {
                        arbeitgeber: {
                            inDeutschland: true
                        },
                        probezeit: data.probezeit === 'true' ? true : false,
                        anzahlGehaelterProJahr: data.anzahlGehaelterProJahr,
                        beschaeftigtSeit: this.contentService.convertDate(data.beschaeftigtSeit),
                    }
                }
            }
            if ((data.Beschaeftigung === 'BEAMTER' || data.Beschaeftigung === 'ANGESTELLTER') && data.elternzeit === 'ElternzeitBesteht') {
                person.finanzielles.einkommenNetto = this.contentService.convertStringNumber(data.elterngeld);
            }

            // ARBEITER
            if (data.Beschaeftigung === 'ARBEITER') {
                person.finanzielles.einkommenNetto = this.contentService.convertStringNumber(data.gehalt);
                person.finanzielles.beschaeftigung = {
                    ...
                    person.finanzielles.beschaeftigung,
                    beruf: data.beruf,
                    beschaeftigungsverhaeltnis: {
                        arbeitgeber: {
                            inDeutschland: true
                        },
                        probezeit: data.probezeit === 'true' ? true : false,
                        anzahlGehaelterProJahr: data.anzahlGehaelterProJahr,
                        beschaeftigtSeit: this.contentService.convertDate(data.beschaeftigtSeit),
                    },
                    beschaeftigungsstatus: data.beschaeftigungsstatus
                }
            }
            // AUSZUBILDENDER
            if (data.Beschaeftigung === 'AUSZUBILDENDER') {
                person.finanzielles.einkommenNetto = this.contentService.convertStringNumber(data.gehalt);
                person.finanzielles.beschaeftigung = {
                    '@type': 'ARBEITER',
                    beruf: data.beruf,
                    beschaeftigungsverhaeltnis: {
                        arbeitgeber: {
                            inDeutschland: true
                        },
                        probezeit: data.probezeit === 'true' ? true : false,
                        anzahlGehaelterProJahr: data.anzahlGehaelterProJahr,
                        beschaeftigtSeit: this.contentService.convertDate(data.beschaeftigtSeit),
                    },
                    beschaeftigungsstatus: data.beschaeftigungsstatus
                }
            }
            // SELBSTSTAENDIGER / FREIBERUFLER
            if (data.Beschaeftigung === 'SELBSTSTAENDIGER' || data.Beschaeftigung === 'FREIBERUFLER') {
                const gewinn1 = this.contentService.convertStringNumber(data.gewinnAktuellesJahr);
                const gewinn2 = this.contentService.convertStringNumber(data.gewinnLetztesJahr);
                const gewinn3 = this.contentService.convertStringNumber(data.gewinnVorletztesJahr);
                const einkommen = (gewinn1 + gewinn2 + gewinn3) / 3;
                person.finanzielles.einkommenNetto = einkommen;
                if (data.Beschaeftigung === 'SELBSTSTAENDIGER') {
                    person.finanzielles.beschaeftigung = {
                        ...
                        person.finanzielles.beschaeftigung,
                        beruf: data.SelbststaendigeTaetigkeit,
                        taetigkeit: {
                            taetigSeit: this.contentService.convertDate(data.taetigSeit)
                        }
                    }
                }
                if (data.Beschaeftigung === 'FREIBERUFLER') {
                    person.finanzielles.beschaeftigung = {
                        ...
                        person.finanzielles.beschaeftigung,
                        beruf: data.SelbststaendigeTaetigkeit,
                        taetigkeit: {
                            taetigSeit: this.contentService.convertDate(data.taetigSeit)
                        }
                    }
                }
            }
            // RENTNER
            if (data.Beschaeftigung === 'RENTNER') {
                if (data.typRente === 'Altersrente') {
                    person.finanzielles.einkommenNetto = this.contentService.convertStringNumber(data.monatlicheRente);
                }
            }
        } else {
            person.finanzielles.beschaeftigung = {
                '@type': 'HAUSHALTENDE_PERSON'
            }
        }

        if (data.Beschaeftigung === 'SCHUELER' || data.Beschaeftigung === 'ARBEITSLOSER' || data.Beschaeftigung === 'HAUSHALTENDE_PERSON') {
            person.finanzielles.einkommenNetto = 0;
        }

        return person
    }

    mapFinanzierungsBedarf(data: Finanzierungsbedarf) {
        data.finanzierungszweck['@type'] = this.reduxData.projectPlan !== 'MIXED' ? this.reduxData.projectPlan : 'ANSCHLUSSFINANZIERUNG';
        let eigenleistung;
        let eigenleistungValue = !!this.reduxData.eigenleistungValue ? this.contentService.convertStringNumber(this.reduxData.eigenleistungValue) : 0;
        if (!!this.reduxData.eigenleistung) {
            if (this.reduxData.eigenleistung === 'VORHANDENE_EIGENLEISTUNG') {
                eigenleistung = {
                    '@type': 'VORHANDENE_EIGENLEISTUNG',
                    eigenleistung: eigenleistungValue
                }
            } else {
                eigenleistung = {
                    '@type': this.reduxData.eigenleistung
                }
            }
        }
        if (eigenleistung) {
            data.finanzierungszweck.eigenleistungErfassung = eigenleistung;
        }

        let modernisierung;
        let renovierungskosten = !!this.reduxData.sanierungBetrag ? this.contentService.convertStringNumber(this.reduxData.sanierungBetrag) : 0;
        let modernisierungkosten = !!this.reduxData.modernisierungkosten ? this.contentService.convertStringNumber(this.reduxData.modernisierungkosten) : 0;
        let abzuloesendeDarlehen: AbloesungBestehendesdarlehen[] = [];

        if (this.reduxData.darlehencounterForward && this.reduxData.darlehencounterForward > 0) {
            if (this.reduxData.darlehenForward) {
                for(let i = 0; i < this.reduxData.darlehencounterForward; i++) {
                    if (this.reduxData.darlehenForward[i].abloesen === 'abloesenJa') {
                        let id = `darlehen-${this.reduxData.darlehenForward[i].id}`;
                        let tilgung = !!this.reduxData.darlehenForward[i].tilgungBetrag ? this.contentService.convertStringNumber(this.reduxData.darlehenForward[i].tilgungBetrag) : 0;
                        abzuloesendeDarlehen.push({
                            referenzIdAbzuloesendesDarlehen: id,
                            sondertilgungZumZinsbindungsende: tilgung
                        });
                    }
                }
            }
        }

        if (modernisierungkosten > 0 || renovierungskosten > 0) {

            modernisierung = {
                '@type': 'VORHANDENE_MODERNISIERUNGSKOSTEN',
            }
            if (modernisierungkosten > 0) {
                modernisierung = { ...modernisierung, modernisierungskostenInklEigenleistungen: modernisierungkosten + eigenleistungValue }
            }

            if (renovierungskosten > 0) {
                modernisierung = { ...modernisierung, renovierungskosten: renovierungskosten }
            }

            if (eigenleistung) {
                modernisierung = { ...modernisierung, eigenleistungErfassung: eigenleistung }
            }
        } else {
            modernisierung = {
                '@type': 'KEINE_MODERNISIERUNGSKOSTEN',
            }
        }

        // ANSCHLUSSFINANZIERUNG, MODERNISIERUNG_UMBAU_ANBAU, KAPITALBESCHAFFUNG
        if (data.finanzierungszweck['@type'] === 'ANSCHLUSSFINANZIERUNG' || data.finanzierungszweck['@type'] === 'MODERNISIERUNG_UMBAU_ANBAU' || data.finanzierungszweck['@type'] === 'KAPITALBESCHAFFUNG') {
            let kapital;
            if (!!this.reduxData.kapitalbeschaffung && this.reduxData.kapitalbeschaffung === 'true' && this.contentService.convertStringNumber(this.reduxData.kapitalbeschaffungBetrag) > 0) {
                const betrag = {
                    betrag: this.contentService.convertStringNumber(this.reduxData.kapitalbeschaffungBetrag)
                }
                kapital = {
                    '@type': 'VORHANDENES_ZU_BESCHAFFENDES_KAPITAL',
                    zuBeschaffendesKapital: betrag
                }
            } else {
                kapital = {
                    '@type': 'KEIN_ZU_BESCHAFFENDES_KAPITAL',
                }
            }
            data.finanzierungszweck.zuBeschaffendesKapitalErfassung = kapital;

            if (!!this.reduxData.objectMarkwert) {
                data.finanzierungszweck.marktwertFinanzierungsobjekt = this.contentService.convertStringNumber(this.reduxData.objectMarkwert);
            }
            // ANSCHLUSSFINANZIERUNG, MODERNISIERUNG_UMBAU_ANBAU
            // if (data.finanzierungszweck['@type'] !== 'KAPITALBESCHAFFUNG') {



            // ANSCHLUSSFINANZIERUNG - abzuloesende vorhandene Restschuld
            if (abzuloesendeDarlehen && abzuloesendeDarlehen.length > 0) {
                data.finanzierungszweck.abloesungDarlehenDesFinanzierungsobjektes = abzuloesendeDarlehen;
            }
            // MODERNISIERUNG_UMBAU_ANBAU - weitere kosten
            if (data.finanzierungszweck['@type'] === 'MODERNISIERUNG_UMBAU_ANBAU') {
                data.finanzierungszweck.modernisierung = modernisierung;

                if (!!this.reduxData.mobiliarkosten || !!this.reduxData.sonstigeskosten) {
                    let weiteres = {};

                    if (!!this.reduxData.mobiliarkosten) {
                        weiteres = {
                            ...weiteres,
                            mobiliarkosten: this.contentService.convertStringNumber(this.reduxData.mobiliarkosten)
                        }
                    }

                    if (!!this.reduxData.sonstigeskosten) {
                        weiteres = {
                            ...weiteres,
                            sonstigeKosten: { betrag: this.contentService.convertStringNumber(this.reduxData.sonstigeskosten) }
                        }
                    }
                    data.finanzierungszweck.weitereKosten = weiteres;
                }
            } else {
                data.finanzierungszweck.modernisierungskostenErfassung = modernisierung;
            }
            // }
        }

        // NEUBAU, KAUF, KAUF_NEUBAU_VOM_BAUTRAEGER
        if (data.finanzierungszweck['@type'] === 'NEUBAU' || data.finanzierungszweck['@type'] === 'KAUF' || data.finanzierungszweck['@type'] === 'KAUF_NEUBAU_VOM_BAUTRAEGER') {
            if (!!this.reduxData.mobiliarkosten || !!this.reduxData.sonstigeskosten || !!this.reduxData.beglicheneskosten) {
                let weiteres = {};

                if (!!this.reduxData.mobiliarkosten) {
                    weiteres = {
                        ...weiteres,
                        mobiliarkosten: this.contentService.convertStringNumber(this.reduxData.mobiliarkosten)
                    }
                }

                if (!!this.reduxData.sonstigeskosten) {
                    weiteres = {
                        ...weiteres,
                        sonstigeKosten: { betrag: this.contentService.convertStringNumber(this.reduxData.sonstigeskosten) }
                    }
                }
                if (!!this.reduxData.beglicheneskosten) {
                    weiteres = {
                        ...weiteres,
                        beglicheneKosten: this.contentService.convertStringNumber(this.reduxData.beglicheneskosten)
                    }
                }
                data.finanzierungszweck.weitereKosten = weiteres;
            }
            if (!!this.reduxData.steuerprozent || !!this.reduxData.notarprozent || !!this.reduxData.maklerprozent) {
                let nebenkosten = {};
                // steuer
                if (!!this.reduxData.steuerprozent) {
                    nebenkosten = {
                        ...nebenkosten,
                        grunderwerbsteuer: {
                            wert: this.contentService.convertStringNumber(this.reduxData.steuerprozent, 2),
                            einheit: 'PROZENT'
                        }
                    }
                } else if (!!this.reduxData.steuerbetrag) {
                    nebenkosten = {
                        ...nebenkosten,
                        grunderwerbsteuer: {
                            wert: this.contentService.convertStringNumber(this.reduxData.steuerbetrag, 2),
                            einheit: 'EURO'
                        }
                    }
                }
                // notar
                if (!!this.reduxData.notarprozent) {
                    nebenkosten = {
                        ...nebenkosten,
                        notargebuehr: {
                            wert: this.contentService.convertStringNumber(this.reduxData.notarprozent, 2),
                            einheit: 'PROZENT'
                        }
                    }
                } else if (!!this.reduxData.notarbetrag) {
                    nebenkosten = {
                        ...nebenkosten,
                        notargebuehr: {
                            wert: this.contentService.convertStringNumber(this.reduxData.notarbetrag, 2),
                            einheit: 'EURO'
                        }
                    }
                }
                // makler
                if (!!this.reduxData.maklerprozent) {
                    nebenkosten = {
                        ...nebenkosten,
                        maklergebuehr: {
                            wert: this.contentService.convertStringNumber(this.reduxData.maklerprozent, 2),
                            einheit: 'PROZENT'
                        }
                    }
                } else if (!!this.reduxData.maklerbetrag) {
                    nebenkosten = {
                        ...nebenkosten,
                        maklergebuehr: {
                            wert: this.contentService.convertStringNumber(this.reduxData.maklerbetrag, 2),
                            einheit: 'EURO'
                        }
                    }
                }
                data.finanzierungszweck.nebenkosten = nebenkosten;
            }
            if (data.finanzierungszweck['@type'] === 'KAUF' || data.finanzierungszweck['@type'] === 'KAUF_NEUBAU_VOM_BAUTRAEGER') {
                if (!!this.reduxData.kaufpreis) {
                    data.finanzierungszweck.kaufpreis = this.contentService.convertStringNumber(this.reduxData.kaufpreis);
                }
            }
            if (data.finanzierungszweck['@type'] === 'KAUF') {
                if (modernisierung) {
                    data.finanzierungszweck.modernisierungskostenErfassung = modernisierung;
                }
            }
            if (data.finanzierungszweck['@type'] === 'NEUBAU') {
                let grundstueck = {};
                let grundstueckBereitsBezahlt = false;
                let grundstueckkaufpreis = 0;
                let erschliessungskosten = 0;
                if (!!this.reduxData.herstellungskosten) {
                    if (!!this.reduxData.eigenleistung && this.reduxData.eigenleistung === 'VORHANDENE_EIGENLEISTUNG') {
                        let eigenleistungTage = this.reduxData.eigenleistungTage;
                        eigenleistungTage = eigenleistungTage * 400;
                        data.finanzierungszweck.herstellungskostenInklusiveEigenleistungen = this.contentService.convertStringNumber(this.reduxData.herstellungskosten) + eigenleistungTage;
                    } else {
                        data.finanzierungszweck.herstellungskostenInklusiveEigenleistungen = this.contentService.convertStringNumber(this.reduxData.herstellungskosten);
                    }
                }
                if (!!this.reduxData.aussenanlagenkosten) {
                    data.finanzierungszweck.aussenanlagen = this.contentService.convertStringNumber(this.reduxData.aussenanlagenkosten);
                }
                if (!!this.reduxData.baunebenkosten) {
                    data.finanzierungszweck.baunebenkosten = this.contentService.convertStringNumber(this.reduxData.baunebenkosten);
                }
                if (!!this.reduxData.useOwnLand && this.reduxData.useOwnLand === 'ja') {
                    if (this.reduxData.bestehendeImmobilien) {
                        let grundstuecke = 0;
                        this.reduxData.bestehendeImmobilien.map((immobilie: any) => {
                            if (immobilie.objektArt === 'BAUGRUNDSTUECK' && immobilie.useForProject === 'ja') {
                                grundstuecke++
                                grundstueckkaufpreis = this.contentService.convertStringNumber(immobilie.marktwert)
                            }
                        });
                        if (grundstuecke > 0) {
                            grundstueckBereitsBezahlt = true
                        } else {
                            grundstueckBereitsBezahlt = false
                        }
                    }

                } else {
                    grundstueckBereitsBezahlt = false;
                    if (!!this.reduxData.grundstueckspreis) {
                        grundstueckkaufpreis = this.contentService.convertStringNumber(this.reduxData.grundstueckspreis);
                    }
                }

                if (!!this.reduxData.erschliessungkosten) {
                    erschliessungskosten = this.contentService.convertStringNumber(this.reduxData.erschliessungkosten);
                }

                grundstueck = {
                    ...grundstueck,
                    grundstueckkaufpreis: grundstueckkaufpreis,
                    grundstueckBereitsBezahlt: grundstueckBereitsBezahlt,
                    erschliessungskosten: erschliessungskosten
                }
                data.finanzierungszweck.grundstueckskosten = grundstueck;
            }
        }
        // FINANZIERUNGS BAUSTEINE
        data.finanzierungsbausteine = this.mapFinanzierungsbausteine(data.finanzierungsbausteine)

        return data
    }

    mapFinanzierungsbausteine(data: Finanzierungsbaustein[]) {
        let bausteine: Finanzierungsbaustein[] = [];

        if (this.reduxData.darlehenBausteine && this.reduxData.darlehenBausteine.length > 0) {
            this.reduxData.darlehenBausteine.map((item: any) => {
                let tilgungswunsch = {};
                let baustein: Finanzierungsbaustein = {
                    '@type': item.type,
                    darlehensbetrag: !!item.darlehensbetrag ? this.contentService.convertStringNumber(item.darlehensbetrag) : 0,
                };
                if (this.checkBausteineData(item.bereitstellungszinsfreieZeitInMonaten, item.bereitstellungszinsfreieZeitInMonatenVariable)) {
                    baustein = {
                        ...baustein,
                        bereitstellungszinsfreieZeitInMonaten: this.contentService.convertStringNumber(this.checkBausteineData(item.bereitstellungszinsfreieZeitInMonaten, item.bereitstellungszinsfreieZeitInMonatenVariable))
                    }
                }
                if (this.checkBausteineData(item.laufzeitInMonaten, item.laufzeitInMonatenVariable)) {
                    if (item.type === 'PRIVATDARLEHEN') {
                        baustein = {
                            ...baustein,
                            laufzeitInMonaten: this.contentService.convertStringNumber(this.checkBausteineData(item.laufzeitInMonaten, item.laufzeitInMonatenVariable))
                        }
                    }
                    if (item.type === 'ZWISCHENFINANZIERUNG') {
                        baustein = {
                            ...baustein,
                            maximaleLaufzeitInMonaten: this.contentService.convertStringNumber(this.checkBausteineData(item.laufzeitInMonaten, item.laufzeitInMonatenVariable))
                        }
                    }

                }

                if (!!item.tilgungsWunsch) {
                    tilgungswunsch = { '@type': item.tilgungsWunsch }

                    if (item.tilgungsWunsch === 'RATE') {
                        tilgungswunsch = {
                            ...tilgungswunsch,
                            rate: !!item.rate ? this.contentService.convertStringNumber(item.rate) : 0
                        }
                    } else if (item.tilgungsWunsch === 'TILGUNG_IN_PROZENT') {
                        tilgungswunsch = {
                            ...tilgungswunsch,
                            tilgungssatzInProzent: !!item.tilgungssatzInProzent ? this.contentService.convertStringNumber(item.tilgungssatzInProzent, 2) : 0
                        }
                    }
                }

                if (!!item.verwendung && item.type === 'ZWISCHENFINANZIERUNG') {
                    baustein = {
                        ...baustein,
                        verwendungszweck: item.verwendung
                    }
                }

                if (item.type === 'ANNUITAETENDARLEHEN' || item.type === 'FORWARDDARLEHEN') {
                    let details = {};
                    if (this.checkBausteineData(item.zinsbindungInJahren, item.zinsbindungInJahrenVariable)) {
                        details = {
                            ...details,
                            zinsbindungInJahren: this.contentService.convertStringNumber(this.checkBausteineData(item.zinsbindungInJahren, item.zinsbindungInJahrenVariable))
                        }
                    }
                    if (this.checkBausteineData(item.sondertilgungJaehrlich, item.sondertilgungJaehrlichVariable)) {
                        details = {
                            ...details,
                            sondertilgungJaehrlich: this.contentService.convertStringNumber(this.checkBausteineData(item.sondertilgungJaehrlich, item.sondertilgungJaehrlichVariable), 2)
                        }
                    }
                    if (!!item.auszahlungszeitpunkt) {
                        details = {
                            ...details,
                            auszahlungszeitpunkt: this.contentService.convertDate(item.auszahlungszeitpunkt)
                        }
                    }

                    if (tilgungswunsch) {
                        details = {
                            ...details,
                            tilgungswunsch: tilgungswunsch
                        }
                    }

                    baustein = {
                        ...baustein,
                        annuitaetendetails: details
                    }
                }

                if (item.type === 'KFW_DARLEHEN') {
                    let programm: KfwProgramm = { '@type': 'KFW_PROGRAMM_124' };

                    if (this.checkBausteineData(item.laufzeitInJahren, item.laufzeitInJahrenVariable)) {
                        programm = {
                            ...programm,
                            laufzeitInJahren: this.contentService.convertStringNumber(this.checkBausteineData(item.laufzeitInJahren, item.laufzeitInJahrenVariable))
                        }
                    }
                    if (item.zinsbindungInJahren) {
                        programm = {
                            ...programm,
                            zinsbindungInJahren: this.contentService.convertStringNumber(item.zinsbindungInJahren)
                        }
                    }
                    if (item.tilgungsfreieAnlaufjahre) {
                        programm = {
                            ...programm,
                            tilgungsfreieAnlaufjahre: this.contentService.convertStringNumber(item.tilgungsfreieAnlaufjahre)
                        }
                    }
                    baustein = {
                        ...baustein,
                        programm: programm
                    }
                }
                bausteine.push(baustein)
            });
        }
        return bausteine
    }

    checkBausteineData(item1: any, item2: any) {
        let data;
        if (item1 === 0 && (!item2 || item2 === 0)) {
            data = false
        } else {
            if (item1 !== 0 && item1 !== 'other') {
                data = item1
            } else if (item1 === 'other') {
                data = item2
            }
        }
        return data
    }

    mapFinanzierungsObjekt(data: Finanzierungsobjekt) {
        data.immobilie.adresse = this.reduxData.objectAddress;

        let parking; let erbrecht;
        // STELLPLAETZE
        if (!!this.reduxData.objectParking) {
            if (this.reduxData.objectParking === 'true') {
                parking = {
                    '@type': 'VORHANDENE_STELLPLAETZE',
                    stellplaetze: this.reduxData.objectStellplaetze
                }
            } else {
                parking = {
                    '@type': 'KEINE_STELLPLAETZE',
                }
            }
            data.immobilie.stellplaetzeErfassung = parking;
        }
        // ERBBAURECHT
        if (!!this.reduxData.objectErbbaurecht) {
            if (this.reduxData.objectErbbaurecht === 'VORHANDENES_ERBBAURECHT') {
                erbrecht = {
                    '@type': this.reduxData.objectErbbaurecht,
                    erbbauzinsJaehrlich: this.contentService.convertStringNumber(this.reduxData.objectErbbauzinsJaehrlich),
                    grundstueckseigentuemer: this.reduxData.objectErbbauGrundstueckseigentuemer,
                    laufzeitBis: parseInt(this.reduxData.objectErbbauLaufzeitBis)
                }
            } else {
                erbrecht = {
                    '@type': this.reduxData.objectErbbaurecht,
                }
            }
            data.immobilie.erbbaurechtErfassung = erbrecht;
        }
        // ANGABEN ZUM OBJEKT / WELCHE ART OBJEKT
        // HAUS
        if (this.reduxData.objectType === "Haus") {
            data.immobilie.typ['@type'] = this.reduxData.houseType;
            data.immobilie.typ.grundstuecksgroesse = this.contentService.convertStringNumber(this.reduxData.objectGrundstuecksgroesse);

            const nutzflaechen: Nutzflaechen = {
                dachgeschoss: this.reduxData.objectDachgeschoss,
                unterkellerung: this.reduxData.objectUnterkellerung,
            }
            data.immobilie.typ.nutzflaechen = nutzflaechen;
            if (this.reduxData.houseType === 'DOPPELHAUSHAELFTE' || this.reduxData.houseType === 'REIHENHAUS' || this.reduxData.houseType === 'EINFAMILIENHAUS') {
                data.immobilie.typ.einliegerwohnungVorhanden = this.reduxData.objectEinliegerwohnungVorhanden === 'true' ? true : false;
            }
            if (this.reduxData.houseType === 'DOPPELHAUSHAELFTE' || this.reduxData.houseType === 'ZWEIFAMILIENHAUS' || this.reduxData.houseType === 'EINFAMILIENHAUS') {
                data.immobilie.typ.fertighaus = this.reduxData.objectFertighaus === 'true' ? true : false;
            }
            if (this.reduxData.houseType === 'REIHENHAUS' || this.reduxData.houseType === 'MEHRFAMILIENHAUS' || this.reduxData.houseType === 'ZWEIFAMILIENHAUS') {
                data.immobilie.typ.haustyp = this.reduxData.houseCategory;
            }
            if (this.reduxData.houseType === 'MEHRFAMILIENHAUS') {
                data.immobilie.typ.anzahlDerGewerbeeinheiten = this.reduxData.objectAnzahlDerGewerbeeinheiten ? this.reduxData.objectAnzahlDerGewerbeeinheiten : 0;
                data.immobilie.typ.anzahlDerWohneinheiten = this.reduxData.objectAnzahlDerWohneinheiten ? this.reduxData.objectAnzahlDerWohneinheiten : 0;
            }
        } else if (this.reduxData.objectType !== "Gewerbe") {
            data.immobilie.typ['@type'] = this.reduxData.objectType;
        }
        // EIGENTUMSWOHNUNG / Gewerbe
        if (this.reduxData.objectType === "EIGENTUMSWOHNUNG" || this.reduxData.objectType === "Gewerbe") {
            data.immobilie.typ.anzahlDerGewerbeeinheitenImObjekt = this.reduxData.objectAnzahlDerGewerbeeinheiten ? this.reduxData.objectAnzahlDerGewerbeeinheiten : 0;
            data.immobilie.typ.anzahlDerWohneinheitenImObjekt = this.reduxData.objectAnzahlDerWohneinheiten ? this.reduxData.objectAnzahlDerWohneinheiten : 0;
        }
        // BAUGRUNDSTUECK
        if (this.reduxData.objectType === "BAUGRUNDSTUECK") {
            data.immobilie.typ.grundstuecksart = this.reduxData.objectTypeGrundstueck;
            data.immobilie.typ.grundstuecksgroesse = this.contentService.convertStringNumber(this.reduxData.objectGesamtflaeche);

            // ALLE AUSSER BAUGRUNDSTUECK
        } else {
            let nutzung: Nutzung = {};
            // MEHRFAMILIENHAUS
            if (this.reduxData.objectType === "Haus" && this.reduxData.houseType === 'MEHRFAMILIENHAUS') {
                let wohnen = {}; let gewerbe = {};
                if (this.reduxData.objectAnzahlDerWohneinheiten && this.reduxData.objectAnzahlDerWohneinheiten > 0) {
                    wohnen = {
                        gesamtflaeche: this.reduxData.objectGesamtflaecheWohnen ? this.contentService.convertStringNumber(this.reduxData.objectGesamtflaecheWohnen) : 0,
                        nutzungsart: {
                            '@type': this.reduxData.objectNutzungwohnflaeche,
                            mieteinnahmenNettoKaltMonatlich: !!this.reduxData.objectMieteinnahmenwohnflaeche ? this.contentService.convertStringNumber(this.reduxData.objectMieteinnahmenwohnflaeche) : 0,
                            vermieteteFlaeche: !!this.reduxData.objectVermietetewohnflaeche ? this.contentService.convertStringNumber(this.reduxData.objectVermietetewohnflaeche) : 0,
                        }
                    }

                    nutzung = {
                        ...nutzung,
                        wohnen: wohnen
                    }
                };
                if (this.reduxData.objectAnzahlDerGewerbeeinheiten && this.reduxData.objectAnzahlDerGewerbeeinheiten > 0) {
                    gewerbe = {
                        gesamtflaeche: this.reduxData.objectGesamtflaecheGewerbe ? this.contentService.convertStringNumber(this.reduxData.objectGesamtflaecheGewerbe) : 0,
                        nutzungsart: {
                            '@type': this.reduxData.objectNutzunggewerbeflaeche,
                            mieteinnahmenNettoKaltMonatlich: !!this.reduxData.objectMieteinnahmengewerbeflaeche ? this.contentService.convertStringNumber(this.reduxData.objectMieteinnahmengewerbeflaeche) : 0,
                            vermieteteFlaeche: !!this.reduxData.objectVermietetegewerbeflaeche ? this.contentService.convertStringNumber(this.reduxData.objectVermietetegewerbeflaeche) : 0,
                        }
                    }
                    nutzung = {
                        ...nutzung,
                        gewerbeErfassung: {
                            '@type': 'VORHANDENES_GEWERBE',
                            gewerbe: gewerbe
                        }
                    }
                } else {
                    nutzung = {
                        ...nutzung,
                        gewerbeErfassung: {
                            '@type': 'KEIN_GEWERBE'
                        }
                    }
                }

                // KEIN MEHRFAMILIENHAUS
            } else {
                const unitType: any = !!this.reduxData.objectUnitType && this.reduxData.objectUnitType === 'Gewerbeflaeche' ? 'Gewerbeflaeche' : 'Wohnflaeche';
                nutzung = unitType === 'Wohnflaeche' ? {
                    wohnen: {
                        gesamtflaeche: this.contentService.convertStringNumber(this.reduxData.objectGesamtflaeche),
                        nutzungsart: {
                            '@type': this.reduxData.objectNutzungwohnflaeche,
                            mieteinnahmenNettoKaltMonatlich: !!this.reduxData.objectMieteinnahmenwohnflaeche ? this.contentService.convertStringNumber(this.reduxData.objectMieteinnahmenwohnflaeche) : 0,
                            vermieteteFlaeche: !!this.reduxData.objectVermietetewohnflaeche ? this.contentService.convertStringNumber(this.reduxData.objectVermietetewohnflaeche) : 0,
                        }
                    },
                    gewerbeErfassung: {
                        '@type': 'KEIN_GEWERBE'
                    }
                } : {
                    gewerbeErfassung: {
                        '@type': 'VORHANDENES_GEWERBE',
                        gewerbe: {
                            gesamtflaeche: !!this.reduxData.objectGesamtflaeche ? this.contentService.convertStringNumber(this.reduxData.objectGesamtflaeche) : 0,
                            nutzungsart: {
                                '@type': 'TEILVERMIETET',
                                mieteinnahmenNettoKaltMonatlich: this.reduxData.objectMieteinnahmenwohnflaeche ? this.contentService.convertStringNumber(this.reduxData.objectMieteinnahmenwohnflaeche) : 0,
                                vermieteteFlaeche: this.reduxData.objectVermietetewohnflaeche ? this.contentService.convertStringNumber(this.reduxData.objectVermietetewohnflaeche) : 0,
                            }
                        }
                    }
                };
            }

            let gebaeude: Gebaeude = {
                baujahr: this.reduxData.objectBaujahr,
                bauweise: this.reduxData.objectBauweise,
                anzahlGeschosse: this.reduxData.objectAnzahlGeschosse,
                zustand: this.reduxData.objectZustand,
                ausstattung: this.reduxData.objectAusstattung,
                nutzung: nutzung
            }
            // MODERNISIERUNG
            if (!!this.reduxData.objectModernisierungErfassung) {
                if (this.reduxData.objectModernisierungErfassung !== 'VORHANDENE_MODERNISIERUNG') {
                    gebaeude = { ...gebaeude, modernisierungErfassung: { '@type': 'KEINE_MODERNISIERUNG' } };
                } else {
                    gebaeude = {
                        ...gebaeude,
                        modernisierungErfassung: {
                            '@type': 'VORHANDENE_MODERNISIERUNG',
                            jahr: parseInt(this.reduxData.objectModernisierungJahr),
                            grad: this.reduxData.objectModernisierungGrad
                        }
                    }
                }
            }
            // ENERGIEAUSWEIS
            if (!!this.reduxData.objectEnergyKnown && this.reduxData.objectEnergyKnown === 'true') {
                let energie: EnergieEffizienzAusweis = {
                    ausweistyp: this.reduxData.objectEnergieEffizienzAusweistyp,
                }
                if (this.reduxData.objectEnergyUseKnown === 'true') {
                    energie = {
                        ...energie,
                        endEnergie: this.contentService.convertStringNumber(this.reduxData.objectEndEnergie)
                    }
                } else if (this.reduxData.objectEnergyClassKnown === 'true') {
                    energie = {
                        ...energie,
                        energieEffizienzklasse: this.reduxData.objectEnergieEffizienzklasse
                    }
                }
                gebaeude = {
                    ...gebaeude,
                    energieEffizienzAusweis: energie
                }
            }
            data.immobilie.typ.gebaeude = gebaeude;
        }

        if (this.reduxData.darlehencounterForward && this.reduxData.darlehencounterForward > 0) {
            if (this.reduxData.darlehenForward && this.reduxData.darlehenForward.length > 0) {
                // BestehendesDarlehen
                let bestehendeDarlehen: BestehendesDarlehenDesFinanzierungsobjektes[] = [];
                for(let i = 0; i < this.reduxData.darlehencounterForward; i++) {
                    let id = `darlehen-${this.reduxData.darlehenForward[i].id}`;
                    let darlehenBaustein = {
                        referenzId: id,
                        darlehen: {
                            "@type": this.reduxData.darlehenForward[i].darlehenType,
                            restschuld: {
                                zumAbloesetermin: !!this.reduxData.darlehenForward[i].restschuldBetrag ? this.contentService.convertStringNumber(this.reduxData.darlehenForward[i].restschuldBetrag) : 0,
                                zumAbloeseTermin: !!this.reduxData.darlehenForward[i].restschuldBetrag ? this.contentService.convertStringNumber(this.reduxData.darlehenForward[i].restschuldBetrag) : 0,
                                aktuell: !!this.reduxData.darlehenForward[i].restschuldBetrag ? this.contentService.convertStringNumber(this.reduxData.darlehenForward[i].restschuldBetrag) : 0
                            },
                            darlehensbetrag: !!this.reduxData.darlehenForward[i].restschuldBetrag ? this.contentService.convertStringNumber(this.reduxData.darlehenForward[i].restschuldBetrag) : 0,
                            grundschuld: !!this.reduxData.darlehenForward[i].restschuldBetrag ? this.contentService.convertStringNumber(this.reduxData.darlehenForward[i].restschuldBetrag) : 0,
                            zinsbindungBis: !!this.reduxData.darlehenForward[i].abloesedate ? this.contentService.convertDate(this.reduxData.darlehenForward[i].abloesedate) : '',
                        }
                    }
                    bestehendeDarlehen.push(darlehenBaustein);
                }
                if (bestehendeDarlehen.length > 0) {
                    data.darlehenslisteErfassung = {
                        "@type": 'VORHANDENE_BESTEHENDE_DARLEHEN_DES_FINANZIERUNGSOBJEKTES',
                        darlehensliste: bestehendeDarlehen
                    }
                } else {
                    data.darlehenslisteErfassung = {
                        "@type": 'KEINE_BESTEHENDE_DARLEHEN_DES_FINANZIERUNGSOBJEKTES',
                    }
                }
            }
        }

        return data;
    }

    // MAPPING FOR AI / PASST
    mapEinkommen(data: any, isPartner: boolean) {
        let einkommen = 0;
        let gewinne = 0;

        if (!isPartner) {
            if (!!data.gehalt) {
                einkommen = einkommen + this.contentService.convertStringNumber(data.gehalt, 2);
            }

            if (!!data.Beschaeftigung && (data.Beschaeftigung === 'SELBSTSTAENDIGER' || data.Beschaeftigung === 'FREIBERUFLER')) {
                gewinne = this.contentService.convertStringNumber(data.gewinnAktuellesJahr, 2) + this.contentService.convertStringNumber(data.gewinnLetztesJahr, 2) + this.contentService.convertStringNumber(data.gewinnVorletztesJahr, 2);
                einkommen = einkommen + (gewinne / 36);
            }
            // Mietausgaben
            if (!!data.haushaltPersonMiete && data.haushaltPersonMiete === 'VorhandeneMiete') {
                if ((!!data.abloeseMiete1 && data.abloeseMiete1 === 'false') || (!!data.abloeseMiete2 && data.abloeseMiete2 === 'false')) {
                    if (data.abloeseMiete1 === 'false' && !!data.miete1) {
                        einkommen = einkommen - this.contentService.convertStringNumber(data.miete1, 2);
                    }
                    if (data.abloeseMiete2 === 'false' && !!data.miete2) {
                        einkommen = einkommen - this.contentService.convertStringNumber(data.miete2, 2);
                    }
                }
            }
            // Cars
            if (!!data.haushaltPersonFahrzeuge && data.haushaltPersonFahrzeuge === 'VorhandeneFahrzeuge') {
                if (!!data.countFahrzeuge) {
                    if (data.countFahrzeuge === 'one') {
                        einkommen = einkommen - 200;
                    } else {
                        einkommen = einkommen - 400;
                    }
                }
            }
            // KKV
            if (!!data.haushaltPersonKKV && data.haushaltPersonKKV !== 'Nein') {
                if (!!data.abgezogenKkv1 && data.abgezogenKkv1 === 'false' && !!data.kkv1) {
                    einkommen = einkommen - this.contentService.convertStringNumber(data.kkv1, 2);
                }
            }
            // Mieteinnahmen
            if (!!data.immobilien && data.immobilien === 'VORHANDENE_BESTEHENDE_IMMOBILIEN') {
                if (data.immobilienVermietetAnzahl > 0 || data.immobilienTeilVermietetAnzahl > 0) {
                    data.bestehendeImmobilien.map((immobilie: any) => {
                        if ((immobilie.objektnutzung === 'TEILVERMIETET' || immobilie.objektnutzung === 'VERMIETET') && !!immobilie.kaltmiete) {
                            einkommen = einkommen + this.contentService.convertStringNumber(immobilie.kaltmiete, 2);
                        }
                    });
                }
            }
            // Partner
        } else {
            if (!!data.gehaltPartner) {
                einkommen = einkommen + this.contentService.convertStringNumber(data.gehaltPartner, 2);
            }

            if (!!data.BeschaeftigungPartner && (data.BeschaeftigungPartner === 'SELBSTSTAENDIGER' || data.BeschaeftigungPartner === 'FREIBERUFLER')) {
                let gewinne = this.contentService.convertStringNumber(data.gewinnAktuellesJahrPartner, 2) + this.contentService.convertStringNumber(data.gewinnLetztesJahrPartner, 2) + this.contentService.convertStringNumber(data.gewinnVorletztesJahrPartner, 2);
                einkommen = einkommen + (gewinne / 36);
            }
            // KKV
            if (!!data.haushaltPersonKKV && data.haushaltPersonKKV !== 'Nein') {
                if (!!data.abgezogenKkv2 && data.abgezogenKkv2 === 'false' && !!data.kkv3) {
                    einkommen = einkommen - this.contentService.convertStringNumber(data.kkv3, 2);
                }
            }
        }
        return einkommen
    }

    mapEinnahmen(data: any) {
        let einnahmen = 0;

        if (!!data.nebentaetigkeit && data.nebentaetigkeit === 'NebentaetigkeitBesteht') {
            einnahmen = einnahmen + this.contentService.convertStringNumber(data.einkuenfteAusNebentaetigkeit, 2);
        }

        if (!!data.rente && data.rente === 'RenteBesteht') {
            einnahmen = einnahmen + this.contentService.convertStringNumber(data.summeUnbefristeteZusatzrentenMonatlich, 2);
        }

        if (!!data.provision && data.provision === 'ProvisionBesteht') {
            einnahmen = einnahmen + this.contentService.convertStringNumber(data.summeSonstigeEinnahmenMonatlich, 2);
        }
        if (!!data.nebentaetigkeitPartner && data.nebentaetigkeitPartner === 'NebentaetigkeitBesteht') {
            einnahmen = einnahmen + this.contentService.convertStringNumber(data.einkuenfteAusNebentaetigkeitPartner, 2);
        }

        if (!!data.rentePartner && data.rentePartner === 'RenteBesteht') {
            einnahmen = einnahmen + this.contentService.convertStringNumber(data.summeUnbefristeteZusatzrentenMonatlichPartner, 2);
        }

        if (!!data.provisionPartner && data.provisionPartner === 'ProvisionBesteht') {
            einnahmen = einnahmen + this.contentService.convertStringNumber(data.summeSonstigeEinnahmenMonatlichPartner, 2);
        }

        if (!!data.KinderErfassung && data.KinderErfassung === 'VORHANDENE_KINDER') {
            data.kinder.map((kind: any) => {
                if (kind.unterhaltPlus === 'true' && !!kind.unterhaltPlusBetrag) {
                    einnahmen = einnahmen + this.contentService.convertStringNumber(kind.unterhaltPlusBetrag, 2);
                }
            })
        }

        return einnahmen
    }

    mapEigenkapital(data: any) {
        let eigenkapital = 0;
        // sparen
        if (!!data.sparguthaben && data.sparguthaben === 'sparguthabenVorhanden') {
            if (!!data.sparenVerwendung && data.sparenVerwendung === 'AUFLOESUNG_ALS_VERWENDUNG') {
                if (!!data.sparenMaximalEinzusetzenderBetrag) {
                    eigenkapital = eigenkapital + this.contentService.convertStringNumber(data.sparenMaximalEinzusetzenderBetrag, 2)
                }
            }
        }
        // wertpapiere
        if (!!data.wertpapiere && data.wertpapiere === 'wertpapiereVorhanden') {
            if (!!data.wertpapiereVerwendung && data.wertpapiereVerwendung === 'AUFLOESUNG_ALS_VERWENDUNG') {
                if (!!data.wertpapiereMaximalEinzusetzenderBetrag) {
                    eigenkapital = eigenkapital + this.contentService.convertStringNumber(data.wertpapiereMaximalEinzusetzenderBetrag, 2)
                }
            }
        }
        // bausparvertraege
        if (!!data.bausparguthaben && data.bausparguthaben === 'bausparguthabenVorhanden') {
            data.bausparvertraege.map((vertrag: any) => {
                if (vertrag.verwendung === 'AUFLOESUNG_ALS_VERWENDUNG' && !!vertrag.maximalEinzusetzenderBetrag) {
                    eigenkapital = eigenkapital + this.contentService.convertStringNumber(vertrag.maximalEinzusetzenderBetrag, 2);
                }
            });
        }
        // versicherungen
        if (!!data.versicherung && data.versicherung === 'versicherungVorhanden') {
            data.versicherungsvertraege.map((vertrag: any) => {
                if (vertrag.verwendung === 'AUFLOESUNG_ALS_VERWENDUNG' && !!vertrag.maximalEinzusetzenderBetrag) {
                    eigenkapital = eigenkapital + this.contentService.convertStringNumber(vertrag.maximalEinzusetzenderBetrag, 2);
                }
            });
        }
        // bestehende Immobilien
        if (!!data.immobilien && data.immobilien === 'VORHANDENE_BESTEHENDE_IMMOBILIEN') {
            if (data.bestehendeImmobilien && data.bestehendeImmobilien.length > 0) {
                data.bestehendeImmobilien.map((immobilie: any) => {
                    if (immobilie.change === 'VERKAUFEN' && immobilie.erloesVerwenden === 'true' && !!immobilie.realValue) {
                        eigenkapital = eigenkapital + this.contentService.convertStringNumber(immobilie.realValue, 2);
                    }
                });
            }
        }

        return eigenkapital;
    }

    mapSchulden(data: any, typ: string) {
        let schulden = 0;

        switch (typ) {
            case 'immobilien': {
                data.bestehendeImmobilien.map((immobilie: any) => {
                    if (immobilie.finanzierung === 'VORHANDENE_BESTEHENDE_DARLEHEN' && !!immobilie.restschuld) {
                        schulden = schulden + this.contentService.convertStringNumber(immobilie.restschuld, 2);
                    }
                });
                break;
            };
            case 'kredite': {
                data.verbindlichkeiten.map((kredit: any) => {
                    if (kredit.verbindlichkeitTyp !== 'leasing' && !!kredit.restschuld) {
                        schulden = schulden + this.contentService.convertStringNumber(kredit.restschuld, 2);
                    }
                });
                break;
            }
            case 'rate': {
                data.verbindlichkeiten.map((kredit: any) => {
                    if (!!kredit.rate) {
                        schulden = schulden + this.contentService.convertStringNumber(kredit.rate, 2);
                    }
                });
                if (data.bestehendeImmobilien && data.bestehendeImmobilien.length > 0) {
                    data.bestehendeImmobilien.map((immobilie: any) => {
                        if (immobilie.finanzierung === 'VORHANDENE_BESTEHENDE_DARLEHEN' && !!immobilie.rate) {
                            schulden = schulden + this.contentService.convertStringNumber(immobilie.rate, 2);
                        }
                    });
                }
                break;
            }
        }

        return schulden
    }

    mapObject(data: any) {
        let type = '';

        if (data.objectType !== 'Haus' && data.objectType !== 'Gewerbe') {
            type = data.objectType;
        } else if (data.objectType === 'Haus') {
            type = data.houseType;
        } else if (data.objectType === 'Gewerbe') {
            type = 'MEHRFAMILIENHAUS';
        }

        return type
    }

    mapPreferences(data: any, type: string) {
        let value: any;
        const currentDate = new Date;
        let currentYear = currentDate.getFullYear();

        switch (type) {
            case 'ratePref': {
                if (!!data.ratePref && data.ratePref === 'rateJa') {
                    if (!!data.rateValuePref) {
                        if (data.rateValuePref === '4' && !!data.rateNumberPref) {
                            value = this.contentService.convertStringNumber(data.rateNumberPref, 2);
                        }
                    }
                }
                break;
            }
            case 'bewilligungPref': {
                if (!!data.bewilligungPref) {
                    if (data.bewilligungPref !== 'none') {
                        value = this.contentService.convertDate(this.contentService.addDay(currentDate, this.contentService.convertStringNumber(data.bewilligungPref)));
                    }
                }
                break;
            }
            // case 'zinsbindungPref': {
            //     if (!!data.zinsbindungPref && data.zinsbindungPref === 'zinsbindungJa') {
            //         if (!!data.zinsbindungValuePref) {
            //             value = this.contentService.convertStringNumber(data.zinsbindungValuePref);
            //         }
            //     }
            //     break;
            // }
            case 'laufzeitPref': {
                if (!!data.laufzeitPref && data.laufzeitPref === 'laufzeitJa') {
                    if (!!data.laufzeitValuePref) {
                        switch (data.laufzeitValuePref) {
                            case '1': {
                                if (!!data.zinsbindungValuePref) {
                                    value = this.contentService.convertStringNumber(data.zinsbindungValuePref);
                                } else {
                                    value = 20;
                                }
                                break;
                            }
                            case '2': {
                                value = 10;
                                break;
                            }
                            case '3': {
                                if (data.participants !== 'allein') {
                                    let datum1 = new Date(data.geburtsdatum);
                                    let datum2 = new Date(data.geburtsdatumPartner);
                                    let age1 = currentYear - datum1.getFullYear();
                                    let age2 = currentYear - datum2.getFullYear();
                                    let years1 = 67 - age1;
                                    let years2 = 67 - age2;
                                    value = years1 > years2 ? years1 : years2;
                                } else {
                                    let datum = new Date(data.geburtsdatum);
                                    let age = currentYear - datum.getFullYear();
                                    value = 67 - age;
                                }
                                break;
                            }
                            case '4': {
                                if (!!data.laufzeitVariablePref) {
                                    value = this.contentService.convertStringNumber(data.laufzeitVariablePref) - currentYear;
                                } else {
                                    value = 20;
                                }
                                break;
                            }
                        }

                    }
                }
                break;
            }
            case 'sondertilgungPref': {
                if (!!data.sondertilgungPref && data.sondertilgungPref === 'sondertilgungJa') {
                    if (!!data.sondertilgungValuePref) {
                        value = this.contentService.convertStringNumber(data.sondertilgungValuePref);
                    }
                }
                break;
            }
            case 'auszahlungPref': {
                if (!!data.auszahlungPref) {
                    value = this.contentService.convertStringNumber(data.auszahlungPref);
                }
                break;
            }
        };
        return value
    }

    // API-CALLS
    async sendEuropaceRequest(request?: Root) {
        let header = new HttpHeaders({ 'finsoliotenantuuid': this.contentService.getTenantUuid() });
        const host_url = environment.backendHost; //only DEV System allowed
        const path_finanzierungsanfrage = '/finanzierungsanfrage';
        const path_validate = '/finanzierungsanfrage/validate';

        console.log(header, this.contentService.getTenantUuid());
        console.log('REQUEST TO EUROPACE (VORGANG ANLEGEN): ', request);
        this.http.post(host_url + path_validate, request, { headers: header }).subscribe({
            next: (result) => {
                console.log('path_validate', result)
            },
            error: (error) => {
                console.log(error)
            }
        });
        return this.http.post(host_url + path_finanzierungsanfrage, request, { headers: header })
        // .subscribe({
        //     next: (result: any) => {
        //         console.log('path_finanzierungsanfrage', result),
        //             this.daService.update(1, { anfrageUuid: result.finanzierungsanfrageuuid });
        //         return result;
        //     },
        //     error: (error) => {
        //         console.log(error)
        //     }
        // });
    }

    async patchEuropaceRequest(request: Root) {
        // const textRequest = testData;
        let header = new HttpHeaders({ 'finsoliotenantuuid': this.contentService.getTenantUuid() });
        const host_url = environment.backendHost; //only DEV System allowed
        const path_finanzierungsanfrage = '/finanzierungsanfrage/';

        console.log('REQUEST TO EUROPACE (VORGANG AKTUALISIEREN): ', request);
        let uuid = this.contentService.getValue('anfrageUuid');
        return this.http.put(host_url + path_finanzierungsanfrage + uuid, request, { headers: header });
    }

    async getResults() {
        let header = new HttpHeaders({ 'finsoliotenantuuid': this.contentService.getTenantUuid() });
        const request: VorschlagKonventionellRoot = {
            "dametadata": {
                "finanzierungsanfrageuuid": this.contentService.getValue('anfrageUuid'),
                "precheckstore": this.contentService.getValue('expressRequest') ? false : true,
                "alternativeangebote": true
            }
        }
        console.log('die Daten, die an die Angebote API geschickt werden: ', request)
        return this.http.post(`${environment.backendHost}/finanzierungsvorschlaege/konventionell`, request, { headers: header })
    };

    async getAiResults(objectPlz: string) {
        let header = new HttpHeaders({ 'finsoliotenantuuid': this.contentService.getTenantUuid() });
        // console.log('objectPlz: ', objectPlz);
        this.reduxData = this.dataQuery.getEntity(1);
        //PERSON
        let kunden = [];
        let einkommen = this.mapEinkommen(this.reduxData, false);
        let eigenkapital = 0;
        let sonstigeEinnahmen = this.mapEinnahmen(this.reduxData);
        let gesamtPrivateDarlehenRestschuld = 0;
        let gesamtRatenkrediteRestschuld = 0;
        let verbleibendeRatenkrediteRestschuld = gesamtRatenkrediteRestschuld;
        let verbleibendeRatenkrediteRateMonatlich = 0;
        // FINANCE NEEDS
        let eigenleistungValue = !!this.reduxData.eigenleistungValue ? this.contentService.convertStringNumber(this.reduxData.eigenleistungValue) : 0;
        let grundstueckKaufpreis = !!this.reduxData.grundstueckspreis ? this.contentService.convertStringNumber(this.reduxData.grundstueckspreis, 2) : 0;
        let kaufpreis = this.reduxData.projectPlan !== 'NEUBAU' && !!this.reduxData.kaufpreis ? this.contentService.convertStringNumber(this.reduxData.kaufpreis, 2) : 0;
        let herstellungskostenInklusiveEigenleistungen = this.reduxData.projectPlan === 'NEUBAU' && !!this.reduxData.herstellungskosten ? this.contentService.convertStringNumber(this.reduxData.herstellungskosten, 2) + eigenleistungValue : 0;
        let modernisierungsKostenInklEigenleistungen = !!this.reduxData.modernisierungkosten ? this.contentService.convertStringNumber(this.reduxData.modernisierungkosten, 2) + eigenleistungValue : 0;
        let modernisierungEigenleistung = eigenleistungValue;
        let sonderzahlung = 0; // NEU!!
        // PREFERENCES
        let rate = this.mapPreferences(this.reduxData, 'ratePref');
        let kreditEntscheidungsZeit = this.mapPreferences(this.reduxData, 'bewilligungPref');
        // let zinsbindungInJahren = this.mapPreferences(this.reduxData, 'zinsbindungPref');
        let laufzeit = this.mapPreferences(this.reduxData, 'laufzeitPref');
        let optionaleSondertilgung = this.mapPreferences(this.reduxData, 'sondertilgungPref');
        let bereitstellungszinsfreieZeit = this.mapPreferences(this.reduxData, 'auszahlungPref');
        //OBJECT
        let objektArt = this.mapObject(this.reduxData);
        let vermietet = this.reduxData.objectNutzungwohnflaeche !== 'EIGENGENUTZT' ? true : false;
        let baujahr = this.reduxData.objectBaujahr;
        let gewerblicheNutzung = this.reduxData.objectType === 'Gewerbe' ? true : false;
        let plz = objectPlz;
        let ort = this.reduxData.objectAddress.ort;
        let strasse = this.reduxData.objectAddress.strasse;
        let hausnummer = this.reduxData.objectAddress.hausnummer;
        let wohnflaeche = !!this.reduxData.objectGesamtflaeche ? this.contentService.convertStringNumber(this.reduxData.objectGesamtflaeche, 2) : 0;
        let darlehensliste: BestehendesDarlehenDesFinanzierungsobjektesAi[] = [];

        if (this.reduxData.darlehencounterForward && this.reduxData.darlehencounterForward > 0) {
            if (this.reduxData.darlehenForward && this.reduxData.darlehenForward.length > 0) {
                // BestehendesDarlehen
                this.reduxData.darlehenForward.map((item: any) => {
                    let id = `darlehen-${item.id}`;
                    let darlehenBaustein = {
                        wirdAbgeloest: !!item.abloesen && item.abloesen === 'abloesenJa' ? true : false,
                        referenzId: id,
                        restschuld: {
                            zumAbloesetermin: !!item.restschuldBetrag ? this.contentService.convertStringNumber(item.restschuldBetrag) : 0,
                            zumAbloeseTermin: !!item.restschuldBetrag ? this.contentService.convertStringNumber(item.restschuldBetrag) : 0,
                            aktuell: !!item.restschuldBetrag ? this.contentService.convertStringNumber(item.restschuldBetrag) : 0
                        },
                        darlehensbetrag: !!item.restschuldBetrag ? this.contentService.convertStringNumber(item.restschuldBetrag) : 0,
                        grundschuld: !!item.restschuldBetrag ? this.contentService.convertStringNumber(item.restschuldBetrag) : 0,
                        zinsbindungBis: !!item.abloesedate ? this.contentService.convertDate(item.abloesedate) : '',
                    }
                    darlehensliste.push(darlehenBaustein);
                });
            }
        }


        let kunde = {
            "einkommenNetto": einkommen,
            "geburtsdatum": this.contentService.convertDate(this.reduxData.geburtsdatum),
            "beschaeftigungsArt": this.reduxData.Beschaeftigung
        }
        kunden.push(kunde);

        if (this.reduxData.participants !== 'allein') {
            let einkommenPartner = this.mapEinkommen(this.reduxData, true);
            let kundePartner = {
                "einkommenNetto": einkommenPartner,
                "geburtsdatum": this.contentService.convertDate(this.reduxData.geburtsdatumPartner),
                "beschaeftigungsArt": this.reduxData.BeschaeftigungPartner
            }
            kunden.push(kundePartner);
        }

        if (!!this.reduxData.hasAssets && this.reduxData.hasAssets === 'Ja') {
            eigenkapital = this.mapEigenkapital(this.reduxData);
            if (!!this.reduxData.immobilien && this.reduxData.immobilien === 'VORHANDENE_BESTEHENDE_IMMOBILIEN' && this.reduxData.bestehendeImmobilien && this.reduxData.bestehendeImmobilien.length > 0) {
                gesamtPrivateDarlehenRestschuld = this.mapSchulden(this.reduxData, 'immobilien');
            }
        }

        if (!!this.reduxData.VerbindlichkeitenErfassung && this.reduxData.VerbindlichkeitenErfassung === 'VorhandeneVerbindlichkeiten' && this.reduxData.verbindlichkeiten && this.reduxData.verbindlichkeiten.length > 0) {
            gesamtRatenkrediteRestschuld = this.mapSchulden(this.reduxData, 'kredite');
            verbleibendeRatenkrediteRateMonatlich = this.mapSchulden(this.reduxData, 'rate');
        }

        let finanzierungsObjekt: FinanzierungsobjektAi = {
            "objektArt": objektArt,
            "vermietet": vermietet,
            "baujahr": baujahr,
            "gewerblicheNutzung": gewerblicheNutzung,
            "anschrift": {
                "plz": plz,
                "ort": ort,
                "strasse": strasse,
                "hausnummer": hausnummer
            },
            "wohnflaeche": wohnflaeche,
        }

        if (darlehensliste.length > 0) {
            finanzierungsObjekt = { ...finanzierungsObjekt, darlehensliste: darlehensliste }
        }

        if(!!this.reduxData.objectMarkwert) {
            finanzierungsObjekt = { ...finanzierungsObjekt, marktwert: this.contentService.convertStringNumber(this.reduxData.objectMarkwert) }
        }

        let request: VorschlagAiRoot = {
            "anfragedaten": {
                "kundenangaben": {
                    "haushalte": [
                        {
                            "kunden": kunden,
                            "finanzielleSituation": {
                                "eigenKapital": eigenkapital,
                                "sonstigeEinnahmen": sonstigeEinnahmen,
                                "gesamtPrivateDarlehenRestschuld": gesamtPrivateDarlehenRestschuld,
                                "gesamtRatenkrediteRestschuld": gesamtRatenkrediteRestschuld,
                                "verbleibendeRatenkrediteRestschuld": verbleibendeRatenkrediteRestschuld,
                                "verbleibendeRatenkrediteRateMonatlich": verbleibendeRatenkrediteRateMonatlich
                            }
                        }
                    ],
                    "finanzierungsbedarf": {
                        "finanzierungszweck": this.reduxData.projectPlan,
                        "grundstueckKaufpreis": grundstueckKaufpreis,
                        "kaufpreis": kaufpreis,
                        "maklergebuehr": !!this.reduxData.maklerbetrag ? this.contentService.convertStringNumber(this.reduxData.maklerbetrag) : 0,
                        "darlehenswunsch": !!this.reduxData.darlehensbedarf ? this.contentService.convertStringNumber(this.reduxData.darlehensbedarf) : undefined,
                        "modernisierungsKostenInklEigenleistungen": modernisierungsKostenInklEigenleistungen,
                        "herstellungskostenInklusiveEigenleistungen": herstellungskostenInklusiveEigenleistungen,
                        "sonderzahlungZumZinsbindungsEnde": sonderzahlung,
                        "modernisierungEigenleistung": modernisierungEigenleistung,
                        "praeferenzen": {
                            "rate": rate,
                            "kreditEntscheidungsZeit": kreditEntscheidungsZeit,
                            // "zinsbindungInJahren": zinsbindungInJahren,
                            "laufzeit": laufzeit,
                            "optionaleSondertilgung": optionaleSondertilgung,
                            "bereitstellungszinsfreieZeit": bereitstellungszinsfreieZeit
                        }
                    },
                    "finanzierungsobjekt": finanzierungsObjekt
                },
                "metadaten": {
                    "datenkontext": "ECHT_GESCHAEFT",
                    "feature": this.reduxData.aufbauPref === 'aufbauJa' && !!this.reduxData.aufbauValuePref ? this.reduxData.aufbauValuePref : 'enable_extended_loan_types',
                    "mode": "exploration-matrix",
                    "gewuenschteAnzahlVorschlaege": 30
                }
            }
        }

        console.log('die Daten, die an die Passt API geschickt werden: ', request)
        return this.http.post(`${environment.backendHost}/finanzierungsvorschlaege/ai`, request, { headers: header })
    };
}