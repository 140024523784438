import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateComponent implements OnInit {

  @Input() num!: number;
  @Output() public done: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private daService: DataService,
    private dataQuery: DataQuery,
  ) { }

  ngOnInit(): void {
  }

  validateInput(e: any, element: any) {
    setTimeout(() => {
      const value = e[0];
      element.touched = true;
      element.placeholder = '';
      let isValid = this.contentService.validatePattern(value, element.validationType);
      element.error = !isValid;
      let darlehen;
      if (!element.error) {
        darlehen = this.contentService.updateNestedObject('darlehenBausteine', 'rate', value, this.num, 'state', 'warning');
      } else {
        darlehen = this.contentService.updateNestedObject('darlehenBausteine', 'state', 'error', this.num);
      }
      this.daService.update(1, { darlehenBausteine: darlehen });
      this.done.emit(this.num);

    },100)
  }

}
