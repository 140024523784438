<lib-header-text
    class="da-title"
    label="{{title | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>

<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
    >
    <ng-template #content>
        <!-- wieviele vertraege -->
        <lib-tile-text
            size="16px"
            label="{{'MIETE.TILE_TEXT1' | translate}}"
            [light]="true"
        ></lib-tile-text>
        <lib-toggle
            #count
            label1="1"
            label2="2"
            name="count"
            value1="one"
            value2="two"
            [localValue]="contentService.getValue('countMiete')"
            (switchValue)="setSwitch($event, 'count')"
            [light]="true"
        ></lib-toggle>
        <lib-toggle-tristate 
            class="da-miete-tabs"
            *ngIf="!!contentService.getValue('countMiete') && contentService.getValue('countMiete') === 'two'"
            [tabs]="tabs"
            (switchValue)="setSwitch($event, 'tabs')"
        ></lib-toggle-tristate>

         <!-- vertrag1 -->
         <ng-container *ngIf="!!contentService.getValue('countMiete') && (contentService.getValue('countMiete') === 'one' || selectedTab.value === 'number1')">
            <lib-tile-text
                *ngIf="!!contentService.getValue('countMiete') && contentService.getValue('countMiete') === 'two'"
                size="20px"
                label="{{'MIETE.TILE_TEXT_VERTRAG1' | translate}}"
                [light]="true"
            ></lib-tile-text>
            <lib-input
                #rentOne
                name="rent1"
                placeholder="800,00"
                [error]="false"
                [inputDisabled]="inputDisabled"
                (click)="testValid = true"
                errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                [touched]="!!contentService.getValue('miete1')"
                label="{{'COMMON.LABELS.MIETE' | translate}}"
                [value]="!!contentService.getValue('miete1') ? contentService.getValue('miete1') : ''"
                (change)="validateInputValue($event, rentOne)"
                [light]="true"
                [ngStyle]="{'margin-top': !!contentService.getValue('countMiete') && contentService.getValue('countMiete') === 'one' ? '45px' : '0'}"
            ></lib-input>
            <lib-tile-text
                class="da-miete-text-smaller"
                size="16px"
                label="{{'MIETE.TILE_TEXT2' | translate}}"
                [light]="true"
                info="true"
                (infoValue)="screen.openInfo(mieteInfo)"
            ></lib-tile-text>
            <lib-toggle
                #abloese1
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="abloese1"
                value1="true"
                value2="false"
                [localValue]="contentService.getValue('abloeseMiete1')"
                (switchValue)="setSwitch($event, 'abloese1')"
                [light]="true"
            ></lib-toggle>
        </ng-container>

        <!-- vertrag2 -->
        <ng-container *ngIf="!!contentService.getValue('countMiete') && (contentService.getValue('countMiete') === 'two' && selectedTab.value === 'number2')">
            <lib-tile-text
                size="20px"
                label="{{'MIETE.TILE_TEXT_VERTRAG2' | translate}}"
                [light]="true"
            ></lib-tile-text>
            <lib-input
                #rentTwo
                name="rent2"
                placeholder="800,00"
                [error]="false"
                [inputDisabled]="inputDisabled"
                (click)="testValid = true"
                errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                [touched]="!!contentService.getValue('miete2')"
                label="{{'COMMON.LABELS.MIETE' | translate}}"
                [value]="!!contentService.getValue('miete2') ? contentService.getValue('miete2') : ''"
                (change)="validateInputValue($event, rentTwo)"
                [light]="true"
            ></lib-input>
            <lib-tile-text
                class="da-miete-text-smaller"
                size="16px"
                label="{{'MIETE.TILE_TEXT2' | translate}}"
                [light]="true"
                info="true"
                (infoValue)="screen.openInfo(mieteInfo)"
            ></lib-tile-text>
            <lib-toggle
                #abloese2
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="abloese2"
                value1="true"
                value2="false"
                [localValue]="contentService.getValue('abloeseMiete2')"
                (switchValue)="setSwitch($event, 'abloese2')"
                [light]="true"
            ></lib-toggle>
        </ng-container>
    </ng-template>
</lib-tile-group>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

<ng-template #mieteInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MIETE.INFO_HEADER' | translate}}" text="{{'MIETE.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>