<div class="da-lightbox" [ngClass]="{'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet()}">
    <div class="da-close-icon" [ngClass]="{'mobile' : screen.isSmallest()}" (click)="dismiss()">
        <svg-icon
            src="assets/images/chevronRight.svg"
        >
        </svg-icon>
    </div>
    <div class="da-modal-body">
        <div class="da-header-icon">
            <svg-icon
                src="assets/images/userHeadset.svg"
            >
            </svg-icon>
        </div>
        <lib-header-text
            class="da-lightbox-header"
            size="38px"
            [convertMobile]="true"
            label="{{'CONTACT.HEADER' | translate}}"
        ></lib-header-text>
        <div class="da-lightbox-content">
            <div class="da-content-wrapper">
                <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <div class="da-content-row-left">
                        <lib-tile-text
                            class="da-lightbox-content-text"
                            label="{{'CONTACT.TIMES' | translate}}"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                    <div class="da-content-row-right">
                        <lib-tile-text
                            class="da-lightbox-content-text"
                            [label]="times"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                </div>
                <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <div class="da-content-row-left">
                        <lib-tile-text
                            class="da-lightbox-content-text"
                            label="{{'CONTACT.PERSON' | translate}}"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                    <div class="da-content-row-right">
                        <lib-tile-text
                            class="da-lightbox-content-text"
                            [label]="person"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                </div>
                <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <div class="da-content-row-left">
                        <lib-tile-text
                            class="da-lightbox-content-text"
                            label="{{'CONTACT.TEL' | translate}}"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                    <div class="da-content-row-right">
                        <lib-tile-text
                            class="da-lightbox-content-text"
                            [label]="tel"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                </div>
                <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <div class="da-content-row-left">
                        <lib-tile-text
                            class="da-lightbox-content-text"
                            label="{{'CONTACT.EMAIL' | translate}}"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                    <div class="da-content-row-right">
                        <lib-tile-text
                            class="da-lightbox-content-text"
                            [label]="email"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                </div>
                <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <div class="da-content-row-left">
                        <lib-tile-text
                            class="da-lightbox-content-text"
                            label="{{'CONTACT.MAIL' | translate}}"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                    <div class="da-content-row-right">
                        <lib-tile-text
                            class="da-lightbox-content-text"
                            [label]="mail"
                            size="16px"
                        ></lib-tile-text>
                    </div>
                </div>
                <div class="da-content-divider">
                    <lib-tile-text
                        class="da-lightbox-content-text"
                        label="{{'CONTACT.DIVIDER' | translate}}"
                        [thin]="true"
                        size="12px"
                    ></lib-tile-text>
                </div>
            </div>
        </div>
        <div class="da-header-icon">
            <svg-icon
                src="assets/images/calendarRange.svg"
            >
            </svg-icon>
        </div>
        <lib-header-text
            class="da-lightbox-header"
            size="38px"
            [convertMobile]="true"
            label="{{'CONTACT.APPT_HEADER' | translate}}"
        ></lib-header-text>
        <lib-tile-text
            class="da-content-text"
            size="16px"
            [convertMobile]="true"
            label="{{'CONTACT.APPT_TEXT' | translate}}"
        ></lib-tile-text>
        <div class="da-address-button-wrapper" *ngIf="showButtons">
            <div class="da-sidebar-button-bg">
                <lib-button 
                    name="nebenkosten button"
                    value="{{'CONTACT.BUTTON' | translate}}" 
                    width="230"
                    (buttonValue)="getCalendly(calendly)"
                    iconRight="assets/images/chevronRight.svg"
                ></lib-button>
                <lib-tile-text
                    class="da-small-text"
                    size="10px"
                    [convertMobile]="true"
                    label="{{'BUTTONS.SUB_TEXT' | translate}}"
                ></lib-tile-text>
            </div>
        </div>
    </div>
</div>
<ng-template #calendly let-modal>
    <app-calendly 
    [modal]="modal" 
    ></app-calendly>
</ng-template>
