import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentService } from 'src/app/services/content.service';
import { DaClickpathService } from 'src/app/state/click-path/da-clickpath.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';

@Component({
  selector: 'app-nav-buttons',
  templateUrl: './nav-buttons.component.html'
})
export class NavButtonsComponent implements OnChanges {

  @Input() save!: boolean;
  @Input() dashboard!: boolean;
  @Input() zertifikat!: boolean;
  @Input() nextValidate: boolean = false;
  @Input() nextDisabled: boolean = true;
  @Input() noNextButton: boolean = false;
  @Input() done: boolean = false;
  @Input() current!: string;
  @Input() next!: string;

  buttons: any = []
  buttonsWithSave: any = []
  buttonsDashboard: any = []
  buttonsZertifikat: any = []
  buttonsNoNext: any = []
  buttonsDone: any = []

  

  @Output() public slideTo:EventEmitter<any> = new EventEmitter<string>();
  @Output() public validate:EventEmitter<any> = new EventEmitter<string>();

  constructor(
    private daClickpathService: DaClickpathService,
    private daNextSlideService: DaNextSlideService,
    private modalService: NgbModal,
    public contentService: ContentService
  ) { }

  ngOnChanges(): void {
    this.setButtons();
  }

  setButtons() {
    this.buttons = [
      {
        'iconLeft': 'assets/images/chevronLeft.svg',
        'value': 'BUTTONS.BACK',
        'type': 'back'
      },
      {
        'iconRight': 'assets/images/chevronRight.svg',
        'value': this.nextValidate ? 'BUTTONS.VALID' : 'BUTTONS.FORWARD',
        'disabled': this.nextDisabled,
        'width': this.nextValidate ? '160' : '',
        'type': 'next'
      }
    ];
    this.buttonsWithSave = [
      {
        'iconLeft': 'assets/images/chevronLeft.svg',
        'value': 'BUTTONS.BACK',
        'type': 'back'
      },
      {
        'label': 'BUTTONS.SAVE',
        'iconButton': true,
        'noHover': true,
        'icon': 'assets/images/diskSave.svg',
        'disabled': !this.save,
        'type': 'save',
      },
      {
        'iconRight': 'assets/images/chevronRight.svg',
        'value': this.nextValidate ? 'BUTTONS.VALID' : 'BUTTONS.FORWARD',
        'disabled': this.nextDisabled,
        'width': this.nextValidate ? '160' : '',
        'type': 'next'
      }
    ];
    this.buttonsNoNext = [
      {
        'iconLeft': 'assets/images/chevronLeft.svg',
        'value': 'BUTTONS.BACK',
        'type': 'back'
      }
    ];
    this.buttonsDashboard = [
      {
        'iconLeft': 'assets/images/chevronLeft.svg',
        'value': 'BUTTONS.BACK',
        'type': 'back'
      },
      {
        'label': 'BUTTONS.SAVE',
        'iconButton': true,
        'noHover': true,
        'icon': 'assets/images/diskSave.svg',
        'disabled': !this.save,
        'type': 'save',
      },
      {
        'iconRight': 'assets/images/chevronRight.svg',
        'value': this.nextValidate ? 'BUTTONS.VALID' : 'BUTTONS.DASHBOARD',
        'disabled': this.nextDisabled,
        'type': 'dashboard',
        'width': this.nextValidate ? '160' : '220',
      }
    ];

    this.buttonsZertifikat = [
      {
        'iconLeft': 'assets/images/chevronLeft.svg',
        'value': 'BUTTONS.BACK',
        'type': 'back'
      },
      {
        'label': 'BUTTONS.SAVE',
        'iconButton': true,
        'noHover': true,
        'icon': 'assets/images/diskSave.svg',
        'disabled': !this.save,
        'type': 'save',
      },
      {
        'iconRight': 'assets/images/chevronRight.svg',
        'value': this.nextValidate ? 'BUTTONS.VALID' : 'BUTTONS.ZERTIFIKAT',
        'disabled': this.nextDisabled,
        'type': 'dashboard',
        'width': this.nextValidate ? '160' : '220',
      }
    ];
    this.buttonsDone = [
      {
        'icon': 'assets/images/chevronDown.svg',
        'value': '',
        'iconButton': true,
        'type': 'down',
      },
      {
        'label': 'BUTTONS.SAVE',
        'iconButton': true,
        'noHover': true,
        'icon': 'assets/images/diskSave.svg',
        'disabled': !this.save,
        'type': 'save',
      },
      {
        'icon': 'assets/images/chevronUp.svg',
        'value': '',
        'type': 'up',
        'iconButton': true,
      }
    ];
  }

  processNavigation(type: string) {
    if(this.nextValidate) {
      this.validate.emit(type);
    } else {
      if (type === 'next') {
        this.daNextSlideService.update(1, { next: this.next, timestamp: new Date });
        this.daClickpathService.updateClickPath(this.current);
      }
      this.slideTo.emit(type);
    }

    if (type === 'save') {
      this.modalService.dismissAll();
      setTimeout(() => {
        this.slideTo.emit(type);
      })
    }
  }
}
