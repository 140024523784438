import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';

@Component({
  selector: 'app-modernisierung-forward',
  templateUrl: './modernisierung-forward.component.html',
  styleUrls: ['./modernisierung-forward.component.scss']
})
export class ModernisierungForwardComponent implements OnInit {
  @Output() sum: EventEmitter<string> = new EventEmitter();
  @Output() state: EventEmitter<any> = new EventEmitter();
  @Input() inputDisabled!: boolean;
  @Input() testValid!: boolean;
  error: boolean = false;
  isVisible: boolean = true;
  containerState: string = 'optional';

  @ViewChild('sanierung') sanierung!: any;
  @ViewChild('modernisierung') modernisierung!: any;
  @ViewChild('modernisierungGesamt') modernisierungGesamt!: any;

  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    public dataQuery: DataQuery,
    public dataService: DataService
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (!!this.contentService.getValue('projectPlan')) {
        if (this.contentService.getValue('projectPlan') === 'MODERNISIERUNG_UMBAU_ANBAU') {
          this.containerState = 'warning';
          this.setValid();
        }
      }

      if (this.contentService.getValue('sanierungBetrag') && this.contentService.getValue('modernisierungkosten')) {
        this.setValid();
    }
    }, 200)
  }

  validateInput(e: any, element: any) {
    let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
    element.touched = value ? true : false;
    element.placeholder = '';
    const isValid = this.contentService.validatePattern(value, element.validationType);
    const isValidAmount = this.contentService.minMax(value, 0, 999999);
    element.error = isValid && isValidAmount === true ? false : true;

    if (element.name === 'modernisierung') {
      if (!!this.contentService.getValue('eigenleistungTage')) {
        const eigenleistung = this.contentService.getValue('eigenleistungTage') * 400;
        if (eigenleistung > this.contentService.convertStringNumber(value)) {
          this.dataService.update(1, { eigenleistungInValid: true });
        }
      }
    }
    if (!element.error) {
      this.storeValue(value, element.name);
      setTimeout(() => {
        this.calculateSum();
      }, 100)
    }

    setTimeout(() => {
      this.setValid();
    }, 400)
  }

  calculateSum() {
    let sanierung = !!this.contentService.getValue('sanierungBetrag') ? this.contentService.getValue('sanierungBetrag') : '';
    let modernisierung = !!this.contentService.getValue('modernisierungkosten') ? this.contentService.getValue('modernisierungkosten') : '';
    let sum = 0;

    const sanierungSum = !!sanierung ? this.contentService.convertStringNumber(sanierung, 2) : 0;
    const modernisierungSum = !!modernisierung ? this.contentService.convertStringNumber(modernisierung, 2) : 0;

    sum = sanierungSum + modernisierungSum;
    setTimeout(() => {
      this.storeValue(this.contentService.convertNumberString(sum), 'modernisierungGesamt');
      this.sum.emit()
    }, 200)
  }

  storeValue(e: string, name: string) {
    let value = e;
    switch (name) {
      case 'sanierung': {
        this.dataService.update(1, { sanierungBetrag: value });
        break
      }
      case 'modernisierung': {
        this.dataService.update(1, { modernisierungkosten: value });
        break
      }
      case 'modernisierungGesamt': {
        this.dataService.update(1, { modernisierungGesamtBetrag: value });
        break
      }
    }
  }

  setValid() {

    let warnings = 0;
    let errors = 0;

    if ((!this.contentService.getValue('sanierungBetrag') || this.contentService.getValue('sanierungBetrag') === '') &&
      (!this.contentService.getValue('modernisierungkosten') || this.contentService.getValue('modernisierungkosten') === '')) {
      warnings++;
    }

    if (this.sanierung.error || this.modernisierung.error || this.modernisierungGesamt.error || this.contentService.getValue('eigenleistungInValid')) {
      errors++;
    }

    this.containerState = errors > 0 ? 'error' : warnings > 0 ? 'warning' : 'success';
    this.state.emit({ type: 'modernisierung', state: this.containerState });
  }
}
