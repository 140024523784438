import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DaClickpathQuery } from 'src/app/state/click-path/da-clickpath.query';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';

@Component({
  selector: 'app-finance-results',
  templateUrl: './finance-results.component.html',
  styleUrls: ['./finance-results.component.scss']
})
export class FinanceResultsComponent implements OnChanges {

  @Input() resultsDone!: boolean;
  @Input() expressDone!: boolean;
  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  
  ergebnisse: any = [];

  fakeErgebnisse: any = [1,2];

  basePosition: number = 0;
  screenPosition: string = '0px';
  tileSlideFactor: number = window.innerWidth > 1366 ? 1310 : window.innerWidth - 55;
  tileIndex: number = 0;

  pageNumber: number = 1;
  pageGesamt: number = 0;
  pageStart: number = 0;
  pageFactor: number = 5;
  pageEnd: number = this.pageFactor;

  inComplete: boolean = false;
  incompleteText: string = 'COMMON.WARNINGS.INCOMPLETE.TEXT';
  incompleteButton: string = 'COMMON.WARNINGS.INCOMPLETE.BUTTON';
  uploadText: string = 'COMMON.WARNINGS.UPLOAD.TEXT';
  uploadButton: string = 'COMMON.WARNINGS.UPLOAD.BUTTON';
  showButton: boolean = true;
  resultId!: number;
  headerText: string = 'FINANCE_RESULTATE.TITLE';

  constructor(
    public contentService: ContentService,
    public screen: LayoutService,
    private dataQuery: DataQuery,
    private daClickpathQuery: DaClickpathQuery,
    private daService: DataService,
    private configurationQuery: ConfigurationQuery
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
    this.tileSlideFactor = window.innerWidth > 1366 ? 1310 : window.innerWidth - 55;
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(this.dataQuery.getEntity(1)?.['resultsDone']) {
        setTimeout(() => {
          const status = this.dataQuery.getEntity(1);
          const progress = this.daClickpathQuery.getEntity(1)?.slideIndex;
          let incompleteCount = 0;
          if (status?.['results']) {
            setTimeout(() => {this.filterErgebnisse(status?.['results'])}, 300);
          }
          if(progress) {
            progress.map((item: any) => {
              if(!item.excluded && item.state === 'warning') {
                incompleteCount++;
              }
            });
          };
          if(incompleteCount > 0 || status?.['shortcut']) {
            this.inComplete = true;
          } else {
            this.inComplete = false;
          }
        }, 300);
      } else {
        this.ergebnisse = [];
      }
      if(this.contentService.getValue('expressSent')) {
        this.ergebnisse = this.dataQuery.getEntity(1)?.['results'];
        this.uploadText = 'COMMON.WARNINGS.UPLOAD.TEXT2';
        this.headerText = 'FINANCE_RESULTATE.TITLE_DONE';
        this.pageStart = 0;
      }
  }

  filterErgebnisse(results: any) {
    const status = this.dataQuery.getEntity(1);
    let matrix = '';
    let first = true;
    let newItems: any[] = [];
    if(!!status?.['ermittlungsweg'] && status?.['ermittlungsweg'] === 'ai') {
      if(status?.['zinsbindungPref'] === 'zinsbindungJa' && !!status?.['zinsbindungValuePref']) {
        if(!!status?.['rateValuePref'] && status?.['rateValuePref'] !== '4') {
          matrix = `${status?.['zinsbindungValuePref']}-${status?.['rateValuePref']}`;
        } else {
          matrix = status?.['zinsbindungValuePref'];
        }
      } else {
        if(!!status?.['rateValuePref'] && status?.['rateValuePref'] !== '4') {
          matrix = status?.['rateValuePref'];
          first = false;
        }
      }

      if(matrix.indexOf('-') > 0) {
        results.map((item: any) => {
          if(item.matrix === matrix) {
            newItems.push(item);
          }
        });
        this.ergebnisse = newItems;
      } else if (matrix.length > 1) {
        this.ergebnisse = results.map((item: any) => {
          let cut = '';
          if(first) {
            cut = item.matrix.substring(0, item.matrix.indexOf('-'));
          } else {
            cut = item.matrix.substring(item.matrix.indexOf('-') + 1, item.matrix.length);
          }
          if(cut === matrix) {
            newItems.push(item);
          }
        });
        this.ergebnisse = newItems;
      } else {
        this.ergebnisse = results;
      }

    } else {
      this.ergebnisse = results;
    }
    this.ergebnisse = Object.assign([], this.ergebnisse);
    // this.ergebnisse.sort((a: { mischzins: any; },b: { mischzins: any; }) => (a.mischzins> b.mischzins) ? 1 : ((b.mischzins > a.mischzins) ? -1 : 0))
    this.ergebnisse.sort((a: { mischzins: any; },b: { mischzins: any; }) => this.contentService.convertStringNumber(a.mischzins, 2) - this.contentService.convertStringNumber(b.mischzins, 2));
    // console.log(this.ergebnisse);
    // console.log(newItems);

    this.pageGesamt = Math.ceil(this.ergebnisse.length / this.pageFactor);
  }

  switchMeta(i: number, type: string, value: string = '0') {
    let temp: any = [];
    this.ergebnisse.map((ergebnis: any) => {
      if (ergebnis.id === i) {
        if(type === 'reserved') {
          ergebnis = {... ergebnis, isReserved: !ergebnis.isReserved}
        } else if (type === 'visible'){
          ergebnis = {... ergebnis, isVisible: !ergebnis.isVisible}
        } else if (type === 'index') {
          ergebnis = {... ergebnis, selectedTile: value}
        } else if (type === 'tilePos') {
          ergebnis = {... ergebnis, tilePosition: value}
        }
      }
      temp.push(ergebnis)
    });
  this.ergebnisse = temp;
  }

  setVisibility(i: number) {
    this.ergebnisse.map((ergebnis: any) => {
      if (ergebnis.id === i) {
        ergebnis.isVisible = !ergebnis.isVisible
      }
    });
  }

  moveTo(e: string) {
    if(e === 'next') {
      this.pageStart = this.pageStart + this.pageFactor;
      this.pageEnd = this.pageEnd + this.pageFactor;
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageStart = this.pageStart - this.pageFactor;
      this.pageEnd = this.pageEnd - this.pageFactor;
      this.pageNumber = this.pageNumber - 1;
    }
    setTimeout(() => {
      this.screen.scrollToElement('resultate-title', 'start');
    }, 500);
  }

  getValue(e: any) {
    console.log("rating", e);
  }

  getExpressAccess(id: number, modal: any) {
    this.daService.update(1, { expressId: id });
    this.resultId = id;
    this.screen.openInfo(modal);
  }

  getIndex(id: number, e: string, tiles: any, tilePos: string) {
    this.switchMeta(id, 'index', e);
    const tilePosition = this.screen.calculateNewPos(tiles, e, this.tileSlideFactor, tilePos, this.basePosition);
    this.switchMeta(id, 'tilePos', tilePosition);
  };

  stopResults() {
    this.daService.update(1, { resultsLoading: false });
  }

  docDownload(type: string) {
    this.configurationQuery.configuration.subscribe((res: any) => {
      if (res) {
        switch (type) {
          case 'type1': {
            window.open(res.linkpdfvvi,'_blank', 'fullscreen=yes');
            break;
          }
          case 'type2': {
            window.open(res.linkpdfesismustermerkblatt,'_blank', 'fullscreen=yes');
            break;
          }
          case 'type3': {
            window.open(res.linkpdfdatenschutzerklaerung,'_blank', 'fullscreen=yes');
            break;
          }
          case 'type4': {
            window.open(res.linkpdfunterlagencheckliste,'_blank', 'fullscreen=yes');
            break;
          }
          case 'type5': {
            alert('Dokument nicht vorhanden');
            break;
          }
        }
      }
    });
  }

}
