import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-eigenleistung-sidebar',
  templateUrl: './eigenleistung-sidebar.component.html',
  styleUrls: ['./eigenleistung-sidebar.component.scss']
})
export class EigenleistungSidebarComponent implements AfterViewInit {
  localValue!: string;
  disabled: boolean = true;
  tiles = tilesContent['EIGENLEISTUNG'].items;
  showButtons: boolean = false;

  @Input() modal!: NgbModalRef;
  @Output() public eigenleistungDone: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('content') content!: TemplateRef<any>;
  @ViewChild('tage') tage!: TemplateRef<any>;
  @ViewChild('value') eigenleistungValue!: TemplateRef<any>;

  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private daService: DataService,
    private dataQuery: DataQuery,
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngAfterViewInit(): void {
    this.setStatus();
  }

  setStatus() {
    const status = this.dataQuery.getEntity(1)?.['eigenleistung'];
    setTimeout(() => {this.showButtons = true}, 1100);
    setTimeout(() => {
      if(!!status) {
        this.setValue(status);
        if(status === 'VORHANDENE_EIGENLEISTUNG' && this.contentService.getValue('eigenleistungTage') > 0) {
          setTimeout(() => {this.setCounter(this.contentService.getValue('eigenleistungTage'), this.eigenleistungValue)});
        }
      }
      if(status === '') {
        this.tiles = this.contentService.setTileStatusChecked(this.tiles, 'VORHANDENE_EIGENLEISTUNG', 'warning', false);
      }
    }, 100)
  }

  setValue(e: string) {
    this.localValue = e;
    this.daService.update(1, { eigenleistung: e });
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
    if (e === 'VORHANDENE_EIGENLEISTUNG') {
      this.contentService.setTilesContent(this.tiles, e, this.content);
    } else {
      this.daService.update(1, { eigenleistungTage: 0 });
      this.daService.update(1, { eigenleistungValue: '0,00' });
      this.disabled = false;
    }
  }

  setCounter(e: number, element: any) {
    this.daService.update(1, { eigenleistungTage: e });
    let isValid: any = true;
    const value = e * 400;
    element.touched = true;
    let modernisierung = this.dataQuery.getEntity(1)?.['modernisierungkosten'];
    if(!!modernisierung) { 
      modernisierung = this.contentService.convertStringNumber(modernisierung, 2); 
      isValid = this.contentService.minMax(value.toLocaleString('de-DE'), 0, modernisierung);
    };
    
    this.daService.update(1, { eigenleistungValue: value.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) });
    if(isValid !== true) {
      this.daService.update(1, { eigenleistungInValid: true });
      element.error = true;
    } else {
      this.daService.update(1, { eigenleistungInValid: false });
      element.error = false;
    }
    
    if(e > 0 && isValid === true) {
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, 'VORHANDENE_EIGENLEISTUNG', 'success');
      this.disabled = false;
    } else {
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, 'VORHANDENE_EIGENLEISTUNG', 'error');
      this.disabled = true;
    }
  }

  finishInput() {
    this.eigenleistungDone.emit();
    this.dismiss();
  }

  dismiss() {
    document.body.style.overflowY = 'auto';
    this.modal.dismiss();
  }

}
