export const shortcutData = {
    shortcut: true,
    haushaltPerson: 'MANN',
    geburtsdatum: '1995-12-31T22:00:00.000Z',
    staatsangehoerigkeit: 'DE',
    familienstand: 'LEDIG',
    Beschaeftigung: 'ANGESTELLTER',
    probezeit: 'false',
    beschaeftigtSeit: '2019-12-31T22:00:00.000Z',
    beschaeftigungsstatus: 'UNBEFRISTET',
    anzahlGehaelterProJahr: 'ZWOELF',
    gehalt: '15.000,00',
    objectType: 'EIGENTUMSWOHNUNG',
    objectUnitType: 'Wohnflaeche',
    objectGesamtflaeche: '70,00',
    objectNutzungwohnflaeche: 'EIGENGENUTZT',
}

export const shortcutDataPartner = {
    shortcut: true,
    haushaltPerson: 'MANN',
    geburtsdatum: '1995-12-31T22:00:00.000Z',
    staatsangehoerigkeit: 'DE',
    familienstand: 'LEDIG',
    Beschaeftigung: 'ANGESTELLTER',
    probezeit: 'false',
    beschaeftigtSeit: '2019-12-31T22:00:00.000Z',
    beschaeftigungsstatus: 'UNBEFRISTET',
    anzahlGehaelterProJahr: 'ZWOELF',
    gehalt: '15.000,00',
    haushaltPersonPartner: 'FRAU',
    geburtsdatumPartner: '1995-12-31T22:00:00.000Z',
    staatsangehoerigkeitPartner: 'DE',
    familienstandPartner: 'LEDIG',
    BeschaeftigungPartner: 'ANGESTELLTER',
    probezeitPartner: 'false',
    beschaeftigtSeitPartner: '2019-12-31T22:00:00.000Z',
    beschaeftigungsstatusPartner: 'UNBEFRISTET',
    anzahlGehaelterProJahrPartner: 'ZWOELF',
    gehaltPartner: '15.000,00',
    objectType: 'EIGENTUMSWOHNUNG',
    objectUnitType: 'Wohnflaeche',
    objectGesamtflaeche: '70,00',
    objectNutzungwohnflaeche: 'EIGENGENUTZT',
}