import { contentInterface } from "./tiles";

export const switchesContent:contentInterface = {
    "PROPERTY_CATEGORY": {
        "items": [
            {
                "label": "TILES.OBJECT_CATEGORY.SWITCH1",
                "value": "UNBEBAUTES_WOHNGRUNDSTUECK",
            },
            {
                "label": "TILES.OBJECT_CATEGORY.SWITCH2",
                "value": "UNBEBAUTES_MISCHGRUNDSTUECK",
            },
            {
                "label": "TILES.OBJECT_CATEGORY.SWITCH3",
                "value": "UNBEBAUTES_GEWERBEGRUNDSTUECK",
            },
            {
                "label": "TILES.OBJECT_CATEGORY.SWITCH4",
                "value": "LANDWIRTSCHAFTLICHES_GRUNDSTUECK",
            },
            {
                "label": "TILES.OBJECT_CATEGORY.SWITCH5",
                "value": "SONSTIGES_GRUNDSTUECK",
            }
        ]
    },
    "ZWEIFAMILIENHAUS": {
        "items": [
            {
                "label": "TILES.HOUSE_CATEGORY.SWITCH1",
                "value": "FREISTEHEND",
                "parent": "Zweifamilienhaus"
            },
            {
                "label": "TILES.HOUSE_CATEGORY.SWITCH2",
                "value": "KOPFHAUS",
                "parent": "Zweifamilienhaus"
            },
            {
                "label": "TILES.HOUSE_CATEGORY.SWITCH3",
                "value": "MITTELHAUS",
                "parent": "Zweifamilienhaus"
            }
        ]
    },
    "REIHENHAUS": {
        "items": [
            {
                "label": "TILES.HOUSE_CATEGORY.SWITCH2",
                "value": "KOPFHAUS",
                "parent": "Reihenhaus"
            },
            {
                "label": "TILES.HOUSE_CATEGORY.SWITCH3",
                "value": "MITTELHAUS",
                "parent": "Reihenhaus"
            }
        ]
    },
    "MEHRFAMILIENHAUS": {
        "items": [
            {
                "label": "TILES.HOUSE_CATEGORY.SWITCH1",
                "value": "FREISTEHEND",
                "parent": "Mehrfamilienhaus"
            },
            {
                "label": "TILES.HOUSE_CATEGORY.SWITCH2",
                "value": "KOPFHAUS",
                "parent": "Mehrfamilienhaus"
            },
            {
                "label": "TILES.HOUSE_CATEGORY.SWITCH3",
                "value": "MITTELHAUS",
                "parent": "Mehrfamilienhaus"
            }
        ]
    },
    "OBJEKT_EINHEIT": {
        "items": [
            {
                "label": "TILES.OBJEKT_EINHEIT.SWITCH1",
                "value": "Wohnflaeche",
                "parent": "unitType"
            },
            {
                "label": "TILES.OBJEKT_EINHEIT.SWITCH2",
                "value": "Gewerbeflaeche",
                "parent": "unitType"
            },
        ]
    },
    "BAUWEISE": {
        "items": [
            {
                "label": "TILES.BAUWEISE.SWITCH1",
                "value": "UNTERKELLERT",
                "parent": "keller"
            },
            {
                "label": "TILES.BAUWEISE.SWITCH2",
                "value": "TEILWEISE_UNTERKELLERT",
                "parent": "keller"
            },
            {
                "label": "TILES.BAUWEISE.SWITCH3",
                "value": "NICHT_UNTERKELLERT",
                "parent": "keller"
            },
        ]
    },
    "BAUWEISE_DACH": {
        "items": [
            {
                "label": "TILES.BAUWEISE.SWITCH4",
                "value": "VOLL_AUSGEBAUT",
                "parent": "dach"
            },
            {
                "label": "TILES.BAUWEISE.SWITCH5",
                "value": "TEILWEISE_AUSGEBAUT",
                "parent": "dach"
            },
            {
                "label": "TILES.BAUWEISE.SWITCH6",
                "value": "NICHT_AUSGEBAUT",
                "parent": "dach"
            },
            {
                "label": "TILES.BAUWEISE.SWITCH7",
                "value": "FLACHDACH",
                "parent": "dach"
            },
        ]
    },
    "ENERGY_TYPE": {
        "items": [
            {
                "label": "TILES.ENERGY.SWITCH1",
                "value": "ENDENERGIEBEDARF",
                "parent": "energyType"
            },
            {
                "label": "TILES.ENERGY.SWITCH2",
                "value": "ENDENERGIEVERBRAUCH",
                "parent": "energyType"
            }
        ]
    },
    "ENERGY": {
        "items": [
            {
                "label": "A +",
                "value": "A_PLUS",
                "parent": "energyClass"
            },
            {
                "label": "A",
                "value": "A",
                "parent": "energyClass"
            },
            {
                "label": "B",
                "value": "B",
                "parent": "energyClass"
            },
            {
                "label": "C",
                "value": "C",
                "parent": "energyClass"
            },
            {
                "label": "D",
                "value": "D",
                "parent": "energyClass"
            },
            {
                "label": "E",
                "value": "E",
                "parent": "energyClass"
            },
            {
                "label": "F",
                "value": "F",
                "parent": "energyClass"
            },
            {
                "label": "G",
                "value": "G",
                "parent": "energyClass"
            },
            {
                "label": "H",
                "value": "H",
                "parent": "energyClass"
            },
        ]
    },
    "ERBBAURECHT": {
        "items": [
            {
                "label": "TILES.ERBBAURECHT.SWITCH1",
                "value": "OEFFENTLICH_KIRCHLICH",
                "parent": "ownerType"
            },
            {
                "label": "TILES.ERBBAURECHT.SWITCH2",
                "value": "ANDERE",
                "parent": "ownerType"
            },
        ]
    },
    "RENTE": {
        "items": [
            {
                "label": "TILES.PERSONAL.SWITCH1",
                "value": "Altersrente",
                "parent": "typRente"
            },
            {
                "label": "TILES.PERSONAL.SWITCH2",
                "value": "Erwerbsminderungsrente",
                "parent": "typRente"
            },
        ]
    },
    "PRIVATKREDIT": {
        "items": [
            {
                "label": "TILES.PRIVATKREDIT.SWITCH1",
                "value": "ratenkredite",
                "parent": "typKredit"
            },
            {
                "label": "TILES.PRIVATKREDIT.SWITCH2",
                "value": "leasing",
                "parent": "typKredit"
            },
            {
                "label": "TILES.PRIVATKREDIT.SWITCH3",
                "value": "privatdarlehen",
                "parent": "typKredit"
            },
            {
                "label": "TILES.PRIVATKREDIT.SWITCH4",
                "value": "sonstigeVerbindlichkeit",
                "parent": "typKredit"
            },
        ]
    },
    "MIETE_HEALTH": {
        "items": [
            {
                "label": "N° 1",
                "name": "mieteHealth",
                "state": "warning",
                "value": "number1",
                "checked": true
            },
            {
                "label": "N° 2",
                "name": "mieteHealth",
                "state": "warning",
                "value": "number2",
                "checked": false
            }
        ]
    },
    "UMSCHULDUNG": {
        "items": [
            {
                "label": "TILES.UMSCHULDUNG.SWITCH1",
                "value": "Umschuldung",
                "parent": "umschuldung"
            },
            {
                "label": "TILES.UMSCHULDUNG.SWITCH2",
                "value": "KeineUmschuldung",
                "parent": "umschuldung"
            }
        ]
    },
    "PROPERTY_CHANGE": {
        "items": [
            {
                "label": "TILES.PROPERTY_CHANGE.SWITCH1",
                "value": "KEIN_EINSATZ",
                "parent": "propertyChange"
            },
            {
                "label": "TILES.PROPERTY_CHANGE.SWITCH2",
                "value": "VERKAUFEN",
                "parent": "propertyChange"
            },
            {
                "label": "TILES.PROPERTY_CHANGE.SWITCH3",
                "value": "VERMIETEN",
                "parent": "propertyChange"
            }
        ]
    },
    "PROPERTY_CHANGE_MF_NO_WOHNEN": {
        "items": [
            {
                "label": "TILES.PROPERTY_CHANGE_MF_NO.WOHNEN.SWITCH1",
                "value": "EIGENGENUTZT",
                "parent": "propertyChange"
            },
            {
                "label": "TILES.PROPERTY_CHANGE_MF_NO.WOHNEN.SWITCH2",
                "value": "TEILVERMIETET",
                "parent": "propertyChange"
            },
            {
                "label": "TILES.PROPERTY_CHANGE_MF_NO.WOHNEN.SWITCH3",
                "value": "VERMIETET",
                "parent": "propertyChange"
            }
        ]
    },
    "PROPERTY_CHANGE_MF_NO_GEWERBE": {
        "items": [
            {
                "label": "TILES.PROPERTY_CHANGE_MF_NO.GEWERBE.SWITCH1",
                "value": "EIGENGENUTZT",
                "parent": "propertyChange"
            },
            {
                "label": "TILES.PROPERTY_CHANGE_MF_NO.GEWERBE.SWITCH2",
                "value": "TEILVERMIETET",
                "parent": "propertyChange"
            },
            {
                "label": "TILES.PROPERTY_CHANGE_MF_NO.GEWERBE.SWITCH3",
                "value": "VERMIETET",
                "parent": "propertyChange"
            }
        ]
    },
    "PROPERTY_CHANGE_MF_YES": {
        "items": [
            {
                "label": "TILES.PROPERTY_CHANGE_MF_YES.SWITCH1",
                "value": "EIGENGENUTZT",
                "parent": "propertyChange"
            },
            {
                "label": "TILES.PROPERTY_CHANGE_MF_YES.SWITCH2",
                "value": "TEILVERMIETET",
                "parent": "propertyChange"
            },
            {
                "label": "TILES.PROPERTY_CHANGE_MF_YES.SWITCH3",
                "value": "VERMIETET",
                "parent": "propertyChange"
            }
        ]
    },
    "VERWENDUNG": {
        "items": [
            {
                "label": "TILES.VERWENDUNG.SWITCH1",
                "value": "AUFLOESUNG_ALS_VERWENDUNG",
                "parent": "verwendung"
            },
            {
                "label": "TILES.VERWENDUNG.SWITCH2",
                "value": "ABTRETEN_ALS_VERWENDUNG",
                "parent": "verwendung"
            },
            {
                "label": "TILES.VERWENDUNG.SWITCH3",
                "value": "KEINE_VERWENDUNG",
                "parent": "verwendung"
            }
        ]
    },
    "GENDER": {
        "items": [
            {
                "label": "COMMON.FRAU",
                "value": "FRAU",
                "parent": "gender"
            },
            {
                "label": "COMMON.HERR",
                "value": "MANN",
                "parent": "gender"
            },
            {
                "label": "COMMON.DIVERS",
                "value": "DIVERS",
                "parent": "gender"
            }
        ]
    },
    "ZUSTELL": {
        "items": [
            {
                "label": "UEBERGABE_A.FORM.SCREEN1.SWITCHES.SWITCH1",
                "value": "Verkäufer",
                "parent": "sendTo"
            },
            {
                "label": "UEBERGABE_A.FORM.SCREEN1.SWITCHES.SWITCH2",
                "value": "Makler",
                "parent": "sendTo"
            }
        ]
    },
    "ZINSBINDUNG": {
        "items": [
            {
                "label": "5",
                "value": "5",
                "parent": "zinsbindung"
            },
            {
                "label": "10",
                "value": "10",
                "parent": "zinsbindung"
            },
            {
                "label": "15",
                "value": "15",
                "parent": "zinsbindung"
            },
            {
                "label": "FINANCE_ASSEMBLY.BAUSTEINE.ZINSBINDUNG.LABEL",
                "value": "other",
                "parent": "zinsbindung"
            }
        ]
    },
    "SONDER": {
        "items": [
            {
                "label": "5%",
                "value": "5",
                "parent": "sondertilgung"
            },
            {
                "label": "10%",
                "value": "10",
                "parent": "sondertilgung"
            },
            {
                "label": "FINANCE_ASSEMBLY.BAUSTEINE.SONDERTILGUNG.LABEL",
                "value": "other",
                "parent": "sondertilgung"
            }
        ]
    },
    "ZINSFREI": {
        "items": [
            {
                "label": "3",
                "value": "3",
                "parent": "zinsfrei"
            },
            {
                "label": "6",
                "value": "6",
                "parent": "zinsfrei"
            },
            {
                "label": "12",
                "value": "12",
                "parent": "zinsfrei"
            },
            {
                "label": "FINANCE_ASSEMBLY.BAUSTEINE.MONATE.LABEL",
                "value": "other",
                "parent": "zinsfrei"
            }
        ]
    },
    "LAUFZEIT": {
        "items": [
            {
                "label": "25",
                "value": "25",
                "parent": "laufzeit"
            },
            {
                "label": "30",
                "value": "30",
                "parent": "laufzeit"
            },
            {
                "label": "35",
                "value": "35",
                "parent": "laufzeit"
            },
            {
                "label": "FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.LABEL",
                "value": "other",
                "parent": "laufzeit"
            }
        ]
    },
    "LAUFZEIT_M": {
        "items": [
            {
                "label": "120",
                "value": "120",
                "parent": "laufzeitM"
            },
            {
                "label": "180",
                "value": "180",
                "parent": "laufzeitM"
            },
            {
                "label": "240",
                "value": "240",
                "parent": "laufzeitM"
            },
            {
                "label": "FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.LABEL",
                "value": "other",
                "parent": "laufzeitM"
            }
        ]
    },
    "LAUFZEIT_Z": {
        "items": [
            {
                "label": "6",
                "value": "6",
                "parent": "laufzeitZ"
            },
            {
                "label": "12",
                "value": "12",
                "parent": "laufzeitZ"
            },
            {
                "label": "24",
                "value": "24",
                "parent": "laufzeitZ"
            },
            {
                "label": "FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.LABEL",
                "value": "other",
                "parent": "laufzeitZ"
            }
        ]
    },
    "ZINSBINDUNG_KFW": {
        "items": [
            {
                "label": "5",
                "value": "5",
                "parent": "zinsbindungKfw"
            },
            {
                "label": "10",
                "value": "10",
                "parent": "zinsbindungKfw"
            },
        ]
    },
    "ANLAUF": {
        "items": [
            {
                "label": "1",
                "value": "1",
                "parent": "anlauf"
            },
            {
                "label": "2",
                "value": "2",
                "parent": "anlauf"
            },
            {
                "label": "3",
                "value": "3",
                "parent": "anlauf"
            },
            {
                "label": "4",
                "value": "4",
                "parent": "anlauf"
            },
            {
                "label": "5",
                "value": "5",
                "parent": "anlauf"
            }
        ]
    },
    "ZINSBINDUNG_PREF": {
        "items": [
            // {
            //     "label": "FINANCE_PREFERENCES.TILES.TILE1.SWITCHES.SWITCH1",
            //     "value": "5",
            //     "parent": "zinsbindungPref"
            // },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE1.SWITCHES.SWITCH2",
                "value": "kurz",
                "parent": "zinsbindungPref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE1.SWITCHES.SWITCH3",
                "value": "mittel",
                "parent": "zinsbindungPref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE1.SWITCHES.SWITCH4",
                "value": "lang",
                "parent": "zinsbindungPref"
            },
            // {
            //     "label": "FINANCE_PREFERENCES.TILES.TILE1.SWITCHES.SWITCH5",
            //     "value": "25",
            //     "parent": "zinsbindungPref"
            // },
        ]
    },
    "RATE_PREF": {
        "items": [
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE2.SWITCHES.SWITCH1",
                "value": "niedrig",
                "parent": "ratePref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE2.SWITCHES.SWITCH2",
                "value": "mittel",
                "parent": "ratePref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE2.SWITCHES.SWITCH3",
                "value": "hoch",
                "parent": "ratePref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE2.SWITCHES.SWITCH4",
                "value": "4",
                "parent": "ratePref"
            },
        ]
    },
    "AUFBAU_PREF": {
        "items": [
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE3.SWITCHES.SWITCH1",
                "value": "default",
                "parent": "aufbauPref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE3.SWITCHES.SWITCH2",
                "value": "enable_extended_loan_types",
                "parent": "aufbauPref"
            }
        ]
    },
    "SONDERTILGUNG_PREF": {
        "items": [
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE4.SWITCHES.SWITCH1",
                "value": "5",
                "parent": "sondertilgungPref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE4.SWITCHES.SWITCH2",
                "value": "5",
                "parent": "sondertilgungPref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE4.SWITCHES.SWITCH3",
                "value": "10",
                "parent": "sondertilgungPref"
            }
        ]
    },
    "LAUFZEIT_PREF": {
        "items": [
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE5.SWITCHES.SWITCH1",
                "value": "1",
                "parent": "laufzeitPref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE5.SWITCHES.SWITCH2",
                "value": "2",
                "parent": "laufzeitPref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE5.SWITCHES.SWITCH3",
                "value": "3",
                "parent": "laufzeitPref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE5.SWITCHES.SWITCH4",
                "value": "4",
                "parent": "laufzeitPref"
            },
        ]
    },
    "BEWILLIGUNG_PREF": {
        "items": [
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE7.SWITCHES.SWITCH1",
                "value": "1",
                "parent": "bewilligungPref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE7.SWITCHES.SWITCH2",
                "value": "3",
                "parent": "bewilligungPref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE7.SWITCHES.SWITCH3",
                "value": "10",
                "parent": "bewilligungPref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE7.SWITCHES.SWITCH4",
                "value": "none",
                "parent": "bewilligungPref"
            },
        ]
    },
    "AUSZAHLUNG_PREF": {
        "items": [
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE8.SWITCHES.SWITCH1",
                "value": "3",
                "parent": "auszahlungPref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE8.SWITCHES.SWITCH2",
                "value": "6",
                "parent": "auszahlungPref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE8.SWITCHES.SWITCH3",
                "value": "12",
                "parent": "auszahlungPref"
            },
            {
                "label": "FINANCE_PREFERENCES.TILES.TILE8.SWITCHES.SWITCH4",
                "value": "24",
                "parent": "auszahlungPref"
            },
        ]
    },
    "DARLEHEN_FORWARD": {
        "items": [
            {
                "label": "FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.SWITCH.TEXT1",
                "value": "BESTEHENDES_IMMOBILIENDARLEHEN",
                "parent": "darlehenType"
            },
            {
                "label": "FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.SWITCH.TEXT2",
                "value": "BESTEHENDES_BAUSPARDARLEHEN",
                "parent": "darlehenType"
            }
        ]
    }
}