<div class="da-loading-screen" *ngIf="loading">
    <div class="da-loading-bar">
        <div 
            class="da-loading-bar-value"
            [ngStyle]="{'width.%': percentageValue}">
        </div>
    </div>
    <!-- <mat-progress-spinner mode="indeterminate" diameter="150" class="da-start-loader"
        [ngStyle]="{'stroke': '#D2D2D2'}"></mat-progress-spinner> -->
</div>
<ng-container *ngIf="!loading">
<!-- <ng-container> -->
    <div *ngIf="!showLoginPage" class="da-master">
        <app-frame [webapp]="environment.daAsWebApp" [webappType]="environment.webappType"></app-frame>
    </div>
    
    <div *ngIf="showLoginPage">
        <router-outlet></router-outlet>
    </div>
</ng-container>
<div #style></div>