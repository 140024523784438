<div class="da-lightbox" [ngClass]="{'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet()}">
    <div class="da-close-icon" [ngClass]="{'mobile' : screen.isSmallest()}" (click)="dismiss()">
        <svg-icon
            src="assets/images/chevronRight.svg"
        >
        </svg-icon>
    </div>
    <div class="da-modal-body">
        <div class="da-header-icon">
            <svg-icon
                src="assets/images/coins.svg"
            >
            </svg-icon>
        </div>
        <lib-header-text
            class="da-lightbox-header"
            size="38px"
            [convertMobile]="true"
            [light]="true"
            label="{{'NEBENKOSTEN.TITLE' | translate}}"
        ></lib-header-text>
        <div class="da-lightbox-content">
            <div class="da-content-wrapper">
                <!-- Grunderwerbssteuer -->
                <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }" *ngIf="!noTax">
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'NEBENKOSTEN.TEXT1' | translate}}"
                        [light]="true"
                        size="20px"
                    ></lib-tile-text>
                    <div class="da-content-row-right">

                        <div class="small">
                            <lib-dropdown *ngIf="!contentService.getValue('steuerprozent')"
                                #steuerprozent
                                name="steuerprozent"
                                [selectItems]="steuerItems" 
                                [selectedItem]="selectedSteuerItem"
                                label=""
                                (selectValue)="getSelectedValue($event, steuerprozent)"
                                [autoWidth]="true">
                            </lib-dropdown>
                            <lib-input *ngIf="!!contentService.getValue('steuerprozent')"
                                #steuerprozent
                                label=""
                                name="steuerprozent"
                                [error]="false"
                                placeholder="5,5"
                                errorText="{{'COMMON.ERRORS.PERCENT' | translate}}"
                                [touched]="!!contentService.getValue('steuerprozent')"
                                [value]="contentService.getValue('steuerprozent')"
                                (keyup)="validateInput($event, 'steuerprozent')"
                                [isCurrency]="false"
                                unit="%"
                                [autoWidth]="true"
                                [light]="true"
                            ></lib-input>
                        </div>
                        <svg-icon class="da-container-icon" src="assets/images/equals.svg"></svg-icon>
                        <div>
                            <lib-input
                                #steuerbetrag
                                label=""
                                name="steuerbetrag"
                                [error]="false"
                                placeholder="0,00"
                                errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                                [touched]="!!contentService.getValue('steuerbetrag')"
                                [value]="contentService.getValue('steuerbetrag')"
                                (inputValue)="validateInput($event, 'steuerbetrag')"
                                unit="€"
                                [autoWidth]="true"
                                [light]="true"
                            ></lib-input>
                        </div>
                    </div>
                </div>
                <!-- notarkosten -->
                <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'NEBENKOSTEN.TEXT2' | translate}}"
                        [light]="true"
                        size="20px"
                    ></lib-tile-text>
                    <div class="da-content-row-right">
                        <div class="small">
                            <lib-input
                                #notarprozent
                                label=""
                                name="notarprozent"
                                [error]="false"
                                placeholder="5,5"
                                errorText="{{'COMMON.ERRORS.PERCENT' | translate}}"
                                [touched]="!!contentService.getValue('notarprozent')"
                                [value]="!!contentService.getValue('notarprozent') ? contentService.getValue('notarprozent') : '2,0'"
                                (keyup)="validateInput($event, 'notarprozent')"
                                [isCurrency]="false"
                                unit="%"
                                [autoWidth]="true"
                                [light]="true"
                            ></lib-input>
                        </div>
                        <svg-icon class="da-container-icon" src="assets/images/equals.svg"></svg-icon>
                        <div>
                            <lib-input
                                #notarbetrag
                                label=""
                                name="notarbetrag"
                                [error]="false"
                                placeholder="0,00"
                                errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                                [touched]="!!contentService.getValue('notarbetrag')"
                                [value]="contentService.getValue('notarbetrag')"
                                (inputValue)="validateInput($event, 'notarbetrag')"
                                unit="€"
                                [autoWidth]="true"
                                [light]="true"
                            ></lib-input>
                        </div>
                    </div>
                </div>
                <!-- provision -->
                <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'NEBENKOSTEN.TEXT3' | translate}}"
                        [light]="true"
                        size="20px"
                    ></lib-tile-text>
                    <div class="da-content-row-right">
                        <div class="small">
                            <lib-input
                                #maklerprozent
                                label=""
                                name="maklerprozent"
                                [error]="false"
                                placeholder="5,5"
                                errorText="{{'COMMON.ERRORS.PERCENT' | translate}}"
                                [touched]="!!contentService.getValue('maklerprozent')"
                                [value]="!!contentService.getValue('maklerprozent') ? contentService.getValue('maklerprozent') : '3,57'"
                                (keyup)="validateInput($event, 'maklerprozent')"
                                [isCurrency]="false"
                                unit="%"
                                [autoWidth]="true"
                                [light]="true"
                            ></lib-input>
                        </div>
                        <svg-icon class="da-container-icon" src="assets/images/equals.svg"></svg-icon>
                        <div>
                            <lib-input
                                #maklerbetrag
                                label=""
                                name="maklerbetrag"
                                [error]="false"
                                placeholder="0,00"
                                errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                                [touched]="!!contentService.getValue('maklerbetrag')"
                                [value]="contentService.getValue('maklerbetrag')"
                                (inputValue)="validateInput($event, 'maklerbetrag')"
                                unit="€"
                                [autoWidth]="true"
                                [light]="true"
                            ></lib-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="da-content-wrapper" *ngIf="hasVerbindlichkeiten">
                <!-- verbindlichkeiten -->
                <lib-tile-text
                    class="da-content-headline"
                    label="{{'NEBENKOSTEN.TEXT4' | translate}}"
                    [light]="true"
                    size="20px"
                    [ngStyle]="{'margin-left': '20px'}"
                ></lib-tile-text>
                <ng-container *ngFor="let item of contentService.getValue('verbindlichkeiten', true); let num = index">
                    <div class="da-content-wrapper-row" 
                    [ngClass]="{ 'mobile' : screen.isSmallest() }" *ngIf="setLocalValue(num, 'verbindlichkeitTyp') !== 'leasing'">
                        <lib-tile-text
                            class="da-lightbox-content-headline"
                            label="{{'NEBENKOSTEN.TEXT5' | translate: {name: item.name} }}"
                            [light]="true"
                            size="16px"
                        ></lib-tile-text>
                        <div class="da-content-row-right verbindlichkeit">
                            <div>
                                <lib-toggle
                                    #abloesen
                                    label1="{{'COMMON.TRUE' | translate}}"
                                    label2="{{'COMMON.FALSE' | translate}}"
                                    name="abloesen{{num}}"
                                    value1="true"
                                    value2="false"
                                    [localValue]="setLocalValue(num, 'abloesen')"
                                    (switchValue)="validateInput($event, 'abloesen', num)"
                                ></lib-toggle>
                            </div>
                            <svg-icon class="da-container-icon" src="assets/images/equals.svg"></svg-icon>
                            <div class="da-fixed-width-input">
                                <lib-input
                                    #abloesenSumme
                                    label=""
                                    name="abloesenSumme{{num}}"
                                    [error]="false"
                                    placeholder="0,00"
                                    [touched]="!!setLocalValue(num, 'restschuld')"
                                    [value]="!!setLocalValue(num, 'abloesen') && setLocalValue(num, 'abloesen') === 'true' ? setLocalValue(num, 'restschuld') : '0,00'"
                                    unit="€"
                                    [inputDisabled]="true"
                                    [autoWidth]="true"
                                    [light]="true"
                                ></lib-input>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="da-content-wrapper">
                <!-- Gesamt -->
                <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'NEBENKOSTEN.TEXT6' | translate}}"
                        [light]="true"
                        size="20px"
                    ></lib-tile-text>
                    <div class="da-content-row-right">
                        <div class="small" *ngIf="!screen.isSmallest()">
                        </div>
                        <svg-icon class="da-container-icon" src="assets/images/equals.svg" *ngIf="!screen.isSmallest()"></svg-icon>
                        <div>
                            <lib-input
                                #gesamtbetrag
                                label=""
                                name="gesamtbetrag"
                                [error]="false"
                                placeholder="0,00"
                                [touched]="!!contentService.getValue('nebenkosten')"
                                [value]="contentService.getValue('nebenkosten')"
                                unit="€"
                                [inputDisabled]="true"
                                [autoWidth]="!screen.isSmallest() ? true : false"
                                [light]="true"
                            ></lib-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="da-address-button-wrapper" *ngIf="showButtons">
            <div class="da-sidebar-button-bg">
                <lib-button 
                    name="nebenkosten button"
                    value="{{'NEBENKOSTEN.BUTTON' | translate}}" 
                    [disabled]="!inputValid"
                    [light]="true"
                    width="230"
                    (buttonValue)="finishInput()"
                    iconRight="assets/images/disk.svg"
                ></lib-button>
            </div>
        </div>
    </div>
</div>
