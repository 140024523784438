import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-light-save-loggedin',
  templateUrl: './login-light-save-loggedin.component.html',
  styleUrls: ['./login-light-save-loggedin.component.scss']
})
export class LoginLightSaveLoggedinComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
