import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-login-light-info',
  templateUrl: './login-light-info.component.html',
  styleUrls: ['./login-light-info.component.scss', '../login-light-sidebar.component.scss']
})
export class LoginLightInfoComponent implements OnInit {

  constructor(private screen: LayoutService) { }

  infoList: { icon: string, height: number, text: string }[] = [
    {
      icon: 'linkSharpSolid',
      height: 20,
      text: 'INFO_1'
    },
    {
      icon: 'percentSolid',
      height: 28,
      text: 'INFO_2'
    },
    {
      icon: 'screwdriverWrenchSolid',
      height: 24,
      text: 'INFO_3'
    },
    {
      icon: 'timerSolid',
      height: 24,
      text: 'INFO_4'
    }
  ];

  ngOnInit(): void {
  }

  isMobile() {
    return this.screen.isMobile();
  }

  isMobileOrSmallTablet() {
    return this.screen.isMobileOrSmallTablet();
  }
}
