 <div class="da-forward-darlehen-wrapper">
    <lib-state-icon [state]="containerState" [ngStyle]="{'left': '0', 'top': '0', 'position': 'absolute'}"></lib-state-icon>
    <lib-header-text
        class="da-title"
        label="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.HEADER' | translate: {count: count} }}"
        size="38px"
        [convertMobile]="true"
    ></lib-header-text>
    <button class="da-forward-arrow" [ngClass]="{'mobile': screen.isSmallest()}" (click)="isVisible = !isVisible">
        <svg-icon src="assets/images/chevronUp.svg" *ngIf="isVisible"></svg-icon>
        <svg-icon src="assets/images/chevronDown.svg" *ngIf="!isVisible"></svg-icon>
    </button>
    <ng-container *ngIf="isVisible">
        <div class="da-forward-darlehen-row toggle">
            <lib-toggle-horizontal
                *ngIf="!screen.isSmallest()"
                #darlehenType
                [options]="darlehenItems"
                name="darlehenType{{count}}"
                [localValue]="setLocalValue(count, 'darlehenType')"
                elementWidth="400px"
                (switchValue)="setSwitch($event, darlehenType )"
            ></lib-toggle-horizontal>
            <lib-toggle-vertical
                *ngIf="screen.isSmallest()"
                #darlehenType
                [options]="darlehenItems"
                name="darlehenType{{count}}"
                [multiLine]="true"
                [localValue]="setLocalValue(count, 'darlehenType')"
                (switchValue)="setSwitch($event, darlehenType)"
            ></lib-toggle-vertical>
        </div>
        <!-- Ablösedate -->
        <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}">
            <div class="da-forward-darlehen-row-part left">
                <lib-tile-text
                    class="da-title"
                    [ngClass]="{'more-margin-top': !screen.isSmallest()}"
                    label="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.LABELS.LABEL1' | translate }}"
                    size="16px"
                    info="true"
                    (infoValue)="screen.openInfo(dateInfo)"
                ></lib-tile-text>
            </div>
            <div class="da-forward-darlehen-row-part">
                <lib-input-date
                    #abloesedate
                    class="less-margin-bottom"
                    label="{{'COMMON.LABELS.DATE' | translate}}"
                    name="abloesedate{{count}}"
                    [value]="setLocalValue(count, 'abloesedate')"
                    [touched]="!!setLocalValue(count, 'abloesedate') || abloesedate.touched "
                    [error]="false"
                    [maxDate]="maxDate"
                    [minDate]="minDate"
                    (click)="testValid = true"
                    errorText="{{'COMMON.ERRORS.DATE' | translate}}"
                    placeholder="tt.mm.jjjj"
                    (dateValue)="validateDate($event, abloesedate)"
                    (dateInputValue)="validateDate($event, abloesedate, true)"
                ></lib-input-date>
            </div>
        </div>
        <!-- Zinsbindung -->
        <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}">
            <div class="da-forward-darlehen-row-part left">
                <lib-tile-text
                    class="da-title"
                    label="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.LABELS.LABEL2' | translate }}"
                    size="16px"
                    info="true"
                    (infoValue)="screen.openInfo(restschuldInfo)"
                ></lib-tile-text>
            </div>
            <div class="da-forward-darlehen-row-part">
                <lib-toggle 
                    #restschuld
                    label1="{{'COMMON.TRUE' | translate}}"
                    label2="{{'COMMON.FALSE' | translate}}"
                    value1="restschuldVorhanden"
                    value2="keineRestschuld"
                    name="restschuldVorhanden{{count}}"
                    [localValue]="setLocalValue(count, 'restschuld')"
                    (switchValue)="setSwitch($event, restschuld, noRestschuldInfo)"
                ></lib-toggle>
            </div>
        </div>
        <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}" *ngIf="setLocalValue(count, 'restschuld') === 'restschuldVorhanden'">
            <div class="da-forward-darlehen-row-part left">
            </div>
            <div class="da-forward-darlehen-row-part">
                <lib-input
                    #restschuldBetrag
                    #inputs
                    label="{{'COMMON.LABELS.SUMME' | translate}}"
                    name="restschuldBetrag{{count}}"
                    [error]="false"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [touched]="!!setLocalValue(count, 'restschuldBetrag')"
                    [value]="setLocalValue(count, 'restschuldBetrag')"
                    (change)="validateInput($event, restschuldBetrag, sparenBetrag)"
                ></lib-input>
            </div>
        </div>
        <!-- Sparbetrag -->
        <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}" *ngIf="setLocalValue(count, 'darlehenType') === 'BESTEHENDES_BAUSPARDARLEHEN'">
            <div class="da-forward-darlehen-row-part left">
                <lib-tile-text
                    class="da-title more-margin-top"
                    label="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.LABELS.LABEL6' | translate }}"
                    size="16px"
                    info="true"
                    (infoValue)="screen.openInfo(sparenInfo)"
                ></lib-tile-text>
            </div>
            <div class="da-forward-darlehen-row-part">
                <lib-input
                    #sparenBetrag
                    #inputs
                    label="{{'COMMON.LABELS.SUMME' | translate}}"
                    name="sparenBetrag{{count}}"
                    [error]="false"
                    errorText="{{errorSparen | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [touched]="!!setLocalValue(count, 'sparenBetrag')"
                    [value]="setLocalValue(count, 'sparenBetrag')"
                    (change)="validateInput($event, sparenBetrag)"
                ></lib-input>
            </div>
        </div>
        <!-- Tilgung -->
        <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}">
            <div class="da-forward-darlehen-row-part left">
                <lib-tile-text
                    class="da-title"
                    [ngClass]="{'more-margin-top': !screen.isSmallest()}"
                    label="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.LABELS.LABEL3' | translate }}"
                    size="16px"
                    info="true"
                    (infoValue)="screen.openInfo(rundungInfo)"
                ></lib-tile-text>
            </div>
            <div class="da-forward-darlehen-row-part" [ngClass]="{'mobile': screen.isSmallest()}">
                <lib-input
                    #tilgungBetrag
                    #inputs
                    label="{{'COMMON.LABELS.SUMME' | translate}}"
                    name="tilgungBetrag{{count}}"
                    [error]="false"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [touched]="!!setLocalValue(count, 'tilgungBetrag')"
                    [value]="setLocalValue(count, 'tilgungBetrag')"
                    (change)="validateInput($event, tilgungBetrag)"
                ></lib-input>
            </div>
        </div>
        <!-- Abloesen -->
        <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}">
            <div class="da-forward-darlehen-row-part left">
                <lib-tile-text
                    class="da-title"
                    label="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.LABELS.LABEL4' | translate }}"
                    size="16px"
                    info="true"
                    (infoValue)="screen.openInfo(abloeseInfo)"
                ></lib-tile-text>
            </div>
            <div class="da-forward-darlehen-row-part">
                <lib-toggle 
                    #abloesen
                    label1="{{'COMMON.TRUE' | translate}}"
                    label2="{{'COMMON.FALSE' | translate}}"
                    value1="abloesenJa"
                    value2="abloesenNein"
                    name="abloesen{{count}}"
                    [localValue]="setLocalValue(count, 'abloesen')"
                    (switchValue)="setSwitch($event, abloesen)"
                ></lib-toggle>
            </div>
        </div>
        <!-- Abloesenbetrag -->
        <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}">
            <div class="da-forward-darlehen-row-part left">
                <lib-tile-text
                    class="da-title"
                    label="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.LABELS.LABEL5' | translate }}"
                    size="16px"
                    info="true"
                    (infoValue)="screen.openInfo(betragInfo)"
                ></lib-tile-text>
            </div>
            <div class="da-forward-darlehen-row-part">
                <lib-input
                    #darlehenBetrag
                    #inputs
                    label=""
                    name="darlehenBetrag{{count}}"
                    [error]="false"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [inputDisabled]="true"
                    [touched]="false"
                    [value]="setLocalValue(count, 'darlehenBetrag')"
                ></lib-input>
            </div>
        </div>
    </ng-container>
</div>
<ng-template #dateInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.INFOS.HEADER1' | translate}}" text="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.INFOS.TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #restschuldInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.INFOS.HEADER2' | translate}}" text="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.INFOS.TEXT2' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #rundungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.INFOS.HEADER3' | translate}}" text="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.INFOS.TEXT3' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #abloeseInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.INFOS.HEADER4' | translate}}" text="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.INFOS.TEXT4' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #betragInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.INFOS.HEADER5' | translate}}" text="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.INFOS.TEXT5' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #sparenInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.INFOS.HEADER6' | translate}}" text="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.INFOS.TEXT6' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #noRestschuldInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.INFOS.HEADER7' | translate}}" text="{{'FINANCE_REQUIREMENTS_FORWARD.DARLEHEN.INFOS.TEXT7' | translate}}"></app-info-sidebar>
</ng-template>