import { AfterViewInit, Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';

@Component({
  selector: 'app-address-sidebar',
  templateUrl: './address-sidebar.component.html',
  styleUrls: ['./address-sidebar.component.scss']
})
export class AddressSidebarComponent implements AfterViewInit {

  plzDisabled: boolean = false;
  cityDisabled: boolean = false;
  currentAddress: any = { 'plz': '', 'ort': '', 'strasse': '', 'hausnummer': '' };
  inputValid: boolean = false;

  @Input() num!: number;
  @Input() text!: string;
  @Input() modal!: NgbModalRef;

  @Output() public adressDone: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('objectPlz') objectPlz!: any;
  @ViewChild('objectOrt') objectOrt!: any;
  @ViewChild('objectStrasse') objectStrasse!: any;
  @ViewChild('objectNr') objectNr!: any;

  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private daService: DataService,
    private dataQuery: DataQuery,
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngAfterViewInit(): void {
    this.getStatus();
  }

  getStatus() {
    setTimeout(() => {
      const status = this.dataQuery.getEntity(1)?.['bestehendeImmobilien'];

      if (!!status) {
        this.currentAddress = this.contentService.getNestedValue('bestehendeImmobilien', 'adresse', this.num) !== '' ? this.contentService.getNestedValue('bestehendeImmobilien', 'adresse', this.num) : this.currentAddress;
        this.objectPlz.value = this.currentAddress.plz;
        this.objectOrt.value = this.currentAddress.ort;
        this.objectStrasse.value = this.currentAddress.strasse;
        this.objectNr.value = this.currentAddress.hausnummer;
      }

      this.setDisabled();
    }, 100);
  }

  getPlz(value: any, element: any) {
    element.touched = false;
    element.error = false;
    this.objectPlz.value = value.target ? value.target.value : value;
    const isValid = this.contentService.minMaxLength(this.objectPlz.value, 5, 5);
    if (isValid === true) {
      this.contentService.getByPlz(this.objectPlz.value).then((result: any) => {
        result.subscribe({
          next: (data: any) => {
            element.touched = true;
            if (data[0]) {
              this.objectOrt.value = data[0].name;
              this.objectOrt.touched = true;
              this.objectOrt.error = false;
              element.error = false;
              setTimeout(() => { this.cityDisabled = false; }, 500);
              this.storeInputValue(this.objectPlz);
              this.storeInputValue(this.objectOrt);
            } else {
              element.error = true;
              this.objectOrt.touched = false;
            }
          },
          error: (error: any) => console.log(error)
        })
      });
      return;
    }

    if (isValid?.tooLong) {
      element.touched = true;
      element.error = true;
    }
  }

  getCity(value: any, element: any) {
    setTimeout(() => {
      element.touched = !!value.target.value ? true : false;
      this.objectOrt.error = false;
      this.objectOrt.value = value.target ? value.target.value : value;
      const isValid = this.contentService.validatePattern(value.target.value, element.validationType);

      if (isValid === true && !!value.target.value) {
        this.storeInputValue(this.objectOrt);
      } else {
        element.error = true;
        element.touched = true;
      }
    }, 100);
  }

  validateInputValue(e: any, element: any) {
    const value = e.target ? e.target.value : e;
    element.touched = value ? true : false;
    element.value = value;
    const isValid = this.contentService.validatePattern(value, element.validationType);
    element.error = isValid && value.length > 0 ? false : true;

    this.storeInputValue(element);
  }

  finishAdressInput() {
    this.adressDone.emit(this.currentAddress);
    this.dismiss();
  }

  storeInputValue(element: any) {
    let newAdress = Object.assign({}, this.currentAddress);
    Object.keys(this.currentAddress).forEach(key => {
      if (key === element.name) {
        newAdress[key] = element.value;
      }
    });
    this.currentAddress = newAdress;
    this.setDisabled();
  }

  setDisabled() {
    this.inputValid = false;

    if((!!this.objectPlz.value && !!this.objectOrt.value && !!this.objectStrasse.value && !!this.objectNr.value) && 
        !this.objectPlz.error && !this.objectOrt.error && !this.objectStrasse.error && !this.objectNr.error) {
          this.inputValid = true;
        }
  }

  dismiss() {
    document.body.style.overflowY = 'auto';
    this.modal.dismiss();
  }
}
