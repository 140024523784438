<lib-header-text
    class="da-title"
    label="{{title | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>

<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
    >
    <ng-template #content>
        <lib-tile-text
            size="16px"
            label="{{'VEHICLES.TILE_TEXT' | translate}}"
            [light]="true"
        ></lib-tile-text>
        <lib-toggle
            #count
            label1="1"
            label2="2(+)"
            name="count"
            value1="one"
            value2="two"
            [localValue]="contentService.getValue('countFahrzeuge')"
            (switchValue)="setSwitch($event)"
            [light]="true"
        ></lib-toggle>
    </ng-template>
</lib-tile-group>
<div class="da-buttons-wrapper">
    <app-nav-buttons
        class="da-buttons-group"
        [save]="true"
        [nextDisabled]="daNextSlideService.getDisabled()"
        current="vehicles"
        [next]="next"
        (slideTo)="contentService.setNav($event, 'vehicles', slideTo)"
        >
    </app-nav-buttons>
</div>

