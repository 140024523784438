import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { switchesContent } from 'src/assets/content/switches';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-bauweise',
  templateUrl: './bauweise.component.html',
  styleUrls: ['./bauweise.component.scss']
})
export class BauweiseComponent implements OnInit {
  public slideName: string = 'bauweise';
  next: string = 'energy';
  localValue!: string;
  disabled: boolean = true;
  tiles = tilesContent['BAUWEISE'].items;
  switches = switchesContent['BAUWEISE'].items;
  switchesDach = switchesContent['BAUWEISE_DACH'].items;
  dach!: string;

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('content') content!: TemplateRef<any>;
  @ViewChild('objectAnzahlGeschosse') objectAnzahlGeschosse!: TemplateRef<any>;
  @ViewChild('objectDachgeschoss') objectDachgeschoss!: TemplateRef<any>;
  @ViewChild('objectKeller') objectKeller!: TemplateRef<any>;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public contentService: ContentService,
  ) {}

  ngOnInit(): void {
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.setStatus();
    });
  }
  
  setStatus() {
    this.disabled = true;
    const parent = this.dataQuery.getEntity(1)?.['objectBauweise'];
    const geschosse = this.dataQuery.getEntity(1)?.['objectAnzahlGeschosse'];

    if (!!parent) {
      this.localValue = parent;
      this.tiles = this.contentService.setTileChecked(this.tiles, parent);
      this.tiles = this.contentService.setTilesContent(this.tiles, parent, this.content);
    };
    setTimeout(() => {
      if(geschosse && geschosse > 0) {
        this.objectAnzahlGeschosse = this.contentService.validateReduxValue(geschosse, this.objectAnzahlGeschosse);
      } else {
        this.daService.update(1, { objectAnzahlGeschosse: 1 });
      }
    });
    this.setTileStatus();
  }

  setValue(e: string) { // Tiles (Bauweise)
    this.localValue = e;
    this.daService.update(1, { objectBauweise: e });
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
    this.contentService.setTilesContent(this.tiles, e, this.content);
    this.setTileStatus();
  }

  setSwitch(e: string, type: string) { 
    switch (type) {
      case 'dach': {
        this.daService.update(1, { objectDachgeschoss: e });
      }
      break;
      case 'geschosse': {
        this.daService.update(1, { objectAnzahlGeschosse: e });
      }
      break;
      case 'keller': {
        this.daService.update(1, { objectUnterkellerung: e });
      }
      break;
    }
    this.setTileStatus();
  }

  setTileStatus() {
    const status = this.dataQuery.getEntity(1);
    let valid = false;

    if(!!status) {
      if(this.contentService.getValue('objectType') !== 'EIGENTUMSWOHNUNG') {
        valid = (status['objectAnzahlGeschosse'] && status['objectAnzahlGeschosse'] > 0) && !!status['objectDachgeschoss'] && !!status['objectUnterkellerung'] ? true : false;
      } else {
        valid = (status['objectAnzahlGeschosse'] && status['objectAnzahlGeschosse'] > 0) ? true : false;
      }
    };

    if(valid) {
      this.tiles.map((tile: { value: string; state: string; }) => {
        tile.state = 'success'
      });
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.disabled = false;
    } else {
      this.tiles.map((tile: { value: string; state: string; }) => {
        tile.state = 'warning'
      });
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.disabled = true;
    }
    this.stateChange.emit(true);
    this.daNextSlideService.update(1, { disabled: this.disabled });
  }
}
