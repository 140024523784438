<div class="da-start-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
    <ng-container *ngIf="!screen.isSmallest()">
        <div class="da-start-image">
            <!-- <img src="{{grafik_start}}"/> -->
            <img alt="digitaler assistent startbild" [src]="grafik">
        </div>
        <div class="da-start-text">
            <div class="da-start-header">
                <lib-header-text
                    label="{{'START_A.TITLE' | translate}}"
                    size="38px"
                    class="da-start-header"
                ></lib-header-text>
            </div>
            <lib-tile-text
                label="{{'START_A.SUBTITLE' | translate}}"
                size="20px"
                info="true"
                (infoValue)="getInfo(startInfo)"
                [ngStyle]="{'text-align': 'left'}"
            >
            </lib-tile-text>
            <div class="da-start-button">
                <lib-button 
                    name="start button"
                    value="{{'START_A.BUTTON' | translate}}" 
                    (buttonValue)="goTo()"
                    iconRight="assets/images/chevronRight.svg"
                ></lib-button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="screen.isSmallest()">
        <div class="da-start-text mobile" >
            <div class="da-start-header mobile">
                <lib-header-text
                    label="{{'START_A.TITLE' | translate}}"
                    size="38px"
                    class="da-start-header"
                    [convertMobile]="true"
                ></lib-header-text>
            </div>
            <lib-tile-text
                label="{{'START_A.SUBTITLE' | translate}}"
                size="20px"
                info="true"
                [convertMobile]="true"
                (infoValue)="getInfo(startInfo)"
                [ngStyle]="{'text-align': 'left'}"
            >
            </lib-tile-text>
            <div class="da-start-button mobile">
                <lib-button 
                    name="start button"
                    value="{{'START_A.BUTTON' | translate}}" 
                    (buttonValue)="goTo()"
                    iconRight="assets/images/chevronRight.svg"
                ></lib-button>
            </div>
        </div>
        <div class="da-start-image mobile">
            <!-- <img src="{{grafik_start}}"/> -->
            <img alt="digitaler assistent startbild" [src]="grafik">
        </div>
    </ng-container>

</div>
<ng-template #startInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'START_A.INFO_HEADER' | translate}}" text="{{'START_A.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>

