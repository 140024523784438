<lib-header-text
    class="da-title"
    label="{{title | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<lib-tile-text
    class="da-title"
    label="{{'PRIVATKREDIT.SUB_TITLE' | translate}}"
    size="20px"
    [convertMobile]="true"
    [ngStyle]="{'display': 'flex', 'justify-content': 'center'}"
></lib-tile-text>
<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
>
    <ng-template #content>
        <div class="da-tile-content-wrapper">
            <lib-tile-text
            size="16px"
            label="{{'PRIVATKREDIT.TILE_TEXT1' | translate}}"
            info="true"
            (infoValue)="screen.openInfo(anzahlInfo)"
            [light]="true"
        ></lib-tile-text>
        <lib-counter
            #verbindlichkeiten
            [value]="contentService.getValue('verbindlichkeitenAnzahl', true)"
            name="verbindlichkeiten"
            [min]="1"
            [max]="7"
            (counterValue)="setCounter($event)"
            [light]="true"
        ></lib-counter>
        </div>
    </ng-template>
</lib-tile-group>
<ng-container *ngIf="showVertrag">
    <app-index 
        [tiles]="fakeTiles" 
        [selectedTile]="selectedTile"
        [slideFactor]="tileSlideFactor"
        [slidePos]="tilePosition"
        (indexValue)="getIndex($event, true)">
    </app-index>
    <div class="da-content-extended">
        <div class="da-content-extended-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest(), 'small': screen.isSmallerThanChildContainer()}">
            <div 
                class="da-tiles-wrapper" 
                [ngClass]="{'mobile-wrap' : screen.isSmallest()}"
                [ngStyle]="{'right': 'calc(-'+ screenPosition +' + '+ tilePosition +'px)'}">
                <div *ngFor="let item of contentService.getValue('verbindlichkeitenAnzahl', true) | fill; let num = index"
                    class="da-container-wide" 
                    [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <div class="da-edit-label-container" [ngClass]="{'is-editing' : labelEdit}">
                        <lib-header-text
                            *ngIf="!labelEdit"
                            class="da-title"
                            [label]="setLocalValue(num, 'name')"
                            size="38px"
                            [convertMobile]="true"
                        ></lib-header-text>
                        <lib-input
                            #name
                            *ngIf="labelEdit"
                            class="da-title"
                            label="{{'PRIVATKREDIT.LABEL_EDIT' | translate}}"
                            name="name{{num}}"
                            inputmode=""
                            [value]="setLocalValue(num, 'name')"
                            (keyup)="editLabel($event, 'name', num)"
                        ></lib-input>
                        <div class="da-edit-label" (click)="labelEdit = !labelEdit">
                            <svg-icon *ngIf="!labelEdit" src="assets/images/pen.svg" class="da-edit-label-icon"></svg-icon>
                            <svg-icon *ngIf="labelEdit" src="assets/images/diskSave.svg" class="da-edit-label-icon"></svg-icon>
                        </div>
                    </div>
                    <div class="da-child-container" #childContainer>
                        <!-- Typ Container -->
                        <div #VertragContainer
                            class="da-container" 
                            [ngClass]="{ 'mobile' : screen.isSmallest() }"
                            >
                            <lib-state-icon [state]="fakeTiles[num].typeState ? fakeTiles[num].typeState : 'warning'"></lib-state-icon>
                            <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                                <lib-tile-text
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'PRIVATKREDIT.TILE_TEXT2' | translate}}"
                                ></lib-tile-text>
                                <div class="da-type-selector">
                                    <lib-toggle-vertical
                                        #type
                                        [options]="switches"
                                        [localValue]="setLocalValue(num, 'verbindlichkeitTyp')"
                                        name="type{{num}}"
                                        (switchValue)="validateInput($event, 'type', num)">
                                    </lib-toggle-vertical>
                                    <lib-tooltip 
                                        *ngIf="errorType" 
                                        text="{{errorTypeText | translate}}"
                                    ></lib-tooltip>
                                </div>
                            </div>
                        </div>

                        <!-- amount Container -->
                        <div #amountContainer
                            class="da-container" 
                            [ngClass]="{ 'mobile' : screen.isSmallest() }"
                            >
                            <lib-state-icon [state]="fakeTiles[num].amountState ? fakeTiles[num].amountState : 'warning'"></lib-state-icon>
                            <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                                <lib-tile-text
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'PRIVATKREDIT.TILE_TEXT3' | translate}}"
                                ></lib-tile-text>
                                <lib-input
                                    #rate
                                    #inputs
                                    class="da-tile-field"
                                    label="{{labelRate | translate}}"
                                    name="rate{{num}}"
                                    [error]="false"
                                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                                    placeholder="0,00"
                                    unit="€"
                                    [inputDisabled]="inputDisabled"
                                    (click)="testValid = true"
                                    [touched]="!!setLocalValue(num, 'rate')"
                                    [value]="!!setLocalValue(num, 'rate') ? setLocalValue(num, 'rate') : ''"
                                    (change)="validateInput($event, 'rate', num)"
                                ></lib-input>
                                <lib-input
                                    *ngIf="setLocalValue(num, 'verbindlichkeitTyp') !== 'leasing'"
                                    #restschuld
                                    #inputs
                                    class="da-tile-field"
                                    label="{{'COMMON.LABELS.RESTSCHULD' | translate}}"
                                    name="restschuld{{num}}"
                                    [error]="false"
                                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                                    placeholder="0,00"
                                    unit="€"
                                    [inputDisabled]="inputDisabled"
                                    (click)="testValid = true"
                                    [touched]="!!setLocalValue(num, 'restschuld')"
                                    [value]="!!setLocalValue(num, 'restschuld') ? setLocalValue(num, 'restschuld') : ''"
                                    (change)="validateInput($event, 'restschuld', num)"
                                ></lib-input>
                            </div>
                        </div>
                        <!-- Abloesen Container -->
                        <div #abloese
                            *ngIf="setLocalValue(num, 'verbindlichkeitTyp') !== 'leasing'"
                            class="da-container" 
                            [ngClass]="{ 'mobile' : screen.isSmallest() }"
                            >
                            <lib-state-icon [state]="fakeTiles[num].abloeseState ? fakeTiles[num].abloeseState : 'warning'"></lib-state-icon>
                            <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                                <lib-tile-text
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    info="true"
                                    (infoValue)="screen.openInfo(abloeseInfo)"
                                    label="{{'PRIVATKREDIT.TILE_TEXT4' | translate}}"
                                ></lib-tile-text>
                                <lib-toggle
                                    #abloesen
                                    label1="{{'COMMON.TRUE' | translate}}"
                                    label2="{{'COMMON.FALSE' | translate}}"
                                    name="abloesen{{num}}"
                                    value1="true"
                                    value2="false"
                                    [localValue]="setLocalValue(num, 'abloesen')"
                                    (switchValue)="validateInput($event, 'abloesen', num)"
                                ></lib-toggle>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

<ng-template #anzahlInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'PRIVATKREDIT.INFO_HEADER1' | translate}}" text="{{'PRIVATKREDIT.INFO_TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #abloeseInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'PRIVATKREDIT.INFO_HEADER2' | translate}}" text="{{'PRIVATKREDIT.INFO_TEXT2' | translate}}"></app-info-sidebar>
</ng-template>
