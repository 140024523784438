<lib-header-text
    class="da-title"
    label="{{'EXPOSE.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>

<div class="da-tiles-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container1State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'EXPOSE.TILE_TEXT1' | translate}}"
            >
            </lib-tile-text>
        </div>
            <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                <svg-icon 
                    src="assets/images/globe.svg"
                    class="da-tile-wrapper-icon">
                </svg-icon>
            </div>
            <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-input
                #objectExposeLink
                label="{{'COMMON.LABELS.LINK' | translate}}"
                name="expose"
                [error]="false"
                placeholder="https://www.immobilienscout24.de/"
                validationType="link"
                inputmode=""
                errorText="{{ 'COMMON.ERRORS.LINK' | translate}}"
                [touched]="false"
                [autoWidth]="true"
                [value]="objectExposeLink.value"
                (keyup)="storeInputValue($event)"
            ></lib-input>
        </div>
    </div>
    <lib-tile-text
        size="20px"
        [convertMobile]="true"
        label="{{'EXPOSE.SUB_TITLE' | translate}}"
    >
    </lib-tile-text>
    <div class="da-container upload" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container2State"></lib-state-icon>
        <div class="da-tile-wrapper upload" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'EXPOSE.TILE_TEXT2' | translate}}"
            >
            </lib-tile-text>
        </div>
        <div class="da-tile-wrapper upload" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <app-file-upload type="expose" (fileUploaded)="setContainerStatus('container2', $event)"></app-file-upload>
        </div>
    </div>
</div>
<div class="da-buttons-wrapper">
    <app-nav-buttons
        class="da-buttons-group"
        [save]="true"
        [nextDisabled]="daNextSlideService.getDisabled()"
        [current]="slideName"
        [next]="next"
        (slideTo)="contentService.setNav($event, slideName, slideTo)"
        >
    </app-nav-buttons>
</div>

