import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { ContentService } from './services/content.service';
import { Location } from '@angular/common';
import { DataService } from './state/data/data.service';
import { DataStore } from './state/data/data.store';
import { DaClickpathService } from './state/click-path/da-clickpath.service';
import { DaNextSlideService } from './state/next-slide/da-next-slide.service';
import { ConfigurationQuery } from './state/configuration/configuration.query';
import * as manifest from '../assets/content/manifest.json';
import { UserQuery } from './state/user/user.query';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'finanzmakler-DA';
  tabTitleDigitalAssistant: string = '';
  tabTitleLoginPage: string = '';
  showLoginPage: boolean = false;
  environment = environment;
  name: string = '';
  sliderIndex: any;
  customContent: any;
  loading: boolean = true;
  percentageValue: number = 2;
  manifest = manifest;

  constructor(
    private contentService: ContentService,
    private location: Location,
    private renderer: Renderer2,
    private dataService: DataService,
    private dataStore: DataStore,
    private configurationQuery: ConfigurationQuery,
    private daClickpathService: DaClickpathService,
    private daNextSlideService: DaNextSlideService,
    private userQuery: UserQuery,
    private titleService: Title
  ) {
    this.contentService.getTenant();
    this.sliderIndex = this.dataService.getSliderIndexByType(environment.webappType);
  }

  ngOnInit(): void {

    const urlParam = window.location.search;

    this.loadAnimation();
    const status = this.userQuery.getEntity(1);
    if (!status) {
      // Initialisierung des Data Stores (user not logged in)
      this.dataStore.reset();
      this.dataService.add({ id: 1 });
      this.daClickpathService.add({ id: 1, clickpath: [], slideIndex: this.sliderIndex });
      this.daNextSlideService.add({ id: 1, next: '', disabled: true, L03Sent: false, timestamp: new Date });
    }
    setTimeout(() => {
      if(!!urlParam) {
        let source = urlParam.includes('&') ? urlParam.slice(urlParam.indexOf('id=') + 3, urlParam.indexOf('&')) : urlParam.slice(urlParam.indexOf("id=") + 3, urlParam.length);
        this.dataService.update(1, {lead: source} )
      }
    }, 100)


    // get custom content for each mandant
    this.configurationQuery.configuration.subscribe((res: any) => {
      this.customContent = res;
      if (res) {
        setTimeout(() => {

          this.addElement(this.customContent.grundeinstellungencss, 'style');
          this.addElement(this.customContent.unternehmenseinstellungen.linkfavicon, 'favicon');
          this.addElement(this.customContent.unternehmenseinstellungen.linkfavicon192x192, 'favicon192');
          this.addElement(this.customContent.unternehmenseinstellungen.linkfavicon256x256, 'favicon256');
          this.addElement(this.customContent.unternehmenseinstellungen.linkfavicon384x384, 'favicon384');
          this.addElement(this.customContent.unternehmenseinstellungen.linkfavicon512x512, 'favicon512');
          this.addElement(this.customContent.kontaktheaderunternehmensname, 'name');
          // this.addElement(this.customContent.spezialeinstellungen.googleoptionsparameters, 'google');
          this.addElement(this.customContent.kontaktlinkcookies, 'cookies');


          const description = document.getElementById('da-description');
          const ogDescription = document.getElementById('da-og-description');
          const ogTitle = document.getElementById('da-og-title');
          const twDescription = document.getElementById('da-tw-description');
          const twTitle = document.getElementById('da-tw-title');

          if (environment.webappType === 'a') {
            this.tabTitleDigitalAssistant = this.customContent.unternehmenseinstellungen.webpagetitel_daa;
            this.tabTitleLoginPage = `${this.customContent.unternehmenseinstellungen.webpagetitel_daa} | Login`;

            if (description) {description.setAttribute('content', 'Finanzierungsmachbarkeit sofort ermitteln und Finanzierungszertifikat für den Immobilienkauf erhalten.');}
            if (ogDescription) {ogDescription.setAttribute('content', 'Finanzierungsmachbarkeit sofort ermitteln und Finanzierungszertifikat für den Immobilienkauf erhalten.');}
            if (ogTitle) {ogTitle.setAttribute('content', 'Finanzierungszertifikator - Finanzierungsmachbarkeit ermitteln');}
            if (twDescription) {twDescription.setAttribute('content', 'Finanzierungsmachbarkeit sofort ermitteln und Finanzierungszertifikat für den Immobilienkauf erhalten.');}
            if (twTitle) {twTitle.setAttribute('content', 'Finanzierungszertifikator - Finanzierungsmachbarkeit ermitteln');}
            this.addElement(`https://${this.customContent.unternehmenseinstellungen.linkwebsite}.finanzierungszertifikator.finsolio.de`, 'url');
          } else if (environment.webappType === 'b') {
            this.tabTitleDigitalAssistant = this.customContent.unternehmenseinstellungen.webpagetitel_dab;
            this.tabTitleLoginPage = `${this.customContent.unternehmenseinstellungen.webpagetitel_dab} | Login`;
            this.addElement(`https://${this.customContent.unternehmenseinstellungen.linkwebsite}.finanzierungskonfigurator-one.finsolio.de`, 'url');
          } else {
            this.tabTitleDigitalAssistant = this.customContent.unternehmenseinstellungen.webpagetitel_dac;
            this.tabTitleLoginPage = `${this.customContent.unternehmenseinstellungen.webpagetitel_dac} | Login`;
            if (ogTitle) {ogTitle.setAttribute('content', 'Forwardkonfigurator - Immobilienfinanzierung im Self-Service');}
            if (twTitle) {twTitle.setAttribute('content', 'Forwardkonfigurator - Immobilienfinanzierung im Self-Service');}
            this.addElement(`https://${this.customContent.unternehmenseinstellungen.linkwebsite}.forwardkonfigurator.finsolio.de`, 'url');
          }
          this.showRoutingPage()
        })
        setTimeout(() => {
          this.loading = false;
        }, 500);
      } else {
        setTimeout(() => {
          this.loading = false;
        }, 7000);
      }
    });
  }

  loadAnimation() {
    setTimeout(() => {
      this.percentageValue++;
      if (this.percentageValue < 100) {
        this.loadAnimation();
      }
    }, 30);
  }

  @ViewChild('style', { static: false }) style!: ElementRef;

  addElement(value: any, type: string) {
    switch (type) {
      case "style": {
        const customStyle: HTMLStyleElement = this.renderer.createElement('style');
        customStyle.innerHTML = value;
        this.renderer.appendChild(this.style.nativeElement, customStyle);
        break;
      }
      case "favicon": {
        const link = document.getElementById('da-favicon');
        if (link) {
          link.setAttribute('href', value);
        }
        break;
      }
      case "favicon192": {
        const link = document.getElementById('da-favicon-192');
        if (link) {
          link.setAttribute('href', value);
        }
        break;
      }
      case "favicon256": {
        const link = document.getElementById('da-favicon-256');
        if (link) {
          link.setAttribute('href', value);
        }
        break;
      }
      case "favicon384": {
        const link = document.getElementById('da-favicon-384');
        if (link) {
          link.setAttribute('href', value);
        }
        break;
      }
      case "favicon512": {
        const link = document.getElementById('da-favicon-512');
        if (link) {
          link.setAttribute('href', value);
        }
        break;
      }
      case "url": {
        const link = document.getElementById('da-link');
        const ogLink = document.getElementById('da-og-link');
        const fbLink = document.getElementById('da-fb-link');
        const fbAdminLink = document.getElementById('da-fb-admin-link');
        if (link) {
          link.setAttribute('href', value);
        }
        if (ogLink) {
          ogLink.setAttribute('content', value);
        }
        if (fbLink) {
          fbLink.setAttribute('content', value);
        }
        if (fbAdminLink) {
          fbAdminLink.setAttribute('content', value);
        }
        break;
      }
      case "name": {
        const siteLink = document.getElementById('da-og-name');
        const alternateLink = document.getElementById('da-alt-name');
        const langLink = document.getElementById('da-lang-name');
        if (siteLink) {
          siteLink.setAttribute('content', value);
        }
        if (alternateLink) {
          alternateLink.setAttribute('href', value);
        }
        if (langLink) {
          langLink.setAttribute('href', value);
        }
        break;
      }
      case "cookies": {
        const cookieCode = document.getElementById('Cookiebot');
        if (cookieCode) {
          cookieCode.setAttribute('data-cbid', value);
        }
        break;
      }
      case "google": {
       

        break;
      }
    }
  }

  showRoutingPage() {
    this.showLoginPage = this.location.path() && this.location.path().includes('login-light') ? true : false;
    if (this.showLoginPage) {
      this.titleService.setTitle(this.tabTitleLoginPage);
    } else {
      this.titleService.setTitle(this.tabTitleDigitalAssistant);
    }
  }
}
