<lib-header-text
    class="da-title"
    label="{{'PROPERTY_CHANGE.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<div class="da-index">
    <app-index 
        [tiles]="fakeTiles" 
        [selectedTile]="selectedTileValue"
        [slideFactor]="tileSlideFactor"
        [slidePos]="tilePosition"
        [isNumber]="true"
        (indexValue)="getIndex($event)">
    </app-index>
</div>
<div class="da-content-extended">
    <div class="da-content-extended-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest()}">
        <div 
            class="da-tiles-wrapper with-slider" 
            [ngClass]="{'mobile-wrap' : screen.isSmallest()}"
            [ngStyle]="{'right': 'calc('+ screenPosition +' + '+ tilePosition +'px)'}">
            <ng-container *ngIf="fakeTiles && fakeTiles.length > 0">
                <ng-container *ngFor="let fakeTile of fakeTiles; let num = index">
                    <div
                        *ngIf="!fakeTile.invisible"
                        class="da-container" 
                        (click)="getIndex(num)"
                        [ngClass]="{ 'mobile' : screen.isSmallest() }"
                        >
                        <lib-state-icon [state]="fakeTile.state"></lib-state-icon>

                        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                            <svg-icon class="da-tile-icon" [src]="fakeTile.image"></svg-icon>
                            <lib-tile-text
                                *ngIf="!fakeTile.owner"
                                class="da-tile-header"
                                size="38px"
                                [convertMobile]="true"
                                label="{{contentService.getNestedValue('bestehendeImmobilien', 'name', num) | translate: { count : num } }}"
                            ></lib-tile-text>
                            <lib-tile-text
                                *ngIf="fakeTile.owner"
                                class="da-tile-header"
                                size="38px"
                                [convertMobile]="true"
                                label="{{'PROPERTY_INFORMATION.PROPERTY.MAIN' | translate }}"
                            ></lib-tile-text>
                            <!-- ist zu finanzierende Immobilie (DA-C) -->
                            <ng-container *ngIf="fakeTile.owner">
                                <div class="da-tile-input-container text-area">
                                    <lib-text-area
                                        [inputDisabled]="true"
                                        [multiLine]="true"
                                        label="{{'PROPERTY_CHANGE.LABELS.LABEL1' | translate}}"
                                        [value]="fakeTile.address">
                                    </lib-text-area>
                                </div>
                                <lib-tile-text
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'PROPERTY_CHANGE.TILES.TEXT1' | translate}}"
                                ></lib-tile-text>
                                <lib-toggle-vertical
                                    name="switchOwner"
                                    [options]="switchOwner"
                                    [inputDisabled]="true"
                                    [multiLine]="true"
                                    [localValue]="contentService.getValue('projectPlan')">
                                </lib-toggle-vertical>
                            </ng-container>
                            <!-- Objekt ist Baugrundstueck und wird für Vorhaben verwendet -->
                            <ng-container *ngIf="fakeTile.useForProject">
                                <div class="da-tile-input-container text-area">
                                    <lib-text-area
                                        [inputDisabled]="true"
                                        [multiLine]="true"
                                        label="{{'PROPERTY_CHANGE.LABELS.LABEL1' | translate}}"
                                        [value]="fakeTile.address">
                                    </lib-text-area>
                                </div>
                                <lib-tile-text
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'PROPERTY_CHANGE.TILES.TEXT1' | translate}}"
                                ></lib-tile-text>
                                <lib-toggle-vertical
                                    name="switchUseForProject"
                                    [options]="switchUseForProject"
                                    [inputDisabled]="true"
                                    [multiLine]="true"
                                    [localValue]="setLocalValue(fakeTile.id, 'useForProject')">
                                </lib-toggle-vertical>
                            </ng-container>
                            <!-- StandardObjekt -->
                            <ng-container *ngIf="!fakeTile.useForProject && !fakeTile.owner && !fakeTile.family">
                                <div class="da-tile-input-container text-area">
                                    <lib-text-area
                                        [inputDisabled]="true"
                                        [multiLine]="true"
                                        label="{{'PROPERTY_CHANGE.LABELS.LABEL1' | translate}}"
                                        [value]="fakeTile.address">
                                    </lib-text-area>
                                </div>
                                <lib-tile-text
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'PROPERTY_CHANGE.TILES.TEXT1' | translate}}"
                                ></lib-tile-text>
                                <lib-toggle-vertical
                                    #change
                                    name="change{{num}}"
                                    [options]="switchChange"
                                    [localValue]="setLocalValue(num, 'change')"
                                    (switchValue)="setSwitch($event, 'change', fakeTile.value, num)">
                                </lib-toggle-vertical>
                                <ng-container *ngIf="setLocalValue(num, 'change') === 'VERKAUFEN'">
                                    <lib-tile-text
                                        class="da-tile-header"
                                        size="20px"
                                        [convertMobile]="true"
                                        label="{{'PROPERTY_CHANGE.TILES.TEXT3' | translate}}"
                                    ></lib-tile-text>
                                    <lib-toggle
                                        #financing
                                        label1="{{'COMMON.TRUE' | translate}}"
                                        label2="{{'COMMON.FALSE' | translate}}"
                                        name="financing{{num}}"
                                        value1="ja"
                                        value2="nein"
                                        [localValue]="setLocalValue(num, 'zwischenfinanzierung')"
                                        (switchValue)="setSwitch($event, 'financing', fakeTile.value, num)"
                                    ></lib-toggle>
                                </ng-container>
                                <ng-container *ngIf="setLocalValue(num, 'change') === 'VERMIETEN'">
                                    <div class="da-tile-input-container margin-top">
                                        <lib-input
                                            #kaltmiete
                                            label="{{'PROPERTY_CHANGE.LABELS.LABEL2' | translate}}"
                                            name="kaltmiete{{num}}"
                                            [error]="false"
                                            errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                                            placeholder="0,00"
                                            unit="€"
                                            [inputDisabled]="inputDisabled"
                                            (click)="testValid = true"
                                            [touched]="!!setLocalValue(num, 'kaltmiete')"
                                            [value]="!!setLocalValue(num, 'kaltmiete') ? setLocalValue(num, 'kaltmiete') : ''"
                                            (change)="validateInput($event, 'kaltmiete', num, fakeTile.value, 'kaltmiete' + num)"
                                        ></lib-input>
                                    </div>
                                </ng-container>
                                <lib-tile-text
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'PROPERTY_CHANGE.TILES.TEXT2' | translate}}"
                                ></lib-tile-text>
                                <lib-toggle
                                    #security
                                    label1="{{'COMMON.TRUE' | translate}}"
                                    label2="{{'COMMON.FALSE' | translate}}"
                                    name="security{{num}}"
                                    value1="ja"
                                    value2="nein"
                                    [localValue]="setLocalValue(num, 'zusatzsicherheit')"
                                    (switchValue)="setSwitch($event, 'security', fakeTile.value, num)"
                                ></lib-toggle>
                            </ng-container>
                            <!-- Family Immobilie -->
                            <ng-container *ngIf="fakeTile.family">
                                <div class="da-tile-input-container text-area">
                                    <lib-text-area
                                        [inputDisabled]="true"
                                        [multiLine]="true"
                                        label="{{'PROPERTY_CHANGE.LABELS.LABEL1' | translate}}"
                                        [value]="fakeTile.address">
                                    </lib-text-area>
                                </div>
                                <lib-tile-text
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'PROPERTY_CHANGE.TILES.TEXT1' | translate}}"
                                ></lib-tile-text>
                                <lib-toggle-vertical
                                    name="switchFamily"
                                    [options]="switchFamily"
                                    [multiLine]="true"
                                    [localValue]="setLocalValue(num, 'zusatzsicherheit')"
                                    (switchValue)="setSwitch($event, 'security', fakeTile.value, num)">
                                </lib-toggle-vertical>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

