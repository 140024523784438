<lib-header-text
    class="da-title"
    label="{{'HOUSE_CATEGORY.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>

<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
>
    <ng-template #contentZwei>
        <lib-toggle-vertical
            [options]="switchesZwei"
            [localValue]="contentService.getValue('houseCategory')"
            name="zweifamilienhaus"
            (switchValue)="setValue($event, 'Zweifamilienhaus')"
        >
        </lib-toggle-vertical>
    </ng-template>
    <ng-template #contentReihe>
        <lib-toggle-vertical
            [options]="switchesReihe"
            [localValue]="contentService.getValue('houseCategory')"
            name="reihenhaus"
            (switchValue)="setValue($event, 'Reihenhaus')"
        >
        </lib-toggle-vertical>
    </ng-template>
    <ng-template #contentMehr>
        <lib-toggle-vertical
            [options]="switchesMehr"
            [localValue]="contentService.getValue('houseCategory')"
            name="mehrfamilienhaus"
            (switchValue)="setValue($event, 'Mehrfamilienhaus')"
        >
        </lib-toggle-vertical>
    </ng-template>
</lib-tile-group>
<div class="da-buttons-wrapper">
    <app-nav-buttons
        class="da-buttons-group"
        [save]="true"
        [nextDisabled]="daNextSlideService.getDisabled()"
        [current]="slideName"
        [next]="next"
        (slideTo)="contentService.setNav($event, slideName, slideTo)"
        >
    </app-nav-buttons>
</div>
