<lib-header-text
    class="da-title"
    label="{{'MARKET_VALUE.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<div class="da-tiles-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="containerState"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <svg-icon 
                src="assets/images/coins.svg"
                class="da-tile-wrapper-icon"></svg-icon>
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'MARKET_VALUE.TILE_TEXT' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(marketInfo)"
            >
            </lib-tile-text>
            <lib-input
                #objectMarktwert
                label="{{'COMMON.LABELS.WERT' | translate}}"
                name="marktWert"
                [decimals]="0"
                [error]="false"
                placeholder="100.000,00"
                unit="€"
                [inputDisabled]="inputDisabled"
                (click)="testValid = true"
                errorText="{{ wertError | translate}}"
                [touched]="!!contentService.getValue('objectMarkwert')"
                [value]="!!contentService.getValue('objectMarkwert') ? contentService.getValue('objectMarkwert') : ''"
                (change)="validateInputValue($event, objectMarktwert)"
            ></lib-input>
        </div>
    </div>
</div>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

<ng-template #marketInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MARKET_VALUE.INFO_HEADER' | translate}}" text="{{'MARKET_VALUE.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
