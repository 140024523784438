<div class="da-forward-darlehen-wrapper" [ngClass]="{'mobile': screen.isSmallest()}">
    <lib-state-icon [state]="containerState" [ngStyle]="{'left': '0', 'top': '0', 'position': 'absolute'}"></lib-state-icon>
    <lib-header-text
        class="da-title"
        label="{{'FINANCE_REQUIREMENTS_FORWARD.MODERNISIERUNG.HEADER' | translate }}"
        size="38px"
        [convertMobile]="true"
    ></lib-header-text>
    <button class="da-forward-arrow" [ngClass]="{'mobile': screen.isSmallest()}" (click)="isVisible = !isVisible">
        <svg-icon src="assets/images/chevronUp.svg" *ngIf="isVisible"></svg-icon>
        <svg-icon src="assets/images/chevronDown.svg" *ngIf="!isVisible"></svg-icon>
    </button>
    <ng-container *ngIf="isVisible">
        <!-- Sanierung -->
        <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}">
            <div class="da-forward-darlehen-row-part left">
                <lib-tile-text
                    class="da-title"
                    [ngClass]="{'more-margin-top': !screen.isSmallest()}"
                    label="{{'FINANCE_REQUIREMENTS_FORWARD.MODERNISIERUNG.LABELS.LABEL1' | translate }}"
                    size="16px"
                    info="true"
                    (infoValue)="screen.openInfo(sanierungInfo)"
                ></lib-tile-text>
            </div>
            <div class="da-forward-darlehen-row-part">
                <lib-input
                    #sanierung
                    #inputs
                    label="{{'COMMON.LABELS.SUMME' | translate}}"
                    name="sanierung"
                    [error]="false"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [inputDisabled]="inputDisabled"
                    [touched]="!!contentService.getValue('sanierungBetrag')"
                    [value]="contentService.getValue('sanierungBetrag')"
                    (change)="validateInput($event, sanierung)"
                ></lib-input>
            </div>
        </div>
        <!-- Modernisierung -->
        <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}">
            <div class="da-forward-darlehen-row-part left">
                <lib-tile-text
                    class="da-title"
                    [ngClass]="{'more-margin-top': !screen.isSmallest()}"
                    label="{{'FINANCE_REQUIREMENTS_FORWARD.MODERNISIERUNG.LABELS.LABEL2' | translate }}"
                    size="16px"
                    info="true"
                    (infoValue)="screen.openInfo(modernisierungInfo)"
                ></lib-tile-text>
            </div>
            <div class="da-forward-darlehen-row-part">
                <lib-input
                    #modernisierung
                    #inputs
                    label="{{'COMMON.LABELS.SUMME' | translate}}"
                    name="modernisierung"
                    [error]="false"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [inputDisabled]="inputDisabled"
                    [touched]="!!contentService.getValue('modernisierungkosten')"
                    [value]="contentService.getValue('modernisierungkosten')"
                    (change)="validateInput($event, modernisierung)"
                ></lib-input>
            </div>
        </div>
        <!-- Eigenleistung -->
        <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}" *ngIf="!!contentService.getValue('modernisierungkosten') && contentService.getValue('modernisierungkosten') !== '0,00'">
            <div class="da-forward-darlehen-row-part left">
                <lib-tile-text
                    class="da-title"
                    label="{{'FINANCE_REQUIREMENTS_FORWARD.MODERNISIERUNG.LABELS.LABEL3' | translate }}"
                    size="16px"
                    info="true"
                    (infoValue)="screen.openInfo(eigenleistungInfo)"
                ></lib-tile-text>
            </div>
            <div class="da-forward-darlehen-row-part">
                <lib-input-button
                    #eigenleistung
                    #inputs
                    label=""
                    unit="€"
                    [inputDisabled]="inputDisabled"
                    (click)="testValid = true"
                    name="eigenleistung"
                    type="eigenleistung"
                    [inputDisabled]="inputDisabled"
                    [error]="contentService.getValue('eigenleistungInValid')"
                    [value]="!!contentService.getValue('eigenleistungValue') ? contentService.getValue('eigenleistungValue') : '0,00'"
                    [touched]="!!contentService.getValue('eigenleistungValue')"
                    (inputValue)="screen.openInfo(eigenleistungInput)"
                ></lib-input-button>
            </div>
        </div>
        <!-- Betrag gesamt -->
        <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}">
            <div class="da-forward-darlehen-row-part left">
                <lib-tile-text
                    class="da-title"
                    label="{{'FINANCE_REQUIREMENTS_FORWARD.MODERNISIERUNG.LABELS.LABEL4' | translate }}"
                    size="16px"
                ></lib-tile-text>
            </div>
            <div class="da-forward-darlehen-row-part">
                <lib-input
                    #modernisierungGesamt
                    #inputs
                    label=""
                    name="modernisierungGesamt"
                    [error]="false"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="0,00"
                    unit="€"
                    [inputDisabled]="true"
                    [touched]="!!contentService.getValue('modernisierungGesamtBetrag')"
                    [value]="contentService.getValue('modernisierungGesamtBetrag')"
                ></lib-input>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #sanierungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS_FORWARD.MODERNISIERUNG.INFOS.HEADER1' | translate}}" text="{{'FINANCE_REQUIREMENTS_FORWARD.MODERNISIERUNG.INFOS.TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #modernisierungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS_FORWARD.MODERNISIERUNG.INFOS.HEADER2' | translate}}" text="{{'FINANCE_REQUIREMENTS_FORWARD.MODERNISIERUNG.INFOS.TEXT2' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #eigenleistungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS_FORWARD.MODERNISIERUNG.INFOS.HEADER3' | translate}}" text="{{'FINANCE_REQUIREMENTS_FORWARD.MODERNISIERUNG.INFOS.TEXT3' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #eigenleistungInput let-modal>
    <app-eigenleistung-sidebar 
    [modal]="modal" 
    (eigenleistungDone)="setValid()"
    ></app-eigenleistung-sidebar>
</ng-template>