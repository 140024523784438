<div>
    <ng-container *ngIf="!done">
        <ng-container *ngIf="!dashboard && !zertifikat">
            <lib-button-group *ngIf="save" [buttons]="buttonsWithSave" [nowrap]="nextValidate" (buttonValue)="processNavigation($event)"></lib-button-group>
            <lib-button-group *ngIf="!save && !noNextButton" [buttons]="buttons" (buttonValue)="processNavigation($event)"></lib-button-group>
            <lib-button-group *ngIf="noNextButton" [buttons]="buttonsNoNext" (buttonValue)="processNavigation($event)"></lib-button-group>
        </ng-container>
        <lib-button-group *ngIf="dashboard" [buttons]="buttonsDashboard" [custom]="!nextValidate" [nowrap]="nextValidate" (buttonValue)="processNavigation($event)"></lib-button-group>
        <lib-button-group *ngIf="zertifikat" [buttons]="buttonsZertifikat" [custom]="!nextValidate" [nowrap]="nextValidate" (buttonValue)="processNavigation($event)"></lib-button-group>
    </ng-container>
    <lib-button-group *ngIf="done" [buttons]="buttonsDone" (buttonValue)="processNavigation($event)"></lib-button-group>
</div>


