<div class="da-bubble-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
    <div class="da-bubble" *ngFor="let item of buttons; let num = index">
        <lib-header-text
            size="14px"
            [convertMobile]="false"
            [light]="true"
            label="{{ item.label | translate}}"
        ></lib-header-text>
        <button
            class="da-bubble-button"
            [ngClass]="{'enabled' : item.enabled, 'first': num === 0, 'middle': num === 1, 'last': num === 2}"
            (click)="sendValue(item.value, item.enabled)"
        >{{num + 1}}</button>
    </div>
</div>
