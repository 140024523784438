<div class="da-lightbox" [ngClass]="{'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet()}">
    <div class="da-close-icon" [ngClass]="{'mobile' : screen.isSmallest()}" (click)="dismiss()">
        <svg-icon
            src="assets/images/chevronRight.svg"
        >
        </svg-icon>
    </div>
    <div class="da-modal-body">
        <lib-header-text
            class="da-lightbox-header"
            size="38px"
            [convertMobile]="true"
            [light]="true"
            label="{{'CALENDLY.HEADER' | translate}}"
        ></lib-header-text>
        <div class="da-lightbox-content">
            <div class="da-calendly-loading-screen" *ngIf="isLoading">
                <mat-progress-spinner mode="indeterminate" diameter="150" class="da-start-loader" [ngStyle]="{'stroke': '#D2D2D2'}"></mat-progress-spinner>
            </div>
            <span #calendlyContainer></span>
        </div>
    </div>
</div>
