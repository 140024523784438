import { Component, Output, EventEmitter, HostListener, ViewChild, Input, OnInit } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';


@Component({
  selector: 'app-tilgung-forward',
  templateUrl: './tilgung-forward.component.html',
  styleUrls: ['./tilgung-forward.component.scss']
})
export class TilgungForwardComponent implements OnInit {
  @Output() sum: EventEmitter<string> = new EventEmitter();
  @Output() state: EventEmitter<any> = new EventEmitter();
  @Input() inputDisabled!: boolean;
  @Input() testValid!: boolean;
  error: boolean = false;
  isVisible: boolean = true;
  containerState: string = 'warning';

  @ViewChild('betragAktiv') betragAktiv!: any;
  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    public dataQuery: DataQuery,
    public dataService: DataService
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit() {
    this.setValid();
  }

  setValid() {
    this.containerState = this.contentService.getValue('eigenkapitalInValid') ? 'error' : !this.contentService.getValue('eigenkapitalAktiv') ? 'warning' : 'success';
    this.state.emit({type: 'tilgung', state: this.containerState});
    this.sum.emit();
  }
}
