import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { EmailService } from 'src/app/services/email.service';
import { LayoutService } from 'src/app/services/layout.service';
import { MappingService } from 'src/app/services/mapping.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { UserQuery } from 'src/app/state/user/user.query';
import { UserService } from 'src/app/state/user/user.service';

@Component({
  selector: 'app-express-sidebar',
  templateUrl: './express-sidebar.component.html',
  styleUrls: ['./express-sidebar.component.scss']
})
export class ExpressSidebarComponent implements OnInit, AfterViewInit {

  withPartner: boolean = false;
  anredeDN1: string = '';
  anredeDN2: string = '';
  nachnameDN1: string = '';
  nachnameDN2: string = '';
  ergebnis: any;
  parentWidth: number = window.innerWidth;

  label1Done: boolean = false;
  label2Done: boolean = false;
  label3Done: boolean = false;
  label4Done: boolean = false;
  screen1Disabled: boolean = true;
  done: boolean = false;
  loading: boolean = false;
  showButtons: boolean = false;

  @Input() modal!: NgbModalRef;
  @Input() resultId!: number;
  @ViewChild('parent') parent!: ElementRef<any>;
  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private daService: DataService,
    private dataQuery: DataQuery,
    private emailService: EmailService,
    private configurationQuery: ConfigurationQuery,
    private mappingService: MappingService,
    private userService: UserService,
    private userQuery: UserQuery
  ) {
    this.anredeDN1 = this.contentService.getValue('haushaltPerson') !== 'FRAU' ? 'Herr' : "Frau";
    this.nachnameDN1 = !!this.contentService.getValue('haushaltPersonLastName') ? this.contentService.getValue('haushaltPersonLastName') : '';

    if (this.contentService.getValue('participants') !== 'allein') {
      this.withPartner = true;
      this.anredeDN2 = this.contentService.getValue('haushaltPersonPartner') !== 'FRAU' ? 'Herr' : "Frau";
      this.nachnameDN2 = !!this.contentService.getValue('haushaltPersonLastNamePartner') ? this.contentService.getValue('haushaltPersonLastNamePartner') : '';
    }
  }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
    if (this.parent.nativeElement.offsetWidth) {
      this.parentWidth = this.parent.nativeElement.offsetWidth;
    }
  }

  ngOnInit(): void {
    this.ergebnis = this.contentService.getValue('results').filter((item: any) => {
      return item.id === this.resultId;
    });
    this.ergebnis = this.ergebnis[0];
  }

  ngAfterViewInit() {
    if (this.parent.nativeElement.offsetWidth) {
      this.parentWidth = this.parent.nativeElement.offsetWidth;
    }
    setTimeout(() => { this.showButtons = true }, 1100);
  }

  setSwitch(e: any) {
    switch (e.srcElement.name) {
      case 'label1': {
        this.label1Done = e.srcElement.checked;
        break;
      }
      case 'label2': {
        this.label2Done = e.srcElement.checked;
        break;
      }
      case 'label3': {
        this.label3Done = e.srcElement.checked;
        break;
      }
      case 'label4': {
        this.label4Done = e.srcElement.checked;
        break;
      }
    }
    if (this.label1Done && this.label2Done && this.label3Done && this.label4Done) {
      this.screen1Disabled = false;
    } else {
      this.screen1Disabled = true;
    }
  }

  updateUser(type: string, mail: string) {
    const data = { ...this.dataQuery.getEntity(1) };
    const userData = { ...this.userQuery.getEntity(1) };

    this.userService.getStoreData(data['uuid'], { ...userData, uuid: data['uuid'] }).then((storeDataResult) => {
      // console.log('save', storeDataResult);
      if (storeDataResult) {
        this.userService.updateProgress(storeDataResult).then((saveProgressResult: any) => {
          if(!!this.contentService.getValue('uuid')) {
            this.emailService.setEmail(type, mail, this.contentService.getValue('uuid'), true, storeDataResult);
          }
        });
      }
    });
  }

  finishInput() {
    this.loading = true;
    this.daService.update(1, { expressRequest: true });
    setTimeout(() => {
    this.mappingService.mapReduxData().then((result: any) => {
      result.subscribe({
        next: (data: any) => {
          this.done = true;
          this.daService.update(1, { expressSent: this.done });
          this.daService.update(1, { results: [this.ergebnis] });
          this.daService.update(1, { resultsDone: true });
          this.daService.update(1, { anfrageUuid: data.finanzierungsanfrageuuid });
          setTimeout(() => {
            this.emailService.setEmail('n03', this.contentService.getValue('haushaltPersonEmail'));
            this.configurationQuery.configuration.subscribe((res: any) => {
              if (res) {
                this.updateUser('l09', res.kontaktemailkunden);
                this.emailService.setEmail('l06', res.kontaktemailkunden, '', false, this.ergebnis);
              }
            });
          }, 200)
          setTimeout(() => {
            this.loading = false;
          }, 7000)
        },
        error: (error: any) => console.log(error)
      })
    })
  }, 100)
  }

  getDownload(e: any) {
    console.log(e);
  }

  next(e: any) {
    this.dismiss();
  }

  dismiss() {
    document.body.style.overflowY = 'auto';
    this.modal.dismiss();
  }

}
