<div class="da-index">
    <button 
        class="da-index-icon left" 
        [ngClass]="{'visible' : arrowLeft}"
        (click)="moveOne('left')">
        <svg-icon *ngIf="arrowLeft" src="assets/images/chevronLeft.svg">
    </svg-icon>
    </button>
    <div class="da-index-button-single" *ngFor="let tile of tiles">
        <ng-container *ngIf="!tile.invisible">
            <svg-icon 
                *ngIf="selectedTile"
                class="da-index-pointer"
                src="assets/images/caretDown.svg"
                [ngClass]="{'visible' : selectedTile === tile.value}">
            </svg-icon>
            <div 
                *ngIf="!selectedTile"
                class="da-index-pointer-placeholder">
            </div>
            <button 
                (click)="getValue(tile.value)" 
                [ngClass]="{'isSelected' : selectedTile === tile.value}">
                <div class="da-index-button-state" *ngIf="tile.state">
                    <div 
                        class="da-index-button-state-circle"
                        [ngClass]="{'green' : tile.state === 'success', 'yellow': tile.state === 'warning', 'red' : tile.state === 'error'}"
                    ></div>
                </div>
            </button>
        </ng-container>
    </div>
    <button 
        class="da-index-icon right" 
        [ngClass]="{'visible' : arrowRight}"
        (click)="moveOne('right')">
        <svg-icon *ngIf="arrowRight" src="assets/images/chevronRight.svg">
    </svg-icon>
    </button>
</div>
