<div class="da-info-icon">
    <svg-icon
        src="assets/images/diskSave.svg"
    >
    </svg-icon>
</div>
<div class="title">
    <lib-header-text
        class="da-info-header"
        size="38px"
        [convertMobile]="true"
        [light]="false"
        label="{{'LOGIN_LIGHT.SAVE_PROGRESS_TITLE' | translate}}"
    ></lib-header-text>
</div>
<div class="da-info-content">
    <app-login-light-info></app-login-light-info>
    <div class="login-light-form-container">
        <form class="login-light-form"
              [ngClass]="{'xs-device': isMobile(), 's-device': isMobileOrSmallTablet()}"
              [formGroup]="userDataForm"
              (ngSubmit)="onSaveLoginLightProgress()">
            <div class="login-light-form-row input-row">
                <lib-toggle-horizontal
                    label="{{'LOGIN_LIGHT.FORM.LABEL_SALUTATION' | translate}}"
                    [options]="genders"
                    [light]="false"
                    [localValue]="getGender()"
                    (switchValue)="setGenderSwitch($event)">
                </lib-toggle-horizontal>
            </div>
            <div class="login-light-form-row input-row">
                <lib-input  #firstName
                            label="{{'LOGIN_LIGHT.FORM.LABEL_FIRST_NAME' | translate}}"
                            [autoWidth]="true"
                            [form]="userDataForm"
                            [disableNegativeMargin]="true"
                            [touched]="!!contentService.getValue('haushaltPersonFirstName')"
                            [value]="!!contentService.getValue('haushaltPersonFirstName') ? contentService.getValue('haushaltPersonFirstName') : ''"
                            [isCurrency]="false"
                            name="firstName"
                            [placeholder]="'Max'"
                            [error]="false"
                            validationType="string"
                            inputmode=""
                            errorText="{{'LOGIN_LIGHT.ERRORS.FIRST_NAME' | translate}}"
                            (change)="validateFormField($event, firstName)">
                </lib-input>
            </div>
            <div class="login-light-form-row input-row">
                <lib-input  #lastName
                            label="{{'LOGIN_LIGHT.FORM.LABEL_LAST_NAME' | translate}}"
                            [autoWidth]="true"
                            [form]="userDataForm"
                            [disableNegativeMargin]="true"
                            [touched]="!!contentService.getValue('haushaltPersonLastName')"
                            [value]="!!contentService.getValue('haushaltPersonLastName') ? contentService.getValue('haushaltPersonLastName') : ''"
                            [isCurrency]="false"
                            name="lastName"
                            [placeholder]="'Mustermann'"
                            [error]="false"
                            errorText="{{'LOGIN_LIGHT.ERRORS.LAST_NAME' | translate}}"
                            validationType="string"
                            inputmode=""
                            (change)="validateFormField($event, lastName)">
                </lib-input>
            </div>
            <div class="login-light-form-row input-row">
                <lib-input  #email
                            label="{{'LOGIN_LIGHT.FORM.LABEL_EMAIL' | translate}}"
                            [autoWidth]="true"
                            [form]="userDataForm"
                            [disableNegativeMargin]="true"
                            [touched]="!!contentService.getValue('haushaltPersonEmail')"
                            [value]="!!contentService.getValue('haushaltPersonEmail') ? contentService.getValue('haushaltPersonEmail') : ''"
                            [isCurrency]="false"
                            name="mail"
                            [placeholder]="'max.mustermann@web.de'"
                            [error]="false"
                            errorText="{{'LOGIN_LIGHT.ERRORS.EMAIL' | translate}}"
                            validationType="email"
                            inputmode="email"
                            (change)="validateFormField($event, email)">
                </lib-input>
            </div>
            <div class="login-light-form-checks">
                <div class="login-light-form-row login-light-form-check">
                    <div class="check-control">
                        <lib-toggle-checkbox
                            #datenschutz
                            [form]="userDataForm"
                            [dynLabelClassName]="toggleCheckboxLabelClass + ' wrap'"
                            (change)="formIsValid()"
                            name="ds">
                        </lib-toggle-checkbox>
                    </div>
                </div>
                <div class="login-light-form-row login-light-form-check">
                <div class="check-control">
                        <lib-toggle-checkbox
                            #agb
                            [form]="userDataForm"
                            [dynLabelClassName]="toggleCheckboxLabelClass + ' wrap'"
                            (change)="formIsValid()"
                            name="agb">
                        </lib-toggle-checkbox>
                    </div>
                </div>
            </div>
            <div class="login-light-form-row form-submit-button">
                <lib-button value="{{'LOGIN_LIGHT.BUTTONS.SAVE_AND_GET_LINK' | translate}}"
                            type="submit"
                            [autoWidth]="true"
                            iconRight="assets/images/chevronRight.svg"
                            [disabled]="formInvalid">
                </lib-button>
            </div>
        </form>
    </div>
</div>