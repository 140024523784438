<lib-header-text
    class="da-title"
    label="{{'HOUSE_DETAIL.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<div class="da-tiles-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container1State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'HOUSE_DETAIL.TILES.TILE1.TITLE' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(estateInfo)"
            >
            </lib-tile-text>
            <lib-input
                #objectEstateSize
                label="{{'COMMON.LABELS.SIZE' | translate}}"
                name="estateSize"
                [error]="false"
                placeholder="1.000,00"
                unit="m²"
                (click)="testValid = true"
                errorText="{{sizeError | translate}}"
                [touched]="!!contentService.getValue('objectGrundstuecksgroesse')"
                [inputDisabled]="inputDisabled"
                [value]="!!contentService.getValue('objectGrundstuecksgroesse') ? contentService.getValue('objectGrundstuecksgroesse') : ''"
                (change)="validateInputValue($event, objectEstateSize)"
            ></lib-input>
        </div>
    </div>
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container2State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'HOUSE_DETAIL.TILES.TILE2.TITLE' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(houseInfo)"
            >
            </lib-tile-text>
            <lib-input
                #objectHouseSize
                label="{{'COMMON.LABELS.SIZE' | translate}}"
                name="houseSize"
                [error]="false"
                placeholder="500,00"
                unit="m²"
                (click)="testValid = true"
                [inputDisabled]="inputDisabled"
                errorText="{{houseError | translate}}"
                [touched]="!!contentService.getValue('objectGesamtflaeche')"
                [value]="!!contentService.getValue('objectGesamtflaeche') ? contentService.getValue('objectGesamtflaeche') : ''"
                (change)="validateInputValue($event, objectHouseSize)"
            ></lib-input>
        </div>
    </div>
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container3State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'HOUSE_DETAIL.TILES.TILE3.TITLE' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(yearInfo)"
            >
            </lib-tile-text>
            <lib-input
                #objectYear
                label="{{'COMMON.LABELS.YEAR' | translate}}"
                name="year"
                [error]="false"
                placeholder="1992"
                (click)="testValid = true"
                validationType="numbers"
                [inputDisabled]="inputDisabled"
                errorText="{{'COMMON.ERRORS.YEAR' | translate}}"
                [touched]="!!contentService.getValue('objectBaujahr')"
                [value]="!!contentService.getValue('objectBaujahr') ? contentService.getValue('objectBaujahr') : ''"
                (change)="validateYear($event, objectYear)"
                [isCurrency]="false"
            ></lib-input>
        </div>
    </div>
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container4State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'HOUSE_DETAIL.TILES.TILE4.TITLE' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(fertighausInfo)"
            >
            </lib-tile-text>
            <lib-toggle
                #objectFertighaus
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="fertighaus"
                value1="true"
                value2="false"
                [inputDisabled]="inputDisabled"
                [localValue]="fertighaus"
                (switchValue)="storeInputValue(objectFertighaus, $event)"
            ></lib-toggle>
        </div>
    </div>
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }" *ngIf="showWohnung">
        <lib-state-icon [state]="container5State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'HOUSE_DETAIL.TILES.TILE5.TITLE' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(wohnungInfo)"
            >
            </lib-tile-text>
            <lib-toggle
                #objectWohnung
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="wohnung"
                value1="true"
                value2="false"
                [inputDisabled]="inputDisabled"
                [localValue]="wohnung"
                (switchValue)="storeInputValue(objectWohnung, $event)"
            ></lib-toggle>   
        </div>    
    </div>
</div>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

<ng-template #estateInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'HOUSE_DETAIL.TILES.TILE1.INFO_HEADER' | translate}}" text="{{'HOUSE_DETAIL.TILES.TILE1.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #houseInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'HOUSE_DETAIL.TILES.TILE2.INFO_HEADER' | translate}}" text="{{'HOUSE_DETAIL.TILES.TILE2.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #yearInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'HOUSE_DETAIL.TILES.TILE3.INFO_HEADER' | translate}}" text="{{'HOUSE_DETAIL.TILES.TILE3.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #fertighausInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'HOUSE_DETAIL.TILES.TILE4.INFO_HEADER' | translate}}" text="{{'HOUSE_DETAIL.TILES.TILE4.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #wohnungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'HOUSE_DETAIL.TILES.TILE5.INFO_HEADER' | translate}}" text="{{'HOUSE_DETAIL.TILES.TILE5.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
