import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpBackend, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { FmLibraryModule } from 'fm-library';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StarRatingModule } from 'angular-star-rating';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxFileDropModule } from 'ngx-file-drop';
import { CodeInputModule } from 'angular-code-input';
import { createCustomElement } from '@angular/elements';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainPipe } from './state/pipes/pipe.module';
import { environment } from 'src/environments/environment';
import { FrameComponent } from './components/frame/frame.component';
import { DaAViewComponent } from './da-views/da-a-view/da-a-view.component';
import { DaBViewComponent } from './da-views/da-b-view/da-b-view.component';
import { DaCViewComponent } from './da-views/da-c-view/da-c-view.component';
import { StartAComponent } from './components/da-a/start-a/start-a.component';
import { StartBComponent } from './components/da-b/start-b/start-b.component';
import { StartCComponent } from './components/da-c/start-c/start-c.component';
import { DaAComponent } from './components/da-a/da-a/da-a.component';
import { DaBComponent } from './components/da-b/da-b/da-b.component';
import { DaCComponent } from './components/da-c/da-c/da-c.component';
import { EstateStatusComponent } from './components/common-slides/estate-status/estate-status.component';
import { ProjectPlanComponent } from './components/common-slides/project-plan/project-plan.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FooterMenuComponent } from './components/footer/footer-menu/footer-menu.component';
import { NavButtonsComponent } from './components/nav-buttons/nav-buttons.component';
import { ProjectPlanForwardComponent } from './components/da-c/project-plan-forward/project-plan-forward.component';
import { StatusSpecComponent } from './components/common-slides/status-spec/status-spec.component';
import { InfoErrorComponent } from './components/common-slides/info-error/info-error.component';
import { InfoSidebarComponent } from './components/sidebars/info-sidebar/info-sidebar.component';
import { CircleButtonComponent } from './components/circle-button/circle-button.component';
import { LinkLabelComponent } from './components/link-label/link-label.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { InfoSuccessComponent } from './components/common-slides/info-success/info-success.component';
import { ResidenceComponent } from './components/common-slides/residence/residence.component';
import { SchufaComponent } from './components/common-slides/schufa/schufa.component';
import { LoginLightSidebarComponent } from './components/sidebars/login-light-sidebar/login-light-sidebar.component';
import { LoginLightFormComponent } from './components/sidebars/login-light-sidebar/login-light-form/login-light-form.component';
import { LoginLightExitComponent } from './components/sidebars/login-light-sidebar/login-light-exit/login-light-exit.component';
import { LoginLightInfoComponent } from './components/sidebars/login-light-sidebar/login-light-info/login-light-info.component';
import { ParticipantsComponent } from './components/common-slides/participants/participants.component';
import { HouseCategoryComponent } from './components/common-slides/house-category/house-category.component';
import { ObjectCategoryComponent } from './components/common-slides/object-category/object-category.component';
import { AddressComponent } from './components/common-slides/address/address.component';
import { HouseDetailComponent } from './components/common-slides/house-detail/house-detail.component';
import { MfHouseDetailComponent } from './components/common-slides/mf-house-detail/mf-house-detail.component';
import { ObjectDetailComponent } from './components/common-slides/object-detail/object-detail.component';
import { ObjectNutzungComponent } from './components/common-slides/object-nutzung/object-nutzung.component';
import { ConditionEstateComponent } from './components/common-slides/condition-estate/condition-estate.component';
import { LoginLightViewComponent } from './da-views/login-light-view/login-light-view.component';
import { InputPinComponent } from './components/input-pin/input-pin.component';
import { ModernizationComponent } from './components/common-slides/modernization/modernization.component';
import { BauweiseComponent } from './components/common-slides/bauweise/bauweise.component';
import { EnergyComponent } from './components/common-slides/energy/energy.component';
import { ParkingComponent } from './components/common-slides/parking/parking.component';
import { IndexComponent } from './components/index/index.component';
import { ErbbaurechtComponent } from './components/common-slides/erbbaurecht/erbbaurecht.component';
import { MarketValueComponent } from './components/common-slides/market-value/market-value.component';
import { ExposeComponent } from './components/da-a/expose/expose.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { PersonalComponent } from './components/common-slides/personal/personal.component';
import { PersonalPartnerComponent } from './components/common-slides/personal-partner/personal-partner.component';
import { IncomeComponent } from './components/da-a/income/income.component';
import { AdditionalIncomeComponent } from './components/common-slides/additional-income/additional-income.component';
import { AdditionalIncomePartnerComponent } from './components/common-slides/additional-income-partner/additional-income-partner.component';
import { ChildrenComponent } from './components/common-slides/children/children.component';
import { AdditionalIncomeSingleComponent } from './components/common-slides/additional-income-single/additional-income-single.component';
import { PrivatkreditComponent } from './components/common-slides/privatkredit/privatkredit.component';
import { MieteComponent } from './components/common-slides/miete/miete.component';
import { HealthInsuranceComponent } from './components/common-slides/health-insurance/health-insurance.component';
import { VehiclesComponent } from './components/common-slides/vehicles/vehicles.component';
import { LoginLightSaveComponent } from './components/sidebars/login-light-sidebar/login-light-save/login-light-save.component';
import { FinancialAssetsComponent } from './components/common-slides/financial-assets/financial-assets.component';
import { PropertyInformationComponent } from './components/common-slides/property-information/property-information.component';
import { AddressSidebarComponent } from './components/sidebars/address-sidebar/address-sidebar.component';
import { PropertyChangeComponent } from './components/common-slides/property-change/property-change.component';
import { FinanceRequirementsComponent } from './components/common-slides/finance-requirements/finance-requirements.component';
import { NebenkostenSidebarComponent } from './components/sidebars/finance-needs/nebenkosten-sidebar/nebenkosten-sidebar.component';
import { EigenkapitalSidebarComponent } from './components/sidebars/finance-needs/eigenkapital-sidebar/eigenkapital-sidebar.component';
import { EigenleistungSidebarComponent } from './components/sidebars/finance-needs/eigenleistung-sidebar/eigenleistung-sidebar.component';
import { UebergabeSidebarComponent } from './components/sidebars/access/uebergabe-sidebar/uebergabe-sidebar.component';
import { BubbleNavComponent } from './components/bubble-nav/bubble-nav.component';
import { ProductInfoComponent } from './components/sidebars/access/uebergabe-sidebar/product-info/product-info.component';
import { FinanceNeedsComponent } from './components/common-slides/finance-requirements/finance-needs/finance-needs.component';
import { FinancePathComponent } from './components/common-slides/finance-requirements/finance-path/finance-path.component';
import { FinancePreferenceComponent } from './components/common-slides/finance-requirements/finance-preference/finance-preference.component';
import { FinanceAssemblyComponent } from './components/common-slides/finance-requirements/finance-assembly/finance-assembly.component';
import { DarlehenComponent } from './components/common-slides/finance-requirements/bausteine/darlehen/darlehen.component';
import { TilgungComponent } from './components/common-slides/finance-requirements/bausteine/tilgung/tilgung.component';
import { ZinsbindungComponent } from './components/common-slides/finance-requirements/bausteine/zinsbindung/zinsbindung.component';
import { SondertilgungComponent } from './components/common-slides/finance-requirements/bausteine/sondertilgung/sondertilgung.component';
import { LaufzeitComponent } from './components/common-slides/finance-requirements/bausteine/laufzeit/laufzeit.component';
import { AuszahlungsdatumComponent } from './components/common-slides/finance-requirements/bausteine/auszahlungsdatum/auszahlungsdatum.component';
import { AnlaufjahreComponent } from './components/common-slides/finance-requirements/bausteine/anlaufjahre/anlaufjahre.component';
import { ZinsmonateComponent } from './components/common-slides/finance-requirements/bausteine/zinsmonate/zinsmonate.component';
import { VerwendungComponent } from './components/common-slides/finance-requirements/bausteine/verwendung/verwendung.component';
import { RateComponent } from './components/common-slides/finance-requirements/bausteine/rate/rate.component';
import { PreviewComponent } from './components/preview/preview.component';
import { ProgressionComponent } from './components/progression/progression.component';
import { FinanceResultsComponent } from './components/common-slides/finance-requirements/finance-results/finance-results.component';
import { ContainerBausteinComponent } from './components/common-slides/finance-requirements/finance-results/result-components/container-baustein/container-baustein.component';
import { WarningBarComponent } from './components/warning-bar/warning-bar.component';
import { ExpressSidebarComponent } from './components/sidebars/access/express-sidebar/express-sidebar.component';
import { ContainerMultipleComponent } from './components/common-slides/finance-requirements/finance-results/result-components/container-multiple/container-multiple.component';
import { ContainerSingleComponent } from './components/common-slides/finance-requirements/finance-results/result-components/container-single/container-single.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CalendlyComponent } from './components/sidebars/calendly/calendly.component';
import { ContactComponent } from './components/sidebars/contact/contact.component';
import { OverviewComponent } from './components/sidebars/overview/overview.component';
import { TasksComponent } from './components/sidebars/overview/tasks/tasks.component';
import { UploadComponent } from './components/sidebars/overview/upload/upload.component';
import { LoginLightSaveLoggedinComponent } from './components/sidebars/login-light-sidebar/login-light-save/login-light-save-loggedin.component';
import { FinanceRequirementsForwardComponent } from './components/da-c/finance-requirements-forward/finance-requirements-forward.component';
import { FinanceNeedsForwardComponent } from './components/da-c/finance-requirements-forward/finance-needs-forward/finance-needs-forward.component';
import { DarlehenForwardComponent } from './components/da-c/finance-requirements-forward/needs-components/darlehen-forward.component';
import { VerbindlichkeitenForwardComponent } from './components/da-c/finance-requirements-forward/needs-components/verbindlichkeiten-forward.component';
import { KapitalForwardComponent } from './components/da-c/finance-requirements-forward/needs-components/kapital-forward.component';
import { ModernisierungForwardComponent } from './components/da-c/finance-requirements-forward/needs-components/modernisierung-forward.component';
import { TilgungForwardComponent } from './components/da-c/finance-requirements-forward/needs-components/tilgung-forward.component';



export function HttpLoaderFactory(httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(httpBackend, [
    {prefix: environment.host + "assets/content/da-texts-", suffix: ".json"},
    // {prefix: environment.host + "assets/localization/finance-request-enums-", suffix: ".json"},
    // {prefix: environment.host + "assets/localization/finance-request-label-", suffix: ".json"},
  ]);
}

@NgModule({
  declarations: [
    AppComponent,
    FrameComponent,
    DaAViewComponent,
    DaBViewComponent,
    DaCViewComponent,
    StartAComponent,
    StartBComponent,
    StartCComponent,
    DaAComponent,
    DaBComponent,
    DaCComponent,
    EstateStatusComponent,
    ProjectPlanComponent,
    HeaderComponent,
    FooterComponent,
    FooterMenuComponent,
    NavButtonsComponent,
    ProjectPlanForwardComponent,
    StatusSpecComponent,
    InfoErrorComponent,
    InfoSidebarComponent,
    CircleButtonComponent,
    LinkLabelComponent,
    ProgressBarComponent,
    InfoSuccessComponent,
    ResidenceComponent,
    SchufaComponent,
    LoginLightSidebarComponent,
    LoginLightFormComponent,
    LoginLightExitComponent,
    LoginLightInfoComponent,
    ParticipantsComponent,
    HouseCategoryComponent,
    ObjectCategoryComponent,
    AddressComponent,
    HouseDetailComponent,
    MfHouseDetailComponent,
    ObjectDetailComponent,
    ObjectNutzungComponent,
    ConditionEstateComponent,
    LoginLightViewComponent,
    InputPinComponent,
    ModernizationComponent,
    BauweiseComponent,
    EnergyComponent,
    ParkingComponent,
    IndexComponent,
    ErbbaurechtComponent,
    MarketValueComponent,
    ExposeComponent,
    FileUploadComponent,
    PersonalComponent,
    PersonalPartnerComponent,
    IncomeComponent,
    AdditionalIncomeComponent,
    AdditionalIncomePartnerComponent,
    ChildrenComponent,
    AdditionalIncomeSingleComponent,
    PrivatkreditComponent,
    MieteComponent,
    HealthInsuranceComponent,
    VehiclesComponent,
    LoginLightSaveComponent,
    FinancialAssetsComponent,
    PropertyInformationComponent,
    AddressSidebarComponent,
    PropertyChangeComponent,
    FinanceRequirementsComponent,
    NebenkostenSidebarComponent,
    EigenkapitalSidebarComponent,
    EigenleistungSidebarComponent,
    UebergabeSidebarComponent,
    BubbleNavComponent,
    ProductInfoComponent,
    FinanceNeedsComponent,
    FinancePathComponent,
    FinancePreferenceComponent,
    FinanceAssemblyComponent,
    DarlehenComponent,
    TilgungComponent,
    ZinsbindungComponent,
    SondertilgungComponent,
    LaufzeitComponent,
    AuszahlungsdatumComponent,
    AnlaufjahreComponent,
    ZinsmonateComponent,
    VerwendungComponent,
    RateComponent,
    PreviewComponent,
    ProgressionComponent,
    FinanceResultsComponent,
    ContainerBausteinComponent,
    WarningBarComponent,
    ExpressSidebarComponent,
    ContainerMultipleComponent,
    ContainerSingleComponent,
    LoaderComponent,
    CalendlyComponent,
    ContactComponent,
    OverviewComponent,
    TasksComponent,
    UploadComponent,
    LoginLightSaveLoggedinComponent,
    FinanceRequirementsForwardComponent,
    FinanceNeedsForwardComponent,
    DarlehenForwardComponent,
    VerbindlichkeitenForwardComponent,
    KapitalForwardComponent,
    ModernisierungForwardComponent,
    TilgungForwardComponent
  ],
  imports: [
    BrowserModule,
    FmLibraryModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    StarRatingModule,
    NgxFileDropModule,
    MatFormFieldModule, 
    MatDatepickerModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MainPipe,
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'de-DE',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend]
      },
  }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CodeInputModule
  ],
  providers: [
    { 
      provide: NG_ENTITY_SERVICE_CONFIG,  
      useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' } 
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
  entryComponents: [AppComponent]
})

// bootstrap: [AppComponent]
/**
 * decide if digital assistant is rendered as webcomponent or webapp
 */
 export class AppModule implements DoBootstrap {
  constructor(private injector: Injector) {
    // render as web component
    if (!environment.daAsWebApp) {
      const assistentA = createCustomElement(DaAViewComponent, {
        injector: this.injector
      });
      const assistentB = createCustomElement(DaBViewComponent, {
        injector: this.injector
      });
      const assistentC = createCustomElement(DaCViewComponent, {
        injector: this.injector
      });
      customElements.define('digital-assistant', assistentA);
      customElements.define('digital-assistant-b', assistentB);
      customElements.define('digital-assistant-c', assistentC);
    }
  }

  ngDoBootstrap(app: { bootstrap: (arg0: typeof AppComponent) => void; }) {
    // render as webapp
    if (environment.daAsWebApp) {
      app.bootstrap(AppComponent);
    }
  }
}
