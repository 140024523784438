import { AfterViewInit, Component, EventEmitter, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { switchesContent } from 'src/assets/content/switches';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-health-insurance',
  templateUrl: './health-insurance.component.html',
  styleUrls: ['./health-insurance.component.scss']
})
export class HealthInsuranceComponent implements AfterViewInit {
  public slideName: string = 'health-insurance';
  next: string = 'vehicles';
  localValue!: string;
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  title: string = 'HEALTH_INSURANCE.TITLE';
  withChildren: boolean = false;
  tiles = tilesContent['HEALTH_INSURANCE_SINGLE'].items;
  tabs = switchesContent['MIETE_HEALTH'].items;
  selectedTab = switchesContent['MIETE_HEALTH'].items[0];
  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('content') content!: TemplateRef<any>;
  @ViewChild('contentPartner') contentPartner!: TemplateRef<any>;
  @ViewChild('count') count!: any;
  @ViewChild('feeOne') feeOne!: any;
  @ViewChild('feeTwo') feeTwo!: any;
  @ViewChild('abgezogen1') abgezogen1!: any;
  @ViewChild('abgezogen2') abgezogen2!: any;
  @ViewChild('kinder1') kinder1!: any;
  @ViewChild('kinder2') kinder2!: any;
  @ViewChild('feeKinderOne') feeKinderOne!: any;
  @ViewChild('feeKinderTwo') feeKinderTwo!: any;
  @ViewChildren('inputs') inputsList!: QueryList<any>;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public contentService: ContentService,
  ) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setStatus();
    });
  }


  setStatus() {
    const participants = this.dataQuery.getEntity(1)?.['participants'];
    const children = this.dataQuery.getEntity(1)?.['KinderErfassung'];

    if (!!participants && participants !== 'allein') {
      this.title = 'HEALTH_INSURANCE.TITLE_PARTNER';
      this.tiles = tilesContent['HEALTH_INSURANCE'].items;
    }

    if (!!children && children === 'VORHANDENE_KINDER') {
      this.withChildren = true;
    }
    this.disabled = true;
    const parent = this.dataQuery.getEntity(1)?.['haushaltPersonKKV'];
    const status = this.dataQuery.getEntity(1)

    if (!!parent) {
      this.localValue = parent;
      this.tiles = this.contentService.setTileChecked(this.tiles, parent);
      if (parent === 'Ja' || parent === 'NeinPlusPartner') {
        this.contentService.setTilesContent(this.tiles, parent, this.content);
        this.setSwitch(status?.['countKkv'], 'count');
        this.setTileStatus();
      } else if (parent === 'JaPlusPartner') {
        this.contentService.setTilesContent(this.tiles, parent, this.contentPartner);
        this.setTileStatus();
      } else {
        this.disabled = false;
        this.contentService.setSlideIndexState(this.slideName, false, 'success');
        this.stateChange.emit(true);
      }
    }
    this.daNextSlideService.update(1, { disabled: this.disabled });
  }

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'next') {
        if (this.allState !== 'success') {
          this.showState = true;
          this.setTileStatus();
        } else {
          this.setTileStatus('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 50)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  setValue(e: string) {
    this.localValue = e;
    this.daService.update(1, { haushaltPersonKKV: e });
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
    if (e === 'Ja' || e === 'NeinPlusPartner') {
      this.contentService.setTilesContent(this.tiles, e, this.content);
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.setTileStatus();
    } else if (e === 'JaPlusPartner') {
      this.contentService.setTilesContent(this.tiles, e, this.contentPartner);
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.setTileStatus();
    } else {
      this.disabled = false;
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.contentService.setNav('next', 'health-insurance', this.slideTo, this.next);
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
    this.stateChange.emit(true);
  }

  setSwitch(e: string, type: string) {
    switch (type) {
      case 'count': {
        this.daService.update(1, { countKkv: e });
      }
        break;
      case 'tabs': {
        if (e === 'number1') {
          this.selectedTab = this.tabs[0];
        } else {
          this.selectedTab = this.tabs[1];
        }
      }
        break;
      case 'abgezogen1': {
        this.daService.update(1, { abgezogenKkv1: e });
      }
        break;
      case 'abgezogen2': {
        this.daService.update(1, { abgezogenKkv2: e });
      }
        break;
      case 'kinder1': {
        this.daService.update(1, { kinderKkv1: e });
      }
        break;
      case 'kinder2': {
        this.daService.update(1, { kinderKkv2: e });
      }
        break;
    }
    this.setTileStatus();
  }

  validateInputValue(e: any, element: any) {
    setTimeout(() => {
      let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
      element.touched = !!value ? true : false;
      const isValid = this.contentService.validatePattern(value, element.validationType);
      const isValidAmount: any = this.contentService.minMax(value, 0, 9999);
      element.error = isValid && isValidAmount === true ? false : true;

      if (!element.error) {
        switch (element.name) {
          case 'kkv1': {
            this.daService.update(1, { kkv1: value });
            break;
          }
          case 'kkv3': {
            this.daService.update(1, { kkv3: value });
            break;
          }
          case 'kkv2': {
            this.daService.update(1, { kkv2: value });
            break;
          }
          case 'kkv4': {
            this.daService.update(1, { kkv4: value });
            break;
          }
        }
      } else {
        this.disabled = true;
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.setAllState('error', 'ERROR');
        this.stateChange.emit(true);
      }
      this.setTileStatus();
    }, 100);
  }

  setTabsStatus() {
    const status = this.dataQuery.getEntity(1);
    let validationState1 = 'warning';
    let validationState2 = 'warning';
    if (!!status) {
      // tab Person 1
      if (!!status['kkv1'] && !!status['abgezogenKkv1']) {
        if (this.withChildren) {
          if (!!status['kinderKkv1']) {
            if (status['kinderKkv1'] === 'true') {
              if (!!status['kkv2']) {
                validationState1 = 'success';
              }
            } else {
              validationState1 = 'success';
            }
          }
        } else {
          validationState1 = 'success';
        }
      }
      // tab Person 2
      if (!!status['kkv3'] && !!status['abgezogenKkv2']) {
        if (this.withChildren) {
          if (!!status['kinderKkv2']) {
            if (status['kinderKkv2'] === 'true') {
              if (!!status['kkv4']) {
                validationState2 = 'success';
              }
            } else {
              validationState2 = 'success';
            }
          }
        } else {
          validationState2 = 'success';
        }
      }
    }

    this.tabs = this.contentService.setTileStatusCheckbox(this.tabs, 'number1', validationState1);
    this.tabs = this.contentService.setTileStatusCheckbox(this.tabs, 'number2', validationState2);

    if (this.feeOne?.error || this.feeKinderOne?.error) {
      this.tabs = this.contentService.setTileStatusCheckbox(this.tabs, 'number1', 'error');
    }
    if (this.feeTwo?.error || this.feeKinderTwo?.error) {
      this.tabs = this.contentService.setTileStatusCheckbox(this.tabs, 'number2', 'error');
    }
    if (this.selectedTab.value === 'number1') {
      this.selectedTab = this.tabs[0];
    } else {
      this.selectedTab = this.tabs[1];
    }
  }

  setTileStatus(type: string = 'default') {
    const inputFields = this.inputsList.toArray();
    const status = this.dataQuery.getEntity(1);
    let valid = false;

    if (!!status) {
      if (!!status['kkv1'] && !!status['abgezogenKkv1']) {
        if (this.withChildren) {
          if (!!status['kinderKkv1']) {
            if (status['kinderKkv1'] === 'true') {
              valid = !!status['kkv2'] ? true : false;
            } else {
              valid = true;
            }
          }
        } else {
          valid = true;
        }
      }
      if (status?.['haushaltPersonKKV'] === 'JaPlusPartner') {
        this.setTabsStatus();
        valid = this.tabs[0].state === 'success' && this.tabs[1].state === 'success' ? true : false;
      }


      if (valid) {
        this.tiles = this.contentService.setTileStatus(this.tiles, this.localValue, 'success', 'success');
        this.disabled = false;
        this.contentService.setSlideIndexState(this.slideName, false, 'success');
        this.setAllState('success', 'COMPLETE');
      } else {
        this.tiles = this.contentService.setTileStatus(this.tiles, this.localValue, 'warning', 'success');
        this.contentService.setSlideIndexState(this.slideName, false, 'warning');
        this.setAllState('warning', 'INCOMPLETE');
        this.disabled = true;
      }
      this.stateChange.emit(true);
    }

    if(inputFields?.length > 0) {
      inputFields.map((item: any) => {
        if(item.error) {
          this.tiles = this.contentService.setTileStatus(this.tiles, this.localValue, 'error', 'success');
          this.contentService.setSlideIndexState(this.slideName, false, 'error');
          this.setAllState('error', 'ERROR');
          this.stateChange.emit(true);
          this.disabled = true;
        }
      })
    }

    if(!this.disabled) {
      if (type === 'test') {
        this.inputDisabled = true;
        this.testValid = false;
      }
    } else {
      this.testValid = true;
      this.inputDisabled = false;
    }

    if(this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }

  }
}
