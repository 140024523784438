import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
  rowsPart1 = tilesContent['TASKS_PART1'].items;
  rowsPart2 = tilesContent['TASKS_PART2'].items;
  rowsPart3 = tilesContent['TASKS_PART3'].items;
  rowsPart4 = tilesContent['TASKS_PART4'].items;
  rowsPart5 = tilesContent['TASKS_PART5'].items;
  rowsPart6 = tilesContent['TASKS_PART6'].items;

  part1All: boolean = false;
  part2All: boolean = false;
  part3All: boolean = false;
  part4All: boolean = false;
  part5All: boolean = false;
  part6All: boolean = false;

  @Output() public buttonValue:EventEmitter<any> = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  getButtonValue(e: string) {
    this.buttonValue.emit(e);
  }

  getCheckboxValue(e: any) {
    console.log('checkbox', e);
  }

}
