import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { tilesContent } from 'src/assets/content/tiles';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';

@Component({
  selector: 'app-finance-path',
  templateUrl: './finance-path.component.html',
  styleUrls: ['./finance-path.component.scss']
})
export class FinancePathComponent implements OnInit {
  tiles = tilesContent['ERMITTLUNGSWEG'].items;
  localValue!: string;

  @Output() public nextItem: EventEmitter<any> = new EventEmitter<string>();

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      const status = this.dataQuery.getEntity(1)?.['ermittlungsweg'];
      if(!!status) {
        this.setValue(status);
      }
    })
  }

  setValue(e: string) {
    this.localValue = e;
    this.daService.update(1, { ermittlungsweg: e });
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
    this.nextItem.emit(e);
  }

}
