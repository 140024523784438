import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-container-baustein',
  templateUrl: './container-baustein.component.html',
  styleUrls: ['./container-baustein.component.scss']
})
export class ContainerBausteinComponent implements OnInit {

  @Input() item: any;
  @Input() itemText: any;
  @Input() showInfo: string = 'true';
  @Output() public info: EventEmitter<any> = new EventEmitter<string>();
  constructor(
    public screen: LayoutService,
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
  }

}
