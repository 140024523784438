import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { dropdownsContent } from 'src/assets/content/dropdowns';

@Component({
  selector: 'app-nebenkosten-sidebar',
  templateUrl: './nebenkosten-sidebar.component.html',
  styleUrls: ['./nebenkosten-sidebar.component.scss']
})
export class NebenkostenSidebarComponent implements AfterViewInit {

  inputValid: boolean = true;
  steuerItems = dropdownsContent['STEUER'].items;
  selectedSteuerItem = { 'value': '', 'viewValue': 'Bundesland' };
  hasVerbindlichkeiten: boolean = false;
  showButtons: boolean = false;

  @Input() modal!: NgbModalRef;
  @Input() base!: string;
  @Input() noTax!: boolean;
  @Output() public nebenkostenDone: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('steuerprozent') steuerprozent!: any;
  @ViewChild('steuerbetrag') steuerbetrag!: any;
  @ViewChild('notarprozent') notarprozent!: any;
  @ViewChild('notarbetrag') notarbetrag!: any;
  @ViewChild('maklerprozent') maklerprozent!: any;
  @ViewChild('maklerbetrag') maklerbetrag!: any;
  @ViewChild('gesamtbetrag') gesamtbetrag!: any;
  @ViewChildren('abloesen') abloesenList!: QueryList<any>;
  @ViewChildren('abloesenSumme') abloesenSummeList!: QueryList<any>;

  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private daService: DataService,
    private dataQuery: DataQuery,
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngAfterViewInit(): void {
    this.setStatus();
  }

  setStatus() {
    const status = this.dataQuery.getEntity(1);
    if(status?.['verbindlichkeitenAnzahl'] && status?.['verbindlichkeitenAnzahl'] > 0) {
      if(status?.['verbindlichkeiten']) {
        if (status?.['verbindlichkeiten'].length > 1 || this.contentService.getNestedValue('verbindlichkeiten', 'verbindlichkeitTyp', 0) !== 'leasing') {
          this.hasVerbindlichkeiten = true;
        }
      }
    }
    this.setDisabled();
    this.calculateSum();
    setTimeout(() => {this.showButtons = true}, 1100);
  }

  validateInput(e: any, name: string, i: number = 0) {
    setTimeout(() => {
      let element;
      let value = e.target?.value ? e.target?.value : e;
      value = typeof value === 'string' ? value : value[0]; 
      switch (name) {
        case 'steuerprozent': {
          element = this.steuerprozent;
          this.validateNumbers(value, element);
          break;
        }
        case 'notarprozent': {
          element = this.notarprozent;
          this.validateNumbers(value, element);
          break;
        }
        case 'maklerprozent': {
          element = this.maklerprozent;
          this.validateNumbers(value, element);
          break;
        }
        case 'steuerbetrag': {
          element = this.steuerbetrag;
          this.validateNumbers(value, element);
          break;
        }
        case 'notarbetrag': {
          element = this.notarbetrag;
          this.validateNumbers(value, element);
          break;
        }
        case 'maklerbetrag': {
          element = this.maklerbetrag;
          this.validateNumbers(value, element);
          break;
        }
        case 'abloesen': {
          this.abloesenList.toArray().map((item: any) => {
            let number = item.name.slice(item.name.length - 1);
            number = parseInt(number);
            if(number === i) {
              element = item;
            }
          })
          let vertraege: any[] = [];
          const status = this.dataQuery.getEntity(1)?.['verbindlichkeiten'];
          if (status) {
            status.map((vertrag: any) => {
              if (vertrag.id === i) {
                vertrag = { ...vertrag, 'abloesen': value };
              }
              vertraege.push(vertrag);
            });
            this.daService.update(1, { verbindlichkeiten: vertraege });
          }
          this.calculateInput(value, element);
          break;
        }
      };
    }, 500);
  }

  validateNumbers(e: string, element: any) {
    setTimeout(() => {
      element.touched = false;
      let isValidAmount: any = true;
      let isValid = this.contentService.validatePattern(e, element.validationType);

      if (element.name === 'steuerprozent' || element.name === 'notarprozent' || element.name === 'maklerprozent') {
        isValidAmount = this.contentService.minMax(e, 0, 20);
      }

      element.error = isValid && isValidAmount === true ? false : true;
      if (!element.error) {
        this.calculateInput(e, element);
      }
      element.touched = true;
      this.setDisabled();
    }, 300);
  }

  calculateInput(e: any, element: any) {
    let total = this.base === 'kaufpreis' ? this.dataQuery.getEntity(1)?.['kaufpreis'] : this.base === 'grundstueck' ? this.dataQuery.getEntity(1)?.['grundstueckspreis'] : this.dataQuery.getEntity(1)?.['herstellungskosten'];
    if (!!total) {
      switch (element.name) {
        case 'steuerprozent': {
          this.daService.update(1, { steuerprozent: e });
          let steuer = this.contentService.convertPercentToNumber(this.contentService.convertStringNumber(e, 3), this.contentService.convertStringNumber(total));
          this.daService.update(1, { steuerbetrag: steuer.toLocaleString('de-DE', { minimumFractionDigits: 2 }) });
          break;
        }
        case 'notarprozent': {
          this.daService.update(1, { notarprozent: e });
          let notar = this.contentService.convertPercentToNumber(this.contentService.convertStringNumber(e, 3), this.contentService.convertStringNumber(total));
          this.daService.update(1, { notarbetrag: notar.toLocaleString('de-DE', { minimumFractionDigits: 2 }) });
          break;
        }
        case 'maklerprozent': {
          this.daService.update(1, { maklerprozent: e });
          let makler = this.contentService.convertPercentToNumber(this.contentService.convertStringNumber(e, 3), this.contentService.convertStringNumber(total));
          this.daService.update(1, { maklerbetrag: makler.toLocaleString('de-DE', { minimumFractionDigits: 2 }) });
          break;
        }
        case 'steuerbetrag': {
          this.daService.update(1, { steuerbetrag: e });
          let steuer = this.contentService.convertNumberToPercent(this.contentService.convertStringNumber(e, 2), this.contentService.convertStringNumber(total));
          this.daService.update(1, { steuerprozent: steuer.toLocaleString('de-DE', { minimumFractionDigits: 3 }) });
          break;
        }
        case 'notarbetrag': {
          this.daService.update(1, { notarbetrag: e });
          let notar = this.contentService.convertNumberToPercent(this.contentService.convertStringNumber(e, 2), this.contentService.convertStringNumber(total));
          this.daService.update(1, { notarprozent: notar.toLocaleString('de-DE', { minimumFractionDigits: 3 }) });
          break;
        }
        case 'maklerbetrag': {
          this.daService.update(1, { maklerbetrag: e });
          let makler = this.contentService.convertNumberToPercent(this.contentService.convertStringNumber(e, 2), this.contentService.convertStringNumber(total));
          this.daService.update(1, { maklerprozent: makler.toLocaleString('de-DE', { minimumFractionDigits: 3 }) });
          break;
        }
      }
    }
    this.calculateSum();
  }

  calculateSum() {
    let nebenkosten = 0;
    const status = this.dataQuery.getEntity(1);
    nebenkosten = !!status?.['steuerbetrag'] ? this.contentService.convertStringNumber(status?.['steuerbetrag'], 2) + nebenkosten : nebenkosten;
    nebenkosten = !!status?.['notarbetrag'] ? this.contentService.convertStringNumber(status?.['notarbetrag'], 2) + nebenkosten : nebenkosten;
    nebenkosten = !!status?.['maklerbetrag'] ? this.contentService.convertStringNumber(status?.['maklerbetrag']) + nebenkosten : nebenkosten;

    if (status?.['verbindlichkeiten'] && status?.['verbindlichkeiten'].length > 0) {
      status?.['verbindlichkeiten'].map((item: any) => {
        if (item.abloesen === 'true') {
          nebenkosten = this.contentService.convertStringNumber(item.restschuld) + nebenkosten;
        }
      });
    }

    this.daService.update(1, { nebenkosten: nebenkosten.toLocaleString('de-DE', { minimumFractionDigits: 2 }) });
  }

  getSelectedValue(e: string, element: any) {
    this.calculateInput(e, element)
    this.daService.update(1, { steuerprozent: e });
    this.daService.update(1, { nebenkostenAktiv: 'true' });
  }

  setLocalValue(num: number, field: string) {
    let parsedValue = this.contentService.getNestedValue('verbindlichkeiten', field, num) ?? '';
    return parsedValue;
  }

  setDisabled() {
    if (this.steuerprozent) {
      if (this.steuerprozent.error || this.maklerprozent.error || this.notarprozent.error) {
        this.inputValid = false;
      } else {
        this.inputValid = true;
      }
    } else {
      if (this.maklerprozent.error || this.notarprozent.error) {
        this.inputValid = false;
      } else {
        this.inputValid = true;
      }
    }

    this.daService.update(1, { nebenkostenInValid: !this.inputValid });
    this.daService.update(1, { nebenkostenAktiv: this.inputValid && !!this.contentService.getValue('steuerprozent') ? 'true' : '' });
  }

  finishInput() {
    this.nebenkostenDone.emit();
    this.dismiss();
  }

  dismiss() {
    document.body.style.overflowY = 'auto';
    this.modal.dismiss();
  }
}
