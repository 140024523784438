<button 
    class="da-circle-button"
    (click)="getValue()" 
    [ngClass]="{
        'mobile' : screen.isSmallest(), 
        'top-left' : orientation === 'top-left', 
        'top-right' : orientation === 'top-right', 
        'bottom-right' : orientation === 'bottom-right', 
        'bottom-left' : orientation === 'bottom-left',
        'header-base' : background === 'header-base',
        'body-base' : background === 'body-base',
        'header-secondary' : background === 'header-secondary' 
        }"
    [ngStyle]="{ 'background-color' : background}"
    >
    <div class="da-circle-button-wrapper">
        <svg-icon
            *ngIf="svg"
            class="da-circle-button-icon svg" 
            [ngClass]="{
            'mobile' : screen.isSmallest(), 
            'top-left' : orientation === 'top-left', 
            'top-right' : orientation === 'top-right', 
            'bottom-right' : orientation === 'bottom-right', 
            'bottom-left' : orientation === 'bottom-left' 
            }"
            [src]="svg"
        > </svg-icon>
    </div>
</button>
