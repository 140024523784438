import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-login-light-save',
  templateUrl: './login-light-save.component.html',
  styleUrls: ['./login-light-save.component.scss', '../login-light-sidebar.component.scss']
})
export class LoginLightSaveComponent implements OnInit {

  @Output() public logout: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onCloseLoginLightSidebar() {
    this.logout.emit();
  }
}
