import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DaClickpathService } from 'src/app/state/click-path/da-clickpath.service';
import { DaClickpathStore } from 'src/app/state/click-path/da-clickpath.store';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { DaNextSlideStore } from 'src/app/state/next-slide/da-next-slide.store';
import { UserService } from 'src/app/state/user/user.service';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { Debounce } from 'lodash-decorators';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
import { DaClickpathQuery } from 'src/app/state/click-path/da-clickpath.query';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-login-light-view',
  templateUrl: './login-light-view.component.html',
  styleUrls: ['./login-light-view.component.scss']
})
export class LoginLightViewComponent implements OnInit, OnDestroy, AfterViewChecked { // URL: http://localhost:4200/login-light/244a2252-333d-4372-84f2-83f9f2df4573

  private routeSub!: Subscription;
  private routeEmail!: Subscription;
  private uuid!: string;
  sliderIndex: any;
  accessLinkRequested: boolean = false;
  accessLinkRequestSuccessful: boolean = false;
  evaluatePin: boolean = false;
  evaluatePinSuccessful: boolean = false;
  pinRequested: boolean = false;
  pinRequestSuccessful: boolean = false;
  isLoading: boolean = false;
  pin: string = '';
  mobileSidebarIsExtended: boolean = false;
  isMobile!: boolean;
  videoSrc!: string;
  emailIsValid: boolean = true; /* TODO: Muss für Validierung initial auf false gesetzt werden */
  sidebarIsLoaded: boolean = false;


  userDataForm: FormGroup = new FormGroup({
    mail: new FormControl('', Validators.required),
    pin: new FormControl('')
  });

  loginComplete: boolean = false;
  logo: any;
  name: string = '';
  agb: string = '';
  datenschutz: string = '';
  // cookies: string = '';
  impressum: string = '';

  constructor(
    private daDataService: DataService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private daService: DataService,
    private router: Router,
    private daClickpathStore: DaClickpathStore,
    private daNextSlideStore: DaNextSlideStore,
    private daClickpathService: DaClickpathService,
    private daClickpathQuery: DaClickpathQuery,
    private daNextSlideService: DaNextSlideService,
    private renderer: Renderer2,
    private emailService: EmailService,
    private configurationQuery: ConfigurationQuery,
    private userService: UserService,
    private layoutService: LayoutService,
    private cdr: ChangeDetectorRef) {
    this.sliderIndex = this.daClickpathQuery.getEntity(1)?.slideIndex;
  }

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.layoutService.updateScreenSizes();
  }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.uuid = params['uuid'];
    });
    this.routeEmail = this.route.queryParams.subscribe(params => {
      if(params['usrmail']) {
        this.userDataForm.controls['mail'].setValue(atob(params['usrmail']))
      }
    });

    this.isMobile = this.layoutService.isMobile();
    this.setVideoSrc(this.isMobile);
    setTimeout(() => {
      this.sidebarIsLoaded = true;
    }, 300);
    // get custom content for each mandant
    this.configurationQuery.configuration.subscribe((res: any) => {
      if (res) {
        this.logo = res.kontaktheaderlogo;
        this.name = res.unternehmenseinstellungen.unternehmensname_header;
        this.agb = res.kontaktlinkagb;
        this.datenschutz = res.kontaktlinkdse;
        this.impressum = res.kontaktlinkimpressum;
        // this.cookies = res.kontaktlinkcookies;
      }
    });
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }

  onSubmit() {
    this.isLoading = true;
    if (!this.pinRequestSuccessful) {
      if (this.uuid) {
        this.sendPinRequest();
      }
    } else {
      if (this.uuid) {
        this.loginWithPin();
      }
    }
  }

  onPinChanged(pin: string) {
    // TODO: Event optional später verwenden oder entfernen
  }

  onPinCompleted(pin: string) {
    this.userDataForm.controls['pin'].setValue(pin);
    this.pin = pin;
  }

  getUuid() {
    return this.uuid;
  }

  getLoginLightLink() {
    let type = environment.webappType === 'a' ? 'n01a' : environment.webappType === 'b' ? 'n01b' : 'n01c';
    this.emailService.setEmail(type, this.userDataForm.controls['mail'].value, '', false, 'resendaccesslink', true);
        this.accessLinkRequestSuccessful = true;
        this.accessLinkRequested = true;
  }

  sendPinRequest() {
    this.pinRequested = true;
    this.daDataService.setLoginLightUuid(this.uuid);
    this.userService.getDAProgressAccess(this.userDataForm.value.mail, this.uuid).subscribe({
      next: () => {
        this.pinRequestSuccessful = true;
        this.isLoading = false;
      },
      error: (error: any) => {
        if(!!error.statusText && error.statusText === 'OK' && error.status === 200) {
          this.pinRequestSuccessful = true;
          this.isLoading = false;
        } else {
          this.pinRequestSuccessful = false;
          this.isLoading = false;
        }

      }
    });
  }

  loginWithPin() {
    this.userService.getDAProgress(this.userDataForm.value.mail, this.uuid, this.userDataForm.value.pin).subscribe({
      next: (result) => {
        this.evaluatePin = true;
        this.evaluatePinSuccessful = true;
        this.isLoading = false;
        this.loginComplete = true;
        this.daClickpathService.update(1, { clickpath: result.progressdata['clickpath'] });
        this.daClickpathService.update(1, { slideIndex: result.progressdata['slideIndex'] });
        this.daNextSlideService.update(1, { next: result.progressdata['nextSlide'], timestamp: new Date });
        this.daService.setCurrentSlide(result.progressdata['nextSlide']);
        const data = result.progressdata;
        delete data.clickpath;
        delete data.slideIndex;
        delete data.nextSlide;
        this.daService.refill(result.progressdata);
        this.userService.refill(result.metadata);
        const mail = this.userDataForm.value['mail'];
        this.daService.update(1, {
          mail: mail,
          uuid: this.uuid
        });
        this.router.navigate(['/']);
      },
      error: () => {
        this.evaluatePin = true;
        this.evaluatePinSuccessful = false;
        this.isLoading = false;
      }
    });
  }

  setClickpath(clickpath: string[]) {
    this.daClickpathStore.reset();
    this.daClickpathService.add({ id: 1, clickpath: clickpath, slideIndex: this.sliderIndex });
  }

  deviceIsMobileOrSmallTablet() {
    return this.layoutService.isMobileOrSmallTablet();
  }

  deviceIsMobile() {
    if (this.layoutService.isMobile() !== this.isMobile) {
      this.isMobile = this.layoutService.isMobile();
      this.setVideoSrc(this.isMobile);
    }
    return this.layoutService.isMobile();
  }

  toggleMobileSidebar() {
    this.mobileSidebarIsExtended = !this.mobileSidebarIsExtended;
  }

  setVideoSrc(isMobile: boolean) {
    this.videoSrc = isMobile ?
      'assets/videos/login-light-mobile.mp4' :
      'assets/videos/login-light.mp4';
  }

  onEmailInfoButtonClick(type: any) {
    console.log('Click! :)');
    this.layoutService.openInfo(type);
  }

  validateEmail(e: any, element: any) {
    let value = e[0];
    element.touched = !!value ? true : false;
    element.placeholder = '';
    this.emailIsValid = this.contentService.validatePattern(value, element.validationType);
    if (!this.emailIsValid) {
      element.error = true
    } else {
      element.error = false
    }
  }

  getMailValue() {
    return this.userDataForm.get('mail')?.value;
  }
}
