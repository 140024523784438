import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { federalStates } from 'src/assets/content/steuer';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  public slideName: string = 'address';
  next: string = 'object-detail';
  address: any = { 'plz': '', 'ort': '', 'strasse': '', 'hausnummer': '' };
  plzItems: any = [{ 'value': '', 'viewValue': 'COMMON.SELECT' }];
  selectedPlz: any = this.plzItems[0];
  showPlzSelect: boolean = false;
  mitStrasse!: string;
  select!: string;
  plzDisabled: boolean = true;
  cityDisabled: boolean = true;
  streetDisabled: boolean = true;
  nrDisabled: boolean = true;
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  federalStates = federalStates;
  container1State: string = 'warning';
  container2State: string = 'warning';
  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE'

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('objectPlz') objectPlz!: any;
  @ViewChild('objectSelect') objectSelect!: any;
  @ViewChild('objectOrt') objectOrt!: any;
  @ViewChild('objectStrasse') objectStrasse!: any;
  @ViewChild('objectMitStrasse') objectMitStrasse!: any;
  @ViewChild('objectNr') objectNr!: any;

  constructor(
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public daNextSlideService: DaNextSlideService,
    public screen: LayoutService
  ) {

  }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit() {
    this.daService.setCurrentSlide(this.slideName);
    this.inputDisabled = false;
    setTimeout(() => {
      this.getValues();
    });
  }

  getValues() {
    this.disabled = true;
    this.testValid = true;
    const status = this.dataQuery.getEntity(1)?.['objectAddress'];
    this.select = this.dataQuery.getEntity(1)?.['objectSelect'];
    const mitStrasse = this.dataQuery.getEntity(1)?.['objectAddressMitStrasse'];

    this.plzDisabled = (!!status && !!status.plz) || this.select === 'true' ? false : true;
    this.cityDisabled = (!!status && !!status.ort) || this.select === 'false' ? false : true;

    if (!!status) {
      this.mitStrasse = mitStrasse ?? '';

      if(this.mitStrasse === 'true') {
        this.nrDisabled = false;
        this.streetDisabled = false;
      }

      this.setNext();
    }
    setTimeout(() => { this.setContainerState('init') }, 100);
  }

  validateInputValue(e: any, element: any) {
    let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
    element.touched = value ? true : false;
    element.value = value;
    const isValid = this.contentService.validatePattern(value, element.validationType);
    if ((element.name === 'street' || element.name === 'streetNr') && this.mitStrasse === 'false') {
      element.error = isValid;
    } else {
      element.error = isValid && value.length > 0 ? false : true;
    }
    this.storeInputValue(element);
  }

  getPlz(value: any, element: any) {
    let plzValue = value[0] ? value[0] : value.srcElement.value ? value.srcElement.value : '';
    element.touched = plzValue ? true : false;
    element.value = plzValue;
    const isValid: any = this.contentService.minMaxLength(plzValue, 5, 5);
    if (isValid === true) {
      this.contentService.getByPlz(plzValue).then((result: any) => {
        result.subscribe({
          next: (data: any) => {
            element.touched = true;
            if (data[0]) {
              const federalState = data[0].federalState.key;
              Object.keys(federalStates).map((item: any) => {
                if (item = federalState) {
                  this.daService.update(1, { steuerprozent: federalStates[item as keyof typeof federalStates] });
                }
              });

              this.objectOrt.value = data[0].name;
              this.objectOrt.touched = true;
              this.objectOrt.error = false;
              element.error = false;
              setTimeout(() => { this.cityDisabled = false; }, 500);
              this.storeInputValue(this.objectPlz);
              this.storeInputValue(this.objectOrt);
            } else {
              element.error = true;
              this.objectOrt.touched = false;
            }
          },
          error: (error: any) => console.log(error)
        })
      });
    }

    if (isValid?.tooLong || isValid?.tooShort) {
      element.touched = true;
      element.error = true;
    }

    setTimeout(() => { this.setContainerState() }, 100);
  }

  // wird vielleicht später wieder gebraucht
  // getSelectedPlz(e: string) {
  //   this.plzItems.map((item: any) => {
  //     if (item.value === e) {
  //       this.selectedPlz.value = e;
  //       this.selectedPlz.viewValue = e;
  //     }
  //   })
  //   this.objectPlz.value = e;
  //   this.storeInputValue(this.objectPlz);
  // }

  getCity(value: any, element: any) {
      // this.showPlzSelect = false;
      let cityValue = value[0] ? value[0] : value.srcElement.value ? value.srcElement.value : '';
      element.touched = !!cityValue ? true : false;
      this.objectOrt.error = false;
      this.objectOrt.value = cityValue;
      const isValid = this.contentService.validatePattern(cityValue, element.validationType);
      // let selectItems: { value: any; viewValue: any; }[] = [];

      if (isValid === true && !!cityValue) {
        this.storeInputValue(this.objectOrt);
        // this.contentService.getByCity(this.objectOrt.value, '1').then((result: any) => {
        //   result.subscribe({
        //     next: (data: any) => {
        //       if (data[0]) {            
        //         data.map((item: any) => {
        //           if (item.name === value.target.value) {
        //             this.objectPlz.value = item.postalCode;
        //             this.storeInputValue(this.objectPlz);
        //             return
        //           }
        //         })

        //       }
        //     },
        //     error: (error: any) => console.log(error)
        //   })
        // });

        // wird vielleicht später wieder gebraucht
        // this.collectSelectItems(value.target.value, selectItems, '1').then((result) =>{
        //   if (this.plzItems.length  === 50) {
        //     this.collectSelectItems(value.target.value, selectItems, '2').then((result) => {
        //       if (this.plzItems.length === 100) {
        //         this.collectSelectItems(value.target.value, selectItems, '3').then((result) => {
        //           if (this.plzItems.length === 150) {
        //             this.collectSelectItems(value.target.value, selectItems, '4').then((result) => {
        //               this.showPlzSelect = true;
        //               console.log(this.plzItems.length);
        //             });
        //           } else {
        //             this.showPlzSelect = true;
        //           }
        //         });
        //       } else {
        //         this.showPlzSelect = true;
        //       }
        //     });
        //   } else {
        //     this.showPlzSelect = this.plzItems.length > 1 ? true : false;
        //     if (this.plzItems.length === 1) {
        //       this.objectPlz.value = this.plzItems[0].value;
        //       this.objectPlz.touched = true;
        //       this.objectPlz.error = false;
        //       setTimeout(() => { this.plzDisabled = false; }, 500);
        //     }
        //   }
        // });
      } else {
        element.error = true;
        this.objectOrt.error = true;
        element.touched = true;
      }

      setTimeout(() => { this.setContainerState() }, 100);
  }

  // wird vielleicht später wieder gebraucht
  // async collectSelectItems(value: string, currentItems: any, page: string) {
  //   this.contentService.getByCity(this.objectOrt.value, page).then((result: any) => {
  //     result.subscribe({
  //       next: (data: any) => {
  //         if (data[0]) {
  //           data.map((item: any) => {
  //             if (item.name == value) {
  //               currentItems.push({
  //                 'value': item.postalCode,
  //                 'viewValue': item.postalCode,
  //               })
  //             }
  //           });
  //         }
  //         this.plzItems = currentItems;
  //       },
  //       error: (error: any) => console.log(error)
  //     })
  //   });
  // }

  getSwitchValue(e: string, element: any) {
    if (element.name === 'mitStrasse') {
      this.mitStrasse = e;
      this.objectMitStrasse.value = e;

      if (e === 'false') {
        this.objectNr = this.contentService.validateReduxValue('', this.objectNr);
        this.objectStrasse = this.contentService.validateReduxValue('', this.objectStrasse);
        this.streetDisabled = true;
        this.nrDisabled = true;
      } else {
        this.streetDisabled = false;
        this.nrDisabled = false;
      }
      this.daService.update(1, { objectAddressMitStrasse: e });
      this.storeInputValue(this.objectMitStrasse);
    } else {
      this.select = e;
      if (e === 'false') {
        this.objectPlz.placeholder = '';
        this.plzDisabled = true;
        this.cityDisabled = false
      } else {
        this.plzDisabled = false;
        this.cityDisabled = true
      }
      this.daService.update(1, { objectSelect: e });
    }
    setTimeout(() => { this.setContainerState() }, 100);
  }

  storeInputValue(element: any) {

    const currentAddress = this.dataQuery.getEntity(1)?.['objectAddress'];
    if (!!currentAddress) {
      this.address = { ...currentAddress };
    }

    Object.keys(this.address).forEach(key => {
      if (key === element.name) {
        this.address[key] = element.value;
      }
    });

    this.daService.update(1, { objectAddress: this.address });
    setTimeout(() => { this.setContainerState() }, 100);
  }

  runValidation(e: string) {
    setTimeout(() => {
      if(e === 'next') {
        if(this.allState !== 'success') {
          this.showState = true;
          this.setContainerState();
        } else {
          this.setContainerState('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 100)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  setContainerState(type: string = 'default') {
    const newAddress = this.dataQuery.getEntity(1)?.['objectAddress'];
    const mitStrasse = this.dataQuery.getEntity(1)?.['objectAddressMitStrasse'];
    if (!!newAddress) {
      this.address = { ...newAddress };
    }
    if(this.objectPlz.error || this.objectOrt.error || this.objectStrasse.error || this.objectNr.error) {
      this.contentService.setSlideIndexState(this.slideName, false, 'error');
      this.setAllState('error', 'ERROR');
      this.stateChange.emit(true);
      if (this.objectPlz.error || this.objectOrt.error) {
        this.container1State = 'error';
      }
      if (this.objectStrasse.error || this.objectNr.error) {
        this.container2State = 'error';
      }
    } else {
      if ((this.address.ort !== '') && (!this.objectPlz.error && !this.objectOrt.error)) {
        if(environment.webappType !== 'c') {
          this.container1State = 'success';
          if(this.address.plz === '' ) {
            this.contentService.setSlideIndexState(this.slideName, false, 'warning');
          } 
        } else {
          if(this.address.plz !== '' ) {
            this.container1State = 'success';
          } else {
            this.container1State = 'warning';
          }
        }
      } else {
        this.container1State = 'warning';
        this.contentService.setSlideIndexState(this.slideName, false, 'warning');
        this.setAllState('warning', 'INCOMPLETE');
        this.stateChange.emit(true);
      }

      if (mitStrasse === 'false' && environment.webappType !== 'c') {
        if (!this.objectStrasse.error && !this.objectNr.error) {
          this.container2State = 'success';
        } else {
          this.container2State = 'warning';
          this.contentService.setSlideIndexState(this.slideName, false, 'warning');
          this.allState = 'warning';
          this.allStateText = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';
          this.stateChange.emit(true);
        }
      } else {
        if ((!!this.address.strasse && !!this.address.hausnummer) &&
          (!this.objectStrasse.error && !this.objectNr.error)) {
          this.container2State = 'success';
        } else {
          this.container2State = 'warning';
          this.contentService.setSlideIndexState(this.slideName, false, 'warning');
          this.setAllState('warning', 'INCOMPLETE');
          this.stateChange.emit(true);
        }
      }
    }

    setTimeout(() => {this.setDisabled(type);})

  }

  setDisabled(type: string = 'default') {
    const newAddress = this.dataQuery.getEntity(1)?.['objectAddress'];
    this.disabled = true;
    if (!!newAddress) {
      this.address = { ...newAddress };
    }

    if (this.container1State === 'success' && this.container2State === 'success') {
      this.setNext();
      this.disabled = false;
      this.contentService.setSlideIndexState(this.slideName, false, this.address.plz !== '' ? 'success' : 'warning');
      if(environment.webappType !== 'c') {
        this.setAllState('success', 'COMPLETE');
      } else {
        this.setAllState(this.address.plz !== '' ? 'success' : 'warning', this.address.plz !== '' ? 'COMPLETE' : 'INCOMPLETE');
      }
      this.stateChange.emit(true);
    }

    if((!this.plzDisabled || !this.cityDisabled || !this.streetDisabled || !this.nrDisabled) && this.disabled) {
      this.testValid = type === 'init' ? this.testValid : true;
    } else {
      if( type === 'test') {
        this.testValid = false;
        this.inputDisabled = true;
      }
    }

    if(this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }

  }

  setNext() {
    const values = this.dataQuery.getEntity(1);
    if(values?.['shortcut']) {
      this.next = environment.webappType === 'b' ? 'info-success-70' : 'market-value';
    } else {
      if (values?.['objectType'] === 'BAUGRUNDSTUECK') {
        this.next = 'object-nutzung';
        this.contentService.setSlideIndex('house-detail', true);
        this.contentService.setSlideIndex('mf-house-detail', true);
        this.contentService.setSlideIndex('object-detail', true);
      } else if (values?.['objectType'] === 'Haus') {
        if (values?.['houseType'] === 'MEHRFAMILIENHAUS') {
          this.next = 'mf-house-detail';
          this.contentService.setSlideIndex('house-detail', true);
          this.contentService.setSlideIndex('mf-house-detail', false);
          this.contentService.setSlideIndex('object-detail', true);
          this.contentService.setSlideIndex('object-nutzung', true);
        } else {
          this.next = 'house-detail';
          this.contentService.setSlideIndex('house-detail', false);
          this.contentService.setSlideIndex('mf-house-detail', true);
          this.contentService.setSlideIndex('object-detail', true);
        }
      };
    }
  }
}
