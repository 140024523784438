<div class="da-info-icon">
    <svg-icon src="assets/images/diskSave.svg"></svg-icon>
    <div class="disk-check">
        <svg-icon src="assets/images/checkSolid.svg"></svg-icon>
    </div>
</div>
<div class="title">
    <lib-header-text
        class="da-info-header"
        size="38px"
        [convertMobile]="true"
        [light]="false"
        label="{{'LOGIN_LIGHT.SAVE.LABELS.OTHER_SAVE' | translate}}"
    ></lib-header-text>
</div>
