import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { testDataTotal, testEigentum, testEigentumswohnung, testMfh, testRequirements, testShortcut, testExpress } from 'src/assets/content/test-redux';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-finance-needs',
  templateUrl: './finance-needs.component.html',
  styleUrls: ['./finance-needs.component.scss']
})
export class FinanceNeedsComponent implements AfterViewInit {
  public slideName: string = 'finance-requirements';
  next: string = 'info-success-95';
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  noTax: boolean = false;
  base!: string;
  kaufpreisBetrag!: string;
  nebenkostenError: boolean = false;
  container1State: string = 'warning';
  container2State: string = 'warning';
  darlehenInValid: boolean = false;
  testRedux = testDataTotal;
  webappType = environment.webappType;
  hasAccess: boolean = false;

  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public nextItem: EventEmitter<any> = new EventEmitter<string>();
  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('uebergabeA') uebergabe!: any;
  @ViewChild('kaufpreis') kaufpreis!: any;
  @ViewChild('grundstueck') grundstueck!: any;
  @ViewChild('herstellung') herstellung!: any;
  @ViewChild('aussenanlagen') aussenanlagen!: any;
  @ViewChild('baunebenkosten') baunebenkosten!: any;
  @ViewChild('erschliessung') erschliessung!: any;
  @ViewChild('nebenkosten') nebenkosten!: any;
  @ViewChild('modernisierung') modernisierung!: any;
  @ViewChild('mobiliar') mobiliar!: any;
  @ViewChild('sonstiges') sonstiges!: any;
  @ViewChild('beglichenes') beglichenes!: any;
  @ViewChild('eigenkapital') eigenkapital!: any;
  @ViewChild('eigenleistung') eigenleistung!: any;
  @ViewChild('darlehensbedarf') darlehensbedarf!: any;
  @ViewChildren('inputs') inputsList!: QueryList<any>;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngAfterViewInit(): void {
    this.setStatus();
    // this.daService.update(1, this.testRedux);
  }

  setNav(e: string) {
    if (e === 'dashboard') {
      this.screen.openInfo(this.uebergabe);
    } else {
      this.slideTo.emit(e);
    }
  }

  setStatus() {
    setTimeout(() => {
      const status = this.dataQuery.getEntity(1);
      if (!!status?.['kaufpreis']) {
        this.kaufpreisBetrag = this.contentService.parseNumberString(this.contentService.getValue('kaufpreis'));
      }

      if (!status?.['eigenleistungValue']) {
        this.daService.update(1, { eigenleistungValue: '0,00' });
      }

      if (status?.['uebergabe']) {
        this.hasAccess = true;
      }

      this.setValid(status, 'init');
    }, 100)
  }

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'dashboard') {
        if (this.allState !== 'success') {
          this.showState = true;
        }
        const status = this.dataQuery.getEntity(1);
        this.setValid(status, 'test');
      } else {
        this.setNav(e);
      }
    }, 50)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  validateInput(e: any, element: any, type: string) {
    setTimeout(() => {
      let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
      element.touched = false;
      // const min = element.name !== 'modernisierung' && element.name !== 'mobiliar' && element.name !== 'sonstiges' && element.name !== 'beglichenes' ? 1000 : 0
      const min = 0;
      let isValidAmount = this.contentService.minMax(value, min, 9999999);
      let isValid = this.contentService.validatePattern(value, element.validationType);

      element.error = isValid && isValidAmount === true ? false : true;
      if (!element.error) {
        this.storeInputValue(element, value);
        if (type === 'container1') {
          this.container1State = 'warning';
        } else if (type === 'container2') {
          this.container2State = 'warning';
        }
      } else {
        if (type === 'container1') {
          this.container1State = 'error';
        } else if (type === 'container2') {
          this.container2State = 'error';
        }
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.setAllState('error', 'ERROR');
        this.stateChange.emit(true);
        setTimeout(() => { this.setValid() })
      }
      element.touched = true;
    }, 50)
  }

  storeInputValue(element: any, e: any) {

    switch (element.name) {
      case 'kaufpreis': {
        // this.kaufpreisBetrag = this.contentService.parseNumberString(e);
        this.daService.update(1, { kaufpreis: e });
        this.base = 'kaufpreis';
        this.calculateNebenkosten(e);
      }
        break;
      case 'grundstueck': {
        this.daService.update(1, { grundstueckspreis: e });
        this.base = 'grundstueck';
        this.calculateNebenkosten(e);
      }
        break;
      case 'herstellung': {
        this.daService.update(1, { herstellungskosten: e });
        this.base = 'herstellung';
        if (!this.grundstueck) {
          this.calculateNebenkosten(e);
          this.noTax = true;
        }
      }
        break;
      case 'aussenanlagen': this.daService.update(1, { aussenanlagenkosten: e });
        break;
      case 'baunebenkosten': this.daService.update(1, { baunebenkosten: e });
        break;
      case 'erschliessung': this.daService.update(1, { erschliessungkosten: e });
        break;
      case 'nebenkosten': this.daService.update(1, { nebenkosten: e });
        break;
      case 'modernisierung': {
        if (e === '0,00') {
          this.daService.update(1, { eigenleistung: '' });
          this.daService.update(1, { eigenleistungTage: 0 });
          this.daService.update(1, { eigenleistungValue: '0,00' });
        } else {
          if (!!this.dataQuery.getEntity(1)?.['eigenleistungValue']) {
            const eigenleistung = this.dataQuery.getEntity(1)?.['eigenleistungValue'];
            const isValid = this.contentService.minMax(eigenleistung, 0, this.contentService.convertStringNumber(e, 2));
            if (isValid !== true) {
              this.daService.update(1, { eigenleistungInValid: true });
            } else {
              this.daService.update(1, { eigenleistungInValid: false });
            }
          }
        }
        this.daService.update(1, { modernisierungkosten: e });
        break;
      }
      case 'mobiliar': this.daService.update(1, { mobiliarkosten: e });
        break;
      case 'sonstiges': this.daService.update(1, { sonstigeskosten: e });
        break;
      case 'beglichenes': this.daService.update(1, { beglicheneskosten: e });
        break;
      case 'eigenkapital': this.daService.update(1, { eigenkapital: e });
        break;
      case 'eigenleistung': this.daService.update(1, { eigenleistung: e });
        break;
    }
    this.calculateSum();
  }

  calculateNebenkosten(value: string) {
    const status = this.dataQuery.getEntity(1);
    let steuer = this.contentService.convertPercentToNumber(this.contentService.convertStringNumber(!!status?.['steuerprozent'] ? status?.['steuerprozent'] : '0,0', 5), this.contentService.convertStringNumber(value, 2));
    this.daService.update(1, { steuerbetrag: steuer.toLocaleString('de-DE', { minimumFractionDigits: 2 }) });
    this.daService.update(1, { steuerprozent: !!status?.['steuerprozent'] ? status?.['steuerprozent'] : '' });

    let notar = this.contentService.convertPercentToNumber(this.contentService.convertStringNumber(!!status?.['notarprozent'] ? status?.['notarprozent'] : '2,0', 5), this.contentService.convertStringNumber(value, 2));
    this.daService.update(1, { notarbetrag: notar.toLocaleString('de-DE', { minimumFractionDigits: 2 }) });
    this.daService.update(1, { notarprozent: !!status?.['notarprozent'] ? status?.['notarprozent'] : '2,0' });

    let makler = this.contentService.convertPercentToNumber(this.contentService.convertStringNumber(!!status?.['maklerprozent'] ? status?.['maklerprozent'] : '3,75', 5), this.contentService.convertStringNumber(value, 2));
    this.daService.update(1, { maklerbetrag: makler.toLocaleString('de-DE', { minimumFractionDigits: 2 }) });
    this.daService.update(1, { maklerprozent: !!status?.['maklerprozent'] ? status?.['maklerprozent'] : '3,75' });

    setTimeout(() => {
      let nebenkosten = 0;
      const newStatus = this.dataQuery.getEntity(1);
      nebenkosten = !!newStatus?.['steuerbetrag'] ? this.contentService.convertStringNumber(newStatus?.['steuerbetrag'], 2) + nebenkosten : nebenkosten;
      nebenkosten = !!newStatus?.['notarbetrag'] ? this.contentService.convertStringNumber(newStatus?.['notarbetrag'], 2) + nebenkosten : nebenkosten;
      nebenkosten = !!newStatus?.['maklerbetrag'] ? this.contentService.convertStringNumber(newStatus?.['maklerbetrag']) + nebenkosten : nebenkosten;

      if (status?.['verbindlichkeiten'] && status?.['verbindlichkeiten'].length > 0) {
        status?.['verbindlichkeiten'].map((item: any) => {
          if (item.abloesen === 'true') {
            nebenkosten = this.contentService.convertStringNumber(item.restschuld) + nebenkosten;
          }
        });
      }

      this.daService.update(1, { nebenkosten: nebenkosten.toLocaleString('de-DE', { minimumFractionDigits: 2 }) });
    }, 100)

  }

  calculateSum() {
    let darlehen = 0;
    const status = this.dataQuery.getEntity(1);
    darlehen = !!status?.['kaufpreis'] ? this.contentService.convertStringNumber(status?.['kaufpreis'], 2) + darlehen : darlehen;
    darlehen = !!status?.['grundstueckspreis'] ? this.contentService.convertStringNumber(status?.['grundstueckspreis'], 2) + darlehen : darlehen;
    darlehen = !!status?.['herstellungskosten'] ? this.contentService.convertStringNumber(status?.['herstellungskosten'], 2) + darlehen : darlehen;
    darlehen = !!status?.['aussenanlagenkosten'] ? this.contentService.convertStringNumber(status?.['aussenanlagenkosten'], 2) + darlehen : darlehen;
    darlehen = !!status?.['baunebenkosten'] ? this.contentService.convertStringNumber(status?.['baunebenkosten'], 2) + darlehen : darlehen;
    darlehen = !!status?.['erschliessungkosten'] ? this.contentService.convertStringNumber(status?.['erschliessungkosten'], 2) + darlehen : darlehen;
    darlehen = !!status?.['nebenkosten'] ? this.contentService.convertStringNumber(status?.['nebenkosten'], 2) + darlehen : darlehen;
    darlehen = !!status?.['modernisierungkosten'] ? this.contentService.convertStringNumber(status?.['modernisierungkosten'], 2) + darlehen : darlehen;
    darlehen = !!status?.['mobiliarkosten'] ? this.contentService.convertStringNumber(status?.['mobiliarkosten'], 2) + darlehen : darlehen;
    darlehen = !!status?.['sonstigeskosten'] ? this.contentService.convertStringNumber(status?.['sonstigeskosten'], 2) + darlehen : darlehen;
    darlehen = !!status?.['beglicheneskosten'] ? darlehen - this.contentService.convertStringNumber(status?.['beglicheneskosten'], 2) : darlehen;
    darlehen = !!status?.['eigenkapitalAktiv'] ? darlehen - this.contentService.convertStringNumber(status?.['eigenkapitalAktiv'], 2) : darlehen;
    darlehen = !!status?.['eigenleistungValue'] ? darlehen - this.contentService.convertStringNumber(status?.['eigenleistungValue'], 2) : darlehen;

    let isValidAmount: any = true;
    isValidAmount = this.contentService.minMax(darlehen.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }), 0, 9999999);
    this.darlehenInValid = isValidAmount !== true ? true : false;
    this.daService.update(1, { darlehensbedarf: darlehen.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) });
    this.checkBausteine(darlehen);
    setTimeout(() => { this.setValid(status) })
  }

  checkBausteine(e: number) {
    let summe = 0;
    const bausteine = this.dataQuery.getEntity(1)?.['darlehenBausteine'];
    if (bausteine) {
      bausteine.map((item: any) => {
        if (!!item.darlehensbetrag) {
          summe = summe + this.contentService.convertStringNumber(item.darlehensbetrag);
        }
      });
      summe = e - summe;
      this.daService.update(1, { darlehensbedarfVerbleibend: summe.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) });
    }
  }

  setValid(data: any = this.dataQuery.getEntity(1), type: string = 'default') {
    const inputFields = this.inputsList.toArray();
    this.disabled = true;
    const kaufpreis = !!data?.['kaufpreis'] && !this.kaufpreis?.error ? true : false;
    const grundstueckspreis = !!data?.['grundstueckspreis'] && !this.grundstueck?.error ? true : false;
    const herstellungskosten = !!data?.['herstellungskosten'] && !this.herstellung?.error ? true : false;
    const aussenanlagenkosten = !!data?.['aussenanlagenkosten'] && !this.aussenanlagen?.error ? true : false;
    const baunebenkosten = !!data?.['baunebenkosten'] && !this.baunebenkosten?.error ? true : false;
    const erschliessungkosten = !!data?.['erschliessungkosten'] && !this.erschliessung?.error ? true : false;
    const nebenkosten = !!data?.['nebenkostenAktiv'] && !this.nebenkosten?.error ? true : false;
    const modernisierungkosten = !this.modernisierung?.error ? true : false;
    const mobiliarkosten = !this.mobiliar?.error ? true : false;
    const sonstigeskosten = !this.sonstiges?.error ? true : false;
    const beglicheneskosten = !!data?.['beglicheneskosten'] && !this.beglichenes?.error ? true : false;
    const eigenkapital = !!data?.['eigenkapitalAktiv'] && !this.eigenkapital?.error ? true : false;
    const eigenleistung = !this.eigenleistung?.error ? true : false;
    const darlehensbedarf = !!data?.['darlehensbedarf'] && !this.darlehenInValid ? true : false;

    let plz;
    const address = data?.['objectAddress'];

    if (address) {
      plz = address.plz
    };

    if (!!data?.['projectPlan']) {
      if (data?.['projectPlan'] === 'NEUBAU') {
        if (grundstueckspreis &&
          herstellungskosten &&
          aussenanlagenkosten &&
          baunebenkosten &&
          erschliessungkosten &&
          nebenkosten &&
          modernisierungkosten &&
          mobiliarkosten &&
          sonstigeskosten) {
          this.container1State = 'success'
        }
        if (beglicheneskosten &&
          eigenkapital &&
          eigenleistung) {
          this.container2State = 'success'
        }
      } else {
        if (kaufpreis &&
          nebenkosten &&
          modernisierungkosten &&
          mobiliarkosten) {
          this.container1State = 'success'
        }
        if (eigenkapital && eigenleistung) {
          this.container2State = 'success'
        }
      }
    } else {
      if (kaufpreis &&
        nebenkosten &&
        modernisierungkosten &&
        mobiliarkosten) {
        this.container1State = 'success'
      }
      if (eigenkapital && eigenleistung) {
        this.container2State = 'success'
      }
    }

    if (darlehensbedarf && this.container1State === 'success' && this.container2State === 'success') {
      this.disabled = false
    }

    if (darlehensbedarf && this.container1State === 'success' && this.container2State === 'success' && !!plz) {
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.setAllState('success', 'COMPLETE');
    }

    if (!darlehensbedarf || this.container1State === 'warning' || this.container2State === 'warning' || !plz) {
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.setAllState('warning', 'INCOMPLETE');
    }

    if (this.container1State === 'error' || this.container2State === 'error') {
      this.contentService.setSlideIndexState(this.slideName, false, 'error');
      this.setAllState('error', 'ERROR');
    }

    this.stateChange.emit(true);

    if (!this.disabled) {
      if (type === 'test') {
        this.inputDisabled = true;
        this.testValid = false;
      }
    } else {
      this.testValid = true;
    }

    if (this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }

  setStateChange() {
    this.hasAccess = true;
    this.stateChange.emit('endItem');
    this.nextItem.emit();
  }

}
