import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ContentService } from 'src/app/services/content.service';
import { EmailService } from 'src/app/services/email.service';
import { LayoutService } from 'src/app/services/layout.service';
import { MappingService } from 'src/app/services/mapping.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { UserData } from 'src/app/state/digitalAssistent/user-data.model';
import { UserQuery } from 'src/app/state/user/user.query';
import { UserService } from 'src/app/state/user/user.service';
import { dropdownsContent } from 'src/assets/content/dropdowns';
import { switchesContent } from 'src/assets/content/switches';

declare global {
  interface Window { dataLayer: any[]; }
}
@Component({
  selector: 'app-uebergabe-sidebar',
  templateUrl: './uebergabe-sidebar.component.html',
  styleUrls: ['./uebergabe-sidebar.component.scss']
})
export class UebergabeSidebarComponent implements AfterViewInit {
  screenType: string = 'screen1';
  person: string = 'person1';
  bubbles: any = [
    {
      "label": "UEBERGABE_A.NAV.BUTTON1",
      "value": "screen1",
      "enabled": true
    },
    {
      "label": "UEBERGABE_A.NAV.BUTTON2",
      "value": "screen2",
      "enabled": false
    },
    {
      "label": "UEBERGABE_A.NAV.BUTTON3",
      "value": "screen3",
      "enabled": false
    }
  ];
  person1State: string = 'warning';
  person2State: string = 'warning';
  screen1Disabled: boolean = true;
  screen2Disabled: boolean = true;
  screen3Disabled: boolean = true;
  screen3Button: string = 'UEBERGABE_A.FORM.BUTTONS.SCREEN3';
  screen3Items: any = [
    {
      'id': 0,
      'state': 'success',
      'label': 'UEBERGABE_A.FORM.SCREEN3.TEXT1',
    },
    {
      'id': 1,
      'state': 'pending',
      'label': 'UEBERGABE_A.FORM.SCREEN3.TEXT2',
    },
    {
      'id': 2,
      'state': 'pending',
      'label': 'UEBERGABE_A.FORM.SCREEN3.TEXT3',
    },
    {
      'id': 3,
      'state': 'pending',
      'label': 'UEBERGABE_A.FORM.SCREEN3.TEXT4',
    },
    {
      'id': 4,
      'state': 'pending',
      'label': 'UEBERGABE_A.FORM.SCREEN3.TEXT5',
    },
    {
      'id': 5,
      'state': 'pending',
      'label': 'UEBERGABE_A.FORM.SCREEN3.TEXT6',
    }
  ];
  screen3DaBItems: any = [
    {
      'id': 0,
      'state': 'pending',
      'label': 'UEBERGABE_A.FORM.SCREEN3.TEXT7',
    }
  ];
  selectedVorwahlItem = { 'value': '', 'viewValue': 'COMMON.SELECT' };
  toggleCheckboxLabelClass = 'uebergabe-checkbox';
  peopleItems = switchesContent['GENDER'].items;
  zustellItems = switchesContent['ZUSTELL'].items;
  vorwahlItems = dropdownsContent['VORWAHL'].items;
  showButtons: boolean = false;
  free: boolean = false;

  dataLayer = window.dataLayer || [];

  @Input() modal!: NgbModalRef;
  @Input() webappType!: string;
  @Output() public uebergabeDone: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('gender1') gender1!: any;
  @ViewChild('gender2') gender2!: any;
  @ViewChild('firstName1') firstName1!: any;
  @ViewChild('firstName2') firstName2!: any;
  @ViewChild('lastName1') lastName1!: any;
  @ViewChild('lastName2') lastName2!: any;
  @ViewChild('vorwahl1') vorwahl1!: any;
  @ViewChild('number1') number1!: any;
  @ViewChild('vorwahl2') vorwahl2!: any;
  @ViewChild('number2') number2!: any;
  @ViewChild('email1') email1!: any;
  @ViewChild('email2') email2!: any;
  @ViewChild('street1') street1!: any;
  @ViewChild('street2') street2!: any;
  @ViewChild('nr1') nr1!: any;
  @ViewChild('nr2') nr2!: any;
  @ViewChild('zustell') zustell!: any;
  @ViewChild('zustellType') zustellType!: any;
  @ViewChild('emailZustell') emailZustell!: any;
  @ViewChild('zahlendePerson') zahlendePerson!: any;
  @ViewChild('zahlendePersonProf') zahlendePersonProf!: any;
  @ViewChild('zahlendePersonDr') zahlendePersonDr!: any;
  @ViewChild('zahlendePersonFirstName') zahlendePersonFirstName!: any;
  @ViewChild('zahlendePersonLastName') zahlendePersonLastName!: any;
  @ViewChild('zahlendePersonEmail') zahlendePersonEmail!: any;

  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private translate: TranslateService,
    private daService: DataService,
    private dataQuery: DataQuery,
    private mappingService: MappingService,
    private emailService: EmailService,
    private configurationQuery: ConfigurationQuery,
    private userService: UserService,
    private userQuery: UserQuery
  ) { }

  ngAfterViewInit(): void {
    this.setStatus();
  }

  setStatus() {
    this.configurationQuery.configuration.subscribe((res: any) => {
      if (res) {
        this.free = res.nettogebuehr ? false : true;
      }
    });

    if (this.webappType !== 'a') {
      this.screen3Button = 'UEBERGABE_A.FORM.BUTTONS.SCREEN3_B';
    }
    setTimeout(() => { this.showButtons = true }, 1100);
    setTimeout(() => {
      const status = this.dataQuery.getEntity(1);

      if (!!status?.['haushaltPersonVorwahl']) {
        this.vorwahl1 = this.contentService.getSelectedItem(status?.['haushaltPersonVorwahl'], this.vorwahl1);
      }

      if (this.webappType !== 'a') {
        this.setLabels();
      }
    });
    this.setDisabled();
  }

  setLabels() {
    this.configurationQuery.configuration.subscribe((res: any) => {
      if (res) {
        let datenschutz = `${this.translate.instant('UEBERGABE_A.FORM.SCREEN2.TEXT3')} <a style="color: inherit;" href="${res.kontaktlinkdse}" target="_blank">${this.translate.instant('UEBERGABE_A.FORM.SCREEN2.TEXT4')}</a>`;
        let agb = `${this.translate.instant('UEBERGABE_A.FORM.SCREEN2.TEXT3')} <a style="color: inherit;" href="${res.kontaktlinkagb}" target="_blank">${this.translate.instant('UEBERGABE_A.FORM.SCREEN2.TEXT5')}</a>`;
        this.contentService.convertStringToHtmlForClass(datenschutz, this.toggleCheckboxLabelClass, 0);
        this.contentService.convertStringToHtmlForClass(agb, this.toggleCheckboxLabelClass, 1);
      }
    });
  }

  getBubbleNav(e: string) {
    if (this.screenType !== 'screen3') {
      this.goTo(e);
    }
  }

  switchPeople(type: string) {
    this.person = type;
    setTimeout(() => {
      const status = this.dataQuery.getEntity(1);

      if (type === 'person2' && !!status?.['haushaltPersonVorwahlPartner']) {
        this.vorwahl2 = this.contentService.getSelectedItem(status?.['haushaltPersonVorwahlPartner'], this.vorwahl2);
      }
      if (type === 'person1' && !!status?.['haushaltPersonVorwahl']) {
        this.vorwahl1 = this.contentService.getSelectedItem(status?.['haushaltPersonVorwahl'], this.vorwahl1);
      }
      if (type === 'person1' && this.webappType !== 'a') {
        this.setLabels();
      }
    }, 100);
  }

  goTo(type: string) {
    this.screenType = type;
    this.screen.scrollToElement('da-header-icon');
    switch (type) {
      case 'screen1': {
        this.bubbles = [
          {
            "label": "UEBERGABE_A.NAV.BUTTON1",
            "value": "screen1",
            "enabled": true
          },
          {
            "label": "UEBERGABE_A.NAV.BUTTON2",
            "value": "screen2",
            "enabled": false
          },
          {
            "label": "UEBERGABE_A.NAV.BUTTON3",
            "value": "screen3",
            "enabled": false
          }
        ];
        break;
      }
      case 'screen2': {
        this.bubbles = [
          {
            "label": "UEBERGABE_A.NAV.BUTTON1",
            "value": "screen1",
            "enabled": true
          },
          {
            "label": "UEBERGABE_A.NAV.BUTTON2",
            "value": "screen2",
            "enabled": true
          },
          {
            "label": "UEBERGABE_A.NAV.BUTTON3",
            "value": "screen3",
            "enabled": false
          }
        ];
        setTimeout(() => { this.setLabels() });
        break;
      };
      case 'screen3': {
        this.bubbles = [
          {
            "label": "UEBERGABE_A.NAV.BUTTON1",
            "value": "screen1",
            "enabled": true
          },
          {
            "label": "UEBERGABE_A.NAV.BUTTON2",
            "value": "screen2",
            "enabled": true
          },
          {
            "label": "UEBERGABE_A.NAV.BUTTON3",
            "value": "screen3",
            "enabled": true
          }
        ];
        setTimeout(() => { this.startAnimation() });
        break;
      }
    }
  }

  startAnimation() {
    this.screen3Items.map((item: any) => {
      if (item.id === 1) {
        setTimeout(() => { item.state = 'success' }, 3000);
      } else if (item.id === 2) {
        setTimeout(() => { item.state = 'success' }, 3500);
      } else if (item.id === 3) {
        setTimeout(() => { item.state = 'success' }, 4000);
      } else if (item.id === 4) {
        setTimeout(() => { item.state = 'success' }, 4500);
      } else if (item.id === 5) {
        setTimeout(() => { item.state = 'success'; this.screen3Disabled = false }, 5000);
      }
    });
    this.screen3DaBItems.map((item: any) => {
      setTimeout(() => {
        item.state = 'success';
        this.screen3Disabled = false
      }, 5000);
    });
  }

  setSwitch(e: any, element?: any) {
    let name = element ? element.name : e.srcElement.name;
    let value = element ? e : e.srcElement.checked;
    switch (name) {
      case 'gender1': {
        this.daService.update(1, { haushaltPerson: value });
        this.daService.update(1, { zahlendePerson: value });
        break;
      }
      case 'gender2': this.daService.update(1, { haushaltPersonPartner: value });
        break;
      case 'prof1': {
        this.daService.update(1, { haushaltPersonTitleProf: value });
        this.daService.update(1, { zahlendePersonTitleProf: value });
        break;
      }
      case 'prof2': this.daService.update(1, { haushaltPersonTitleProfPartner: value });
        break;
      case 'dr1': {
        this.daService.update(1, { haushaltPersonTitleDr: value });
        this.daService.update(1, { zahlendePersonTitleDr: value });
        break;
      }
      case 'dr2': this.daService.update(1, { haushaltPersonTitleDrPartner: value });
        break;
      case 'zustell': this.daService.update(1, { sendCertificate: value });
        break;
      case 'zustellType': this.daService.update(1, { sendCertificateTo: value });
        break;
      case 'zahlendePerson': this.daService.update(1, { zahlendePerson: value });
        break;
      case 'zahlendePersonProf': this.daService.update(1, { zahlendePersonTitleProf: value });
        break;
      case 'zahlendePersonDr': this.daService.update(1, { zahlendePersonTitleDr: value });
        break;
      case 'datenschutz': this.daService.update(1, { datenschutzZertifikat: value });
        break;
      case 'agb': this.daService.update(1, { agbZertifikat: value });
        break;
    }
    setTimeout(() => { this.setDisabled() });
  }

  getSelectedValue(e: any, element: any) {
    if (element.name === 'vorwahl1') {
      this.daService.update(1, { haushaltPersonVorwahl: e });
    } else if (element.name === 'vorwahl2') {
      this.daService.update(1, { haushaltPersonVorwahlPartner: e });
    }
    element = this.contentService.getSelectedItem(e, element);
    setTimeout(() => { this.setDisabled() });
  }

  validateInput(e: any, element: any) {
    setTimeout(() => {
      // let value = e.target ? e.target.value : e;
      let value = e[0];
      element.touched = true;
      element.placeholder = '';
      let isValid = this.contentService.validatePattern(value, element.validationType);
      element.error = isValid ? false : true;

      if (isValid) {
        this.storeInput(value, element);
        if (element.name.includes('1') || element.name === 'emailZustell') {
          this.person1State = 'warning';
        } else if (element.name.includes('2')) {
          this.person2State = 'warning';
        }
      } else {
        if (element.name.includes('1') || element.name === 'emailZustell') {
          this.person1State = 'error';
        } else if (element.name.includes('2')) {
          this.person2State = 'error';
        }
      }
    });
  }

  storeInput(value: string, element: any) {
    switch (element.name) {
      case 'firstName1': {
        this.daService.update(1, { haushaltPersonFirstName: value });
        this.daService.update(1, { zahlendePersonFirstName: value });
        break;
      }
      case 'firstName2': this.daService.update(1, { haushaltPersonFirstNamePartner: value });
        break;
      case 'lastName1': {
        this.daService.update(1, { haushaltPersonLastName: value });
        this.daService.update(1, { zahlendePersonLastName: value });
        break;
      }
      case 'lastName2': this.daService.update(1, { haushaltPersonLastNamePartner: value });
        break;
      case 'number1': this.daService.update(1, { haushaltPersonTelefon: value });
        break;
      case 'number2': this.daService.update(1, { haushaltPersonTelefonPartner: value });
        break;
      case 'email1': {
        this.daService.update(1, { haushaltPersonEmail: value });
        this.daService.update(1, { zahlendePersonEmail: value });
        break;
      }
      case 'email2': this.daService.update(1, { haushaltPersonEmailPartner: value });
        break;
      case 'street1': this.daService.update(1, { haushaltPersonStrasse: value });
        break;
      case 'street2': this.daService.update(1, { haushaltPersonStrassePartner: value });
        break;
      case 'nr1': this.daService.update(1, { haushaltPersonStrasseNr: value });
        break;
      case 'nr2': this.daService.update(1, { haushaltPersonStrasseNrPartner: value });
        break;
      case 'emailZustell': this.daService.update(1, { sendCertificateToEmail: value });
        break;
      case 'zahlendePersonFirstName': this.daService.update(1, { zahlendePersonFirstName: value });
        break;
      case 'zahlendePersonLastName': this.daService.update(1, { zahlendePersonLastName: value });
        break;
      case 'zahlendePersonEmail': this.daService.update(1, { zahlendePersonEmail: value });
        break;
    }

    setTimeout(() => { this.setDisabled() });
  }

  setDisabled() {
    this.screen1Disabled = true;
    this.screen2Disabled = true;
    const status = this.dataQuery.getEntity(1);

    // one person
    if (!!status?.['haushaltPerson'] &&
      !!status?.['haushaltPersonFirstName'] &&
      !!status?.['haushaltPersonLastName'] &&
      !!status?.['haushaltPersonVorwahl'] &&
      !!status?.['haushaltPersonTelefon'] &&
      !!status?.['haushaltPersonEmail'] &&
      !!status?.['haushaltPersonStrasse'] &&
      !!status?.['haushaltPersonStrasseNr']
    ) {
      this.person1State = 'success';

      if (!!status?.['sendCertificate'] && status?.['sendCertificate'] === true) {

        if (!status?.['sendCertificateTo'] || !status?.['sendCertificateToEmail']) {
          this.person1State = 'warning'
        }
      }

      if (this.webappType !== 'a') {
        if (status?.['datenschutzZertifikat'] !== true || status?.['agbZertifikat'] !== true) {
          this.person1State = 'warning'
        }
      }
    }

    if (this.screenType === 'screen1' && this.person === 'person1') {
      if (this.webappType !== 'a') {
        if (this.firstName1.error || this.lastName1.error || this.number1.error ||
          this.email1.error || this.street1.error || this.nr1.error) {
          this.person1State = 'error';
        }
      } else {
        if (this.firstName1.error || this.lastName1.error || this.number1.error ||
          this.email1.error || this.street1.error || this.nr1.error || (!!status?.['sendCertificate'] && !!status?.['sendCertificate'] === true && this.emailZustell.error)) {
          this.person1State = 'error';
        }
      }
    }
    // two people
    if (!!status?.['participants'] && status?.['participants'] !== 'allein') {
      if (!!status?.['haushaltPersonPartner'] &&
        !!status?.['haushaltPersonFirstNamePartner'] &&
        !!status?.['haushaltPersonLastNamePartner'] &&
        !!status?.['haushaltPersonVorwahlPartner'] &&
        !!status?.['haushaltPersonTelefonPartner'] &&
        !!status?.['haushaltPersonEmailPartner']
      ) {
        this.person2State = 'success';
      }
      if (this.screenType === 'screen1' && this.person === 'person2') {
        if (this.firstName2.error || this.lastName2.error || this.number2.error ||
          this.email2.error || this.street2.error || this.nr2.error) {
          this.person2State = 'error';
        }
      }
    }
    // two people
    if (!!status?.['participants'] && status?.['participants'] !== 'allein') {
      if (this.person1State === 'success' && this.person2State === 'success') {
        this.screen1Disabled = false;
      }
      // one person
    } else {
      if (this.person1State === 'success') {
        this.screen1Disabled = false;
      }
    }

    // screen 2
    if (!!status?.['zahlendePerson'] &&
      !!status?.['zahlendePersonFirstName'] &&
      !!status?.['zahlendePersonLastName'] &&
      !!status?.['zahlendePersonEmail'] &&
      !!status?.['datenschutzZertifikat'] &&
      !!status?.['agbZertifikat']
    ) {

      if (status?.['datenschutzZertifikat'] === true && status?.['agbZertifikat'] === true) {
        this.screen2Disabled = false;
      } else {
        this.screen2Disabled = true;
      }
      if (this.screenType === 'screen2') {
        if (!this.zahlendePersonFirstName.error && !this.zahlendePersonLastName.error && !this.zahlendePersonEmail.error) {
          this.screen2Disabled = false;
        } else {
          this.screen2Disabled = true;
        }
      }
    }
  }

  processRequest() {
    let type: { event_DAA_PRIO_01?: string; event_DAB_PRIO_01?: string; event_DAC_PRIO_01?: string; };
    this.goTo('screen3');
    this.mappingService.mapReduxData().then((result: any) => {
      result.subscribe({
        next: (data: any) => {
          this.daService.update(1, { anfrageUuid: data.finanzierungsanfrageuuid });
          setTimeout(() => {
            const uuid = this.contentService.getValue('uuid');
            this.configurationQuery.configuration.subscribe((res: any) => {
              if (res) {
                switch (this.webappType) {
                  case 'a': {
                    type = {'event_DAA_PRIO_01': 'login'};
                    if (!uuid) { // noch nicht registriert
                      this.registerUser('n01a', this.contentService.getValue('haushaltPersonEmail'), res.kontaktemailkunden);
                    } else { // schon registriert
                      this.updateUser('l09', res.kontaktemailkunden);
                    }
                    setTimeout(() => {
                      this.emailService.setEmail('n04', this.contentService.getValue('zahlendePersonEmail'));
                      this.emailService.setEmail('l07', res.kontaktemailkunden);
                      if (!this.free) {
                        this.emailService.setEmail('n06', this.contentService.getValue('zahlendePersonEmail'));
                        this.emailService.setEmail('l11', 'rechnung@finsolio.de');
                      }
                    }, 200);

                    break;
                  }
                  case 'b': {
                    type = {'event_DAB_PRIO_01': 'login'};
                    if (!uuid) { // noch nicht registriert
                      this.registerUser('n01b', this.contentService.getValue('haushaltPersonEmail'), res.kontaktemailkunden);
                    } else { // schon registriert
                      this.updateUser('l09', res.kontaktemailkunden);
                    }
                    setTimeout(() => { this.emailService.setEmail('l02', res.kontaktemailkunden) }, 200);
                    break;
                  }
                  case 'c': {
                    type = {'event_DAC_PRIO_01': 'login'};
                    if (!uuid) { // noch nicht registriert
                      this.registerUser('n01c', this.contentService.getValue('haushaltPersonEmail'), res.kontaktemailkunden);
                    } else { // schon registriert
                      this.updateUser('l09', res.kontaktemailkunden);
                    }
                    setTimeout(() => { this.emailService.setEmail('l02', res.kontaktemailkunden) }, 200);
                    break;
                  }
                }
              }
            });
          }, 100)
          window.dataLayer.push(type);
          this.uebergabeDone.emit();
          this.daService.update(1, { uebergabe: true });
        },
        error: (error: any) => console.log(error)
      });
    });
  }

  registerUser(type: string, mail: string, mail2: string) {
    let userdata: UserData = {
      vorname: this.contentService.getValue('haushaltPersonFirstName'),
      nachname: this.contentService.getValue('haushaltPersonLastName'),
      anrede: this.contentService.getValue('haushaltPerson') === 'MANN' ? 'HERR' : this.contentService.getValue('haushaltPerson'),
      mail: this.contentService.getValue('haushaltPersonEmail'),
      agb: this.contentService.getValue('agbZertifikat'),
      datenschutz: this.contentService.getValue('datenschutzZertifikat')
    };
    this.userService.refill(userdata);

    this.userService.getUuidForSave().then((uuidResult: any) => {
      if (uuidResult) {
        // console.log('save', uuidResult)
        this.daService.setLoginLightUuid(uuidResult)
        this.userService.getStoreData(uuidResult, { ...userdata, uuid: uuidResult }).then((storeDataResult) => {
          // console.log('save', storeDataResult);
          if (storeDataResult) {
            this.userService.saveProgress(storeDataResult).then((saveProgressResult: any) => {
              this.emailService.setEmail(type, mail, '', true);
              // console.log('USEREMAIL', userEmail);
              this.emailService.setEmail('l01', mail2, saveProgressResult.customerid, true, storeDataResult);
            });
          }
        });
      }
    });
  }

  updateUser(type: string, mail: string) {
    const data = { ...this.dataQuery.getEntity(1) };
    const userData = { ...this.userQuery.getEntity(1) };

    this.userService.getStoreData(data['uuid'], { ...userData, uuid: data['uuid'] }).then((storeDataResult) => {
      // console.log('save', storeDataResult);
      if (storeDataResult) {
        this.userService.updateProgress(storeDataResult).then((saveProgressResult: any) => {
          if(!!this.contentService.getValue('uuid')) {
            this.emailService.setEmail(type, mail, this.contentService.getValue('uuid'), true, storeDataResult);
          }
        });
      }
    });
  }

  dismiss() {
    document.body.style.overflowY = 'auto';
    this.modal.dismiss();
  }

}
