import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { DaClickpathService } from 'src/app/state/click-path/da-clickpath.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-status-spec',
  templateUrl: './status-spec.component.html'
})
export class StatusSpecComponent implements OnInit {
  public slideName: string = 'status-spec';
  next: string = 'info-success-25';
  localValue!: string;
  disabled: boolean = true;
  tiles = tilesContent['STATUS_SPEC'].items;

  @Output() public slideTo:EventEmitter<any> = new EventEmitter<string>();

  constructor(
    private daClickpathService: DaClickpathService,
    private daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    private contentService: ContentService
  ) {}

  ngOnInit(): void {
    this.daService.setCurrentSlide(this.slideName);
    const status = this.dataQuery.getEntity(1)?.['statusSpec'];

    if (status && status.length >= 1) {
      this.disabled = false;
      this.localValue = status;
      
      this.tiles.map(tile => {
        if(tile.value === status) {
          tile.checked = true;
        } else {
          tile.checked = false;
        }
      })
    }
  }

  setValue(e: string) {
    // update data store
    this.daService.update(1, { statusSpec: e });

    // update Navigation
    this.daNextSlideService.update(1, { next: this.next, timestamp: new Date });
    this.daClickpathService.updateClickPath(this.slideName);
    this.contentService.setSlideIndexState(this.slideName, false, 'success');
    this.slideTo.emit(this.next);

    // enable forward button
    this.disabled = false;
  }

  setNav(e: string) {
    this.slideTo.emit(e);
  }

}
