import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { DaNextSlide } from './da-next-slide.model';
import { DaNextSlideQuery } from './da-next-slide.query';
import { DaNextSlideStore } from './da-next-slide.store';
import { DataService } from '../data/data.service';

@Injectable({ providedIn: 'root' })
export class DaNextSlideService {

  constructor(
    private daNextSlideStore: DaNextSlideStore,
    private daNextSlideQuery: DaNextSlideQuery,
    private http: HttpClient,
    private daService: DataService) {
  }


  get() {
    return this.http.get<DaNextSlide[]>('https://api.com').pipe(tap(entities => {
      this.daNextSlideStore.set(entities);
    }));
  }

  add(daNextSlide: DaNextSlide) {
    this.daNextSlideStore.add(daNextSlide);
  }

  update(id: any, daNextSlide: Partial<DaNextSlide>) {
    this.daNextSlideStore.update(id, daNextSlide);
    if (daNextSlide && daNextSlide.next) {
      this.daService.setNextSlide(daNextSlide.next);
    }
  }

  remove(id: ID) {
    this.daNextSlideStore.remove(id);
  }

  // get state of disable next button
  getDisabled() {
    const stateDisabled = this.daNextSlideQuery.getEntity(1)?.['disabled'] ?? true;
    return stateDisabled;
  }

  // get next Slide
  getNextSlide() {
    const nextSlide = this.daNextSlideQuery.getEntity(1)?.['next'] ?? '';
    return nextSlide;
  }
}
