import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DaClickpathQuery } from 'src/app/state/click-path/da-clickpath.query';
import { DataService } from 'src/app/state/data/data.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {
  @Input() currentSlide!: string;
  allSlides: any = [];
  percentageValue: number = 2;
  done: boolean = false;

  constructor(
    public screen: LayoutService,
    private daClickpathQuery: DaClickpathQuery,
    private contentService: ContentService,
    private daService: DataService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.setSliderIndex();
  }

  setSliderIndex() {
    this.allSlides = [];
    const sliderIndex = this.daClickpathQuery.getEntity(1)?.slideIndex;
    if(sliderIndex && sliderIndex.length >=1) {
      sliderIndex.map((slide: any) => {
        if (!slide.excluded) {
          this.allSlides.push(slide);
        }
      });
    };
    this.setProgress();
  }

  setProgress() {
    const current = this.daService.getNextSlide();
    this.allSlides.map((slide: any) => {
      if(slide.id === this.currentSlide || (!!current && slide.id === current)) {
        this.percentageValue = (100 / this.allSlides.length) * (this.allSlides.indexOf(slide) + 1);
        if(this.percentageValue === 100) {
          this.done = true;
        } else {
          this.done = false;
        }
      }
    });
  }
}
