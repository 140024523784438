
export const testDaCShortcut = {
  id: 1,
  currentSlide: '',
  shortcut: true,
  participants: 'allein',
  haushaltPerson: 'MANN',
  geburtsdatum: '1995-12-31T22:00:00.000Z',
  staatsangehoerigkeit: 'DE',
  familienstand: 'LEDIG',
  Beschaeftigung: 'ANGESTELLTER',
  probezeit: 'false',
  beschaeftigtSeit: '2019-12-31T22:00:00.000Z',
  beschaeftigungsstatus: 'UNBEFRISTET',
  anzahlGehaelterProJahr: 'ZWOELF',
  gehalt: '15.000,00',
  objectType: 'EIGENTUMSWOHNUNG',
  objectUnitType: 'Wohnflaeche',
  objectGesamtflaeche: '70,00',
  objectNutzungwohnflaeche: 'EIGENGENUTZT',
  projectPlan: 'MODERNISIERUNG_UMBAU_ANBAU',
  lastCompletedSlide: 'privatkredit',
  residence: 'Deutschland',
  schufa: 'schufaOk',
  objectSelect: 'true',
  steuerprozent: '5,5',
  objectAddress: {
    plz: '04109',
    ort: 'Leipzig',
    strasse: '',
    hausnummer: ''
  },
  objectAddressMitStrasse: 'false',
  objectMarkwert: '520.000',
  VerbindlichkeitenErfassung: 'KeineVerbindlichkeiten',
  verbindlichkeitenAnzahl: 0,
  verbindlichkeiten: []
}

export const testDaC = {
  id: 1,
    currentSlide: '',
    shortcut: false,
    participants: 'allein',
    haushaltPerson: 'FRAU',
    geburtsdatum: '2006-01-31T23:00:00.000Z',
    staatsangehoerigkeit: 'DE',
    familienstand: 'LEDIG',
    Beschaeftigung: 'ANGESTELLTER',
    probezeit: 'false',
    beschaeftigtSeit: '2019-09-30T22:00:00.000Z',
    beschaeftigungsstatus: 'UNBEFRISTET',
    anzahlGehaelterProJahr: 'DREIZEHN',
    gehalt: '3.500,00',
    objectType: 'EIGENTUMSWOHNUNG',
    objectUnitType: 'Wohnflaeche',
    objectGesamtflaeche: '120,00',
    objectNutzungwohnflaeche: 'EIGENGENUTZT',
    projectPlan: 'ANSCHLUSSFINANZIERUNG',
    lastCompletedSlide: 'property-change',
    residence: 'Deutschland',
    schufa: 'schufaOk',
    objectTypeGrundstueck: '',
    objectSelect: 'true',
    objectAddressMitStrasse: 'true',
    objectAddress: {
      plz: '04109',
      ort: 'Dresden',
      strasse: 'Tester Strasse',
      hausnummer: '22'
    },
    steuerprozent: '5,5',
    objectBaujahr: '1992',
    objectAnzahlDerWohneinheiten: 4,
    objectNutzungFuture: 'false',
    objectZustand: 'MITTEL',
    objectAusstattung: 'MITTEL',
    objectModernisierungErfassung: 'KEINE_MODERNISIERUNG',
    objectModernisierungJahr: '',
    objectModernisierungGrad: '',
    objectAnzahlGeschosse: 3,
    objectBauweise: 'FACHWERK_MIT_ZIEGELN',
    objectEnergyKnown: 'true',
    objectEnergieEffizienzAusweistyp: 'ENDENERGIEBEDARF',
    objectEnergyUseKnown: 'true',
    objectEndEnergie: '135,00',
    objectParking: 'true',
    objectAnzahlStellplatz: 1,
    objectStellplaetze: [
      {
        typ: 'STELLPLATZ'
      }
    ],
    objectErbbaurecht: 'KEIN_ERBBAURECHT',
    objectErbbauLaufzeitBis: '',
    objectErbbauGrundstueckseigentuemer: '',
    objectErbbauzinsJaehrlich: '',
    objectMarkwert: '160.000',
    zahlendePerson: 'FRAU',
    wohnsituationPlz: '04109',
    wohnsituationOrt: 'Leipzig',
    beruf: 'Lehrer',
    elternzeit: 'KeineElternzeit',
    additionalIncome: 'Nein',
    KinderErfassung: 'KEINE_KINDER',
    VerbindlichkeitenErfassung: 'KeineVerbindlichkeiten',
    verbindlichkeitenAnzahl: 0,
    verbindlichkeiten: [],
    haushaltPersonMiete: 'KeineMiete',
    haushaltPersonKKV: 'Nein',
    haushaltPersonFahrzeuge: 'VorhandeneFahrzeuge',
    countFahrzeuge: 'one',
    hasAssets: 'Ja',
    immobilien: 'VORHANDENE_BESTEHENDEN_IMMOBILIEN',
    mehrImmobilien: 'MEHR_VORHANDENE_BESTEHENDE_IMMOBILIEN',
    // immobilienEigengenutztAnzahl: 0,
    // immobilienVermietetAnzahl: 1,
    immobilienEigengenutztAnzahl: 1,
    immobilienVermietetAnzahl: 1,
    immobilienTeilVermietetAnzahl: 1,
    wertpapiere: 'keineWertpapiere',
    bausparguthaben: 'keinBausparguthaben',
    // bausparenAnzahl: 1,
    // bausparSelectedTab: 1,
    // bausparvertraege: [
    //   {
    //     id: 1,
    //     angesparterBetrag: '30.000,00',
    //     verwendung: 'ABTRETEN_ALS_VERWENDUNG',
    //     maximalEinzusetzenderBetrag: ''
    //   }
    // ],
    sparguthaben: 'sparguthabenVorhanden',
    sparguthabenBetrag: '50.000,00',
    sparenVerwendung: 'AUFLOESUNG_ALS_VERWENDUNG',
    sparenMaximalEinzusetzenderBetrag: '40.000,00',
    familienvermoegen: 'familienvermoegenVorhanden',
    familienSparvermoegen: 'keinFamilienSparvermoegen',
    familienImmobilie: 'familienImmobilieVorhanden',
    versicherung: 'keineVersicherung',
    bestehendeImmobilien: [
      {
        id: 1,
        name: 'PROPERTY_INFORMATION.PROPERTY.DEFAULT 1',
        objektArt: 'EIGENTUMSWOHNUNG',
        marktwert: '150.000',
        objektnutzung: 'EIGENGENUTZT',
        kaltmiete: '',
        adresse: {
          plz: '99091',
          ort: 'Erfurt',
          strasse: 'Tester Strasse',
          hausnummer: '22'
        },
        finanzierung: 'VORHANDENE_BESTEHENDE_DARLEHEN',
        restschuld: '25.000,00',
        darlehen: '50.000,00',
        rate: '500,00',
        useForProject: '',
        umschulden: '',
        erloesVerwenden: '',
        realValue: '125.000',
        betragEinsetzen: '',
        change: '',
        zwischenfinanzierung: '',
        zusatzsicherheit: '',
        loeschen: false
      },
      {
        id: 2,
        name: 'PROPERTY_INFORMATION.PROPERTY.DEFAULT 2',
        objektArt: 'EINFAMILIENHAUS',
        marktwert: '52.100',
        objektnutzung: 'TEILVERMIETET',
        kaltmiete: '520,00',
        adresse: {
          plz: '99091',
          ort: 'Erfurt',
          strasse: 'Tester Strasse',
          hausnummer: '22'
        },
        finanzierung: 'KEINE_BESTEHENDEN_DARLEHEN',
        restschuld: '',
        darlehen: '',
        rate: '',
        useForProject: '',
        umschulden: '',
        erloesVerwenden: '',
        realValue: '52.100',
        betragEinsetzen: '',
        change: '',
        zwischenfinanzierung: '',
        zusatzsicherheit: '',
        loeschen: false
      },
      {
        id: 3,
        name: 'PROPERTY_INFORMATION.PROPERTY.DEFAULT 3',
        objektArt: 'BAUGRUNDSTUECK',
        marktwert: '45.000',
        objektnutzung: 'VERMIETET',
        kaltmiete: '520,00',
        adresse: {
          plz: '99091',
          ort: 'Erfurt',
          strasse: 'Tester Strasse',
          hausnummer: '22'
        },
        finanzierung: 'VORHANDENE_BESTEHENDE_DARLEHEN',
        restschuld: '5.000,00',
        darlehen: '10000',
        rate: '450,00',
        useForProject: '',
        umschulden: '',
        erloesVerwenden: '',
        realValue: '40.000',
        betragEinsetzen: '',
        change: '',
        zwischenfinanzierung: '',
        zusatzsicherheit: '',
        loeschen: false
      },
      {
        id: 4,
        name: 'PROPERTY_INFORMATION.PROPERTY.FAMILY',
        objektArt: 'EIGENTUMSWOHNUNG',
        marktwert: '48.000',
        objektnutzung: 'EIGENGENUTZT',
        kaltmiete: '',
        adresse: {
          plz: '99098',
          ort: 'Erfurt',
          strasse: 'Tester Strasse',
          hausnummer: '22'
        },
        finanzierung: 'KEINE_BESTEHENDEN_DARLEHEN',
        restschuld: '',
        darlehen: '',
        rate: '',
        useForProject: '',
        umschulden: '',
        erloesVerwenden: '',
        realValue: '48.000',
        betragEinsetzen: '',
        change: '',
        zwischenfinanzierung: '',
        zusatzsicherheit: 'ja',
        loeschen: false
      }
    ],
    restschuldVorhanden: 'keinerRestschuldVorhanden',
}

export const testExpress = {
  id: 1,
  currentSlide: 'finance-requirements',
  shortcut: false,
  participants: 'allein',
  haushaltPerson: 'FRAU',
  geburtsdatum: '2006-02-02T23:00:00.000Z',
  staatsangehoerigkeit: 'DE',
  familienstand: 'LEDIG',
  Beschaeftigung: 'ANGESTELLTER',
  probezeit: 'false',
  beschaeftigtSeit: '2006-10-10T22:00:00.000Z',
  beschaeftigungsstatus: 'UNBEFRISTET',
  anzahlGehaelterProJahr: 'ZWOELF_EINHALB',
  gehalt: '5.000,00',
  objectType: 'EIGENTUMSWOHNUNG',
  objectUnitType: 'Wohnflaeche',
  objectGesamtflaeche: '250,00',
  objectNutzungwohnflaeche: 'EIGENGENUTZT',
  estateStatus: 'IN_PROSPECT',
  lastCompletedSlide: 'info-success-90',
  projectPlan: 'MODERNISIERUNG_UMBAU_ANBAU',
  statusSpec: 'Verhandlung',
  residence: 'Deutschland',
  schufa: 'schufaOk',
  objectTypeGrundstueck: '',
  objectSelect: 'true',
  objectAddressMitStrasse: 'true',
  objectAddress: {
    plz: '04109',
    ort: 'Dresden',
    strasse: 'Tester Strasse',
    hausnummer: '22'
  },
  steuerprozent: '5,5',
  objectBaujahr: '1992',
  objectAnzahlDerWohneinheiten: 3,
  objectNutzungFuture: 'true',
  objectZustand: 'MITTEL',
  objectAusstattung: 'STARK_GEHOBEN',
  objectModernisierungErfassung: 'KEINE_MODERNISIERUNG',
  objectModernisierungJahr: '',
  objectModernisierungGrad: '',
  objectAnzahlGeschosse: 2,
  objectBauweise: 'GLAS_STAHL',
  objectEnergyKnown: 'true',
  objectEnergieEffizienzAusweistyp: 'ENDENERGIEBEDARF',
  objectEnergyUseKnown: 'true',
  objectEndEnergie: '123,00',
  objectParking: 'false',
  objectErbbaurecht: 'KEIN_ERBBAURECHT',
  objectErbbauLaufzeitBis: '',
  objectErbbauGrundstueckseigentuemer: '',
  objectErbbauzinsJaehrlich: '',
  zahlendePerson: 'FRAU',
  wohnsituationPlz: '04109',
  wohnsituationOrt: 'Leipzig',
  beruf: 'Lehrer',
  elternzeit: 'KeineElternzeit',
  additionalIncome: 'Nein',
  KinderErfassung: 'KEINE_KINDER',
  VerbindlichkeitenErfassung: 'KeineVerbindlichkeiten',
  verbindlichkeitenAnzahl: 0,
  verbindlichkeiten: [],
  haushaltPersonMiete: 'VorhandeneMiete',
  countMiete: 'one',
  abloeseMiete1: 'true',
  miete1: '850,00',
  haushaltPersonKKV: 'Nein',
  haushaltPersonFahrzeuge: 'VorhandeneFahrzeuge',
  countFahrzeuge: 'one',
  hasAssets: 'Ja',
  sparguthaben: 'sparguthabenVorhanden',
  sparguthabenBetrag: '50.000,00',
  sparenVerwendung: 'AUFLOESUNG_ALS_VERWENDUNG',
  sparenMaximalEinzusetzenderBetrag: '45.000,00',
  bausparguthaben: 'keinBausparguthaben',
  wertpapiere: 'keineWertpapiere',
  immobilien: 'KEINE_BESTEHENDEN_IMMOBILIEN',
  familienvermoegen: 'keinFamilienvermoegen',
  familienSparvermoegen: 'keinFamilienSparvermoegen',
  familienImmobilie: 'keineFamilienImmobilie',
  versicherung: 'keineVersicherung',
  // eigenleistungValue: '0,00',
  // kaufpreis: '165.000,00',
  // steuerbetrag: '9.075,00',
  // notarbetrag: '3.300,00',
  // notarprozent: '2,0',
  // maklerbetrag: '6.187,50',
  // maklerprozent: '3,75',
  // darlehensbedarf: '138.563,00',
  // nebenkostenInValid: false,
  // nebenkostenAktiv: 'true',
  // nebenkosten: '18.563,00',
  // eigenkapitalPassiv: '0,00',
  // eigenkapitalInValid: false,
  // eigenkapitalAktiv: '45.000,00',
  haushaltPersonFirstName: 'Testerin',
  zahlendePersonFirstName: 'Testerin',
  haushaltPersonLastName: 'Test',
  zahlendePersonLastName: 'Test',
  haushaltPersonVorwahl: '01511',
  haushaltPersonTelefon: '123654',
  haushaltPersonEmail: 'schtschur@gmail.com',
  zahlendePersonEmail: 'schtschur@gmail.com',
  haushaltPersonStrasse: 'Tester Strasse',
  haushaltPersonStrasseNr: '22',
  datenschutzZertifikat: true,
  agbZertifikat: true,
  // anfrageUuid: '2bc97302-e55f-4e35-bc73-27e20dc0ef83',
  // uebergabe: true,
  // mail: 'schtschur@gmail.com',
  // uuid: '2ee7e6ba-b399-4542-9142-de456bc67691',
  clickpath: [
    'start',
    'estate-status',
    'project-plan',
    'status-spec',
    'info-success-25',
    'residence',
    'schufa',
    'participants',
    'object-category',
    'address',
    'object-detail',
    'object-nutzung',
    'condition-estate',
    'modernization',
    'bauweise',
    'energy',
    'parking',
    'erbbaurecht',
    'info-success-70',
    'personal',
    'additional-income-single',
    'children',
    'privatkredit',
    'miete',
    'health-insurance',
    'vehicles',
    'financial-assets',
    'info-success-90'
  ],
  nextSlide: '',
  slideIndex: [
    {
      id: 'start',
      progressIndex: 1,
      icon: '/assets/images/personRunning.svg',
      label: 'PROGRESS.LABELS.START',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'estate-status',
      progressIndex: 2,
      icon: '/assets/images/spinner.svg',
      label: 'PROGRESS.LABELS.ESTATE_STATUS',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'project-plan',
      progressIndex: 3,
      icon: '/assets/images/toggleOff.svg',
      label: 'PROGRESS.LABELS.PROJECT_PLAN',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'project-plan-forward',
      progressIndex: 3,
      icon: '/assets/images/toggleOff.svg',
      label: 'PROGRESS.LABELS.PROJECT_PLAN',
      state: 'warning',
      excluded: true,
      disabled: true
    },
    {
      id: 'info-error-project',
      progressIndex: 3,
      icon: '/assets/images/circleExclamation.svg',
      label: 'PROGRESS.LABELS.INFO',
      state: 'end',
      excluded: true,
      disabled: true
    },
    {
      id: 'status-spec',
      progressIndex: 4,
      icon: '/assets/images/signalBars.svg',
      label: 'PROGRESS.LABELS.STATUS',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'info-success-25',
      progressIndex: 4.5,
      icon: '/assets/images/circleCheck.svg',
      label: 'PROGRESS.LABELS.PROGRESS',
      state: 'info',
      excluded: false,
      disabled: false
    },
    {
      id: 'residence',
      progressIndex: 5,
      icon: '/assets/images/locationDot.svg',
      label: 'PROGRESS.LABELS.RESIDENCE',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'info-error-residence',
      progressIndex: 5,
      icon: '/assets/images/circleExclamation.svg',
      label: 'PROGRESS.LABELS.INFO',
      state: 'end',
      excluded: true,
      disabled: true
    },
    {
      id: 'schufa',
      progressIndex: 6,
      icon: '/assets/images/gaugeMax.svg',
      label: 'PROGRESS.LABELS.SCHUFA',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'info-error-schufa',
      progressIndex: 6,
      icon: '/assets/images/circleExclamation.svg',
      label: 'PROGRESS.LABELS.INFO',
      state: 'end',
      excluded: true,
      disabled: true
    },
    {
      id: 'participants',
      progressIndex: 7,
      icon: '/assets/images/people.svg',
      label: 'PROGRESS.LABELS.PARTICIPANTS',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'object-category',
      progressIndex: 8,
      icon: '/assets/images/tableCells.svg',
      label: 'PROGRESS.LABELS.OBJECT_CATEGORY',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'house-category',
      progressIndex: 9,
      icon: '/assets/images/houseBuilding.svg',
      label: 'PROGRESS.LABELS.HOUSE_CATEGORY',
      state: 'warning',
      excluded: true,
      disabled: true
    },
    {
      id: 'address',
      progressIndex: 10,
      icon: '/assets/images/mapLocation.svg',
      label: 'PROGRESS.LABELS.ADRESS',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'house-detail',
      progressIndex: 11,
      icon: '/assets/images/fileMagnifyingGlass.svg',
      label: 'PROGRESS.LABELS.HOUSE_DETAILS',
      state: 'warning',
      excluded: true,
      disabled: true
    },
    {
      id: 'mf-house-detail',
      progressIndex: 11,
      icon: '/assets/images/fileMagnifyingGlass.svg',
      label: 'PROGRESS.LABELS.HOUSE_DETAILS',
      state: 'warning',
      excluded: true,
      disabled: true
    },
    {
      id: 'object-detail',
      progressIndex: 11,
      icon: '/assets/images/fileMagnifyingGlass.svg',
      label: 'PROGRESS.LABELS.OBJECT_DETAILS',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'object-nutzung',
      progressIndex: 12,
      icon: '/assets/images/apartment.svg',
      label: 'PROGRESS.LABELS.NUTZUNG',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'condition-estate',
      progressIndex: 13,
      icon: '/assets/images/starHalf.svg',
      label: 'PROGRESS.LABELS.CONDITION',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'modernization',
      progressIndex: 14,
      icon: '/assets/images/handSparkles.svg',
      label: 'PROGRESS.LABELS.MODERNIZATION',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'bauweise',
      progressIndex: 15,
      icon: '/assets/images/blockBrick.svg',
      label: 'PROGRESS.LABELS.BAUWEISE',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'energy',
      progressIndex: 16,
      icon: '/assets/images/tag.svg',
      label: 'PROGRESS.LABELS.ENERGY',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'parking',
      progressIndex: 17,
      icon: '/assets/images/squareParking.svg',
      label: 'PROGRESS.LABELS.PARKING',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'erbbaurecht',
      progressIndex: 18,
      icon: '/assets/images/personArrowDown.svg',
      label: 'PROGRESS.LABELS.ERBE',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'info-success-70',
      progressIndex: 19,
      icon: '/assets/images/circleCheck.svg',
      label: 'PROGRESS.LABELS.PROGRESS',
      state: 'info',
      excluded: false,
      disabled: false
    },
    {
      id: 'personal',
      progressIndex: 20,
      icon: '/assets/images/user.svg',
      label: 'PROGRESS.LABELS.PERSON',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'personal-partner',
      progressIndex: 20.5,
      icon: '/assets/images/userPlus.svg',
      label: 'PROGRESS.LABELS.PARTNER',
      state: 'warning',
      excluded: true,
      disabled: true
    },
    {
      id: 'additional-income-single',
      progressIndex: 21,
      icon: '/assets/images/grid-plus.svg',
      label: 'PROGRESS.LABELS.ADD_INCOME_1',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'additional-income',
      progressIndex: 21,
      icon: '/assets/images/grid-plus.svg',
      label: 'PROGRESS.LABELS.ADD_INCOME_2',
      state: 'warning',
      excluded: true,
      disabled: true
    },
    {
      id: 'additional-income-partner',
      progressIndex: 21.5,
      icon: '/assets/images/grid-plus.svg',
      label: 'PROGRESS.LABELS.ADD_INCOME_3',
      state: 'warning',
      excluded: true,
      disabled: true
    },
    {
      id: 'children',
      progressIndex: 22,
      icon: '/assets/images/children.svg',
      label: 'PROGRESS.LABELS.KIDS',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'privatkredit',
      progressIndex: 23,
      icon: '/assets/images/fileContract.svg',
      label: 'PROGRESS.LABELS.CREDIT',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'miete',
      progressIndex: 24,
      icon: '/assets/images/key.svg',
      label: 'PROGRESS.LABELS.RENT',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'health-insurance',
      progressIndex: 25,
      icon: '/assets/images/staffSnake.svg',
      label: 'PROGRESS.LABELS.KV',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'vehicles',
      progressIndex: 26,
      icon: '/assets/images/carSide.svg',
      label: 'PROGRESS.LABELS.CAR',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'financial-assets',
      progressIndex: 27,
      icon: '/assets/images/chartPieSimpleCircleDollar.svg',
      label: 'PROGRESS.LABELS.ASSETS',
      state: 'success',
      excluded: false,
      disabled: false
    },
    {
      id: 'property-information',
      progressIndex: 28,
      icon: '/assets/images/objectsAlignBottom.svg',
      label: 'PROGRESS.LABELS.IMMO_INFO',
      state: 'warning',
      excluded: true,
      disabled: true
    },
    {
      id: 'property-change',
      progressIndex: 29,
      icon: '/assets/images/arrowsCross.svg',
      label: 'PROGRESS.LABELS.IMMO_CHANGE',
      state: 'warning',
      excluded: true,
      disabled: true
    },
    {
      id: 'info-success-90',
      progressIndex: 29.5,
      icon: '/assets/images/circleCheck.svg',
      label: 'PROGRESS.LABELS.PROGRESS',
      state: 'info',
      excluded: false,
      disabled: false
    },
    {
      id: 'finance-requirements',
      progressIndex: 30,
      icon: '/assets/images/chartTreeMap.svg',
      label: 'PROGRESS.LABELS.NEEDS',
      state: 'success',
      excluded: false,
      disabled: false
    }
  ],
  // ermittlungsweg: 'ai',
  // zinsbindungPref: 'zinsbindungJa',
  // zinsbindungValuePref: 'mittel',
  // ratePref: 'rateJa',
  // rateValuePref: 'mittel',
  // aufbauPref: 'aufbauNein',
  // sondertilgungPref: 'sondertilgungJa',
  // sondertilgungValuePref: '5',
}



export const testShortcut = {
  id: 1,
  shortcut: true,
  participants: 'allein',
  haushaltPerson: 'MANN',
  zahlendePerson: 'MANN',
  geburtsdatum: '1995-12-31T22:00:00.000Z',
  staatsangehoerigkeit: 'DE',
  familienstand: 'LEDIG',
  Beschaeftigung: 'ANGESTELLTER',
  probezeit: 'false',
  beschaeftigtSeit: '2019-12-31T22:00:00.000Z',
  beschaeftigungsstatus: 'UNBEFRISTET',
  anzahlGehaelterProJahr: 'ZWOELF',
  gehalt: '15.000,00',
  objectType: 'EIGENTUMSWOHNUNG',
  objectUnitType: 'Wohnflaeche',
  objectGesamtflaeche: '70,00',
  objectNutzungwohnflaeche: 'EIGENGENUTZT',
  estateStatus: 'IN_PROSPECT',
  lastCompletedSlide: 'info-success-90',
  projectPlan: 'KAUF_NEUBAU_VOM_BAUTRAEGER',
  statusSpec: 'Verhandlung',
  residence: 'Deutschland',
  schufa: 'schufaOk',
  objectSelect: 'true',
  objectAddressMitStrasse: 'false',
  objectAddress: {
    plz: '',
    ort: 'Leipzig',
    strasse: '',
    hausnummer: ''
  },
  steuerprozent: '5,5',
  hasAssets: 'nein',
  eigenleistungValue: '0,00',
  kaufpreis: '150.000,00',
  steuerbetrag: '8.250,00',
  notarbetrag: '3.000,00',
  notarprozent: '2,0',
  maklerbetrag: '5.625,00',
  maklerprozent: '3,75',
  nebenkosten: '16.875,00',
  haushaltPersonFirstName: 'test',
  zahlendePersonFirstName: 'test',
  haushaltPersonLastName: 'tester',
  zahlendePersonLastName: 'tester',
  haushaltPersonVorwahl: '01512',
  haushaltPersonTelefon: '365897',
  haushaltPersonEmail: 'schtschur@gmail.com',
  zahlendePersonEmail: 'schtschur@gmail.com',
  haushaltPersonStrasse: 'Tester Strasse',
  haushaltPersonStrasseNr: '22',
  datenschutzZertifikat: true,
  agbZertifikat: true,
  VerbindlichkeitenErfassung: 'VorhandeneVerbindlichkeiten',
  verbindlichkeitenAnzahl: 3,
  verbindlichkeiten: [
    {
      id: 0,
      name: 'Vertrag 1',
      verbindlichkeitTyp: 'ratenkredite',
      rate: '740,00',
      restschuld: '85.000,00',
      abloesen: 'true'
    },
    {
      id: 1,
      name: 'Vertrag 2',
      verbindlichkeitTyp: 'leasing',
      rate: '250,00',
      restschuld: '',
      abloesen: ''
    },
    {
      id: 2,
      name: 'Vertrag 3',
      verbindlichkeitTyp: 'privatdarlehen',
      rate: '450,00',
      restschuld: '95.000,00',
      abloesen: 'false'
    },
    {
      id: 3,
      name: 'Vertrag 4',
      verbindlichkeitTyp: 'sonstigeVerbindlichkeit',
      rate: '785',
      restschuld: '9.541',
      abloesen: 'false'
    },
  ],
  // uebergabe: true,
  // anfrageUuid: 'df3f61bf-f99b-483c-a8b3-5777dfc607f5',
  // ermittlungsweg: 'ai',
  // zinsbindungPref: 'zinsbindungJa',
  // zinsbindungValuePref: 'mittel',
  // ratePref: 'rateJa',
  // rateValuePref: 'niedrig',
  // aufbauPref: 'aufbauJa',
  // aufbauValuePref: 'enable_extended_loan_types',
  // sondertilgungPref: 'sondertilgungJa',
  // laufzeitPref: 'laufzeitJa',
  // laufzeitValuePref: '3',
  // bewilligungPref: '10',
  // auszahlungPref: '6',
  // sondertilgungValuePref: '5',
  // resultsDone: true,
}

export const testMfh = {
  estateStatus: 'IN_PROSPECT',
  lastCompletedSlide: 'info-success-90',
  projectPlan: 'KAUF',
  statusSpec: 'Besichtigung',
  residence: 'Deutschland',
  schufa: 'schufaOk',
  participants: 'allein',
  objectType: 'Haus',
  objectUnitType: '',
  objectTypeGrundstueck: '',
  houseCategory: 'KOPFHAUS',
  houseType: 'MEHRFAMILIENHAUS',
  objectSelect: 'true',
  steuerprozent: '5,5',
  objectAddress: {
    plz: '04109',
    ort: 'Dresden',
    strasse: 'Tester Strasse',
    hausnummer: '22'
  },
  objectAddressMitStrasse: 'true',
  objectGrundstuecksgroesse: '1.000,00',
  objectBaujahr: '1992',
  objectAnzahlDerWohneinheiten: 4,
  objectAnzahlDerGewerbeeinheiten: 1,
  objectGesamtflaecheWohnen: '500,00',
  objectGesamtflaecheGewerbe: '200,00',
  objectWohnenChange: 'true',
  objectNutzungwohnflaeche: 'VERMIETET',
  objectMieteinnahmenwohnflaeche: '3.000,00',
  objectGewerbeChange: 'false',
  objectNutzunggewerbeflaeche: 'TEILVERMIETET',
  objectVermietetegewerbeflaeche: '150,00',
  objectMieteinnahmengewerbeflaeche: '2.000,00',
  objectZustand: 'GUT',
  objectAusstattung: 'STARK_GEHOBEN',
  objectModernisierungErfassung: 'unbekannt',
  objectModernisierungJahr: '',
  objectModernisierungGrad: '',
  objectAnzahlGeschosse: 3,
  objectBauweise: 'GLAS_STAHL',
  objectDachgeschoss: 'TEILWEISE_AUSGEBAUT',
  objectUnterkellerung: 'UNTERKELLERT',
  objectEnergyKnown: 'false',
  objectEnergieEffizienzAusweistyp: '',
  objectEnergyUseKnown: '',
  objectEndEnergie: '',
  objectEnergyClassKnown: '',
  objectEnergieEffizienzklasse: '',
  objectParking: 'false',
  objectErbbaurecht: 'KEIN_ERBBAURECHT',
  objectErbbauLaufzeitBis: '',
  objectErbbauGrundstueckseigentuemer: '',
  objectErbbauzinsJaehrlich: '',
  haushaltPerson: 'FRAU',
  geburtsdatum: '2005-09-30T22:00:00.000Z',
  wohnsituationPlz: '04109',
  wohnsituationOrt: 'Leipzig',
  familienstand: 'LEDIG',
  staatsangehoerigkeit: 'DE',
  Beschaeftigung: 'ARBEITER',
  beruf: 'Lehrer',
  beschaeftigtSeit: '2023-09-30T22:00:00.000Z',
  probezeit: 'true',
  beschaeftigungsstatus: 'UNBEFRISTET',
  gehalt: '2.000,00',
  anzahlGehaelterProJahr: 'DREIZEHN',
  additionalIncome: 'Nein',
  KinderErfassung: 'KEINE_KINDER',
  VerbindlichkeitenErfassung: 'KeineVerbindlichkeiten',
  haushaltPersonMiete: 'VorhandeneMiete',
  countMiete: 'one',
  miete1: '520,00',
  abloeseMiete1: 'true',
  haushaltPersonKKV: 'Nein',
  haushaltPersonFahrzeuge: 'VorhandeneFahrzeuge',
  countFahrzeuge: 'one',
  hasAssets: 'Ja',
  sparguthaben: 'sparguthabenVorhanden',
  sparguthabenBetrag: '50.000,00',
  sparenVerwendung: 'AUFLOESUNG_ALS_VERWENDUNG',
  sparenMaximalEinzusetzenderBetrag: '30.000,00',
  bausparguthaben: 'keinBausparguthaben',
  bausparenAnzahl: 1,
  bausparSelectedTab: 1,
  wertpapiere: 'keineWertpapiere',
  immobilien: 'KEINE_BESTEHENDEN_IMMOBILIEN',
  familienvermoegen: 'keinFamilienvermoegen',
  familienSparvermoegen: 'keinFamilienSparvermoegen',
  familienImmobilie: 'keineFamilienImmobilie',
  versicherung: 'keineVersicherung',
  versicherungAnzahl: 1,
  versicherungSelectedTab: 1,
  eigenleistungValue: '0,00',
  kaufpreis: '200.000,00',
  steuerbetrag: '11.000,00',
  notarbetrag: '4.000,00',
  notarprozent: '2,0',
  maklerbetrag: '7.500,00',
  maklerprozent: '3,75',
  darlehensbedarf: '192.500,00',
  nebenkosten: '22.500,00',
  nebenkostenValid: false,
  eigenkapitalPassiv: '0,00',
  eigenkapitalInValid: false,
  eigenkapitalAktiv: '30.000,00'
}

export const testEigentum = {
  estateStatus: 'IN_PROSPECT',
  lastCompletedSlide: 'info-success-90',
  projectPlan: 'KAUF',
  statusSpec: 'Verhandlung',
  residence: 'Deutschland',
  schufa: 'schufaOk',
  participants: 'allein',
  objectType: 'EIGENTUMSWOHNUNG',
  objectUnitType: 'Wohnflaeche',
  objectTypeGrundstueck: '',
  objectSelect: 'true',
  steuerprozent: '5,5',
  objectAddress: {
    plz: '04109',
    ort: 'Leipzig',
    strasse: '',
    hausnummer: ''
  },
  objectAddressMitStrasse: 'false',
  objectGesamtflaeche: '120,00',
  objectBaujahr: '1992',
  objectAnzahlDerWohneinheiten: 4,
  objectNutzungFuture: 'false',
  objectNutzungwohnflaeche: 'EIGENGENUTZT',
  objectZustand: 'GUT',
  objectAusstattung: 'STARK_GEHOBEN',
  objectModernisierungErfassung: 'unbekannt',
  objectModernisierungJahr: '',
  objectModernisierungGrad: '',
  objectAnzahlGeschosse: 3,
  objectBauweise: 'GLAS_STAHL',
  objectEnergyKnown: 'false',
  objectEnergieEffizienzAusweistyp: '',
  objectEnergyUseKnown: '',
  objectEndEnergie: '',
  objectEnergyClassKnown: '',
  objectEnergieEffizienzklasse: '',
  objectParking: 'false',
  objectErbbaurecht: 'KEIN_ERBBAURECHT',
  objectErbbauLaufzeitBis: '',
  objectErbbauGrundstueckseigentuemer: '',
  objectErbbauzinsJaehrlich: '',
  haushaltPerson: 'FRAU',
  geburtsdatum: '2005-09-30T22:00:00.000Z',
  wohnsituationPlz: '04109',
  wohnsituationOrt: 'Leipzig',
  familienstand: 'LEDIG',
  staatsangehoerigkeit: 'DE',
  Beschaeftigung: 'ARBEITER',
  beruf: 'Lehrer',
  beschaeftigtSeit: '2023-09-30T22:00:00.000Z',
  probezeit: 'true',
  beschaeftigungsstatus: 'UNBEFRISTET',
  gehalt: '2.500,00',
  anzahlGehaelterProJahr: 'DREIZEHN',
  additionalIncome: 'Nein',
  KinderErfassung: 'KEINE_KINDER',
  VerbindlichkeitenErfassung: 'KeineVerbindlichkeiten',
  haushaltPersonMiete: 'VorhandeneMiete',
  countMiete: 'one',
  miete1: '850,00',
  abloeseMiete1: 'true',
  haushaltPersonKKV: 'Nein',
  haushaltPersonFahrzeuge: 'VorhandeneFahrzeuge',
  countFahrzeuge: 'one',
  hasAssets: 'Ja',
  sparguthaben: 'sparguthabenVorhanden',
  sparguthabenBetrag: '50.000,00',
  sparenVerwendung: 'AUFLOESUNG_ALS_VERWENDUNG',
  sparenMaximalEinzusetzenderBetrag: '30.000,00',
  bausparguthaben: 'keinBausparguthaben',
  bausparenAnzahl: 1,
  bausparSelectedTab: 1,
  wertpapiere: 'keineWertpapiere',
  immobilien: 'KEINE_BESTEHENDEN_IMMOBILIEN',
  familienvermoegen: 'keinFamilienvermoegen',
  familienSparvermoegen: 'keinFamilienSparvermoegen',
  familienImmobilie: 'keineFamilienImmobilie',
  versicherung: 'keineVersicherung',
  versicherungAnzahl: 1,
  versicherungSelectedTab: 1,
  eigenleistungValue: '0,00',
  kaufpreis: '200.000,00',
  steuerbetrag: '11.000,00',
  notarbetrag: '4.000,00',
  notarprozent: '2,0',
  maklerbetrag: '7.500,00',
  maklerprozent: '3,75',
  darlehensbedarf: '192.500,00',
  nebenkosten: '22.500,00',
  eigenkapitalPassiv: '0,00',
  eigenkapitalInValid: false,
  eigenkapitalAktiv: '30.000,00'
}

export const testDataTotal = {
  estateStatus: 'FOUND',
  lastCompletedSlide: 'property-information',
  projectPlan: 'KAUF',
  statusSpec: 'Verhandlung',
  residence: 'Deutschland',
  schufa: 'schufaOk',
  participants: 'mitPartner',
  objectType: 'Haus',
  objectTypeGrundstueck: '',
  houseCategory: '',
  houseType: 'DOPPELHAUSHAELFTE',
  objectSelect: 'true',
  steuerprozent: '5,5',
  objectAddress: {
    plz: '04109',
    ort: 'Leipzig',
    strasse: 'Musterstrasse',
    hausnummer: '22b'
  },
  objectAddressMitStrasse: 'true',
  objectGrundstuecksgroesse: '1.500,00',
  objectGesamtflaeche: '500,00',
  objectBaujahr: '1992',
  objectFertighaus: 'true',
  objectEinliegerwohnungVorhanden: 'false',
  objectNutzungFuture: 'true',
  objectNutzungwohnflaeche: 'TEILVERMIETET',
  objectZustand: 'SEHR_GUT',
  objectAusstattung: 'STARK_GEHOBEN',
  objectVermietetewohnflaeche: '75,00',
  objectMieteinnahmenwohnflaeche: '580,00',
  objectModernisierungErfassung: 'VORHANDENE_MODERNISIERUNG',
  objectModernisierungJahr: '2018',
  objectModernisierungGrad: 'MITTEL',
  objectAnzahlGeschosse: 1,
  objectBauweise: 'GLAS_STAHL',
  objectDachgeschoss: 'VOLL_AUSGEBAUT',
  objectUnterkellerung: 'UNTERKELLERT',
  objectEnergyKnown: 'true',
  objectEnergieEffizienzAusweistyp: 'ENDENERGIEBEDARF',
  objectEnergyUseKnown: 'true',
  objectEndEnergie: '541,00',
  objectParking: 'true',
  objectStellplaetze: [
    {
      typ: 'GARAGE'
    },
    {
      typ: 'GARAGE'
    }
  ],
  objectAnzahlGarage: 1,
  objectErbbaurecht: 'VORHANDENES_ERBBAURECHT',
  objectErbbauLaufzeitBis: '2051',
  objectErbbauzinsJaehrlich: '54,00',
  objectErbbauGrundstueckseigentuemer: 'OEFFENTLICH_KIRCHLICH',
  haushaltPerson: 'FRAU',
  zahlendePerson: 'FRAU',
  geburtsdatum: '2000-09-30T22:00:00.000Z',
  wohnsituationPlz: '04109',
  wohnsituationOrt: 'Leipzig',
  familienstand: 'LEDIG',
  staatsangehoerigkeit: 'DE',
  Beschaeftigung: 'ANGESTELLTER',
  beruf: 'Lehrer',
  beschaeftigtSeit: '2023-10-02T22:00:00.000Z',
  probezeit: 'true',
  beschaeftigungsstatus: 'BEFRISTET',
  anzahlGehaelterProJahr: 'DREIZEHN',
  gehalt: '5.400,00',
  elternzeit: 'KeineElternzeit',
  haushaltPersonPartner: 'MANN',
  geburtsdatumPartner: '2005-09-30T22:00:00.000Z',
  wohnsituationPlzPartner: '04109',
  wohnsituationOrtPartner: 'Leipzig',
  familienstandPartner: 'VERHEIRATET',
  staatsangehoerigkeitPartner: 'DE',
  BeschaeftigungPartner: 'SELBSTSTAENDIGER',
  SelbststaendigeTaetigkeitPartner: 'Restaurateur',
  taetigSeitPartner: '2023-10-02T22:00:00.000Z',
  gewinnAktuellesJahrPartner: '54.000,00',
  gewinnLetztesJahrPartner: '65.000,00',
  gewinnVorletztesJahrPartner: '12.000,00',
  additionalIncome: 'JaPlusPartner',
  nebentaetigkeit: 'NebentaetigkeitBesteht',
  nebentaetigkeitDate: '2023-10-02T22:00:00.000Z',
  einkuenfteAusNebentaetigkeit: '850,00',
  rente: 'RenteBesteht',
  provision: 'KeineProvision',
  summeUnbefristeteZusatzrentenMonatlich: '450,00',
  nebentaetigkeitPartner: 'NebentaetigkeitBesteht',
  nebentaetigkeitDatePartner: '2023-10-02T22:00:00.000Z',
  einkuenfteAusNebentaetigkeitPartner: '1.500,00',
  rentePartner: 'KeineRente',
  provisionPartner: 'ProvisionBesteht',
  summeSonstigeEinnahmenMonatlichPartner: '850,00',
  KinderErfassung: 'VORHANDENE_KINDER',
  kinderAnzahl: 2,
  kinder: [
    {
      id: 0,
      name: 'Manfred',
      kindergeldWirdBezogen: 'true',
      unterhaltPlus: 'false',
      unterhaltMinus: 'true',
      unterhaltPlusBetrag: '',
      unterhaltMinusBetrag: '740,00'
    },
    {
      id: 0,
      name: 'Ursula',
      kindergeldWirdBezogen: 'true',
      unterhaltPlus: 'true',
      unterhaltMinus: 'false',
      unterhaltPlusBetrag: '540,00',
      unterhaltMinusBetrag: ''
    }
  ],
  VerbindlichkeitenErfassung: 'VorhandeneVerbindlichkeiten',
  verbindlichkeitenAnzahl: 3,
  verbindlichkeiten: [
    {
      id: 0,
      name: 'Vertrag 1',
      verbindlichkeitTyp: 'ratenkredite',
      rate: '740,00',
      restschuld: '85.000,00',
      abloesen: 'true'
    },
    {
      id: 1,
      name: 'Vertrag 2',
      verbindlichkeitTyp: 'leasing',
      rate: '250,00',
      restschuld: '',
      abloesen: ''
    },
    {
      id: 2,
      name: 'Vertrag 3',
      verbindlichkeitTyp: 'privatdarlehen',
      rate: '450,00',
      restschuld: '95.000,00',
      abloesen: 'false'
    },
    {
      id: 3,
      name: 'Vertrag 4',
      verbindlichkeitTyp: 'sonstigeVerbindlichkeit',
      rate: '785',
      restschuld: '9.541',
      abloesen: 'false'
    },
  ],
  haushaltPersonMiete: 'VorhandeneMiete',
  countMiete: 'two',
  abloeseMiete1: 'true',
  abloeseMiete2: 'false',
  miete1: '850,00',
  miete2: '540,00',
  haushaltPersonKKV: 'JaPlusPartner',
  abgezogenKkv1: 'true',
  kkv1: '582,00',
  kinderKkv1: 'true',
  kkv2: '456,00',
  abgezogenKkv2: 'true',
  kinderKkv2: 'true',
  kkv3: '741,00',
  kkv4: '965,00',
  haushaltPersonFahrzeuge: 'VorhandeneFahrzeuge',
  countFahrzeuge: 'two',
  hasAssets: 'Ja',
  sparguthaben: 'sparguthabenVorhanden',
  sparenVerwendung: 'AUFLOESUNG_ALS_VERWENDUNG',
  sparguthabenBetrag: '56.852,36',
  bausparguthaben: 'bausparguthabenVorhanden',
  bausparenAnzahl: 2,
  bausparSelectedTab: 2,
  sparenMaximalEinzusetzenderBetrag: '50.000,00',
  bausparvertraege: [
    {
      id: 1,
      angesparterBetrag: '89.000,00',
      verwendung: 'AUFLOESUNG_ALS_VERWENDUNG',
      maximalEinzusetzenderBetrag: '50.000,00'
    },
    {
      id: 2,
      angesparterBetrag: '65.410,36',
      verwendung: 'ABTRETEN_ALS_VERWENDUNG',
      maximalEinzusetzenderBetrag: ''
    },
    {
      id: 3,
      angesparterBetrag: '85.000,23',
      verwendung: 'KEINE_VERWENDUNG',
      maximalEinzusetzenderBetrag: ''
    }
  ],
  wertpapiere: 'wertpapiereVorhanden',
  wertpapiereVerwendung: 'AUFLOESUNG_ALS_VERWENDUNG',
  wertpapiereBetrag: '95.000,00',
  immobilien: 'VORHANDENE_BESTEHENDE_IMMOBILIEN',
  immobilienEigengenutztAnzahl: 1,
  wertpapiereMaximalEinzusetzenderBetrag: '50.000,00',
  immobilienVermietetAnzahl: 1,
  immobilienTeilVermietetAnzahl: 1,
  familienvermoegen: 'familienvermoegenVorhanden',
  familienSparvermoegen: 'familienSparvermoegenVorhanden',
  familienImmobilie: 'familienImmobilieVorhanden',
  versicherung: 'versicherungVorhanden',
  versicherungAnzahl: 2,
  versicherungSelectedTab: 2,
  familienvermoegenBetrag: '85.000,00',
  versicherungsvertraege: [
    {
      id: 1,
      rueckkaufswert: '54.000,36',
      verwendung: 'AUFLOESUNG_ALS_VERWENDUNG',
      maximalEinzusetzenderBetrag: '45.000,00'
    },
    {
      id: 2,
      rueckkaufswert: '6.200,14',
      verwendung: 'ABTRETEN_ALS_VERWENDUNG',
      maximalEinzusetzenderBetrag: ''
    },
    {
      id: 3,
      rueckkaufswert: '85.000,23',
      verwendung: 'KEINE_VERWENDUNG',
      maximalEinzusetzenderBetrag: ''
    }
  ],
  bestehendeImmobilien: [
    {
      id: 1,
      name: 'Eigentum 1',
      objektArt: 'EIGENTUMSWOHNUNG',
      marktwert: '58.962',
      objektnutzung: 'EIGENGENUTZT',
      kaltmiete: '',
      adresse: {
        plz: '99091',
        ort: 'Erfurt',
        strasse: 'Tester Strasse',
        hausnummer: '22'
      },
      finanzierung: 'VORHANDENE_BESTEHENDE_DARLEHEN',
      restschuld: '56.000,00',
      darlehen: '58.000,00',
      rate: '1.000,00',
      useForProject: '',
      umschulden: '',
      erloesVerwenden: '',
      realValue: '2.962',
      change: 'VERKAUFEN',
      zwischenfinanzierung: 'ja',
      zusatzsicherheit: 'nein'
    },
    {
      id: 2,
      name: 'Eigentum 2',
      objektArt: 'EINFAMILIENHAUS',
      marktwert: '150.000',
      objektnutzung: 'TEILVERMIETET',
      kaltmiete: '560,58',
      adresse: {
        plz: '04109',
        ort: 'Leipzig',
        strasse: 'Tester Strasse',
        hausnummer: '22'
      },
      finanzierung: 'KEINE_BESTEHENDEN_DARLEHEN',
      restschuld: '',
      darlehen: '',
      rate: '',
      useForProject: '',
      umschulden: '',
      erloesVerwenden: '',
      realValue: '150.000',
      change: 'VERKAUFEN',
      zwischenfinanzierung: 'nein',
      zusatzsicherheit: 'ja'
    },
    {
      id: 3,
      name: 'Eigentum 3',
      objektArt: 'EIGENTUMSWOHNUNG',
      marktwert: '540.000',
      objektnutzung: 'VERMIETET',
      kaltmiete: '987,00',
      adresse: {
        plz: '99089',
        ort: 'Erfurt',
        strasse: 'Tester Strasse',
        hausnummer: '22'
      },
      finanzierung: 'KEINE_BESTEHENDEN_DARLEHEN',
      restschuld: '',
      darlehen: '',
      rate: '',
      useForProject: '',
      umschulden: '',
      erloesVerwenden: '',
      realValue: '540.000',
      change: 'KEIN_EINSATZ',
      zwischenfinanzierung: '',
      zusatzsicherheit: 'ja'
    },
    {
      id: 4,
      name: 'Zusatzobjekt (Familie)',
      objektArt: 'EINFAMILIENHAUS',
      marktwert: '850.000',
      objektnutzung: 'EIGENGENUTZT',
      kaltmiete: '',
      adresse: {
        plz: '99098',
        ort: 'Erfurt',
        strasse: 'Tester Strasse',
        hausnummer: '22'
      },
      finanzierung: 'VORHANDENE_BESTEHENDE_DARLEHEN',
      restschuld: '78.254,21',
      darlehen: '500.000,00',
      rate: '',
      useForProject: '',
      umschulden: '',
      erloesVerwenden: '',
      realValue: '771.746',
      change: '',
      zwischenfinanzierung: '',
      zusatzsicherheit: 'ja'
    }
  ],
  erfahrungImmobilienFinanzierung: true,
  eigenleistungValue: '0,00',
  eigenkapitalInValid: false,
  nebenkostenInValid: false,
  nebenkostenAktiv: 'true',
  kaufpreis: '350.000,00',
  steuerbetrag: '8.250,00',
  notarbetrag: '3.000,00',
  notarprozent: '2,0',
  maklerbetrag: '5.625,00',
  maklerprozent: '3,75',
  darlehensbedarf: '56.875,00',
  nebenkosten: '101.875,00',
  ermittlungsweg: 'traditionell',
  haushaltPersonTitleDr: true,
  zahlendePersonTitleDr: true,
  haushaltPersonTitleProf: true,
  zahlendePersonTitleProf: true,
  haushaltPersonFirstName: 'TesterFrau',
  zahlendePersonFirstName: 'TesterFrau',
  haushaltPersonLastName: 'TestNeuVier',
  zahlendePersonLastName: 'TestNeuVier',
  haushaltPersonVorwahl: '01511',
  haushaltPersonTelefon: '236589',
  haushaltPersonEmail: 'schtschur@gmail.com',
  zahlendePersonEmail: 'schtschur@gmail.com',
  haushaltPersonStrasse: 'Tester Strasse',
  haushaltPersonStrasseNr: '22',
  datenschutzZertifikat: true,
  agbZertifikat: true,
  sendCertificate: true,
  sendCertificateTo: 'Makler',
  sendCertificateToEmail: 'tester@test.de',
  haushaltPersonTitleProfPartner: true,
  haushaltPersonTitleDrPartner: false,
  haushaltPersonFirstNamePartner: 'testerMann',
  haushaltPersonLastNamePartner: 'TestNeuDrei',
  haushaltPersonVorwahlPartner: '01578',
  haushaltPersonTelefonPartner: '789564',
  haushaltPersonEmailPartner: 'testerMann@test.de',
  // uebergabe: true,
  // anfrageUuid: 'c5c16771-790b-451d-ac07-93b40bf5ec9d',
  bausteine: 'multiple',
  anzahlBausteine: 3,
  darlehenBausteine: [
    {
      id: 0,
      type: 'ANNUITAETENDARLEHEN',
      darlehensbetrag: '200.000,00',
      darlehensbetragPrev: '',
      bereitstellungszinsfreieZeitInMonaten: '12',
      zinsbindungInJahren: '10',
      tilgungsWunsch: 'RATE',
      tilgungssatzInProzent: 0,
      rate: '2.000,00',
      sondertilgungJaehrlich: '10',
      auszahlungszeitpunkt: '2023-12-27T23:00:00.000Z',
      laufzeitInJahren: 0,
      laufzeitInMonaten: 0,
      tilgungsfreieAnlaufjahre: 0,
      verwendung: '',
      isVisible: true,
      state: 'success',
      darlehenError: false,
      darlehenErrorText: 'COMMON.ERRORS.FEE',
      zinsbindungInJahrenVariable: 0,
      sondertilgungJaehrlichVariable: 0,
      bereitstellungszinsfreieZeitInMonatenVariable: 0
    },
    {
      id: 1,
      type: 'KFW_DARLEHEN',
      darlehensbetrag: '50.000,00',
      darlehensbetragPrev: '',
      bereitstellungszinsfreieZeitInMonaten: 0,
      zinsbindungInJahren: '5',
      tilgungsWunsch: '',
      tilgungssatzInProzent: 0,
      rate: 0,
      sondertilgungJaehrlich: 0,
      auszahlungszeitpunkt: '',
      laufzeitInJahren: '30',
      laufzeitInMonaten: 0,
      tilgungsfreieAnlaufjahre: '3',
      verwendung: '',
      isVisible: true,
      state: 'success',
      darlehenError: false,
      darlehenErrorText: 'COMMON.ERRORS.FEE',
      zinsbindungInJahrenVariable: 0
    },
    {
      id: 2,
      type: 'PRIVATDARLEHEN',
      darlehensbetrag: '29.375,00',
      darlehensbetragPrev: '',
      bereitstellungszinsfreieZeitInMonaten: 0,
      zinsbindungInJahren: 0,
      tilgungsWunsch: '',
      tilgungssatzInProzent: 0,
      rate: 0,
      sondertilgungJaehrlich: 0,
      auszahlungszeitpunkt: '',
      laufzeitInJahren: 0,
      laufzeitInMonaten: '180',
      tilgungsfreieAnlaufjahre: 0,
      verwendung: '',
      isVisible: true,
      state: 'success',
      darlehenError: false,
      darlehenErrorText: 'COMMON.ERRORS.FEE'
    }
  ],
  darlehensbedarfVerbleibend: '0,00',
  // resultsDone: false,
  results: [
  //   {
  //     id: 0,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,92',
  //         effektivZins: '5,08',
  //         anfaenglicheTilgung: '7,08',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '17.549,06',
  //         laufzeit: '10,75',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'VR Bank Westthüringen eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VR_WESTTHUERINGEN.svg',
  //         rating: 3
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VR_WESTTHUERINGEN',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VR_WESTTHUERINGEN.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,29',
  //     gesamtrate: '2.641,33',
  //     gesamttilgung: '2,22-7,08',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 1,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '3,95',
  //         effektivZins: '4,07',
  //         anfaenglicheTilgung: '8,05',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '2.959,83',
  //         laufzeit: '10,17',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '5,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Volksbank Düsseldorf R',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VB_DUESSELDORF_R.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VB_DUESSELDORF_R',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VB_DUESSELDORF_R.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '14,60',
  //     gesamtrate: '2.641,33',
  //     gesamttilgung: '2,22-8,05',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 2,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,95',
  //         effektivZins: '5,11',
  //         anfaenglicheTilgung: '7,31',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.043,33',
  //         restSchuld: '11.320,14',
  //         laufzeit: '10,5',
  //         dateGueltig: '27.12.2023',
  //         hinweisDate: '26.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Volksbank Vogtland-Saale-Orla eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_VOGTLAND.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '27.12.2023',
  //         hinweisDate: '26.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '27.12.2023',
  //         hinweisDate: '26.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VOBA_VOGTLAND',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_VOGTLAND.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,32',
  //     gesamtrate: '2.686,30',
  //     gesamttilgung: '2,22-7,31',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 3,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,55',
  //         effektivZins: '4,69',
  //         anfaenglicheTilgung: '7,45',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '11.762,94',
  //         laufzeit: '10,5',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'BKM - Bausparkasse Mainz AG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/BKM.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'BKM',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/BKM.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,04',
  //     gesamtrate: '2.642,97',
  //     gesamttilgung: '2,22-7,45',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 4,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '5,08',
  //         effektivZins: '5,25',
  //         anfaenglicheTilgung: '6,92',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '20.139,15',
  //         laufzeit: '10,92',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Raiffeisenbank Werratal-Landeck eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/RB_WERRATAL_LANDECK.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'RB_WERRATAL_LANDECK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/RB_WERRATAL_LANDECK.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,41',
  //     gesamtrate: '2.641,33',
  //     gesamttilgung: '2,22-6,92',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 5,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,08',
  //         effektivZins: '4,20',
  //         anfaenglicheTilgung: '8,02',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.016,67',
  //         restSchuld: '2.343,16',
  //         laufzeit: '10,17',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Volksbank Düsseldorf Neuss W',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_DUESSNEUSS_W.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VOBA_DUESSNEUSS_W',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_DUESSNEUSS_W.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,70',
  //     gesamtrate: '2.659,64',
  //     gesamttilgung: '2,22-8,02',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 6,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,31',
  //         effektivZins: '4,44',
  //         anfaenglicheTilgung: '7,81',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.020,00',
  //         restSchuld: '5.163,99',
  //         laufzeit: '10,25',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 15,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Hannoversche',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANNOVERSCHE_LEBEN.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'HANNOVERSCHE_LEBEN',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANNOVERSCHE_LEBEN.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,87',
  //     gesamtrate: '2.662,97',
  //     gesamttilgung: '2,22-7,81',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 7,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,92',
  //         effektivZins: '5,08',
  //         anfaenglicheTilgung: '7,08',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '17.549,06',
  //         laufzeit: '10,75',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'VR Bank Westthüringen eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VR_WESTTHUERINGEN.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VR_WESTTHUERINGEN',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VR_WESTTHUERINGEN.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,30',
  //     gesamtrate: '2.642,97',
  //     gesamttilgung: '2,22-7,08',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 8,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,47',
  //         effektivZins: '4,58',
  //         anfaenglicheTilgung: '1,00',
  //         zinsBindung: '10',
  //         rateMonatlich: '911,67',
  //         restSchuld: '174.839,95',
  //         laufzeit: '38,17',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'VR-Bank Mittelsachsen eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VR_MITTELSACHSEN.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VR_MITTELSACHSEN',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VR_MITTELSACHSEN.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,98',
  //     gesamtrate: '1.554,64',
  //     gesamttilgung: '1-5,55',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 9,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '5,56',
  //         effektivZins: '5,75',
  //         anfaenglicheTilgung: '6,44',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '28.240,54',
  //         laufzeit: '11,25',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'PSD Bank Hessen-Thüringen eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/PSD_HESSEN_THUERINGEN.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'PSD_HESSEN_THUERINGEN',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/PSD_HESSEN_THUERINGEN.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,75',
  //     gesamtrate: '2.641,33',
  //     gesamttilgung: '2,22-6,44',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 10,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,47',
  //         effektivZins: '4,58',
  //         anfaenglicheTilgung: '1,00',
  //         zinsBindung: '10',
  //         rateMonatlich: '911,67',
  //         restSchuld: '174.839,95',
  //         laufzeit: '38,17',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'VR-Bank Mittelsachsen eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VR_MITTELSACHSEN.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VR_MITTELSACHSEN',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VR_MITTELSACHSEN.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,97',
  //     gesamtrate: '1.553,00',
  //     gesamttilgung: '1-5,58',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 11,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,06',
  //         effektivZins: '4,18',
  //         anfaenglicheTilgung: '7,94',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '4.521,73',
  //         laufzeit: '10,25',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'ERGO Lebensversicherung AG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/ERGO.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'ERGO',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/ERGO.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,68',
  //     gesamtrate: '2.641,33',
  //     gesamttilgung: '2,22-7,94',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 12,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,89',
  //         effektivZins: '5,05',
  //         anfaenglicheTilgung: '7,11',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '17.069,35',
  //         laufzeit: '10,75',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'ALTE LEIPZIGER Bauspar AG (I)',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/ALTE_LEIPZIGER_BSV.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'ALTE_LEIPZIGER_BSV',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/ALTE_LEIPZIGER_BSV.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,28',
  //     gesamtrate: '2.642,97',
  //     gesamttilgung: '2,22-7,11',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 13,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '6,20',
  //         effektivZins: '6,42',
  //         anfaenglicheTilgung: '5,80',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '39.852,03',
  //         laufzeit: '11,83',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Volksbank eG Hildesheim-Lehrte-Pattensen',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_HILDESHEIM.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VOBA_HILDESHEIM',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_HILDESHEIM.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '6,22',
  //     gesamtrate: '2.642,97',
  //     gesamttilgung: '2,22-5,8',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 14,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,29',
  //         effektivZins: '4,42',
  //         anfaenglicheTilgung: '7,71',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '7.862,30',
  //         laufzeit: '10,33',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Volksbank Stendal eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_STENDAL.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VOBA_STENDAL',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_STENDAL.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,84',
  //     gesamtrate: '2.641,33',
  //     gesamttilgung: '2,22-7,71',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 15,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,06',
  //         effektivZins: '4,18',
  //         anfaenglicheTilgung: '7,94',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '4.521,73',
  //         laufzeit: '10,25',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'ERGO Lebensversicherung AG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/ERGO.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'ERGO',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/ERGO.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,69',
  //     gesamtrate: '2.642,97',
  //     gesamttilgung: '2,22-7,94',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 16,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,29',
  //         effektivZins: '4,42',
  //         anfaenglicheTilgung: '7,71',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '7.862,30',
  //         laufzeit: '10,33',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Volksbank Stendal eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_STENDAL.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VOBA_STENDAL',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_STENDAL.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,85',
  //     gesamtrate: '2.642,97',
  //     gesamttilgung: '2,22-7,71',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 17,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '3,47',
  //         effektivZins: '3,57',
  //         anfaenglicheTilgung: '8,53',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '--',
  //         laufzeit: '9,92',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Commerzbank AG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/COMMERZBANK.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'COMMERZBANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/COMMERZBANK.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,25',
  //     gesamtrate: '2.641,33',
  //     gesamttilgung: '2,22-8,53',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 18,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,95',
  //         effektivZins: '5,11',
  //         anfaenglicheTilgung: '7,31',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.043,33',
  //         restSchuld: '11.320,14',
  //         laufzeit: '10,5',
  //         dateGueltig: '27.12.2023',
  //         hinweisDate: '26.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Volksbank Vogtland-Saale-Orla eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_VOGTLAND.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '27.12.2023',
  //         hinweisDate: '26.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '27.12.2023',
  //         hinweisDate: '26.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VOBA_VOGTLAND',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_VOGTLAND.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,31',
  //     gesamtrate: '2.684,66',
  //     gesamttilgung: '2,22-7,31',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 19,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,55',
  //         effektivZins: '4,69',
  //         anfaenglicheTilgung: '7,45',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '11.762,94',
  //         laufzeit: '10,5',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'BKM - Bausparkasse Mainz AG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/BKM.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'BKM',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/BKM.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,03',
  //     gesamtrate: '2.641,33',
  //     gesamttilgung: '2,22-7,45',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 20,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '5,56',
  //         effektivZins: '5,75',
  //         anfaenglicheTilgung: '6,44',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '28.240,54',
  //         laufzeit: '11,25',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'PSD Bank Hessen-Thüringen eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/PSD_HESSEN_THUERINGEN.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'PSD_HESSEN_THUERINGEN',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/PSD_HESSEN_THUERINGEN.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,76',
  //     gesamtrate: '2.642,97',
  //     gesamttilgung: '2,22-6,44',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 21,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '3,47',
  //         effektivZins: '3,57',
  //         anfaenglicheTilgung: '8,53',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '--',
  //         laufzeit: '9,92',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Commerzbank AG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/COMMERZBANK.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'COMMERZBANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/COMMERZBANK.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,26',
  //     gesamtrate: '2.642,97',
  //     gesamttilgung: '2,22-8,53',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 22,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,29',
  //         effektivZins: '4,42',
  //         anfaenglicheTilgung: '7,71',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '7.862,30',
  //         laufzeit: '10,33',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '12,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Volksbank Riesa eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_RIESA.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VOBA_RIESA',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_RIESA.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,85',
  //     gesamtrate: '2.642,97',
  //     gesamttilgung: '2,22-7,71',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 23,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,29',
  //         effektivZins: '4,42',
  //         anfaenglicheTilgung: '7,71',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '7.862,30',
  //         laufzeit: '10,33',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '12,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Volksbank Riesa eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_RIESA.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VOBA_RIESA',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_RIESA.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,84',
  //     gesamtrate: '2.641,33',
  //     gesamttilgung: '2,22-7,71',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 24,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,78',
  //         effektivZins: '4,93',
  //         anfaenglicheTilgung: '7,22',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '15.326,62',
  //         laufzeit: '10,67',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Leipziger Volksbank eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VR_TORGAU.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '5,58',
  //         effektivZins: '5,72',
  //         anfaenglicheTilgung: '4,29',
  //         zinsBindung: '--',
  //         rateMonatlich: '241,61',
  //         restSchuld: '--',
  //         laufzeit: '15,34',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '4,29',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Leipziger Volksbank eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VR_TORGAU.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VR_TORGAU',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VR_TORGAU.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       }
  //     ],
  //     mischzins: '4,64',
  //     gesamtrate: '2.480,92',
  //     gesamttilgung: '2,22-7,22',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 25,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '5,73',
  //         effektivZins: '5,93',
  //         anfaenglicheTilgung: '6,27',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '31.232,42',
  //         laufzeit: '11,42',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 4,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'PSD Bank Hannover eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/PSD_HANNOVER.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'PSD_HANNOVER',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/PSD_HANNOVER.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,87',
  //     gesamtrate: '2.641,33',
  //     gesamttilgung: '2,22-6,27',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 26,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,29',
  //         effektivZins: '4,42',
  //         anfaenglicheTilgung: '7,71',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '7.862,30',
  //         laufzeit: '10,33',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '12,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Volksbank Riesa eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_RIESA.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '5,40',
  //         effektivZins: '5,54',
  //         anfaenglicheTilgung: '4,36',
  //         zinsBindung: '--',
  //         rateMonatlich: '238,92',
  //         restSchuld: '--',
  //         laufzeit: '15,32',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '4,36',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Volksbank Riesa eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_RIESA.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VOBA_RIESA',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_RIESA.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       }
  //     ],
  //     mischzins: '4,27',
  //     gesamtrate: '2.478,23',
  //     gesamttilgung: '2,22-7,71',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 27,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,31',
  //         effektivZins: '4,44',
  //         anfaenglicheTilgung: '7,81',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.020,00',
  //         restSchuld: '5.163,99',
  //         laufzeit: '10,25',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 15,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Hannoversche',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANNOVERSCHE_LEBEN.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'HANNOVERSCHE_LEBEN',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANNOVERSCHE_LEBEN.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,86',
  //     gesamtrate: '2.661,33',
  //     gesamttilgung: '2,22-7,81',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 28,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '5,73',
  //         effektivZins: '5,93',
  //         anfaenglicheTilgung: '6,27',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '31.232,42',
  //         laufzeit: '11,42',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 4,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'PSD Bank Hannover eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/PSD_HANNOVER.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'PSD_HANNOVER',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/PSD_HANNOVER.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,88',
  //     gesamtrate: '2.642,97',
  //     gesamttilgung: '2,22-6,27',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 29,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '3,95',
  //         effektivZins: '4,07',
  //         anfaenglicheTilgung: '8,05',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '2.959,83',
  //         laufzeit: '10,17',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '5,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Volksbank Düsseldorf R',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VB_DUESSELDORF_R.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VB_DUESSELDORF_R',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VB_DUESSELDORF_R.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,61',
  //     gesamtrate: '2.642,97',
  //     gesamttilgung: '2,22-8,05',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 30,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '6,20',
  //         effektivZins: '6,42',
  //         anfaenglicheTilgung: '5,80',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '39.852,03',
  //         laufzeit: '11,83',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Volksbank eG Hildesheim-Lehrte-Pattensen',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_HILDESHEIM.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VOBA_HILDESHEIM',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_HILDESHEIM.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '6,21',
  //     gesamtrate: '2.641,33',
  //     gesamttilgung: '2,22-5,8',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 31,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,82',
  //         effektivZins: '4,94',
  //         anfaenglicheTilgung: '1,00',
  //         zinsBindung: '10',
  //         rateMonatlich: '970,00',
  //         restSchuld: '174.367,40',
  //         laufzeit: '36,67',
  //         dateGueltig: '27.12.2023',
  //         hinweisDate: '26.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Signal Iduna',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/SIGNAL_IDUNA.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '27.12.2023',
  //         hinweisDate: '26.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '27.12.2023',
  //         hinweisDate: '26.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'SIGNAL_IDUNA',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/SIGNAL_IDUNA.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,23',
  //     gesamtrate: '1.612,97',
  //     gesamttilgung: '1-5,55',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 32,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '5,08',
  //         effektivZins: '5,25',
  //         anfaenglicheTilgung: '6,92',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '20.139,15',
  //         laufzeit: '10,92',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Raiffeisenbank Werratal-Landeck eG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/RB_WERRATAL_LANDECK.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '29.12.2023',
  //         hinweisDate: '28.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'RB_WERRATAL_LANDECK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/RB_WERRATAL_LANDECK.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,42',
  //     gesamtrate: '2.642,97',
  //     gesamttilgung: '2,22-6,92',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 33,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,08',
  //         effektivZins: '4,20',
  //         anfaenglicheTilgung: '8,02',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.016,67',
  //         restSchuld: '2.343,16',
  //         laufzeit: '10,17',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Volksbank Düsseldorf Neuss W',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_DUESSNEUSS_W.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '22.12.2023',
  //         hinweisDate: '21.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'VOBA_DUESSNEUSS_W',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/VOBA_DUESSNEUSS_W.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,69',
  //     gesamtrate: '2.658,00',
  //     gesamttilgung: '2,22-8,02',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 34,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,82',
  //         effektivZins: '4,94',
  //         anfaenglicheTilgung: '1,00',
  //         zinsBindung: '10',
  //         rateMonatlich: '970,00',
  //         restSchuld: '174.367,40',
  //         laufzeit: '36,67',
  //         dateGueltig: '27.12.2023',
  //         hinweisDate: '26.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Signal Iduna',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/SIGNAL_IDUNA.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,59',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '27.12.2023',
  //         hinweisDate: '26.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '27.12.2023',
  //         hinweisDate: '26.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'SIGNAL_IDUNA',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/SIGNAL_IDUNA.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,22',
  //     gesamtrate: '1.611,33',
  //     gesamttilgung: '1-5,58',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 35,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,89',
  //         effektivZins: '5,05',
  //         anfaenglicheTilgung: '7,11',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '17.069,35',
  //         laufzeit: '10,75',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'ALTE LEIPZIGER Bauspar AG (I)',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/ALTE_LEIPZIGER_BSV.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,63',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'ALTE_LEIPZIGER_BSV',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/ALTE_LEIPZIGER_BSV.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '5,27',
  //     gesamtrate: '2.641,33',
  //     gesamttilgung: '2,22-7,11',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 36,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,25',
  //         effektivZins: '4,33',
  //         anfaenglicheTilgung: '7,70',
  //         zinsBindung: '10',
  //         rateMonatlich: '1.991,67',
  //         restSchuld: '8.516,90',
  //         laufzeit: '10,5',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'DSL Bank - eine Niederlassung der Deutsche Bank AG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/DSL_BANK.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '5,35',
  //         effektivZins: '5,48',
  //         anfaenglicheTilgung: '4,40',
  //         zinsBindung: '--',
  //         rateMonatlich: '238,57',
  //         restSchuld: '--',
  //         laufzeit: '15,28',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '4,40',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'DSL Bank - eine Niederlassung der Deutsche Bank AG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/DSL_BANK.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,58',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'DSL_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/DSL_BANK.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       }
  //     ],
  //     mischzins: '4,24',
  //     gesamtrate: '2.469,55',
  //     gesamttilgung: '2,22-7,7',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 37,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,25',
  //         effektivZins: '4,33',
  //         anfaenglicheTilgung: '7,70',
  //         zinsBindung: '10',
  //         rateMonatlich: '1.991,67',
  //         restSchuld: '8.516,90',
  //         laufzeit: '10,5',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'DSL Bank - eine Niederlassung der Deutsche Bank AG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/DSL_BANK.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,58',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'DSL_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/DSL_BANK.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,82',
  //     gesamtrate: '2.634,64',
  //     gesamttilgung: '2,22-7,7',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 38,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '4,25',
  //         effektivZins: '4,33',
  //         anfaenglicheTilgung: '7,70',
  //         zinsBindung: '10',
  //         rateMonatlich: '1.991,67',
  //         restSchuld: '8.516,90',
  //         laufzeit: '10,5',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'DSL Bank - eine Niederlassung der Deutsche Bank AG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/DSL_BANK.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,58',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'DSL_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/DSL_BANK.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,81',
  //     gesamtrate: '2.633,00',
  //     gesamttilgung: '2,22-7,7',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 39,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '3,94',
  //         effektivZins: '4,01',
  //         anfaenglicheTilgung: '8,06',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '2.818,95',
  //         laufzeit: '10,25',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Deutsche Bank AG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/DEUTSCHE_BANK_INDIVIDUELL.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,58',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,94',
  //         effektivZins: '11,51',
  //         anfaenglicheTilgung: '5,55',
  //         zinsBindung: '--',
  //         rateMonatlich: '403,66',
  //         restSchuld: '--',
  //         laufzeit: '10,49',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,55',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'DEUTSCHE_BANK_INDIVIDUELL',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/DEUTSCHE_BANK_INDIVIDUELL.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,60',
  //     gesamtrate: '2.642,97',
  //     gesamttilgung: '2,22-8,06',
  //     gesamtzinsbindung: '5-10'
  //   },
  //   {
  //     id: 40,
  //     isReserved: false,
  //     isVisible: false,
  //     darlehen: [
  //       {
  //         id: 0,
  //         typ: 'Annuitätendarlehen',
  //         darlehensBetrag: '200.000,00',
  //         sollZins: '3,94',
  //         effektivZins: '4,01',
  //         anfaenglicheTilgung: '8,06',
  //         zinsBindung: '10',
  //         rateMonatlich: '2.000,00',
  //         restSchuld: '2.818,95',
  //         laufzeit: '10,25',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '10,00',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2024',
  //         dauer: '5',
  //         bankName: 'Deutsche Bank AG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/DEUTSCHE_BANK_INDIVIDUELL.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 1,
  //         typ: 'KfW-Darlehen',
  //         darlehensBetrag: '50.000,00',
  //         sollZins: '3,52',
  //         effektivZins: '3,58',
  //         anfaenglicheTilgung: '2,22',
  //         zinsBindung: '5',
  //         rateMonatlich: '239,31',
  //         restSchuld: '47.699,90',
  //         laufzeit: '27,44',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '2,22',
  //         zinsfreieMonate: 12,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '31.1.2027',
  //         dauer: '5',
  //         bankName: 'KfW',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg',
  //         rating: 2.5
  //       },
  //       {
  //         id: 2,
  //         typ: 'Privatdarlehen',
  //         darlehensBetrag: '29.375,00',
  //         sollZins: '10,84',
  //         effektivZins: '11,40',
  //         anfaenglicheTilgung: '5,58',
  //         zinsBindung: '--',
  //         rateMonatlich: '402,02',
  //         restSchuld: '--',
  //         laufzeit: '10,48',
  //         dateGueltig: '28.12.2023',
  //         hinweisDate: '27.12.2023',
  //         optionaleTilgung: '5,58',
  //         zinsfreieMonate: 0,
  //         wechselTilgung: 'ja',
  //         beginnTilgung: '--',
  //         dauer: '5',
  //         bankName: 'Hanseatic Bank GmbH & Co. KG',
  //         bankLogo: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg',
  //         rating: 2.5
  //       }
  //     ],
  //     fakeTiles: [
  //       {
  //         value: '0'
  //       },
  //       {
  //         value: '1'
  //       },
  //       {
  //         value: '2'
  //       }
  //     ],
  //     selectedTile: '0',
  //     tilePosition: '0',
  //     logos: [
  //       {
  //         id: 'DEUTSCHE_BANK_INDIVIDUELL',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/DEUTSCHE_BANK_INDIVIDUELL.svg'
  //       },
  //       {
  //         id: 'KFW',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/KFW.svg'
  //       },
  //       {
  //         id: 'HANSEATIC_BANK',
  //         link: 'https://app-fm-backend.azurewebsites.net/produktanbieter-logos/logo/HANSEATIC_BANK.svg'
  //       }
  //     ],
  //     mischzins: '4,59',
  //     gesamtrate: '2.641,33',
  //     gesamttilgung: '2,22-8,06',
  //     gesamtzinsbindung: '5-10'
  //   }
  ], 
  // expressRequest: false,
  // expressSent: false
}

export const testNebenkosten = [
  {
    id: 0,
    name: 'Vertrag 1',
    verbindlichkeitTyp: 'ratenkredite',
    rate: '123',
    restschuld: '3.000',
    abloesen: 'true'
  },
  {
    id: 1,
    name: 'Vertrag 2',
    verbindlichkeitTyp: 'privatdarlehen',
    rate: '654',
    restschuld: '87.452',
    abloesen: 'true'
  },
  {
    id: 2,
    name: 'Vertrag 3',
    verbindlichkeitTyp: 'sonstigeVerbindlichkeit',
    rate: '785',
    restschuld: '9.541',
    abloesen: 'false'
  },
  {
    id: 3,
    name: 'Vertrag 4',
    verbindlichkeitTyp: 'leasing',
    rate: '852',
    restschuld: '',
    abloesen: ''
  }
];

const Haus = {
  id: 1,
  estateStatus: 'OWN_PROJECT',
  lastCompletedSlide: 'estate-status',
  projectPlan: 'NEUBAU',
  objectType: 'Haus',
  hasAssets: 'Ja',
  immobilien: 'VORHANDENE_BESTEHENDE_IMMOBILIEN',
  immobilienEigengenutztAnzahl: 1,
  bestehendeImmobilien: [
    {
      id: 1,
      name: 'Eigentum 1',
      objektArt: 'BAUGRUNDSTUECK',
      marktwert: '52.000',
      objektnutzung: 'EIGENGENUTZT',
      kaltmiete: '',
      adresse: {
        plz: '99099',
        ort: 'Erfurt',
        strasse: 'Tester Strasse',
        hausnummer: '22'
      },
      finanzierung: 'KEINE_BESTEHENDEN_DARLEHEN',
      restschuld: '',
      darlehen: '',
      rate: '',
      useForProject: 'ja',
      umschulden: '',
      change: '',
      zwischenfinanzierung: '',
      zusatzsicherheit: ''
    }
  ],
  verbindlichkeitenAnzahl: 4,
  verbindlichkeiten: [
    {
      id: 0,
      name: 'Vertrag 1',
      verbindlichkeitTyp: 'ratenkredite',
      rate: '123',
      restschuld: '3.000',
      abloesen: 'true'
    },
    {
      id: 1,
      name: 'Vertrag 2',
      verbindlichkeitTyp: 'privatdarlehen',
      rate: '654',
      restschuld: '87.452',
      abloesen: 'true'
    },
    {
      id: 2,
      name: 'Vertrag 3',
      verbindlichkeitTyp: 'sonstigeVerbindlichkeit',
      rate: '785',
      restschuld: '9.541',
      abloesen: 'false'
    },
    {
      id: 3,
      name: 'Vertrag 4',
      verbindlichkeitTyp: 'leasing',
      rate: '852',
      restschuld: '',
      abloesen: ''
    }
  ],
  useOwnLand: 'ja'
}

export const testDataImmobilien = {
  id: 1,
  hasAssets: 'Ja',
  sparguthaben: 'sparguthabenVorhanden',
  sparguthabenBetrag: '85.000',
  sparenVerwendung: 'AUFLOESUNG_ALS_VERWENDUNG',
  sparenMaximalEinzusetzenderBetrag: '50.000',
  bausparguthaben: 'bausparguthabenVorhanden',
  bausparenAnzahl: 2,
  bausparSelectedTab: 2,
  bausparvertraege: [
    {
      id: 1,
      angesparterBetrag: '30.000',
      verwendung: 'AUFLOESUNG_ALS_VERWENDUNG',
      maximalEinzusetzenderBetrag: '20.000'
    },
    {
      id: 2,
      angesparterBetrag: '50.000',
      verwendung: 'ABTRETEN_ALS_VERWENDUNG',
      maximalEinzusetzenderBetrag: ''
    },
    {
      id: 3,
      angesparterBetrag: '60.000',
      verwendung: 'KEINE_VERWENDUNG',
      maximalEinzusetzenderBetrag: ''
    }
  ],
  wertpapiere: 'wertpapiereVorhanden',
  wertpapiereBetrag: '78.000',
  wertpapiereVerwendung: 'KEINE_VERWENDUNG',
  immobilien: 'VORHANDENE_BESTEHENDE_IMMOBILIEN',
  immobilienEigengenutztAnzahl: 1,
  immobilienVermietetAnzahl: 1,
  immobilienTeilVermietetAnzahl: 1,
  familienvermoegen: 'familienvermoegenVorhanden',
  familienSparvermoegen: 'familienSparvermoegenVorhanden',
  familienvermoegenBetrag: '40.000',
  familienImmobilie: 'familienImmobilieVorhanden',
  versicherung: 'versicherungVorhanden',
  versicherungAnzahl: 2,
  versicherungSelectedTab: 2,
  versicherungsvertraege: [
    {
      id: 1,
      rueckkaufswert: '74.000',
      verwendung: 'AUFLOESUNG_ALS_VERWENDUNG',
      maximalEinzusetzenderBetrag: '50.000'
    },
    {
      id: 2,
      rueckkaufswert: '65.000',
      verwendung: 'ABTRETEN_ALS_VERWENDUNG',
      maximalEinzusetzenderBetrag: ''
    }
  ],
  bestehendeImmobilien: [
    {
      id: 1,
      name: 'Eigentum 1',
      objektArt: 'BAUGRUNDSTUECK',
      marktwert: '50000',
      objektnutzung: 'EIGENGENUTZT',
      kaltmiete: '',
      adresse: {
        plz: '99099',
        ort: 'Erfurt',
        strasse: 'Tester Strasse',
        hausnummer: '22'
      },
      finanzierung: 'VORHANDENE_BESTEHENDE_DARLEHEN',
      restschuld: '5000',
      darlehen: '50000',
      rate: '520',
      useForProject: '',
      umschulden: '',
      erloesVerwenden: '',
      realValue: '45000',
      change: '',
      zwischenfinanzierung: '',
      zusatzsicherheit: ''
    },
    {
      id: 2,
      name: 'Eigentum 2',
      objektArt: 'EIGENTUMSWOHNUNG',
      marktwert: '150000',
      objektnutzung: 'TEILVERMIETET',
      kaltmiete: '520',
      adresse: {
        plz: '99089',
        ort: 'Erfurt',
        strasse: 'Tester Strasse',
        hausnummer: '22'
      },
      finanzierung: 'VORHANDENE_BESTEHENDE_DARLEHEN',
      restschuld: '50000',
      darlehen: '100000',
      rate: '600',
      useForProject: '',
      umschulden: '',
      erloesVerwenden: '',
      realValue: '100000',
      change: '',
      zwischenfinanzierung: '',
      zusatzsicherheit: ''
    },
    {
      id: 3,
      name: 'Eigentum 3',
      objektArt: 'EIGENTUMSWOHNUNG',
      marktwert: '500000',
      objektnutzung: 'VERMIETET',
      kaltmiete: '542',
      adresse: {
        plz: '04109',
        ort: 'Leipzig',
        strasse: 'Tester Strasse',
        hausnummer: '22'
      },
      finanzierung: 'KEINE_BESTEHENDEN_DARLEHEN',
      restschuld: '',
      darlehen: '',
      rate: '',
      useForProject: '',
      umschulden: '',
      erloesVerwenden: '',
      realValue: '500000',
      change: '',
      zwischenfinanzierung: '',
      zusatzsicherheit: ''
    },
    {
      id: 4,
      name: 'Zusatzobjekt (Familie)',
      objektArt: 'EINFAMILIENHAUS',
      marktwert: '540000',
      objektnutzung: 'EIGENGENUTZT',
      kaltmiete: '',
      adresse: {
        plz: '99091',
        ort: 'Erfurt',
        strasse: 'Tester Strasse',
        hausnummer: '22'
      },
      finanzierung: 'VORHANDENE_BESTEHENDE_DARLEHEN',
      restschuld: '250000',
      darlehen: '300000',
      rate: '',
      useForProject: '',
      umschulden: '',
      erloesVerwenden: '',
      realValue: '290000',
      change: '',
      zwischenfinanzierung: '',
      zusatzsicherheit: 'ja'
    }
  ],
  erfahrungImmobilienFinanzierung: true,
  lastCompletedSlide: 'property-information'
}

export const testRequirements = {
  id: 1,
  estateStatus: 'IN_PROSPECT',
  projectPlan: 'KAUF',
  projectInDeutschland: 'true',
  lastCompletedSlide: 'info-success-90',
  statusSpec: 'Besichtigung',
  residence: 'Deutschland',
  schufa: 'schufaOk',
  participants: 'mitPartner',
  objectType: 'EIGENTUMSWOHNUNG',
  objectUnitType: 'Wohnflaeche',
  objectTypeGrundstueck: '',
  objectSelect: 'true',
  steuerprozent: '5,5',
  objectAddress: {
    plz: '04109',
    ort: 'Leipzig',
    strasse: '',
    hausnummer: ''
  },
  objectAddressMitStrasse: 'false',
  objectGesamtflaeche: '150,00',
  objectBaujahr: '1992',
  objectAnzahlDerWohneinheiten: 5,
  objectNutzungFuture: 'true',
  objectNutzungwohnflaeche: 'EIGENGENUTZT',
  objectZustand: 'MITTEL',
  objectAusstattung: 'STARK_GEHOBEN',
  objectAnzahlGeschosse: 3,
  objectBauweise: 'GLAS_STAHL',
  objectEnergyKnown: 'false',
  objectEnergieEffizienzAusweistyp: '',
  objectEnergyUseKnown: '',
  objectEndEnergie: '',
  objectEnergyClassKnown: '',
  objectEnergieEffizienzklasse: '',
  objectParking: 'false',
  objectErbbaurecht: 'KEIN_ERBBAURECHT',
  objectErbbauLaufzeitBis: '',
  objectErbbauGrundstueckseigentuemer: '',
  objectErbbauzinsJaehrlich: '',
  haushaltPerson: 'FRAU',
  zahlendePerson: 'FRAU',
  geburtsdatum: '2005-10-31T23:00:00.000Z',
  wohnsituationPlz: '04109',
  wohnsituationOrt: 'Leipzig',
  familienstand: 'LEDIG',
  staatsangehoerigkeit: 'DE',
  Beschaeftigung: 'ARBEITSLOSER',
  additionalIncome: 'Nein',
  KinderErfassung: 'KEINE_KINDER',
  VerbindlichkeitenErfassung: 'KeineVerbindlichkeiten',
  verbindlichkeitenAnzahl: 0,
  verbindlichkeiten: [],
  haushaltPersonMiete: 'VorhandeneMiete',
  countMiete: 'one',
  abloeseMiete1: 'true',
  miete1: '850,00',
  haushaltPersonKKV: 'Nein',
  haushaltPersonFahrzeuge: 'VorhandeneFahrzeuge',
  countFahrzeuge: 'one',
  hasAssets: 'nein',
  eigenleistungValue: '0,00',
  grundstueckspreis: '50.000,00',
  steuerbetrag: '2.750,00',
  notarbetrag: '1.000,00',
  notarprozent: '2,0',
  maklerbetrag: '1.875,00',
  maklerprozent: '3,75',
  darlehensbedarf: '240.625,00',
  nebenkosten: '5.625,00',
  herstellungskosten: '150.000,00',
  aussenanlagenkosten: '5.000,00',
  baunebenkosten: '6.000,00',
  erschliessungkosten: '7.000,00',
  mobiliarkosten: '8.000,00',
  sonstigeskosten: '9.000,00',
  eigenkapitalPassiv: '0,00',
  eigenkapitalInValid: false,
  eigenkapitalAktiv: '0,00',
  beglicheneskosten: '0,00',
  ermittlungsweg: 'traditionell',
  bausteine: 'one',
  anzahlBausteine: 2,
  darlehenBausteine: [
    {
      id: 0,
      type: 'ANNUITAETENDARLEHEN',
      darlehensbetrag: '225.000,00',
      darlehensbetragPrev: '',
      bereitstellungszinsfreieZeitInMonaten: '6',
      zinsbindungInJahren: '15',
      tilgungsWunsch: 'RATE',
      tilgungssatzInProzent: 0,
      rate: '1.500,00',
      sondertilgungJaehrlich: '10',
      auszahlungszeitpunkt: '2023-11-29T23:00:00.000Z',
      laufzeitInJahren: 0,
      laufzeitInMonaten: 0,
      tilgungsfreieAnlaufjahre: 0,
      verwendung: '',
      isVisible: true,
      state: 'success',
      darlehenError: false,
      darlehenErrorText: 'COMMON.ERRORS.FEE',
      zinsbindungInJahrenVariable: 0,
      sondertilgungJaehrlichVariable: 0,
      bereitstellungszinsfreieZeitInMonatenVariable: 0
    },
    {
      id: 1,
      type: 'PRIVATDARLEHEN',
      darlehensbetrag: '15.625,00',
      darlehensbetragPrev: '',
      bereitstellungszinsfreieZeitInMonaten: 0,
      zinsbindungInJahren: 0,
      tilgungsWunsch: '',
      tilgungssatzInProzent: 0,
      rate: 0,
      sondertilgungJaehrlich: 0,
      auszahlungszeitpunkt: '',
      laufzeitInJahren: 0,
      laufzeitInMonaten: '180',
      tilgungsfreieAnlaufjahre: 0,
      verwendung: '',
      isVisible: true,
      state: 'success',
      darlehenError: false,
      darlehenErrorText: 'COMMON.ERRORS.FEE'
    }
  ],
  darlehensbedarfVerbleibend: '0,00',
  haushaltPersonTitleDr: true,
  zahlendePersonTitleDr: true,
  haushaltPersonTitleProf: true,
  zahlendePersonTitleProf: true,
  haushaltPersonFirstName: 'Tester',
  zahlendePersonFirstName: 'Tester',
  haushaltPersonLastName: 'testing',
  zahlendePersonLastName: 'testing',
  haushaltPersonVorwahl: '01511',
  haushaltPersonTelefon: '236589',
  haushaltPersonEmail: 'test@test.de',
  zahlendePersonEmail: 'test@test.de',
  haushaltPersonStrasse: 'Tester Strasse',
  haushaltPersonStrasseNr: '22',
  datenschutzZertifikat: true,
  agbZertifikat: true,
  sendCertificate: true,
  sendCertificateTo: 'Makler',
  sendCertificateToEmail: 'tester@test.de',
  haushaltPersonPartner: 'MANN',
  geburtsdatumPartner: '2005-11-08T23:00:00.000Z',
  wohnsituationPlzPartner: '99099',
  wohnsituationOrtPartner: 'Erfurt',
  familienstandPartner: 'GETRENNT_LEBEND',
  staatsangehoerigkeitPartner: 'FR',
  BeschaeftigungPartner: 'HAUSHALTENDE_PERSON',
  haushaltPersonTitleProfPartner: true,
  haushaltPersonTitleDrPartner: false,
  haushaltPersonFirstNamePartner: 'testerMann',
  haushaltPersonLastNamePartner: 'testingMann',
  haushaltPersonVorwahlPartner: '01578',
  haushaltPersonTelefonPartner: '789564',
  haushaltPersonEmailPartner: 'testerMann@test.de',
}

export const testEigentumswohnung = {
  id: 1,
  estateStatus: 'FOUND',
  lastCompletedSlide: 'info-success-90',
  projectPlan: 'KAUF',
  statusSpec: 'Besichtigung',
  residence: 'Deutschland',
  schufa: 'schufaOk',
  participants: 'allein',
  objectType: 'EIGENTUMSWOHNUNG',
  objectUnitType: 'Wohnflaeche',
  objectTypeGrundstueck: '',
  objectSelect: 'true',
  steuerprozent: '5,5',
  objectAddress: {
    plz: '04109',
    ort: 'Leipzig',
    strasse: '',
    hausnummer: ''
  },
  objectAddressMitStrasse: 'false',
  objectGesamtflaeche: '200,00',
  objectBaujahr: '1992',
  objectAnzahlDerWohneinheiten: 4,
  objectNutzungFuture: 'true',
  objectNutzungwohnflaeche: 'EIGENGENUTZT',
  objectZustand: 'GUT',
  objectAusstattung: 'STARK_GEHOBEN',
  objectModernisierungErfassung: 'unbekannt',
  objectModernisierungJahr: '',
  objectModernisierungGrad: '',
  objectAnzahlGeschosse: 2,
  objectBauweise: 'MASSIV',
  objectEnergyKnown: 'false',
  objectEnergieEffizienzAusweistyp: '',
  objectEnergyUseKnown: '',
  objectEndEnergie: '',
  objectEnergyClassKnown: '',
  objectEnergieEffizienzklasse: '',
  objectParking: 'false',
  objectErbbaurecht: 'KEIN_ERBBAURECHT',
  objectErbbauLaufzeitBis: '',
  objectErbbauGrundstueckseigentuemer: '',
  objectErbbauzinsJaehrlich: '',
  haushaltPerson: 'FRAU',
  zahlendePerson: 'FRAU',
  geburtsdatum: '1987-06-08T22:00:00.000Z',
  wohnsituationPlz: '04109',
  wohnsituationOrt: 'Leipzig',
  familienstand: 'LEDIG',
  staatsangehoerigkeit: 'DE',
  Beschaeftigung: 'BEAMTER',
  beruf: 'Lehrer',
  beschaeftigtSeit: '2013-03-02T23:00:00.000Z',
  gehalt: '5.000,00',
  anzahlGehaelterProJahr: 'DREIZEHN',
  elternzeit: 'KeineElternzeit',
  additionalIncome: 'Nein',
  KinderErfassung: 'KEINE_KINDER',
  VerbindlichkeitenErfassung: 'KeineVerbindlichkeiten',
  verbindlichkeitenAnzahl: 0,
  verbindlichkeiten: [],
  haushaltPersonMiete: 'VorhandeneMiete',
  countMiete: 'one',
  miete1: '850,00',
  abloeseMiete1: 'false',
  haushaltPersonKKV: 'Nein',
  haushaltPersonFahrzeuge: 'VorhandeneFahrzeuge',
  countFahrzeuge: 'one',
  hasAssets: 'Ja',
  sparguthaben: 'sparguthabenVorhanden',
  sparguthabenBetrag: '40.000,00',
  sparenVerwendung: 'AUFLOESUNG_ALS_VERWENDUNG',
  sparenMaximalEinzusetzenderBetrag: '60.000,00',
  bausparguthaben: 'keinBausparguthaben',
  bausparenAnzahl: 1,
  bausparSelectedTab: 1,
  wertpapiere: 'keineWertpapiere',
  immobilien: 'KEINE_BESTEHENDEN_IMMOBILIEN',
  familienvermoegen: 'familienvermoegenVorhanden',
  familienSparvermoegen: 'familienSparvermoegenVorhanden',
  familienvermoegenBetrag: '60.000,00',
  familienImmobilie: 'keineFamilienImmobilie',
  versicherung: 'keineVersicherung',
  versicherungAnzahl: 1,
  versicherungSelectedTab: 1,
  eigenleistungValue: '0,00',
  kaufpreis: '250.000,00',
  steuerbetrag: '13.750,00',
  notarbetrag: '5.000,00',
  notarprozent: '2,0',
  maklerbetrag: '9.375,00',
  maklerprozent: '3,75',
  darlehensbedarf: '218.125,00',
  nebenkosten: '28.125,00',
  eigenkapitalPassiv: '0,00',
  eigenkapitalInValid: false,
  eigenkapitalAktiv: '60.000,00',
  anfrageUuid: 'ca953b03-a70d-4c9c-a722-34cb430af3d4',
  ermittlungsweg: 'traditionell',
  bausteine: 'mutliple',
  anzahlBausteine: 2,
  darlehenBausteine: [
    {
      id: 0,
      type: 'ANNUITAETENDARLEHEN',
      darlehensbetrag: '200.000,00',
      darlehensbetragPrev: '',
      bereitstellungszinsfreieZeitInMonaten: '12',
      zinsbindungInJahren: '10',
      tilgungsWunsch: 'RATE',
      tilgungssatzInProzent: 0,
      rate: '1.500,00',
      sondertilgungJaehrlich: '10',
      auszahlungszeitpunkt: '2023-12-30T23:00:00.000Z',
      laufzeitInJahren: 0,
      laufzeitInMonaten: 0,
      tilgungsfreieAnlaufjahre: 0,
      verwendung: '',
      isVisible: true,
      state: 'success',
      darlehenError: false,
      darlehenErrorText: 'COMMON.ERRORS.FEE',
      zinsbindungInJahrenVariable: 0,
      sondertilgungJaehrlichVariable: 0,
      bereitstellungszinsfreieZeitInMonatenVariable: 0
    },
    {
      id: 1,
      type: 'KFW_DARLEHEN',
      darlehensbetrag: '18.125,00',
      darlehensbetragPrev: '',
      bereitstellungszinsfreieZeitInMonaten: 0,
      zinsbindungInJahren: '5',
      tilgungsWunsch: '',
      tilgungssatzInProzent: 0,
      rate: 0,
      sondertilgungJaehrlich: 0,
      auszahlungszeitpunkt: '',
      laufzeitInJahren: '25',
      laufzeitInMonaten: 0,
      tilgungsfreieAnlaufjahre: '4',
      verwendung: '',
      isVisible: true,
      state: 'success',
      darlehenError: false,
      darlehenErrorText: 'COMMON.ERRORS.FEE',
      zinsbindungInJahrenVariable: 0
    }
  ],
  darlehensbedarfVerbleibend: '0,00',
}