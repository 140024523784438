import { Component, EventEmitter, HostListener, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { switchesContent } from 'src/assets/content/switches';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-property-change',
  templateUrl: './property-change.component.html',
  styleUrls: ['./property-change.component.scss']
})
export class PropertyChangeComponent implements OnInit {
  public slideName: string = 'property-change';
  next: string = 'info-success-90';
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  fakeTiles: any[] = [];
  selectedTile: number = environment.webappType === 'c' ? 0 : 1;
  selectedTileValue: string = environment.webappType === 'c' ? 'PROPERTY_INFORMATION.PROPERTY.MAIN' : 'PROPERTY_INFORMATION.PROPERTY.DEFAULT 1';
  tilePosition: string = '122';
  basePosition: number = 122;
  screenPosition: string = '-50%';
  tileSlideFactor: number = 266;

  isOwner: boolean = environment.webappType === 'c' ? true : false;
  switchChange = switchesContent['PROPERTY_CHANGE'].items;
  switchOwner = [{
    "label": "keine Veränderungen",
    "value": "nein"
  },
  {
    "label": "Anschlussfinanzierung soll betrachtet werden",
    "value": "ANSCHLUSSFINANZIERUNG"
  },
  {
    "label": "Modernisierung ist geplant",
    "value": "MODERNISIERUNG_UMBAU_ANBAU"
  },
  {
    "label": "Eigentum soll beliehen werden",
    "value": "KAPITALBESCHAFFUNG"
  },
  {
    "label": "Mix aus mehreren Vorhaben",
    "value": "MIXED"
  }];
  switchUseForProject = [{
    "label": "keine Veränderungen",
    "value": "nein"
  },
  {
    "label": "Grundstück wird für das Neubauvorhaben verwendet",
    "value": "ja"
  }];
  switchFamily = [{
    "label": "keine Veränderungen",
    "value": "nein"
  },
  {
    "label": "dient als Zusatzsicherheit",
    "value": "ja"
  }];
  selectFamily = 'ja';
  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  constructor(
    private dataQuery: DataQuery,
    private daService: DataService,
    public screen: LayoutService,
    public contentService: ContentService,
    public daNextSlideService: DaNextSlideService,
  ) { }

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChildren('change') change!: QueryList<any>;
  @ViewChildren('kaltmiete') kaltmiete!: QueryList<any>;
  @ViewChildren('security') security!: QueryList<any>;
  @ViewChildren('financing') financing!: QueryList<any>;

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
    this.setTilePos();
  }

  ngOnInit(): void {
    this.setTilePos();
    this.setStatus();
  }

  setStatus() {
    const status = this.dataQuery.getEntity(1);
    const immos = this.dataQuery.getEntity(1)?.['bestehendeImmobilien'];
    let count = this.fakeTiles.length;

    if (!!status) {
      // User ist schon Besitzer der Imobilie, die finanziert werden soll (DA-C)
      if (this.isOwner) {
        let address = '';
        this.isOwner = true;
        if (status['objectAddress']) {
          if (status['objectAddressMitStrasse']) {
            if (status['objectAddressMitStrasse'] === 'true') {
              address = `${status['objectAddress'].plz} ${status['objectAddress'].ort},<br>${status['objectAddress'].strasse} ${status['objectAddress'].hausnummer}`;
            } else {
              address = `${status['objectAddress'].plz} ${status['objectAddress'].ort}`;
            }
          }
        }
        this.fakeTiles.push(this.fillFakeTiles('PROPERTY_INFORMATION.PROPERTY.MAIN', 0, 'success', '', false, true, false, address));
      } else {
        this.fakeTiles.push(this.fillFakeTiles('', 0, 'success', '', false, false, false, '', true));
      }
      // bestehende Immobilien
      if (immos && immos.length > 0) {
        let count = 1;

        if (this.fakeTiles.length > 0) {
          this.fakeTiles.splice(1, this.fakeTiles.length);
        }
        immos.map((item: any) => {
          let address = `${item.adresse?.plz} ${item.adresse?.ort},<br>${item.adresse?.strasse} ${item.adresse?.hausnummer}`
          if (!!item.useForProject) {
            let useProject = item.useForProject === 'ja' ? true : false;
            this.fakeTiles.push(this.fillFakeTiles('', count, 'success', '', false, false, useProject, address));
          } else if (!item.name.includes('FAMILY')) {
            this.fakeTiles.push(this.fillFakeTiles('', count, 'warning', '', false, false, false, address));
          } else {
            this.fakeTiles.push(this.fillFakeTiles('', count, 'success', '', true, false, false, address));
          }
          count++
        });
        this.fakeTiles.map((tile: any) => {
          if (tile.value !== 'PROPERTY_INFORMATION.PROPERTY.FAMILY') {
            this.setFakeTileStatus(tile.value, tile.id, 'init');
          }
        });
      } else {
        setTimeout(() => { this.setDisabled('init') });
      }
    }
  }

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'next') {
        if (this.allState !== 'success') {
          this.showState = true;
          this.setDisabled();
        } else {
          this.setDisabled('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 50)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  fillFakeTiles(
    name: string = '',
    count: number,
    state: string,
    nutzung: string,
    family: boolean,
    owner: boolean = false,
    useForProject: boolean = false,
    address: string,
    invisible: boolean = false,
  ) {
    let tile = {
      "value": !!name ? name : family ? "PROPERTY_INFORMATION.PROPERTY.FAMILY" : `PROPERTY_INFORMATION.PROPERTY.DEFAULT ${count}`,
      "image": family ? "assets/images/house.svg" : "assets/images/houseUser.svg",
      "state": state,
      "nutzung": nutzung,
      "selectFamilyItem": {},
      "selectItem": {},
      "owner": owner,
      "family": family,
      "useForProject": useForProject,
      "address": address,
      "id": count,
      "invisible": invisible
    }
    return tile;
  }

  setTilePos() {
    if (this.screen.isSmallerThanMinWidth()) {
      if (this.screen.isSmallest()) {
        this.screenPosition = '-25px';
        this.tilePosition = '0';
        this.tileSlideFactor = window.innerWidth;
      } else {
        this.screenPosition = '-50%';
        this.tilePosition = '122';
        this.tileSlideFactor = 266;
      }
    } else {
      this.screenPosition = '-50%';
      this.tilePosition = '122';
      this.tileSlideFactor = 266;
    }
    this.getIndex(this.selectedTile);
  }

  getIndex(i: number) {
    this.fakeTiles.map((item: any) =>{
      if(item.id === i) {
        this.selectedTileValue = item.value;
      }
    })
    this.tilePosition = this.screen.calculateNewPosById(!this.isOwner ? i - 1 : i, this.tileSlideFactor, this.tilePosition, this.basePosition);
  };

  // the toggles
  setSwitch(e: string, name: string, parent: string, num: number) {
    this.getValue(e, name, num, parent);
  }

  // the input
  validateInput(e: any, name: string, num: number, parent: string, subname?: string) {
    setTimeout(() => {
      let value = e.target ? e.target.value : e;
      value = typeof value === 'string' ? value : value[0];
      let element: any;
      const allKaltmiete = this.kaltmiete.toArray();
      allKaltmiete.map((item) => {
        if (item.name === subname) {
          element = item
        }
      });

      element.touched = true;
      element.placeholder = '';
      element.value = value;

      if (element.value === '') {
        element.error = true;
        this.getValue('', name, num, parent);
        return
      }

      let isValidAmount = this.contentService.minMax(element.value, 1, 9999);
      let isValid = this.contentService.validatePattern(element.value, element.validationType);

      element.error = isValid && isValidAmount === true ? false : true;
      if (!element.error) {
        this.getValue(value, name, num, parent);
      } else {
        this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, parent, 'error');
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.setAllState('error', 'ERROR');
        this.stateChange.emit(true);
        setTimeout(() => { this.setDisabled() });
      }
    }, 100)
  }

  getValue(e: any, name: string, i: number, parent: string) {

    let value = e.target?.value ? e.target?.value : e;
    let immobilien: {
      id: number;
      name: string;
      objektArt: string;
      marktwert: string;
      objektnutzung: string;
      kaltmiete: string;
      adresse: any;
      finanzierung: string;
      restschuld: string;
      darlehen: string;
      rate: string;
      useForProject: string;
      umschulden: string;
      erloesVerwenden: string;
      realValue: string;
      betragEinsetzen: string;
      change: string;
      zwischenfinanzierung: string,
      zusatzsicherheit: string
    }[] = [];
    const status = this.dataQuery.getEntity(1)?.['bestehendeImmobilien'];

    if (status) {
      status.map((immobilie: any) => {
        if (immobilie.id === i) {
          switch (name) {
            case 'change': {
              immobilie = { ...immobilie, 'change': value };
              break
            }
            case 'security': {
              immobilie = { ...immobilie, 'zusatzsicherheit': value };
              break
            }
            case 'financing': {
              immobilie = { ...immobilie, 'zwischenfinanzierung': value };
              break
            }
            case 'kaltmiete': {
              immobilie = { ...immobilie, 'kaltmiete': value };
              break
            }
          }
        }

        immobilien.push(immobilie);
      });
    }
    this.daService.update(1, { bestehendeImmobilien: immobilien });
    this.setFakeTileStatus(parent, i);
  }

  setFakeTileStatus(parent: string, i: number, type: string = 'default') {
    const status = this.dataQuery.getEntity(1)?.['bestehendeImmobilien'];
    let state = 'warning';
    let num = i - 1;

    if (!!status?.[num]?.['name'] && status?.[num]?.['name'] !== 'PROPERTY_INFORMATION.PROPERTY.FAMILY') {
      if (!!status?.[num]?.['change'] && !!status?.[num]?.['zusatzsicherheit']) {
        if (status?.[num]?.['change'] === 'KEIN_EINSATZ') {
          state = 'success';
        } else {
          if (status?.[num]?.['change'] === 'VERKAUFEN' && !!status?.[num]?.['zwischenfinanzierung']) {
            state = 'success'
          } else if (status?.[num]?.['change'] === 'VERMIETEN' && !!status?.[num]?.['kaltmiete']) {
            state = 'success'
          } else {
            state = 'warning'
          }
        }
      }
    } else {
      state = 'success';
    }
    this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, parent, state);
    setTimeout(() => { this.setDisabled(type) });
  }

  setDisabled(type: string = 'default') {
    const inputFields = this.kaltmiete.toArray();
    this.disabled = true;
    let isNotValid = true;
    let hasWarning = 0;
    let hasError = 0;
    let state = 'warning';
    let stateText = 'INCOMPLETE';

    this.fakeTiles?.map((tile: any) => {
      if (tile.state === 'warning') {
        hasWarning++;
      }
      if (tile.state === 'error') {
        hasError++;
      }
    });

    isNotValid = hasWarning > 0 || hasError > 0;
    state = hasError > 0 ? 'error' : hasWarning > 0 ? 'warning' : 'success';
    stateText = hasError > 0 ? 'ERROR' : hasWarning > 0 ? 'INCOMPLETE' : 'COMPLETE';

    this.contentService.setSlideIndexState(this.slideName, false, state);
    this.setAllState(state, stateText);
    this.stateChange.emit(true);

    if (inputFields?.length > 0) {
      inputFields.map((item: any) => {
        if (item.error) {
          this.contentService.setSlideIndexState(this.slideName, false, 'error');
          this.setAllState('error', 'ERROR');
          this.stateChange.emit(true);
          isNotValid = true;
        }
      })
    }

    if (!isNotValid) {
      this.disabled = false;
      if (type === 'test') {
        this.inputDisabled = true;
        this.testValid = false;
      }
    } else {
      this.disabled = true;
      this.testValid = true;
    }

    if (this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }

  setLocalValue(num: number, field: string) {
    let parsedValue = this.contentService.getNestedValue('bestehendeImmobilien', field, num) ?? '';
    return parsedValue;
  }
}
