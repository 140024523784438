<lib-header-text
    class="da-title"
    label="{{'ADDRESS.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>

<div class="da-tiles-wrapper" [ngClass]="{ 'mobile' : screen.isMobile() }">
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container1State"></lib-state-icon>
        <div class="da-address-top-wrapper">
            <lib-tile-text
                label="{{'ADDRESS.TILE1_TITLE' | translate}}"
                size="20px"
                [convertMobile]="true"
            ></lib-tile-text>
            <lib-toggle
                #objectSelect
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="select"
                value1="true"
                value2="false"
                [inputDisabled]="inputDisabled"
                [localValue]="select"
                (switchValue)="getSwitchValue($event, objectSelect)"
            ></lib-toggle>
            <!-- <svg-icon src="assets/images/mapLocation.svg" class="da-address-icon"></svg-icon> -->
        </div>
        <div class="da-address-inputs" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <div class="da-address-input plz" [ngClass]="{'has-select' : showPlzSelect}">
                <lib-input
                    #objectPlz
                    label="{{'ADDRESS.LABELS.PLZ' | translate}}"
                    name="plz"
                    [error]="false"
                    placeholder="12345"
                    errorText="{{'ADDRESS.ERRORS.PLZ' | translate}}"
                    [touched]="!!contentService.getValue('objectAddress').plz"
                    [value]="!!contentService.getValue('objectAddress').plz ? contentService.getValue('objectAddress').plz : ''"
                    (change)="getPlz($event, objectPlz)"
                    [isCurrency]="false"
                    (click)="testValid = true"
                    [inputDisabled]="plzDisabled || inputDisabled"
                    [autoWidth]="true"
                ></lib-input>
                <!-- wird vielleicht später wieder gebraucht -->
                <!-- <lib-dropdown 
                    *ngIf="showPlzSelect"
                    #objectPlz
                    name="plz"
                    [selectItems]="plzItems" 
                    [selectedItem]="objectPlz.selectedItem ? objectPlz.selectedItem : selectedPlz"
                    label="{{'ADDRESS.LABELS.PLZ' | translate}}"
                    (selectValue)="getSelectedPlz($event)"
                    [autoWidth]="true">
                </lib-dropdown> -->
            </div>
            <div class="da-address-input ort">
                <lib-input
                    #objectOrt
                    label="{{'ADDRESS.LABELS.ORT' | translate}}"
                    name="ort"
                    validationType="city"
                    inputmode=""
                    [error]="false"
                    placeholder="Musterstadt"
                    errorText="{{'ADDRESS.ERRORS.ORT' | translate}}"
                    [touched]="!!contentService.getValue('objectAddress').ort"
                    [value]="!!contentService.getValue('objectAddress').ort ? contentService.getValue('objectAddress').ort : ''"
                    [inputDisabled]="cityDisabled || inputDisabled"
                    (change)="getCity($event, objectOrt)"
                    (click)="testValid = true"
                    [isCurrency]="false"
                    [autoWidth]="true"
                ></lib-input>
            </div>
        </div>
    </div>
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container2State"></lib-state-icon>
        <div class="da-address-top-wrapper">
            <lib-tile-text
                label="{{'ADDRESS.TILE2_TITLE' | translate}}"
                size="20px"
                [convertMobile]="true"
            ></lib-tile-text>
            <lib-toggle
                #objectMitStrasse
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="mitStrasse"
                value1="true"
                value2="false"
                [inputDisabled]="inputDisabled"
                [localValue]="mitStrasse"
                (switchValue)="getSwitchValue($event, objectMitStrasse)"
            ></lib-toggle>
        </div>

        <div class="da-address-inputs" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <div class="da-address-input strasse">
                <lib-input
                    #objectStrasse
                    label="{{'ADDRESS.LABELS.STRASSE' | translate}}"
                    name="strasse"
                    validationType="street"
                    inputmode=""
                    [error]="false"
                    placeholder="Musterstrasse"
                    errorText="{{'ADDRESS.ERRORS.STREET' | translate}}"
                    [touched]="!!contentService.getValue('objectAddress').strasse"
                    [inputDisabled]="streetDisabled || inputDisabled"
                    [value]="!!contentService.getValue('objectAddress').strasse ? contentService.getValue('objectAddress').strasse : ''"
                    (change)="validateInputValue($event, objectStrasse)"
                    (click)="testValid = true"
                    [isCurrency]="false"
                    [autoWidth]="true"
                ></lib-input>
            </div>
            <div class="da-address-input nr">            
                <lib-input
                    #objectNr
                    label="{{'ADDRESS.LABELS.NR' | translate}}"
                    name="hausnummer"
                    validationType="streetNr"
                    inputmode=""
                    [error]="false"
                    placeholder="12"
                    errorText="{{'ADDRESS.ERRORS.NR' | translate}}"
                    [touched]="!!contentService.getValue('objectAddress').hausnummer"
                    [inputDisabled]="nrDisabled || inputDisabled"
                    [value]="!!contentService.getValue('objectAddress').hausnummer ? contentService.getValue('objectAddress').hausnummer : ''"
                    (change)="validateInputValue($event, objectNr)"
                    (click)="testValid = true"
                    [isCurrency]="false"
                    [autoWidth]="true"
                ></lib-input>
            </div>
        </div>
    </div>
</div>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>

</div>

