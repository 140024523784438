<div class="da-footer" *ngIf="visible">
    <div class="da-footer-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <div class="da-footer-element left">
            <app-footer-menu (gender)="screen.openInfo(genderInfo)" (cookies)="screen.openInfo(cookieInfo)"></app-footer-menu>
        </div>
        <div 
            class="da-footer-element center" 
            (click)="screen.openInfo(co2Info)"
            placement="bottom" 
            [ngbTooltip]="co2"
        >
            <p>{{ 'FOOTER.CO2' | translate}}
                <span>
                    <svg-icon
                        src="/assets/images/leaf.svg"
                    > </svg-icon>
                </span>
            </p>
            <ng-template #co2>
                <app-link-label label="Lightbox für mehr Information öffnen"></app-link-label>
            </ng-template>
        </div>
        <div class="da-footer-element right">
            <lib-tile-text
                (click)="openLink()"
                [placement]="screen.isSmallest() ? 'top' : 'bottom'" 
                [ngbTooltip]="copyright" 
                label="{{ 'FOOTER.COPYRIGHT' | translate}}"
                size="14px"
            >
        </lib-tile-text>
        <ng-template #copyright>
            <app-link-label label="{{ 'FOOTER.LINK' | translate}}" [top]="screen.isSmallest()"></app-link-label>
        </ng-template>
        </div>
    </div>
</div>
<ng-template #co2Info let-modal>
    <app-info-sidebar 
    [isGreen]="true"
    [modal]="modal" 
    category="{{'FOOTER.INFO.ECO_HEADER' | translate}}" 
    text="{{'FOOTER.INFO.ECO_TEXT' | translate}}"
    [info]="false"
    icon="assets/images/leaf.svg"
    ></app-info-sidebar>
</ng-template>
<ng-template #genderInfo let-modal>
    <app-info-sidebar 
    [modal]="modal" 
    category="{{'FOOTER.INFO.GENDER_HEADER' | translate}}" 
    text="{{'FOOTER.INFO.GENDER_TEXT' | translate}}"
    [info]="false"
    icon="assets/images/personHalfDress.svg"
    ></app-info-sidebar>
</ng-template>
<ng-template #cookieInfo let-modal>
    <app-info-sidebar 
    [modal]="modal" 
    category="cookies" 
    text=""
    [info]="false"
    icon=""
    ></app-info-sidebar>
</ng-template>
