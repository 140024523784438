import { Component, EventEmitter, HostListener, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-children',
  templateUrl: './children.component.html',
  styleUrls: ['./children.component.scss']
})
export class ChildrenComponent implements OnInit {
  public slideName: string = 'children';
  next: string = 'privatkredit';
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  title: string = 'CHILDREN.TITLE';
  tiles = tilesContent['CHILDREN'].items;
  fakeTiles = [{
    "value": "Kind 1",
    "name": "Kind 1",
    "state": "warning",
    "birthdateState": "warning",
    "kindergeldState": "warning",
    "unterhaltplusState": "warning",
    "unterhaltminusState": "warning"
  }];
  localValue!: string;
  showKids!: boolean;
  labelEdit: boolean = false;

  selectedTile: string = 'Kind 1';
  tilePosition: string = '0';
  basePosition: number = 0;
  screenPosition: string = '0px';
  tileSlideFactor: number = window.innerWidth;
  tileIndex: number = 0;
  maxDateBirth!: any;
  minDateBirth!: any;
  errorBirthdate: string = "COMMON.ERRORS.DATE";
  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('content') content!: TemplateRef<any>;
  @ViewChild('childContainer') childContainer!: any;
  @ViewChildren('birthdate') birthdateList!: QueryList<any>;
  @ViewChildren('kindergeld') kindergeldList!: QueryList<any>;
  @ViewChildren('unterhaltplus') unterhaltplusList!: QueryList<any>;
  @ViewChildren('unterhaltminus') unterhaltminusList!: QueryList<any>;
  @ViewChildren('unterhaltplusBetrag') unterhaltplusBetragList!: QueryList<any>;
  @ViewChildren('unterhaltminusBetrag') unterhaltminusBetragList!: QueryList<any>;
  @ViewChildren('inputs') inputsList!: QueryList<any>;

  constructor(
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public daNextSlideService: DaNextSlideService,
    public contentService: ContentService,
    private translate: TranslateService
  ) {
    this.minDateBirth = this.contentService.subtractYears(new Date(), 25);
    this.maxDateBirth = this.contentService.subtractYears(new Date(), 0);
    this.calculateScreenPos();
  }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
    this.calculateScreenPos();
  }

  ngOnInit(): void {
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.setStatus();
    });
  }

  setStatus() {
    this.disabled = true;
    const state = this.dataQuery.getEntity(1)?.['KinderErfassung'];
    const participants = this.dataQuery.getEntity(1)?.['participants'];

    if (!!participants && participants !== 'allein') {
      this.title = 'CHILDREN.TITLE_PARTNER';
    }

    if (!!state) {
      this.localValue = state;
      setTimeout(() => { this.setValue(state, true); }, 100);
      setTimeout(() => { this.setDisabled('init'); }, 200);
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'next') {
        if (this.allState !== 'success') {
          this.showState = true;
          this.setDisabled();
        } else {
          this.setDisabled('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 50)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  setValue(e: any, init?: boolean) {
    this.localValue = e;
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
    this.daService.update(1, { KinderErfassung: e });

    if (e === 'VORHANDENE_KINDER') {
      if (!init) {
        this.daService.update(1, { kinderAnzahl: 1 });
        this.setCounter(1);
      } else {
        this.setCounter(this.dataQuery.getEntity(1)?.['kinderAnzahl']);
        this.tiles = this.contentService.setTilesContent(this.tiles, e, this.content);
        this.showKids = true;
        return;
      };
      this.tiles = this.contentService.setTilesContent(this.tiles, e, this.content);
      this.showKids = true;
      setTimeout(() => {
        this.screen.scrollToElement('da-content-extended');
        this.setDisabled();
      }, 200);
    } else {
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, e, 'success');
      this.disabled = false;
      this.daNextSlideService.update(1, { disabled: this.disabled });
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.stateChange.emit(true);
      if (!init) { this.contentService.setNav('next', 'children', this.slideTo, this.next); }
    }
  }

  calculateScreenPos() {
    setTimeout(() => {
      if (this.screen.isSmallerThanMinWidth()) {
        this.tileSlideFactor = window.innerWidth;
      } else {
        this.tileSlideFactor = 1366;
      }
      this.getIndex(this.selectedTile);
    });
  }


  setCounter(e: number) {
    this.daService.update(1, { kinderAnzahl: e });
    this.fakeTiles = [];
    for (let i = 1; i <= e; i++) {
      let childName  = 'Kind 1'; 
      this.translate.get('CHILDREN.LABEL', {number: i}).subscribe((res: string) => {
        childName = res;
    });
      this.fakeTiles.push({
        "value": "Kind " + i,
        "state": "warning",
        "name": this.setLocalValue(i - 1, 'name') !== '' ? this.setLocalValue(i - 1, 'name') : childName,
        "birthdateState": "warning",
        "kindergeldState": "warning",
        "unterhaltplusState": "warning",
        "unterhaltminusState": "warning"
      })
      this.getValue(this.setLocalValue(i - 1, 'name') !== '' ? this.setLocalValue(i - 1, 'name') : childName, 'name', i - 1);
    }
    setTimeout(() => { this.setDisabled() });
  }

  setLocalValue(num: number, field: string) {
    let parsedValue = this.contentService.getNestedValue('kinder', field, num) ?? '';
    return parsedValue;
  }

  getValue(e: any, name: string, i: number) {
    let value = e.target?.value ? e.target?.value : e;
    let children: { id: number; name: string; geburtsdatum: string; kindergeldWirdBezogen: string; unterhaltPlus: string; unterhaltMinus: string; unterhaltPlusBetrag: string; unterhaltMinusBetrag: string; }[] = [];
    const status = this.dataQuery.getEntity(1)?.['kinder'];
    let childName; 
    this.translate.get('CHILDREN.LABEL', {number: i}).subscribe((res: string) => {
      childName = res;
  });
    if (!status) {
      const count = i + 1;
      children.push({
        'id': i,
        'name': name === 'name' ? value : childName + ' ' + count,
        'geburtsdatum': name === 'birthdate' ? value : '',
        'kindergeldWirdBezogen': name === 'kindergeld' ? value : '',
        'unterhaltPlus': name === 'unterhaltplus' ? value : '',
        'unterhaltMinus': name === 'unterhaltminus' ? value : '',
        'unterhaltPlusBetrag': name === 'unterhaltplusBetrag' ? value : '',
        'unterhaltMinusBetrag': name === 'unterhaltminusBetrag' ? value : '',
      })
    } else {
      let count = 0;
      status.map((child: any) => {
        if (child.id === i) {
          count++;
          switch (name) {
            case 'name': {
              child = { ...child, 'name': value };
              break
            }
            case 'birthdate': {
              child = { ...child, 'geburtsdatum': value };
              break
            }
            case 'kindergeld': {
              child = { ...child, 'kindergeldWirdBezogen': value };
              break
            }
            case 'unterhaltplus': {
              child = { ...child, 'unterhaltPlus': value };
              break
            }
            case 'unterhaltplusBetrag': {
              child = { ...child, 'unterhaltPlusBetrag': value };
              break
            }
            case 'unterhaltminus': {
              child = { ...child, 'unterhaltMinus': value };
              break
            }
            case 'unterhaltminusBetrag': {
              child = { ...child, 'unterhaltMinusBetrag': value };
              break
            }
          }
        }
        children.push(child);
      });
      if (count === 0) {
        const count = i + 1;
        children.push({
          'id': i,
          'name': name === 'name' ? value : childName + ' ' + count,
          'geburtsdatum': name === 'birthdate' ? value : '',
          'kindergeldWirdBezogen': name === 'kindergeld' ? value : '',
          'unterhaltPlus': name === 'unterhaltplus' ? value : '',
          'unterhaltMinus': name === 'unterhaltminus' ? value : '',
          'unterhaltPlusBetrag': name === 'unterhaltplusBetrag' ? value : '',
          'unterhaltMinusBetrag': name === 'unterhaltminusBetrag' ? value : '',
        })
      }
    }
    this.daService.update(1, { kinder: children });
    setTimeout(() => { this.setDisabled() });
  }

  validateDate(e: any, i: number, isInput?: boolean) {
    const element = this.birthdateList.get(i);
    let isDone = false;
    element.error = !!e ? false : true;
    if (isInput) {
      if (e._i.length >= 8 && e._i.substring(e._i.length - 5, e._i.length - 4) === '.') {
        isDone = true;
      } else {
        isDone = false;
      }
    } else {
      isDone = true;
    }

    if (isDone) {
      const baseDate = e !== null ? e.toDate() : null;
      let baseTooOld: boolean = false
      let baseTooYoung: boolean = false;

      setTimeout(() => {
        element.touched = true;
        baseTooYoung = baseDate > this.maxDateBirth;
        baseTooOld = baseDate < this.minDateBirth;
        element.error = baseTooYoung || baseTooOld ? true : false;
        if (!element.error) {
          this.getValue(baseDate, 'birthdate', i);
          this.fakeTiles[i].birthdateState = 'success';
        } else {
          this.fakeTiles[i].birthdateState = 'error';
          this.contentService.setSlideIndexState(this.slideName, false, 'error');
          this.setAllState('error', 'ERROR');
          this.stateChange.emit(true);
          setTimeout(() => { this.setDisabled()}); 
        }
      }, 50);
    }
  }

  validateInput(e: any, name: string, i: number) {
    setTimeout(() => {
      let value = typeof e === 'string' ? e : e.srcElement.value ? e.srcElement.value : '';
      let element: any;
      switch (name) {
        case 'kindergeld': element = this.kindergeldList.get(i);
          break;
        case 'unterhaltplus': element = this.unterhaltplusList.get(i);
          break;
        case 'unterhaltminus': element = this.unterhaltminusList.get(i);
          break;
        case 'unterhaltplusBetrag': {
          if (this.unterhaltplusBetragList.length === this.fakeTiles.length) {
            element = this.unterhaltplusBetragList.get(i);
          } else {
            this.unterhaltplusBetragList.toArray().map((item: any) => {
              let number = item.name.slice(item.name.length - 1);
              number = parseInt(number);
              if (number === i) {
                element = item;
              }
            })
          }
        }
          break;
        case 'unterhaltminusBetrag': {
          if (this.unterhaltminusBetragList.length === this.fakeTiles.length) {
            element = this.unterhaltminusBetragList.get(i);
          } else {
            this.unterhaltminusBetragList.toArray().map((item: any) => {
              let number = item.name.slice(item.name.length - 1);
              number = parseInt(number);
              if (number === i) {
                element = item;
              }
            })
          }
        }
          break;
      }

      if (name === 'unterhaltplusBetrag' || name === 'unterhaltminusBetrag') {
        element.touched = true;
        element.placeholder = '';
        element.value = value;
        if (element.value === '') {
          element.error = true;
          this.getValue('', name, i);
          setTimeout(() => { this.setDisabled(); });
          return
        }
        let isValidAmount = this.contentService.minMax(element.value, 1, 99999);
        let isValid = this.contentService.validatePattern(element.value, element.validationType);
        element.error = isValid && isValidAmount === true ? false : true;
        if (!element.error) {
          this.getValue(value, name, i);
        } else {
          this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, 'Kind ' + i, 'error');
          this.contentService.setSlideIndexState(this.slideName, false, 'error');
          this.setAllState('error', 'ERROR');
          this.stateChange.emit(true);
          setTimeout(() => { this.setDisabled() });
        }

      } else if (name === 'unterhaltplus' || name === 'unterhaltminus') {
        if (name === 'unterhaltplus' && value === 'true') {
          this.getValue('false', 'unterhaltminus', i);
          this.getValue('', 'unterhaltminusBetrag', i);
        }
        if (name === 'unterhaltminus' && value === 'true') {
          this.getValue('false', 'unterhaltplus', i);
          this.getValue('', 'unterhaltplusBetrag', i);
        }
        this.getValue(value, name, i);
      } else {
        this.getValue(value, name, i);
      }

      setTimeout(() => { this.setDisabled(); });
    }, 50);
  }

  setDisabled(type: string = 'default') {
    const inputFields = this.inputsList.toArray();
    this.disabled = true;
    let count = 0;
    for (let i = 0; i < this.fakeTiles.length; i++) {
      const geburtsdatum = this.birthdateList.get(i).value ?? this.birthdateList.get(i).value;
      const kindergeld = this.kindergeldList.get(i).localValue.target?.value ?? this.kindergeldList.get(i).localValue;
      const unterhaltplus = this.unterhaltplusList.get(i).localValue.target?.value ?? this.unterhaltplusList.get(i).localValue;
      const unterhaltminus = this.unterhaltminusList.get(i).localValue.target?.value ?? this.unterhaltminusList.get(i).localValue;

      if (!!kindergeld) {
        this.fakeTiles[i].kindergeldState = 'success';
      }

      if(!!geburtsdatum) {
        if(!this.birthdateList.get(i).error) {
          this.fakeTiles[i].birthdateState = 'success';
        } else {
          this.fakeTiles[i].birthdateState = 'error';
          this.contentService.setSlideIndexState(this.slideName, false, 'error');
          this.setAllState('error', 'ERROR');
          this.stateChange.emit(true);
        }

      }

      if (!!unterhaltplus) {
        let unterhaltplusBetrag, unterhaltplusError;
        if (this.unterhaltplusBetragList.length === this.fakeTiles.length) {
          unterhaltplusBetrag = this.unterhaltplusBetragList.get(i)?.value;
          unterhaltplusError = this.unterhaltplusBetragList.get(i)?.error;
        } else {
          this.unterhaltplusBetragList.toArray().map((item: any) => {
            let number = item.name.slice(item.name.length - 1);
            number = parseInt(number);
            if (number === i) {
              unterhaltplusBetrag = item.value;
              unterhaltplusError = item.error;
            }
          })
        }

        if (unterhaltplus === 'true') {
          if (!!unterhaltplusBetrag) {
            this.fakeTiles[i].unterhaltplusState = unterhaltplusError ? 'error' : 'success';
            this.contentService.setSlideIndexState(this.slideName, false, unterhaltplusError ? 'error' : 'success');
            this.setAllState(unterhaltplusError ? 'error' : 'success', unterhaltplusError ? 'ERROR' : 'COMPLETE');
          } else {
            this.fakeTiles[i].unterhaltplusState = unterhaltplusError ? 'error' : 'warning';
            this.contentService.setSlideIndexState(this.slideName, false, unterhaltplusError ? 'error' : 'warning');
            this.setAllState(unterhaltplusError ? 'error' : 'warning', unterhaltplusError ? 'ERROR' : 'INCOMPLETE');
          }
          this.stateChange.emit(true);
        }

        if (unterhaltplus === 'false') {
          this.fakeTiles[i].unterhaltplusState = 'success';
        }
      }

      if (!!unterhaltminus) {
        let unterhaltminusBetrag, unterhaltminusError;
        if (this.unterhaltminusBetragList.length === this.fakeTiles.length) {
          unterhaltminusBetrag = this.unterhaltminusBetragList.get(i)?.value;
          unterhaltminusError = this.unterhaltminusBetragList.get(i)?.error;
        } else {
          this.unterhaltminusBetragList.toArray().map((item: any) => {
            let number = item.name.slice(item.name.length - 1);
            number = parseInt(number);
            if (number === i) {
              unterhaltminusBetrag = item.value;
              unterhaltminusError = item.error;
            }
          })
        }
        if (unterhaltminus === 'true') {
          if (!!unterhaltminusBetrag) {
            this.fakeTiles[i].unterhaltminusState = unterhaltminusError ? 'error' : 'success';
            this.contentService.setSlideIndexState(this.slideName, false, unterhaltminusError ? 'error' : 'success');
            this.setAllState(unterhaltminusError ? 'error' : 'success', unterhaltminusError ? 'ERROR' : 'COMPLETE');
          } else {
            this.fakeTiles[i].unterhaltminusState = unterhaltminusError ? 'error' : 'warning';
            this.contentService.setSlideIndexState(this.slideName, false, unterhaltminusError ? 'error' : 'warning');
            this.setAllState(unterhaltminusError ? 'error' : 'warning', unterhaltminusError ? 'ERROR' : 'INCOMPLETE');
          }
          this.stateChange.emit(true);
        }

        if (unterhaltminus === 'false') {
          this.fakeTiles[i].unterhaltminusState = 'success';
        }
      }

      if (this.fakeTiles[i].kindergeldState === 'success' &&
        this.fakeTiles[i].birthdateState === 'success' &&
        this.fakeTiles[i].unterhaltplusState === 'success' &&
        this.fakeTiles[i].unterhaltminusState === 'success') {
        this.setIndexState(i, 'success');
        count++;
      } 
      if (this.fakeTiles[i].kindergeldState === 'warning' ||
        this.fakeTiles[i].birthdateState === 'warning' ||
        this.fakeTiles[i].unterhaltplusState === 'warning' ||
        this.fakeTiles[i].unterhaltminusState === 'warning') {
        this.setIndexState(i, 'warning');
      } 
      if (this.fakeTiles[i].kindergeldState === 'error' ||
        this.fakeTiles[i].birthdateState === 'error' ||
        this.fakeTiles[i].unterhaltplusState === 'error' ||
        this.fakeTiles[i].unterhaltminusState === 'error') {
        this.setIndexState(i, 'error');
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.setAllState('error', 'ERROR');
        this.stateChange.emit(true);
      }
    }

    if (count === this.fakeTiles.length) {
      this.disabled = false;
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.setAllState('success', 'COMPLETE');
      this.stateChange.emit(true);
    } else {
      this.disabled = true;
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.setAllState('warning', 'INCOMPLETE');
      this.stateChange.emit(true);
    }

    if(inputFields?.length > 0) {
      inputFields.map((item: any) => {
        if(item.error) {
          this.contentService.setSlideIndexState(this.slideName, false, 'error');
          this.setAllState('error', 'ERROR');
          this.stateChange.emit(true);
        }
      })
    }

    if(!this.disabled) {
      if (type === 'test') {
        this.inputDisabled = true;
        this.testValid = false;
      }
    } else {
      this.testValid = true;
      this.inputDisabled = false;
    }

    if(this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }

  editLabel(e: any, elementName: string, i: number) {
    const value = e.target ? e.target.value : e;
    this.fakeTiles[i].name = value;
    this.getValue(value, elementName, i);
  }

  setIndexState(i: number, state: string) {
    const count = i + 1;
    this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, 'Kind ' + count, state);
    this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, 'VORHANDENE_KINDER', state);
    this.contentService.setSlideIndexState(this.slideName, false, state);
      this.stateChange.emit(true);
  }

  getIndex(e: string, isIndexClick?: boolean) {
    if (isIndexClick) { this.labelEdit = false };
    this.selectedTile = e;
    this.tilePosition = this.screen.calculateNewPos(this.fakeTiles, e, this.tileSlideFactor, this.tilePosition, this.basePosition);
  };
}
