<lib-header-text
    class="da-title"
    label="{{'OBJECT_CATEGORY.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>

<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
>
    <ng-template #content>
        <lib-toggle-vertical
            [options]="switches"
            [localValue]="contentService.getValue('objectTypeGrundstueck')"
            name="grundstueck"
            (switchValue)="setObjectTypeGrundstueck($event)"
        ></lib-toggle-vertical>
        <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'OBJECT_CATEGORY.TILE_TEXT' | translate}}"
                [light]="true"
            >
            </lib-tile-text>
            <lib-input
                #objectEstateSize
                label="{{'COMMON.LABELS.SIZE' | translate}}"
                name="estateSize"
                [error]="false"
                [light]="true"
                placeholder="500,00"
                unit="m²"
                errorText="{{ sizeError | translate}}"
                [touched]="false"
                [value]="contentService.getValue('objectGesamtflaeche')"
                (inputValue)="validateInputValue($event, objectEstateSize)"
            ></lib-input>
    </ng-template>
</lib-tile-group>
<div class="da-buttons-wrapper">
    <app-nav-buttons
        class="da-buttons-group"
        [save]="true"
        [nextDisabled]="daNextSlideService.getDisabled()"
        [current]="slideName"
        [next]="next"
        (slideTo)="contentService.setNav($event, slideName, slideTo)"
        >
    </app-nav-buttons>
</div>
