import { Component, OnInit, Input, HostListener, OnDestroy } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LayoutService } from 'src/app/services/layout.service';
import { Debounce } from 'lodash-decorators';
import { DataService } from 'src/app/state/data/data.service';
import { UserData } from 'src/app/state/digitalAssistent/user-data.model';
import { DataQuery } from 'src/app/state/data/data.query';
import { Subscription, firstValueFrom } from 'rxjs';
import { UserQuery } from 'src/app/state/user/user.query';
import { UserService } from 'src/app/state/user/user.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EmailService } from 'src/app/services/email.service';
import { ContentService } from 'src/app/services/content.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
import { DaClickpathQuery } from 'src/app/state/click-path/da-clickpath.query';
import { LoginLightService } from 'src/app/services/login-light.service';
import { MappingService } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-login-light-sidebar',
  templateUrl: './login-light-sidebar.component.html',
  styleUrls: ['./login-light-sidebar.component.scss']
})
export class LoginLightSidebarComponent implements OnInit, OnDestroy {

  private testSub?: Subscription;
  @Input() modal!: NgbModalRef;
  @Input() showLoginLightExit: boolean = false;
  @Input() showLoginLightForm: boolean = false;
  @Input() showLoginLightSaveInfo: boolean = false;
  @Input() updateLoginLightProgress: boolean = false;
  firstSave: boolean = true;
  @Input() hasAccess: boolean = false;
  public isLoading: boolean = false;

  constructor(public screen: LayoutService,
    private daService: DataService,
    private contentService: ContentService,
    private loginLightService: LoginLightService,
    private dataQuery: DataQuery,
    private userQuery: UserQuery,
    private userService: UserService,
    private router: Router,
    private emailService: EmailService,
    private mappingService: MappingService,
    private configurationQuery: ConfigurationQuery) { }

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
    if (this.userService.isLoggedInForLoginLight()) {
      this.showLoginLightExit = true;
      this.firstSave = false;
    }

    // Falls der Nutzer im Header af 'Speichern' klickt, muss initial der Update Prozess getriggert werden
    if (this.updateLoginLightProgress) {
      this.onUpdateLoginLightProgress();
    }
  }

  ngOnDestroy(): void {
    this.testSub ? this.testSub.unsubscribe() : null;
  }

  dismiss() {
    document.body.style.overflowY = 'auto';
    this.modal.dismiss();
  }

  onBookConsultationAppointment(element: any) {
      this.screen.openInfo(element);
      this.dismiss();
  }

  userIsLoggedIn() {
    return this.userService.isLoggedInForLoginLight();
  }

  onSaveLoginLightProgress(userdata: UserData) {
    // console.log('isSave');
    this.isLoading = true;
    this.userService.getUuidForSave().then((uuidResult: any) => {
      if (uuidResult) {
        // console.log('save', uuidResult)
        this.daService.setLoginLightUuid(uuidResult)
        this.userService.getStoreData(uuidResult, { ...userdata, uuid: uuidResult }).then((storeDataResult) => {
          // console.log('save', storeDataResult);
          if (storeDataResult) {
            this.userService.saveProgress(storeDataResult).then((saveProgressResult: any) => {
              // console.log('save', saveProgressResult);
              this.showLoginLightSaveInfo = true;
              this.isLoading = false;
              let type = environment.webappType === 'a' ? 'n01a' : environment.webappType === 'b' ? 'n01b' : 'n01c';
              this.emailService.setEmail(type, '', '', true);
              this.configurationQuery.configuration.subscribe((res: any) => {
                if (res) {
                  this.emailService.setEmail('l01', res.kontaktemailkunden, saveProgressResult.customerid, true, storeDataResult);
                }
              });
            });
          }
        });
      }
    });
  }

  onUpdateLoginLightProgress() {
    // console.log('isUpdate');
    if (this.firstSave && this.hasAccess) {
      const data = {
        agb: this.contentService.getValue('agbZertifikat'),
        anrede: this.contentService.getValue('haushaltPerson') === 'MANN' || this.contentService.getValue('haushaltPerson') === 'DIVERS' ? 'HERR' : 'FRAU',
        datenschutz: this.contentService.getValue('datenschutzZertifikat'),
        mail: this.contentService.getValue('agbZertifikat'),
        nachname: this.contentService.getValue('haushaltPersonLastName'),
        vorname: this.contentService.getValue('haushaltPersonFirstName'),
      }
      this.onSaveLoginLightProgress(data);

    } else {
      this.isLoading = true;
      const uebergabe = this.dataQuery.getEntity(1)?.['uebergabe'];
      const data = { ...this.dataQuery.getEntity(1) };
      const userData = { ...this.userQuery.getEntity(1) };

      if (data) {
        this.userService.getStoreData(data['uuid'], { ...userData, uuid: data['uuid'] }).then((storeDataResult) => {
          // console.log('update', storeDataResult);
          if (storeDataResult) {
            this.userService.updateProgress(storeDataResult).then((updateProgressResult: any) => {
              // console.log('update', updateProgressResult);
              this.showLoginLightSaveInfo = true;
              this.showLoginLightExit = false;
              this.isLoading = false;
            });
          }
          if(uebergabe) {
            this.mappingService.mapReduxDataUpdate().then((resultOne: { subscribe: (arg0: (value: any) => void) => void; }) => {
              resultOne.subscribe((value: any) => {
                console.log('RESULT FROM EUROPACE (VORGANG AKTUALISIEREN): ', value);
              });
            });
          }
        });
      }
    }
  }

  onShowLoginLightForm() {
    this.showLoginLightExit = false;
    this.showLoginLightForm = true;
  }

  onLogout() {
    this.isLoading = true;

    if(this.dataQuery.getEntity(1)?.['uebergabe']) {
      this.onUpdateLoginLightProgress();
      setTimeout(() => {
        this.dismiss();
        this.loginLightService.resetStores();
        this.router.navigate(['/login-light']);
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }, 3000)
    } else {
      this.dismiss();
      this.loginLightService.resetStores();
      this.router.navigate(['/login-light']);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }

  isMobile() {
    return this.screen.isMobile();
  }

  isMobileOrSmallTablet() {
    return this.screen.isMobileOrSmallTablet();
  }
}
