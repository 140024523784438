<div class="da-forward-needs-wrapper">
    <lib-header-text
        class="da-title"
        label="{{'FINANCE_REQUIREMENTS.TITLE' | translate}}"
        size="38px"
        [convertMobile]="true"
    ></lib-header-text>
    <div class="da-forward-container-wrapper">
        <div class="da-forward-container header">
            <svg-icon class="da-forward-header-icon" src="assets/images/fileSignature.svg"></svg-icon>
            <lib-tile-text
                class="da-forward-subtitle"
                label="{{'FINANCE_REQUIREMENTS_FORWARD.COUNTER.TEXT' | translate}}"
                size="18px"
                [convertMobile]="true"
                info="true"
                (infoValue)="screen.openInfo(counterInfo)"
            ></lib-tile-text>
            <lib-counter
                #darlehenCounter
                [value]="contentService.getValue('darlehencounterForward', true)"
                name="darlehencounter"
                [min]="darlehenCount"
                [max]="5"
                (click)="testValid = true"
                (counterValue)="setSwitch($event, 'darlehencounter')"
            ></lib-counter>
        </div>
        <ng-container *ngFor="let item of contentService.getValue('darlehencounterForward', true) | fill; let num = index">
            <div class="da-forward-container">
                <app-darlehen-forward 
                    [count]="num + 1" 
                    [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                    [testValid]="testValid"
                    (sum)="calculateGesamt()"
                    (state)="setValid($event)"
                ></app-darlehen-forward>
            </div>
        </ng-container>
        <div class="da-forward-container">
            <app-modernisierung-forward 
                [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                [testValid]="testValid"
                (sum)="calculateGesamt()"
                (state)="setValid($event)"
            ></app-modernisierung-forward>
        </div>
        <div class="da-forward-container" *ngIf="!!contentService.getValue('VerbindlichkeitenErfassung') && contentService.getValue('VerbindlichkeitenErfassung') === 'VorhandeneVerbindlichkeiten'">
            <app-verbindlichkeiten-forward (sum)="calculateGesamt()"></app-verbindlichkeiten-forward>
        </div>
        <div class="da-forward-container">
            <app-kapital-forward 
                [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                [testValid]="testValid"
                (sum)="calculateGesamt()"
                (state)="setValid($event)"
            ></app-kapital-forward>
        </div>
        <div class="da-forward-container">
            <app-tilgung-forward 
                [inputDisabled]="inputDisabled && !contentService.getValue('uebergabe')"
                [testValid]="testValid"
                (sum)="calculateGesamt()"
                (state)="setValid($event)"
            ></app-tilgung-forward>
        </div>
    </div>
    <div class="da-forward-gesamt-wrapper" [ngClass]="{'mobile': screen.isSmallest()}">
        <div class="da-forward-container">
            <div class="da-gesamt-left">
                <lib-tile-text
                    class="da-title"
                    label="{{'FINANCE_REQUIREMENTS_FORWARD.GESAMT.LABEL' | translate }}"
                    size="16px"
                ></lib-tile-text>
            </div>
            <lib-input
                #gesamt
                #inputs
                label=""
                name="gesamt"
                [error]="false"
                errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                placeholder="0,00"
                unit="€"
                [inputDisabled]="true"
                [touched]="!!contentService.getValue('darlehensbedarf')"
                [value]="contentService.getValue('darlehensbedarf')"
                info="true"
                (infoValue)="screen.openInfo(gesamtInfo)"
            ></lib-input>
        </div>
    </div>
    <div class="da-buttons-wrapper" *ngIf="!hasAccess">
        <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}" [ngClass]="{'withDashboard' : !testValid}">
            <div class="da-button-states" *ngIf="showState">
                <lib-state-icon [state]="allState"></lib-state-icon>
                <lib-tile-text
                    label="{{allStateText | translate}}"
                    size="16px"
                    [convertMobile]="true"
                ></lib-tile-text>
            </div>
            <app-nav-buttons
                [save]="true"
                [dashboard]="true"
                [nextValidate]="testValid"
                [nextDisabled]="daNextSlideService.getDisabled()"
                [current]="slideName"
                (validate)="runValidation($event)"
                (slideTo)="setNav($event)"
                >
            </app-nav-buttons>
         </div>
    </div>
</div>
<ng-template #counterInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS_FORWARD.COUNTER.INFO_HEADER' | translate}}" text="{{'FINANCE_REQUIREMENTS_FORWARD.COUNTER.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #gesamtInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS_FORWARD.GESAMT.INFOS.HEADER' | translate}}" text="{{'FINANCE_REQUIREMENTS_FORWARD.GESAMT.INFOS.TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #uebergabeA let-modal>
    <app-uebergabe-sidebar                  
    [modal]="modal" 
    [webappType]="webappType"
    (uebergabeDone)="setStateChange()"
    ></app-uebergabe-sidebar>
</ng-template>
