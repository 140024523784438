import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DaNextSlide } from './da-next-slide.model';

export interface DaNextSlideState extends EntityState<DaNextSlide> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'nextSlideStore', resettable: true })

export class DaNextSlideStore extends EntityStore<DaNextSlideState> {

  constructor() {
    super();
  }

}
