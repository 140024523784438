import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DaNextSlideStore, DaNextSlideState } from './da-next-slide.store';

@Injectable({ providedIn: 'root' })
export class DaNextSlideQuery extends QueryEntity<DaNextSlideState> {

  constructor(protected override store: DaNextSlideStore) {
    super(store);
  }

}
