import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface ConfigurationState { 
    configuration: any;
    tenantuuid: string;
}

export function createInitialState(): ConfigurationState {
	return {
       configuration: '',
       tenantuuid: ''
	};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'configurationStore' })

export class ConfigurationStore extends Store<ConfigurationState> {

    constructor() {
        super(createInitialState());
    }

}