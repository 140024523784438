import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { DaClickpathService } from 'src/app/state/click-path/da-clickpath.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { tilesContent } from 'src/assets/content/tiles';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-estate-status',
  templateUrl: './estate-status.component.html'
})
export class EstateStatusComponent implements OnInit {
  public slideName: string = 'estate-status';
  next: string = 'project-plan';
  localValue!: string;
  disabled: boolean = true;
  tiles = tilesContent['ESTATE_STATUS'].items;

  @Output() public slideTo:EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('content') content!: TemplateRef<any>;
  @ViewChild('umleitung') umleitung!: TemplateRef<any>;

  constructor(
    private daClickpathService: DaClickpathService,
    private daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    private configurationQuery: ConfigurationQuery
  ) {
    this.tiles = environment.webappType === 'b' ? tilesContent['ESTATE_STATUS_B'].items : this.tiles;
  }

  ngOnInit() {
    this.daService.setCurrentSlide(this.slideName);
    const status = this.dataQuery.getEntity(1)?.['estateStatus'];
    const inGermany = this.dataQuery.getEntity(1)?.['projectInDeutschland'];

    if (!!status) {
      this.localValue = status;
      this.setValue(status, true);
    }

    if(!!inGermany) {
      setTimeout(() => {this.setSwitch(inGermany);},50)
    }
  }

  setValue(e: string, isInit: boolean = false) {
    // update data store
    if(!isInit) {
      this.localValue = e;
      this.daService.update(1, { estateStatus: e });
    }
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
    if (e === 'NEUBAU') {
      setTimeout(() => {
        this.contentService.setTilesContent(this.tiles, e, this.content);
        if(!isInit) {
          this.daService.update(1, { projectInDeutschland: '' });
        }
        this.setTileStatus(e);
      })
    } else if( e === 'OWNER') {
      this.contentService.setTilesContent(this.tiles, e, this.umleitung);
  } else {
      this.contentService.setSlideIndexState(this.slideName, false, e === 'NOT_CONCRETE' ? 'warning' : 'success');
      // update Navigation
      if(!isInit) {
        this.setNext(e);
        setTimeout(() => {
          this.daNextSlideService.update(1, { next: this.next, timestamp: new Date });
          this.daClickpathService.updateClickPath(this.slideName);
          this.slideTo.emit(this.next);
        },50);
      }
      this.setNext(e);
      // enable forward button
      this.disabled = false;
    }
  }

  goToDaC() {
    this.configurationQuery.configuration.subscribe((res: any) => {
      if (res) {
        window.location.assign(`https://${res.unternehmenseinstellungen.linkwebsite}.forwardkonfigurator.finsolio.de`);
      }
    })
  }

  setSwitch(e: string) {
    this.daService.update(1, { projectInDeutschland: e });
    this.setNext(this.localValue, e);
    this.setTileStatus();
  }

  setTileStatus(e?: string) {
    const status = this.dataQuery.getEntity(1);
    this.disabled = true;
    this.dataQuery.getEntity(1);
    if(e && e === 'NEUBAU') { // DA-B
      this.daService.update(1, { projectPlan: e });
    }

    if (!!status && !!status['projectInDeutschland']) {
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, this.localValue, e === 'NOT_CONCRETE' ? 'warning' : 'success');
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.disabled = false;
    } else {
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, this.localValue, 'warning');
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
    }
    this.stateChange.emit(true);
    this.daNextSlideService.update(1, { disabled: this.disabled });
  }

  setNext(value: string, inDe?: string) {
    if (value === 'OWNER' ) {
      if(inDe) {
        if(inDe === 'false') {
          this.next = 'info-error-project';
          this.contentService.setSlideIndex('info-error-project', false);
          this.contentService.setSlideIndex('project-plan-forward', true);
          this.contentService.setSlideIndex('project-plan', true);
        } else {
          this.next = 'project-plan-forward';
          this.contentService.setSlideIndex('info-error-project', true);
          this.contentService.setSlideIndex('status-spec', true);
          this.contentService.setSlideIndex('project-plan-forward', false);
          this.contentService.setSlideIndex('project-plan', true);
        }
      }
    } else if(value === 'NEUBAU'){ // DA-B
      if(inDe) {
        if(inDe === 'false') {
          this.next = 'info-error-project';
          this.contentService.setSlideIndex('info-error-project', false);
        } else {
          this.next = 'status-spec';
          this.contentService.setSlideIndex('info-error-project', true);
          this.contentService.setSlideIndex('status-spec', false);
        }
        this.contentService.setSlideIndex('project-plan-forward', true);
        this.contentService.setSlideIndex('project-plan', true);
      }
    } else {
      this.next = 'project-plan';
      this.contentService.setSlideIndex('info-error-project', true);
      this.contentService.setSlideIndex('status-spec', false);
      this.contentService.setSlideIndex('project-plan', false);
      this.contentService.setSlideIndex('project-plan-forward', true);
    };
  }

  setNav(e: string) {
    this.slideTo.emit(e);
  }
}
