import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { switchesContent } from 'src/assets/content/switches';

@Component({
  selector: 'app-laufzeit',
  templateUrl: './laufzeit.component.html',
  styleUrls: ['./laufzeit.component.scss']
})
export class LaufzeitComponent implements OnInit, OnChanges {
  switches = switchesContent['LAUFZEIT'].items;
  title = 'FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.TITLE';
  infoText = 'FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.INFO_TEXT';
  infoHeader = 'FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.INFO_HEADER';
  
  @Input() num!: number;
  @Input() type!: string;
  @Output() public done: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('fixedL') fixed!: any;
  @ViewChild('variableL') variable!: any;

  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private daService: DataService,
    private dataQuery: DataQuery,
  ) {}

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes) {
      if(this.type === 'PRIVATDARLEHEN') {
        this.switches = switchesContent['LAUFZEIT_M'].items;
        this.title = 'FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.TITLE2';
        this.infoText = 'FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.INFO_TEXT2';
        this.infoHeader = 'FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.INFO_HEADER2';
      } else if(this.type === 'KFW_DARLEHEN') {
        this.switches = switchesContent['LAUFZEIT'].items;
        this.title = 'FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.TITLE';
        this.infoText = 'FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.INFO_TEXT';
        this.infoHeader = 'FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.INFO_HEADER';
      } else {
        this.switches = switchesContent['LAUFZEIT_Z'].items;
        this.title = 'FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.TITLE2';
        this.infoText = 'FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.INFO_TEXT2';
        this.infoHeader = 'FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.INFO_HEADER2';
      }
    }
  }

  setSwitch(e: string) {
    let darlehen;
    if(e !== 'other') {
      darlehen = this.type !== 'KFW_DARLEHEN' ? this.contentService.updateNestedObject('darlehenBausteine', 'laufzeitInMonaten', e, this.num) : this.contentService.updateNestedObject('darlehenBausteine', 'laufzeitInJahren', e, this.num);
    } else {
      darlehen = this.type !== 'KFW_DARLEHEN' ? this.contentService.updateNestedObject('darlehenBausteine', 'laufzeitInMonaten', e, this.num, 'laufzeitInMonatenVariable', 0) : this.contentService.updateNestedObject('darlehenBausteine', 'laufzeitInJahren', e, this.num, 'laufzeitInJahrenVariable', 0);
    }
    this.daService.update(1, { darlehenBausteine: darlehen });
    this.done.emit(this.num);
  }

  validateInput(e: any, element: any) {
    setTimeout(() => {
      const value = e[0];
      element.touched = true;
      element.placeholder = '';
      let isValid = this.contentService.validatePattern(value, element.validationType);
      element.error = !isValid;

      let darlehen;
      if (!element.error) {
        darlehen = this.type !== 'KFW_DARLEHEN' ? this.contentService.updateNestedObject('darlehenBausteine', 'laufzeitInMonatenVariable', value, this.num, 'state', 'warning') : this.contentService.updateNestedObject('darlehenBausteine', 'laufzeitInJahrenVariable', value, this.num, 'state', 'warning');
      } else {
        darlehen = this.contentService.updateNestedObject('darlehenBausteine', 'state', 'error', this.num);
      }
      this.daService.update(1, { darlehenBausteine: darlehen });
      this.done.emit(this.num);

    },100)
  }

}
