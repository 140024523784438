<lib-header-text
    class="da-title"
    label="{{title1 | translate}}"
    size="38px"
    [convertMobile]="true"
    info="true"
    (infoValue)="screen.openInfo(assetsInfo)"
></lib-header-text>
<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
></lib-tile-group>
<ng-container *ngIf="showAssets">
    <lib-header-text
        class="da-title"
        label="{{title2 | translate}}"
        size="38px"
        [convertMobile]="true"
    ></lib-header-text>
    <div class="da-index">
        <app-index 
            [tiles]="fakeTiles" 
            [selectedTile]="selectedTile"
            [slideFactor]="tileSlideFactor"
            [slidePos]="tilePosition"
            (indexValue)="getIndex($event)">
        </app-index>
    </div>
    <div class="da-content-extended">
        <div class="da-content-extended-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest()}">
            <div 
                class="da-tiles-wrapper with-slider" 
                [ngClass]="{'mobile-wrap' : screen.isSmallest()}"
                [ngStyle]="{'right': 'calc('+ screenPosition +' + '+ tilePosition +'px)'}">
                
                <!-- TILE1 - SPARGUTHABEN -->
                <div
                    class="da-container" 
                    (click)="getIndex('Sparen')"
                    [ngClass]="{ 'mobile' : screen.isSmallest() }"
                    >
                    <lib-state-icon [state]="fakeTiles?.[0].state"></lib-state-icon>
                    <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                        <svg-icon class="da-tile-icon" [src]="fakeTiles?.[0].image"></svg-icon>
                        <lib-tile-text
                            class="da-tile-header"
                            size="20px"
                            [convertMobile]="true"
                            label="{{'FINANCIAL_ASSETS.TILES.TILE1.TITLE' | translate}}"
                            info="true"
                            (infoValue)="screen.openInfo(sparenInfo)"
                        ></lib-tile-text>
                        <lib-toggle
                            #sparenVorhanden
                            label1="{{'COMMON.TRUE' | translate}}"
                            label2="{{'COMMON.FALSE' | translate}}"
                            name="sparenVorhanden"
                            value1="sparguthabenVorhanden"
                            value2="keinSparguthaben"
                            [localValue]="contentService.getValue('sparguthaben')"
                            (switchValue)="setSwitch($event, sparenVorhanden, 'Sparen')"
                        ></lib-toggle>
                        <ng-container *ngIf="contentService.getValue('sparguthaben') === 'sparguthabenVorhanden'">
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'FINANCIAL_ASSETS.TILES.TILE1.TEXT1' | translate}}"
                            ></lib-tile-text>
                            <lib-input
                                #sparenBetrag
                                #inputs
                                label="{{'COMMON.LABELS.SUMME' | translate}}"
                                name="sparenBetrag"
                                [error]="false"
                                errorText="{{'COMMON.ERRORS.NUMBER' | translate}}"
                                placeholder="0,00"
                                unit="€"
                                [inputDisabled]="inputDisabled"
                                (click)="testValid = true"
                                [touched]="!!contentService.getValue('sparguthabenBetrag')"
                                [value]="!!contentService.getValue('sparguthabenBetrag') ? contentService.getValue('sparguthabenBetrag') : ''"
                                (change)="validateInput($event, sparenBetrag, 'Sparen')"
                            ></lib-input>
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'FINANCIAL_ASSETS.TILES.TILE1.TEXT2' | translate}}"
                            ></lib-tile-text>
                            <lib-toggle
                                #sparenEinsetzen
                                label1="{{'COMMON.TRUE' | translate}}"
                                label2="{{'COMMON.FALSE' | translate}}"
                                name="sparenEinsetzen"
                                value1="AUFLOESUNG_ALS_VERWENDUNG"
                                value2="KEINE_VERWENDUNG"
                                [localValue]="contentService.getValue('sparenVerwendung')"
                                (switchValue)="setSwitch($event, sparenEinsetzen, 'Sparen')"
                            ></lib-toggle>
                            <ng-container *ngIf="contentService.getValue('sparenVerwendung') === 'AUFLOESUNG_ALS_VERWENDUNG'">
                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'FINANCIAL_ASSETS.TILES.TILE1.TEXT3' | translate}}"
                                ></lib-tile-text>
                                <lib-input
                                    #sparenEinsetzenBetrag
                                    #inputs
                                    label="{{'COMMON.LABELS.SUMME' | translate}}"
                                    name="sparenEinsetzenBetrag"
                                    [error]="false"
                                    errorText="{{errorSparen | translate}}"
                                    placeholder="0,00"
                                    unit="€"
                                    [inputDisabled]="inputDisabled"
                                    (click)="testValid = true"
                                    [touched]="!!contentService.getValue('sparenMaximalEinzusetzenderBetrag')"
                                    [value]="!!contentService.getValue('sparenMaximalEinzusetzenderBetrag') ? contentService.getValue('sparenMaximalEinzusetzenderBetrag') : ''"
                                    (change)="validateInput($event, sparenEinsetzenBetrag, 'Sparen')"
                                ></lib-input>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                
                <!-- TILE2 - BAUSPAREN -->
                <div
                    class="da-container" 
                    (click)="getIndex('Bausparen')"
                    [ngClass]="{ 'mobile' : screen.isSmallest() }"
                    >
                    <lib-state-icon [state]="fakeTiles?.[1].state"></lib-state-icon>
                    <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                        <svg-icon class="da-tile-icon" [src]="fakeTiles?.[1].image"></svg-icon>
                        <lib-tile-text
                            class="da-tile-header"
                            size="20px"
                            [convertMobile]="true"
                            label="{{'FINANCIAL_ASSETS.TILES.TILE2.TITLE' | translate}}"
                            info="true"
                            (infoValue)="screen.openInfo(bausparInfo)"
                        ></lib-tile-text>
                        <lib-toggle
                            #bausparenVorhanden
                            label1="{{'COMMON.TRUE' | translate}}"
                            label2="{{'COMMON.FALSE' | translate}}"
                            name="bausparenVorhanden"
                            value1="bausparguthabenVorhanden"
                            value2="keinBausparguthaben"
                            [localValue]="contentService.getValue('bausparguthaben')"
                            (switchValue)="setSwitch($event, bausparenVorhanden, 'Bausparen')"
                        ></lib-toggle>
                        <ng-container *ngIf="contentService.getValue('bausparguthaben') === 'bausparguthabenVorhanden'">
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'FINANCIAL_ASSETS.TILES.TILE2.TEXT1' | translate}}"
                            ></lib-tile-text>
                            <lib-counter
                                #bausparenCount
                                [value]="contentService.getValue('bausparenAnzahl', true)"
                                name="bausparenCount"
                                [min]="1"
                                [max]="3"
                                (counterValue)="setCounter($event, bausparenCount, 'Bausparen')"
                            ></lib-counter>
                            <ng-container *ngIf="contentService.getValue('bausparenAnzahl')">
                                <div [ngStyle]="{'margin-top': '25px'}" *ngIf="bausparTabs && bausparTabs.length > 1">
                                    <lib-toggle-tristate 
                                        #bausparTabsInput
                                        class="da-bauspar-tabs"
                                        name="bausparTabs"
                                        [tabs]="bausparTabs"
                                        (switchValue)="setTristate($event, bausparTabsInput)"
                                    ></lib-toggle-tristate> 
                                </div>
                                <lib-tile-text
                                    *ngIf="bausparTabs && bausparTabs.length > 1"
                                    size="20px"
                                    [convertMobile]="true"
                                    [label]="'FINANCIAL_ASSETS.LABEL' | translate: {number: selectedTab}"
                                ></lib-tile-text> 
                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'FINANCIAL_ASSETS.TILES.TILE2.TEXT2' | translate}}"
                                ></lib-tile-text>
                                <lib-input
                                    #bausparenBetrag
                                    #inputs
                                    label="{{'COMMON.LABELS.SUMME' | translate}}"
                                    name="bausparenBetrag{{selectedTab}}"
                                    [error]="false"
                                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                                    placeholder="0,00"
                                    unit="€"
                                    [inputDisabled]="inputDisabled"
                                    (click)="testValid = true"
                                    [touched]="!!setLocalValue(selectedTab, 'angesparterBetrag', 'bausparvertraege')"
                                    [value]="!!setLocalValue(selectedTab, 'angesparterBetrag', 'bausparvertraege') ? setLocalValue(selectedTab, 'angesparterBetrag', 'bausparvertraege') : ''"
                                    (change)="validateInput($event, bausparenBetrag, 'Bausparen')"
                                ></lib-input>
                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'FINANCIAL_ASSETS.TILES.TILE2.TEXT3' | translate}}"
                                ></lib-tile-text>
                                <lib-toggle-vertical *ngIf="selectedTab === 1"
                                    #bausparenEinsetzen1
                                    [options]="switches"
                                    [localValue]="selectedVerwendung1"
                                    name="bausparenVerwendung1"
                                    (switchValue)="setSwitch($event, bausparenEinsetzen1, 'Bausparen')"
                                ></lib-toggle-vertical>
                                <lib-toggle-vertical *ngIf="selectedTab === 2"
                                    #bausparenEinsetzen2
                                    [options]="switches"
                                    [localValue]="selectedVerwendung2"
                                    name="bausparenVerwendung2"
                                    (switchValue)="setSwitch($event, bausparenEinsetzen2, 'Bausparen')"
                                ></lib-toggle-vertical>
                                <lib-toggle-vertical *ngIf="selectedTab === 3"
                                    #bausparenEinsetzen3
                                    [options]="switches"
                                    [localValue]="selectedVerwendung3"
                                    name="bausparenVerwendung3"
                                    (switchValue)="setSwitch($event, bausparenEinsetzen3, 'Bausparen')"
                                ></lib-toggle-vertical>
                                <ng-container *ngIf="contentService.getNestedObject('bausparvertraege', 'verwendung', selectedTab) === 'AUFLOESUNG_ALS_VERWENDUNG'">
                                    <lib-tile-text
                                        size="20px"
                                        [convertMobile]="true"
                                        label="{{'FINANCIAL_ASSETS.TILES.TILE2.TEXT4' | translate}}"
                                    ></lib-tile-text>
                                    <lib-input
                                        #bausparenEinsetzenBetrag
                                        #inputs
                                        label="{{'COMMON.LABELS.SUMME' | translate}}"
                                        name="bausparenEinsetzenBetrag{{selectedTab}}"
                                        [error]="false"
                                        errorText="{{errorBauSparen | translate}}"
                                        placeholder="0,00"
                                        unit="€"
                                        [inputDisabled]="inputDisabled"
                                        (click)="testValid = true"
                                        [touched]="!!setLocalValue(selectedTab, 'maximalEinzusetzenderBetrag', 'bausparvertraege')"         
                                        [value]="!!setLocalValue(selectedTab, 'maximalEinzusetzenderBetrag', 'bausparvertraege') ? setLocalValue(selectedTab, 'maximalEinzusetzenderBetrag', 'bausparvertraege') : ''"
                                        (change)="validateInput($event, bausparenEinsetzenBetrag, 'Bausparen')"
                                    ></lib-input>
                                </ng-container>
                            </ng-container>                                        
                        </ng-container>
                    </div>
                </div>

                <!-- TILE3 - WERTPAPIERE -->
                <div
                    class="da-container" 
                    (click)="getIndex('Wertpapiere')"
                    [ngClass]="{ 'mobile' : screen.isSmallest() }"
                    >
                    <lib-state-icon [state]="fakeTiles?.[2].state"></lib-state-icon>
                    <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                        <svg-icon class="da-tile-icon" [src]="fakeTiles?.[2].image"></svg-icon>
                        <lib-tile-text
                            class="da-tile-header"
                            size="20px"
                            [convertMobile]="true"
                            label="{{'FINANCIAL_ASSETS.TILES.TILE3.TITLE' | translate}}"
                            info="true"
                            (infoValue)="screen.openInfo(wertInfo)"
                        ></lib-tile-text>
                        <lib-toggle
                            #wertpapiereVorhanden
                            label1="{{'COMMON.TRUE' | translate}}"
                            label2="{{'COMMON.FALSE' | translate}}"
                            name="wertpapiereVorhanden"
                            value1="wertpapiereVorhanden"
                            value2="keineWertpapiere"
                            [localValue]="contentService.getValue('wertpapiere')"
                            (switchValue)="setSwitch($event, wertpapiereVorhanden, 'Wertpapiere')"
                        ></lib-toggle>
                        <ng-container *ngIf="contentService.getValue('wertpapiere') === 'wertpapiereVorhanden'">
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'FINANCIAL_ASSETS.TILES.TILE3.TEXT1' | translate}}"
                            ></lib-tile-text>
                            <lib-input
                                #wertpapiereBetrag
                                #inputs
                                label="{{'COMMON.LABELS.SUMME' | translate}}"
                                name="wertpapiereBetrag"
                                [error]="false"
                                errorText="{{'COMMON.ERRORS.NUMBER' | translate}}"
                                placeholder="0,00"
                                unit="€"
                                [inputDisabled]="inputDisabled"
                                (click)="testValid = true"
                                [touched]="!!contentService.getValue('wertpapiereBetrag')"
                                [value]="!!contentService.getValue('wertpapiereBetrag') ? contentService.getValue('wertpapiereBetrag') : ''"
                                (change)="validateInput($event, wertpapiereBetrag, 'Wertpapiere')"
                            ></lib-input>
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'FINANCIAL_ASSETS.TILES.TILE1.TEXT2' | translate}}"
                            ></lib-tile-text>
                            <lib-toggle
                                #wertpapiereEinsetzen
                                label1="{{'COMMON.TRUE' | translate}}"
                                label2="{{'COMMON.FALSE' | translate}}"
                                name="wertpapiereEinsetzen"
                                value1="AUFLOESUNG_ALS_VERWENDUNG"
                                value2="KEINE_VERWENDUNG"
                                [localValue]="contentService.getValue('wertpapiereVerwendung')"
                                (switchValue)="setSwitch($event, wertpapiereEinsetzen, 'Wertpapiere')"
                            ></lib-toggle>
                            <ng-container *ngIf="contentService.getValue('wertpapiereVerwendung') === 'AUFLOESUNG_ALS_VERWENDUNG'">
                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'FINANCIAL_ASSETS.TILES.TILE1.TEXT3' | translate}}"
                                ></lib-tile-text>
                                <lib-input
                                    #wertpapiereEinsetzenBetrag
                                    #inputs
                                    label="{{'COMMON.LABELS.SUMME' | translate}}"
                                    name="wertpapiereEinsetzenBetrag"
                                    [error]="false"
                                    errorText="{{errorWertpapiere | translate}}"
                                    placeholder="0,00"
                                    unit="€"
                                    [inputDisabled]="inputDisabled"
                                    (click)="testValid = true"
                                    [touched]="!!contentService.getValue('wertpapiereMaximalEinzusetzenderBetrag')"
                                    [value]="!!contentService.getValue('wertpapiereMaximalEinzusetzenderBetrag') ? contentService.getValue('wertpapiereMaximalEinzusetzenderBetrag') : ''"
                                    (change)="validateInput($event, wertpapiereEinsetzenBetrag, 'Wertpapiere')"
                                ></lib-input>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>

                <!-- TILE4 - IMMOBILIEN -->
                <div
                    class="da-container" 
                    (click)="getIndex('Immobilien')"
                    [ngClass]="{ 'mobile' : screen.isSmallest() }"
                    >
                    <lib-state-icon [state]="fakeTiles?.[3].state"></lib-state-icon>
                    <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                        <svg-icon class="da-tile-icon" [src]="fakeTiles?.[3].image"></svg-icon>
                        <lib-tile-text
                            class="da-tile-header"
                            size="20px"
                            [convertMobile]="true"
                            label="{{'FINANCIAL_ASSETS.TILES.TILE4.TITLE' | translate}}"
                            info="true"
                            (infoValue)="screen.openInfo(hausInfo)"
                        ></lib-tile-text>
                        <lib-toggle
                            #immobilienVorhanden
                            label1="{{'COMMON.TRUE' | translate}}"
                            label2="{{'COMMON.FALSE' | translate}}"
                            name="immobilienVorhanden"
                            value1="VORHANDENE_BESTEHENDE_IMMOBILIEN"
                            value2="KEINE_BESTEHENDEN_IMMOBILIEN"
                            [localValue]="contentService.getValue('immobilien')"
                            (switchValue)="setSwitch($event, immobilienVorhanden, 'Immobilien')"
                        ></lib-toggle>
                        <ng-container *ngIf="contentService.getValue('immobilien') === 'VORHANDENE_BESTEHENDE_IMMOBILIEN'">
                            <ng-container *ngIf="hasHouse">
                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'FINANCIAL_ASSETS.TILES.TILE4.TEXT1' | translate}}"
                                ></lib-tile-text>
                                <div class="da-asset-house-wrapper">
                                    <svg-icon class="da-asset-icon" src="assets/images/house.svg"></svg-icon>
                                    <div class="da-asset-info-wrapper">
                                        <p>{{asset?.type}}</p>
                                        <p>{{asset?.address?.plz + ' ' + asset?.address?.ort + ','}}</p>
                                        <p *ngIf="asset?.mitStrasse === 'true'">{{asset?.address?.strasse + ' ' + asset?.address?.hausnummer}}</p>
                                    </div>
                                </div>
                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'FINANCIAL_ASSETS.TILES.TILE4.TEXT2' | translate}}"
                                ></lib-tile-text>
                                <lib-toggle
                                    #mehrImmobilienVorhanden
                                    label1="{{'COMMON.TRUE' | translate}}"
                                    label2="{{'COMMON.FALSE' | translate}}"
                                    name="mehrImmobilienVorhanden"
                                    value1="MEHR_VORHANDENE_BESTEHENDE_IMMOBILIEN"
                                    value2="KEINE_WEITEREN_IMMOBILIEN"
                                    [localValue]="contentService.getValue('mehrImmobilien')"
                                    (switchValue)="setSwitch($event, mehrImmobilienVorhanden, 'Immobilien')"
                                ></lib-toggle>
                            </ng-container>
                            <ng-container *ngIf="!hasHouse || contentService.getValue('mehrImmobilien') === 'MEHR_VORHANDENE_BESTEHENDE_IMMOBILIEN'">
                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'FINANCIAL_ASSETS.TILES.TILE4.TEXT3' | translate}}"
                                ></lib-tile-text>
                                <lib-counter
                                    #immobilienEigengenutztCount
                                    [value]="contentService.getValue('immobilienEigengenutztAnzahl', true)"
                                    name="immobilienEigengenutztCount"
                                    [min]="0"
                                    [max]="5"
                                    (counterValue)="setCounter($event, immobilienEigengenutztCount, 'Immobilien')"
                                ></lib-counter>

                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'FINANCIAL_ASSETS.TILES.TILE4.TEXT4' | translate}}"
                                ></lib-tile-text>
                                <lib-counter
                                    #immobilienVermietetCount
                                    [value]="contentService.getValue('immobilienVermietetAnzahl', true)"
                                    name="immobilienVermietetCount"
                                    [min]="0"
                                    [max]="5"
                                    (counterValue)="setCounter($event, immobilienVermietetCount, 'Immobilien')"
                                ></lib-counter>

                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'FINANCIAL_ASSETS.TILES.TILE4.TEXT5' | translate}}"
                                ></lib-tile-text>
                                <lib-counter
                                    #immobilienTeilVermietetCount
                                    [value]="contentService.getValue('immobilienTeilVermietetAnzahl', true)"
                                    name="immobilienTeilVermietetCount"
                                    [min]="0"
                                    [max]="5"
                                    (counterValue)="setCounter($event, immobilienTeilVermietetCount, 'Immobilien')"
                                ></lib-counter>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>

                <!-- TILE5 - FAMILIENVERMÖGEN -->
                <div
                    class="da-container" 
                    (click)="getIndex('Familie')"
                    [ngClass]="{ 'mobile' : screen.isSmallest() }"
                    >
                    <lib-state-icon [state]="fakeTiles?.[4].state"></lib-state-icon>
                    <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                        <svg-icon class="da-tile-icon" [src]="fakeTiles?.[4].image"></svg-icon>
                        <lib-tile-text
                            class="da-tile-header"
                            size="20px"
                            [convertMobile]="true"
                            label="{{'FINANCIAL_ASSETS.TILES.TILE5.TITLE' | translate}}"
                            info="true"
                            (infoValue)="screen.openInfo(familyInfo)"
                        ></lib-tile-text>
                        <lib-toggle
                            #familienvermoegenVorhanden
                            label1="{{'COMMON.TRUE' | translate}}"
                            label2="{{'COMMON.FALSE' | translate}}"
                            name="familienvermoegenVorhanden"
                            value1="familienvermoegenVorhanden"
                            value2="keinFamilienvermoegen"
                            [localValue]="contentService.getValue('familienvermoegen')"
                            (switchValue)="setSwitch($event, familienvermoegenVorhanden, 'Familie')"
                        ></lib-toggle>
                        <ng-container *ngIf="contentService.getValue('familienvermoegen') === 'familienvermoegenVorhanden'">
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'FINANCIAL_ASSETS.TILES.TILE5.TEXT1' | translate}}"
                            ></lib-tile-text>
                            <lib-toggle
                                #familienSparvermoegenVorhanden
                                label1="{{'COMMON.TRUE' | translate}}"
                                label2="{{'COMMON.FALSE' | translate}}"
                                name="familienSparvermoegenVorhanden"
                                value1="familienSparvermoegenVorhanden"
                                value2="keinFamilienSparvermoegen"
                                [localValue]="contentService.getValue('familienSparvermoegen')"
                                (switchValue)="setSwitch($event, familienSparvermoegenVorhanden, 'Familie')"
                            ></lib-toggle>
                            <ng-container *ngIf="contentService.getValue('familienSparvermoegen') === 'familienSparvermoegenVorhanden'">
                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'FINANCIAL_ASSETS.TILES.TILE5.TEXT2' | translate}}"
                                ></lib-tile-text>
                                <lib-input
                                    #familienvermoegenBetrag
                                    #inputs
                                    label="{{'COMMON.LABELS.SUMME' | translate}}"
                                    name="familienvermoegenBetrag"
                                    [error]="false"
                                    errorText="{{'COMMON.ERRORS.NUMBER' | translate}}"
                                    placeholder="0,00"
                                    unit="€"
                                    [inputDisabled]="inputDisabled"
                                    (click)="testValid = true"
                                    [touched]="!!contentService.getValue('familienvermoegenBetrag')"
                                    [value]="!!contentService.getValue('familienvermoegenBetrag') ? contentService.getValue('familienvermoegenBetrag') : ''"
                                    (change)="validateInput($event, familienvermoegenBetrag, 'Familie')"
                                ></lib-input>
                            </ng-container>
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'FINANCIAL_ASSETS.TILES.TILE5.TEXT3' | translate}}"
                            ></lib-tile-text>
                            <lib-toggle
                                #familienImmobilieVorhanden
                                label1="{{'COMMON.TRUE' | translate}}"
                                label2="{{'COMMON.FALSE' | translate}}"
                                name="familienImmobilieVorhanden"
                                value1="familienImmobilieVorhanden"
                                value2="keineFamilienImmobilie"
                                [localValue]="contentService.getValue('familienImmobilie')"
                                (switchValue)="setSwitch($event, familienImmobilieVorhanden, 'Familie')"
                            ></lib-toggle>
                        </ng-container>
                    </div>
                </div>

                <!-- TILE6 - VERSICHERUNGEN -->
                <div
                    class="da-container" 
                    (click)="getIndex('Versicherungen')"
                    [ngClass]="{ 'mobile' : screen.isSmallest() }"
                    >
                    <lib-state-icon [state]="fakeTiles?.[5].state"></lib-state-icon>
                    <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                        <svg-icon class="da-tile-icon" [src]="fakeTiles?.[5].image"></svg-icon>
                        <lib-tile-text
                            class="da-tile-header"
                            size="20px"
                            [convertMobile]="true"
                            label="{{'FINANCIAL_ASSETS.TILES.TILE6.TITLE' | translate}}"
                            info="true"
                            (infoValue)="screen.openInfo(insuranceInfo)"
                        ></lib-tile-text>
                        <lib-toggle
                            #versicherungVorhanden
                            label1="{{'COMMON.TRUE' | translate}}"
                            label2="{{'COMMON.FALSE' | translate}}"
                            name="versicherungVorhanden"
                            value1="versicherungVorhanden"
                            value2="keineVersicherung"
                            [localValue]="contentService.getValue('versicherung')"
                            (switchValue)="setSwitch($event, versicherungVorhanden, 'Versicherungen')"
                        ></lib-toggle>
                        <ng-container *ngIf="contentService.getValue('versicherung') === 'versicherungVorhanden'">
                            <lib-tile-text
                                size="20px"
                                [convertMobile]="true"
                                label="{{'FINANCIAL_ASSETS.TILES.TILE6.TEXT1' | translate}}"
                            ></lib-tile-text>
                            <lib-counter
                                #versicherungCount
                                [value]="contentService.getValue('versicherungAnzahl', true)"
                                name="versicherungCount"
                                [min]="1"
                                [max]="3"
                                (counterValue)="setCounter($event, versicherungCount, 'Versicherungen')"
                            ></lib-counter>
                            <ng-container *ngIf="contentService.getValue('versicherungAnzahl')">
                                <div [ngStyle]="{'margin-top': '25px'}" *ngIf="versicherungTabs && versicherungTabs.length > 1">
                                    <lib-toggle-tristate 
                                        #versicherungTabsInput
                                        class="da-bauspar-tabs"
                                        name="versicherungTabs"
                                        [tabs]="versicherungTabs"
                                        (switchValue)="setTristate($event, versicherungTabsInput)"
                                    ></lib-toggle-tristate> 
                                </div>
                                <lib-tile-text
                                    *ngIf="versicherungTabs && versicherungTabs.length > 1"
                                    size="20px"
                                    [convertMobile]="true"
                                    [label]="'FINANCIAL_ASSETS.LABEL' | translate: {number: selectedTabVersicherung}"
                                ></lib-tile-text> 
                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'FINANCIAL_ASSETS.TILES.TILE6.TEXT2' | translate}}"
                                ></lib-tile-text>
                                <lib-input
                                    #versicherungBetrag
                                    #inputs
                                    label="{{'COMMON.LABELS.SUMME' | translate}}"
                                    name="versicherungBetrag{{selectedTabVersicherung}}"
                                    [error]="false"
                                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                                    placeholder="0,00"
                                    unit="€"
                                    [inputDisabled]="inputDisabled"
                                    (click)="testValid = true"
                                    [touched]="!!setLocalValue(selectedTabVersicherung, 'rueckkaufswert', 'versicherungsvertraege')"
                                    [value]="!!setLocalValue(selectedTabVersicherung, 'rueckkaufswert', 'versicherungsvertraege') ? setLocalValue(selectedTabVersicherung, 'rueckkaufswert', 'versicherungsvertraege') : ''"
                                    (change)="validateInput($event, versicherungBetrag, 'Versicherungen')"
                                ></lib-input>
                                <lib-tile-text
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'FINANCIAL_ASSETS.TILES.TILE6.TEXT3' | translate}}"
                                ></lib-tile-text>
                                <lib-toggle-vertical *ngIf="selectedTabVersicherung === 1"
                                    #versicherungEinsetzen1
                                    [options]="switches"
                                    [localValue]="selectedVerwendungVersicherung1"
                                    name="versicherungVerwendung1"
                                    (switchValue)="setSwitch($event, versicherungEinsetzen1, 'Versicherungen')"
                                ></lib-toggle-vertical>
                                <lib-toggle-vertical *ngIf="selectedTabVersicherung === 2"
                                    #versicherungEinsetzen2
                                    [options]="switches"
                                    [localValue]="selectedVerwendungVersicherung2"
                                    name="versicherungVerwendung2"
                                    (switchValue)="setSwitch($event, versicherungEinsetzen2, 'Versicherungen')"
                                ></lib-toggle-vertical>
                                <lib-toggle-vertical *ngIf="selectedTabVersicherung === 3"
                                    #versicherungEinsetzen3
                                    [options]="switches"
                                    [localValue]="selectedVerwendungVersicherung3"
                                    name="versicherungVerwendung3"
                                    (switchValue)="setSwitch($event, versicherungEinsetzen3, 'Versicherungen')"
                                ></lib-toggle-vertical>
                                <ng-container *ngIf="contentService.getNestedObject('versicherungsvertraege', 'verwendung', selectedTabVersicherung) === 'AUFLOESUNG_ALS_VERWENDUNG'">
                                    <lib-tile-text
                                        size="20px"
                                        [convertMobile]="true"
                                        label="{{'FINANCIAL_ASSETS.TILES.TILE6.TEXT4' | translate}}"
                                    ></lib-tile-text>
                                    <lib-input
                                        #versicherungEinsetzenBetrag
                                        #inputs
                                        label="{{'COMMON.LABELS.SUMME' | translate}}"
                                        name="versicherungEinsetzenBetrag{{selectedTabVersicherung}}"
                                        [error]="false"
                                        errorText="{{errorVersicherung | translate}}"
                                        placeholder="0,00"
                                        unit="€"
                                        [inputDisabled]="inputDisabled"
                                        (click)="testValid = true"
                                        [touched]="!!setLocalValue(selectedTabVersicherung, 'maximalEinzusetzenderBetrag', 'versicherungsvertraege')"
                                        [value]="!!setLocalValue(selectedTabVersicherung, 'maximalEinzusetzenderBetrag', 'versicherungsvertraege') ? setLocalValue(selectedTabVersicherung, 'maximalEinzusetzenderBetrag', 'versicherungsvertraege') : ''"
                                        (change)="validateInput($event, versicherungEinsetzenBetrag, 'Versicherungen')"
                                    ></lib-input>
                                </ng-container>
                            </ng-container>                                        
                        </ng-container>
                    </div>
                </div>


            </div>
        </div>
    </div>
</ng-container>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

<ng-template #assetsInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCIAL_ASSETS.INFO_HEADER' | translate}}" text="{{'FINANCIAL_ASSETS.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #sparenInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCIAL_ASSETS.TILES.TILE1.INFO_HEADER' | translate}}" text="{{'FINANCIAL_ASSETS.TILES.TILE1.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #bausparInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCIAL_ASSETS.TILES.TILE2.INFO_HEADER' | translate}}" text="{{'FINANCIAL_ASSETS.TILES.TILE2.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #wertInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCIAL_ASSETS.TILES.TILE3.INFO_HEADER' | translate}}" text="{{'FINANCIAL_ASSETS.TILES.TILE3.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #hausInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCIAL_ASSETS.TILES.TILE4.INFO_HEADER' | translate}}" text="{{'FINANCIAL_ASSETS.TILES.TILE4.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #familyInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCIAL_ASSETS.TILES.TILE5.INFO_HEADER' | translate}}" text="{{'FINANCIAL_ASSETS.TILES.TILE5.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #insuranceInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCIAL_ASSETS.TILES.TILE6.INFO_HEADER' | translate}}" text="{{'FINANCIAL_ASSETS.TILES.TILE6.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>

