<div class="da-content-wrapper" *ngIf="type === 'screen1'">
    <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
        <svg-icon class="da-lightbox-icon" src="assets/images/cartShoppingFast.svg"></svg-icon>
        <div class="da-product-wrapper">
            <div class="da-content-wrapper-row headline">
                <div class="da-content-wrapper-row-container">
                    <lib-tile-text
                    class="da-lightbox-content-headline"
                    label="{{'UEBERGABE_A.PRODUCT.SCREEN1.SUBTITLE' | translate}}"
                    [light]="true"
                    size="20px"
                ></lib-tile-text>
                <lib-tile-text
                    class="da-lightbox-content-headline"
                    label="{{'UEBERGABE_A.PRODUCT.SCREEN1.TITLE' | translate}}"
                    [light]="true"
                    size="38px"
                ></lib-tile-text>
                </div>
            </div>
            <div class="da-content-wrapper-row">
                <div class="da-content-wrapper-row-container">
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'UEBERGABE_A.PRODUCT.SCREEN1.SUBTEXT1' | translate}}"
                        [light]="true"
                        size="12px"
                    ></lib-tile-text> 
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'UEBERGABE_A.PRODUCT.SCREEN1.TEXT1' | translate}}"
                        [light]="true"
                        size="20px"
                    ></lib-tile-text> 
                </div>
                <div class="da-content-wrapper-row-container">
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'UEBERGABE_A.PRODUCT.SCREEN1.SUBTEXT2' | translate}}"
                        [light]="true"
                        size="12px"
                    ></lib-tile-text> 
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{gesamtgebuehr}}"
                        [light]="true"
                        size="20px"
                    ></lib-tile-text> 
                </div>
                <div class="da-content-wrapper-row-container file" (click)="docDownload()">
                    <svg-icon class="da-content-icon" src="assets/images/fileArrowDown.svg"></svg-icon>
                    <div>
                        <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'UEBERGABE_A.PRODUCT.SCREEN1.SUBTEXT3' | translate}}"
                        [light]="true"
                        size="12px"
                    ></lib-tile-text> 
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'UEBERGABE_A.PRODUCT.SCREEN1.TEXT3' | translate}}"
                        [light]="true"
                        size="20px"
                    ></lib-tile-text> 
                    </div>

                </div>
            </div>
        </div>
        <div class="da-product-image">
            <img [src]="produktbild" />
        </div>
    </div>
</div>



<div class="da-content-wrapper" *ngIf="type === 'screen2'">
    <!-- <svg-icon class="da-lightbox-icon" src="assets/images/cartShoppingFast.svg"></svg-icon> -->
    <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
        <lib-tile-text
            class="da-lightbox-content-headline"
            label="{{'UEBERGABE_A.PRODUCT.SCREEN2.TITLE' | translate}}"
            [light]="true"
            size="20px"
        ></lib-tile-text>
    </div>
    <div class="da-content-wrapper-row product" [ngClass]="{ 'mobile-product' : screen.isMobile() }">
        <div class="da-product-wrapper">
            <div class="da-content-wrapper-row">
                <div>
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'UEBERGABE_A.PRODUCT.SCREEN2.COLUMN1' | translate}}"
                        [light]="true"
                        size="16px"
                    ></lib-tile-text>
                </div>
                <div>
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'UEBERGABE_A.PRODUCT.SCREEN2.COLUMN2' | translate}}"
                        [light]="true"
                        size="16px"
                    ></lib-tile-text>
                </div>
                <div>
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'UEBERGABE_A.PRODUCT.SCREEN2.COLUMN3' | translate}}"
                        [light]="true"
                        size="16px"
                    ></lib-tile-text>
                </div>
            </div>
            <div class="da-content-wrapper-row">
                <div>
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'UEBERGABE_A.PRODUCT.SCREEN2.NAME' | translate}}"
                        [light]="true"
                        size="12px"
                    ></lib-tile-text>
                </div>
                <div>
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="1x"
                        [light]="true"
                        size="12px"
                    ></lib-tile-text>
                </div>
                <div>
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{nettogebuehr}} €"
                        [light]="true"
                        size="12px"
                    ></lib-tile-text>
                </div>
            </div>
            <div class="da-content-wrapper-row">
                <div>
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'UEBERGABE_A.PRODUCT.SCREEN2.TAX' | translate: {prozent: mehrwertsteuerprozent} }}"
                        [light]="true"
                        size="12px"
                    ></lib-tile-text>
                </div>
                <div></div>
                <div>
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{ mehrwertsteuerbetrag }} €"
                        [light]="true"
                        size="12px"
                    ></lib-tile-text>
                </div>
            </div>
            <div class="da-content-wrapper-row">
                <div>
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'UEBERGABE_A.PRODUCT.SCREEN2.SHIPPING' | translate}}"
                        [light]="true"
                        size="12px"
                    ></lib-tile-text>
                </div>
                <div></div>
                <div>
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="0,00 €"
                        [light]="true"
                        size="12px"
                    ></lib-tile-text>
                </div>
            </div>
            <div class="da-content-wrapper-row price">
                <div>
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'UEBERGABE_A.PRODUCT.SCREEN2.PRICE' | translate}}"
                        size="20px"
                    ></lib-tile-text>
                </div>
                <div></div>
                <div>
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{gesamtgebuehr}}"
                        size="20px"
                    ></lib-tile-text>
                </div>
            </div>
            <div class="da-content-wrapper-row">
                <div>
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'UEBERGABE_A.PRODUCT.SCREEN2.DELIVERY' | translate}}"
                        [light]="true"
                        size="12px"
                    ></lib-tile-text>
                </div>
                <div></div>
                <div>
                    <lib-tile-text
                        class="da-lightbox-content-headline"
                        label="{{'UEBERGABE_A.PRODUCT.SCREEN2.DELIVERY_INFO' | translate: {delivery: zustellungszeit} }}"
                        [light]="true"
                        size="12px"
                    ></lib-tile-text>
                </div>
            </div>
        </div>
        <div class="da-product-image">
            <img [src]="produktbild" />
        </div>
    </div>
</div>
