import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { dropdownsContent } from 'src/assets/content/dropdowns';

@Component({
  selector: 'app-verwendung',
  templateUrl: './verwendung.component.html',
  styleUrls: ['./verwendung.component.scss']
})
export class VerwendungComponent implements OnInit {
  dropdown = dropdownsContent['VERWENDUNG'].items;
  dropdownSelectedItem = { 'value': '', 'viewValue': 'COMMON.SELECT' };
  
  @Input() num!: number;
  @Output() public done: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private daService: DataService,
    private dataQuery: DataQuery,
  ) { }

  ngOnInit(): void {
  }

  getSelectedValue(element: any) {
    const type = this.contentService.getNestedValue('darlehenBausteine', 'verwendung', this.num);

    if(!!type ) {
      element = this.contentService.getSelectedItem(type, element);
    }

    return element.selectedItem
  }

  setSelectedValue(e: string) {
    const darlehen = this.contentService.updateNestedObject('darlehenBausteine', 'verwendung', e, this.num);
    this.daService.update(1, { darlehenBausteine: darlehen });
    this.done.emit(this.num);
  }

}
