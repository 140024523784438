<div class="da-bausteine-tile" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'isSmallest' : screen.isSmallest() }">
    <lib-header-text
    label="{{'FINANCE_ASSEMBLY.BAUSTEINE.AUSZAHLUNGSDATUM.TITLE' | translate}}"
    info="true"
    (infoValue)="screen.openInfo(datumInfo)"
    size="20px"
    [convertMobile]="false"
    ></lib-header-text>
    <lib-input-date
        #datum
        class="da-tile-field"
        label="{{'COMMON.LABELS.DATE' | translate}}"
        name="datum{{num}}"
        [minDate]="today"
        [value]="contentService.getNestedObject('darlehenBausteine', 'auszahlungszeitpunkt', num)"
        [touched]="false"
        [error]="false"
        errorText="{{errorDate | translate}}"
        placeholder="tt.mm.jjjj"
        (dateValue)="validateDate($event, datum)"
        (dateInputValue)="validateDate($event, datum, true)"
    ></lib-input-date>
</div>

<ng-template #datumInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_ASSEMBLY.BAUSTEINE.AUSZAHLUNGSDATUM.INFO_HEADER' | translate}}" text="{{'FINANCE_ASSEMBLY.BAUSTEINE.AUSZAHLUNGSDATUM.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
