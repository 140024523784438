<div class="da-loader-bg">
    <div class="da-loader-wrapper">
        <div class="da-loader-animation">
            <svg-icon class="da-bank-icon bank1" src="assets/images/bank.svg"></svg-icon>
            <svg-icon class="da-bank-icon bank2" src="assets/images/bank.svg"></svg-icon>
            <svg-icon class="da-bank-icon bank3" src="assets/images/bank.svg"></svg-icon>
            <svg-icon class="da-lupe-icon" src="assets/images/magnifyingGlass.svg"></svg-icon>
            <svg-icon class="da-bank-icon bank4" src="assets/images/bank.svg"></svg-icon>
            <svg-icon class="da-bank-icon bank5" src="assets/images/bank.svg"></svg-icon>
            <svg-icon class="da-bank-icon bank6" src="assets/images/bank.svg"></svg-icon>
        </div>
        <lib-tile-text
            class="da-button-text"
            size="20px"
            [convertMobile]="false"
            [light]="true"
            label="{{text | translate}}"
        ></lib-tile-text> 
        <div class="da-loader-button">
            <lib-button 
                name="loader button"
                value="{{button | translate}}" 
                (buttonValue)="abort.emit(true)"
                iconRight="assets/images/chevronRight.svg"
                width="250"
                [light]="true"
                [disabled]="false"
            ></lib-button>
        </div>
    </div>
</div>

