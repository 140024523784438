import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  times!: string;
  person!: string;
  tel!: string;
  email!: string;
  mail!: string;
  showButtons: boolean = false;


  @Input() modal!: NgbModalRef;
  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private configurationQuery: ConfigurationQuery,
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
    this.setConfigVariables();
  }

  setConfigVariables() {
    this.configurationQuery.configuration.subscribe({
      next: (store) => {
        this.times = store.kontaktoeffnungszeitenkunden;
        this.person = store.kontaktberatername;
        this.tel = store.kontakttelefonnummerkunden;
        this.email = store.kontaktemailkunden;
        this.mail = store.kontaktanschriftberatungkunden;
      },
      error: (err) => {
        console.warn('da-info: no configuration found.', err);
      }
    });
    setTimeout(() => {this.showButtons = true}, 1100);
  }

  getCalendly(element: any) {
    this.screen.openInfo(element);
    this.dismiss();
  }

  dismiss() {
    document.body.style.overflowY = 'auto';
    this.modal.dismiss();
  }

}
