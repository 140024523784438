import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { switchesContent } from 'src/assets/content/switches';

@Component({
  selector: 'app-zinsbindung',
  templateUrl: './zinsbindung.component.html',
  styleUrls: ['./zinsbindung.component.scss']
})
export class ZinsbindungComponent implements OnInit, OnChanges {
  switches = switchesContent['ZINSBINDUNG'].items;
  @Input() num!: number;
  @Input() kfw!: boolean;
  @Output() public done: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('fixed') fixed!: any;
  @ViewChild('variable') variable!: any;
  
  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private daService: DataService,
    private dataQuery: DataQuery,
  ) {
    if(this.kfw) {
      this.switches = switchesContent['ZINSBINDUNG_KFW'].items;
    }
   }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes) {
      if(this.kfw) {
        this.switches = switchesContent['ZINSBINDUNG_KFW'].items;
      } else {
        this.switches = switchesContent['ZINSBINDUNG'].items;
      }
    }
  }

  setSwitch(e: string) {
    let darlehen;
    if(e !== 'other') {
      darlehen = this.contentService.updateNestedObject('darlehenBausteine', 'zinsbindungInJahren', e, this.num, 'zinsbindungInJahrenVariable', 0);
    } else {
      darlehen = this.contentService.updateNestedObject('darlehenBausteine', 'zinsbindungInJahren', e, this.num);
    }
    this.daService.update(1, { darlehenBausteine: darlehen });
    this.done.emit(this.num);
  }

  validateInput(e: any, element: any) {
    setTimeout(() => {
      const value = e[0];
      element.touched = true;
      element.placeholder = '';
      let isValid = this.contentService.validatePattern(value, element.validationType);
      element.error = !isValid;

      let darlehen;
      if (!element.error) {
        darlehen = this.contentService.updateNestedObject('darlehenBausteine', 'zinsbindungInJahrenVariable', value, this.num, 'state', 'warning');
      } else {
        darlehen = this.contentService.updateNestedObject('darlehenBausteine', 'state', 'error', this.num);
      }
      this.daService.update(1, { darlehenBausteine: darlehen });
      this.done.emit(this.num);

    },100)
  }

}
