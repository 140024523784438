<div class="da-bausteine-tile" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'isSmallest' : screen.isSmallest() }">
    <lib-header-text
    label="{{'FINANCE_ASSEMBLY.BAUSTEINE.SONDERTILGUNG.TITLE' | translate}}"
    info="true"
    (infoValue)="screen.openInfo(sonderInfo)"
    size="20px"
    [convertMobile]="false"
    ></lib-header-text>
    <lib-toggle-vertical
        #fixedS
        [options]="switches"
        [localValue]="contentService.getNestedObject('darlehenBausteine', 'sondertilgungJaehrlich', num)"
        name="fixedS{{num}}"
        (switchValue)="setSwitch($event)"
    ></lib-toggle-vertical>
    <lib-input
    *ngIf="contentService.getNestedObject('darlehenBausteine', 'sondertilgungJaehrlich', this.num) === 'other'"
        #variableS
        class="da-tile-field"
        label="{{'FINANCE_ASSEMBLY.BAUSTEINE.SONDERTILGUNG.LABEL' | translate}}"
        name="variableS{{num}}"
        [error]="false"
        errorText="{{'COMMON.ERRORS.NUMBER' | translate}}"
        placeholder="0"
        unit="%"
        [value]="contentService.getNestedObject('darlehenBausteine', 'sondertilgungJaehrlichVariable', num) ? contentService.getNestedObject('darlehenBausteine', 'sondertilgungJaehrlichVariable', num) : '0'"
        [touched]="contentService.getNestedObject('darlehenBausteine', 'sondertilgungJaehrlichVariable', num) && contentService.getNestedObject('darlehenBausteine', 'sondertilgungJaehrlichVariable', num) > 0"                               
        (inputValue)="validateInput($event, variable)"
    ></lib-input>
</div>

<ng-template #sonderInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_ASSEMBLY.BAUSTEINE.SONDERTILGUNG.INFO_HEADER' | translate}}" text="{{'FINANCE_ASSEMBLY.BAUSTEINE.SONDERTILGUNG.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
