import { Component, EventEmitter, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-additional-income-partner',
  templateUrl: './additional-income-partner.component.html',
  styleUrls: ['./additional-income-partner.component.scss']
})
export class AdditionalIncomePartnerComponent implements OnInit {
  public slideName: string = 'additional-income-partner';
  next: string = 'children';
  localValue!: string;
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  tiles = tilesContent['ADDITIONAL_INCOME_PARTNER'].containers;
  today: any = new Date();
  beforeDate: any = this.contentService.subtractYears(new Date(), 40);
  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('content') content!: TemplateRef<any>;
  @ViewChildren('inputs') inputsList!: QueryList<any>;

  constructor(
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public daNextSlideService: DaNextSlideService,
    public contentService: ContentService
  ) {}

  ngOnInit(): void {
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.setDisabled('init');
    });
  }

  setSwitch(e: string, element: any) {
    this.inputDisabled = false;
    this.testValid = true;
    setTimeout(() => {
      switch (element.name) {
        case 'job': {
          this.daService.update(1, { nebentaetigkeitPartner: e });
        }
          break;
        case 'rente': {
          this.daService.update(1, { rentePartner: e });
        }
          break;
        case 'provision': {
          this.daService.update(1, { provisionPartner: e });
        }
          break;
      }
      setTimeout(() => { this.setDisabled() }, 200);
    }, 50)
  }

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'next') {
        if (this.allState !== 'success') {
          this.showState = true;
          this.setDisabled();
        } else {
          this.setDisabled('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 50)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  validateInput(e: any, element: any, parent: string) {
    setTimeout(() => {
      let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
      element.touched = value ? true : false;
      element.placeholder = '';
      const isValid = this.contentService.validatePattern(value, element.validationType);
      const isValidAmount = this.contentService.minMax(value, 5, 999999);
      element.error = isValid && isValidAmount === true ? false : true;
      if (!element.error) {
        this.storeInputValue(element, value)
      } else {
        this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, parent, 'error');
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.setAllState('error', 'ERROR');
        this.stateChange.emit(true);
        setTimeout(() => { this.setDisabled() });
      }
    }, 50)
  }

  validateDate(e: any, element: any) {
    element.error = e !== null ? false : true;
    const inputDate = e !== null ? e.toDate() : null;
    let baseTooOld: boolean = false
    let baseTooYoung: boolean = false;
    if (!e) {
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, 'Nebenjob', 'error');
      this.contentService.setSlideIndexState(this.slideName, false, 'error');
      this.setAllState('error', 'ERROR');
      this.stateChange.emit(true);
      setTimeout(() => { this.setDisabled() });
      return
    }
    setTimeout(() => {
      element.touched = true;
      baseTooYoung = inputDate > new Date();
      baseTooOld = inputDate < this.contentService.subtractYears(new Date(), 40);
      element.error = baseTooYoung || baseTooOld ? true : false;
      if (!element.error) {
        this.storeInputValue(element, inputDate);
      } else {
        this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, 'Nebenjob', 'error');
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.setAllState('error', 'ERROR');
        this.stateChange.emit(true);
        setTimeout(() => { this.setDisabled() });
      }
    }, 50)
  }

  storeInputValue(element: any, e: string) {
    switch (element.name) {
      case 'nebentaetigkeitGeld': this.daService.update(1, { einkuenfteAusNebentaetigkeitPartner: e });
        break;
      case 'nebentaetigkeitDate': this.daService.update(1, { nebentaetigkeitDatePartner: e });
        break;
      case 'renteGeld': this.daService.update(1, { summeUnbefristeteZusatzrentenMonatlichPartner: e });
        break;
      case 'provisionGeld': this.daService.update(1, { summeSonstigeEinnahmenMonatlichPartner: e });
        break;
    }
    setTimeout(() => { this.setDisabled() });
  }

  setDisabled(type: string = 'default') {
    const inputFields = this.inputsList.toArray();
    this.disabled = true;
    const status = this.dataQuery.getEntity(1);
    let state = 'warning';
    let jobValid = false;
    let renteValid = false;
    let provisionValid = false;

    if (!!status?.['nebentaetigkeitPartner']) {
      if(status?.['nebentaetigkeitPartner'] === 'NebentaetigkeitBesteht') {
        if (!!status?.['nebentaetigkeitDatePartner'] && !!status?.['einkuenfteAusNebentaetigkeitPartner']) {
          jobValid = true;
        }
      } else {
        jobValid = true;
      }
    } 

    if (!!status?.['rentePartner']) {
      if(status?.['rentePartner'] === 'RenteBesteht') {
        if (!!status?.['summeUnbefristeteZusatzrentenMonatlichPartner']) {
          renteValid = true;
        }
      } else {
        renteValid = true
      }     
    }

    if (!!status?.['provisionPartner']) {
      if(status?.['provisionPartner'] === 'ProvisionBesteht') {
        if (!!status?.['summeSonstigeEinnahmenMonatlichPartner']) {
          provisionValid = true;
        }
      } else {
        provisionValid = true
      }     
    }

    state = jobValid && renteValid && provisionValid ? 'success' : 'warning';
    this.contentService.setSlideIndexState(this.slideName, false, state);
    this.setAllState(state, jobValid && renteValid && provisionValid ? 'COMPLETE' : 'INCOMPLETE');
    this.stateChange.emit(true);
    this.disabled = jobValid && renteValid && provisionValid ? false : true;

    if(inputFields?.length > 0) {
      inputFields.map((item: any) => {
        if(item.error) {
          this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, status?.['additionalIncome'], 'error');
          this.contentService.setSlideIndexState(this.slideName, false, 'error');
          this.setAllState('error', 'ERROR');
          this.stateChange.emit(true);
        }
      })
    }

    if(jobValid && renteValid && provisionValid) {
      this.disabled = false;
      if (type === 'test') {
        this.inputDisabled = true;
        this.testValid = false;
      }
    } else {
      this.disabled = true;
      this.testValid = true;
      this.inputDisabled = false;
    }

    if(jobValid) {
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, 'Nebenjob', 'success');
    } else {
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, 'Nebenjob', 'warning');
    }
    if(renteValid) {
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, 'Rente', 'success');
    } else {
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, 'Rente', 'warning');
    }
    if(provisionValid) {
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, 'Provision', 'success');
    } else {
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, 'Provision', 'warning');
    }

    if(this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }
}
