<lib-tile-text
        class="da-info-content-headline"
        label="{{ 'LOGIN_LIGHT.SAVE_PROGRESS_INFO' | translate}}"
        [light]="false"
        size="20px"
></lib-tile-text>
<div>

<div class="da-info-list-container">
    <div class="da-info-list">
        <div *ngFor="let element of infoList" class="da-info-list-element">
            <div class="da-info-list-element-icon-container" [ngClass]="{'xs-device': isMobile()}">
                <div [class]="'da-info-list-element-icon icon-' + element.icon">
                    <svg-icon [src]="'/assets/images/' + element.icon + '.svg'"></svg-icon>
                </div>
            </div>
            <div class="da-info-list-element-text">
                {{ 'LOGIN_LIGHT.' + element.text | translate }}
            </div>
        </div>
    </div>
</div>
