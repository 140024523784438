import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { switchesContent } from 'src/assets/content/switches';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-object-category',
  templateUrl: './object-category.component.html'
})
export class ObjectCategoryComponent implements OnInit {
  public slideName: string = 'object-category';
  next: string = 'address';
  localValue!: string;
  disabled: boolean = true;
  tiles = tilesContent['OBJECT_CATEGORY'].items;
  switches = switchesContent['PROPERTY_CATEGORY'].items;
  sizeError: string = 'COMMON.ERRORS.NUMBER';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('content') content!: TemplateRef<any>;

  constructor(
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public daNextSlideService: DaNextSlideService,
    public contentService: ContentService
  ) {}

  ngOnInit() {
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.setStatus();
    });
  }

  setStatus() {
    const type = this.dataQuery.getEntity(1)?.['objectType'];

    if (!!type) {
      this.localValue = type;
      if(type === 'BAUGRUNDSTUECK') {
        this.setDisabled();
      } else {
        this.tiles = this.contentService.setTileStatusChecked(this.tiles, type, 'success', true);
        this.disabled = false;
      }
      this.daNextSlideService.update(1, { disabled: this.disabled});
      this.setNext(type);
    }
  }

  setValue(e: string) {
      this.localValue = e;
      this.tiles = this.contentService.setTileChecked(this.tiles, e);
      this.tiles = this.contentService.setTileStatus(this.tiles, e, 'success', 'warning');
      this.daService.update(1, { objectType: e });
      this.setNext(e);

      if(e === 'Gewerbe') {
        this.daService.update(1, { objectUnitType: 'Gewerbeflaeche' });
      } else {
        this.daService.update(1, { objectUnitType: '' });
      }

      if (e === "BAUGRUNDSTUECK") {
        this.tiles = this.contentService.setTileStatusChecked(this.tiles, 'BAUGRUNDSTUECK', 'warning', true);
        this.contentService.setSlideIndexState(this.slideName, false, 'warning');
        this.setObjectType(e);
        return
      } else {
        this.setObjectType(e, true);
        this.contentService.setSlideIndexState(this.slideName, false, e === 'Gewerbe' ? 'warning' : 'success');
      }
  }

  setObjectType(e: string, removeGrundstueck?: boolean) {
    if (removeGrundstueck) { // is NOT Grundstueck
      this.disabled = false;
      this.daService.resetValues({ objectTypeGrundstueck: '' }); 
      this.contentService.setNav('next', 'object-category', this.slideTo, this.next);
    } else { // IS Grundstueck
      this.disabled = true;
      this.tiles = this.contentService.setTilesContent(this.tiles, e, this.content);
    }
    this.daNextSlideService.update(1, { disabled: this.disabled});
  }

  setObjectTypeGrundstueck(e: string) {
    this.daService.update(1, { objectTypeGrundstueck: e });
    setTimeout(() => { this.setDisabled()});
  }

  validateInputValue(e: any, element: any) {

    setTimeout(() => {
      let value = e.target ? e.target.value : e;
      value = element.validationType !== 'currency' ? value : value[0];
      element.touched = !!value ? true : false;
      element.placeholder = '';
      const isValid = this.contentService.validatePattern(value, element.validationType);
      const isValidAmount: any = this.contentService.minMax(value, 25, 9999);
        this.sizeError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';

      element.error = isValid && isValidAmount === true ? false : true;

      if(!element.error) {
        this.daService.update(1, { objectGesamtflaeche: element.value });
        setTimeout(() => { this.setDisabled()});
      } else {
        this.daNextSlideService.update(1, { disabled: true});
        this.tiles = this.contentService.setTileStatusChecked(this.tiles, 'BAUGRUNDSTUECK', 'error', true);
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.stateChange.emit(true);
      }
    }, 100);
  }

  setDisabled() {
    const status = this.dataQuery.getEntity(1);

    if(!!status?.['objectTypeGrundstueck'] && !!status?.['objectGesamtflaeche']) {
      this.daNextSlideService.update(1, { disabled: false});
      this.tiles = this.contentService.setTileStatusChecked(this.tiles, 'BAUGRUNDSTUECK', 'success', true);
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
    } else {
      this.daNextSlideService.update(1, { disabled: true});
      this.tiles = this.contentService.setTileStatusChecked(this.tiles, 'BAUGRUNDSTUECK', 'warning', true);
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
    }
    this.stateChange.emit(true);
  }

  setNext(value: string) {
    this.next = value === 'Haus' ? 'house-category' : 'address';
    this.contentService.setSlideIndex(this.next, false);

    if(value === 'BAUGRUNDSTUECK') {
      this.contentService.setSlideIndex('modernization', true);
      this.contentService.setSlideIndex('condition-estate', true);
      this.contentService.setSlideIndex('bauweise', true);
      this.contentService.setSlideIndex('energy', true);
    } 
  }
}
