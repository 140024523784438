import { Component, EventEmitter, HostListener, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-condition-estate',
  templateUrl: './condition-estate.component.html',
  styleUrls: ['./condition-estate.component.scss']
})
export class ConditionEstateComponent implements OnInit {
  public slideName: string = 'condition-estate';
  next: string = 'modernization';
  disabled: boolean = true;
  tiles = tilesContent['CONDITION_ESTATE'].items;
  zustand: boolean = false;
  equipment: boolean = false;

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('conditionInfo') conditionInfo!: TemplateRef<any>;
  @ViewChild('equipmentInfo') equipmentInfo!: TemplateRef<any>;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit() {
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.getValues();
    });
  }

  getValues() {
    const zustand = this.dataQuery.getEntity(1)?.['objectZustand'];
    const equipment = this.dataQuery.getEntity(1)?.['objectAusstattung'];

    if (!!zustand) {
      let rating = 0;
      switch (zustand) {
        case 'SCHLECHT': rating = 1; break;
        case 'MAESSIG': rating = 2; break;
        case 'MITTEL': rating = 3; break;
        case 'GUT': rating = 4; break;
        case 'SEHR_GUT': rating = 5; break;
      }
      this.tiles.map(tile => {
        if (tile.type === 'condition') {
          tile.rating = rating;
          tile.success = true;
        }
      });
      this.zustand = true;
    }
    if (!!equipment) {
      let rating = 0;
      switch (equipment) {
        case 'EINFACH': rating = 1; break;
        case 'MITTEL': rating = 2; break;
        case 'GEHOBEN': rating = 3; break;
        case 'STARK_GEHOBEN': rating = 4; break;
      }
      this.tiles.map(tile => {
        if (tile.type === 'equipment') {
          tile.rating = rating;
          tile.success = true;
        }
      });
      this.equipment = true;
    }
    if (this.zustand && this.equipment) {
      this.disabled = false;
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.stateChange.emit(true);
      this.daNextSlideService.update(1, { next: this.next, timestamp: new Date });
    }
    this.daNextSlideService.update(1, { disabled: this.disabled });
    this.setNext();
  }

  getInfo(e: any) {
    if (e === 'conditionInfo') {
      this.screen.openInfo(this.conditionInfo);
    } else {
      this.screen.openInfo(this.equipmentInfo);
    }
  }

  setNext() {
    const status = this.dataQuery.getEntity(1)?.['projectPlan'];

    if (!!status) {
      if (status === 'KAUF_NEUBAU_VOM_BAUTRAEGER' || status === 'NEUBAU') {
        this.contentService.setSlideIndex('modernization', true);
        this.next = 'bauweise';
      }
    }
  }

  getRating(e: any) {
    if (e.name === 'conditionEstate') {
      let rating = '';
      switch (e.rating) {
        case 1: rating = 'SCHLECHT'; break;
        case 2: rating = 'MAESSIG'; break;
        case 3: rating = 'MITTEL'; break;
        case 4: rating = 'GUT'; break;
        case 5: rating = 'SEHR_GUT'; break;
      }
      this.daService.update(1, { objectZustand: rating });
      this.zustand = true;
    } else {
      let rating = '';
      switch (e.rating) {
        case 1: rating = 'EINFACH'; break;
        case 2: rating = 'MITTEL'; break;
        case 3: rating = 'GEHOBEN'; break;
        case 4: rating = 'STARK_GEHOBEN'; break;
      }
      this.daService.update(1, { objectAusstattung: rating });
      this.equipment = true;
    };
    setTimeout(() => { this.setDisabled(); });
  }

  setDisabled() {
    this.disabled = true;

    if (this.zustand && this.equipment) {
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.disabled = false;
    } else {
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
    }
    this.stateChange.emit(true);
    this.daNextSlideService.update(1, { disabled: this.disabled });
  }

}
