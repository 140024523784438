export interface contentInterface {
    [key: string]: {
        items: any[],
        itemsMobile?: any[],
        containers?: any[]
    };

}

export const tilesContent:contentInterface = {
    "ESTATE_STATUS": {
        "items": [
            {
                "label": "TILES.ESTATE_STATUS.TILE1",
                "name": "estateStatus",
                "value": "NOT_CONCRETE",
                "image": "assets/images/houseXmark.svg",
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.ESTATE_STATUS.TILE2",
                "name": "estateStatus",
                "value": "IN_PROSPECT",
                "image": "assets/images/houseFlag.svg",
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.ESTATE_STATUS.TILE3",
                "name": "estateStatus",
                "value": "FOUND",
                "image": "assets/images/houseCheckmark.svg",
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.ESTATE_STATUS.TILE4",
                "name": "estateStatus",
                "value": "NEUBAU",
                "image": "assets/images/trowelBricks.svg",
                "state": "warning",
                "checked": false
            },
        ]
    },
    "ESTATE_STATUS_B": {
        "items": [
            {
                "label": "TILES.ESTATE_STATUS.TILE1",
                "name": "estateStatus",
                "value": "NOT_CONCRETE",
                "image": "assets/images/houseXmark.svg",
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.ESTATE_STATUS.TILE2",
                "name": "estateStatus",
                "value": "IN_PROSPECT",
                "image": "assets/images/houseFlag.svg",
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.ESTATE_STATUS.TILE3",
                "name": "estateStatus",
                "value": "FOUND",
                "image": "assets/images/houseCheckmark.svg",
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.ESTATE_STATUS.TILE4",
                "name": "estateStatus",
                "value": "NEUBAU",
                "image": "assets/images/trowelBricks.svg",
                "state": "warning",
                "checked": false
            },
            {
                "label": "TILES.ESTATE_STATUS.TILE5",
                "name": "estateStatus",
                "value": "OWNER",
                "image": "assets/images/houseUser.svg",
                "state": "warning",
                "checked": false
            }
        ]
    },
    "PROJECT_PLAN": {
        "items": [
            {
                "label": "TILES.PROJECT_PLAN.TILE1",
                "name": "project",
                "value": "KAUF",
                "image": 'assets/images/houseTree.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.PROJECT_PLAN.TILE2",
                "name": "project",
                "value": "KAUF_NEUBAU_VOM_BAUTRAEGER",
                "image": 'assets/images/userRoof.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.PROJECT_PLAN.TILE3",
                "name": "project",
                "image": 'assets/images/locationMinus.svg',
                "value": "OTHER",
                "state": "success",
                "checked": false
            }
        ]
    },
    "PROJECT_PLAN_FORWARD": {
        "items": [
            {
                "label": "TILES.PROJECT_PLAN_FORWARD.TILE1",
                "name": "project",
                "value": "ANSCHLUSSFINANZIERUNG",
                "image": 'assets/images/buildingCircleArrowRight.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.PROJECT_PLAN_FORWARD.TILE2",
                "name": "project",
                "value": "MODERNISIERUNG_UMBAU_ANBAU",
                "image": 'assets/images/wandMagicSparkles.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.PROJECT_PLAN_FORWARD.TILE3",
                "name": "project",
                "value": "KAPITALBESCHAFFUNG",
                "image": 'assets/images/coins.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.PROJECT_PLAN_FORWARD.TILE4",
                "name": "project",
                "value": "MIXED",
                "image": 'assets/images/signsPost.svg',
                "state": "success",
                "checked": false
            }
        ]
    },
    "STATUS_SPEC": {
        "items": [
            {
                "label": "TILES.STATUS_SPEC.TILE1",
                "name": "statusSpec",
                "value": "Vorbereitung",
                "image": 'assets/images/signalBarsWeak.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.STATUS_SPEC.TILE2",
                "name": "statusSpec",
                "value": "Besichtigung",
                "image": 'assets/images/signalBarsFair.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.STATUS_SPEC.TILE3",
                "name": "statusSpec",
                "value": "Verhandlung",
                "image": 'assets/images/signalBarsGood.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.STATUS_SPEC.TILE4",
                "name": "statusSpec",
                "value": "Vor Notartermin",
                "image": 'assets/images/signalBarsStrong.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.STATUS_SPEC.TILE5",
                "name": "statusSpec",
                "value": "Kaufvertrag",
                "image": 'assets/images/signalBars.svg',
                "state": "success",
                "checked": false
            }
        ]
    },
    "RESIDENCE": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "residence",
                "value": "Deutschland",
                "image": 'assets/images/locationDot.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "COMMON.FALSE",
                "name": "residence",
                "value": "Ausland",
                "image": 'assets/images/locationDotSlash.svg',
                "state": "success",
                "checked": false
            }
        ]
    },
    "SCHUFA": {
        "items": [
            {
                "label": "TILES.SCHUFA.TILE1",
                "name": "schufa",
                "value": "schufaOk",
                "image": 'assets/images/gaugeMax.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.SCHUFA.TILE2",
                "name": "schufa",
                "value": "schufaNotOk",
                "image": 'assets/images/gaugeMin.svg',
                "state": "success",
                "checked": false
            }
        ]
    },
    "PARTICIPANTS": {
        "items": [
            {
                "label": "TILES.PARTICIPANTS.TILE1",
                "name": "participants",
                "value": "allein",
                "image": 'assets/images/personHalfDress.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.PARTICIPANTS.TILE2",
                "name": "participants",
                "value": "mitPartner",
                "image": 'assets/images/people.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.PARTICIPANTS.TILE3",
                "name": "participants",
                "value": "mitEhePartner",
                "image": 'assets/images/marriage.svg',
                "state": "success",
                "checked": false
            }
        ]
    },
    "OBJECT_CATEGORY": {
        "items": [
            {
                "label": "TILES.OBJECT_CATEGORY.TILE1",
                "name": "object-category",
                "value": "EIGENTUMSWOHNUNG",
                "image": 'assets/images/apartment.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.OBJECT_CATEGORY.TILE2",
                "name": "object-category",
                "value": "BAUGRUNDSTUECK",
                "image": 'assets/images/trees.svg',
                "state": "warning",
                "checked": false,
                "content": null
            },
            {
                "label": "TILES.OBJECT_CATEGORY.TILE3",
                "name": "object-category",
                "value": "Haus",
                "image": 'assets/images/houseBuilding.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.OBJECT_CATEGORY.TILE4",
                "name": "object-category",
                "value": "Gewerbe",
                "image": 'assets/images/industryBuilding.svg',
                "state": "success",
                "checked": false
            }
        ]
    },
    "HOUSE_CATEGORY": {
        "items": [
            {
                "label": "TILES.HOUSE_CATEGORY.TILE1",
                "name": "house-category",
                "value": "EINFAMILIENHAUS",
                "image": 'assets/images/house.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.HOUSE_CATEGORY.TILE2",
                "name": "house-category",
                "value": "DOPPELHAUSHAELFTE",
                "image": 'assets/images/duplexHouse.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.HOUSE_CATEGORY.TILE3",
                "name": "house-category",
                "value": "ZWEIFAMILIENHAUS",
                "image": 'assets/images/doubleHouse.svg',
                "state": "warning",
                "checked": false,
                "content": null
            },
            {
                "label": "TILES.HOUSE_CATEGORY.TILE4",
                "name": "house-category",
                "value": "REIHENHAUS",
                "image": 'assets/images/lineHouse.svg',
                "state": "warning",
                "checked": false,
                "content": null
            },
            {
                "label": "TILES.HOUSE_CATEGORY.TILE5",
                "name": "house-category",
                "value": "MEHRFAMILIENHAUS",
                "image": 'assets/images/building.svg',
                "state": "warning",
                "checked": false,
                "content": null
            }
        ]
    },
    "OBJECT_NUTZUNG": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "object-nutzung",
                "value": "true",
                "image": 'assets/images/thumbsUp.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "COMMON.FALSE",
                "name": "object-nutzung",
                "value": "false",
                "image": 'assets/images/thumbsDown.svg',
                "state": "success",
                "checked": false
            },
        ]
    },
    "OBJECT_NUTZUNG_JA": {
        "items": [
            {
                "label": "TILES.OBJECT_NUTZUNG.TILE1",
                "name": "object-nutzung-soll",
                "value": "EIGENGENUTZT",
                "image": 'assets/images/houseUser.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.OBJECT_NUTZUNG.TILE2",
                "name": "object-nutzung-soll",
                "value": "TEILVERMIETET",
                "image": 'assets/images/handHoldingDollar.svg',
                "state": "warning",
                "checked": false,
                "content": null
            },
            {
                "label": "TILES.OBJECT_NUTZUNG.TILE3",
                "name": "object-nutzung-soll",
                "value": "VERMIETET",
                "image": 'assets/images/signHanging.svg',
                "state": "warning",
                "checked": false,
                "content": null
            },
        ]
    },
    "OBJECT_NUTZUNG_NEIN": {
        "items": [
            {
                "label": "TILES.OBJECT_NUTZUNG.TILE4",
                "name": "object-nutzung-ist",
                "value": "EIGENGENUTZT",
                "image": 'assets/images/houseUser.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.OBJECT_NUTZUNG.TILE5",
                "name": "object-nutzung-ist",
                "value": "TEILVERMIETET",
                "image": 'assets/images/handHoldingDollar.svg',
                "state": "warning",
                "checked": false,
                "content": null
            },
            {
                "label": "TILES.OBJECT_NUTZUNG.TILE6",
                "name": "object-nutzung-ist",
                "value": "VERMIETET",
                "image": 'assets/images/moneyTransfer.svg',
                "state": "warning",
                "checked": false,
                "content": null
            }
        ]
    },
    "CONDITION_ESTATE": {
        "items": [
            {
                "label": "TILES.CONDITION_ESTATE.TILE1",
                "info": "conditionInfo",
                "name": "conditionEstate",
                "type":"condition",
                "rating": 0,
                "success": false,
                "stars": 5
            },
            {
                "label": "TILES.CONDITION_ESTATE.TILE2",
                "info": "equipmentInfo",
                "name": "equipmentEstate",
                "type": "equipment",
                "rating": 0,
                "success": false,
                "stars": 4
            }
        ]
    },
    "MODERNIZATION": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "modernization",
                "value": "VORHANDENE_MODERNISIERUNG",
                "image": 'assets/images/circleCheck.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "COMMON.FALSE",
                "name": "modernization",
                "value": "KEINE_MODERNISIERUNG",
                "image": 'assets/images/circleXmark.svg',
                "state": "success",
                "checked": false,
            },
            {
                "label": "TILES.MODERNIZATION.TILE3",
                "name": "modernization",
                "value": "unbekannt",
                "image": 'assets/images/circleFullQuestion.svg',
                "state": "success",
                "checked": false
            }
        ]
    },
    "BAUWEISE": {
        "items": [
            {
                "label": "TILES.BAUWEISE.TILE1",
                "name": "bauweise",
                "value": "MASSIV",
                "image": 'assets/images/blockBrick.svg',
                "state": "warning",
                "checked": false,
                "content": null
            },
            {
                "label": "TILES.BAUWEISE.TILE2",
                "name": "bauweise",
                "value": "HOLZ",
                "image": 'assets/images/tree.svg',
                "state": "warning",
                "checked": false,
                "content": null
            },
            {
                "label": "TILES.BAUWEISE.TILE3",
                "name": "bauweise",
                "value": "GLAS_STAHL",
                "image": 'assets/images/bitBucket.svg',
                "state": "warning",
                "checked": false,
                "content": null
            },
            {
                "label": "TILES.BAUWEISE.TILE4",
                "name": "bauweise",
                "value": "FACHWERK_MIT_ZIEGELN",
                "image": 'assets/images/blockBrickLight.svg',
                "state": "warning",
                "checked": false,
                "content": null
            },
            {
                "label": "TILES.BAUWEISE.TILE5",
                "name": "bauweise",
                "value": "FACHWERK_MIT_STROH_LEHM",
                "image": 'assets/images/wheat.svg',
                "state": "warning",
                "checked": false,
                "content": null
            }
        ]
    },
    "ENERGY": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "energy",
                "value": "true",
                "image": 'assets/images/thumbsUp.svg',
                "state": "warning",
                "checked": false,
                "content": null
            },
            {
                "label": "COMMON.FALSE",
                "name": "energy",
                "value": "false",
                "image": 'assets/images/thumbsDown.svg',
                "state": "success",
                "checked": false
            }
        ]
    },
    "PARKING": {
        "items": [
            {
                "label": "TILES.PARKING.TILE1",
                "name": "parking",
                "value": "KEINE_STELLPLAETZE",
                "image": 'assets/images/circleXmark.svg',
                "state": "neutral",
                "checked": false,
            },
            {
                "label": "TILES.PARKING.TILE2",
                "name": "parking",
                "value": "STELLPLATZ",
                "image": 'assets/images/car.svg',
                "state": "neutral",
                "checked": false,
                "content": null
            },
            {
                "label": "TILES.PARKING.TILE3",
                "name": "parking",
                "value": "CARPORT",
                "image": 'assets/images/carGarage.svg',
                "state": "neutral",
                "checked": false,
                "content": null
            },
            {
                "label": "TILES.PARKING.TILE4",
                "name": "parking",
                "value": "GARAGE",
                "image": 'assets/images/garageCar.svg',
                "state": "neutral",
                "checked": false,
                "content": null
            },
            {
                "label": "TILES.PARKING.TILE5",
                "name": "parking",
                "value": "DOPPEL_GARAGE",
                "image": 'assets/images/garage.svg',
                "state": "neutral",
                "checked": false,
                "content": null
            },
            {
                "label": "TILES.PARKING.TILE6",
                "name": "parking",
                "value": "TIEFGARAGEN_STELLPLATZ",
                "image": 'assets/images/carBuilding.svg',
                "state": "neutral",
                "checked": false,
                "content": null
            }
        ]
    },
    "ERBBAURECHT": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "erbbaurecht",
                "value": "VORHANDENES_ERBBAURECHT",
                "image": 'assets/images/handsHoldingCircle.svg',
                "state": "warning",
                "checked": false,
                "content": null
            },
            {
                "label": "COMMON.FALSE",
                "name": "erbbaurecht",
                "value": "KEIN_ERBBAURECHT",
                "image": 'assets/images/circleXmark.svg',
                "state": "success",
                "checked": false,
                
            }
            
        ]
    },
    "PERSONAL": {
        "items": [
            {
                "label": "TILES.PERSONAL.TILE1",
                "name": "personal",
                "value": "FRAU",
                "image": 'assets/images/personDress.svg',
                "state": "success",
                "checked": false,
                
            },
            {
                "label": "TILES.PERSONAL.TILE2",
                "name": "personal",
                "value": "MANN",
                "image": 'assets/images/person.svg',
                "state": "success",
                "checked": false,
            },
            {
                "label": "TILES.PERSONAL.TILE3",
                "name": "personal",
                "value": "DIVERS",
                "image": 'assets/images/personHalfDress.svg',
                "state": "success",
                "checked": false,
            }
        ],
        "containers": [
            {
                "value": "Birthdate",
                "state": "warning",
            },
            {
                "value": "PostalCode",
                "state": "warning",
            },
            {
                "value": "Family",
                "state": "warning",
            },
            {
                "value": "State",
                "state": "warning",
            },
            {
                "value": "Job",
                "state": "warning",
            }
        ]
    },
    "PERSONAL_PARTNER": {
        "items": [],
        "containers": [
            {
                "value": "Birthdate",
                "state": "warning",
            },
            {
                "value": "PostalCode",
                "state": "warning",
            },
            {
                "value": "Family",
                "state": "warning",
            },
            {
                "value": "State",
                "state": "warning",
            },
            {
                "value": "Job",
                "state": "warning",
            }
        ]
    },
    "ADDITIONAL_INCOME_SINGLE": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "additionalIncome",
                "value": "Ja",
                "image": 'assets/images/coins.svg',
                "state": "warning",
                "checked": false,
                "content": null
                
            },
            {
                "label": "COMMON.FALSE",
                "name": "additionalIncome",
                "value": "Nein",
                "image": 'assets/images/circleXmark.svg',
                "state": "success",
                "checked": false
            },
        ]
    },
    "ADDITIONAL_INCOME": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "additionalIncome",
                "value": "Ja",
                "image": 'assets/images/coins.svg',
                "state": "warning",
                "checked": false,
                "content": null
                
            },
            {
                "label": "TILES.ADDITIONAL_INCOME.TILE1",
                "name": "additionalIncome",
                "value": "JaPlusPartner",
                "image": 'assets/images/moneyBillsSimple.svg',
                "state": "success",
                "checked": false,
                "content": null
                
            },
            {
                "label": "COMMON.FALSE",
                "name": "additionalIncome",
                "value": "Nein",
                "image": 'assets/images/circleXmark.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.ADDITIONAL_INCOME.TILE2",
                "name": "additionalIncome",
                "value": "NeinPlusPartner",
                "image": 'assets/images/handBackPointRight.svg',
                "state": "success",
                "checked": false
            }
        ]
    },
    "ADDITIONAL_INCOME_PARTNER": {
        "items": [],
        "containers": [
            {
                "value": "Nebenjob",
                "state": "warning",
            },
            {
                "value": "Rente",
                "state": "warning",
            },
            {
                "value": "Provision",
                "state": "warning",
            }
        ]
    },
    "CHILDREN": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "children",
                "value": "VORHANDENE_KINDER",
                "image": 'assets/images/children.svg',
                "state": "warning",
                "checked": false,
                "content": null
                
            },
            {
                "label": "COMMON.FALSE",
                "name": "children",
                "value": "KEINE_KINDER",
                "image": 'assets/images/circleXmark.svg',
                "state": "success",
                "checked": false
            }
        ]
    },
    "PRIVATKREDIT": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "privatkredit",
                "value": "VorhandeneVerbindlichkeiten",
                "image": 'assets/images/fileContract.svg',
                "state": "warning",
                "checked": false,
                "content": null
                
            },
            {
                "label": "COMMON.FALSE",
                "name": "privatkredit",
                "value": "KeineVerbindlichkeiten",
                "image": 'assets/images/circleXmark.svg',
                "state": "success",
                "checked": false
            }
        ]
    },
    "MIETE": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "miete",
                "value": "VorhandeneMiete",
                "image": 'assets/images/key.svg',
                "state": "warning",
                "checked": false,
                "content": null
                
            },
            {
                "label": "COMMON.FALSE",
                "name": "miete",
                "value": "KeineMiete",
                "image": 'assets/images/circleXmark.svg',
                "state": "success",
                "checked": false
            }
        ]
    },
    "HEALTH_INSURANCE_SINGLE": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "healthInsurance",
                "value": "Ja",
                "image": 'assets/images/staffSnake.svg',
                "state": "warning",
                "checked": false,
                "content": null
                
            },
            {
                "label": "COMMON.FALSE",
                "name": "healthInsurance",
                "value": "Nein",
                "image": 'assets/images/circleXmark.svg',
                "state": "success",
                "checked": false
            },
        ]
    },
    "HEALTH_INSURANCE": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "healthInsurance",
                "value": "Ja",
                "image": 'assets/images/staffSnake.svg',
                "state": "warning",
                "checked": false,
                "content": null
                
            },
            {
                "label": "TILES.ADDITIONAL_INCOME.TILE1",
                "name": "healthInsurance",
                "value": "JaPlusPartner",
                "image": 'assets/images/notesMedical.svg',
                "state": "warning",
                "checked": false,
                "content": null
                
            },
            {
                "label": "COMMON.FALSE",
                "name": "healthInsurance",
                "value": "Nein",
                "image": 'assets/images/circleXmark.svg',
                "state": "success",
                "checked": false
            },
            {
                "label": "TILES.ADDITIONAL_INCOME.TILE2",
                "name": "healthInsurance",
                "value": "NeinPlusPartner",
                "image": 'assets/images/userHospital.svg',
                "state": "warning",
                "checked": false,
                "content": null
            }
        ]
    },
    "VEHICLES": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "vehicles",
                "value": "VorhandeneFahrzeuge",
                "image": 'assets/images/carSide.svg',
                "state": "warning",
                "checked": false,
                "content": null
                
            },
            {
                "label": "COMMON.FALSE",
                "name": "vehicles",
                "value": "KeineFahrzeuge",
                "image": 'assets/images/circleXmark.svg',
                "state": "success",
                "checked": false
            }
        ]
    },
    "FINANCIAL_ASSETS": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "financialAssets",
                "value": "Ja",
                "image": 'assets/images/sackDollar.svg',
                "state": "warning",
                "checked": false,
                "content": null
                
            },
            {
                "label": "COMMON.FALSE",
                "name": "financialAssets",
                "value": "nein",
                "image": 'assets/images/circleXmark.svg',
                "state": "success",
                "checked": false
            }
        ],
        "containers": [
            {
                "value": "Sparen",
                "image": 'assets/images/piggyBank.svg',
                "state": "warning",
            },
            {
                "value": "Bausparen",
                "image": 'assets/images/fileLines.svg',
                "state": "warning",
            },
            {
                "value": "Wertpapiere",
                "image": 'assets/images/chartMixedUpCircleDollar.svg',
                "state": "warning",
            },
            {
                "value": "Immobilien",
                "image": 'assets/images/houseBuilding.svg',
                "state": "warning",
            },
            {
                "value": "Familie",
                "image": 'assets/images/handHoldingDollar.svg',
                "state": "warning",
            },
            {
                "value": "Versicherungen",
                "image": 'assets/images/fileChartColumn.svg',
                "state": "warning",
            }
        ]
    },
    "EIGENLEISTUNG": {
        "items": [
            {
                "label": "COMMON.TRUE",
                "name": "eigenleistung",
                "value": "VORHANDENE_EIGENLEISTUNG",
                "image": 'assets/images/hammer.svg',
                "state": "warning",
                "checked": false,
                "content": null,
                "dark": true,
                
            },
            {
                "label": "COMMON.FALSE",
                "name": "eigenleistung",
                "value": "KEINE_EIGENLEISTUNG",
                "image": 'assets/images/mugHot.svg',
                "state": "success",
                "checked": false,
                "dark": true
            }
        ]
    },
    "ERMITTLUNGSWEG": {
        "items": [
            {
                "label": "FINANCE_CONTINUE.PATH.TILES.TILE1",
                "name": "ermittlung",
                "value": "traditionell",
                "image": 'assets/images/sliders.svg',
                "state": "success",
                "isTwin": true,
                "isDouble": true,
                "checked": false,
                
            },
            {
                "label": "FINANCE_CONTINUE.PATH.TILES.TILE2",
                "name": "ermittlung",
                "value": "ai",
                "image": 'assets/images/microchipAi.svg',
                "state": "success",
                "isEP": true,
                "isTwin": true,
                "isDouble": true,
                "checked": false,
            }
        ]
    },
    "BAUSTEINE": {
        "items": [
            {
                "label": "FINANCE_ASSEMBLY.TILES.TILE1",
                "name": "bausteine",
                "value": "one",
                "image": 'assets/images/gear.svg',
                "state": "success",
                "isDouble": true,
                "checked": false,
                
            },
            {
                "label": "FINANCE_ASSEMBLY.TILES.TILE2",
                "name": "ermittlung",
                "value": "multiple",
                "image": 'assets/images/gears.svg',
                "state": "success",
                "isDouble": true,
                "checked": false,
                "content": null
            }
        ]
    },
    "PREFERENCES": {
        "items": [
            {
                "value": "Zinsbindung",
                "image": 'assets/images/rightLongToLine.svg',
                "state": "optional",
                
            },
            {
                "value": "Rate",
                "image": 'assets/images/barsProgress.svg',
                "state": "optional",
            },
            {
                "value": "Aufbau",
                "image": 'assets/images/chartSimpleHorizontal.svg',
                "state": "optional",
            },
            {
                "value": "Sondertilgung",
                "image": 'assets/images/coins.svg',
                "state": "optional",
            },
            {
                "value": "Laufzeit",
                "image": 'assets/images/chartLineDown.svg',
                "state": "optional",
            },
            // {
            //     "value": "Wechsel",
            //     "image": 'assets/images/arrowUpArrowDown.svg',
            //     "state": "optional",
            // },
            {
                "value": "Bewilligung",
                "image": 'assets/images/hourglassClock.svg',
                "state": "optional",
            },
            {
                "value": "Auszahlung",
                "image": 'assets/images/moneyBillsSimple.svg',
                "state": "optional",
            }
        ]
    },
    "TASKS_PART1": {
        "items": [
            {
                "label": "TASKS.PARTS.PART1.TASK1",
                "button": false,
                
            },
            {
                "label": "TASKS.PARTS.PART1.TASK2",
                "button": false,
                
            },
            {
                "label": "TASKS.PARTS.PART1.TASK3",
                "button": false,
                
            },
            {
                "label": "TASKS.PARTS.PART1.TASK4",
                "button": false,
                
            }
        ]
    },
    "TASKS_PART2": {
        "items": [
            {
                "label": "TASKS.PARTS.PART2.TASK1",
                "button": false,
            },
            {
                "label": "TASKS.PARTS.PART2.TASK2",
                "button": false,

            },
            {
                "label": "TASKS.PARTS.PART2.TASK3",
                "button": true,
                
            },
            {
                "label": "TASKS.PARTS.PART2.TASK4",
                "button": false,
                
            },
            {
                "label": "TASKS.PARTS.PART2.TASK5",
                "button": false,
                
            },
            {
                "label": "TASKS.PARTS.PART2.TASK6",
                "button": false,
                
            },
            {
                "label": "TASKS.PARTS.PART2.TASK7",
                "button": false,
                
            }
        ]
    },
    "TASKS_PART3": {
        "items": [
            {
                "label": "TASKS.PARTS.PART3.TASK1",
                "button": false,
                
            },
            {
                "label": "TASKS.PARTS.PART3.TASK2",
                "button": false,
                
            },
            {
                "label": "TASKS.PARTS.PART3.TASK3",
                "button": false,
                
            }
        ]
    },
    "TASKS_PART4": {
        "items": [
            {
                "label": "TASKS.PARTS.PART4.TASK1",
                "button": false,
                
            },
            {
                "label": "TASKS.PARTS.PART4.TASK2",
                "button": false,
                
            }
        ]
    },
    "TASKS_PART5": {
        "items": [
            {
                "label": "TASKS.PARTS.PART5.TASK1",
                "button": false,
                
            },
            {
                "label": "TASKS.PARTS.PART5.TASK2",
                "button": false,
                
            },
            {
                "label": "TASKS.PARTS.PART5.TASK3",
                "button": false,
                
            },
            {
                "label": "TASKS.PARTS.PART5.TASK4",
                "button": false,
                
            },
            {
                "label": "TASKS.PARTS.PART5.TASK5",
                "button": false,
                
            }
        ]
    },
    "TASKS_PART6": {
        "items": [
            {
                "label": "TASKS.PARTS.PART6.TASK1",
                "button": false,
                
            }
        ]
    },
}