
export const daBSliderIndex = [
    {
        id: 'start',
        progressIndex: 1,
        icon: '/assets/images/personRunning.svg',
        label: 'PROGRESS.LABELS.START',
        state: 'success',
        excluded: false,
        disabled: false,
    },
    {
        id: 'estate-status',
        progressIndex: 2,
        icon: '/assets/images/spinner.svg',
        label: 'PROGRESS.LABELS.ESTATE_STATUS',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'project-plan',
        progressIndex: 3,
        icon: '/assets/images/toggleOff.svg',
        label: 'PROGRESS.LABELS.PROJECT_PLAN',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'project-plan-forward',
        progressIndex: 3,
        icon: '/assets/images/toggleOff.svg',
        label: 'PROGRESS.LABELS.PROJECT_PLAN',
        state: 'warning',
        excluded: true,
        disabled: true,
    },
    {
        id: 'info-error-project',
        progressIndex: 3,
        icon: '/assets/images/circleExclamation.svg',
        label: 'PROGRESS.LABELS.INFO',
        state: 'end',
        excluded: true,
        disabled: true,
    },
    {
        id: 'status-spec',
        progressIndex: 4,
        icon: '/assets/images/signalBars.svg',
        label: 'PROGRESS.LABELS.STATUS',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'info-success-25',
        progressIndex: 4.5,
        icon: '/assets/images/circleCheck.svg',
        label: 'PROGRESS.LABELS.PROGRESS',
        state: 'info',
        excluded: false,
        disabled: true,
    },
    {
        id: 'residence',
        progressIndex: 5,
        icon: '/assets/images/locationDot.svg',
        label: 'PROGRESS.LABELS.RESIDENCE',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'info-error-residence',
        progressIndex: 5,
        icon: '/assets/images/circleExclamation.svg',
        label: 'PROGRESS.LABELS.INFO',
        state: 'end',
        excluded: true,
        disabled: true,
    },
    {
        id: 'schufa',
        progressIndex: 6,
        icon: '/assets/images/gaugeMax.svg',
        label: 'PROGRESS.LABELS.SCHUFA',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'info-error-schufa',
        progressIndex: 6,
        icon: '/assets/images/circleExclamation.svg',
        label: 'PROGRESS.LABELS.INFO',
        state: 'end',
        excluded: true,
        disabled: true,
    },
    {
        id: 'participants',
        progressIndex: 7,
        icon: '/assets/images/people.svg',
        label: 'PROGRESS.LABELS.PARTICIPANTS',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'object-category',
        progressIndex: 8,
        icon: '/assets/images/tableCells.svg',
        label: 'PROGRESS.LABELS.OBJECT_CATEGORY',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'house-category',
        progressIndex: 9,
        icon: '/assets/images/houseBuilding.svg',
        label: 'PROGRESS.LABELS.HOUSE_CATEGORY',
        state: 'warning',
        excluded: true,
        disabled: true,
    },
    {
        id: 'address',
        progressIndex: 10,
        icon: '/assets/images/mapLocation.svg',
        label: 'PROGRESS.LABELS.ADRESS',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'house-detail',
        progressIndex: 11,
        icon: '/assets/images/fileMagnifyingGlass.svg',
        label: 'PROGRESS.LABELS.HOUSE_DETAILS',
        state: 'warning',
        excluded: true,
        disabled: true,
    },
    {
        id: 'mf-house-detail',
        progressIndex: 11,
        icon: '/assets/images/fileMagnifyingGlass.svg',
        label: 'PROGRESS.LABELS.HOUSE_DETAILS',
        state: 'warning',
        excluded: true,
        disabled: true,
    },
    {
        id: 'object-detail',
        progressIndex: 11,
        icon: '/assets/images/fileMagnifyingGlass.svg',
        label: 'PROGRESS.LABELS.OBJECT_DETAILS',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'object-nutzung',
        progressIndex: 12,
        icon: '/assets/images/apartment.svg',
        label: 'PROGRESS.LABELS.NUTZUNG',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'condition-estate',
        progressIndex: 13,
        icon: '/assets/images/starHalf.svg',
        label: 'PROGRESS.LABELS.CONDITION',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'modernization',
        progressIndex: 14,
        icon: '/assets/images/handSparkles.svg',
        label: 'PROGRESS.LABELS.MODERNIZATION',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'bauweise',
        progressIndex: 15,
        icon: '/assets/images/blockBrick.svg',
        label: 'PROGRESS.LABELS.BAUWEISE',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'energy',
        progressIndex: 16,
        icon: '/assets/images/tag.svg',
        label: 'PROGRESS.LABELS.ENERGY',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'parking',
        progressIndex: 17,
        icon: '/assets/images/squareParking.svg',
        label: 'PROGRESS.LABELS.PARKING',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'erbbaurecht',
        progressIndex: 18,
        icon: '/assets/images/personArrowDown.svg',
        label: 'PROGRESS.LABELS.ERBE',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    // {
    //     id: 'market-value',
    //     progressIndex: 19,
    //     icon: '/assets/images/moneyBillsSimple.svg',
    //     label: 'Marktwert',
    //     state: 'warning',
    //     excluded: false,
    //     disabled: true,
    // },
    {
        id: 'info-success-70',
        progressIndex: 19,
        icon: '/assets/images/circleCheck.svg',
        label: 'PROGRESS.LABELS.PROGRESS',
        state: 'info',
        excluded: false,
        disabled: true,
    },
    {
        id: 'personal',
        progressIndex: 20,
        icon: '/assets/images/user.svg',
        label: 'PROGRESS.LABELS.PERSON',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'personal-partner',
        progressIndex: 20.5,
        icon: '/assets/images/userPlus.svg',
        label: 'PROGRESS.LABELS.PARTNER',
        state: 'warning',
        excluded: true,
        disabled: true,
    },
    {
        id: 'additional-income-single',
        progressIndex: 21,
        icon: '/assets/images/grid-plus.svg',
        label: 'PROGRESS.LABELS.ADD_INCOME_1',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'additional-income',
        progressIndex: 21,
        icon: '/assets/images/grid-plus.svg',
        label: 'PROGRESS.LABELS.ADD_INCOME_2',
        state: 'warning',
        excluded: true,
        disabled: true,
    },
    {
        id: 'additional-income-partner',
        progressIndex: 21.5,
        icon: '/assets/images/grid-plus.svg',
        label: 'PROGRESS.LABELS.ADD_INCOME_3',
        state: 'warning',
        excluded: true,
        disabled: true,
    },
    {
        id: 'children',
        progressIndex: 22,
        icon: '/assets/images/children.svg',
        label: 'PROGRESS.LABELS.KIDS',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'privatkredit',
        progressIndex: 23,
        icon: '/assets/images/fileContract.svg',
        label: 'PROGRESS.LABELS.CREDIT',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'miete',
        progressIndex: 24,
        icon: '/assets/images/key.svg',
        label: 'PROGRESS.LABELS.RENT',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'health-insurance',
        progressIndex: 25,
        icon: '/assets/images/staffSnake.svg',
        label: 'PROGRESS.LABELS.KV',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'vehicles',
        progressIndex: 26,
        icon: '/assets/images/carSide.svg',
        label: 'PROGRESS.LABELS.CAR',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'financial-assets',
        progressIndex: 27,
        icon: '/assets/images/chartPieSimpleCircleDollar.svg',
        label: 'PROGRESS.LABELS.ASSETS',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
    {
        id: 'property-information',
        progressIndex: 28,
        icon: '/assets/images/objectsAlignBottom.svg',
        label: 'PROGRESS.LABELS.IMMO_INFO',
        state: 'warning',
        excluded: true,
        disabled: true,
    },
    {
        id: 'property-change',
        progressIndex: 29,
        icon: '/assets/images/arrowsCross.svg',
        label: 'PROGRESS.LABELS.IMMO_CHANGE',
        state: 'warning',
        excluded: true,
        disabled: true,
    },
    {
        id: 'info-success-90',
        progressIndex: 29.5,
        icon: '/assets/images/circleCheck.svg',
        label: 'PROGRESS.LABELS.PROGRESS',
        state: 'info',
        excluded: false,
        disabled: true,
    },
    {
        id: 'finance-requirements',
        progressIndex: 30,
        icon: '/assets/images/chartTreeMap.svg',
        label: 'PROGRESS.LABELS.NEEDS',
        state: 'warning',
        excluded: false,
        disabled: true,
    },
]