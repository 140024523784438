import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';

@Component({
  selector: 'app-expose',
  templateUrl: './expose.component.html',
  styleUrls: ['./expose.component.scss']
})
export class ExposeComponent implements AfterViewInit {
  public slideName: string = 'expose';
  next: string = 'info-success-70';
  disabled: boolean = true;

  container1State: string = 'optional';
  container2State: string = 'optional';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('objectExposeLink') objectExposeLink!: any;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService
  ) {
    this.daService.setCurrentSlide(this.slideName);
  }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.setValue() });
  }

  setValue() {
    this.disabled = false;
    const status = this.dataQuery.getEntity(1)?.['objectExposeLink'];

    if (!!status) {
      this.objectExposeLink.value = status;
      this.setContainerStatus('container1', status);
    }

    this.daNextSlideService.update(1, { disabled: this.disabled });
  }

  storeInputValue(e: any) {
    this.setContainerStatus('container1', e.target.value);

    this.daService.update(1, { objectExposeLink: e.target.value });
  }

  setContainerStatus(type: string, value: string) {
    setTimeout(() => {
      if (type === 'container1') {
        if (value) {
          this.container1State = 'success';
        } else {
          this.container1State = 'optional';
        }
      } else {
        this.container2State = value;
      }

      if (this.container1State === 'error' || this.container2State === 'error') {
        this.disabled = true;
      } else {
        this.disabled = false;
      }

      this.daNextSlideService.update(1, { disabled: this.disabled });
    }, 100)
  }

}
