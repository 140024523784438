import { Injectable, Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public getWidth: any;
  public getHeight: any;

  private displaySizeSmallest = 540;
  private displaySizePhone = 768;
  private displayBreakpointTwoResultsWidth = 920;
  private displaySizeTablet = 1024;
  private displaySizeChildrenWidth = 1195;
  private displayBreakpointOneResultsWidth = 1205;
  private displaySizeDesktopS = 1280;
  private displaySizeDesktopM = 1366;
  private displaySizeDesktopL = 1630;
  private displaySizeDesktopXL = 1920;

  public modal: any;

  constructor(
    public modalService: NgbModal,
  ) {
    this.getWidth = window.innerWidth;
    this.getHeight = window.innerHeight;
  }

  updateScreenSizes() {
    this.getWidth = window.innerWidth;
    this.getHeight = window.innerHeight;
  }

  isSmallest() {
    return this.getWidth < this.displaySizeSmallest ? true : false;
  }

  isMobile() {
    return this.getWidth < this.displaySizePhone ? true : false;
  }

  isSmallerThanChildContainer() {
    return this.getWidth < this.displaySizeChildrenWidth ? true : false;
  }

  isFirstResultBreakpoint() {
    return this.getWidth < this.displayBreakpointOneResultsWidth ? true : false;
  }

  isSecondResultBreakpoint() {
    return this.getWidth < this.displayBreakpointTwoResultsWidth ? true : false;
  }

  isMobileOrSmallTablet() {
    return this.getWidth < this.displaySizeTablet ? true : false;
  }

  isMobileOrTablet() {
    return this.getWidth < this.displaySizeDesktopS ? true : false;
  }

  isSmallerThanMinWidth() {
    return this.getWidth < this.displaySizeDesktopM ? true : false;
  }

  isBiggerThanMinWidth() {
    return this.getWidth < this.displaySizeDesktopL ? true : false;
  }

  isBiggerThanFullHD() {
    return this.getWidth > this.displaySizeDesktopXL ? true : false;
  }

  openInfo(type: any) {
    this.modalService.open(type, { 
      scrollable: true,
      animation: false,
      backdropClass: 'da-info-backdrop'
    });
  }

  scrollToTop() {
    let element;
    if(environment.daAsWebApp) {
      element = document.getElementsByClassName('da-master');
    } else {
      element = document.getElementsByClassName('modal-dialog');
    }
    
    if (element && element[0]) {
      element[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  scrollToElement(type: string, point: ScrollLogicalPosition = 'start') {
    const element = document.getElementsByClassName(type);
    if (element && element[0]) {
      element[0].scrollIntoView({ behavior: 'smooth', block: point });
    }
  }

  getTileIndex(tiles: any, value: string) {
    let index; 
    tiles.map((tile: { value: string; }) => {
      if (tile.value === value) {
        index = tiles.indexOf(tile);
      }
    });
    return index;
  }

  calculateNewPos(tiles: any, value: string, tileSlideFactor: number, tilePosition: string, basePosition: number) {
    let baseWidth = this.isSmallest() ? 0 : basePosition;
    tiles.map((tile: { value: string; }) => {
      if (tile.value === value) {
        const index = tiles.indexOf(tile);
        const slideLength = tileSlideFactor * index;
        const newPos = baseWidth + slideLength;
        tilePosition = newPos.toString();
      }
    });
    return tilePosition;
  }

  calculateNewPosById(id: number, tileSlideFactor: number, tilePosition: string, basePosition: number) {
    let baseWidth = this.isSmallest() ? 0 : basePosition;
    const slideLength = tileSlideFactor * id;
    const newPos = baseWidth + slideLength;
    tilePosition = newPos.toString();
    return tilePosition;
  }
}