import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { DaClickpathService } from 'src/app/state/click-path/da-clickpath.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-project-plan',
  templateUrl: './project-plan.component.html'
})
export class ProjectPlanComponent implements OnInit {
  public slideName: string = 'project-plan';
  next: string = 'status-spec';
  localValue!: string;
  disabled: boolean = true;
  tiles = tilesContent['PROJECT_PLAN'].items;

  @Output() public slideTo:EventEmitter<any> = new EventEmitter<string>();

  constructor(
    private daClickpathService: DaClickpathService,
    private daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    private contentService: ContentService
  ) {}

  ngOnInit(): void {
    this.daService.setCurrentSlide(this.slideName);
    const status = this.dataQuery.getEntity(1)?.['projectPlan'];

    if (status && status.length >= 1) {
      this.disabled = false;
      this.localValue = status;
      
      this.tiles.map(tile => {
        if(tile.value === status) {
          tile.checked = true;
        } else {
          tile.checked = false;
        }
      })
    }
    this.setNext(status);
  }

  setValue(e: string) {
    // update data store
    this.daService.update(1, { projectPlan: e });
    this.setNext(e);

    // update Navigation
    this.daNextSlideService.update(1, { next: this.next, timestamp: new Date });
    this.daClickpathService.updateClickPath(this.slideName);
    this.contentService.setSlideIndexState(this.slideName, false, 'success');
    this.slideTo.emit(this.next);

    // enable forward button
    this.disabled = false;
  }

  setNext(value: string) {
    const shortcut = this.dataQuery.getEntity(1)?.['shortcut'];
    const status = this.dataQuery.getEntity(1)?.['estateStatus'];
    switch (value) {
      case 'OTHER': {
        this.next = 'info-error-project';
        this.contentService.setSlideIndex('info-error-project', false);
      }
      break;
      case 'KAUF_NEUBAU_VOM_BAUTRAEGER':
      case 'NEUBAU': {
        this.next = 'status-spec';
        this.contentService.setSlideIndex('info-error-project', true);
        if(!shortcut) {
          if(!!status && status === 'NEUBAU') {
            this.contentService.setSlideIndex('object-category', true);
            this.contentService.setSlideIndex('house-category', false);
            this.contentService.setSlideIndex('modernization', true);
            this.daService.update(1, { objectType: 'Haus' });
          } else {
            this.contentService.setSlideIndex('object-category', false);
          }
        }
      }
      break;
      default: {
        this.next = 'status-spec';
        this.contentService.setSlideIndex('info-error-project', true);
        if(!shortcut) {
          this.contentService.setSlideIndex('object-category', false);
          this.contentService.setSlideIndex('modernization', false);
        }
      }
      break;
    }
  }

  setNav(e: string) {
    this.slideTo.emit(e);
  }

}
