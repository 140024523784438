import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { User } from './user.model';
import { UserStore } from './user.store';
import { UserQuery } from './user.query';
import { DataService } from '../data/data.service';
import { UserData } from '../digitalAssistent/user-data.model';
import { LoginLightService } from 'src/app/services/login-light.service';
import { DaClickpathQuery } from '../click-path/da-clickpath.query';
import { DataQuery } from '../data/data.query';
import { ContentService } from 'src/app/services/content.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {

  private APIURL_BASE = environment.backendHost;
  private APIEP_DAPROGRESS_UUID = '/daprogress/uuid';
  private APIEP_DAPROGRESS_DATA = '/daprogress/data';
  private APIEP_DAPROGRESS_ACCESS = '/daprogress/access';
  private APIEP_DAPROGRESS_SEND_LINK = '/daprogress/access_link';
  private APIEP_DAPROGRESS_RESEND_LINK = '/daprogress/resend/accesslink';

  constructor(
    private userStore: UserStore,
    private http: HttpClient,
    private userQuery: UserQuery,
    private daClickpathQuery: DaClickpathQuery,
    private daService: DataService,
    private dataQuery: DataQuery,
    private contentService: ContentService) {
  }


  get() {
    return this.http.get<User[]>('https://api.com').pipe(tap(entities => {
      this.userStore.set(entities);
    }));
  }

  add(user: User) {
    this.userStore.add(user);
  }

  /**
   * update clickpath in redux store
   * @param  {} id
   * @param  {Partial<User>} user
   */
  update(id: number, user: Partial<User>) {
    this.userStore.update(id, user);
  }

  refill(dataObject: any) {
    this.add({ id: 1, isLoggedInForLoginLight: true });
    for (let key of Object.keys(dataObject)) {
      let data = { [key]: dataObject[key] };
      this.update(1, data);
    }
  }

  remove(id: ID) {
    this.userStore.remove(id);
  }

  isLoggedInForLoginLight() {
    const user = this.userQuery.getEntity(1);
    return user && user.isLoggedInForLoginLight ? true : false;
  }

  // functions for Login Light
  private getAuthDataB64(id: string, secret?: string) {
    let auth_data_string = '';
    auth_data_string = secret ? auth_data_string.concat(id ,':', secret) : auth_data_string.concat(id);
    let auth_data_base64 = secret ? 'Basic\x20' : '\x20';
    auth_data_base64 = auth_data_base64.concat(btoa(auth_data_string));
    return auth_data_base64;
  }

  getUuid() : Observable<any> {
    let header = new HttpHeaders({'finsoliotenantuuid': this.contentService.getTenantUuid()});
    return this.http.get<any>(this.APIURL_BASE + this.APIEP_DAPROGRESS_UUID, {headers: header});
  }

  saveDAProgress(data: object): Observable<any> {
    let header = new HttpHeaders({'finsoliotenantuuid': this.contentService.getTenantUuid()});
    return this.http.post<any>(this.APIURL_BASE + this.APIEP_DAPROGRESS_DATA, data, {headers: header});
  }

  updateDAProgress(data: object): Observable<any> {
    let header = new HttpHeaders({'finsoliotenantuuid': this.contentService.getTenantUuid()});
    return this.http.put<any>(this.APIURL_BASE + this.APIEP_DAPROGRESS_DATA, data, {headers: header});
  }

  getDAProgressAccess(mail: string, uuid: string): Observable<any> {
    let request_headers = new HttpHeaders();
    request_headers = request_headers.set('Authorization', this.getAuthDataB64(mail, uuid));
    request_headers = request_headers.set('Cache-Control', 'no-store');
    request_headers = request_headers.set('finsoliotenantuuid', this.contentService.getTenantUuid());
    
    let result = this.http.get<any>(this.APIURL_BASE + this.APIEP_DAPROGRESS_ACCESS + '/' + uuid, {
      headers: request_headers
    });
    return result
  }

  getDAProgress(mail: string, uuid: string, secret: string) {
    let request_headers = new HttpHeaders();
    request_headers = request_headers.set('Authorization', this.getAuthDataB64(mail, secret));
    request_headers = request_headers.set('Cache-Control', 'no-store');
    request_headers = request_headers.set('finsoliotenantuuid', this.contentService.getTenantUuid());

    return this.http.get<any>(this.APIURL_BASE + this.APIEP_DAPROGRESS_DATA + '/' + uuid, {
      headers: request_headers
    });
  }
  
  sendAccessLink(mail: string) {
    let data = { clienttime: new Date()};
    let request_headers = new HttpHeaders();
    request_headers = request_headers.set('usermail', this.getAuthDataB64(mail));
    request_headers = request_headers.set('Cache-Control', 'no-store');
    request_headers = request_headers.set('finsoliotenantuuid', this.contentService.getTenantUuid());

    return this.http.post<any>(this.APIURL_BASE + this.APIEP_DAPROGRESS_RESEND_LINK, data, {
      headers: request_headers
    });
  }

  // Helperfunctions for save
  getUuidForSave() {
    return new Promise((resolve, reject) => {
      let $uuidObservable = this.getUuid();
      firstValueFrom($uuidObservable).then((uuidResult) => {
        if (!!uuidResult && !!uuidResult.progressid) {
          resolve(uuidResult.progressid);
        }
        reject(false);
      });
    });
  }

  getStoreData(uuid: any, userdata: any) {
    const next = this.daService.getNextSlide();

    return new Promise((resolve, reject) => {
      this.daService.update(1, { mail: userdata.mail });
      this.daService.update(1, { uuid: userdata.uuid });
      this.daService.update(1, { clickpath: this.daClickpathQuery.getEntity(1)?.clickpath });
      this.daService.update(1, { nextSlide: this.daService.getNextSlide() });
      this.daService.update(1, { slideIndex: this.daClickpathQuery.getEntity(1)?.slideIndex });

      setTimeout(() => {
        let $getStoreDataObservable = this.daService.getStoreData();
        firstValueFrom($getStoreDataObservable).then((getStoreDataResult) => {
          let progressData = { ...getStoreDataResult['1'], uuid: uuid };
          if (!!getStoreDataResult && !!userdata && !!progressData) {
            resolve({ metadata: this.getUserData(userdata), progressdata: progressData });
          }
          reject('store data nicht gefunden.');
        });
      }, 100);
    });
  }

  getUserData(rawUserData: any) {
    return {
      anrede: rawUserData.anrede,
      vorname: rawUserData.vorname,
      nachname: rawUserData.nachname,
      mail: rawUserData.mail,
      agb: rawUserData.agb,
      datenschutz: rawUserData.datenschutz,
      uuid: rawUserData.uuid
    };
  }

  saveProgress(data: any) {
    return new Promise((resolve) => {
      let $saveProgressObservable = this.saveDAProgress(data);
      firstValueFrom($saveProgressObservable).then((getSaveProgressResult) => {
        if (!!getSaveProgressResult) {
          resolve(getSaveProgressResult);
        }
      });
    });
  }

  updateProgress(data: any) {
    return new Promise((resolve) => {
      let $updateProgressObservable = this.updateDAProgress(data);
      firstValueFrom($updateProgressObservable).then((getUpdateProgressResult) => {
        resolve(getUpdateProgressResult);
      });
    });
  }

}
