<lib-header-text
    class="da-title"
    label="{{title | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>

<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
    >

    <!-- allein oder nur partner -->
    <ng-template #content>
        <lib-input
            #feeOne
            name="kkv1"
            #inputs
            placeholder="800,00"
            [error]="false"
            [inputDisabled]="inputDisabled"
            (click)="testValid = true"
            errorText="{{'COMMON.ERRORS.FEE' | translate}}"
            [touched]="!!contentService.getValue('kkv1')"
            label="{{'COMMON.LABELS.MONTHLY_FEE' | translate}}"
            [value]="!!contentService.getValue('kkv1') ? contentService.getValue('kkv1') : ''"
            (change)="validateInputValue($event, feeOne)"
            [isCurrency]="false"
            [light]="true"
            [ngStyle]="{'margin-top': '45px'}"
        ></lib-input>
        <lib-tile-text
            class="da-health-text-smaller"
            size="16px"
            label="{{'HEALTH_INSURANCE.TILE_TEXT1' | translate}}"
            [light]="true"
        ></lib-tile-text>
        <lib-toggle
            #abgezogen1
            label1="{{'COMMON.TRUE' | translate}}"
            label2="{{'COMMON.FALSE' | translate}}"
            name="abgezogen1"
            value1="true"
            value2="false"
            [localValue]="contentService.getValue('abgezogenKkv1')"
            (switchValue)="setSwitch($event, 'abgezogen1')"
            [light]="true"
        ></lib-toggle>
        <ng-container *ngIf="withChildren">
            <lib-tile-text
                class="da-health-text-smaller"
                size="16px"
                label="{{'HEALTH_INSURANCE.TILE_TEXT2' | translate}}"
                [light]="true"
            ></lib-tile-text>
            <lib-toggle
                #kinder1
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="kinder1"
                value1="true"
                value2="false"
                [localValue]="contentService.getValue('kinderKkv1')"
                (switchValue)="setSwitch($event, 'kinder1')"
                [light]="true"
            ></lib-toggle>
            <lib-input
                *ngIf="!!contentService.getValue('kinderKkv1') && contentService.getValue('kinderKkv1') === 'true'"
                #feeKinderOne
                #inputs
                name="kkv2"
                placeholder="800,00"
                [error]="false"
                [inputDisabled]="inputDisabled"
                (click)="testValid = true"
                errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                [touched]="!!contentService.getValue('kkv2')"
                label="{{'COMMON.LABELS.MONTHLY_FEE' | translate}}"
                [value]="!!contentService.getValue('kkv2') ? contentService.getValue('kkv2') : ''"
                (change)="validateInputValue($event, feeKinderOne)"
                [isCurrency]="false"
                [light]="true"
                [ngStyle]="{'margin-top': '45px'}"
            ></lib-input>
        </ng-container>
    </ng-template>

    <!-- beide -->
    <ng-template #contentPartner>
        <lib-toggle-tristate 
            class="da-miete-tabs"
            [tabs]="tabs"
            (switchValue)="setSwitch($event, 'tabs')"
        ></lib-toggle-tristate>

        <!-- ANGABEN PERSON 1 -->
        <ng-container *ngIf="selectedTab.value === 'number1'">
            <lib-tile-text
                size="20px"
                label="{{'HEALTH_INSURANCE.SUB_TITLE' | translate}}"
                [light]="true"
            ></lib-tile-text>
            <lib-input
                #feeOne
                #inputs
                name="kkv1"
                placeholder="800,00"
                [error]="false"
                [inputDisabled]="inputDisabled"
                (click)="testValid = true"
                errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                [touched]="!!contentService.getValue('kkv1')"
                label="{{'COMMON.LABELS.MONTHLY_FEE' | translate}}"
                [value]="!!contentService.getValue('kkv1') ? contentService.getValue('kkv1') : ''"
                (change)="validateInputValue($event, feeOne)"
                [light]="true"
            ></lib-input>
            <lib-tile-text
                class="da-health-text-smaller"
                size="16px"
                label="{{'HEALTH_INSURANCE.TILE_TEXT1' | translate}}"
                [light]="true"
            ></lib-tile-text>
            <lib-toggle
                #abgezogen1
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="abgezogen1"
                value1="true"
                value2="false"
                [localValue]="contentService.getValue('abgezogenKkv1')"
                (switchValue)="setSwitch($event, 'abgezogen1')"
                [light]="true"
            ></lib-toggle>
            <ng-container *ngIf="withChildren">
                <lib-tile-text
                    class="da-health-text-smaller"
                    size="16px"
                    label="{{'HEALTH_INSURANCE.TILE_TEXT2' | translate}}"
                    [light]="true"
                ></lib-tile-text>
                <lib-toggle
                    #kinder1
                    label1="{{'COMMON.TRUE' | translate}}"
                    label2="{{'COMMON.FALSE' | translate}}"
                    name="kinder1"
                    value1="true"
                    value2="false"
                    [localValue]="contentService.getValue('kinderKkv1')"
                    (switchValue)="setSwitch($event, 'kinder1')"
                    [light]="true"
                ></lib-toggle>
                <lib-input
                    *ngIf="!!contentService.getValue('kinderKkv1') && contentService.getValue('kinderKkv1') === 'true'"
                    #feeKinderOne
                    #inputs
                    name="kkv2"
                    placeholder="800,00"
                    [error]="false"
                    [inputDisabled]="inputDisabled"
                    (click)="testValid = true"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    [touched]="!!contentService.getValue('kkv2')"
                    label="{{'COMMON.LABELS.MONTHLY_FEE' | translate}}"
                    [value]="!!contentService.getValue('kkv2') ? contentService.getValue('kkv2') : ''"
                    (change)="validateInputValue($event, feeKinderOne)"
                    [light]="true"
                    [ngStyle]="{'margin-top': '45px'}"
                ></lib-input>
            </ng-container>
        </ng-container>
        <!-- ANGABEN PERSON 2 -->
        <ng-container *ngIf="selectedTab.value === 'number2'">
            <lib-tile-text
                size="20px"
                label="{{'HEALTH_INSURANCE.SUB_TITLE_PARTNER' | translate}}"
                [light]="true"
            ></lib-tile-text>
            <lib-input
                #feeTwo
                #inputs
                name="kkv3"
                placeholder="800,00"
                [error]="false"
                [inputDisabled]="inputDisabled"
                (click)="testValid = true"
                errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                [touched]="!!contentService.getValue('kkv3')"
                label="{{'COMMON.LABELS.MONTHLY_FEE' | translate}}"
                [value]="!!contentService.getValue('kkv3') ? contentService.getValue('kkv3') : ''"
                (change)="validateInputValue($event, feeTwo)"
                [light]="true"
            ></lib-input>
            <lib-tile-text
                class="da-health-text-smaller"
                size="16px"
                label="{{'HEALTH_INSURANCE.TILE_TEXT1' | translate}}"
                [light]="true"
            ></lib-tile-text>
            <lib-toggle
                #abgezogen2
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="abgezogen2"
                value1="true"
                value2="false"
                [localValue]="contentService.getValue('abgezogenKkv2')"
                (switchValue)="setSwitch($event, 'abgezogen2')"
                [light]="true"
            ></lib-toggle>
            <ng-container *ngIf="withChildren">
                <lib-tile-text
                    class="da-health-text-smaller"
                    size="16px"
                    label="{{'HEALTH_INSURANCE.TILE_TEXT2' | translate}}"
                    [light]="true"
                ></lib-tile-text>
                <lib-toggle
                    #kinder2
                    label1="{{'COMMON.TRUE' | translate}}"
                    label2="{{'COMMON.FALSE' | translate}}"
                    name="kinder2"
                    value1="true"
                    value2="false"
                    [localValue]="contentService.getValue('kinderKkv2')"
                    (switchValue)="setSwitch($event, 'kinder2')"
                    [light]="true"
                ></lib-toggle>
                <lib-input
                    *ngIf="!!contentService.getValue('kinderKkv2') && contentService.getValue('kinderKkv2') === 'true'"
                    #feeKinderTwo
                    #inputs
                    name="kkv4"
                    placeholder="800,00"
                    [error]="false"
                    [inputDisabled]="inputDisabled"
                    (click)="testValid = true"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    [touched]="!!contentService.getValue('kkv4')"
                    label="{{'COMMON.LABELS.MONTHLY_FEE' | translate}}"
                    [value]="!!contentService.getValue('kkv4') ? contentService.getValue('kkv4') : ''"
                    (change)="validateInputValue($event, feeKinderTwo)"
                    [light]="true"
                    [ngStyle]="{'margin-top': '45px'}"
                ></lib-input>
            </ng-container>
        </ng-container>
    </ng-template>
</lib-tile-group>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

