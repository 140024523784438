import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { switchesContent } from 'src/assets/content/switches';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-finance-preference',
  templateUrl: './finance-preference.component.html',
  styleUrls: ['./finance-preference.component.scss']
})
export class FinancePreferenceComponent implements AfterViewInit {
  fakeTiles = tilesContent['PREFERENCES'].items;
  selectedTile: string = 'Zinsbindung';
  tilePosition: string = '122';
  basePosition: number = 122;
  screenPosition: string = '-50%';
  tileSlideFactor: number = 266;
  tileIndex: number = 0;
  switchesZinsen = switchesContent['ZINSBINDUNG_PREF'].items;
  switchesRate = switchesContent['RATE_PREF'].items;
  switchesAufbau = switchesContent['AUFBAU_PREF'].items;
  switchesSondertilgung = switchesContent['SONDERTILGUNG_PREF'].items;
  switchesLaufzeit = switchesContent['LAUFZEIT_PREF'].items;
  switchesBewilligung = switchesContent['BEWILLIGUNG_PREF'].items;
  switchesAuszahlung = switchesContent['AUSZAHLUNG_PREF'].items;

  errorMax: string = 'COMMON.ERRORS.FEE';
  disabled: boolean = false;

  @ViewChild('zinsbindung') zinsbindung!: any;
  @ViewChild('zinsbindungValue') zinsbindungValue!: any;
  @ViewChild('rate') rate!: any;
  @ViewChild('rateValue') rateValue!: any;
  @ViewChild('rateNumber') rateNumber!: any;
  @ViewChild('aufbau') aufbau!: any;
  @ViewChild('aufbauValue') aufbauValue!: any;
  @ViewChild('sondertilgung') sondertilgung!: any;
  @ViewChild('sondertilgungValue') sondertilgungValue!: any;
  @ViewChild('laufzeit') laufzeit!: any;
  @ViewChild('laufzeitValue') laufzeitValue!: any;
  @ViewChild('laufzeitVariable') laufzeitVariable!: any;
  @ViewChild('wechsel') wechsel!: any;
  @ViewChild('bewilligung') bewilligung!: any;
  @ViewChild('auszahlung') auszahlung!: any;
  @Output() public nextItem: EventEmitter<any> = new EventEmitter<string>();
  @Output() public previousItem: EventEmitter<any> = new EventEmitter<string>();
  constructor(
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public contentService: ContentService,
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
    this.setTilePos();
  }

  ngAfterViewInit(): void {
    this.setTilePos();
  }

  setTilePos() {
    if (this.screen.isSmallerThanMinWidth()) {
      if (this.screen.isSmallest()) {
        this.screenPosition = '-25px'; //-25px
        this.tilePosition = '0';
        this.tileSlideFactor = window.innerWidth;
      } else {
        this.screenPosition = '-50%';
        this.tilePosition = '122';
        this.tileSlideFactor = 266;
      }
    } else {
      this.screenPosition = '-50%';
      this.tilePosition = '122';
      this.tileSlideFactor = 266;
    }
    this.getIndex(this.selectedTile);
  }

  setSwitch(e: string, element: any, parent: string) {
    switch (element.name) {
      case 'zinsbindung': {
        this.daService.update(1, { zinsbindungPref: e });
        break;
      }
      case 'zinsbindungValue': {
        this.daService.update(1, { zinsbindungValuePref: e });
        break;
      }
      case 'rate': {
        this.daService.update(1, { ratePref: e });
        break;
      }
      case 'rateValue': {
        this.daService.update(1, { rateValuePref: e });
        break;
      }
      case 'rateNumber': {
        this.daService.update(1, { rateNumberPref: e });
        break;
      }
      case 'aufbau': {
        this.daService.update(1, { aufbauPref: e });
        break;
      }
      case 'aufbauValue': {
        this.daService.update(1, { aufbauValuePref: e });
        break;
      }
      case 'sondertilgung': {
        this.daService.update(1, { sondertilgungPref: e });
        break;
      }
      case 'sondertilgungValue': {
        this.daService.update(1, { sondertilgungValuePref: e });
        break;
      }
      case 'laufzeit': {
        this.daService.update(1, { laufzeitPref: e });
        break;
      }
      case 'laufzeitValue': {
        this.daService.update(1, { laufzeitValuePref: e });
        break;
      }
      case 'laufzeitVariable': {
        this.daService.update(1, { laufzeitVariablePref: e });
        break;
      }
      case 'wechsel': {
        this.daService.update(1, { wechselPref: e });
        break;
      }
      case 'bewilligung': {
        this.daService.update(1, { bewilligungPref: e });
        break;
      }
      case 'auszahlung': {
        this.daService.update(1, { auszahlungPref: e });
        break;
      }
    }
    this.setFakeTileStatus(parent);
  }

  validateInput(e: any, element: any, parent: string) {
    let value;
    if (element.name !== 'laufzeitVariable') {
      value = e ? e[0] : '';
      element.touched = e ? true : false;
      const isValid = this.contentService.validatePattern(value, element.validationType);
      element.error = !isValid;
      const isValidRate: any = this.contentService.minMax(this.rateNumber.value, 10, 10000);

      if (isValidRate.tooLow || isValidRate.tooHigh) {
        this.rateNumber.error = true;
        this.errorMax = 'COMMON.ERRORS.RATE'
      } else {
        this.errorMax = 'COMMON.ERRORS.FEE'
      }
    } else {
      const date = new Date();

      value = e.srcElement.value ?? '';
      element = this.contentService.validateYearValue(value, element, { min: date.getFullYear() + 1, max: 2100 });
    }

    if (!element.error) {
      this.setSwitch(value, element, parent);
      this.setFakeTileStatus(parent);
    } else {
      this.contentService.setTileStatusCheckbox(this.fakeTiles, parent, 'error');
    }
  }

  getIndex(e: string) {
    this.selectedTile = e;
    this.tilePosition = this.screen.calculateNewPos(this.fakeTiles, e, this.tileSlideFactor, this.tilePosition, this.basePosition);
  };

  setFakeTileStatus(parent: string) {
    this.disabled = true;
    const status = this.dataQuery.getEntity(1);
    let state = 'warning';

    switch (parent) {
      case 'Zinsbindung': {
        if (!!status?.['zinsbindungPref']) {
          if (status?.['zinsbindungPref'] === 'zinsbindungNein') {
            state = 'success';
            break;
          }
          if (!!status?.['zinsbindungValuePref']) {
            state = 'success';
          }
        }
        break;
      }
      case 'Rate': {
        if (!!status?.['ratePref']) {
          if (status?.['ratePref'] === 'rateNein') {
            state = 'success';
            break;
          }
          if (!!status?.['rateValuePref']) {
            if (status?.['rateValuePref'] !== '4') {
              state = 'success';
              break;
            }
          }
          if (!!status?.['rateNumberPref']) {
            if (!this.rateNumber.error) {
              state = 'success';
            } else {
              state = 'error';
            }
            break;
          }
        }
        break;
      }
      case 'Aufbau': {
        if (!!status?.['aufbauPref']) {
          if (status?.['aufbauPref'] === 'aufbauNein') {
            state = 'success';
            break;
          }
          if (!!status?.['aufbauValuePref']) {
            state = 'success';
          }
        }
        break;
      }
      case 'Sondertilgung': {
        if (!!status?.['sondertilgungPref']) {
          if (status?.['sondertilgungPref'] === 'sondertilgungNein') {
            state = 'success';
            break;
          }
          if (!!status?.['sondertilgungValuePref']) {
            state = 'success';
          }
        }
        break;
      }
      case 'Laufzeit': {
        if (!!status?.['laufzeitPref']) {
          if (status?.['laufzeitPref'] === 'laufzeitNein') {
            state = 'success';
            break;
          }
          if (!!status?.['laufzeitValuePref']) {
            if (status?.['laufzeitValuePref'] !== '4') {
              state = 'success';
              break;
            }
            if (!!status?.['laufzeitVariablePref']) {
              if (!this.laufzeitVariable.error) {
                state = 'success';
              } else {
                state = 'error';
              }
              break;
            }
          }
        }
        break;
      }
      case 'Wechsel': {
        if (!!status?.['wechselPref']) {
          state = 'success';
        }
        break;
      }
      case 'Bewilligung': {
        if (!!status?.['bewilligungPref']) {
          state = 'success';
        }
        break;
      }
      case 'Auszahlung': {
        if (!!status?.['auszahlungPref']) {
          state = 'success';
        }
        break;
      }
    }
    this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, parent, state);

    this.disabled = true;
    let hasError = false;
    this.fakeTiles.map((tile: any) => {
      if (tile.state === 'error') {
        hasError = true;
      }
    });

    this.disabled = hasError || state !== 'success' ? true : false;
  }

  goToNextItem() {

  }

  getResults() {
    this.daService.update(1, { results: [] });
    this.daService.update(1, { resultsDone: false });
    setTimeout(() => {
      this.nextItem.emit('preferenceDone');
    })
  }

  resetBausteine() {
    this.daService.update(1, { results: [] });
    this.daService.update(1, { resultsDone: false });
    this.daService.resetValues(
      { zinsbindungPref: '',  
        zinsbindungValuePref: '',
        ratePref: '',
        rateValuePref: '',
        rateNumberPref: '',
        aufbauPref: '',
        aufbauValuePref: '',
        sondertilgungPref: '',
        sondertilgungValuePref: '',
        laufzeitPref: '',
        laufzeitValuePref: '',
        laufzeitVariablePref: '',
        wechselPref: '',
        bewilligungPref: '',
        auszahlungPref: ''
      });
      this.previousItem.emit(true);
  }

}
