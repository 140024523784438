import { AfterViewInit, Component, EventEmitter, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { MappingService } from 'src/app/services/mapping.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { dropdownsContent } from 'src/assets/content/dropdowns';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-finance-assembly',
  templateUrl: './finance-assembly.component.html',
  styleUrls: ['./finance-assembly.component.scss']
})
export class FinanceAssemblyComponent implements AfterViewInit {
  tiles = tilesContent['BAUSTEINE'].items;
  dropdown = dropdownsContent['BAUSTEINE'].items;
  dropdownSelectedItem = { 'value': '', 'viewValue': 'COMMON.SELECT' };
  localValue!: string;
  errorDarlehenText: string = 'COMMON.ERRORS.FEE';
  darlehenValue: any = { value: '', i: 0 };
  disabled: boolean = true;

  darlehenLeft: boolean = true;
  warningText: string = 'COMMON.ERRORS.DARLEHENBEDARF_LEFT';

  @ViewChild('content') content!: TemplateRef<any>;
  @ViewChildren('darlehen') darlehenList!: QueryList<any>;
  @ViewChild('darlehensbedarf') darlehensbedarf!: any;
  @ViewChild('darlehenBaustein') darlehenBaustein!: any;
  @Output() public nextItem: EventEmitter<any> = new EventEmitter<string>();
  @Output() public previousItem: EventEmitter<any> = new EventEmitter<string>();

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService,
    private translate: TranslateService,
    private mappingService: MappingService,
  ) { }

  ngAfterViewInit(): void {
    this.setInitState();
  }

  setInitState() {
    const status = this.dataQuery.getEntity(1)?.['bausteine'];
    const bausteine = this.dataQuery.getEntity(1)?.['darlehenBausteine'];
    this.localValue = status;
    if (!!status) {
      if (status === 'multiple') {
        this.contentService.setTilesContent(this.tiles, status, this.content);
      }
      this.tiles = this.contentService.setTileChecked(this.tiles, status);
    } else {
      this.contentService.resetTileStatus(this.tiles);
    }

    if (bausteine) {
      bausteine.map((item: any) => {
        this.checkValidity(item.id);
      });
    }
  }

  setValue(e: string) {
    this.localValue = e;
    this.daService.update(1, { bausteine: e });

    if (e === 'one') {
      this.daService.update(1, { anzahlBausteine: 1 });
    } else {
      this.contentService.setTilesContent(this.tiles, e, this.content);
      this.daService.update(1, { anzahlBausteine: 2 });
    }
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
  }

  setSwitch(e: number, type: string) {
    switch (type) {
      case 'anzahlBausteine': {
        this.daService.update(1, { anzahlBausteine: e });
      }
        break;
    }
  }

  setVisibility(i: number) {
    const bausteine = this.dataQuery.getEntity(1)?.['darlehenBausteine'];
    let darlehen: any[] = [];
    if (bausteine) {
      bausteine.map((darlehenBaustein: any) => {
        if (darlehenBaustein.id === i) {
          darlehenBaustein = { ...darlehenBaustein, 'isVisible': !darlehenBaustein.isVisible };
        }
        darlehen.push(darlehenBaustein);
      });
    }
    this.daService.update(1, { darlehenBausteine: darlehen });
  }

  updateBausteinDarlehen(e: any) {
    if (this.darlehenValue.value !== e.value || this.darlehenValue.i !== e.i) {
      const bausteine = this.dataQuery.getEntity(1)?.['darlehenBausteine'];
      let darlehenState: any;

      if (!!bausteine) {

        bausteine.map((baustein: any) => {
          // its the baustein that sent the event
          if (baustein.id === e.i) {
            // validate regex pattern
            const isValid = this.contentService.validatePattern(e.value, 'currency');

            // if there is no error, write the value into the redux store plus adjust baustein state
            if (isValid) {
              const stateChanges = [
                {
                  key: 'darlehensbetrag',
                  value: e.value
                },
                {
                  key: 'state',
                  value: 'warning'
                }
              ]
              darlehenState = this.contentService.updateNestedObjectMultiple('darlehenBausteine', stateChanges, e.i);
              setTimeout(() => { this.calculateDarlehen(e) }, 100);
              // if there are errors, determine error message  and write errors plus state into redux store
            } else {
              const stateChanges = [
                {
                  key: 'darlehenError',
                  value: true
                },
                {
                  key: 'darlehenErrorText',
                  value: 'COMMON.ERRORS.FEE'
                },
                {
                  key: 'state',
                  value: 'error'
                }
              ]
              darlehenState = this.contentService.updateNestedObjectMultiple('darlehenBausteine', stateChanges, e.i);
            }
            this.daService.update(1, { darlehenBausteine: darlehenState });
          }
        });
      }
      this.darlehenValue = e;
    }
  }

  calculateDarlehen(e: any) {
    const status = this.dataQuery.getEntity(1)?.['darlehensbedarf'];
    const bausteine = this.dataQuery.getEntity(1)?.['darlehenBausteine'];

    let darlehen = 0;
    let bausteineDarlehen = 0;
    let darlehenState;
    let stateChanges = [];

    if (!!status && bausteine) {

      bausteine.map((baustein: any) => {
        if (!!baustein.darlehensbetrag) {
          bausteineDarlehen = bausteineDarlehen + this.contentService.convertStringNumber(baustein.darlehensbetrag);
        }
      });

      // if bausteine darlehen is smaller or equal to sum that still needs to be distributed, update verbleibend in the redux store
      if (bausteineDarlehen <= this.contentService.convertStringNumber(status)) {
        darlehen = this.contentService.convertStringNumber(status) - bausteineDarlehen;
        this.daService.update(1, { darlehensbedarfVerbleibend: this.contentService.convertNumberString(darlehen) });
        stateChanges = [
          {
            key: 'darlehenError',
            value: false
          },
          {
            key: 'darlehenErrorText',
            value: this.errorDarlehenText
          },
          {
            key: 'state',
            value: 'warning'
          }
        ]
        // if bausteine darlehen is larger, update error text and error state 
      } else {
        stateChanges = [
          {
            key: 'darlehenError',
            value: true
          },
          {
            key: 'darlehenErrorText',
            value: 'COMMON.ERRORS.DARLEHENBEDARF'
          },
          {
            key: 'state',
            value: 'error'
          }
        ]
      }
      darlehenState = this.contentService.updateNestedObjectMultiple('darlehenBausteine', stateChanges, e.i);
      this.daService.update(1, { darlehenBausteine: darlehenState });
    }
    this.checkValidity(e.i);
  }

  getSelectedValue(i: number, stateType: string) {
    const type = this.contentService.getNestedValue('darlehenBausteine', 'type', i);
    let value: any;
    let name: string;
    if (!!type) {
      switch (type) {
        case 'ANNUITAETENDARLEHEN' : name = 'FINANCE_ASSEMBLY.BAUSTEINE.DROPDOWN.ITEM1';
        break;
        case 'FORWARDDARLEHEN' : name = 'FINANCE_ASSEMBLY.BAUSTEINE.DROPDOWN.ITEM2';
        break;
        case 'KFW_DARLEHEN' : name = 'FINANCE_ASSEMBLY.BAUSTEINE.DROPDOWN.ITEM3';
        break;
        case 'PRIVATDARLEHEN' : name = 'FINANCE_ASSEMBLY.BAUSTEINE.DROPDOWN.ITEM4';
        break;
        case 'ZWISCHENFINANZIERUNG' : name = 'FINANCE_ASSEMBLY.BAUSTEINE.DROPDOWN.ITEM5';
        break;
        case 'VARIABLES_DARLEHEN' : name = 'FINANCE_ASSEMBLY.BAUSTEINE.DROPDOWN.ITEM6';
        break;
      }



      this.dropdown.map((item: any) => {
        if (type === item.value) {
          if(stateType === 'selected') {
            value = {
              viewValue: name,
              value: type
            };
          } else {
            value = true;
          }
          
          
        }
      });
      // element = this.contentService.getSelectedItem(type, element);
    }
    return value
  }

  setSelectedValue(e: string, element: any, i: number) {
    setTimeout(() => {
      let name = element.name;
      let value = e;
      let darlehen: any[] = [];
      const status = this.dataQuery.getEntity(1)?.['darlehenBausteine'];

      if (!status) {
        const count = i + 1;
        darlehen.push({
          'id': i,
          'type': value,
          'darlehensbetrag': '',
          'darlehensbetragPrev': '',
          'bereitstellungszinsfreieZeitInMonaten': 0,
          'zinsbindungInJahren': 0,
          'tilgungsWunsch': '',
          'tilgungssatzInProzent': 0,
          'rate': 0,
          'sondertilgungJaehrlich': 0,
          'auszahlungszeitpunkt': '',
          'laufzeitInJahren': 0,
          'laufzeitInMonaten': 0,
          'tilgungsfreieAnlaufjahre': 0,
          'verwendung': '',
          'isVisible': true,
          'state': 'warning',
          'darlehenError': false,
          'darlehenErrorText': 'COMMON.ERRORS.FEE',
        })
      } else {
        let count = 0;
        status.map((darlehenBaustein: any) => {
          if (darlehenBaustein.id === i) {
            count++;
            darlehenBaustein = { ...darlehenBaustein, 'type': value };
          }
          darlehen.push(darlehenBaustein);
        });
        if (count === 0) {
          darlehen.push({
            'id': i,
            'type': value,
            'darlehensbetrag': '',
            'darlehensbetragPrev': '',
            'bereitstellungszinsfreieZeitInMonaten': 0,
            'zinsbindungInJahren': 0,
            'tilgungsWunsch': '',
            'tilgungssatzInProzent': 0,
            'rate': 0,
            'sondertilgungJaehrlich': 0,
            'auszahlungszeitpunkt': '',
            'laufzeitInJahren': 0,
            'laufzeitInMonaten': 0,
            'tilgungsfreieAnlaufjahre': 0,
            'verwendung': '',
            'isVisible': true,
            'state': 'warning',
            'darlehenError': false,
            'darlehenErrorText': 'COMMON.ERRORS.FEE',
          })
        }
      }
      this.daService.update(1, { darlehenBausteine: darlehen });
    }, 100)
  }

  checkValidity(i: number) {
    setTimeout(() => {
      const status = this.dataQuery.getEntity(1)?.['darlehenBausteine'];
      if (status) {
        const darlehen: any[] = [];
        status.map((item: any) => {
          if (item.state !== 'error') {
            if (item.type === 'ANNUITAETENDARLEHEN' || item.type === 'FORWARDDARLEHEN') {
              if (!!item.darlehensbetrag && !!item.tilgungsWunsch && (item.tilgungssatzInProzent || item.rate) && !!item.auszahlungszeitpunkt) {
                item = { ...item, state: 'success' }
              } else {
                item = { ...item, state: 'warning' }
              }
            } else if (item.type === 'KFW_DARLEHEN') {
              if (!!item.darlehensbetrag && item.zinsbindungInJahren && item.tilgungsfreieAnlaufjahre) {
                item = { ...item, state: 'success' }
              } else {
                item = { ...item, state: 'warning' }
              }
            } else if (item.type === 'PRIVATDARLEHEN') {
              if (!!item.darlehensbetrag && !!item.laufzeitInMonaten) {
                item = { ...item, state: 'success' }
              } else {
                item = { ...item, state: 'warning' }
              }
            } else {
              if (!!item.darlehensbetrag) {
                item = { ...item, state: 'success' }
              } else {
                item = { ...item, state: 'warning' }
              }
            }
          }

          darlehen.push(item);
        });
        let isValid = true;
        darlehen.map((item: any) => {
          if (item.state === 'warning' || item.state === 'error') {
            isValid = false;
          }
        })
        this.disabled = !isValid;
        this.daService.update(1, { darlehenBausteine: darlehen });
      }
    }, 200);
  }

  resetBausteine() {
    const status = this.dataQuery.getEntity(1)?.['darlehensbedarf'];
    this.daService.update(1, { bausteine: '' });
    this.daService.update(1, { anzahlBausteine: 0 });
    this.daService.update(1, { darlehenBausteine: [] });
    this.daService.update(1, { results: [] });
    this.daService.update(1, { resultsDone: false });
    this.daService.update(1, { darlehensbedarfVerbleibend: this.contentService.convertNumberString(status) });

    setTimeout(() => {
      this.setInitState();
      this.previousItem.emit(true);
    }, 100);

  }

  getResults() {
    this.daService.update(1, { results: [] });
    this.daService.update(1, { resultsDone: false });
    setTimeout(() => {
      const status = this.contentService.getValue('darlehensbedarfVerbleibend');

      if (this.contentService.convertStringNumber(status) !== 0) {
        const bausteine = this.contentService.getValue('darlehenBausteine');
        if(bausteine.length > 0) {
          bausteine.map((item: any) => {
            this.updateBausteinDarlehen({value: item.darlehensbetrag, i: item.id});
          })
        }
        this.darlehensbedarf.error = true;
      } else {
        this.nextItem.emit('assemblyDone');
      }
    }, 200)
  }

  processRequest() {
    setTimeout(() => {
      this.mappingService.mapReduxData().then((result: any) => {
        result.subscribe({
          next: (data: any) => {
            this.daService.update(1, { anfrageUuid: data.finanzierungsanfrageuuid });
          },
          error: (error: any) => console.log(error)
        });
      });
    }, 300)
  }
}
