import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-info-success',
  templateUrl: './info-success.component.html',
  styleUrls: ['./info-success.component.scss']
})
export class InfoSuccessComponent implements OnInit {
  public slideName: string = 'info-success-';
  next: string = 'residence';
  current: string = 'info-success-';
  webappType = environment.webappType;
  title: string = '';
  label: string = '';
  label2: string = '';
  image: string = '';

  customContent: any;
  grafik_25!: string;
  grafik_70!: string;
  grafik_90!: string;

  @Input() type!: string;
  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();

  constructor(
    public screen: LayoutService,
    private configurationQuery: ConfigurationQuery,
    private daNextSlideService: DaNextSlideService,
    private contentService: ContentService,
    private daService: DataService,
    private dataQuery: DataQuery,
  ) { }

  ngOnInit(): void {
    this.slideName = this.slideName + this.type;
    this.daService.setCurrentSlide(this.slideName);

    this.configurationQuery.configuration.subscribe((res: any) => {
      this.customContent = res;
      this.grafik_25 = this.customContent.bildmaterial_common.linkgrafikinfo25;
      this.grafik_70 = this.customContent.bildmaterial_common.linkgrafikinfo70;
      this.grafik_90 = this.customContent.bildmaterial_common.linkgrafikinfo90;
    });

    this.setText();
  }

  setText() {
    const shortcut = this.dataQuery.getEntity(1)?.['shortcut'];
    switch (this.type) {
      case '25':
        this.label = 'INFO_SLIDE_SUCCESS.TEXT_25';
        this.title = 'INFO_SLIDE_SUCCESS.TITLE_25';
        this.image = this.grafik_25;
        break;
      case '70':
        this.label = 'INFO_SLIDE_SUCCESS.TEXT_70_1';
        this.label2 = 'INFO_SLIDE_SUCCESS.TEXT_70_2';
        this.title = 'INFO_SLIDE_SUCCESS.TITLE_70';
        this.image = this.grafik_70;
        this.next = shortcut ? 'privatkredit' : 'personal';
        break;
      case '90':
        this.label = 'INFO_SLIDE_SUCCESS.TEXT_90';
        this.title = 'INFO_SLIDE_SUCCESS.TITLE_90';
        this.image = this.grafik_90;
        this.next = this.webappType === 'c' ? 'finance-requirements-forward' : 'finance-requirements';
        break;
      default:
        break;
    }
    this.contentService.setSlideIndexState(this.slideName, false, 'info');

  }

  setNav(e: string) {
    this.daNextSlideService.update(1, { next: this.next, timestamp: new Date });
    this.slideTo.emit(e);
  }

}
