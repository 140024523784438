<div class="da-upload-container">
    <lib-button 
        class="da-upload-list-button"
        [ngClass]="{ 'mobile' : screen.isMobile() }"
        name="list button"
        value="{{'UPLOAD.LIST' | translate}}" 
        (buttonValue)="docDownload()"
        width="300"
        iconRight="assets/images/filePdf.svg"
        [disabled]="false"
    ></lib-button>

    <app-file-upload (fileUploaded)="getValue($event)"></app-file-upload>

    <lib-button 
        class="da-upload-list-button"
        [ngClass]="{ 'mobile' : screen.isMobile() }"
        name="list button"
        value="{{'BUTTONS.SAVE' | translate}}" 
        (buttonValue)="screen.openInfo(loginLightSidebar)"
        width="300"
        iconRight="assets/images/chevronRight.svg"
        [disabled]="false"
    ></lib-button>
</div>

<ng-template #loginLightSidebar let-modal>
    <app-login-light-sidebar 
    [modal]="modal" 
    [showLoginLightExit]="false" 
    [updateLoginLightProgress]="userService.isLoggedInForLoginLight()"
    ></app-login-light-sidebar>
</ng-template>

