import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';

@Component({
  selector: 'app-info-error',
  templateUrl: './info-error.component.html',
  styleUrls: ['./info-error.component.scss']
})
export class InfoErrorComponent implements OnInit {
  public slideName: string = 'info-error-';
  next: string = '';
  title: string = 'INFO_SLIDE_ERROR.TITLE';
  label!: string;
  noNextButton: boolean = true;

 @Input() type!: string; 

 @Output() public slideTo:EventEmitter<any> = new EventEmitter<string>();
 
  constructor(
    private daService: DataService,
    private dataQuery: DataQuery,
  ) { }

  ngOnInit(): void {
    this.slideName = this.slideName + this.type;
    this.daService.setCurrentSlide(this.slideName);
    this.setText();
  }

  setText() {
    const shortcut = this.dataQuery.getEntity(1)?.['shortcut'];
    switch(this.type) {
      case 'project': 
        this.label = 'INFO_SLIDE_ERROR.PROJECT_ABROAD';
        break;
      case 'unclear': 
        this.label = 'INFO_SLIDE_ERROR.PROJECT_UNCLEAR';
        this.noNextButton = false;
        this.next = 'status-spec';
        break;
      case 'residence':
        this.label = 'INFO_SLIDE_ERROR.RESIDENCE_ABROAD';
        break;
      case 'schufa': 
        this.label = 'INFO_SLIDE_ERROR.SCHUFA';
        this.noNextButton = false;
        this.title = 'INFO_SLIDE_ERROR.TITLE_HINWEIS';
        this.next = 'participants';
        break;
      default: 
        this.label = '';
        break;
    }
  }

  setNav(e: string) {
    this.slideTo.emit(e);
  }

}
