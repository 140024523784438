
<lib-header-text
    class="da-title"
    label="{{title | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<div class="da-tiles-wrapper">
    <lib-tile-text
        label="{{ label | translate}}"
        size="18px"
        [convertMobile]="true"
        [ngStyle]="{'text-align': 'center'}"
    ></lib-tile-text>
</div>
<div class="da-buttons-wrapper">
    <app-nav-buttons
        class="da-buttons-group"
        [save]="false"
        [current]="slideName"
        [nextDisabled]="false"
        [next]="next"
        (slideTo)="setNav($event)"
        [noNextButton]="noNextButton"
        >
    </app-nav-buttons>
</div>
