<lib-header-text
    class="da-title"
    label="{{'MODERNIZATION.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>

<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
>
    <ng-template #content>
        <div class="da-tile-content-wrapper">
            <lib-tile-text
                size="16px"
                label="{{'MODERNIZATION.TILE_TEXT' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(jahrInfo)"
                [light]="true"
            ></lib-tile-text>
            <lib-input
                #objectYear
                label="{{'COMMON.LABELS.YEAR_SHORT' | translate}}"
                name="year"
                [error]="false"
                placeholder="2020"
                validationType="numbers"
                errorText="{{errorYear | translate}}"
                [touched]="!!contentService.getValue('objectModernisierungJahr')"
                [ngStyle]="{'z-index': '3'}"
                [inputDisabled]="inputDisabled"
                [value]="!!contentService.getValue('objectModernisierungJahr') ? contentService.getValue('objectModernisierungJahr') : ''"
                (change)="validateYear($event, objectYear)"
                (click)="testValid = true"
                [isCurrency]="false"
                [light]="true"
            ></lib-input>
            <lib-tile-text
                class="da-tile-text"
                size="16px"
                label="{{'MODERNIZATION.TILE_TEXT2' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(gradInfo)"
                [light]="true"
            ></lib-tile-text>
            <star-rating 
                #objectGrad
                class="da-white-stars"
                [starType]="'svg'"
                size="large"
                [numOfStars]="3" 
                [rating]="rating"
                [hoverEnabled]="true"
                (starClickChange)="setGrad($event)"
            ></star-rating>
        </div>
    </ng-template>
</lib-tile-group>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

<ng-template #jahrInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MODERNIZATION.INFO_HEADER' | translate}}" text="{{'MODERNIZATION.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #gradInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'MODERNIZATION.INFO_HEADER2' | translate}}" text="{{'MODERNIZATION.INFO_TEXT2' | translate}}"></app-info-sidebar>
</ng-template>
