export const footerMenuItems = [
    {
        "label": "FOOTER.IMPRESSUM",
        "link": "linkwebsite_impressum",
    },
    {
        "label": "FOOTER.DATENSCHUTZ",
        "link": "linkwebsite_datenschutz",
    },
    {
        "label": "FOOTER.AGB",
        "link": "linkwebsite_agb",
    },
    {
        "label": "FOOTER.COOKIES",
        "link": "cookie",
    },
    {
        "label": "FOOTER.GENDER",
        "link": "gender",
    },
]