<div class="da-warning" [ngClass]="{ 'mobile' : screen.isSmallest() }">
    <lib-state-icon state="warning"></lib-state-icon>
    <div class="da-warning-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-tile-text
        class="da-tile-header"
        size="16px"
        [convertMobile]="false"
        [ngStyle]="{'text-align': 'left'}"
        label="{{text | translate}}"
    ></lib-tile-text>
    <lib-button 
        *ngIf="showButton"
        name="complete button"
        value="{{buttonText | translate}}" 
        (buttonValue)="goNext(type)"
        width="250"
        iconRight="assets/images/chevronRight.svg"
    ></lib-button>
    </div>
</div>
