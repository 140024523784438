import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { switchesContent } from 'src/assets/content/switches';
import { tilesContent } from 'src/assets/content/tiles';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-erbbaurecht',
  templateUrl: './erbbaurecht.component.html',
  styleUrls: ['./erbbaurecht.component.scss']
})
export class ErbbaurechtComponent implements OnInit {
  public slideName: string = 'erbbaurecht';
  next: string = 'market-value';
  localValue!: string;
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  zinsError: string = 'COMMON.ERRORS.NUMBER';
  tiles = tilesContent['ERBBAURECHT'].items;
  switches = switchesContent['ERBBAURECHT'].items;
  today = new Date();
  todayYear = this.today.getFullYear()
  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('content') content!: TemplateRef<any>;
  @ViewChild('objectDate') objectDate!: any;
  @ViewChild('objectZins') objectZins!: any;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public contentService: ContentService,
  ) {}

  ngOnInit(): void {
    this.next = environment.webappType === 'c' ? this.next : 'info-success-70';
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.setStatus();
    });
  }

  setStatus() {
    this.disabled = true;
    const parent = this.dataQuery.getEntity(1)?.['objectErbbaurecht'];

    if (!!parent) {
      this.localValue = parent;
      this.tiles = this.contentService.setTileChecked(this.tiles, parent);
      if (parent !== 'KEIN_ERBBAURECHT') {
        this.tiles = this.contentService.setTilesContent(this.tiles, parent, this.content);
        this.setTileStatus();
      } else {
        this.contentService.setSlideIndexState(this.slideName, false, 'success');
        this.stateChange.emit(true);
        this.disabled = false;
      }
    }
    this.daNextSlideService.update(1, { disabled: this.disabled });
  };

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'next') {
        if (this.allState !== 'success') {
          this.showState = true;
          this.setTileStatus();
        } else {
          this.setTileStatus('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 100)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  setValue(e: string) {
    this.localValue = e;
    this.daService.update(1, { objectErbbaurecht: e });
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
    if (e !== 'KEIN_ERBBAURECHT') {
      this.contentService.setTilesContent(this.tiles, e, this.content);
      this.setTileStatus();
    } else {
      this.disabled = false;
      this.daService.resetValues({ objectErbbauLaufzeitBis: '' });
      this.daService.resetValues({ objectErbbauGrundstueckseigentuemer: '' });
      this.daService.resetValues({ objectErbbauzinsJaehrlich: '' });
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.setAllState('success', 'success');
      this.stateChange.emit(true);
      this.contentService.setNav('next', 'erbbaurecht', this.slideTo, this.next);
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }

  validateInputValue(e: any, element: any) {
    let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
    element.touched = !!value ? true : false;
    const isValid = this.contentService.validatePattern(value, element.validationType);
    const isValidAmount: any = this.contentService.minMax(value, 0, 999999);
    this.zinsError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';

    element.error = isValid && isValidAmount === true ? false : true;

    if (!element.error) {
      this.daService.update(1, { objectErbbauzinsJaehrlich: value });
    } else {
      this.contentService.setSlideIndexState(this.slideName, false, 'error');
      this.setAllState('error', 'ERROR');
      this.stateChange.emit(true);
      this.disabled = true;
    }
    setTimeout(() => { this.setTileStatus() });
  };

  validateYear(year: any, element: any) {
    const date = year.target ? year.target.value : year;
    this.objectDate.value = date;

    this.objectDate = this.contentService.validateYearValue(date, element, { min: this.todayYear, max: 2100 });
    if (!this.objectDate.error) {
      this.daService.update(1, { objectErbbauLaufzeitBis: date });
    } else {
      this.contentService.setSlideIndexState(this.slideName, false, 'error');
      this.setAllState('error', 'ERROR');
      this.stateChange.emit(true);
    }

    setTimeout(() => { this.setTileStatus() });
  }

  setSwitch(e: string, type: string) {
    this.daService.update(1, { objectErbbauGrundstueckseigentuemer: e });
    this.setTileStatus();
  }

  setTileStatus(type: string = 'default') {
    const status = this.dataQuery.getEntity(1);
    let valid = false;

    if (!!status) {
      valid = !!status['objectErbbauLaufzeitBis'] && !!status['objectErbbauGrundstueckseigentuemer'] && !!status['objectErbbauzinsJaehrlich'] ? true : false;
    };
    if (valid) {
      this.tiles = this.contentService.setTileStatus(this.tiles, this.localValue, 'success', 'success');
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.disabled = false;
      if (type === 'test') {
        this.testValid = false;
        this.inputDisabled = true;
      }
      this.setAllState('success', 'COMPLETE');
    } else {
      this.tiles = this.contentService.setTileStatus(this.tiles, this.localValue, 'warning', 'success');
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.setAllState('warning', 'INCOMPLETE');
      this.disabled = true;
      this.testValid = type === 'init' ? this.testValid : true;
    }
    this.stateChange.emit(true);

    if (this.objectDate && this.objectDate.error || this.objectZins && this.objectZins.error) {
      this.tiles = this.contentService.setTileStatus(this.tiles, this.localValue, 'error', 'success');
      this.contentService.setSlideIndexState(this.slideName, false, 'error');
      this.setAllState('error', 'ERROR');
      this.testValid = true;
      this.inputDisabled = false;
    }

    if (this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }
}
