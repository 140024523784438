import { Component, EventEmitter, HostListener, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-object-nutzung',
  templateUrl: './object-nutzung.component.html',
  styleUrls: ['./object-nutzung.component.scss']
})
export class ObjectNutzungComponent implements OnInit {
  public slideName: string = 'object-nutzung';
  next: string = 'condition-estate';
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  teilSizeError: string = 'COMMON.ERRORS.NUMBER';
  teilRentError: string = 'COMMON.ERRORS.NUMBER';
  fullRentError: string = 'COMMON.ERRORS.NUMBER';

  tiles = tilesContent['OBJECT_NUTZUNG'].items;
  subTiles = tilesContent['OBJECT_NUTZUNG_JA'].items;
  subtitle: string = 'OBJECT_NUTZUNG.SUB_TITLE1';
  localValue!: string;
  localValueSub!: string;
  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('contentTeil') contentTeil!: TemplateRef<any>;
  @ViewChild('contentFull') contentFull!: TemplateRef<any>;

  @ViewChild('objectTeilSize') objectTeilSize!: any;
  @ViewChild('objectTeilRent') objectTeilRent!: any;
  @ViewChild('objectFullRent') objectFullRent!: any;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService
  ) {}

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit() {
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.setStatus();
    });
  }

  setStatus() {
    this.disabled = true;
    const parentType = this.dataQuery.getEntity(1)?.['objectNutzungFuture'];

    if (!!parentType) {
      this.setFutureValue(parentType, 'init');
    }
    setTimeout(() => {
      this.validateParentContent('init');
      this.setNext();
    }, 100)
  }

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'next') {
        if (this.allState !== 'success') {
          this.showState = true;
          this.validateParentContent();
        } else {
          this.validateParentContent('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 100)
  }

  // on switch yes/no tiles
  setFutureValue(e: any, type: string = 'default') {
    this.daService.update(1, { objectNutzungFuture: e });
    this.localValue = e;
    this.subtitle = e === 'true' ? 'OBJECT_NUTZUNG.SUB_TITLE1' : 'OBJECT_NUTZUNG.SUB_TITLE2';
    this.subTiles = e === 'true' ? tilesContent['OBJECT_NUTZUNG_JA'].items : tilesContent['OBJECT_NUTZUNG_NEIN'].items
    this.tiles = this.contentService.setTileChecked(this.tiles, e);

    const status = this.dataQuery.getEntity(1)?.['objectNutzungwohnflaeche']; // EIGENGENUTZT, TEILVERMIETET, VERMIETET
    if (!!status) {
      this.localValueSub = status;
      this.setParentValue(status, false, type);
    }
    setTimeout(() => { this.screen.scrollToElement('da-secondary-container') }, 100);
  }

  // on switch tiles
  setParentValue(e: any, enableAutoJump: boolean = true, type: string = 'default') {
    this.daService.update(1, { objectNutzungwohnflaeche: e });
    this.localValueSub = e;
    this.subTiles = this.localValue === 'true' ? tilesContent['OBJECT_NUTZUNG_JA'].items : tilesContent['OBJECT_NUTZUNG_NEIN'].items
    this.contentService.setTileChecked(this.subTiles, e);

    if (e === 'EIGENGENUTZT') {
      this.disabled = false;
      this.testValid = false;
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.setAllState('success', 'COMPLETE');
      this.stateChange.emit(true);
      this.daNextSlideService.update(1, { disabled: this.disabled });
      if (enableAutoJump) { this.contentService.setNav('next', 'object-nutzung', this.slideTo, this.next); }
    } else {
      this.testValid = true;
      this.setContent(e, type);
    }
  }

  // set content for active tile
  setContent(e: string, type: string = 'default') {
    this.subTiles = this.contentService.setTilesContent(this.subTiles, 'TEILVERMIETET', this.contentTeil);
    this.subTiles = this.contentService.setTilesContent(this.subTiles, 'VERMIETET', this.contentFull);
    this.localValueSub = e;

    setTimeout(() => { this.validateParentContent(type) });
  }

  // validate input in text fields
  validateInputValue(e: any, element: any) {
    let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
    element.touched = !!value ? true : false;
    element.placeholder = '';
    const isValid = this.contentService.validatePattern(value, element.validationType);
    let isValidAmount: any;

    switch (element.name) {
      case 'teilSize': {
        const status = this.dataQuery.getEntity(1);
        const gesamtSize = this.contentService.convertStringNumber(status?.['objectGesamtflaeche'] ? status?.['objectGesamtflaeche'] : '0');
        isValidAmount = this.contentService.minMax(value, 25, (gesamtSize > 0 && gesamtSize <= 999) ? gesamtSize : 999);
        this.teilSizeError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
        this.teilSizeError = isValidAmount?.tooHigh && (gesamtSize > 0 && gesamtSize <= 999) ? 'COMMON.ERRORS.TOO_HIGH_RENT' : this.teilSizeError;
      };
        break;
      case 'teilRent': {
        isValidAmount = this.contentService.minMax(value, 50, 999999);
        this.teilRentError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
      };
        break;
      case 'fullRent': {
        isValidAmount = this.contentService.minMax(value, 50, 999999);
        this.fullRentError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
      };
        break;
    }

    element.error = isValid && isValidAmount === true ? false : true;

    if (!element.error) {
      this.storeInputValue(element);
    } else {
      this.validateParentContent();
    }
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  // validate values of active tile when switching tiles and set tile status accordingly
  validateParentContent(type: string = 'default') {
    this.disabled = true;
    let valid: string = 'warning';
    switch (this.localValueSub) {
      case 'TEILVERMIETET': {
        if (this.objectTeilSize.touched && this.objectTeilRent.touched) {
          valid = !this.objectTeilSize.error && !this.objectTeilRent.error ? 'success' : 'error';
        }
      }
        break;
      case 'VERMIETET': {
        if (this.objectFullRent.touched) {
          valid = !this.objectFullRent.error ? 'success' : 'error';
        }
      }
        break;
      case 'EIGENGENUTZT': 
        valid = 'success';
        break;
    }

    this.subTiles = this.contentService.setTileStatusChecked(this.subTiles, this.localValueSub, valid, true);
    this.contentService.setSlideIndexState(this.slideName, false, valid);
    this.stateChange.emit(true);

    switch (valid) {
      case 'error': {
        this.setAllState('error', 'ERROR');
        break;
      }
      case 'warning': {
        this.setAllState('warning', 'INCOMPLETE');
        break;
      }
      case 'success': {
        this.setAllState('success', 'COMPLETE');
        break;
      }
    }
    if (valid === 'success') {
      if (type === 'test') {
        this.inputDisabled = true;
        this.testValid = false;
      }
      this.disabled = false;
    } else {
      this.testValid = type === 'init' ? this.testValid : true;
    }
    if (this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: valid === 'success' ? false : true });
    }
    this.setNext();
  }

  // store Input values in redux
  storeInputValue(element: any) {
    switch (element.name) {
      case 'teilSize': {
        this.daService.update(1, { objectVermietetewohnflaeche: element.value });
      }
        break;
      case 'teilRent':
      case 'fullRent': {
        this.daService.update(1, { objectMieteinnahmenwohnflaeche: element.value });
      }
        break;
    }
    setTimeout(() => { this.validateParentContent() }, 100);
  }

  setNext() {
    const status = this.dataQuery.getEntity(1);

    if (!!status) {
      if (!!status['objectType'] && status['objectType'] === 'BAUGRUNDSTUECK') {
        this.next = 'parking';
      }
    }
  }
}
