import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DaClickpathQuery } from 'src/app/state/click-path/da-clickpath.query';
import { DaClickpathService } from 'src/app/state/click-path/da-clickpath.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';

@Component({
  selector: 'app-warning-bar',
  templateUrl: './warning-bar.component.html',
  styleUrls: ['./warning-bar.component.scss']
})
export class WarningBarComponent implements OnInit {
  next: string = '';

  @Input() text!: string;
  @Input() buttonText!: string;
  @Input() type!: string;
  @Input() showButton: boolean = false;
  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public uploadScreen: EventEmitter<any> = new EventEmitter<string>();
  constructor(
    public screen: LayoutService,
    private daNextSlideService: DaNextSlideService,
    private daClickpathService: DaClickpathService,
    private daClickpathQuery: DaClickpathQuery,
    private dataQuery: DataQuery,
    private daService: DataService,
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
  }

  goNext(typ: string) {
    if (typ === 'incomplete') {
      let progress = this.daClickpathQuery.getEntity(1)?.slideIndex;
      const shortcut = this.dataQuery.getEntity(1)?.['shortcut'];
      const participants = this.dataQuery.getEntity(1)?.['participants'];
      const projectPlan = this.dataQuery.getEntity(1)?.['projectPlan'];
      let count = 0;
      if (shortcut) {
        const excludedItems = [
          'object-detail',
          'object-nutzung',
          'condition-estate',
          'bauweise',
          'energy',
          'parking',
          'erbbaurecht',
          'personal',
          'children',
          'miete',
          'health-insurance',
          'vehicles'];

        if (!!projectPlan) {
          if (projectPlan !== 'NEUBAU') {
            excludedItems.push('object-category');
            excludedItems.push('modernization');
          } else {
            excludedItems.push('house-category');
          }
        }

        if (!!participants && participants !== 'allein') {
          excludedItems.push('additional-income');
          excludedItems.push('additional-income-partner');
          excludedItems.push('personal-partner');
          this.daService.resetValues({
            shortcut: false,
            haushaltPerson: '',
            geburtsdatum: '',
            staatsangehoerigkeit: '',
            familienstand: '',
            Beschaeftigung: '',
            probezeit: '',
            beschaeftigtSeit: '',
            beschaeftigungsstatus: '',
            anzahlGehaelterProJahr: '',
            gehalt: '',
            haushaltPersonPartner: '',
            geburtsdatumPartner: '',
            staatsangehoerigkeitPartner: '',
            familienstandPartner: '',
            BeschaeftigungPartner: '',
            probezeitPartner: '',
            beschaeftigtSeitPartner: '',
            beschaeftigungsstatusPartner: '',
            anzahlGehaelterProJahrPartner: '',
            gehaltPartner: '',
            objectType: !!projectPlan && projectPlan === 'NEUBAU' ? 'Haus' : '',
            objectUnitType: '',
            objectGesamtflaeche: '',
            objectNutzungwohnflaeche: '',
          });
        } else {
          excludedItems.push('additional-income-single');
          this.daService.resetValues({
            shortcut: false,
            haushaltPerson: '',
            geburtsdatum: '',
            staatsangehoerigkeit: '',
            familienstand: '',
            Beschaeftigung: '',
            probezeit: '',
            beschaeftigtSeit: '',
            beschaeftigungsstatus: '',
            anzahlGehaelterProJahr: '',
            gehalt: '',
            objectType: !!projectPlan && projectPlan === 'NEUBAU' ? 'Haus' : '',
            objectUnitType: '',
            objectGesamtflaeche: '',
            objectNutzungwohnflaeche: '',
          });
        }

        this.contentService.setBulkSlideIndex(excludedItems, false);

        if (progress) {
          progress.map((item: any) => {
            if (item.id === 'estate-status' && item.state === 'warning') {
              this.next = 'estate-status';
            }
          });
        }
        if (this.next !== 'estate-status') {
          this.next = 'participants';
        }
      } else {
        if (progress) {
          progress.map((item: any) => {
            if (item.state === 'warning' && !item.excluded) {
              if (count === 0) {
                this.next = item.id;
              }
              count++;
            }
          });
        }
      }
      if (!!this.next) {
        this.daNextSlideService.update(1, { next: this.next, timestamp: new Date });
        this.daClickpathService.updateClickPath('finance-requirements');
        this.slideTo.emit('next');
      }
    } else if (typ === 'upload') {
      this.uploadScreen.emit(true)
    }

  }

}
