<div class="da-tasks-wrapper">
    <div class="da-task-wrapper">
        <lib-tile-text
            class="da-task-title"
            label="{{'TASKS.PARTS1_HEADER' | translate}}"
            size="20px"
            [convertMobile]="true"
        ></lib-tile-text>
        <div class="da-tasks-row-container">
            <ng-container *ngFor="let item of rowsPart1; let num = index">
                <lib-row 
                *ngIf="part1All || num <= 1"
                label="{{item.label | translate}}"
                [button]="item.button"
                type="part1-{{num}}"
                (buttonValue)="getButtonValue($event)"
                (checkboxValue)="getCheckboxValue($event)">
            </lib-row>
            </ng-container>
            <div class="da-tasks-visual" *ngIf="rowsPart1.length > 2">
                <span (click)="part1All = !part1All" *ngIf="!part1All" class="da-more">{{'TASKS.MORE' | translate}}</span>
                <span (click)="part1All = !part1All" *ngIf="part1All" class="da-less">{{'TASKS.LESS' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="da-task-wrapper">
        <lib-tile-text
            class="da-task-title"
            label="{{'TASKS.PARTS2_HEADER' | translate}}"
            size="20px"
            [convertMobile]="true"
        ></lib-tile-text>
        <div class="da-tasks-row-container">
            <ng-container *ngFor="let item of rowsPart2; let num = index">
                <lib-row 
                    *ngIf="part2All || num <= 1"
                    label="{{item.label | translate}}"
                    [button]="item.button"
                    type="part2-{{num}}"
                    (buttonValue)="getButtonValue($event)"
                    (checkboxValue)="getCheckboxValue($event)">
                </lib-row>
            </ng-container>
            <div class="da-tasks-visual" *ngIf="rowsPart2.length > 2">
                <span (click)="part2All = !part2All" *ngIf="!part2All" class="da-more">{{'TASKS.MORE' | translate}}</span>
                <span (click)="part2All = !part2All" *ngIf="part2All" class="da-less">{{'TASKS.LESS' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="da-task-wrapper">
        <lib-tile-text
            class="da-task-title"
            label="{{'TASKS.PARTS3_HEADER' | translate}}"
            size="20px"
            [convertMobile]="true"
        ></lib-tile-text>
        <div class="da-tasks-row-container">
            <ng-container *ngFor="let item of rowsPart3; let num = index">
                <lib-row 
                    *ngIf="part3All || num <= 1"
                    label="{{item.label | translate}}"
                    [button]="item.button"
                    type="part3-{{num}}"
                    (buttonValue)="getButtonValue($event)"
                    (checkboxValue)="getCheckboxValue($event)">
                </lib-row>
            </ng-container>
            <div class="da-tasks-visual" *ngIf="rowsPart3.length > 2">
                <span (click)="part3All = !part3All" *ngIf="!part3All" class="da-more">{{'TASKS.MORE' | translate}}</span>
                <span (click)="part3All = !part3All" *ngIf="part3All" class="da-less">{{'TASKS.LESS' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="da-task-wrapper">
        <lib-tile-text
            class="da-task-title"
            label="{{'TASKS.PARTS4_HEADER' | translate}}"
            size="20px"
            [convertMobile]="true"
        ></lib-tile-text>
        <div class="da-tasks-row-container">
            <ng-container *ngFor="let item of rowsPart4; let num = index">
                <lib-row 
                    *ngIf="part4All || num <= 1"
                    label="{{item.label | translate}}"
                    [button]="item.button"
                    type="part4-{{num}}"
                    (buttonValue)="getButtonValue($event)"
                    (checkboxValue)="getCheckboxValue($event)">
                </lib-row>
            </ng-container>
            <div class="da-tasks-visual" *ngIf="rowsPart4.length > 2">
                <span (click)="part4All = !part4All" *ngIf="!part4All" class="da-more">{{'TASKS.MORE' | translate}}</span>
                <span (click)="part4All = !part4All" *ngIf="part4All" class="da-less">{{'TASKS.LESS' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="da-task-wrapper">
        <lib-tile-text
            class="da-task-title"
            label="{{'TASKS.PARTS5_HEADER' | translate}}"
            size="20px"
            [convertMobile]="true"
        ></lib-tile-text>
        <div class="da-tasks-row-container">
            <ng-container *ngFor="let item of rowsPart5; let num = index">
                <lib-row 
                    *ngIf="part5All || num <= 1"
                    label="{{item.label | translate}}"
                    [button]="item.button"
                    type="part5-{{num}}"
                    (buttonValue)="getButtonValue($event)"
                    (checkboxValue)="getCheckboxValue($event)">
                </lib-row>
            </ng-container>
            <div class="da-tasks-visual" *ngIf="rowsPart5.length > 2">
                <span (click)="part5All = !part5All" *ngIf="!part5All" class="da-more">{{'TASKS.MORE' | translate}}</span>
                <span (click)="part5All = !part5All" *ngIf="part5All" class="da-less">{{'TASKS.LESS' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="da-task-wrapper">
        <lib-tile-text
            class="da-task-title"
            label="{{'TASKS.PARTS6_HEADER' | translate}}"
            size="20px"
            [convertMobile]="true"
        ></lib-tile-text>
        <div class="da-tasks-row-container">
            <ng-container *ngFor="let item of rowsPart6; let num = index">
                <lib-row 
                    *ngIf="part6All || num <= 1"
                    label="{{item.label | translate}}"
                    [button]="item.button"
                    type="part6-{{num}}"
                    (buttonValue)="getButtonValue($event)"
                    (checkboxValue)="getCheckboxValue($event)">
                </lib-row>
            </ng-container>
            <div class="da-tasks-visual" *ngIf="rowsPart6.length > 2">
                <span (click)="part6All = !part6All" *ngIf="!part6All" class="da-more">{{'TASKS.MORE' | translate}}</span>
                <span (click)="part6All = !part6All" *ngIf="part6All" class="da-less">{{'TASKS.LESS' | translate}}</span>
            </div>
        </div>
    </div>
</div>



