import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';

@Component({
  selector: 'app-login-light-exit',
  templateUrl: './login-light-exit.component.html',
  styleUrls: ['./login-light-exit.component.scss', '../login-light-sidebar.component.scss']
})
export class LoginLightExitComponent implements OnInit {

  tel: string = '';
  email: string = '';

  @Input() userIsLoggedIn: boolean = false;
  @Output() public saveLoginLightProgress: EventEmitter<any> = new EventEmitter<any>();
  @Output() public updateLoginLightProgress: EventEmitter<any> = new EventEmitter<any>();
  @Output() public bookConsultationAppointment: EventEmitter<any> = new EventEmitter<any>();
  @Output() public logout: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private screen: LayoutService,
    private configurationQuery: ConfigurationQuery,
    private daService: DataService,
    private dataQuery: DataQuery,
    private contentService: ContentService
    ) { }

  ngOnInit(): void {
    this.setConfigVariables();
  }

  onCloseLoginLightSidebar() {
    const oldResult = this.dataQuery.getEntity(1)?.['results'];
    this.daService.update(1, { resultsLoading: false });
    this.daService.update(1, { results: this.contentService.getValue('expressSent') ? oldResult : [] });
    this.daService.update(1, { resultsDone: false });
    setTimeout(() => {
      this.logout.emit();
    })

  }

  handleSaveButton() {
    const oldResult = this.dataQuery.getEntity(1)?.['results'];
    this.daService.update(1, { resultsLoading: false });
    this.daService.update(1, { results: this.contentService.getValue('expressSent') ? oldResult : [] });
    this.daService.update(1, { resultsDone: false });
    setTimeout(() => {
      if (this.userIsLoggedIn) {
        this.onUpdateLoginLightProgress();
      } else {
        this.onSaveLoginLightProgress();
      }
    })
  }

  setConfigVariables() {
    this.configurationQuery.configuration.subscribe({
      next: (store) => {
        this.tel = store.kontakttelefonnummerkunden;
        this.email = store.kontaktemailkunden;
      },
      error: (err) => {
        console.warn('da-info: no configuration found.', err);
      }
    });
  }

  onSaveLoginLightProgress() {
    this.saveLoginLightProgress.emit();
  }

  onUpdateLoginLightProgress() {
    this.updateLoginLightProgress.emit();
  }

  onBookConsultationAppointment() {
    this.bookConsultationAppointment.emit();
  }

  isMobile() {
    return this.screen.isMobile();
  }

  isMobileOrSmallTablet() {
    return this.screen.isMobileOrSmallTablet();
  }
}
