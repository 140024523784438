import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';

@Component({
  selector: 'app-da-b-view',
  templateUrl: './da-b-view.component.html',
  styleUrls: ['./da-b-view.component.scss']
})
export class DaBViewComponent implements OnInit {
  customContent: any;

  constructor(
    private modalService: NgbModal,
    private renderer: Renderer2,
    private configurationQuery: ConfigurationQuery,
  ) { }

  ngOnInit(): void {
    // get custom content for each mandant
    this.configurationQuery.configuration.subscribe((res: any) => {
      this.customContent = res;

      setTimeout(() => { this.addElement(this.customContent.grundeinstellungencss); })
    });
  }

  @ViewChild('style', { static: false }) style!: ElementRef;

  addElement(value: string) {
    const customStyle: HTMLStyleElement = this.renderer.createElement('style');
    customStyle.innerHTML = value;
    this.renderer.appendChild(this.style.nativeElement, customStyle);
  }

  open(content: any, label: string) {
    document.body.style.overflowY = 'hidden';
    this.modalService.open(content, {
      scrollable: true,
      animation: true
     });
  }

}
