import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnChanges {
  previewText: string = 'PREVIEW.PREVIEW1';

  @Input() type!: string;
  constructor() { }

  ngOnChanges(): void {
    if (this.type === 'chapter1') {
      this.previewText = 'PREVIEW.PREVIEW1'
    } else {
      this.previewText = 'PREVIEW.PREVIEW2'
    }
  }

}
