import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss']
})
export class IncomeComponent {
  public slideName: string = 'upload-income';
  next: string = 'additional-income-single';
  disabled: boolean = false;
  container1State: string = 'optional';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();

  constructor(
    public daNextSlideService: DaNextSlideService,
    public contentService: ContentService,
    public screen: LayoutService,
    public dataQuery: DataQuery,
    public daService: DataService
  ) {
    this.daService.setCurrentSlide(this.slideName);
  }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  setContainerStatus(value: string) {
    setTimeout(() => {
      this.container1State = value;

      if (this.container1State === 'error') {
        this.disabled = true;
      } else {
        this.disabled = false;
      }

      this.daNextSlideService.update(1, { disabled: this.disabled });
      this.setNext();
    }, 100)
  }

  setNext() {
    const status = this.dataQuery.getEntity(1)?.['participants'];

    if (!!status && status !== 'allein') {
      this.next = 'additional-income';
      this.contentService.setSlideIndex('additional-income-single', true);
      this.contentService.setSlideIndex('additional-income', false);
    }
  }

}
