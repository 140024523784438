<div class="da-info-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
    <div class="da-info-text" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <div class="da-info-header" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                label="{{ title | translate}}"
                [size]="type === '25' ? '20px' : '38px'"
                [convertMobile]="true"
                [ngStyle]="{'display': 'flex', 'justify-content': 'center'}"
            ></lib-tile-text>
        </div>
        <lib-tile-text
            label="{{label | translate}}"
            size="20px"
            [convertMobile]="true"
            [ngStyle]="{'display': 'flex', 'justify-content': 'center'}"
        >
        </lib-tile-text>
        <lib-tile-text *ngIf="type === '70'"
            label="{{label2 | translate}}"
            size="20px"
            [convertMobile]="true"
            [ngStyle]="{'display': 'flex', 'justify-content': 'center'}"
        >
        </lib-tile-text>
    </div>
    <div class="da-info-image" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <img alt="digitaler assistent Fortschritt" [src]="image">
    </div>
</div>
<div class="da-buttons-wrapper">
    <app-nav-buttons
        class="da-buttons-group"
        [save]="true"
        [current]="slideName"
        [nextDisabled]="false"
        [next]="type === 'project' ? '' : next"
        (slideTo)="setNav($event)"
        [noNextButton]="type === 'project' ? true : false"
        >
    </app-nav-buttons>
</div>

