import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';

@Component({
  selector: 'app-house-detail',
  templateUrl: './house-detail.component.html',
  styleUrls: ['./house-detail.component.scss']
})
export class HouseDetailComponent implements OnInit {
  public slideName: string = 'house-detail';
  next: string = 'object-nutzung';
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  sizeError: string = 'COMMON.ERRORS.NUMBER';
  houseError: string = 'COMMON.ERRORS.NUMBER';

  fertighaus: string = '';
  wohnung: string = '';
  showWohnung: boolean = true;

  container1State: string = 'warning';
  container2State: string = 'warning';
  container3State: string = 'warning';
  container4State: string = 'warning';
  container5State: string = 'warning';
  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('objectEstateSize') objectEstateSize!: any;
  @ViewChild('objectHouseSize') objectHouseSize!: any;
  @ViewChild('objectYear') objectYear!: any;
  @ViewChild('objectFertighaus') objectFertighaus!: any;
  @ViewChild('objectWohnung') objectWohnung!: any;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService
  ) {}

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit() {
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.setStatus();
    });
  }

  setStatus() {
    const status = this.dataQuery.getEntity(1); 
    const hausTyp = this.dataQuery.getEntity(1)?.['houseType'];

    if(!!status) {
      if(status['objectGrundstuecksgroesse']) {
        this.objectEstateSize = this.contentService.validateReduxValue(status['objectGrundstuecksgroesse'], this.objectEstateSize);
      }
      if(status['objectGesamtflaeche']) {
        this.objectHouseSize = this.contentService.validateReduxValue(status['objectGesamtflaeche'], this.objectHouseSize);
      }
      if(status['objectBaujahr']) {
        this.objectYear = this.contentService.validateReduxValue(status['objectBaujahr'], this.objectYear);
      }
      if(status['objectFertighaus']) {
        this.fertighaus = status['objectFertighaus'];
      }
      if(status['objectEinliegerwohnungVorhanden']) {
        this.wohnung = status['objectEinliegerwohnungVorhanden'];
      }
    }

    if(hausTyp === 'Zweifamilienhaus') {this.showWohnung = false}

    setTimeout(() => { this.setContainerState('init') });
  }

  runValidation(e: string) {
    console.log(e)
    setTimeout(() => {
      if(e === 'next') {
        if(this.allState !== 'success') {
          this.showState = true;
          this.setContainerState();
        } else {
          this.setContainerState('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 100)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  validateInputValue(e: any, element: any) {
      let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
      element.touched = !!value ? true : false;
      element.placeholder = '';
      const isValid = this.contentService.validatePattern(value, element.validationType);
      const isValidAmount: any = this.contentService.minMax(value, 25, element.name === 'estateSize' ? 99999 : 999);
      if (element.name === 'estateSize') {
        this.sizeError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
      } else {
        this.houseError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
      }
      element.error = isValid && isValidAmount === true ? false : true;
      this.storeInputValue(element);
  }

  validateYear(year: any, element: any) {
      this.objectYear.value = year.target ? year.target.value : year;

      this.objectYear = this.contentService.validateYearValue(year.target ? year.target.value : year, element); 
      if (!this.objectYear.error) {
        this.storeInputValue(element);
      }

      setTimeout(() => { this.setContainerState() }, 100);
  }

  storeInputValue(element: any, e?: any) {
    switch (element.name) {
      case 'estateSize': this.daService.update(1, { objectGrundstuecksgroesse: element.value });
      break;
      case 'houseSize': this.daService.update(1, { objectGesamtflaeche: element.value });
      break;
      case 'year': this.daService.update(1, { objectBaujahr: element.value });
      break;
      case 'fertighaus': this.daService.update(1, { objectFertighaus: e });
      break;
      case 'wohnung': this.daService.update(1, { objectEinliegerwohnungVorhanden: e });
      break;
    }

    setTimeout(() => { this.setContainerState() });
  }

  setContainerState(type: string = 'default') {
    const status = this.dataQuery.getEntity(1);

    this.container1State = !!status?.['objectGrundstuecksgroesse'] && this.objectEstateSize.touched ? 'success' : 'warning';
    this.container2State = !!status?.['objectGesamtflaeche'] && this.objectHouseSize.touched ? 'success' : 'warning';
    this.container3State = !!status?.['objectBaujahr'] && this.objectYear.touched ? 'success' : 'warning';
    this.container4State = !!status?.['objectFertighaus'] ? 'success' : 'warning';
    this.container5State = !!status?.['objectEinliegerwohnungVorhanden'] || !this.showWohnung ? 'success' : 'warning';

    this.container1State = this.objectEstateSize.error ? 'error' : this.container1State;
    this.container2State = this.objectHouseSize.error ? 'error' : this.container2State;
    this.container3State = this.objectYear.error ? 'error' : this.container3State;

    if(this.container1State === 'warning' || 
       this.container2State === 'warning' || 
       this.container3State === 'warning' || 
       this.container4State === 'warning' ||
       this.container5State === 'warning') {
        this.contentService.setSlideIndexState(this.slideName, false, 'warning');
        this.setAllState('warning', 'INCOMPLETE');
        this.stateChange.emit(true);
    }

    if(this.container1State === 'error' || 
       this.container2State === 'error' || 
       this.container3State === 'error' || 
       this.container4State === 'error' ||
       this.container5State === 'error') {
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.setAllState('error', 'ERROR');
        this.stateChange.emit(true);
    }

    this.setDisabled(type);
  }

  setDisabled(type: string = 'default') {

    this.disabled = true;
    
    if(this.container1State === 'success' && 
       this.container2State === 'success' && 
       this.container3State === 'success' && 
       this.container4State === 'success' &&
       this.container5State === 'success') {
        if( type === 'test') {
          this.testValid = false;
          this.inputDisabled = true;
        }
        this.disabled = false;
        this.contentService.setSlideIndexState(this.slideName, false, 'success');
        this.setAllState('success', 'COMPLETE');
        this.stateChange.emit(true);
    } else {
      this.testValid = type === 'init' ? this.testValid : true;
    }

    if(this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }

}
