import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Data } from './data.model';

export interface DataState extends EntityState<Data> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'dataStore', resettable: true })

export class DataStore extends EntityStore<DataState> {

  constructor() {
    super();
  }
}
