<div class="da-bausteine-tile" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'isSmallest' : screen.isSmallest() }">
    <lib-header-text
    label="{{'FINANCE_ASSEMBLY.BAUSTEINE.VERWENDUNG.TITLE' | translate}}"
    info="true"
    (infoValue)="screen.openInfo(verwendungInfo)"
    size="20px"
    [convertMobile]="false"
    ></lib-header-text>
    <div class="dropdown-wrapper">
        <lib-dropdown 
            #verwendung
            name="verwendung"
            [selectItems]="dropdown" 
            [selectedItem]="getSelectedValue(verwendung) ?? dropdownSelectedItem"
            label=""
            (selectValue)="setSelectedValue($event)"
            [autoWidth]="true">
        </lib-dropdown>
    </div>
</div>

<ng-template #verwendungInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_ASSEMBLY.BAUSTEINE.VERWENDUNG.INFO_HEADER' | translate}}" text="{{'FINANCE_ASSEMBLY.BAUSTEINE.VERWENDUNG.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
