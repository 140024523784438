import { Component, HostListener, OnInit } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { LayoutService } from 'src/app/services/layout.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
import { UserService } from 'src/app/state/user/user.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  constructor(
    private configurationQuery: ConfigurationQuery,
    public screen: LayoutService,
    public userService: UserService
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
  }

  getValue(e: any) {
    // console.log(e)
  }

  docDownload() {
    this.configurationQuery.configuration.subscribe((res: any) => {
      if (res) {
        window.open(res.linkpdfunterlagencheckliste,'_blank', 'fullscreen=yes');
      }
    });
  }

}
