<div class="da-frame">
    <app-header (progressValue)="setCurrentSlide($event)" [currentSlide]="currentSlide" [daType]="environment.webappType" [stateChange]="stateChange" [accessChange]="accessChange"></app-header>
    <!-- Digitaler Assistent A -->
    <div *ngIf="topic === 'digitalAssistantA' || (webapp && webappType === 'a')">
        <app-da-a (currentSlide)="setCurrentSlide($event)" [name]="currentSlide" [userIsLoggedIn]="userIsLoggedIn" (stateChange)="setStateChange($event)"></app-da-a>
    </div>
    <!-- Digitaler Assistent B -->
    <div *ngIf="topic === 'digitalAssistantB' || (webapp && webappType === 'b')">
        <app-da-b (currentSlide)="setCurrentSlide($event)" [name]="currentSlide" [userIsLoggedIn]="userIsLoggedIn" (stateChange)="setStateChange($event)"></app-da-b>
    </div>
    <!-- Digitaler Assistent C -->
    <div *ngIf="topic === 'digitalAssistantC' || (webapp && webappType === 'c')">
        <app-da-c (currentSlide)="setCurrentSlide($event)" [name]="currentSlide" [userIsLoggedIn]="userIsLoggedIn" (stateChange)="setStateChange($event)"></app-da-c>
    </div>
    <span *ngIf="environment.buildEnv === 'local' || environment.buildEnv === 'development'">DA: {{webappType}}</span> 
    <app-footer [visible]="footerVisible"></app-footer>
    <div class="da-footer-buttons">
        <app-circle-button 
            name="menü button"
            orientation="bottom-left" 
            [svg]="menuIcon"
            type="menu"
            [background]="background"
            (buttonValue)="getButtonValue($event)"
        ></app-circle-button>  
        <app-circle-button 
            name="overview button"
            orientation="bottom-right" 
            svg="assets/images/grid2.svg"
            type="overview"
            [background]="background"
            (buttonValue)="getButtonValue($event, overview)"
        ></app-circle-button>
    </div>
</div>
<ng-template #overview let-modal>
    <app-overview 
    [modal]="modal" 
    ></app-overview>
</ng-template>

