<div class="da-bausteine-container resultate" [ngClass]="{ 'isSmallest' : screen.isSmallest() }">
    <!-- DESKTOP -->
    <!-- header-row -->
    <div class="da-container" *ngIf="!screen.isSmallest()" [ngClass]="{ 'is920' : screen.isSecondResultBreakpoint()}">
        <div class="da-container-header-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
            <div class="da-container-part">
                <div class="da-container-bausteine">
                    <div class="da-container-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet(), 'is920' : screen.isSecondResultBreakpoint() }">
                        <!-- logos -->
                        <div class="da-container-baustein logos" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                            <svg-icon 
                            *ngFor="let item of ergebnis.logos"
                            class="da-external-svg" 
                            [src]="item.link"></svg-icon>
                        </div>
                        <div class="da-container-bausteine-wrapper" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet(), 'is920' : screen.isSecondResultBreakpoint() }">
                            <!-- sollzins -->
                            <app-container-baustein showInfo="false"
                                item="Ø {{ergebnis.mischzins}} % p.a." 
                                itemText="{{'FINANCE_RESULTATE.LABELS.SOLLZINS_ALL' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- rate -->
                            <app-container-baustein showInfo="false"
                                item="{{ergebnis.gesamtrate}} €" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.RATE_ALL' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- tilgung -->
                            <app-container-baustein showInfo="false"
                                item="{{ergebnis.gesamttilgung}} %" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_ALL' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- jahre -->
                            <app-container-baustein showInfo="false"
                                item="{{ergebnis.gesamtzinsbindung}} J." 
                                itemText="{{'FINANCE_RESULTATE.LABELS.ZINSBINDUNG_ALL' | translate}}"
                                >
                            </app-container-baustein>
                        </div>
                    </div>
                </div>
            </div>
            <div class="da-container-part end" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                <!-- Button -->
                <div class="da-container-baustein-button">
                </div>
            </div>  
        </div>
        <div class="da-container-single-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
            <div class="da-container-part">
                <div class="da-container-bausteine">
                    <div class="da-container-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'isMultiple' : ergebnis.darlehen.length > 1, 'is920' : screen.isSecondResultBreakpoint() }" *ngFor="let item of ergebnis.darlehen">
                        <!-- logo -->
                        <div class="da-container-baustein logo" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                            <svg-icon class="da-external-svg" [src]="item.bankLogo"></svg-icon>
                        </div>
                        <div class="da-container-bausteine-wrapper" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet(), 'is920' : screen.isSecondResultBreakpoint() }">
                            <!-- empfehlen -->
                            <!-- <div class="da-container-baustein" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                                <star-rating 
                                    [starType]="'svg'"
                                    size="medium"
                                    [numOfStars]="3" 
                                    [rating]="item.rating"
                                    [hoverEnabled]="true"
                                    (starClickChange)="getValue($event)"
                                    ></star-rating>
                                <lib-tile-text
                                    class="da-button-text"
                                    size="12px"
                                    [thin]="true"
                                    [convertMobile]="false"
                                    label="{{'FINANCE_RESULTATE.LABELS.RECOMMEND' | translate}}"
                                ></lib-tile-text> 
                            </div> -->
                            <!-- sollzins -->
                            <app-container-baustein showInfo="false"
                                item="{{item.sollZins}} % p.a." 
                                itemText="{{'FINANCE_RESULTATE.LABELS.SOLLZINS' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- rate -->
                            <app-container-baustein showInfo="false"
                                item="{{item.rateMonatlich}} €" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.RATE' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- tilgung -->
                            <app-container-baustein showInfo="false"
                                item="{{item.anfaenglicheTilgung}} %" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- jahre -->
                            <app-container-baustein showInfo="false"
                                item="{{item.zinsBindung}} J." 
                                itemText="{{'FINANCE_RESULTATE.LABELS.ZINSBINDUNG' | translate}}"
                                >
                            </app-container-baustein>
                        </div>
                    </div>
                </div>
            </div>

            <div class="da-container-part end" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                <!-- Button -->
                <div class="da-container-baustein-button">
                </div>
                <div class="da-bausteine-accordion-button" (click)="setSwitchMeta('visible')">
                    <svg-icon *ngIf="ergebnis.isVisible" src="assets/images/chevronUp.svg"></svg-icon>
                    <svg-icon *ngIf="!ergebnis.isVisible" src="assets/images/chevronDown.svg"></svg-icon>
                </div>
            </div> 
        </div>  

    </div>

    <!-- MOBILE -->
    <!-- header-row -->
    <div class="da-container-mobile" *ngIf="screen.isSmallest()">
        <!--mehrere Bausteine-->
        <div class="da-container-header-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
            <div class="da-container-part top">
                <!-- logos -->
                <div class="da-container-baustein logos" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                    <svg-icon 
                    *ngFor="let item of ergebnis.logos"
                    class="da-external-svg" 
                    [src]="item.link"></svg-icon>
                </div>
            </div>
            <div class="da-container-part">
                <div class="da-container-bausteine">
                    <!-- sollzins -->
                    <app-container-baustein showInfo="false"
                        item="Ø {{ergebnis.mischzins}} % p.a." 
                        itemText="{{'FINANCE_RESULTATE.LABELS.SOLLZINS_ALL' | translate}}"
                        >
                    </app-container-baustein>
                    <!-- rate -->
                    <app-container-baustein showInfo="false"
                        item="{{ergebnis.gesamtrate}} €" 
                        itemText="{{'FINANCE_RESULTATE.LABELS.RATE_ALL' | translate}}"
                        >
                    </app-container-baustein>
                    <!-- tilgung -->
                    <app-container-baustein showInfo="false"
                        item="{{ergebnis.gesamttilgung}} %" 
                        itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_ALL' | translate}}"
                        >
                    </app-container-baustein>
                    <!-- jahre -->
                    <app-container-baustein showInfo="false"
                        item="{{ergebnis.gesamtzinsbindung}} J." 
                        itemText="{{'FINANCE_RESULTATE.LABELS.ZINSBINDUNG_ALL' | translate}}"
                        >
                    </app-container-baustein>
                </div>
            </div>
            <div class="da-container-part bottom">
                <!-- Button -->
                <div class="da-container-baustein-button">
                </div>
            </div>  
        </div>
        <!-- reservieren -->
        <div class="da-container-single-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }" *ngFor="let item of ergebnis.darlehen">
            <div class="da-container-part-top">
                <!-- logo -->
                <div class="da-container-baustein" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                    <svg-icon class="da-external-svg" [src]="item.bankLogo"></svg-icon>
                </div>
                <!-- empfehlen -->
                <!-- <div class="da-container-baustein" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }">
                    <star-rating 
                        [starType]="'svg'"
                        size="medium"
                        [numOfStars]="3" 
                        [rating]="item.rating"
                        [hoverEnabled]="true"
                        (starClickChange)="getValue($event)"
                        ></star-rating>
                    <lib-tile-text
                        class="da-button-text"
                        size="12px"
                        [thin]="true"
                        [convertMobile]="false"
                        label="{{'FINANCE_RESULTATE.LABELS.RECOMMEND' | translate}}"
                    ></lib-tile-text> 
                </div> -->
            </div>
            <div class="da-container-part-bottom">
                <app-container-baustein showInfo="false"
                    item="{{item.sollZins}} % p.a." 
                    itemText="{{'FINANCE_RESULTATE.LABELS.SOLLZINS' | translate}}"
                    >
                </app-container-baustein>
                <app-container-baustein showInfo="false"
                    item="{{item.zinsBindung}} J." 
                    itemText="{{'FINANCE_RESULTATE.LABELS.ZINSBINDUNG' | translate}}"
                    >
                </app-container-baustein>
                <app-container-baustein showInfo="false"
                    item="{{item.rateMonatlich}} €" 
                    itemText="{{'FINANCE_RESULTATE.LABELS.RATE' | translate}}"
                    >
                </app-container-baustein>
                <app-container-baustein showInfo="false"
                    item="{{item.anfaenglicheTilgung}} %" 
                    itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG' | translate}}"
                    >
                </app-container-baustein>
                <div class="da-container-baustein-button">
                </div>
            </div>
            <div class="da-container-part">
                <div 
                    class="da-bausteine-accordion-button" 
                    (click)="setSwitchMeta('visible')"
                    *ngIf="ergebnis.darlehen.length === 1 || item.id === ergebnis.darlehen.length - 1"
                    >
                    <svg-icon *ngIf="ergebnis.isVisible" src="assets/images/chevronUp.svg"></svg-icon>
                    <svg-icon *ngIf="!ergebnis.isVisible" src="assets/images/chevronDown.svg"></svg-icon>
                </div>
            </div> 
        </div>               
    </div>

    <!-- DESKTOP -->
    <!-- DETAILS -->
    <div 
        class="da-container-body"
        *ngIf="ergebnis.isVisible && !screen.isSmallest()">
            <!-- MEHRERE BAUSTEINE -->
        <app-index 
            [tiles]="ergebnis.fakeTiles" 
            [selectedTile]="ergebnis.selectedTile"
            [slideFactor]="tileSlideFactor"
            [slidePos]="ergebnis.tilePosition"
            (indexValue)="getIndex($event, ergebnis.fakeTiles, ergebnis.tilePosition)">
        </app-index>
        <div class="da-content-extended">
            <div class="da-content-extended-wrapper" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'small': screen.isSmallerThanChildContainer()}">
                <div 
                    class="da-tiles-wrapper" 
                    [ngClass]="{'mobile-wrap' : screen.isSmallest()}"
                    [ngStyle]="{'right': 'calc(-'+ screenPosition +' + '+ ergebnis.tilePosition +'px)'}">
                    <div class="da-container-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }" *ngFor="let item of ergebnis.darlehen">
                        <div class="da-container-inrow-row">
                            <!-- darlehen -->
                            <app-container-baustein showInfo="false"
                                item="{{item.darlehensBetrag}} €" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.DARLEHEN' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- Zins -->
                            <app-container-baustein showInfo="false"
                                item="{{item.effektivZins}} % p.a." 
                                itemText="{{'FINANCE_RESULTATE.LABELS.ZINS' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- Restschuld -->
                            <app-container-baustein showInfo="false"
                                item="{{item.restSchuld}} €" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.RESTSCHULD' | translate: {jahre: item.zinsBindung} }}"
                                >
                            </app-container-baustein>
                            <!-- Laufzeit -->
                            <app-container-baustein showInfo="false"
                                item="{{item.laufzeit}} J." 
                                itemText="{{'FINANCE_RESULTATE.LABELS.LAUFZEIT' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- Gueltig -->
                            <app-container-baustein showInfo="false"
                                item="{{item.dateGueltig}}" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.DATE_GUELTIG' | translate}}"
                                >
                            </app-container-baustein>
                        </div>
                        <div class="da-container-inrow-row">
                            <!-- tilgung -->
                            <app-container-baustein showInfo="false"
                                item="{{item.optionaleTilgung}} % p.a." 
                                itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_OPTIONAL' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- monate -->
                            <app-container-baustein showInfo="false"
                                item="{{item.zinsfreieMonate}}" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.ZINSFREI' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- wechsel -->
                            <app-container-baustein showInfo="false"
                                item="{{item.wechselTilgung}}" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_WECHSEL' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- bearbeitung -->
                            <app-container-baustein showInfo="false"
                                item="{{item.dauer}}" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.DAUER' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- tilgung beginn -->
                            <app-container-baustein showInfo="false"
                                item="{{item.beginnTilgung}}" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_DATE' | translate}}"
                                >
                            </app-container-baustein>
                        </div>
                        <div class="da-container-inrow-row">
                            <!-- type -->
                            <app-container-baustein showInfo="false"
                                item="{{item.typ}}" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.TYP.LABEL' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- name -->
                            <app-container-baustein showInfo="false"
                                item="{{item.bankName}}" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.NAME' | translate}}"
                                >
                            </app-container-baustein>
                            <!-- hinweis -->
                            <app-container-baustein showInfo="false"
                                [ngClass]="'long'"
                                item="{{'FINANCE_RESULTATE.LABELS.TIP' | translate}}" 
                                itemText="{{'FINANCE_RESULTATE.LABELS.TIP_TEXT' | translate: {date: item.hinweisDate} }}"
                                >
                            </app-container-baustein>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MOBILE -->
    <!-- DETAILS -->
    <div 
        class="da-container-body-mobile"
        *ngIf="ergebnis.isVisible && screen.isSmallest()">
        
        <!-- MEHRERE BAUSTEINE -->
        <app-index 
            [tiles]="ergebnis.fakeTiles" 
            [selectedTile]="ergebnis.selectedTile"
            [slideFactor]="tileSlideFactor"
            [slidePos]="ergebnis.tilePosition"
            (indexValue)="getIndex($event, ergebnis.fakeTiles, ergebnis.tilePosition)">
        </app-index>
        <div class="da-content-extended">
            <div class="da-content-extended-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest(), 'small': screen.isSmallerThanChildContainer()}">
                <div 
                    class="da-tiles-wrapper" 
                    [ngClass]="{'mobile-wrap' : screen.isSmallest()}"
                    [ngStyle]="{'right': 'calc(-'+ screenPosition +' + '+ ergebnis.tilePosition +'px)'}">
                    <div class="da-container-row" [ngClass]="{ 'isTablet' : screen.isFirstResultBreakpoint(), 'is1024' : screen.isMobileOrSmallTablet() }" *ngFor="let item of ergebnis.darlehen">
                        <!-- darlehen -->
                        <app-container-baustein showInfo="false"
                            item="{{item.darlehensBetrag}} €" 
                            itemText="{{'FINANCE_RESULTATE.LABELS.DARLEHEN' | translate}}"
                            >
                        </app-container-baustein>
                        <!-- Zins -->
                        <app-container-baustein showInfo="false"
                            item="{{item.effektivZins}} % p.a." 
                            itemText="{{'FINANCE_RESULTATE.LABELS.ZINS' | translate}}"
                            >
                        </app-container-baustein>
                        <!-- Restschuld -->
                        <app-container-baustein showInfo="false"
                            item="{{item.restSchuld}} €" 
                            itemText="{{'FINANCE_RESULTATE.LABELS.RESTSCHULD' | translate: {jahre: item.zinsBindung} }}"
                            >
                        </app-container-baustein>
                        <!-- Laufzeit -->
                        <app-container-baustein showInfo="false"
                            item="{{item.laufzeit}} J." 
                            itemText="{{'FINANCE_RESULTATE.LABELS.LAUFZEIT' | translate}}"
                            >
                        </app-container-baustein>
                        <!-- Gueltig -->
                        <app-container-baustein showInfo="false"
                            item="{{item.dateGueltig}}" 
                            itemText="{{'FINANCE_RESULTATE.LABELS.DATE_GUELTIG' | translate}}"
                            >
                        </app-container-baustein>
                        <!-- tilgung -->
                        <app-container-baustein showInfo="false"
                            item="{{item.optionaleTilgung}} % p.a." 
                            itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_OPTIONAL' | translate}}"
                            >
                        </app-container-baustein>
                        <!-- monate -->
                        <app-container-baustein showInfo="false"
                            item="{{item.zinsfreieMonate}}" 
                            itemText="{{'FINANCE_RESULTATE.LABELS.ZINSFREI' | translate}}"
                            >
                        </app-container-baustein>
                        <!-- wechsel -->
                        <app-container-baustein showInfo="false"
                            item="{{item.wechselTilgung}}" 
                            itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_WECHSEL' | translate}}"
                            >
                        </app-container-baustein>
                        <!-- bearbeitung -->
                        <app-container-baustein showInfo="false"
                            item="{{item.dauer}}" 
                            itemText="{{'FINANCE_RESULTATE.LABELS.DAUER' | translate}}"
                            >
                        </app-container-baustein>
                        <!-- tilgung beginn -->
                        <app-container-baustein showInfo="false"
                            item="{{item.beginnTilgung}}" 
                            itemText="{{'FINANCE_RESULTATE.LABELS.TILGUNG_DATE' | translate}}"
                            >
                        </app-container-baustein>
                        <!-- type -->
                        <app-container-baustein showInfo="false"
                            [ngClass]="'long'"
                            item="{{item.typ}}" 
                            itemText="{{'FINANCE_RESULTATE.LABELS.TYP.LABEL' | translate}}"
                            >
                        </app-container-baustein>
                        <!-- name -->
                        <app-container-baustein showInfo="false"
                            [ngClass]="'long'"
                            item="{{item.bankName}}" 
                            itemText="{{'FINANCE_RESULTATE.LABELS.NAME' | translate}}"
                            >
                        </app-container-baustein>
                        <!-- hinweis -->
                        <app-container-baustein showInfo="false"
                            [ngClass]="'long'"
                            item="{{'FINANCE_RESULTATE.LABELS.TIP' | translate}}" 
                            itemText="{{'FINANCE_RESULTATE.LABELS.TIP_TEXT' | translate: {date: item.hinweisDate} }}"
                            >
                        </app-container-baustein>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>
