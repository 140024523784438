import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { Data } from './data.model';
import { DataStore } from './data.store';
import { environment } from 'src/environments/environment';
import { DataQuery } from './data.query';
import { Subject } from 'rxjs';
import { daASliderIndex, SlideIndexInterface } from '../da-a-slide-index';
import { daBSliderIndex } from '../da-b-slide-index';
import { daCSliderIndex } from '../da-c-slide-index';


@Injectable({ providedIn: 'root' })
export class DataService {

  private url = environment.apiHost;
  private loginLightUuid!: string; // progressId = uuid für login light - Anmeldung
  private currentSlide!: string;
  private $currentProgressIndex: Subject<number> = new Subject();
  private clickpath: string[] = [];
  private nextSlide: string = '';

  constructor(
    private dataStore: DataStore,
    private dataQuery: DataQuery,
  ) {
  }

  add(data: Data) {
    this.dataStore.add(data);
  }

  update(id: ID, data: Partial<Data>) {
    this.dataStore.update(id, data);
  }

  remove(id: ID) {
    this.dataStore.remove(id);
  }

  // reset multiple values
  resetValues(fields: any) {
    this.update(1, fields)
  }

  refill(dataObject: any) {
    this.add({ id: 1 });
    for (let key of Object.keys(dataObject)) {
      let data = { [key]: dataObject[key] };
      this.update(1, data);
    }
  }

  // get DA Type
  getSliderIndexByType(type: string) {
    let sliderIndex;
    switch (type) {
      case 'a': {
        sliderIndex = daASliderIndex;
        break;
      }
      case 'b': {
        sliderIndex = daBSliderIndex;
        break;
      }
      case 'c': {
        sliderIndex = daCSliderIndex;
        break;
      }
    }
    return sliderIndex;
  }

  setLoginLightUuid(progressId: any) {
    this.loginLightUuid = progressId;
  }

  getLoginLightUuid() {
    return this.loginLightUuid;
  }

  getStoreData() {
    return this.dataQuery.selectAll({ asObject: true });
  }

  setCurrentSlide(slideName: string) {
    this.nextSlide = slideName;
    const sliderIndex: SlideIndexInterface[] = this.getSliderIndexByType(environment.webappType) as SlideIndexInterface[];
    let slideData = sliderIndex.find(x => x.id === slideName);
    if (slideData?.progressIndex) {
      this.setCurrentProgressIndex(slideData?.progressIndex);
    }
  }

  setClickpath(clickpath: string[]) {
    this.clickpath = clickpath;
  }

  getClickpath() {
    return this.clickpath;
  }

  setNextSlide(nextSlide: string) {
    this.nextSlide = nextSlide;
  }

  getNextSlide() {
    return this.nextSlide;
  }

  getCurrentProgressIndex() {
    return this.$currentProgressIndex.asObservable();
  }

  setCurrentProgressIndex(index: number) {
    this.$currentProgressIndex.next(index);
  }
}
