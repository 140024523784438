import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Debounce } from 'lodash-decorators';
import { LayoutService } from 'src/app/services/layout.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() visible: boolean = false;
  @ViewChild('copyright') copyright!: ElementRef;
  @ViewChild('co2') co2!: ElementRef;
  showLabel: boolean = false;
  snippet = 'https://consent.cookiebot.com/485ac99f-ee30-467e-bab1-4716f29847dc/cd.js';

  constructor(
    public screen: LayoutService,
    private translate: TranslateService,
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
    onWindowResize() {
      this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
    
  }

  public loadScript() {
    let node = document.createElement('script');
    node.src = this.snippet;
    node.type = 'text/javascript';
    node.async = true;
    node.id = 'CookieDeclaration';
    document.getElementsByTagName('body')[0].appendChild(node);
    console.log(document.getElementsByTagName('div'))
}

  openLink() {
    window.open(`https://${this.translate.instant('FOOTER.LINK')}`);
  }

}
