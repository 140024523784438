import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { LayoutService } from 'src/app/services/layout.service';
import { DaClickpathService } from 'src/app/state/click-path/da-clickpath.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';

@Component({
  selector: 'app-start-a',
  templateUrl: './start-a.component.html',
  styleUrls: ['./start-a.component.scss']
})
export class StartAComponent implements OnInit{
  public slideName: string = 'start-da';
  grafik!: string;

  @Output() public slideNext:EventEmitter<any> = new EventEmitter<string>();

  constructor(
    private daClickpathService: DaClickpathService,
    private daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    private configurationQuery: ConfigurationQuery,
  ) {}

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
    onWindowResize() {
      this.screen.updateScreenSizes();
  }

  ngOnInit() {
    this.daService.setCurrentSlide(this.slideName);
    
    this.configurationQuery.configuration.subscribe((res: any) => {
      this.grafik = res.bildmaterial_da_a?.linkgrafikstart;
    });
  }

  goTo() {
    this.daNextSlideService.update(1, { next: 'estate-status', timestamp: new Date });
    this.daClickpathService.updateClickPath(this.slideName);
    this.slideNext.emit();
  }

  getInfo(type: any) {
    this.screen.openInfo(type)
  }
}
