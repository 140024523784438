<lib-header-text
    class="da-title"
    label="{{'PROPERTY_INFORMATION.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<div class="da-index">
    <app-index 
        [tiles]="fakeTiles" 
        [selectedTile]="selectedTileValue"
        [slideFactor]="tileSlideFactor"
        [slidePos]="tilePosition"
        [isNumber]="true"
        (indexValue)="getIndex($event)">
    </app-index>
</div>
<div class="da-content-extended">
    <div class="da-content-extended-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest()}">
        <div 
            class="da-tiles-wrapper with-slider" 
            [ngClass]="{'mobile-wrap' : screen.isSmallest()}"
            [ngStyle]="{'right': 'calc('+ screenPosition +' + '+ tilePosition +'px)'}">
            <ng-container *ngIf="fakeTiles && fakeTiles.length > 0">
                <ng-container *ngFor="let fakeTile of fakeTiles; let num = index">
                    <div
                        *ngIf="!fakeTile.invisible"
                        class="da-container" 
                        (click)="getIndex(num)"
                        [ngClass]="{ 'mobile' : screen.isSmallest() }"
                        >
                        <lib-state-icon [state]="!!fakeTile.state ? fakeTile.state : 'warning'"></lib-state-icon>
                        <ng-container *ngIf="!fakeTile.owner">
                            <!-- DELETE -->
                            <lib-header-button
                                
                                class="da-content-delete-button"
                                icon="assets/images/trash.svg"
                                (buttonValue)="showDeleteDialog(num)"
                                ></lib-header-button>
                            <div class="da-content-delete-dialog">
                                <lib-dialog 
                                    *ngIf="contentService.getNestedObject('bestehendeImmobilien', 'loeschen', num)"
                                    [content]="content"
                                    [autoWidth]="true"
                                    >
                                    <ng-template #content>
                                        <lib-tile-text
                                            class="da-tile-header"
                                            size="16px"
                                            [convertMobile]="true"
                                            [light]="true"
                                            label="{{'PROPERTY_INFORMATION.DIALOG' | translate}}"
                                        ></lib-tile-text>
                                        <lib-button-group
                                            [buttons]="buttons"
                                            (buttonValue)="deleteImmobilie($event, num)"
                                            [light]="true"
                                        ></lib-button-group>
                                    </ng-template>
                                </lib-dialog>
                            </div>
                        </ng-container>

                        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                            <svg-icon class="da-tile-icon" [src]="fakeTile.image"></svg-icon>
                            <lib-tile-text
                                *ngIf="!fakeTile.owner"
                                class="da-tile-header"
                                size="38px"
                                [convertMobile]="true"
                                label="{{contentService.getNestedValue('bestehendeImmobilien', 'name', num) | translate: { count : num } }}"
                            ></lib-tile-text>
                            <lib-tile-text
                                *ngIf="fakeTile.owner"
                                class="da-tile-header"
                                size="38px"
                                [convertMobile]="true"
                                label="{{'PROPERTY_INFORMATION.PROPERTY.MAIN' | translate }}"
                            ></lib-tile-text>

                            <!-- ist zu finanzierende Immobilie (DA-C) -->
                            <ng-container *ngIf="fakeTile.owner">
                                <div class="da-tile-input-container">
                                    <lib-input
                                        [inputDisabled]="true"
                                        label="{{'PROPERTY_INFORMATION.LABELS.LABEL1' | translate}}"
                                        [value]="!!asset.type ? asset.type : ''"></lib-input>
                                </div>
                                <div class="da-tile-input-container">
                                    <lib-input
                                        [inputDisabled]="true"
                                        label="{{'PROPERTY_INFORMATION.LABELS.LABEL2' | translate}}"
                                        unit="€"
                                        [value]="!!asset.marktwert ? asset.marktwert : ''"></lib-input>
                                </div>
                                <div class="da-tile-input-container">
                                    <lib-input
                                        [inputDisabled]="true"
                                        label="{{'PROPERTY_INFORMATION.LABELS.LABEL3' | translate}}"
                                        value="{{asset.nutzung | translate}}"></lib-input>
                                </div>
                                <div class="da-tile-input-container text-area">
                                    <lib-text-area
                                        [inputDisabled]="true"
                                        [multiLine]="true"
                                        label="{{'PROPERTY_INFORMATION.LABELS.LABEL4' | translate}}"
                                        [value]="asset.address">
                                    </lib-text-area>
                                </div>
                                <div class="da-tile-input-container text-area">
                                    <lib-text-area
                                        [inputDisabled]="true"
                                        [multiLine]="true"
                                        label="{{'PROPERTY_INFORMATION.LABELS.LABEL5' | translate}}"
                                        value="{{asset.vorhaben | translate}}"></lib-text-area>
                                </div>
                                <lib-tile-text
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'PROPERTY_INFORMATION.TILES.TEXT1' | translate}}"
                                ></lib-tile-text>
                                <lib-toggle
                                    #restschuldVorhanden
                                    label1="{{'COMMON.TRUE' | translate}}"
                                    label2="{{'COMMON.FALSE' | translate}}"
                                    name="restschuldVorhanden"
                                    value1="restschuldVorhanden"
                                    value2="keinerRestschuldVorhanden"
                                    [localValue]="contentService.getValue('restschuldVorhanden')"
                                    (switchValue)="setSwitch($event, 'restschuldVorhanden', fakeTile.value, 0)"
                                ></lib-toggle>
                            </ng-container>

                            <!-- BESTANDSIMMOBILIEN -->
                            <ng-container *ngIf="!fakeTile.owner">
                                <div class="da-tile-input-container">
                                    <lib-dropdown 
                                        #objectArt
                                        name="objectArt{{num}}"
                                        [selectItems]="dropdownObjektArt" 
                                        [selectedItem]="getSelectedItem(num) ? getSelectedItem(num) : {'value': '', 'viewValue': 'COMMON.SELECT'}"
                                        label="{{'PROPERTY_INFORMATION.LABELS.LABEL1' | translate}}"
                                        [success]="!!getSelectedItem(num)"
                                        (selectValue)="getSelectedValue($event, num, fakeTile.value)"
                                        [autoWidth]="true">
                                    </lib-dropdown>
                                </div>
                                <div class="da-tile-input-container">
                                    <lib-input
                                        #marktwert
                                        #inputs
                                        label="{{'PROPERTY_INFORMATION.LABELS.LABEL2' | translate}}"
                                        name="marktwert{{num}}"
                                        [error]="false"
                                        errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                                        placeholder="0,00"
                                        unit="€"
                                        [inputDisabled]="inputDisabled"
                                        (click)="testValid = true"
                                        [decimals]="0"
                                        [touched]="!!setLocalValue(num, 'marktwert')"
                                        [value]="!!setLocalValue(num, 'marktwert') ? setLocalValue(num, 'marktwert') : ''"
                                        (change)="validateInput($event, 'marktwert', num, fakeTile.value)"
                                    ></lib-input>
                                </div>
                                <div class="da-tile-input-container" *ngIf="!fakeTile.family">
                                    <lib-input
                                        [inputDisabled]="true"
                                        label="{{'PROPERTY_INFORMATION.LABELS.LABEL3' | translate}}"
                                        [value]="fakeTile.nutzung"></lib-input>
                                </div>
                                <div class="da-tile-input-container dropdown" *ngIf="fakeTile.family">
                                    <lib-dropdown 
                                        #objectNutzung
                                        name="objectNutzung{{num}}"
                                        [selectItems]="dropdownObjektnutzung" 
                                        [selectedItem]="getSelectedItem(num, true) ? getSelectedItem(num, true) : {'value': '', 'viewValue': 'COMMON.SELECT'}"
                                        label="{{'PROPERTY_INFORMATION.LABELS.LABEL3' | translate}}"
                                        [success]="!!getSelectedItem(num, true)"
                                        (selectValue)="getSelectedValue($event, num, fakeTile.value, true)"
                                        [autoWidth]="true">
                                    </lib-dropdown>
                                </div>
                                
                                <ng-container *ngIf="setLocalValue(num, 'objektnutzung') !== 'EIGENGENUTZT' && setLocalValue(num, 'objektnutzung') !== '' && !fakeTile.family">
                                    <div class="da-tile-input-container">
                                        <lib-input
                                            #kaltmiete
                                            #inputs
                                            label="{{'PROPERTY_INFORMATION.LABELS.LABEL9' | translate}}"
                                            name="kaltmiete{{num}}"
                                            [error]="false"
                                            errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                                            placeholder="0,00"
                                            unit="€"
                                            [inputDisabled]="inputDisabled"
                                            (click)="testValid = true"
                                            [touched]="!!setLocalValue(num, 'kaltmiete')"
                                            [value]="!!setLocalValue(num, 'kaltmiete') ? setLocalValue(num, 'kaltmiete') : ''"
                                            (change)="validateInput($event, 'kaltmiete', num, fakeTile.value, 'kaltmiete' + num)"
                                        ></lib-input>
                                    </div>
                                </ng-container>
                                <div class="da-tile-input-container">
                                    <lib-input-button
                                        #adresse
                                        #inputs
                                        label="{{'PROPERTY_INFORMATION.LABELS.LABEL4' | translate}}"
                                        name="adresse{{num}}"
                                        type="adresse"
                                        [value]="getAdresse(num)"
                                        [touched]="getAdresse(num) ? true : false"
                                        (inputValue)="getAdressLightbox(num, adressInput, fakeTile.value)"
                                    ></lib-input-button>
                                </div>
                                <lib-tile-text *ngIf="!fakeTile.family"
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'PROPERTY_INFORMATION.TILES.TEXT2' | translate}}"
                                    info="true"
                                    (infoValue)="screen.openInfo(financeInfo)"
                                ></lib-tile-text>
                                <lib-tile-text *ngIf="fakeTile.family"
                                    class="da-tile-header"
                                    size="20px"
                                    [convertMobile]="true"
                                    label="{{'PROPERTY_INFORMATION.TILES.TEXT5' | translate}}"
                                    info="true"
                                    (infoValue)="screen.openInfo(financeFamilyInfo)"
                                ></lib-tile-text>
                                <div class="da-tile-input-container">
                                    <lib-toggle
                                        #finanzierung
                                        label1="{{'COMMON.TRUE' | translate}}"
                                        label2="{{'COMMON.FALSE' | translate}}"
                                        name="finanzierung{{num}}"
                                        value1="VORHANDENE_BESTEHENDE_DARLEHEN"
                                        value2="KEINE_BESTEHENDEN_DARLEHEN"
                                        [localValue]="setLocalValue(num, 'finanzierung')"
                                        (switchValue)="setSwitch($event, 'finanzierung', fakeTile.value, num)"
                                    ></lib-toggle>
                                </div>
                                <ng-container *ngIf="setLocalValue(num, 'finanzierung') === 'VORHANDENE_BESTEHENDE_DARLEHEN'">
                                    <div class="da-tile-input-container">
                                        <lib-input
                                            #restschuld
                                            #inputs
                                            label="{{'PROPERTY_INFORMATION.LABELS.LABEL6' | translate}}"
                                            name="restschuld{{num}}"
                                            [error]="false"
                                            errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                                            placeholder="0,00"
                                            unit="€"
                                            [inputDisabled]="inputDisabled"
                                            (click)="testValid = true"
                                            [touched]="!!setLocalValue(num, 'restschuld')"
                                            [value]="!!setLocalValue(num, 'restschuld') ? setLocalValue(num, 'restschuld') : ''"
                                            (change)="validateInput($event, 'restschuld', num, fakeTile.value, 'restschuld' + num)"
                                        ></lib-input>
                                    </div>
                                    <div class="da-tile-input-container">
                                        <lib-input
                                            #darlehen
                                            #inputs
                                            label="{{'PROPERTY_INFORMATION.LABELS.LABEL7' | translate}}"
                                            name="darlehen{{num}}"
                                            [error]="false"
                                            errorText="{{errorDarlehen | translate}}"
                                            placeholder="0,00"
                                            unit="€"
                                            [inputDisabled]="inputDisabled"
                                            (click)="testValid = true"
                                            [touched]="!!setLocalValue(num, 'darlehen')"
                                            [value]="!!setLocalValue(num, 'darlehen') ? setLocalValue(num, 'darlehen') : ''"
                                            (change)="validateInput($event, 'darlehen', num, fakeTile.value, 'darlehen' + num)"
                                        ></lib-input>
                                    </div>
                                    <div class="da-tile-input-container" *ngIf="!fakeTile.family">
                                        <lib-input
                                            #rate
                                            #inputs
                                            label="{{'PROPERTY_INFORMATION.LABELS.LABEL8' | translate}}"
                                            name="rate{{num}}"
                                            [error]="false"
                                            errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                                            placeholder="0,00"
                                            unit="€"
                                            [inputDisabled]="inputDisabled"
                                            (click)="testValid = true"
                                            [touched]="!!setLocalValue(num, 'rate')"
                                            [value]="!!setLocalValue(num, 'rate') ? setLocalValue(num, 'rate') : ''"
                                            (change)="validateInput($event, 'rate', num, fakeTile.value, 'rate' + num)"
                                        ></lib-input>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="setLocalValue(num, 'objektArt') === 'BAUGRUNDSTUECK' && contentService.getValue('projectPlan') === 'NEUBAU' && !fakeTile.family">
                                    <lib-tile-text
                                        class="da-tile-header"
                                        size="20px"
                                        [convertMobile]="true"
                                        label="{{'PROPERTY_INFORMATION.TILES.TEXT3' | translate}}"
                                    ></lib-tile-text>
                                    <lib-toggle
                                        #useForProject
                                        label1="{{'COMMON.TRUE' | translate}}"
                                        label2="{{'COMMON.FALSE' | translate}}"
                                        name="useForProject"
                                        value1="ja"
                                        value2="nein"
                                        [localValue]="setLocalValue(num, 'useForProject')"
                                        (switchValue)="setSwitch($event, 'useForProject', fakeTile.value, num)"
                                    ></lib-toggle>
                                    <ng-container *ngIf="setLocalValue(num, 'finanzierung') === 'VORHANDENE_BESTEHENDE_DARLEHEN'">
                                        <lib-tile-text
                                            class="da-tile-header"
                                            size="20px"
                                            [convertMobile]="true"
                                            label="{{'PROPERTY_INFORMATION.TILES.TEXT4' | translate}}"
                                        ></lib-tile-text>
                                        <lib-toggle-vertical
                                            #umschulden
                                            [options]="switchUmschulden"
                                            name="umschulden"
                                            [localValue]="setLocalValue(num, 'umschulden')"
                                            (switchValue)="setSwitch($event, 'umschulden', fakeTile.value, num)"
                                        ></lib-toggle-vertical>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </ng-container> 
            </ng-container>
        </div>
    </div>
</div>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

<!-- <ng-template #delete let-modal>
    <lib-button value="test" (buttonValue)="deleteImmobilie($event, 1)"></lib-button>
</ng-template> -->

<ng-template #financeInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'PROPERTY_INFORMATION.INFO_HEADER1' | translate}}" text="{{'PROPERTY_INFORMATION.INFO_TEXT1' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #financeFamilyInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'PROPERTY_INFORMATION.INFO_HEADER2' | translate}}" text="{{'PROPERTY_INFORMATION.INFO_TEXT2' | translate}}"></app-info-sidebar>
</ng-template>

<ng-template #adressInput let-modal>
    <app-address-sidebar [modal]="modal" [num]="objectNumber" (adressDone)="getValue($event, 'adresse', objectNumber, '')"></app-address-sidebar>
</ng-template>
