<div class="da-bausteine-tile" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'isSmallest' : screen.isSmallest() }">
    <lib-header-text
    label="{{title | translate}}"
    info="true"
    (infoValue)="screen.openInfo(laufzeitInfo)"
    size="20px"
    [convertMobile]="false"
    ></lib-header-text>
    <lib-toggle-vertical
        #fixedL
        [options]="switches"
        [localValue]="type !== 'KFW_DARLEHEN' ? contentService.getNestedObject('darlehenBausteine', 'laufzeitInMonaten', num) : contentService.getNestedObject('darlehenBausteine', 'laufzeitInJahren', num)"
        name="fixedL{{num}}"
        (switchValue)="setSwitch($event)"
    ></lib-toggle-vertical>
    <lib-input
        *ngIf="type !== 'KFW_DARLEHEN' && contentService.getNestedObject('darlehenBausteine', 'laufzeitInMonaten', num) === 'other'"
        #variableL
        class="da-tile-field"
        label="{{'FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.LABEL' | translate}}"
        name="variableL{{num}}"
        [error]="false"
        validationType="numbers"
        errorText="{{'COMMON.ERRORS.NUMBER' | translate}}"
        placeholder="0"
        [isCurrency]="false"
        [value]="contentService.getNestedObject('darlehenBausteine', 'laufzeitInMonatenVariable', num) ? contentService.getNestedObject('darlehenBausteine', 'laufzeitInMonatenVariable', num) : 0"
        [touched]="contentService.getNestedObject('darlehenBausteine', 'laufzeitInMonatenVariable', num) && contentService.getNestedObject('darlehenBausteine', 'laufzeitInMonatenVariable', num) > 0"                               
        (inputValue)="validateInput($event, variable)"
    ></lib-input>
    <lib-input
        *ngIf="type === 'KFW_DARLEHEN' && contentService.getNestedObject('darlehenBausteine', 'laufzeitInJahren', num) === 'other'"
        #variableL
        class="da-tile-field"
        label="{{'FINANCE_ASSEMBLY.BAUSTEINE.LAUFZEIT.LABEL' | translate}}"
        name="variableL{{num}}"
        [error]="false"
        validationType="numbers"
        errorText="{{'COMMON.ERRORS.NUMBER' | translate}}"
        placeholder="0"
        [isCurrency]="false"
        [value]="contentService.getNestedObject('darlehenBausteine', 'laufzeitInJahrenVariable', num) ? contentService.getNestedObject('darlehenBausteine', 'laufzeitInJahrenVariable', num) : 0"
        [touched]="contentService.getNestedObject('darlehenBausteine', 'laufzeitInJahrenVariable', num) && contentService.getNestedObject('darlehenBausteine', 'laufzeitInJahrenVariable', num) > 0"                               
        (inputValue)="validateInput($event, variable)"
    ></lib-input>
</div>

<ng-template #laufzeitInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{infoHeader | translate}}" text="{{infoText | translate}}"></app-info-sidebar>
</ng-template>
