import { AfterViewInit, Component, EventEmitter, HostListener, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { switchesContent } from 'src/assets/content/switches';
import { tilesContent } from 'src/assets/content/tiles';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-financial-assets',
  templateUrl: './financial-assets.component.html',
  styleUrls: ['./financial-assets.component.scss']
})
export class FinancialAssetsComponent implements AfterViewInit {
  public slideName: string = 'financial-assets';
  next: string = 'financial-assets';
  localValue!: string;
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  title1: string = 'FINANCIAL_ASSETS.TITLE1';
  title2: string = 'FINANCIAL_ASSETS.TITLE2';
  showAssets: boolean = false;
  hasHouse: boolean = false;
  tiles = tilesContent['FINANCIAL_ASSETS'].items;
  switches = switchesContent['VERWENDUNG'].items;
  bausparTabs: any;
  versicherungTabs: any;
  asset: any = {};

  fakeTiles = tilesContent['FINANCIAL_ASSETS'].containers;
  selectedTile: string = 'Sparen';
  tilePosition: string = '122';
  basePosition: number = 122;
  screenPosition: string = '-50%';
  tileSlideFactor: number = 266;
  tileIndex: number = 0;
  selectedTab!: number;
  selectedTabVersicherung!: number;
  selectedVerwendung1: string = 'NA';
  selectedVerwendung2: string = 'NA';
  selectedVerwendung3: string = 'NA';
  selectedVerwendungVersicherung1: string = 'NA';
  selectedVerwendungVersicherung2: string = 'NA';
  selectedVerwendungVersicherung3: string = 'NA';

  errorSparen: string = 'COMMON.ERRORS.NUMBER';
  errorWertpapiere: string = 'COMMON.ERRORS.NUMBER';
  errorBauSparen: string = 'COMMON.ERRORS.NUMBER';
  errorVersicherung: string = 'COMMON.ERRORS.NUMBER';

  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public contentService: ContentService,
  ) { }

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('sparenVorhanden') sparenVorhanden!: any;
  @ViewChild('sparenBetrag') sparenBetrag!: any;
  @ViewChild('sparenEinsetzen') sparenEinsetzen!: any;
  @ViewChild('sparenEinsetzenBetrag') sparenEinsetzenBetrag!: any;
  @ViewChild('bausparenVorhanden') bausparenVorhanden!: any;
  @ViewChild('bausparenCount') bausparenCount!: any;
  @ViewChild('bausparTabsInput') bausparTabsInput!: any;
  @ViewChild('bausparenBetrag') bausparenBetrag!: any;
  @ViewChild('bausparenEinsetzen1') bausparenEinsetzen1!: any;
  @ViewChild('bausparenEinsetzen2') bausparenEinsetzen2!: any;
  @ViewChild('bausparenEinsetzen3') bausparenEinsetzen3!: any;
  @ViewChild('bausparenEinsetzenBetrag') bausparenEinsetzenBetrag!: any;
  @ViewChild('bausparenSicherheit') bausparenSicherheit!: any;
  @ViewChild('wertpapiereVorhanden') wertpapiereVorhanden!: any;
  @ViewChild('wertpapiereBetrag') wertpapiereBetrag!: any;
  @ViewChild('wertpapiereEinsetzen') wertpapiereEinsetzen!: any;
  @ViewChild('wertpapiereEinsetzenBetrag') wertpapiereEinsetzenBetrag!: any;
  @ViewChild('immobilienVorhanden') immobilienVorhanden!: any;
  @ViewChild('mehrImmobilienVorhanden') mehrImmobilienVorhanden!: any;
  @ViewChild('immobilienEigengenutztCount') immobilienEigengenutztCount!: any;
  @ViewChild('immobilienVermietetCount') immobilienVermietetCount!: any;
  @ViewChild('immobilienTeilVermietetCount') immobilienTeilVermietetCount!: any;
  @ViewChild('familienvermoegenVorhanden') familienvermoegenVorhanden!: any;
  @ViewChild('familienSparvermoegenVorhanden') familienSparvermoegenVorhanden!: any;
  @ViewChild('familienvermoegenBetrag') familienvermoegenBetrag!: any;
  @ViewChild('familienImmobilieVorhanden') familienImmobilieVorhanden!: any;
  @ViewChild('versicherungVorhanden') versicherungVorhanden!: any;
  @ViewChild('versicherungCount') versicherungCount!: any;
  @ViewChild('versicherungTabsInput') versicherungTabsInput!: any;
  @ViewChild('versicherungBetrag') versicherungBetrag!: any;
  @ViewChild('versicherungEinsetzen1') versicherungEinsetzen1!: any;
  @ViewChild('versicherungEinsetzen2') versicherungEinsetzen2!: any;
  @ViewChild('versicherungEinsetzen3') versicherungEinsetzen3!: any;
  @ViewChild('versicherungEinsetzenBetrag') versicherungEinsetzenBetrag!: any;
  @ViewChild('versicherungSicherheit') versicherungSicherheit!: any;
  @ViewChildren('inputs') inputsList!: QueryList<any>;

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
    this.setTilePos();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setTilePos();
      this.setStatus();
    });
  }

  setStatus() {
    const participants = this.dataQuery.getEntity(1)?.['participants'];

    if (!!participants && participants !== 'allein') {
      this.title1 = 'FINANCIAL_ASSETS.TITLE_PARTNER1';
      this.title2 = 'FINANCIAL_ASSETS.TITLE_PARTNER2';
    }

    const status = this.dataQuery.getEntity(1);

    if (!!status) {
      // sonderfall DA-C
      // if (!status['shortcut']) {
        if (environment.webappType === 'c') {
          this.hasHouse = true;
          this.setValue('Ja', true);
          this.daService.update(1, { immobilien: 'VORHANDENE_BESTEHENDE_IMMOBILIEN' });

          if (!!status['objectType']) {
            if (status['objectType'] !== 'Haus') {
              let objectType = status['objectType'][0].toUpperCase() + status['objectType'].slice(1).toLowerCase();
              objectType = objectType.replaceAll('ue', 'ü');
              this.asset = {
                ... this.asset,
                type: objectType,
              }
            } else {
              let houseType = status['houseType'][0].toUpperCase() + status['houseType'].slice(1).toLowerCase();
              houseType = houseType.replaceAll('ae', 'ä');
              this.asset = {
                ... this.asset,
                type: houseType,
              }
            }
          }
          if (status['objectAddress']) {
            this.asset = {
              ... this.asset,
              address: status['objectAddress'],
            }
          }
          if (status['objectAddressMitStrasse']) {
            this.asset = {
              ... this.asset,
              mitStrasse: status['objectAddressMitStrasse'],
            }
          }
        }
      // }

      setTimeout(() => {
        if (status?.['bausparenAnzahl']) {
          this.selectedTab = 1;
          if (status?.['bausparenAnzahl'] >= 1) {
            setTimeout(() => {
              this.setCounter(status?.['bausparenAnzahl'], this.bausparenCount, 'Bausparen');
              this.setTristate('1', this.bausparTabsInput, 'bausparen');
            }, 100);
          }

        }
        if (status?.['versicherungAnzahl']) {
          this.selectedTabVersicherung = 1;
          if (status?.['versicherungAnzahl'] >= 1) {
            setTimeout(() => {
              this.setCounter(status?.['versicherungAnzahl'], this.versicherungCount, 'Versicherungen');
              this.setTristate('1', this.versicherungTabsInput, 'versicherung');
            }, 100);
          }
        }
      }, 100)
    }
    if (!status?.['hasAssets'] || status?.['hasAssets'] === 'Ja') {
      this.fakeTiles?.map((tile: any) => {
        this.setFakeTileStatus(tile.value, 'init');
      });
    }

    if (!!status?.['hasAssets']) {
      this.setValue(status?.['hasAssets'], true, true);
    } 

  }

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'next') {
        if (this.allState !== 'success') {
          this.showState = true;
          this.setDisabled();
        } else {
          this.setDisabled('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 50)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  setTilePos() {
    if (this.screen.isSmallerThanMinWidth()) {
      if (this.screen.isSmallest()) {
        this.screenPosition = '-25px';
        this.tilePosition = '0';
        this.tileSlideFactor = window.innerWidth;
      } else {
        this.screenPosition = '-50%';
        this.tilePosition = '122';
        this.tileSlideFactor = 266;
      }
    } else {
      this.screenPosition = '-50%';
      this.tilePosition = '122';
      this.tileSlideFactor = 266;
    }
    this.getIndex(this.selectedTile);
  }

  setValue(e: any, noScroll: boolean = false, init?: boolean) {
    this.localValue = e;
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
    this.daService.update(1, { hasAssets: e });
    if (e === 'Ja') {
      this.showAssets = true;
      if (!noScroll) { setTimeout(() => { this.screen.scrollToElement('da-content-extended') }, 100); }
      setTimeout(() => { this.setDisabled() });
    } else if (e === 'nein') {
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, e, 'success');
      this.showAssets = false;
      this.disabled = false;
      this.daNextSlideService.update(1, { disabled: this.disabled });
      this.setNext();
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.stateChange.emit(true);
      if (!init) { this.contentService.setNav('next', 'financial-assets', this.slideTo, this.next); }
    }
  }

  setLocalValue(num: number, field: string, parent: string) {
    let parsedValue = '';
    parsedValue = this.contentService.getNestedValue(parent, field, num) ?? '';
    return parsedValue;
  }

  getIndex(e: string) {
    this.selectedTile = e;
    this.tilePosition = this.screen.calculateNewPos(this.fakeTiles, e, this.tileSlideFactor, this.tilePosition, this.basePosition);
  };

  setTristate(e: string, element: any, type?: string) {
    if ((element && element.name === 'bausparTabs') || (type && type === 'bausparen')) {
      this.daService.update(1, { bausparSelectedTab: parseInt(e) });
      this.selectedTab = parseInt(e);
      setTimeout(() => {
        if (this.selectedTab === 1) {
          if (!!this.contentService.getNestedValue('bausparvertraege', 'verwendung', this.selectedTab)) {
            this.selectedVerwendung1 = this.contentService.getNestedValue('bausparvertraege', 'verwendung', this.selectedTab);
          } else {
            this.selectedVerwendung1 = 'NA';
          }
        } else if (this.selectedTab === 2) {
          if (!!this.contentService.getNestedValue('bausparvertraege', 'verwendung', this.selectedTab)) {
            this.selectedVerwendung2 = this.contentService.getNestedValue('bausparvertraege', 'verwendung', this.selectedTab);
          } else {
            this.selectedVerwendung2 = 'NA';
          }
        } else {
          if (!!this.contentService.getNestedValue('bausparvertraege', 'verwendung', this.selectedTab)) {
            this.selectedVerwendung3 = this.contentService.getNestedValue('bausparvertraege', 'verwendung', this.selectedTab);
          } else {
            this.selectedVerwendung3 = 'NA';
          }
        }
      }, 100);
    } else if ((element && element.name === 'versicherungTabs') || (type && type === 'versicherung')) {
      this.daService.update(1, { versicherungSelectedTab: parseInt(e) });
      this.selectedTabVersicherung = parseInt(e);
      setTimeout(() => {
        if (this.selectedTabVersicherung === 1) {
          if (!!this.contentService.getNestedValue('versicherungsvertraege', 'verwendung', this.selectedTabVersicherung)) {
            this.selectedVerwendungVersicherung1 = this.contentService.getNestedValue('versicherungsvertraege', 'verwendung', this.selectedTabVersicherung);
          } else {
            this.selectedVerwendungVersicherung1 = 'NA';
          }
        } else if (this.selectedTabVersicherung === 2) {
          if (!!this.contentService.getNestedValue('versicherungsvertraege', 'verwendung', this.selectedTabVersicherung)) {
            this.selectedVerwendungVersicherung2 = this.contentService.getNestedValue('versicherungsvertraege', 'verwendung', this.selectedTabVersicherung);
          } else {
            this.selectedVerwendungVersicherung2 = 'NA';
          }
        } else {
          if (!!this.contentService.getNestedValue('versicherungsvertraege', 'verwendung', this.selectedTabVersicherung)) {
            this.selectedVerwendungVersicherung3 = this.contentService.getNestedValue('versicherungsvertraege', 'verwendung', this.selectedTabVersicherung);
          } else {
            this.selectedVerwendungVersicherung3 = 'NA';
          }
        }
      }, 100);
    }
  }

  setSwitch(e: string, element: any, parent: string) {
    this.inputDisabled = false;
    this.testValid = true;
    setTimeout(() => {
      if (parent === 'Bausparen' && element.name.includes('bausparenVerwendung')) {
        this.storeBauvertraege(element, e);
      }
      if (parent === 'Versicherungen' && element.name.includes('versicherungVerwendung')) {
        this.storeVersicherungen(element, e);
      }
      switch (element.name) {
        case 'sparenVorhanden': {
          this.daService.update(1, { sparguthaben: e });
        }
          break;
        case 'sparenEinsetzen': {
          this.daService.update(1, { sparenVerwendung: e });
        }
          break;
        case 'bausparenVorhanden': {
          this.daService.update(1, { bausparguthaben: e });
          if(e === 'bausparguthabenVorhanden') {
            this.daService.update(1, { bausparenAnzahl: 1 });
            this.daService.update(1, { bausparSelectedTab: 1 });
            this.selectedTab = 1;
          }
        }
          break;
        case 'wertpapiereVorhanden': {
          this.daService.update(1, { wertpapiere: e });
        }
          break;
        case 'wertpapiereEinsetzen': {
          this.daService.update(1, { wertpapiereVerwendung: e });
        }
          break;
        case 'immobilienVorhanden': {
          this.daService.update(1, { immobilien: e });
        }
          break;
        case 'mehrImmobilienVorhanden': {
          this.daService.update(1, { mehrImmobilien: e });
        }
          break;
        case 'familienvermoegenVorhanden': {
          this.daService.update(1, { familienvermoegen: e });
          if (e === 'keinFamilienvermoegen') {
            this.daService.update(1, { familienSparvermoegen: 'keinFamilienSparvermoegen' });
            this.daService.update(1, { familienImmobilie: 'keineFamilienImmobilie' });
          }
        }
          break;
        case 'familienSparvermoegenVorhanden': {
          this.daService.update(1, { familienSparvermoegen: e });
        }
          break;
        case 'familienImmobilieVorhanden': {
          this.daService.update(1, { familienImmobilie: e });
        }
          break;
        case 'versicherungVorhanden': {
          this.daService.update(1, { versicherung: e });
          if(e === 'versicherungVorhanden') {
            this.daService.update(1, { versicherungAnzahl: 1 });
            this.daService.update(1, { versicherungSelectedTab: 1 });
            this.selectedTabVersicherung = 1;
          }
        }
          break;
      }
      this.setFakeTileStatus(parent);
    });
  }

  setCounter(e: number, element: any, parent: string) {
    this.inputDisabled = false;
    this.testValid = true;
    switch (element.name) {
      case 'bausparenCount': {
        this.bausparTabs = [];
        this.daService.update(1, { bausparenAnzahl: e });
        for (let i = 1; i <= e; i++) {
          this.bausparTabs.push({
            "label": "N° " + i,
            "name": "bausparTab",
            "value": i,
            "state": "warning",
            "checked": this.selectedTab === i ? true : false,
          })
        }
        break;
      }
      case 'versicherungCount': {
        this.versicherungTabs = [];
        this.daService.update(1, { versicherungAnzahl: e });
        for (let i = 1; i <= e; i++) {
          this.versicherungTabs.push({
            "label": "N° " + i,
            "name": "versicherungTab",
            "value": i,
            "state": "warning",
            "checked": this.selectedTabVersicherung === i ? true : false,
          })
        }
        break;
      }
      case 'immobilienEigengenutztCount': {
        this.daService.update(1, { immobilienEigengenutztAnzahl: e });
        break;
      }
      case 'immobilienVermietetCount': {
        this.daService.update(1, { immobilienVermietetAnzahl: e });
        break;
      }
      case 'immobilienTeilVermietetCount': {
        this.daService.update(1, { immobilienTeilVermietetAnzahl: e });
        break;
      }
    }
    this.setFakeTileStatus(parent);
  }

  validateInput(e: any, element: any, parent: string) {
    let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
    element.touched = false;
    let isValidAmount: any = true;
    let isValid = this.contentService.validatePattern(value, element.validationType);
    if (element.name === 'sparenEinsetzenBetrag') {
      const sparenTotal = this.contentService.convertStringNumber(this.dataQuery.getEntity(1)?.['sparguthabenBetrag']);
      isValidAmount = this.contentService.minMax(value, 0, sparenTotal);
      this.errorSparen = isValidAmount?.tooHigh ? 'COMMON.ERRORS.SPAREN_EINSETZEN' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
    }

    if (element.name === 'wertpapiereEinsetzenBetrag') {
      const wertpapiereTotal = this.contentService.convertStringNumber(this.dataQuery.getEntity(1)?.['wertpapiereBetrag']);
      isValidAmount = this.contentService.minMax(value, 0, wertpapiereTotal);
      this.errorWertpapiere = isValidAmount?.tooHigh ? 'COMMON.ERRORS.WERTPAPIERE_EINSETZEN' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
    }

    if (element.name.includes('bausparenEinsetzenBetrag')) {
      const bausparenTotal = this.contentService.convertStringNumber(this.setLocalValue(this.selectedTab, 'angesparterBetrag', 'bausparvertraege'));
      isValidAmount = this.contentService.minMax(value, 0, bausparenTotal);
      this.errorBauSparen = isValidAmount?.tooHigh ? 'COMMON.ERRORS.BAUSPAREN_EINSETZEN' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
    }

    if (element.name.includes('versicherungEinsetzenBetrag')) {
      const versicherungTotal = this.contentService.convertStringNumber(this.setLocalValue(this.selectedTabVersicherung, 'rueckkaufswert', 'versicherungsvertraege'));
      isValidAmount = this.contentService.minMax(value, 0, versicherungTotal);
      this.errorVersicherung = isValidAmount?.tooHigh ? 'COMMON.ERRORS.VERSICHERUNG_EINSETZEN' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
    }

    element.error = isValid && isValidAmount === true ? false : true;
    if (!element.error) {
      this.storeInputValue(element, value, parent)
    } else {
      this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, parent, 'error');
      this.contentService.setSlideIndexState(this.slideName, false, 'error');
      this.setAllState('error', 'ERROR');
      this.stateChange.emit(true);
      setTimeout(() => { this.setFakeTileStatus(parent); }, 100);
    }
    element.touched = true;
  }

  storeInputValue(element: any, e: any, parent: string) {
    if (parent === 'Bausparen') {
      this.storeBauvertraege(element, e);

    } else if (parent === 'Versicherungen') {
      this.storeVersicherungen(element, e);

    } else {
      switch (element.name) {
        case 'sparenBetrag': this.daService.update(1, { sparguthabenBetrag: e });
          break;
        case 'sparenEinsetzenBetrag': this.daService.update(1, { sparenMaximalEinzusetzenderBetrag: e });
          break;
        case 'wertpapiereBetrag': this.daService.update(1, { wertpapiereBetrag: e });
          break;
        case 'wertpapiereEinsetzenBetrag': this.daService.update(1, { wertpapiereMaximalEinzusetzenderBetrag: e });
          break;
        case 'familienvermoegenBetrag': this.daService.update(1, { familienvermoegenBetrag: e });
          break;
      }
    }

    this.setFakeTileStatus(parent);
  }

  storeBauvertraege(element: any, e: any) {
    let vertraege = [];
    const status = this.dataQuery.getEntity(1)?.['bausparvertraege'];
    if (!status) {
      vertraege.push({
        'id': this.selectedTab,
        'angesparterBetrag': element.name.includes('bausparenBetrag') ? e : '',
        'verwendung': element.name.includes('bausparenVerwendung') ? e : '',
        'maximalEinzusetzenderBetrag': element.name.includes('bausparenEinsetzenBetrag') ? e : '',
      })
    } else {
      let count = 0;
      status.map((vertrag: any) => {
        if (vertrag.id === this.selectedTab) {
          count++;
          if (element.name.includes('bausparenBetrag')) {
            vertrag = { ...vertrag, 'angesparterBetrag': e }
          } else if (element.name.includes('bausparenVerwendung')) {
            vertrag = { ...vertrag, 'verwendung': e };
          } else {
            vertrag = { ...vertrag, 'maximalEinzusetzenderBetrag': e };
          }
        }
        vertraege.push(vertrag);
      });
      if (count === 0) {
        vertraege.push({
          'id': this.selectedTab,
          'angesparterBetrag': element.name.includes('bausparenBetrag') ? e : '',
          'verwendung': element.name.includes('bausparenVerwendung') ? e : '',
          'maximalEinzusetzenderBetrag': element.name.includes('bausparenEinsetzenBetrag') ? e : '',
        });
      }
    }
    this.daService.update(1, { bausparvertraege: vertraege });
  }

  storeVersicherungen(element: any, e: any) {
    let vertraege = [];
    const status = this.dataQuery.getEntity(1)?.['versicherungsvertraege'];
    if (!status) {
      vertraege.push({
        'id': this.selectedTabVersicherung,
        'rueckkaufswert': element.name.includes('versicherungBetrag') ? e : '',
        'verwendung': element.name.includes('versicherungVerwendung') || element.name.includes('versicherungSicherheit') ? e : '',
        'maximalEinzusetzenderBetrag': element.name.includes('versicherungEinsetzenBetrag') ? e : '',
      })
    } else {
      let count = 0;
      status.map((vertrag: any) => {
        if (vertrag.id === this.selectedTabVersicherung) {
          count++;
          if (element.name.includes('versicherungBetrag')) {
            vertrag = { ...vertrag, 'rueckkaufswert': e }
          } else if (element.name.includes('versicherungVerwendung') || element.name.includes('versicherungSicherheit')) {
            vertrag = { ...vertrag, 'verwendung': e };
          } else {
            vertrag = { ...vertrag, 'maximalEinzusetzenderBetrag': e };
          }
        }
        vertraege.push(vertrag);
      });
      if (count === 0) {
        vertraege.push({
          'id': this.selectedTabVersicherung,
          'rueckkaufswert': element.name.includes('versicherungBetrag') ? e : '',
          'verwendung': element.name.includes('versicherungVerwendung') || element.name.includes('versicherungSicherheit') ? e : '',
          'maximalEinzusetzenderBetrag': element.name.includes('versicherungEinsetzenBetrag') ? e : '',
        });
      }
    }
    this.daService.update(1, { versicherungsvertraege: vertraege });
  }

  setFakeTileStatus(parent: string, type: string = 'default') {

    const status = this.dataQuery.getEntity(1);
    let state = 'warning';

    switch (parent) {
      case 'Sparen': {
        if (!!status?.['sparguthaben']) {
          if (status?.['sparguthaben'] === 'keinSparguthaben') {
            state = 'success';
            break;
          }
          if (!!status?.['sparguthabenBetrag'] && !!status?.['sparenVerwendung']) {
            if (status?.['sparenVerwendung'] === 'KEINE_VERWENDUNG') {
              state = 'success';
              break;
            }
            if (!!status?.['sparenMaximalEinzusetzenderBetrag']) {
              state = 'success';
            }
          }
        }
        break;
      }
      case 'Bausparen': {
        if (!!status?.['bausparguthaben']) {
          if (status?.['bausparguthaben'] === 'keinBausparguthaben') {
            state = 'success';
            break;
          }
          state = this.setTabState(status?.['bausparenAnzahl'], this.bausparTabs, status?.['bausparvertraege'], 'angesparterBetrag');
        }
        break;
      }
      case 'Wertpapiere': {
        if (!!status?.['wertpapiere']) {
          if (status?.['wertpapiere'] === 'keineWertpapiere') {
            state = 'success';
            break;
          }
          if (!!status?.['wertpapiereBetrag'] && !!status?.['wertpapiereVerwendung']) {
            if (status?.['wertpapiereVerwendung'] === 'KEINE_VERWENDUNG') {
              state = 'success';
              break;
            }
            if (!!status?.['wertpapiereMaximalEinzusetzenderBetrag']) {
              state = 'success';
            }
          }
        }
        break;
      }
      case 'Immobilien': {
        if (!!status?.['immobilien']) {
          if (status?.['immobilien'] === 'KEINE_BESTEHENDEN_IMMOBILIEN') {
            state = 'success';
            break;
          }
          if (this.hasHouse && !!status?.['mehrImmobilien']) {
            if (status?.['mehrImmobilien'] === 'KEINE_WEITEREN_IMMOBILIEN') {
              state = 'success';
              break;
            }
          }
          if ((status?.['immobilienEigengenutztAnzahl'] && status?.['immobilienEigengenutztAnzahl'] > 0) ||
            (status?.['immobilienVermietetAnzahl'] && status?.['immobilienVermietetAnzahl'] > 0) ||
            (status?.['immobilienTeilVermietetAnzahl'] && status?.['immobilienTeilVermietetAnzahl'] > 0)) {
            state = 'success';
          }
        }
        break;
      }
      case 'Familie': {
        if (!!status?.['familienvermoegen']) {
          if (status?.['familienvermoegen'] === 'keinFamilienvermoegen') {
            state = 'success';
            break;
          }
          if (!!status?.['familienSparvermoegen'] && !!status?.['familienImmobilie']) {
            if (status?.['familienSparvermoegen'] === 'keinFamilienSparvermoegen') {
              state = 'success';
              break;
            }
          }

          if (!!status?.['familienvermoegenBetrag']) {
            state = 'success';
          }
        }
        break;
      }
      case 'Versicherungen': {
        if (!!status?.['versicherung']) {
          if (status?.['versicherung'] === 'keineVersicherung') {
            state = 'success';
            break;
          }
          state = this.setTabState(status?.['versicherungAnzahl'], this.versicherungTabs, status?.['versicherungsvertraege'], 'rueckkaufswert');
        }
        break;
      }
    }
    this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, parent, state);
    setTimeout(() => { this.setDisabled(type) });
  }

  setTabState(count: number, tabs: any, reduxData: any, key: string) {
    let state = 'warning';
    if (count <= 1 && reduxData) {
      if (!!reduxData[0]?.[key] && reduxData[0]?.['verwendung'] === 'KEINE_VERWENDUNG') {
        state = 'success';
        return state;
      }
      if (!!reduxData[0]?.[key]) {
        if (reduxData[0]?.['verwendung'] === 'ABTRETEN_ALS_VERWENDUNG') {
          state = 'success';
          return state;
        }

        if (!!reduxData[0]?.['maximalEinzusetzenderBetrag']) {
          state = 'success';
        }
      }
    } else {
      if (tabs && reduxData) {
        for (let i = 0; i < reduxData.length; i++) {
          let tabState = 'warning';
          if (!!reduxData[i]?.[key] && reduxData[i]?.['verwendung'] === 'KEINE_VERWENDUNG') {
            tabState = 'success';
          } else {
            if (!!reduxData[i]?.[key]) {
              if (reduxData[i]?.['verwendung'] === 'ABTRETEN_ALS_VERWENDUNG') {
                tabState = 'success';
              } else {
                if (!!reduxData[i]?.['maximalEinzusetzenderBetrag']) {
                  tabState = 'success';
                }
              }
            }
          }

          tabs.map((tab: any) => {
            if (tab.value === reduxData[i]?.['id']) {
              tab.state = tabState;
            }
          });

          if (key === 'angesparterBetrag') {
            this.bausparTabs = tabs
          } else {
            this.versicherungTabs = tabs;
          }
        }
        let tabSuccess = 0;
        tabs.map((tab: any) => {
          if (tab.state === 'success') {
            tabSuccess++;
          }
        });
        state = tabSuccess === tabs.length ? 'success' : state;
      }
    }
    return state;
  }

  setDisabled(type: string = 'default') {
    const inputFields = this.inputsList.toArray();
    this.disabled = true;
    let isNotValid = true;
    let hasWarning = 0;
    let hasError = 0;
    let state = 'warning';
    let stateText = 'INCOMPLETE';

    this.fakeTiles?.map((tile: any) => {
      if (tile.state === 'warning') {
        hasWarning++;
      }
      if (tile.state === 'error') {
        hasError++;
      }
    });
    this.setNext();

    isNotValid = hasWarning > 0 || hasError > 0;

    state = hasError > 0 ? 'error' : hasWarning > 0 ? 'warning' : 'success';
    stateText = hasError > 0 ? 'ERROR' : hasWarning > 0 ? 'INCOMPLETE' : 'COMPLETE';

    this.contentService.setTileStatusCheckbox(this.tiles, 'Ja', state);
    this.contentService.setSlideIndexState(this.slideName, false, state);
    this.setAllState(state, stateText);
    this.stateChange.emit(true);

    if (inputFields?.length > 0) {
      inputFields.map((item: any) => {
        if (item.error) {
          this.tiles = this.contentService.setTileStatus(this.tiles, this.localValue, 'error', 'success');
          this.contentService.setSlideIndexState(this.slideName, false, 'error');
          this.setAllState('error', 'ERROR');
          this.stateChange.emit(true);
          isNotValid = true;
        }
      })
    }

    if (!isNotValid) {
      this.disabled = false;
      if (type === 'test') {
        this.inputDisabled = true;
        this.testValid = false;
      }
    } else {
      this.disabled = true;
      this.testValid = type === 'init' ? false : true;
    }

    if (this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }

  setNext() {
    const status = this.dataQuery.getEntity(1)?.['immobilien'];
    const familyStatus = this.dataQuery.getEntity(1)?.['familienImmobilie'];

    if ((!!status && status !== 'KEINE_BESTEHENDEN_IMMOBILIEN') || (!!familyStatus && familyStatus === 'familienImmobilieVorhanden')) {
      this.next = 'property-information';
      this.contentService.setSlideIndex('property-information', false);
      this.contentService.setSlideIndex('property-change', false);
    } else {
      this.next = 'info-success-90';
      this.contentService.setSlideIndex('property-information', true);
      this.contentService.setSlideIndex('property-change', true);
    }
  }
}
