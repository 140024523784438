<div class="da-forward-darlehen-wrapper" [ngClass]="{'mobile': screen.isSmallest()}">
    <lib-state-icon [state]="containerState" [ngStyle]="{'left': '0', 'top': '0', 'position': 'absolute'}"></lib-state-icon>
    <lib-header-text
        class="da-title"
        label="{{'FINANCE_REQUIREMENTS_FORWARD.KAPITAL.HEADER' | translate }}"
        size="38px"
        [convertMobile]="true"
    ></lib-header-text>
    <button class="da-forward-arrow" [ngClass]="{'mobile': screen.isSmallest()}" (click)="isVisible = !isVisible">
        <svg-icon src="assets/images/chevronUp.svg" *ngIf="isVisible"></svg-icon>
        <svg-icon src="assets/images/chevronDown.svg" *ngIf="!isVisible"></svg-icon>
    </button>
    <ng-container *ngIf="isVisible">
        <div class="da-forward-darlehen-row" [ngClass]="{'mobile': screen.isSmallest()}">
            <div class="da-forward-darlehen-row-part left">
                <lib-tile-text
                    [ngStyle]="{'margin-top': '20px'}"
                    class="da-title"
                    label="{{'FINANCE_REQUIREMENTS_FORWARD.KAPITAL.LABEL' | translate }}"
                    size="16px"
                    info="true"
                    (infoValue)="screen.openInfo(kapitalInfo)"
                ></lib-tile-text>
                <lib-toggle 
                    [ngStyle]="{'margin-top': '20px'}"
                    #kapitalbeschaffung
                    label1="{{'COMMON.TRUE' | translate}}"
                    label2="{{'COMMON.FALSE' | translate}}"
                    value1="true"
                    value2="false"
                    name="kapitalbeschaffung"
                    [localValue]="contentService.getValue('kapitalbeschaffung')"
                    (switchValue)="setSwitch($event, 'kapitalbeschaffung')"
                ></lib-toggle>
            </div>
            <div class="da-forward-darlehen-row-part">
                <ng-container *ngIf="contentService.getValue('kapitalbeschaffung') === 'true'">
                    <lib-input
                        #kapitalBetrag
                        #inputs
                        label="{{'FINANCE_REQUIREMENTS_FORWARD.VERBINDLICHKEITEN.LABELS.LABEL0' | translate}}"
                        name="kapitalBetrag"
                        label="{{'COMMON.LABELS.SUMME' | translate}}"
                        [error]="false"
                        errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                        placeholder="0,00"
                        unit="€"
                        [inputDisabled]="inputDisabled"
                        [touched]="!!contentService.getValue('kapitalbeschaffungBetrag')"
                        [value]="contentService.getValue('kapitalbeschaffungBetrag')"
                        (change)="validateInput($event, kapitalBetrag)"
                    ></lib-input>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>
<ng-template #kapitalInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_REQUIREMENTS_FORWARD.KAPITAL.INFOS.HEADER' | translate}}" text="{{'FINANCE_REQUIREMENTS_FORWARD.KAPITAL.INFOS.TEXT' | translate}}"></app-info-sidebar>
</ng-template>