import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {
  mehrwertsteuerbetrag!: string;
  mehrwertsteuerprozent!: string;
  nettogebuehr!: string;
  gesamtgebuehr!: string;
  zustellungszeit!: string;
  produktbild!: string;

  @Input() type!: string;
  @Input() free!: boolean;
  
  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private configurationQuery: ConfigurationQuery,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.configurationQuery.configuration.subscribe((res: any) => {
      this.mehrwertsteuerbetrag = res.mehrwertsteuerbetrag ? res.mehrwertsteuerbetrag.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '0,00';
      this.mehrwertsteuerprozent = res.mehrwertsteuerprozent ? res.mehrwertsteuerprozent.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : '0';
      this.nettogebuehr = res.nettogebuehr ? res.nettogebuehr.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '0,00';
      this.gesamtgebuehr = res.gesamtgebuehr ? `${res.gesamtgebuehr.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} €` : this.translate.instant('UEBERGABE_A.PRODUCT.SCREEN1.TEXT4');
      this.zustellungszeit = res.zustellungszeit;
      this.produktbild = res.bildmaterial_da_a.linkgrafikproduktzertifikat;
    });
  }

  docDownload() {
    this.configurationQuery.configuration.subscribe((res: any) => {
      if (res) {
        window.open(res.linkpdfleistungsbeschreibung,'_blank', 'fullscreen=yes');
      }
    });
  }
}
