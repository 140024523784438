import { Component, EventEmitter, HostListener, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';

import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { dropdownsContent } from 'src/assets/content/dropdowns';
import { switchesContent } from 'src/assets/content/switches';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-personal-partner',
  templateUrl: './personal-partner.component.html',
  styleUrls: ['./personal-partner.component.scss']
})
export class PersonalPartnerComponent implements OnInit {
  public slideName: string = 'personal-partner';
  next: string = 'additional-income';
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  tiles = tilesContent['PERSONAL'].items;
  fakeTiles = tilesContent['PERSONAL_PARTNER'].containers;
  switches = switchesContent['RENTE'].items;
  localValue!: string;
  selectedTile: string = 'Birthdate';
  tilePosition: string = '122';
  basePosition: number = 122;
  screenPosition: string = '-50vw';
  tileSlideFactor: number = 266;
  tileIndex: number = 0;

  familyItems = dropdownsContent['FAMILY'].items;
  selectedFamilyItem = { 'value': '', 'viewValue': 'COMMON.SELECT' };
  staatItems = dropdownsContent['STAAT'].items;
  selectedStaatItem = { 'value': '', 'viewValue': 'COMMON.SELECT' };
  jobItems = dropdownsContent['JOB'].items;
  selectedJobItem = { 'value': '', 'viewValue': 'COMMON.SELECT' };
  gehaltItems = dropdownsContent['GEHALT'].items;
  selectedGehaltItem = { 'value': '', 'viewValue': 'COMMON.SELECT' };
  visumItems = dropdownsContent['VISUM'].items;
  selectedVisumItem = { 'value': '', 'viewValue': 'COMMON.SELECT' };
  today: Date = new Date();
  maxDateBirth!: any;
  minDateBirth!: any;
  errorBirthdate: string = "COMMON.ERRORS.DATE";
  errorRentePrivat: string = "COMMON.ERRORS.NUMBER";
  errorRenteBetrieblich: string = "COMMON.ERRORS.NUMBER";

  showPartner: boolean = false;
  showFakeTiles: boolean = false;
  showStaatDate: boolean = false;
  showVisumDate: boolean = false;
  showPermitDate: boolean = false;
  showJobDetail: boolean = false;
  showIndependent: boolean = false;
  showRentner: boolean = false;

  tile5Text2 = 'PERSONAL_PARTNER.TILES.TILE5.TEXT2';
  tile5Text5 = 'PERSONAL_PARTNER.TILES.TILE5.TEXT5';
  tile5Text6 = 'PERSONAL_PARTNER.TILES.TILE5.TEXT6';

  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('birthdateContainer') birthdateContainer!: any;
  @ViewChild('plzContainer') plzContainer!: any;
  @ViewChild('familyContainer') familyContainer!: any;
  @ViewChild('stateContainer') stateContainer!: any;
  @ViewChild('jobContainer') jobContainer!: any;

  @ViewChild('birthdate') birthdate!: any;
  @ViewChild('plz') plz!: any;
  @ViewChild('family') family!: any;
  @ViewChild('staat') staat!: any;
  @ViewChild('staatdate') staatdate!: any;
  @ViewChild('visum') visum!: any;
  @ViewChild('visumdate') visumdate!: any;
  @ViewChild('permit') permit!: any;
  @ViewChild('permitdate') permitdate!: any;
  @ViewChild('job') job!: any;
  @ViewChild('jobDescription') jobDescription!: any;
  @ViewChild('jobdate') jobdate!: any;
  @ViewChild('probezeit') probezeit!: any;
  @ViewChild('befristet') befristet!: any;
  @ViewChild('gehalt') gehalt!: any;
  @ViewChild('gehaltNumber') gehaltNumber!: any;
  @ViewChild('eltern') eltern!: any;
  @ViewChild('elterngeld') elterngeld!: any;
  @ViewChild('elternzeit') elternzeit!: any;
  @ViewChild('taetigkeit') taetigkeit!: any;
  @ViewChild('independentdate') independentdate!: any;
  @ViewChild('gewinnCurrent') gewinnCurrent!: any;
  @ViewChild('gewinnPrevious') gewinnPrevious!: any;
  @ViewChild('gewinnPrePrevious') gewinnPrePrevious!: any;
  @ViewChild('typRente') typRente!: any;
  @ViewChild('renteBetrag') renteBetrag!: any;
  @ViewChild('rentePrivatBetrag') rentePrivatBetrag!: any;
  @ViewChild('renteBetrieblichBetrag') renteBetrieblichBetrag!: any;
  @ViewChild('renteFrist') renteFrist!: any;
  @ViewChild('rentedate') rentedate!: any;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    public dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService,
  ) {
    this.setTilePos();
    this.minDateBirth = this.contentService.subtractYears(new Date(), 85);
    this.maxDateBirth = this.contentService.subtractYears(new Date(), 18);
  }

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
    this.setTilePos();
  }

  ngOnInit() {
    setTimeout(() => {
      this.daService.setCurrentSlide(this.slideName);
      this.setStatus();
    });
  }

  setStatus() {
    this.disabled = true;
    const state = this.dataQuery.getEntity(1);
    const statePerson = this.dataQuery.getEntity(1)?.['haushaltPersonPartner'];

    if (!!statePerson) {
      this.tiles = this.contentService.setTileStatus(this.tiles, statePerson, 'success', 'warning');
      this.tiles = this.contentService.setTileChecked(this.tiles, statePerson);
      this.localValue = statePerson;
      this.showFakeTiles = true;
      setTimeout(() => {
        this.birthdate = this.contentService.validateReduxDate(state?.['geburtsdatumPartner'], this.birthdate);
        this.plz = this.contentService.validateReduxValue(state?.['wohnsituationPlzPartner'], this.plz);
        this.setFakeTileStatus('Birthdate');
        this.setFakeTileStatus('PostalCode');
        this.getSelectedValue(state?.['familienstandPartner'], this.family, 'Family');
        // staat Tile
        this.getSelectedValue(state?.['staatsangehoerigkeitPartner'], this.staat, 'State');
        setTimeout(() => {
          if (this.staatdate) { this.staatdate = this.contentService.validateReduxDate(state?.['inDeutschlandSeitPartner'], this.staatdate); }
          if (this.visum) { this.getSelectedValue(state?.['aufenthaltstitel2'], this.visum, 'State'); }
          setTimeout(() => {
            if (this.visumdate) { this.visumdate = this.contentService.validateReduxDate(state?.['visumBefristetBisPartner'], this.visumdate); }
            if (!!state?.['arbeitserlaubnisPartner'] && state?.['arbeitserlaubnisPartner'] === 'ARBEITSERLAUBNIS_VORHANDEN') {
              this.setInputVisibility(state?.['arbeitserlaubnisPartner'], this.permit);
              setTimeout(() => {
                if (this.permitdate) { this.permitdate = this.contentService.validateReduxDate(state?.['arbeitserlaubnisBefristetBisPartner'], this.permitdate); }
              });
            }
          });
        });
        // job Tile
        this.getSelectedValue(state?.['BeschaeftigungPartner'], this.job, 'Job');
        setTimeout(() => {
          if (state?.['berufPartner']) {
            this.jobDescription = this.contentService.validateReduxValue(state?.['berufPartner'], this.jobDescription);
            this.jobdate = this.contentService.validateReduxDate(state?.['beschaeftigtSeitPartner'], this.jobdate)
            this.gehalt = this.contentService.validateReduxValue(state?.['gehaltPartner'], this.gehalt);
            this.getSelectedValue(state?.['anzahlGehaelterProJahrPartner'], this.gehaltNumber, 'Job');
            setTimeout(() => {
              if (state?.['elternzeitPartner'] && state?.['elternzeitPartner'] === 'ElternzeitBesteht') {
                this.elternzeit = this.contentService.validateReduxDate(state?.['elternzeitBisPartner'], this.elternzeit);
                this.elterngeld = this.contentService.validateReduxValue(state?.['elterngeldPartner'], this.elterngeld);
              }
            }, 100);
          }
          if (state?.['BeschaeftigungPartner'] === 'SELBSTSTAENDIGER' || state?.['BeschaeftigungPartner'] === 'FREIBERUFLER') {
            this.taetigkeit = this.contentService.validateReduxValue(state?.['SelbststaendigeTaetigkeitPartner'], this.taetigkeit);
            this.independentdate = this.contentService.validateReduxDate(state?.['taetigSeitPartner'], this.independentdate);
            this.gewinnCurrent = this.contentService.validateReduxValue(state?.['gewinnAktuellesJahrPartner'], this.gewinnCurrent);
            this.gewinnPrevious = this.contentService.validateReduxValue(state?.['gewinnLetztesJahrPartner'], this.gewinnPrevious);
            this.gewinnPrePrevious = this.contentService.validateReduxValue(state?.['gewinnVorletztesJahrPartner'], this.gewinnPrePrevious);
          }
          if (state?.['BeschaeftigungPartner'] === 'RENTNER') {
            this.renteBetrag = this.contentService.validateReduxValue(state?.['monatlicheRentePartner'], this.renteBetrag);
            setTimeout(() => {
              if (state?.['typRentePartner'] && state?.['typRentePartner'] === 'Altersrente') {
                this.rentePrivatBetrag = this.contentService.validateReduxDate(state?.['AnteilPrivatRentePartner'], this.rentePrivatBetrag);
                this.renteBetrieblichBetrag = this.contentService.validateReduxValue(state?.['AnteilBetriebsRentePartner'], this.renteBetrieblichBetrag);
              }
              if ((state?.['typRentePartner'] && state?.['typRentePartner'] === 'Erwerbsminderungsrente') && (state?.['RenteBefristetPartner'] && state?.['RenteBefristetPartner'] === 'befristet')) {
                this.rentedate = this.contentService.validateReduxDate(state?.['RenteBefristetBisPartner'], this.rentedate);
              }
            }, 100);
          }
        }, 500);
      });
    } else {
      this.tiles = this.contentService.setTileStatus(this.tiles, '', 'success', 'warning');
      this.tiles = this.contentService.setTileChecked(this.tiles, '');
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.setAllState('warning', 'INCOMPLETE')
      this.stateChange.emit(true);
    }
    this.daNextSlideService.update(1, { disabled: this.disabled});
  }

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'next') {
        if (this.allState !== 'success') {
          this.showState = true;
          this.setDisabled();
        } else {
          this.setDisabled('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 50)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  setTilePos() {
    if (this.screen.isSmallerThanMinWidth()) {
      if (this.screen.isSmallest()) {
        this.screenPosition = '-25px';
        this.tilePosition = '0';
        this.tileSlideFactor = window.innerWidth;
      } else {
        this.screenPosition = '-50vw';
        this.tilePosition = '122';
        this.tileSlideFactor = 266;
      }
      this.getIndex(this.selectedTile);
    } else {
      this.screenPosition = '0vw';
      this.tilePosition = '0';
    }
  }

  setValue(e: any) {
    this.localValue = e;
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
    this.tiles = this.contentService.setTileStatus(this.tiles, e, 'success', 'warning');
    this.daService.update(1, { haushaltPersonPartner: e });
    this.showFakeTiles = true;
    setTimeout(() => { this.screen.scrollToElement('da-content-extended') }, 100);
  }

  getIndex(e: string) {
    if (this.screen.isSmallerThanMinWidth()) {
      this.selectedTile = e;
      this.tilePosition = this.screen.calculateNewPos(this.fakeTiles, e, this.tileSlideFactor, this.tilePosition, this.basePosition);
    }
  };

  validateDate(e: any, element: any, parent: string, isInput?: boolean) {
    let isDone = false
    element.error = !!e ? false : true;
    if (!e) {
      this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, parent, 'error');
      setTimeout(() => { this.setDisabled() });
      return
    }

    if (isInput) {
      if (e._i.length >= 8 && e._i.substring(e._i.length - 5, e._i.length - 4) === '.') {
        isDone = true;
      } else {
        isDone = false;
      }
    } else {
      isDone = true;
    }

    if (isDone) {
      const baseDate = e !== null ? e.toDate() : null;
      const currentDate = new Date();
      let baseTooOld: boolean = false
      let baseTooYoung: boolean = false;
      const birthDate = this.dataQuery.getEntity(1)?.['geburtsdatum'];
      setTimeout(() => {
        element.touched = true;
        switch (element.name) {
          case 'birthdate': {
            baseTooYoung = baseDate > this.maxDateBirth;
            baseTooOld = baseDate < this.minDateBirth;
            this.errorBirthdate = baseTooYoung ? 'COMMON.ERRORS.TOO_YOUNG_PARTNER' : baseTooOld ? 'COMMON.ERRORS.TOO_OLD_PARTNER' : 'COMMON.ERRORS.DATE';
            element.error = baseTooYoung || baseTooOld ? true : false;
            if (!element.error) {
              this.getDate(baseDate, element, parent);
            } else {
              this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, parent, 'error');
              this.contentService.setSlideIndexState(this.slideName, false, 'error');
              this.setAllState('error', 'ERROR');
              this.stateChange.emit(true);
              setTimeout(() => { this.setDisabled() });
            }

            return
          }
          case 'staatdate':
          case 'jobdate':
          case 'independentdate': {
            baseTooYoung = baseDate > currentDate;
            baseTooOld = !!birthDate ? baseDate < birthDate : baseDate < this.maxDateBirth;
          }
            break;
          case 'visumdate':
          case 'permitdate': {
            baseTooYoung = baseDate < currentDate;
            baseTooOld = baseDate > this.contentService.addYears(currentDate, 4);
          }
            break;
          case 'elternzeit': {
            baseTooYoung = baseDate < currentDate;
            baseTooOld = baseDate > this.contentService.addYears(currentDate, 3);
          }
            break;
          case 'rentedate': {
            baseTooYoung = baseDate < currentDate;
            baseTooOld = baseDate > this.contentService.addYears(currentDate, 40);
          }
            break;
        }
        element.error = baseTooYoung || baseTooOld ? true : false;
        if (!element.error) {
          this.getDate(baseDate, element, parent);
        } else {
          this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, parent, 'error');
          this.contentService.setSlideIndexState(this.slideName, false, 'error');
          this.setAllState('error', 'ERROR');
          this.stateChange.emit(true);
          setTimeout(() => { this.setDisabled() });
        }
      }, 50);
    }
  }

  getDate(e: any, element: any, parent: string) {

    switch (element.name) {
      case 'birthdate': {
        this.daService.update(1, { geburtsdatumPartner: e });
        break;
      }
      case 'staatdate': {
        this.daService.update(1, { inDeutschlandSeitPartner: e });
        break;
      }
      case 'visumdate': {
        this.daService.update(1, { visumBefristetBisPartner: e });
        break;
      }
      case 'permitdate': {
        this.daService.update(1, { arbeitserlaubnisBefristetBisPartner: e });
        break;
      }
      case 'jobdate': {
        this.daService.update(1, { beschaeftigtSeitPartner: e });
        break;
      }
      case 'elternzeit': {
        this.daService.update(1, { elternzeitBisPartner: e });
        break;
      }
      case 'independentdate': {
        this.daService.update(1, { taetigSeitPartner: e });
        break;
      }
      case 'rentedate': {
        this.daService.update(1, { RenteBefristetBisPartner: e });
        break;
      }
    }
    this.setFakeTileStatus(parent);
  }

  getSelectedValue(e: string, element: any, parent: string) {
    switch (element.name) {
      case 'family': {
        this.daService.update(1, { familienstandPartner: e });
        break;
      }
      case 'staat': {
        this.daService.update(1, { staatsangehoerigkeitPartner: e });
        break;
      }
      case 'visum': {
        this.daService.update(1, { aufenthaltstitelPartner: e });
        break;
      }
      case 'job': {
        if (e === 'BEAMTER') {
          this.tile5Text2 = 'PERSONAL_PARTNER.TILES.TILE5.TEXT2_BEAMTER';
          this.tile5Text5 = 'PERSONAL_PARTNER.TILES.TILE5.TEXT5_BEAMTER';
          this.tile5Text6 = 'PERSONAL_PARTNER.TILES.TILE5.TEXT6_BEAMTER';
        } else {
          this.tile5Text2 = 'PERSONAL_PARTNER.TILES.TILE5.TEXT2';
          this.tile5Text5 = 'PERSONAL_PARTNER.TILES.TILE5.TEXT5';
          this.tile5Text6 = 'PERSONAL_PARTNER.TILES.TILE5.TEXT6';
        }
        this.daService.update(1, { BeschaeftigungPartner: e });
        break;
      }
      case 'gehaltNumber': {
        this.daService.update(1, { anzahlGehaelterProJahrPartner: e });
        break;
      }
    }
    if (!!e) {
      element.success = true;
      this.contentService.getSelectedItem(e, element);
      this.setFakeTileStatus(parent);
      this.setInputVisibility(e, element);
    }
  }

  setSwitch(e: string, element: any, parent: string) {
    switch (element.name) {
      case 'permit': {
        this.daService.update(1, { arbeitserlaubnisPartner: e });
        this.setInputVisibility(e, element);
      }
        break;
      case 'probezeit': {
        this.daService.update(1, { probezeitPartner: e });
      }
        break;
      case 'befristet': {
        this.daService.update(1, { beschaeftigungsstatusPartner: e });
      }
        break;
      case 'eltern': {
        this.daService.update(1, { elternzeitPartner: e });
      }
        break;
      case 'typRente': {
        this.daService.update(1, { typRentePartner: e });
      }
        break;
      case 'renteFrist': {
        this.daService.update(1, { RenteBefristetPartner: e });
      }
        break;
    }
    setTimeout(() => {this.setFakeTileStatus(parent);}, 50);
  }

  validateInput(e: any, element: any, parent: string) {
    setTimeout(() => {
      let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
      element.touched = true;
      element.placeholder = '';
      let isValidAmount: any;
      let isValid = this.contentService.validatePattern(value, element.validationType);

      if (element.name === 'plz') {
        isValidAmount = this.contentService.minMaxLength(value, 5, 5);
      } else {
        isValidAmount = true;
      }

      if (element.name === 'rentePrivatBetrag' || element.name === 'renteBetrieblichBetrag') {
        const renteTotal = this.contentService.convertStringNumber(this.dataQuery.getEntity(1)?.['monatlicheRentePartner']);
        let rentePrivat = this.dataQuery.getEntity(1)?.['AnteilPrivatRentePartner'] ? this.contentService.convertStringNumber(this.dataQuery.getEntity(1)?.['AnteilPrivatRentePartner']) : 0;
        let renteBetrieb = this.dataQuery.getEntity(1)?.['AnteilBetriebsRentePartner'] ? this.contentService.convertStringNumber(this.dataQuery.getEntity(1)?.['AnteilBetriebsRentePartner']) : 0;

        if (element.name === 'rentePrivatBetrag') {
          isValidAmount = this.contentService.minMax(value, 1, renteTotal - renteBetrieb);
          this.errorRentePrivat = isValidAmount?.tooHigh ? 'COMMON.ERRORS.RENTE_PRIVAT' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
        }

        if (element.name === 'renteBetrieblichBetrag') {
          isValidAmount = this.contentService.minMax(value, 1, renteTotal - rentePrivat);
          this.errorRenteBetrieblich = isValidAmount?.tooHigh ? 'COMMON.ERRORS.RENTE_BETRIEBLICH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
        }

      }

      element.error = isValid && isValidAmount === true ? false : true;
      if (!element.error) {
        this.storeInputValue(element, value, parent)
      } else {
        this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, parent, 'error');
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.setAllState('error', 'ERROR');
        this.stateChange.emit(true);
        this.setDisabled();
      }
    }, 50);
  }

  storeInputValue(element: any, e: string, parent: string) {
    switch (element.name) {
      case 'plz': {
        this.daService.update(1, { wohnsituationPlzPartner: e });
        this.contentService.getByPlz(e).then((result: any) => {
          result.subscribe({
            next: (data: any) => {
              if (data[0]) {
                this.daService.update(1, { wohnsituationOrtPartner: data[0].name });
              }
            },
            error: (error: any) => console.log(error)
          })
        });
      }
        break;
      case 'jobDescription': this.daService.update(1, { berufPartner: e });
        break;
      case 'gehalt': this.daService.update(1, { gehaltPartner: e });
        break;
      case 'elterngeld': this.daService.update(1, { elterngeldPartner: e });
        break;
      case 'taetigkeit': this.daService.update(1, { SelbststaendigeTaetigkeitPartner: e });
        break;
      case 'gewinnCurrent': this.daService.update(1, { gewinnAktuellesJahrPartner: e });
        break;
      case 'gewinnPrevious': this.daService.update(1, { gewinnLetztesJahrPartner: e });
        break;
      case 'gewinnPrePrevious': this.daService.update(1, { gewinnVorletztesJahrPartner: e });
        break;
      case 'renteBetrag': this.daService.update(1, { monatlicheRentePartner: e });
        break;
      case 'rentePrivatBetrag': this.daService.update(1, { AnteilPrivatRentePartner: e });
        break;
      case 'renteBetrieblichBetrag': this.daService.update(1, { AnteilBetriebsRentePartner: e });
        break;
    }
    this.setFakeTileStatus(parent);
  }


  setInputVisibility(value: string, element: any) {

    switch (element.name) {
      case 'staat': {
        const isEU = this.contentService.euCountries.indexOf(value);
        if (isEU >= 0) {
          this.showStaatDate = false;
        } else {
          this.showStaatDate = true;
        }
        break;
      }
      case 'visum': {
        if (value === 'VISUM' || value === 'AUFENTHALTSERLAUBNIS' || value === 'BLAUEKARTE_EU') {
          this.showVisumDate = true;
        } else {
          this.showVisumDate = false;
        }
        break;
      }
      case 'job': {
        if (value === 'BEAMTER' || value === 'ANGESTELLTER' || value === 'ARBEITER' || value === 'AUSZUBILDENDER') {
          this.showJobDetail = true;
        } else {
          this.showJobDetail = false;
        }
        if (value === 'SELBSTSTAENDIGER' || value === 'FREIBERUFLER') {
          this.showIndependent = true;
        } else {
          this.showIndependent = false;
        }
        if (value === 'RENTNER') {
          this.showRentner = true;
        } else {
          this.showRentner = false;
        }
        break;
      }
      case 'permit': {
        if (value === 'ARBEITSERLAUBNIS_VORHANDEN') {
          this.showPermitDate = true;
        } else {
          this.showPermitDate = false;
        }
        break;
      }
    }
  }

  setFakeTileStatus(parent: string) {
    const status = this.dataQuery.getEntity(1);
    let state = 'success';
    switch (parent) {
      case 'Birthdate': {
        if (!status?.['geburtsdatumPartner']) { state = 'warning' }
        break;
      }
      case 'PostalCode': {
        if (!status?.['wohnsituationPlzPartner']) { state = 'warning' }
        break;
      }
      case 'State': {
        let staatStatus = false;
        if (!!status?.['staatsangehoerigkeitPartner']) {
          const isEU = this.contentService.euCountries.indexOf(status['staatsangehoerigkeitPartner']);
          staatStatus = isEU >= 0;
          if (!staatStatus) {  // if NICHT-EU-Bürger
            if(!!status['inDeutschlandSeitPartner'] && !!status['aufenthaltstitelPartner']) { // true = Fields filled out
              if(status['aufenthaltstitelPartner'] === 'BLAUEKARTE_EU') {
                staatStatus = !!status['visumBefristetBisPartner'] ? true : false;
              } else if(status['aufenthaltstitelPartner'] === 'VISUM' || status['aufenthaltstitelPartner'] === 'AUFENTHALTSERLAUBNIS') { // not permanent
                if(!!status['visumBefristetBisPartner'] && !!status['arbeitserlaubnisPartner']) {
                  if(status['arbeitserlaubnisPartner'] === 'ARBEITSERLAUBNIS_VORHANDEN') {
                    staatStatus = !!status['arbeitserlaubnisBefristetBisPartner'] ? true : false;
                  } else {
                    staatStatus = true;
                  }
                }
              } else {
                staatStatus = true;
              }
            }
          }
        }
        state = staatStatus ? 'success' : 'warning';
      }
        break;
      case 'Job': {
        let jobStatus = false;
        if (!!status?.['BeschaeftigungPartner']) {
          jobStatus = status['BeschaeftigungPartner'] == 'HAUSHALTENDE_PERSON' ||
            status['BeschaeftigungPartner'] == 'ARBEITSLOSER' ||
            status['BeschaeftigungPartner'] == 'SCHUELER';

          if (!jobStatus) {
            switch (status['BeschaeftigungPartner']) {
              case 'AUSZUBILDENDER':
              case 'ARBEITER': {
                jobStatus = !!status['berufPartner'] &&
                  !!status['beschaeftigtSeitPartner'] &&
                  !!status['beschaeftigungsstatusPartner'] &&
                  !!status['probezeitPartner'] &&
                  !!status['gehaltPartner'] &&
                  !!status['anzahlGehaelterProJahrPartner'];
                break;
              }
              case 'BEAMTER':
              case 'ANGESTELLTER': {
                jobStatus = !!status['berufPartner'] &&
                  !!status['beschaeftigtSeitPartner'] &&
                  !!status['gehaltPartner'] &&
                  !!status['anzahlGehaelterProJahrPartner'] &&
                  !!status['elternzeitPartner'];

                  if(jobStatus) {
                    if (status['BeschaeftigungPartner'] === 'ANGESTELLTER') {
                      jobStatus = !!status['probezeitPartner'] && !!status['beschaeftigungsstatusPartner'] ? jobStatus : false;
                    }
                    if (status['elternzeitPartner'] === 'ElternzeitBesteht') {
                      jobStatus = !!status['elterngeldPartner'] && !!status['elternzeitBisPartner'] ? jobStatus : false;
                    }
                  }
                break;
              }
              case 'SELBSTSTAENDIGER':
              case 'FREIBERUFLER': {
                jobStatus = !!status['SelbststaendigeTaetigkeitPartner'] &&
                  !!status['taetigSeitPartner'] &&
                  !!status['gewinnAktuellesJahrPartner'] &&
                  !!status['gewinnLetztesJahrPartner'] &&
                  !!status['gewinnVorletztesJahrPartner'];
                break;
              }
              case 'RENTNER': {
                jobStatus = !!status['typRentePartner'] && !!status['monatlicheRentePartner'];
                
                if(jobStatus) {
                  if (status['typRentePartner'] === 'Altersrente') {
                    jobStatus = !!status['AnteilPrivatRentePartner'] && !!status['AnteilBetriebsRentePartner'] ? jobStatus : false;
                  }
                  if (status['typRentePartner'] === 'Erwerbsminderungsrente') {
                    jobStatus = !!status['RenteBefristetPartner'] ? jobStatus : false;

                    if(jobStatus) {
                      if (status['RenteBefristetPartner'] === 'befristet') {
                        jobStatus = !!status['RenteBefristetBisPartner'] ? jobStatus : false;
                      }
                    }
                  }
                }
                break;
              }
            }
          }
        }
        state = jobStatus ? 'success' : 'warning';
      }
        break;
    }
    this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, parent, state);
    setTimeout(() => { this.setDisabled() });
  }


  setDisabled(type: string = 'default') {
    const status = this.dataQuery.getEntity(1);
    this.disabled = true;
    let isNotValid = true;
    let hasWarning = 0;
    let hasError = 0;
    let state = 'warning';
    let stateText = 'INCOMPLETE';

    this.fakeTiles?.map((tile: any) => {
      if (tile.state === 'warning') {
        hasWarning++;
      }
      if (tile.state === 'error') {
        hasError++;
      }
    })

    isNotValid = hasWarning > 0 || hasError > 0;

    state = hasError > 0 ? 'error' : hasWarning > 0 ? 'warning' : 'success';
    stateText = hasError > 0 ? 'ERROR' : hasWarning > 0 ? 'INCOMPLETE' : 'COMPLETE';

    if (!isNotValid) {
      this.disabled = false;
      if (type === 'test') {
        this.inputDisabled = true;
        this.testValid = false;
      }
    } else {
      this.disabled = true;
      this.testValid = true;
    }

    this.contentService.setSlideIndexState(this.slideName, false, state);
    this.setAllState(state, stateText);
    this.stateChange.emit(true);

    if (this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }

}
