import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { ConfigurationStore } from '../state/configuration/configuration.store';
import defaultConfigurationItems from 'src/assets/configuration.json';
import { DaClickpathService } from '../state/click-path/da-clickpath.service';
import { DaClickpathQuery } from '../state/click-path/da-clickpath.query';
import { DataQuery } from '../state/data/data.query';
import { DaNextSlideService } from '../state/next-slide/da-next-slide.service';
import { ConfigurationQuery } from '../state/configuration/configuration.query';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  openDataUrl = 'https://openplzapi.org/de/Localities?';
  private apiUrl = environment.apiHost;
  private apiBackendUrl = environment.backendHost;

  euCountries = ["BE", "BG", "DK", "DE", "EE", "FI", "FR", "GR", "IE", "IT", "HR", "LV", "LT", "LU", "MT", "NL", "AT", "PL", "PT", "RO", "SE", "SK", "SI", "ES", "CZ", "HU", "CY"]

  constructor(
    private http: HttpClient,
    private configurationStore: ConfigurationStore,
    private configurationQuery: ConfigurationQuery,
    private daClickpathService: DaClickpathService,
    private daClickpathQuery: DaClickpathQuery,
    private daNextSlideService: DaNextSlideService,
    private dataQuery: DataQuery,
  ) { }

  //API-CALLS
  
  // retrieve and store the tenant uuid
  async getTenant() {
    let getId: any;
    getId = await firstValueFrom(this.http.get(`${environment.backendHost}/tenantuuid`));
    this.storeCustomContent(getId.tenantuuid);
    this.configurationStore.update({ tenantuuid: getId.tenantuuid })
  };

  // retrieve and store configuration from Strapi and replace empty keys with default values (Mandant 1)
  async storeCustomContent(id: string) {
    let header = new HttpHeaders({'finsoliotenantuuid': id});
    let getCustomContent: any;
    let defaultValues: any = defaultConfigurationItems;
    getCustomContent = await firstValueFrom(this.http.get(`${environment.backendHost}/configuration`, {headers: header}));
    Object.keys(getCustomContent).map(key => {
      if (!getCustomContent[key] && key !== 'grundeinstellungencss') {
        getCustomContent[key] = defaultValues[key];
      }
    });
    this.configurationStore.update({ configuration: getCustomContent })
  };

  getTenantUuid() {
    const id = this.configurationQuery.getValue();
    return id.tenantuuid;
  }

  // get City for PLZ Input
  async getByPlz(plz: string) {
    let city: any;
    city = await this.http.get(`${this.openDataUrl}postalCode=${plz}`);
    return city
  }

  // get PLZ for City Input
  async getByCity(city: string, page: string = '1') {
    let plz: any;
    plz = await this.http.get(`${this.openDataUrl}name=${city}&page=${page}&pageSize=50`);
    return plz
  }

  // VALIDATIONS

  //REGEX Patterns
  validatePattern(value: string, type: string) {
    let regex;

    switch (type) {
      case 'string': regex = /^[a-zA-ZäöüßÄÖÜ., -]*$/;
        break;
      case 'numbers': regex = /^[0-9]*$/;
        break;
      case 'currency': regex = /^[0-9.,]*$/;
        break;
      case 'city': regex = /^[a-zA-ZäöüßÄÖÜ -]*$/;
        break;
      case 'street': regex = /^[a-zA-ZäöüßÄÖÜ0-9. -]*$/;
        break;
      case 'streetNr': regex = /^[a-zA-Z0-9. /-]*$/;
        break;
      case 'email': regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/;
        break;
      default:
      case 'string': regex = /^[a-zA-ZäöüßÄÖÜ]*$/;
        break;
    }
    return regex.test(value);
  }

  // convert number to percent
  convertNumberToPercent(numberValue: number, total: number) {
    const percent = (numberValue * 100) / total;
    return percent;
  }

  // convert percent to number
  convertPercentToNumber(percentValue: number, total: number) {
    const number = total * (percentValue / 100)
    return number;
  }

  // convert german number format into number (1.00,00 to 1000)
  convertStringNumber(value: string, decimals: number = 0) {
    let noComma = value.replace(/\./g, '');
    noComma = noComma.replace(/\,/g, '.');
    return +parseFloat(noComma).toFixed(decimals);
  }

  // convert number into german number format (1000 to 1.000,00)
  convertNumberString(value: number, min: number = 2, max: number = 2) {
    return value.toLocaleString('de-DE', { minimumFractionDigits: min, maximumFractionDigits: max });
  }

  // convert string into german number format (1000 to 1.000,00)
  parseNumberString(value: string) {
    let noComma;
    if (value.indexOf(',') > 0) {
      noComma = value.slice(0, value.indexOf(','));
    }
    else {
      noComma = value;
    }
    const noDots = noComma.replace(/\./g, '');
    let number = +parseFloat(noDots);
    return number.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  // convert dates to europace formats
  convertDate(date: string) {
    let theDate = new Date(date);
    const offset = theDate.getTimezoneOffset()
    theDate = new Date(theDate.getTime() - (offset * 60 * 1000));
    return theDate.toISOString().split('T')[0];
  }

  //min max value for currency and year
  minMax(value: string, min: number, max: number) {
    let noComma;
    if (value.indexOf(',') > 0) {
      noComma = value.slice(0, value.indexOf(','));
    }
    else {
      noComma = value;
    }

    const noDots = noComma.replace(/\./g, '');
    if (parseInt(noDots) < min) {
      return { tooLow: { min } };
    } else if (parseInt(noDots) > max) {
      return { tooHigh: { max } };
    } else {
      return true;
    }
  }

  //min max length for currency and year
  minMaxLength(value: string, min: number, max: number) {
    if (value === '') {
      return null;
    }
    if (value.length < min) {
      return { tooShort: { min } };
    } else if (value.length > max) {
      return { tooLong: { max } };
    } else {
      return true;
    }
  }

  //min or max Value for Date Inputs

  subtractYears(date: any, years: number) {
    date.setFullYear(date.getFullYear() - years);
    return date;
  }

  addYears(date: any, years: number) {
    date.setFullYear(date.getFullYear() + years);
    return date;
  }

  subtractDay(date: any, day: number) {
    date.setDate(date.getDate() - day);
    return date.toLocaleDateString('de-DE');
  }

  addDay(date: any, day: number) {
    date.setDate(date.getDate() + day);
    return date;
  }

  validateReduxValue(e: any, element: any) {
    element.value = e ? e : '';
    element.touched = false;
    element.error = false;
    if (!!e) {
      element.touched = true;
      const isValid = this.validatePattern(e, element.validationType);
      element.error = !isValid;
    }
    return element;
  }

  validateReduxDate(e: any, element: any) {
    element.value = e ? e : '';
    element.touched = false;
    element.error = false;
    if (!!e) {
      element.touched = true;
      element.success = true;
    }
    return element;
  }

  validateYearValue(e: any, element: any, minMax?: any) {
    element.value = e ? e : '';
    element.touched = false;
    element.error = false;
    const isValidLength: any = this.minMaxLength(e, 4, 4);
    const isValidPattern: boolean = this.validatePattern(e, element.validationType);
    const isValidYear: any = this.minMax(e, minMax?.min ?? 1700, minMax?.max ?? 2060);

    let isValid = (isValidLength === true && isValidPattern && isValidYear === true) ? true : false;
    if (!!e) {
      element.touched = true;
      element.error = !isValid;
    }
    return element;
  }

  // NAVIGATION

  // dynamically change shown slides
  setSlideIndex(slide: string, exclude: boolean) {
    const sliderIndex = this.daClickpathQuery.getEntity(1)?.slideIndex as any;
    let updatedSliderIndex: any = [];
    let entry;
    if (sliderIndex && sliderIndex.length >= 1) {
      sliderIndex.forEach((item: any) => {
        if (item.id === slide) {
          entry = { ...item, excluded: exclude };
        } else {
          entry = item;
        }
        updatedSliderIndex.push(entry);
      });
    };
    this.daClickpathService.update(1, { slideIndex: updatedSliderIndex })
  }

  // change multiple progress items excluded
  setBulkSlideIndex(slides: string[], excluded: boolean) {
    const sliderIndex = this.daClickpathQuery.getEntity(1)?.slideIndex as any;
    let updatedSliderIndex: any = [];
    let entry: any;
    if (sliderIndex && sliderIndex.length >= 1) {
      sliderIndex.forEach((item: any) => {
        if (slides.indexOf(item.id) >= 0) {
          entry = { ...item, excluded: excluded};
        } else {
          entry = item;
        }
        updatedSliderIndex.push(entry);
      });
    };
    this.daClickpathService.update(1, { slideIndex: updatedSliderIndex })
  }

  // dynamically change progress item states and disabled
  setSlideIndexState(slide: string, disabled: boolean = false, state?: string) {
    const sliderIndex = this.daClickpathQuery.getEntity(1)?.slideIndex as any;
    let updatedSliderIndex: any = [];
    let entry;
    if (sliderIndex && sliderIndex.length >= 1) {
      sliderIndex.forEach((item: any) => {
        if (item.id === slide) {
          entry = { ...item, disabled: disabled, state: state ? state : item.state };
        } else {
          entry = item;
        }
        updatedSliderIndex.push(entry);
      });
    };
    this.daClickpathService.update(1, { slideIndex: updatedSliderIndex })
  }

  // change multiple progress items disabled
  setBulkSlideIndexState(slides: any) {
    const sliderIndex = this.daClickpathQuery.getEntity(1)?.slideIndex as any;
    let updatedSliderIndex: any = [];
    let entry: any;
    if (sliderIndex && sliderIndex.length >= 1) {
      sliderIndex.forEach((item: any) => {
        slides.map((slide: any) => {
          if (item.id === slide.id) {
            entry = { ...item, disabled: false};
          } else {
            entry = item;
          }
        });
        updatedSliderIndex.push(entry);
      });
    };
    this.daClickpathService.update(1, { slideIndex: updatedSliderIndex })
  }

  //get progressIndex of Slide
  getProgressIndex(slide: string) {
    const sliderIndex = this.daClickpathQuery.getEntity(1)?.slideIndex as any;
    let number = 0;
    sliderIndex.forEach((item: any) => {
      if (item.id === slide) {
        number = item.progressIndex;
      }
    });
    return number
  }

  //get next slide
  setNav(e: string, slide: string, emitter: any, nextSlide?: string) {
    if (nextSlide) {
      this.daNextSlideService.update(1, { next: nextSlide, timestamp: new Date });
      this.daClickpathService.updateClickPath(slide);
      emitter.emit(nextSlide);
    } else {
      if (e === 'next') {
        this.daClickpathService.updateClickPath(slide);
      }
      emitter.emit(e);
    }
  };

  // REDUX STORE COMMUNICATION
  // get a single Value
  getValue(field: string, counter?: boolean) {
    const status = this.dataQuery.getEntity(1)?.[field] ? this.dataQuery.getEntity(1)?.[field] : counter ? 0 : '';
    return status;
  }

  // get a nested Value
  getNestedValue(parent: string, field: string, id: number) {
    const status = this.dataQuery.getEntity(1)?.[parent];
    let value!: string;
    if (!!status) {
      status.map((item: any) => {
        if (item.id === id) {
          value = item[field];
        }
      })
    }
    return value as string;
  }

  // get a nested Object
  getNestedObject(parent: string, field: string, id: number) {
    const status = this.dataQuery.getEntity(1)?.[parent];
    let value!: any;
    if (!!status) {
      status.map((item: any) => {
        if (item.id === id) {
          value = item[field];
        }
      })
    }
    return value
  }

  // update a nested Object
  updateNestedObject(parent: string, field: string, value: any, id: number, resetField?: string, resetValue?: any) {

    const status = this.dataQuery.getEntity(1)?.[parent];
    let object: any[] = [];
    if (!!status) {
      status.map((item: any) => {
        let newItem = { ...item };
        if (item.id === id) {
          newItem[field] = value;

          if (resetField) {
            newItem[resetField] = resetValue;
          }
        }
        object.push(newItem);
      });
    }
    return object
  }

  // update mutliple Values in a nested Object
  updateNestedObjectMultiple(parent: string, changes: any, id: number) {

    const status = this.dataQuery.getEntity(1)?.[parent];
    let object: any[] = [];
    if (!!status) {
      status.map((item: any) => {
        let newItem = { ...item };
        if (item.id === id) {

          changes.map((change: any) => {
            newItem[change.key] = change.value;
          })
        }
        object.push(newItem);
      });
    }

    return object
  }

  // get a dropdown value
  getSelectedItem(value: any, element: any) {
    element.selectItems.map((item: any) => {
      if (value === item.value) {
        element.selectedItem = item;
        element.success = true;
      }
    })
    return element
  }

  // TILE UPDATES

  setTileChecked(tiles: any, value: string) {
    tiles.map((tile: { value: string; checked: boolean; }) => {
      if (tile.value === value) {
        tile.checked = true;
      } else {
        tile.checked = false;
      }
    });
    return tiles;
  }

  setTileCheckedCheckbox(tiles: any, value: string) {
    tiles.map((tile: { value: string; checked: boolean; }) => {
      if (tile.value === value) {
        tile.checked = true;
      }
    });
    return tiles;
  }

  setTileStatus(tiles: any, value: string, typeA: string, typeB: string) {
    tiles.map((tile: { value: string; state: string; }) => {
      if (tile.value === value) {
        tile.state = typeA;
      } else {
        tile.state = typeB;
      }
    });
    return tiles;
  }

  setTileStatusCheckbox(tiles: any, value: string, type: string) {
    tiles.map((tile: { value: string; state: string; }) => {
      if (tile.value === value) {
        tile.state = type;
      }
    });
    return tiles;
  }

  setTileStatusChecked(tiles: any, value: string, type: string, checked: boolean) {
    tiles.map((tile: { value: string; state: string; checked: boolean; }) => {
      if (tile.value === value) {
        tile.state = type;
        tile.checked = checked;
      }
    });
    return tiles;
  }

  resetTileStatus(tiles: any) {
    tiles.map((tile: { checked: boolean; }) => {
      tile.checked = false;
    });
    return tiles;
  }

  setTilesContent(tiles: any, value: string, content: any) {
    tiles.map((tile: { value: string; content: any; }) => {
      if (tile.value === value) {
        tile.content = content;
      }
    });
    return tiles;
  }

  // UPLOADING FILES

  /** 
   * upload file to api
   */
  async uploadFile(file: FormData) {
    let header = new HttpHeaders({'finsoliotenantuuid': this.getTenantUuid()});
    let result: any;
    result = await this.http.post<any>(this.apiBackendUrl + '/document/', file, {headers: header});
    return result;
  }

  /**
   * send uploaded files information to Europace
   * @param id string
   * @returns POST Request
   */
  public async sendFiles(id: string) {
    let header = new HttpHeaders({'finsoliotenantuuid': this.getTenantUuid()});
    const data = this.dataQuery.getEntity(1);
    let fileArray: any[] = [];
    let result: any;
    if (!!data) {
      if (data['einkommenFiles']) {
        fileArray = this.processFiles(data['einkommenFiles'], fileArray);
      }
      if (data['exposeFiles']) {
        fileArray = this.processFiles(data['exposeFiles'], fileArray);
      }
    }

    let request = {
      "financerequestid": id,
      "docRef": fileArray
    };
    result = this.http.post(this.apiUrl + '/finance-requests-documents', request, {headers: header});
    return result;
  }

  /**
   * extract generated file names from generated file url
   * @param files array
   * @param fileArray array
   * @returns processed files
   */
  private processFiles(files: any[], fileArray: any[]) {
    files.map((file: { url: string; }) => {
      const fileNameIndex = file.url.lastIndexOf('/');
      const fileName = file.url.substr(fileNameIndex + 1);
      fileArray.push(fileName);
    });
    return fileArray;
  }

  /**
   * converts a string with html in it to functional html
   */
  convertStringToHtmlForClass(content: string, elementClass: string, elementIndex: number) {
    let element = document.getElementsByClassName(elementClass);
    element[elementIndex].innerHTML = content;
  }
}


