import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DataStore, DataState } from './data.store';

@Injectable({ providedIn: 'root' })
export class DataQuery extends QueryEntity<DataState> {

  constructor(protected override store: DataStore) {
    super(store);
  }

}
