<lib-header-text
    class="da-title"
    label="{{'OBJECT_NUTZUNG.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>

<!-- JA / NEIN -->
<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setFutureValue($event)"
></lib-tile-group>

<!-- HOW - SUBTILES -->
<div class="da-secondary-container">
    <lib-header-text
        *ngIf="!!contentService.getValue('objectNutzungFuture')"
        class="da-title"
        label="{{subtitle | translate}}"
        size="38px"
        [convertMobile]="true"
    ></lib-header-text>

    <lib-tile-group
        *ngIf="!!contentService.getValue('objectNutzungFuture')"
        class="da-tiles-wrapper"
        [tiles]="subTiles"
        [localValue]="localValueSub"
        (tileValue)="setParentValue($event)"
    >
        <ng-template #contentTeil>
            <div class="da-tile-content top">
                <lib-tile-text
                    size="16px"
                    label="{{'OBJECT_NUTZUNG.TILES.TITLE1' | translate}}"
                    info="true"
                    (infoValue)="screen.openInfo(sizeInfo)"
                    [light]="true"
                ></lib-tile-text>
                <lib-input
                    #objectTeilSize
                    label="{{'COMMON.LABELS.SIZE' | translate}}"
                    name="teilSize"
                    [error]="false"
                    placeholder="50,25"
                    unit="m²"
                    errorText="{{teilSizeError | translate}}"
                    [touched]="!!contentService.getValue('objectVermietetewohnflaeche')"
                    [inputDisabled]="inputDisabled"
                    [value]="!!contentService.getValue('objectVermietetewohnflaeche') ? contentService.getValue('objectVermietetewohnflaeche') : ''"
                    (change)="validateInputValue($event, objectTeilSize)"
                    (click)="testValid = true"
                    [light]="true"
                ></lib-input>
            </div>
            <div class="da-tile-content">
                <lib-tile-text
                    size="16px"
                    label="{{'OBJECT_NUTZUNG.TILES.TITLE2' | translate}}"
                    info="true"
                    (infoValue)="screen.openInfo(sollInfo)"
                    [light]="true"
                ></lib-tile-text>
                <lib-input
                    #objectTeilRent
                    label="{{'COMMON.LABELS.SUMME' | translate}}"
                    name="teilRent"
                    [error]="false"
                    placeholder="500,00"
                    unit="€"
                    errorText="{{teilRentError | translate}}"
                    [touched]="!!contentService.getValue('objectMieteinnahmenwohnflaeche')"
                    [inputDisabled]="inputDisabled"
                    [value]="!!contentService.getValue('objectMieteinnahmenwohnflaeche') ? contentService.getValue('objectMieteinnahmenwohnflaeche') : ''"
                    (change)="validateInputValue($event, objectTeilRent)"
                    (click)="testValid = true"
                    [light]="true"
                ></lib-input>
            </div>
        </ng-template>
        <ng-template #contentFull>
            <div class="da-tile-content top">
                <lib-tile-text
                    size="16px"
                    label="{{'OBJECT_NUTZUNG.TILES.TITLE2' | translate}}"
                    info="true"
                    (infoValue)="screen.openInfo(sollInfo)"
                    [light]="true"
                ></lib-tile-text>
                <lib-input
                    #objectFullRent
                    label="{{'COMMON.LABELS.SUMME' | translate}}"
                    name="fullRent"
                    [error]="false"
                    placeholder="500,00"
                    unit="€"
                    errorText="{{fullRentError | translate}}"
                    [touched]="!!contentService.getValue('objectMieteinnahmenwohnflaeche')"
                    [inputDisabled]="inputDisabled"
                    [value]="!!contentService.getValue('objectMieteinnahmenwohnflaeche') ? contentService.getValue('objectMieteinnahmenwohnflaeche') : ''"
                    (change)="validateInputValue($event, objectFullRent)"
                    (click)="testValid = true"
                    [light]="true"
                ></lib-input>
            </div>
        </ng-template>
    </lib-tile-group>
</div>

<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

<ng-template #sizeInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'OBJECT_NUTZUNG.INFO.INFO_HEADER1' | translate}}" text="{{'OBJECT_NUTZUNG.INFO.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #sollInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'OBJECT_NUTZUNG.INFO.INFO_HEADER2' | translate}}" text="{{'OBJECT_NUTZUNG.INFO.INFO_TEXT_SOLL' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #istInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'OBJECT_NUTZUNG.INFO.INFO_HEADER2' | translate}}" text="{{'OBJECT_NUTZUNG.INFO.INFO_TEXT_IST' | translate}}"></app-info-sidebar>
</ng-template>

