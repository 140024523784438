<div #parent class="da-lightbox" [ngClass]="{'mobile' : screen.isSmallest(), 'is-tablet' : screen.isMobileOrSmallTablet(), 'is-medium' : screen.isFirstResultBreakpoint()}">
    <div class="da-close-icon" [ngClass]="{'mobile' : screen.isSmallest()}" (click)="dismiss()">
        <svg-icon
            src="assets/images/chevronRight.svg"
        >
        </svg-icon>
    </div>
    <div class="da-modal-body">
        <div class="da-header-icon">
            <svg-icon
                src="assets/images/fileSignature.svg"
            >
            </svg-icon>
        </div>
        <lib-header-text
            class="da-lightbox-header"
            size="38px"
            [convertMobile]="true"
            [light]="true"
            label="{{'EXPRESS.TITLE' | translate}}"
        ></lib-header-text>
        <!-- SCREEN1 -->
        <ng-container *ngIf="!done && !loading">
            <lib-tile-text
                *ngIf="withPartner"
                class="da-express-header"
                size="20px"
                [convertMobile]="true"
                [light]="true"
                label="{{'EXPRESS.TEXT_PARTNER' | translate: {anredeDN1: anredeDN1, nachnameDN1: nachnameDN1, anredeDN2: anredeDN2, nachnameDN2: nachnameDN2} }}"
            ></lib-tile-text>
            <lib-tile-text
                *ngIf="!withPartner"
                class="da-express-header"
                size="20px"
                [convertMobile]="true"
                [light]="true"
                label="{{'EXPRESS.TEXT_ALONE' | translate: {anredeDN1: anredeDN1, nachnameDN1: nachnameDN1} }}"
            ></lib-tile-text>
            <div class="da-lightbox-content">
                <app-container-single *ngIf="ergebnis && ergebnis.darlehen.length === 1" [ergebnis]="ergebnis"></app-container-single>
                <app-container-multiple *ngIf="ergebnis && ergebnis.darlehen.length > 1" [ergebnis]="ergebnis" [parentWidth]="parentWidth"></app-container-multiple>
                <div class="da-express-wrapper">
                    <!-- DOWNLOAD1 -->
                    <!-- <div class="da-express-row download" [ngClass]="{'mobile' : screen.isSmallest()}">
                        <div class="da-express-checkbox">
                        </div>
                        <a class="da-express-link" href="#">
                            <svg-icon class="da-express-link-icon" src="assets/images/filePdf.svg"></svg-icon>
                            <span class="da-express-link-text">{{'EXPRESS.DOCUMENTS.VORSCHLAG' | translate}}</span>
                            <svg-icon class="da-express-link-download-icon" src="assets/images/circleDown.svg"></svg-icon>
                        </a>
                    </div> -->
                    <!-- LABEL1 -->
                    <div class="da-express-row">
                        <div class="da-express-checkbox">
                            <lib-toggle-checkbox 
                                #label1
                                name="label1"
                                label="" 
                                [light]="true"
                                (change)="setSwitch($event)"
                            ></lib-toggle-checkbox>
                        </div>
                        <lib-tile-text
                            size="16px"
                            [convertMobile]="true"
                            [light]="true"
                            label="{{'EXPRESS.LABELS.LABEL1' | translate }}"
                        ></lib-tile-text>
                    </div>
                    <!-- LABEL2 -->
                    <div class="da-express-row">
                        <div class="da-express-checkbox">
                            <lib-toggle-checkbox 
                                #label2
                                name="label2"
                                label="" 
                                [light]="true"
                                (change)="setSwitch($event)"
                            ></lib-toggle-checkbox>
                        </div>
                        <lib-tile-text
                            size="16px"
                            [convertMobile]="true"
                            [light]="true"
                            label="{{'EXPRESS.LABELS.LABEL2' | translate }}"
                        ></lib-tile-text>
                    </div>
                    <!-- DOWNLOAD2 -->
                    <div class="da-express-row download" [ngClass]="{'mobile' : screen.isSmallest()}">
                        <div class="da-express-checkbox">
                        </div>
                        <div class="da-express-link-wrapper">
                            <a class="da-express-link" href="#">
                                <svg-icon class="da-express-link-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <span class="da-express-link-text">{{'EXPRESS.DOCUMENTS.VORVERTRAG' | translate}}</span>
                                <svg-icon class="da-express-link-download-icon" src="assets/images/circleDown.svg"></svg-icon>
                            </a>
                            <!-- <a class="da-express-link" href="#">
                                <svg-icon class="da-express-link-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <span class="da-express-link-text">{{'EXPRESS.DOCUMENTS.AUSKUNFT' | translate}}</span>
                                <svg-icon class="da-express-link-download-icon" src="assets/images/circleDown.svg"></svg-icon>
                            </a> -->
                            <!-- <a class="da-express-link" href="#">
                                <svg-icon class="da-express-link-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <span class="da-express-link-text">{{'EXPRESS.DOCUMENTS.ANTWORT' | translate}}</span>
                                <svg-icon class="da-express-link-download-icon" src="assets/images/circleDown.svg"></svg-icon>
                            </a> -->
                        </div>
                    </div>
                    <!-- LABEL3 -->
                    <div class="da-express-row">
                        <div class="da-express-checkbox">
                            <lib-toggle-checkbox 
                                #label3
                                name="label3"
                                label="" 
                                [light]="true"
                                (change)="setSwitch($event)"
                            ></lib-toggle-checkbox>
                        </div>
                        <lib-tile-text
                            size="16px"
                            [convertMobile]="true"
                            [light]="true"
                            label="{{'EXPRESS.LABELS.LABEL3' | translate }}"
                        ></lib-tile-text>
                    </div>
                    <!-- DOWNLOAD3 -->
                    <div class="da-express-row download" [ngClass]="{'mobile' : screen.isSmallest()}">
                        <div class="da-express-checkbox">
                        </div>
                        <div class="da-express-link-wrapper">
                            <a class="da-express-link" href="#">
                                <svg-icon class="da-express-link-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <span class="da-express-link-text">{{'EXPRESS.DOCUMENTS.ESIS' | translate}}</span>
                                <svg-icon class="da-express-link-download-icon" src="assets/images/circleDown.svg"></svg-icon>
                            </a>
                            <a class="da-express-link" href="#">
                                <svg-icon class="da-express-link-icon" src="assets/images/filePdf.svg"></svg-icon>
                                <span class="da-express-link-text">{{'EXPRESS.DOCUMENTS.DATEN' | translate}}</span>
                                <svg-icon class="da-express-link-download-icon" src="assets/images/circleDown.svg"></svg-icon>
                            </a>
                        </div>
                    </div>
                    <!-- LABEL4 -->
                    <div class="da-express-row">
                        <div class="da-express-checkbox">
                            <lib-toggle-checkbox 
                                #label4
                                name="label4"
                                label="" 
                                [light]="true"
                                (change)="setSwitch($event)"
                            ></lib-toggle-checkbox>
                        </div>
                        <lib-tile-text
                            size="16px"
                            [convertMobile]="true"
                            [light]="true"
                            label="{{'EXPRESS.LABELS.LABEL4' | translate }}"
                        ></lib-tile-text>
                    </div>
                </div>
            </div>
            <div class="da-address-button-wrapper" *ngIf="showButtons">
                <div class="da-sidebar-button-bg">
                    <lib-button 
                        name="express button"
                        value="{{'EXPRESS.BUTTONS.ANTRAG' | translate}}" 
                        [disabled]="screen1Disabled"
                        [light]="true"
                        width="300"
                        (buttonValue)="finishInput()"
                        iconRight="assets/images/chevronRight.svg"
                    ></lib-button>
                </div>
            </div>
        </ng-container>
        <!-- WAITING -->
        <ng-container *ngIf="!done && loading">
            <mat-progress-spinner mode="indeterminate" diameter="150" class="da-express-loader"></mat-progress-spinner>
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                [light]="true"
                label="{{'EXPRESS.TEXT_PAUSE' | translate }}"
            ></lib-tile-text>
        </ng-container>
        <!-- SCREEN2 -->
        <ng-container *ngIf="done">
            <div class="da-done-icon">
                <svg-icon
                    src="assets/images/checkSolid.svg"
                >
                </svg-icon>
            </div>
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                [light]="true"
                label="{{'EXPRESS.DONE.TEXT1' | translate }}"
            ></lib-tile-text>
            <div class="da-express-done-wrapper">
                <lib-header-text
                    size="25px"
                    [convertMobile]="false"
                    [light]="true"
                    label="{{'EXPRESS.DONE.SUB_TITLE' | translate }}"
                ></lib-header-text>
                <lib-tile-text
                    size="20px"
                    [convertMobile]="true"
                    [light]="true"
                    label="{{'EXPRESS.DONE.TEXT2' | translate }}"
                ></lib-tile-text>
                <div class="da-content-button-wrapper">
                    <lib-button 
                        name="appt button"
                        value="{{'EXPRESS.BUTTONS.TERMIN' | translate}}" 
                        [light]="true"
                        [autoWidth]="true"
                        (buttonValue)="screen.openInfo(calendly)"
                        iconRight="assets/images/arrowUpRightFromSquare.svg"
                    ></lib-button>
                </div>
                <lib-tile-text
                    size="20px"
                    [convertMobile]="true"
                    [light]="true"
                    label="{{'EXPRESS.DONE.TEXT3' | translate }}"
                ></lib-tile-text>
                <div class="da-content-button-wrapper">
                    <lib-button 
                        name="upload button"
                        value="{{'EXPRESS.BUTTONS.UPLOAD' | translate}}" 
                        [light]="true"
                        [autoWidth]="true"
                        (buttonValue)="screen.openInfo(overview)"
                        iconRight="assets/images/chevronRight.svg"
                    ></lib-button>
                </div>
                <lib-tile-text
                    size="20px"
                    [convertMobile]="true"
                    [light]="true"
                    label="{{'EXPRESS.DONE.TEXT4' | translate }}"
                ></lib-tile-text>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #overview let-modal>
    <app-overview 
    [modal]="modal" 
    [chapter]="1"
    ></app-overview>
</ng-template>
<ng-template #calendly let-modal>
    <app-calendly 
    [modal]="modal" 
    ></app-calendly>
</ng-template>
