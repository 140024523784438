<div class="da-lightbox">
    <div class="da-modal-body">
        <div class="da-close-icon" [ngClass]="{'mobile' : screen.isSmallest()}" (click)="dismiss()">
            <svg-icon
                src="assets/images/chevronRight.svg"
            >
            </svg-icon>
        </div>
        <div class="da-info-icon">
            <svg-icon
                src="assets/images/mapLocation.svg"
            >
            </svg-icon>
        </div>
        <lib-header-text
            class="da-info-header"
            size="38px"
            [convertMobile]="true"
            [light]="true"
            label="{{'ADRESS_EINGABE.TITLE' | translate}}"
        ></lib-header-text>
        <lib-header-text
            size="16px"
            [convertMobile]="true"
            [light]="true"
            label="{{'ADRESS_EINGABE.SUB_TITLE' | translate}}"
        ></lib-header-text>
        <div class="da-info-content">
            <div class="da-address-wrapper">
                <lib-tile-text
                    class="da-info-content-headline"
                    label="{{'ADRESS_EINGABE.TEXT' | translate}}"
                    [light]="true"
                    size="20px"
                    [ngStyle]="{'margin-left' : '25px'}"
                ></lib-tile-text>
                <div class="da-address-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <div class="small">
                        <lib-input
                            #objectPlz
                            label="{{'ADDRESS.LABELS.PLZ' | translate}}"
                            name="plz"
                            [error]="false"
                            placeholder="12345"
                            errorText="{{'ADDRESS.ERRORS.PLZ' | translate}}"
                            [touched]="!!currentAddress.plz"
                            [value]="currentAddress.plz"
                            (keyup)="getPlz($event, objectPlz)"
                            [isCurrency]="false"
                            [inputDisabled]="plzDisabled"
                            [autoWidth]="true"
                            [light]="true"
                        ></lib-input>
                    </div>
                    <div class="wide">
                        <lib-input
                            #objectOrt
                            label="{{'ADDRESS.LABELS.ORT' | translate}}"
                            name="ort"
                            validationType="city"
                            inputmode=""
                            [error]="false"
                            placeholder="Musterstadt"
                            errorText="{{'ADDRESS.ERRORS.ORT' | translate}}"
                            [touched]="!!currentAddress.ort"
                            [value]="currentAddress.ort"
                            [inputDisabled]="cityDisabled"
                            (keyup)="getCity($event, objectOrt)"
                            [isCurrency]="false"
                            [autoWidth]="true"
                            [light]="true"
                        ></lib-input>
                    </div>
                </div>
                <div class="da-address-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <div class="wide">
                        <lib-input
                            #objectStrasse
                            label="{{'ADDRESS.LABELS.STRASSE' | translate}}"
                            name="strasse"
                            validationType="street"
                            inputmode=""
                            [error]="false"
                            placeholder="Musterstrasse"
                            errorText="{{'ADDRESS.ERRORS.STREET' | translate}}"
                            [touched]="!!currentAddress.strasse"
                            [value]="currentAddress.strasse"
                            (keyup)="validateInputValue($event, objectStrasse)"
                            [isCurrency]="false"
                            [autoWidth]="true"
                            [light]="true"
                        ></lib-input>
                    </div>
                    <div class="small">
                        <lib-input
                            #objectNr
                            label="{{'ADDRESS.LABELS.NR' | translate}}"
                            name="hausnummer"
                            validationType="streetNr"
                            inputmode=""
                            [error]="false"
                            placeholder="12"
                            errorText="{{'ADDRESS.ERRORS.NR' | translate}}"
                            [touched]="!!currentAddress.hausnummer"
                            [value]="currentAddress.hausnummer"
                            (keyup)="validateInputValue($event, objectNr)"
                            [isCurrency]="false"
                            [autoWidth]="true"
                            [light]="true"
                        ></lib-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="da-address-button-wrapper">
            <lib-button 
                name="address button"
                value="{{'ADRESS_EINGABE.BUTTON' | translate}}" 
                [disabled]="!inputValid"
                [light]="true"
                width="230"
                (buttonValue)="finishAdressInput()"
                iconRight="assets/images/disk.svg"
            ></lib-button>
        </div>
    </div>
</div>