import { Component, Input, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { LayoutService } from 'src/app/services/layout.service';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { UserService } from 'src/app/state/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss']
})
export class FrameComponent implements OnInit {
  @Input() webapp?: boolean;
  @Input() webappType?: string;
  topic!: string;
  currentSlide!: string;
  stateChange!: boolean;
  accessChange!: boolean;
  footerVisible: boolean = false;
  background: any = 'body-base';
  menuIcon: string = 'assets/images/circleInfo.svg';
  userIsLoggedIn: boolean = false;
  environment = environment;

  constructor (
    private modalService: NgbModal,
    public screen: LayoutService,
    private userService: UserService,
    private daService: DataService,
    private daNextSlideService: DaNextSlideService
  ) {}

  ngOnInit(): void {
    this.userIsLoggedIn = this.userService.isLoggedInForLoginLight();

    setTimeout(() => {
      const currentSlide = this.daService.getNextSlide();
      if(!!currentSlide) {
        this.currentSlide = currentSlide;
      }
    }, 1000)
  }

  dismiss() {
    document.body.style.overflowY = 'auto';
    this.modalService.dismissAll();
  }

  setStateChange(e: any) {
    if(e === 'endItem') {
      this.accessChange = true;
    } else {
      this.stateChange = e; // e === progress
    }
  }

  getButtonValue(event: string, modal?: any) {
    switch (event) {
      case 'menu':
        this.footerVisible = !this.footerVisible;
        if(this.footerVisible) {
          this.menuIcon = 'assets/images/chevronDown.svg';
        } else {
          this.menuIcon = 'assets/images/circleInfo.svg';
        }
        break;
      case 'close': 
        this.dismiss();
        break;
      case 'overview': 
        this.screen.openInfo(modal);
        break;
      default:
        break;
    }
  }

  setCurrentSlide(e: string) {
    this.currentSlide = e;
    this.daNextSlideService.update(1, { next: e, timestamp: new Date });
  }
}
