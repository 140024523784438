import { trigger, state, style, transition, group, animate } from '@angular/animations';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  animations: [
    trigger('slideUpDown', [
      state('true', style({
        'max-height': '100%', opacity: '1', visibility: 'visible', marginBottom: '0px'
      })),
      state('false', style({
        'max-height': '0px', opacity: '0', visibility: 'hidden', marginBottom: '0px'
      })),
      transition('true => false', [group([
        animate('800ms ease-in-out', style({
          opacity: '0'
        })),
        animate('800ms ease-in-out', style({
          'max-height': '0px'
        })),
        animate('800ms ease-in-out', style({
          visibility: 'hidden'
        })),
        animate('800ms ease-in-out', style({
          'marginBottom': '0px'
        }))
      ]
      )]),
      transition('false => true', [group([
        animate('800ms ease-in-out', style({
          visibility: 'visible'
        })),
        animate('800ms ease-in-out', style({
          'max-height': '100%'
        })),
        animate('800ms ease-in-out', style({
          opacity: '1'
        })),
        animate('800ms ease-in-out', style({
          'marginBottom': '0px'
        }))
      ]
      )])
    ])
  ]
})
export class OverviewComponent implements OnInit {

  chapters: any = [];

  @Input() modal!: NgbModalRef;
  @Input() chapter!: number;
  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
  ) { 
    this.initChapterContent();
  }

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
    if(this.chapter) {
      this.setActive(this.chapters[this.chapter], true);
    }
  }

  initChapterContent() {

    this.chapters = [
      {
        id: 0,
        title: 'OVERVIEW.TILES.TILE1',
        icon: 'assets/images/listCheck.svg',
        className: 'da-tasks',
        active: false,
      },
      {
        id: 1,
        title: 'OVERVIEW.TILES.TILE2.HEADER',
        subtitle: 'OVERVIEW.TILES.TILE2.SUB_HEADER',
        icon: 'assets/images/fileArrowUp.svg',
        className: 'da-upload',
        active: false,
      }
    ]
  }

  setActive(data: { id: number, active: boolean; title: string; className: string; }, isPath: boolean = false) {
    setTimeout(() => {
      data.active = isPath ? true : !data.active;

    }, 500);
    setTimeout(() => {
      if(data.active) {
        this.screen.scrollToElement(data.className, 'start');
      }
    }, 1000);
  }

  getButtons(e: any) {
    // console.log('Overview', e);
    if(e === 'link-part2-2') {
      this.setActive(this.chapters[1], true);
    }

  }

  dismiss() {
    document.body.style.overflowY = 'auto';
    this.modal.dismiss();
  }

}
