import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';


@Component({
  selector: 'app-kapital-forward',
  templateUrl: './kapital-forward.component.html',
  styleUrls: ['./kapital-forward.component.scss']
})
export class KapitalForwardComponent implements OnInit {
  @Input() inputDisabled!: boolean;
  @Input() testValid!: boolean;
  @Output() sum: EventEmitter<string> = new EventEmitter();
  @Output() state: EventEmitter<any> = new EventEmitter();
  error: boolean = false;
  isVisible: boolean = true;
  containerState: string = 'optional';

  @ViewChild('kapitalBetrag') kapitalBetrag!: any;
  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    public dataQuery: DataQuery,
    public dataService: DataService
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (!!this.contentService.getValue('projectPlan')) {
        if (this.contentService.getValue('projectPlan') === 'KAPITALBESCHAFFUNG') {
          this.containerState = 'warning';
          this.dataService.update(1, { kapitalbeschaffung: 'true' });
          this.setValid();
        }
      }
      if (this.contentService.getValue('kapitalbeschaffung')) {
        this.setValid();
      }
    }, 200)
  }

  setSwitch(e: string, name: string) {
    this.storeValue(e, name);
    setTimeout(() => {
      this.setValid();
    }, 200)
  }

  validateInput(e: any, element: any) {
    let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
    element.touched = value ? true : false;
    element.placeholder = '';
    const isValid = this.contentService.validatePattern(value, element.validationType);
    const isValidAmount = this.contentService.minMax(value, 5, 999999);
    element.error = isValid && isValidAmount === true ? false : true;
    if (!element.error) {
      this.storeValue(value, element.name);
    }
    setTimeout(() => {
      this.setValid();
    }, 200)
  }

  storeValue(e: any, name: string) {
    let value = e.target?.value ? e.target?.value : e;
    if (name.includes('kapitalbeschaffung')) {
      this.dataService.update(1, { kapitalbeschaffung: value });
    } else {
      this.dataService.update(1, { kapitalbeschaffungBetrag: value });
      this.sum.emit()
    }
  }

  setValid() {

    let warnings = 0;
    let errors = 0;

    if (!!this.contentService.getValue('kapitalbeschaffung') && this.contentService.getValue('kapitalbeschaffung') === 'true') {
      if (!this.contentService.getValue('kapitalbeschaffungBetrag') || this.contentService.getValue('kapitalbeschaffungBetrag') === '') {
        warnings++;
      }
    }

    if (this.kapitalBetrag) {
      if (this.kapitalBetrag.error) {
        errors++;
      }
    }

    this.containerState = errors > 0 ? 'error' : warnings > 0 ? 'warning' : 'success';
    this.state.emit({ type: 'kapital', state: this.containerState });
  }
}
