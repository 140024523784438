import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { switchesContent } from 'src/assets/content/switches';

@Component({
  selector: 'app-mf-house-detail',
  templateUrl: './mf-house-detail.component.html',
  styleUrls: ['./mf-house-detail.component.scss']
})
export class MfHouseDetailComponent implements OnInit {
  public slideName: string = 'mf-house-detail';
  next: string = 'condition-estate';
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  sizeError: string = 'COMMON.ERRORS.NUMBER';
  houseError: string = 'COMMON.ERRORS.NUMBER';
  gewerbeError: string = 'COMMON.ERRORS.NUMBER';
  rentedGError: string = 'COMMON.ERRORS.NUMBER';
  rentGError: string = 'COMMON.ERRORS.NUMBER';
  rentedWError: string = 'COMMON.ERRORS.NUMBER';
  rentWError: string = 'COMMON.ERRORS.NUMBER';

  wohneinheiten: number = 0;
  gewerbeeinheiten: number = 0;
  switchesNoWohnen = switchesContent['PROPERTY_CHANGE_MF_NO_WOHNEN'].items;
  switchesNoGewerbe = switchesContent['PROPERTY_CHANGE_MF_NO_GEWERBE'].items;
  switchesYes = switchesContent['PROPERTY_CHANGE_MF_YES'].items;

  container1State: string = 'warning';
  container2State: string = 'warning';
  container3State: string = 'warning';
  container4State: string = 'warning';
  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';


  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('objectEstateSize') objectEstateSize!: any;
  @ViewChild('objectHouseSize') objectHouseSize!: any;
  @ViewChild('objectYear') objectYear!: any;
  @ViewChild('objectWohneinheiten') objectWohneinheiten!: any;
  @ViewChild('objectGewerbeeinheiten') objectGewerbeeinheiten!: any;
  @ViewChild('objectWohnenChange') objectWohnenChange!: any;
  @ViewChild('objectNutzungwohnflaeche') objectNutzungwohnflaeche!: any;
  @ViewChild('objectNutzunggewerbeflaeche') objectNutzunggewerbeflaeche!: any;
  @ViewChild('objectRentedSizeWohnen') objectRentedSizeWohnen!: any;
  @ViewChild('objectRentWohnen') objectRentWohnen!: any;
  @ViewChild('objectRentedSizeGewerbe') objectRentedSizeGewerbe!: any;
  @ViewChild('objectRentGewerbe') objectRentGewerbe!: any;


  @ViewChild('objectGewerbeSize') objectGewerbeSize!: any;
  @ViewChild('objectRentedSize') objectRentedSize!: any;
  @ViewChild('objectRent') objectRent!: any;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService
  ) {}

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit() {
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.setStatus();
    });
  }

  setStatus() {
    const status = this.dataQuery.getEntity(1);

    if (!!status) {
      if (status['objectGrundstuecksgroesse']) {
        this.objectEstateSize = this.contentService.validateReduxValue(status['objectGrundstuecksgroesse'], this.objectEstateSize);
      }
      if (status['objectGesamtflaeche']) {
        this.objectHouseSize = this.contentService.validateReduxValue(status['objectGesamtflaeche'], this.objectHouseSize);
      }
      if (status['objectBaujahr']) {
        this.objectYear = this.contentService.validateReduxValue(status['objectBaujahr'], this.objectYear);
      }
      if (status['objectAnzahlDerWohneinheiten']) {
        this.wohneinheiten = status['objectAnzahlDerWohneinheiten'];
      }
      if (status['objectAnzahlDerGewerbeeinheiten']) {
        this.gewerbeeinheiten = status['objectAnzahlDerGewerbeeinheiten'];
      }
      setTimeout(() => {
        if (status['objectGewerbeGesamtflaeche'] && this.objectGewerbeSize) {
          this.objectGewerbeSize = this.contentService.validateReduxValue(status['objectGewerbeGesamtflaeche'], this.objectGewerbeSize);
        }
        if (status['objectGewerbeVermietet'] && this.objectRentedSize) {
          this.objectRentedSize = this.contentService.validateReduxValue(status['objectGewerbeVermietet'], this.objectRentedSize);
        }
        if (status['objectGewerbeMiete'] && this.objectRent) {
          this.objectRent = this.contentService.validateReduxValue(status['objectGewerbeMiete'], this.objectRent);
        }
      });
    }
    setTimeout(() => { this.setContainerState('init') });
  }

  runValidation(e: string) {
    setTimeout(() => {
      if(e === 'next') {
        if(this.allState !== 'success') {
          this.showState = true;
          this.setContainerState();
        } else {
          this.setContainerState('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 100)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  validateInputValue(e: any, element: any) {
      let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
      element.touched = !!value ? true : false;
      element.placeholder = '';
      const isValid = this.contentService.validatePattern(value, element.validationType);
      let isValidAmount: any;
      const estateSize = this.contentService.convertStringNumber(this.objectEstateSize.value);

      switch (element.name) {
        case 'estateSize': {
          isValidAmount = this.contentService.minMax(value, 25, 99999);
          this.sizeError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
        };
          break;
        case 'houseSize': {
          isValidAmount = this.contentService.minMax(value, 25, 999);
          this.houseError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
        };
          break;
        case 'gewerbeSize': {
          isValidAmount = this.contentService.minMax(value, 25, 9999);
          this.gewerbeError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
        };
          break;
        case 'rentedSizeGewerbe': {
          const gewerbeSize = this.contentService.convertStringNumber(this.objectGewerbeSize.value);
          isValidAmount = this.contentService.minMax(value, 25, (gewerbeSize > 0 && gewerbeSize <= 9999) ? gewerbeSize : 9999);
          this.rentedGError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
          this.rentedGError = isValidAmount?.tooHigh && (gewerbeSize > 0 && gewerbeSize <= 9999) ? 'COMMON.ERRORS.TOO_HIGH_RENT_GEWERBE' : this.rentedGError;
        };
          break;
        case 'rentGewerbe': {
          isValidAmount = this.contentService.minMax(value, 50, 999999);
          this.rentGError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
        };
          break;
        case 'rentedSizeWohnen': {
          const wohnenSize = this.contentService.convertStringNumber(this.objectHouseSize.value);
          isValidAmount = this.contentService.minMax(value, 25, (wohnenSize > 0 && wohnenSize <= 9999) ? wohnenSize : 9999);
          this.rentedWError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
          this.rentedWError = isValidAmount?.tooHigh && (wohnenSize > 0 && wohnenSize <= 9999) ? 'COMMON.ERRORS.TOO_HIGH_RENT' : this.rentedWError;
        };
          break;
        case 'rentWohnen': {
          isValidAmount = this.contentService.minMax(value, 50, 999999);
          this.rentWError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
        };
          break;
      }
      element.error = isValid && isValidAmount === true ? false : true;
      this.storeInputValue(element);
  }

  validateYear(year: any, element: any) {
      this.objectYear.value = year.target ? year.target.value : year;

      this.objectYear = this.contentService.validateYearValue(year.target ? year.target.value : year, element);
      if (!this.objectYear.error) {
        this.storeInputValue(element);
      }

      // setTimeout(() => { this.setContainerState() }, 100);
  }

  setCounter(e: number, element: any) {
    this.storeInputValue(element, e);
  }

  storeInputValue(element: any, e?: any) {
    switch (element.name) {
      case 'estateSize': this.daService.update(1, { objectGrundstuecksgroesse: element.value });
        break;
      case 'houseSize': this.daService.update(1, { objectGesamtflaecheWohnen: element.value });
        break;
      case 'gewerbeSize': this.daService.update(1, { objectGesamtflaecheGewerbe: element.value });
        break;
      case 'year': this.daService.update(1, { objectBaujahr: element.value });
        break;
      case 'wohneinheiten': this.daService.update(1, { objectAnzahlDerWohneinheiten: e });
        break;
      case 'gewerbeeinheiten': this.daService.update(1, { objectAnzahlDerGewerbeeinheiten: e });
        break;
      case 'wohnenChange': this.daService.update(1, { objectWohnenChange: e });
        break;
      case 'gewerbeChange': this.daService.update(1, { objectGewerbeChange: e });
        break;
      case 'nutzungwohnflaeche': this.daService.update(1, { objectNutzungwohnflaeche: e });
        break;
      case 'nutzunggewerbeflaeche': this.daService.update(1, { objectNutzunggewerbeflaeche: e });
        break;
      case 'rentedSizeWohnen': this.daService.update(1, { objectVermietetewohnflaeche: element.value });
        break;
      case 'rentWohnen': this.daService.update(1, { objectMieteinnahmenwohnflaeche: element.value });
        break;
      case 'rentedSizeGewerbe': this.daService.update(1, { objectVermietetegewerbeflaeche: element.value });
        break;
      case 'rentGewerbe': this.daService.update(1, { objectMieteinnahmengewerbeflaeche: element.value });
        break;

    }
    setTimeout(() => { this.setContainerState() });

  }

  setContainerState(type: string = 'default') {
    const status = this.dataQuery.getEntity(1);
    let validWohnen = 'warning';
    let validGewerbe = 'warning';

    this.container1State = !!status?.['objectGrundstuecksgroesse'] && this.objectEstateSize.touched ? 'success' : 'warning';
    this.container2State = !!status?.['objectBaujahr'] && this.objectYear.touched ? 'success' : 'warning';
    this.container3State = status?.['objectAnzahlDerGewerbeeinheiten'] > 0 ? 'optional' : 'warning';
    this.container4State = status?.['objectAnzahlDerWohneinheiten'] > 0 ? 'optional' : 'warning';

    if (status?.['objectAnzahlDerWohneinheiten'] > 0) {
      if (!!status?.['objectGesamtflaecheWohnen'] && !!status?.['objectWohnenChange'] && !!status?.['objectNutzungwohnflaeche']) {
        switch (status?.['objectNutzungwohnflaeche']) {
          case 'EIGENGENUTZT': {
            validWohnen = !this.objectHouseSize.error ? 'success' : 'error';
            break;
          }
          case 'TEILVERMIETET': {
            if (!!status?.['objectVermietetewohnflaeche'] && !!status?.['objectMieteinnahmenwohnflaeche']) {
              validWohnen = !this.objectHouseSize.error && !this.objectRentedSizeWohnen.error && !this.objectRentWohnen.error ? 'success' : 'error';
            }
            break;
          }
          case 'VERMIETET': {
            if (!!status?.['objectMieteinnahmenwohnflaeche']) {
              validWohnen = !this.objectHouseSize.error && !this.objectRentWohnen.error ? 'success' : 'error';
            }
            break;
          }
        }
      }
    }

    if (status?.['objectAnzahlDerGewerbeeinheiten'] > 0) {
      if (!!status?.['objectGesamtflaecheGewerbe'] && !!status?.['objectGewerbeChange'] && !!status?.['objectNutzunggewerbeflaeche']) {
        switch (status?.['objectNutzunggewerbeflaeche']) {
          case 'EIGENGENUTZT': {
            validGewerbe = !this.objectGewerbeSize.error ? 'success' : 'error';
            break;
          }
          case 'TEILVERMIETET': {
            if (!!status?.['objectVermietetegewerbeflaeche'] && !!status?.['objectMieteinnahmengewerbeflaeche']) {
              validGewerbe = !this.objectGewerbeSize.error && !this.objectRentedSizeGewerbe.error && !this.objectRentGewerbe.error ? 'success' : 'error';
            }
            break;
          }
          case 'VERMIETET': {
            if (!!status?.['objectMieteinnahmengewerbeflaeche']) {
              validGewerbe = !this.objectGewerbeSize.error && !this.objectRentGewerbe.error ? 'success' : 'error';
            }
            break;
          }
        }
      }
    }

    this.container1State = this.objectEstateSize.error ? 'error' : this.container1State;
    this.container2State = this.objectYear.error ? 'error' : this.container2State;
    this.container3State = status?.['objectAnzahlDerWohneinheiten'] > 0 ? validWohnen : this.container3State;
    this.container4State = status?.['objectAnzahlDerGewerbeeinheiten'] > 0 ? validGewerbe : this.container4State;

    if (this.container1State === 'warning' ||
      this.container2State === 'warning' ||
      this.container3State === 'warning' ||
      this.container4State === 'warning') {
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.setAllState('warning', 'INCOMPLETE');
      this.stateChange.emit(true);
    }

    if (this.container1State === 'error' ||
      this.container2State === 'error' ||
      this.container3State === 'error' ||
      this.container4State === 'error') {
      this.contentService.setSlideIndexState(this.slideName, false, 'error');
      this.setAllState('error', 'ERROR');
      this.stateChange.emit(true);
    }

    this.setDisabled(type);
  }

  setDisabled(type: string = 'default') {
    this.disabled = true;

    if (this.container1State === 'success' &&
      this.container2State === 'success' &&
      (this.container3State === 'success' || this.container3State === 'optional') &&
      (this.container4State === 'success' || this.container4State === 'optional')) {
      this.disabled = false;
      if( type === 'test') {
        this.testValid = false;
        this.inputDisabled = true;
      }
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.setAllState('success', 'COMPLETE');
      this.stateChange.emit(true);
    } else {
      this.testValid = type === 'init' ? this.testValid : true;
    }

    if(this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }
}
