<lib-header-text
    class="da-title"
    label="{{'SCHUFA.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<lib-tile-text
    class="da-title"
    label="{{'SCHUFA.SUB_TITLE' | translate}}"
    size="20px"
    info="true"
    (infoValue)="getInfo(schufaInfo)"
    [convertMobile]="true"
    [ngStyle]="{'display': 'flex', 'justify-content': 'center'}"
></lib-tile-text>
<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
></lib-tile-group>
<div class="da-buttons-wrapper">
    <app-nav-buttons
        class="da-buttons-group"
        [save]="true"
        [nextDisabled]="disabled"
        [current]="slideName"
        [next]="next"
        (slideTo)="setNav($event)"
        >
    </app-nav-buttons>
</div>

<ng-template #schufaInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'SCHUFA.INFO_HEADER' | translate}}" text="{{'SCHUFA.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>