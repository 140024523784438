import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DaClickpath } from './da-clickpath.model';

export interface DaClickpathState extends EntityState<DaClickpath> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'clickPathStore', resettable: true })

export class DaClickpathStore extends EntityStore<DaClickpathState> {

  constructor() {
    super();
  }

}
