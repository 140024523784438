<lib-header-text
    class="da-title"
    label="{{'FINANCE_ASSEMBLY.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<lib-tile-text
    class="da-title"
    label="{{'FINANCE_ASSEMBLY.SUBTITLE' | translate}}"
    info="true"
    (infoValue)="screen.openInfo(assemblyInfo)"
    size="18px"
    [convertMobile]="true"
    [ngStyle]="{'display': 'flex', 'justify-content': 'center'}"
></lib-tile-text>
<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="contentService.getValue('bausteine')"
    (tileValue)="setValue($event)"
    >
    <ng-template #content>
        <lib-counter
            #anzahlBausteine
            [value]="contentService.getValue('anzahlBausteine', true)"
            name="anzahlBausteine"
            [min]="2"
            [max]="5"
            (counterValue)="setSwitch($event, 'anzahlBausteine')"
            [light]="true"
        ></lib-counter>
    </ng-template>
</lib-tile-group>
<div class="da-bausteine-container" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'isSmallest' : screen.isSmallest() }">
    <!-- mehrere Bausteine - darlehen left -->
    <ng-container *ngIf="contentService.getValue('anzahlBausteine', true) > 1">
        <div class="da-container darlehen" [ngClass]="{ 'mobile' : screen.isMobile(), 'isSmallest' : screen.isSmallest() }">
            <div class="da-container-row">
                <lib-state-icon [state]="darlehenLeft ? 'warning' : 'success'"></lib-state-icon>
                <lib-tile-text
                    class="da-tile-text"
                    size="18px"
                    [convertMobile]="false"
                    label="{{'FINANCE_ASSEMBLY.DARLEHEN.TEXT' | translate}}"
                ></lib-tile-text>
                <div class="da-container-input">
                    <svg-icon class="da-container-icon" src="assets/images/equals.svg"></svg-icon>
                    <lib-input
                        #darlehensbedarf
                        class="da-tile-field"
                        label=""
                        [inputDisabled]="true"
                        name="darlehensbedarf"
                        [error]="false"
                        errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                        placeholder="0,00"
                        info="true"
                        (infoValue)="screen.openInfo(darlehenInfo)"
                        unit="€"
                        [touched]="false"
                        [value]="!!contentService.getValue('darlehensbedarfVerbleibend') ? contentService.getValue('darlehensbedarfVerbleibend') : contentService.getValue('darlehensbedarf')"
                    ></lib-input>

                </div>
            </div>
        </div>
    </ng-container>
    <!-- <div class="da-warning-wrapper">
        <app-warning-bar 
            *ngIf="darlehenLeft" 
            [text]="warningText"
        ></app-warning-bar>
    </div> -->
    <ng-container *ngFor="let item of contentService.getValue('anzahlBausteine', true) | fill; let num = index">
        <div class="da-container" [ngClass]="{ 'mobile' : screen.isMobile(), 'isSmallest' : screen.isSmallest() }">
            <div class="da-container-row">
                <lib-state-icon *ngIf="!!contentService.getNestedValue('darlehenBausteine', 'state', num)" [state]="contentService.getNestedValue('darlehenBausteine', 'state', num)"></lib-state-icon>
                <div class="da-container-row-left">
                    <lib-tile-text
                        class="da-tile-header"
                        size="20px"
                        [convertMobile]="false"
                        [label]="'FINANCE_ASSEMBLY.BAUSTEINE.LABEL' | translate: {number: num + 1}"
                    ></lib-tile-text> 
                    <div class="dropdown-wrapper">
                        <lib-dropdown 
                            #darlehen
                            name="darlehen{{num}}"
                            [selectItems]="dropdown" 
                            [selectedItem]="getSelectedValue(num, 'selected') ? getSelectedValue(num, 'selected') : dropdownSelectedItem"
                            label=""
                            info="true"
                            [success]="getSelectedValue(num, 'success') ? getSelectedValue(num, 'success') : false"
                            (infoValue)="screen.openInfo(darlehenTypeInfo)"
                            (selectValue)="setSelectedValue($event, darlehen, num)"
                            [autoWidth]="true">
                        </lib-dropdown>
                    </div>
                </div>
                <div class="da-container-row-right">
                    <app-darlehen 
                        #darlehenBaustein
                        *ngIf="!!contentService.getNestedObject('darlehenBausteine', 'type', num)"
                        [num]="num" 
                        [darlehensbedarf]="contentService.getValue('darlehensbedarfVerbleibend')"
                        [isSingle]="contentService.getValue('anzahlBausteine', true) > 1 ? false : true"
                        [error]="contentService.getNestedObject('darlehenBausteine', 'darlehenError', num)"
                        [errorDarlehen]="contentService.getNestedObject('darlehenBausteine', 'darlehenErrorText', num)"
                        [type]="contentService.getNestedObject('darlehenBausteine', 'type', num)"
                        (darlehenHoehe)="updateBausteinDarlehen($event)">
                    </app-darlehen>
                    <div class="da-bausteine-accordion-button" (click)="setVisibility(num)">
                        <svg-icon *ngIf="contentService.getNestedObject('darlehenBausteine', 'isVisible', num) === true" src="assets/images/chevronUp.svg"></svg-icon>
                        <svg-icon *ngIf="contentService.getNestedObject('darlehenBausteine', 'isVisible', num) === false" src="assets/images/chevronDown.svg"></svg-icon>
                    </div>
                </div>
            </div>
        </div>
        <div 
            class="da-container-body" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'isSmallest' : screen.isSmallest() }"
            *ngIf="contentService.getNestedObject('darlehenBausteine', 'isVisible', num) === true">
            <ng-container *ngIf="contentService.getNestedObject('darlehenBausteine', 'type', num) === 'FORWARDDARLEHEN' || contentService.getNestedObject('darlehenBausteine', 'type', num) === 'ANNUITAETENDARLEHEN'">
                <app-tilgung [num]="num" (done)="checkValidity($event)"></app-tilgung>
            </ng-container>
            <ng-container *ngIf="contentService.getNestedObject('darlehenBausteine', 'type', num) === 'ZWISCHENFINANZIERUNG'">
                <app-verwendung [num]="num" (done)="checkValidity($event)"></app-verwendung>
            </ng-container>
            <ng-container *ngIf="contentService.getNestedObject('darlehenBausteine', 'type', num) === 'VARIABLES_DARLEHEN'">
                <app-rate [num]="num" (done)="checkValidity($event)"></app-rate>
            </ng-container>
            <ng-container *ngIf="contentService.getNestedObject('darlehenBausteine', 'type', num) === 'KFW_DARLEHEN' || contentService.getNestedObject('darlehenBausteine', 'type', num) === 'PRIVATDARLEHEN' || contentService.getNestedObject('darlehenBausteine', 'type', num) === 'ZWISCHENFINANZIERUNG'">
                <app-laufzeit [num]="num" [type]="contentService.getNestedObject('darlehenBausteine', 'type', num)" (done)="checkValidity($event)"></app-laufzeit>
            </ng-container>
            <ng-container *ngIf="contentService.getNestedObject('darlehenBausteine', 'type', num) !== 'PRIVATDARLEHEN' && contentService.getNestedObject('darlehenBausteine', 'type', num) !== 'ZWISCHENFINANZIERUNG'">
                <app-zinsbindung [num]="num" [kfw]="contentService.getNestedObject('darlehenBausteine', 'type', num) === 'KFW_DARLEHEN'" (done)="checkValidity($event)"></app-zinsbindung>
                <ng-container *ngIf="contentService.getNestedObject('darlehenBausteine', 'type', num) !== 'KFW_DARLEHEN'">
                    <app-sondertilgung [num]="num" (done)="checkValidity($event)"></app-sondertilgung>
                </ng-container>
                <ng-container *ngIf="contentService.getNestedObject('darlehenBausteine', 'type', num) === 'KFW_DARLEHEN'">
                    <app-anlaufjahre [num]="num" (done)="checkValidity($event)"></app-anlaufjahre>
                </ng-container>
                <ng-container *ngIf="contentService.getNestedObject('darlehenBausteine', 'type', num) !== 'KFW_DARLEHEN'">
                    <app-zinsmonate [num]="num" (done)="checkValidity($event)"></app-zinsmonate>
                </ng-container>
                <ng-container *ngIf="contentService.getNestedObject('darlehenBausteine', 'type', num) !== 'KFW_DARLEHEN'">
                    <app-auszahlungsdatum [num]="num" (done)="checkValidity($event)"></app-auszahlungsdatum>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>
<div class="da-assembly-button-wrapper" [ngClass]="{'isSmallest' : screen.isSmallest() }">
    <div class="da-assembly-button" *ngIf="!screen.isMobile()">
        <lib-button 
            name="reset button"
            value="{{'FINANCE_ASSEMBLY.BUTTON.RESET' | translate}}" 
            (buttonValue)="resetBausteine()"
            width="300"
            [disabled]="false"
        ></lib-button>
    </div>
    <div class="da-assembly-button">
        <lib-button 
            name="weiter button"
            value="{{'FINANCE_ASSEMBLY.BUTTON.LABEL' | translate}}" 
            (buttonValue)="getResults()"
            width="300"
            [disabled]="disabled"
        ></lib-button>
        <div class="da-button-subtext">
            <lib-tile-text
                class="da-button-text"
                size="12px"
                [thin]="true"
                [convertMobile]="false"
                label="{{'FINANCE_ASSEMBLY.BUTTON.SUBTITLE' | translate}}"
            ></lib-tile-text>              
        </div>
    </div>
</div>
<ng-template #assemblyInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_ASSEMBLY.INFO_HEADER' | translate}}" text="{{'FINANCE_ASSEMBLY.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #darlehenInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_ASSEMBLY.DARLEHEN.INFO_HEADER' | translate}}" text="{{'FINANCE_ASSEMBLY.DARLEHEN.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #darlehenTypeInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_ASSEMBLY.BAUSTEINE.INFO_HEADER' | translate}}" text="{{'FINANCE_ASSEMBLY.BAUSTEINE.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>