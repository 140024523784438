import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements AfterViewInit {
  @Input() type!: string;
  fileList: any[] = [];
  uploadFiles: any[] = [];
  fileCountError!: boolean;
  fileSizeError!: boolean;
  fileTypeError!: boolean;
  fileErrorText: string = 'FILE_UPLOAD.ERRORS.FILES_TYPE_ERROR';
  deleteIcon: any = [{type: 'delete', icon: "assets/images/trashXmark.svg"}];
  icons: any = [
    {type: 'download', icon: "assets/images/download.svg"},
    {type: 'edit', icon: "assets/images/pen.svg"},
    {type: 'delete', icon: "assets/images/trashXmark.svg"}
  ];
  loading: boolean = false;
  @Output() public fileUploaded: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService
  ) { }


  /**
   * callback file over dropzone
   * @param e: any event
   * @returns 
   */
  fileOver(e: any) {
    return;
  }

  /**
   * callback leave dropzone
   * @param e: any event
   * @returns 
   */
  fileLeave(e: any) {
    return;
  }

  ngAfterViewInit(): void {
    this.getExistingFiles(true);
  }

  getExistingFiles(init?: boolean) {
    this.fileList = [];
    let stateFiles = [];

    if (this.type === 'expose') {
      stateFiles = this.dataQuery.getEntity(1)?.['exposeFiles'] ?? [];
    } else if (this.type === 'income') {
      stateFiles = this.dataQuery.getEntity(1)?.['einkommenFiles'] ?? [];
    } else {
      stateFiles = this.dataQuery.getEntity(1)?.['documents'] ?? [];
    }


    if (stateFiles && stateFiles.length > 0) {
      this.fileUploaded.emit('success');
    } else {
      this.fileUploaded.emit('optional');
    }

    // add already uploaded files to file list
    stateFiles.forEach((file: any) => {
      this.fileList.push({ name: file.filename, state: 'success' });
      if (init) {
        this.uploadFiles.push(file);
      }
    });
  }

  /**
   * callback after drop file / upload via input button
   * @param files 
   * @returns void
   */
  dropped(files: NgxFileDropEntry[]): void {
    this.loading = true;
    this.getExistingFiles();

    // if (this.fileList.length > 5 || files.length > 5 || this.fileList.length + files.length > 5) {
    //   this.fileCountError = true;
    //   this.fileUploaded.emit('optional');
    //   this.loading = false;
    //   return;
    // }
    const saveFiles: any[] = []
    for (const droppedFile of files) {
      this.fileUploaded.emit('success');
      // check if is a file
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // add new files to list
          if (file.size > 30000000) {
            this.fileSizeError = true;
            this.fileUploaded.emit('error');
            this.fileList.push({ name: file.name, state: 'error', errorText: 'FILE_UPLOAD.ERRORS.FILES_SIZE_ERROR' });
          } else if (!this.isFileAllowed(file.name)) {
            this.fileTypeError = true;
            this.fileUploaded.emit('error');
            this.fileList.push({ name: file.name, state: 'error', errorText: 'FILE_UPLOAD.ERRORS.FILES_TYPE_ERROR' });
          } else {
            this.fileList.push({ name: file.name, state: 'success', mediatype: file.type });
            saveFiles.push(file)
          }
          console.log(saveFiles)
          this.fileList.map(fileListfile => {
            if (fileListfile.state === 'success') {

              // upload to api
              const formData = new FormData();
              if (fileListfile.name === file.name) {
                formData.append('file', file, file.name);
                const result = this.contentService.uploadFile(formData);
                // store uploaded link to redux
                result.then((res: any) => {
                  res.subscribe({
                    next: (data: any) => {
                      this.loading = false;
                      this.uploadFiles = Object.assign([], this.uploadFiles);
                      this.uploadFiles.push({
                        filename: data.filename,
                      });
                      this.setValue();
                    },
                    error: (error: any) => {
                      this.loading = false;
                      console.log(error);
                    }
                  })
                  
                });
              } else {
                this.loading = false;
              }
            }
          });
        });
      } else {
        // directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  /**
   * check file type extension
   * @param fileName 
   * @returns boolean
   */
  isFileAllowed(fileName: string): boolean {
    let isFileAllowed = false;
    const allowedFiles = ['.pdf', '.jpg', '.jpeg', '.png', '.PDF', '.JPG', '.JPEG', '.PNG'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  /**
   * remove file from upload, update redux store and fileList
   * @param file 
   */
  removeFile(file: string) {
    setTimeout(() => {
      const tempFileList: string[] = [];
      const tempFileList2: any[] = [];
      let files = Object.assign([], this.uploadFiles);

      const index = files.findIndex((item) => item['filename'] === file);
      if (index > -1) {
        files.splice(index, 1);
      }
      
      this.uploadFiles = files;
      files.forEach(data => tempFileList.push(data['filename']));
  
      this.fileList.map(fileListFile => {
        if (tempFileList.indexOf(fileListFile.name) > -1) {
          tempFileList2.push(fileListFile);
        }
      })
      this.fileList = tempFileList2;

      this.setValue();
      this.resetError();
    }, 500)
  }

  /**
   * reset error msg
   */
  resetError(): void {
    this.fileCountError = false;
    this.fileSizeError = false;
    this.fileTypeError = false;

    if (this.fileList.length > 0) {
      this.fileUploaded.emit('success');
    } else {
      this.fileUploaded.emit('optional');
    }
  }

  /**
   * store value to redux
   */
  setValue() {
    if (this.type === 'expose') {
      this.daService.update(1, { exposeFiles: this.uploadFiles });
    } else if (this.type === 'income') {
      this.daService.update(1, { einkommenFiles: this.uploadFiles });
    } else {
      this.daService.update(1, { documents: this.uploadFiles });
    }
  }

  getButtonValue(e: string) {
    const type = e.slice(0, e.indexOf('-'));
    const name = e.slice(e.indexOf('-') + 1, e.length);

    if(type === 'delete') {
      this.removeFile(name);
    }
  }
}
