import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';

@Component({
  selector: 'app-auszahlungsdatum',
  templateUrl: './auszahlungsdatum.component.html',
  styleUrls: ['./auszahlungsdatum.component.scss']
})
export class AuszahlungsdatumComponent implements OnInit {
  today: Date = new Date();
  errorDate: string = "COMMON.ERRORS.DATE";

  @Input() num!: number;
  @Output() public done: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('datum') datum!: any;
  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private dataQuery: DataQuery,
    private daService: DataService,
  ) { }

  ngOnInit(): void {
  }

  validateDate(e: any, element: any, isInput?: boolean) {
    let isDone = false;
    element.error = !!e ? false : true;

    if (isInput) {
      if (e._i.length >= 8 && e._i.substring(e._i.length - 5, e._i.length - 4) === '.') {
        isDone = true;
      } else {
        isDone = false;
      }
    } else {
      isDone = true;
    }

    if (isDone) {
      const baseDate = e !== null ? e.toDate() : null;
      const currentDate = new Date();
      let baseTooSoon: boolean = false;

      setTimeout(() => {
        element.touched = true;
        baseTooSoon = baseDate < currentDate;
        this.errorDate = baseTooSoon ? 'COMMON.ERRORS.TOO_SOON' : 'COMMON.ERRORS.DATE';
        element.error = baseTooSoon ? true : false;

        if (!element.error) {
          const darlehen = this.contentService.updateNestedObject('darlehenBausteine', 'auszahlungszeitpunkt', baseDate, this.num);
          this.daService.update(1, { darlehenBausteine: darlehen });
          this.done.emit(this.num);
        }
      }, 200);
    }
  }

}
