import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Debounce } from 'lodash-decorators';
import { LayoutService } from 'src/app/services/layout.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';

@Component({
  selector: 'app-calendly',
  templateUrl: './calendly.component.html',
  styleUrls: ['./calendly.component.scss']
})
export class CalendlyComponent implements OnInit {
  calendlyLink!: string;
  isLoading: boolean = true;
  @Input() modal!: NgbModalRef;
  @ViewChild('calendlyContainer', { static: true }) calendlyContainer!: ElementRef;
  constructor(
    public screen: LayoutService,
    private configurationQuery: ConfigurationQuery,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
  }
  ngOnInit(): void {
    this.configurationQuery.configuration.subscribe({
      next: (store) => {
        this.calendlyLink = store.kontaktlinkkalender;
        this.addCalendly();
      },
      error: (err) => {
        console.warn('da-info: no configuration found.', err);
      }
    });
  }

  addCalendly() {
    let script = this.renderer.createElement('script');
    script.type = `text/javascript`;
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    this.renderer.appendChild(this._document.body, script);

    const customLink: HTMLElement = this.renderer.createElement('span');
    customLink.innerHTML = '<div class="calendly-inline-widget" data-url="' + this.calendlyLink + '" style="min-width:auto;height:100vh;"></div>';
    this.renderer.appendChild(this.calendlyContainer.nativeElement, customLink);
    setTimeout(() => {this.isLoading = false;}, 2000);
  }

  dismiss() {
    document.body.style.overflowY = 'auto';
    this.modal.dismiss();
  }

}
