import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { switchesContent } from 'src/assets/content/switches';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-energy',
  templateUrl: './energy.component.html',
  styleUrls: ['./energy.component.scss']
})
export class EnergyComponent implements OnInit {
  public slideName: string = 'energy';
  next: string = 'parking';
  localValue!: string;
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  energyError: string = 'COMMON.ERRORS.NUMBER';
  tiles = tilesContent['ENERGY'].items;
  switches = switchesContent['ENERGY'].items;
  switchesType = switchesContent['ENERGY_TYPE'].items;
  energy: string = '';
  energyType: string = '';
  energyClass: string = '';
  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('content') content!: TemplateRef<any>;
  @ViewChild('objectEnergyClass') objectEnergyClass!: any;
  @ViewChild('objectEnergyType') objectEnergyType!: any;
  @ViewChild('objectEnergy') objectEnergy!: any;
  @ViewChild('objectEnergyKnown') objectEnergyKnown!: any;
  @ViewChild('objectEnergyClassKnown') objectEnergyClassKnown!: any;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public contentService: ContentService,
  ) {}

  ngOnInit(): void {
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.setStatus();
    });
  }

  setStatus() {
    this.disabled = true;
    const parent = this.dataQuery.getEntity(1)?.['objectEnergyKnown'];
    const status = this.dataQuery.getEntity(1)

    if (!!parent) {
      this.localValue = parent;
      this.tiles = this.contentService.setTileChecked(this.tiles, parent);
      if (parent === 'true') {
        this.tiles = this.contentService.setTilesContent(this.tiles, parent, this.content);
        this.setSwitch(status?.['objectEnergieEffizienzAusweistyp'], 'energyType');
        this.setSwitch(status?.['objectEnergyUseKnown'], 'energyKnown');
        this.setSwitch(status?.['objectEnergyClassKnown'], 'energyClassKnown');
        setTimeout(() => {this.setTileStatus();});
      } else {
        this.contentService.setSlideIndexState(this.slideName, false, 'warning');
        this.disabled = false;
        this.stateChange.emit(true);
      }
    }
    this.daNextSlideService.update(1, { disabled: this.disabled });
  };

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'next') {
        if (this.allState !== 'success') {
          this.showState = true;
          this.setTileStatus();
        } else {
          this.setTileStatus('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 100)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  setValue(e: string) {
    this.localValue = e;
    this.daService.update(1, { objectEnergyKnown: e });
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
    if (e === 'true') {
      this.contentService.setTilesContent(this.tiles, e, this.content);
      setTimeout(() => {this.setTileStatus();});
    } else {
      this.disabled = false;
      this.daService.resetValues({ objectEnergieEffizienzAusweistyp: '' });
      this.daService.resetValues({ objectEnergyUseKnown: '' });
      this.daService.resetValues({ objectEndEnergie: '' });
      this.daService.resetValues({ objectEnergyClassKnown: '' });
      this.daService.resetValues({ objectEnergieEffizienzklasse: '' });
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.setAllState('warning', 'warning');
      this.contentService.setNav('next', 'energy', this.slideTo, this.next);
      this.stateChange.emit(true);
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }

  validateInputValue(e: any, element: any) {
    setTimeout(() => {
      let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
      element.touched = !!value ? true : false;
      const isValid = this.contentService.validatePattern(value, element.validationType);
      const isValidAmount: any = this.contentService.minMax(value, 0, 999);
      this.energyError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';

      element.error = isValid && isValidAmount === true ? false : true;

      if (!element.error) {
        this.daService.update(1, { objectEndEnergie: value });
      } else {
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.setAllState('error', 'ERROR');
        this.stateChange.emit(true);
        this.disabled = true;
      }
      this.setTileStatus();
    }, 100);
  };

  setSwitch(e: string, type: string) {
    switch (type) {
      case 'energyClass': {
        this.daService.update(1, { objectEnergieEffizienzklasse: e });
      }
        break;
      case 'energyType': {
        this.daService.update(1, { objectEnergieEffizienzAusweistyp: e });
        this.energyType = e === 'ENDENERGIEBEDARF' ? 'ENERGY.TILE_TEXT2' : 'ENERGY.TILE_TEXT3';
        this.energy = this.contentService.getValue('objectEnergieEffizienzAusweisTyp') === 'ENDENERGIEBEDARF' ? 'ENERGY.TILE_LABEL1' : 'ENERGY.TILE_LABEL2';
      }
        break;
      case 'energyKnown': {
        this.daService.update(1, { objectEnergyUseKnown: e });
      }
        break;
      case 'energyClassKnown': {
        this.daService.update(1, { objectEnergyClassKnown: e });
      }
        break
    }
    setTimeout(() => {this.setTileStatus();});
  }

  setTileStatus(type: string = 'default') {
    const status = this.dataQuery.getEntity(1);
    let valid = false;
    let stateComplete = true;

    if (!!status) {
      if (!!status['objectEnergieEffizienzAusweistyp']) {
        if (!!status['objectEnergyUseKnown']) {
          if (status['objectEnergyUseKnown'] === 'true') {
            valid = !!status['objectEndEnergie'] ? true : false;
          } else {
            if (!!status['objectEnergyClassKnown']) {
              if (status['objectEnergyClassKnown'] === 'true') {
                valid = !!status['objectEnergieEffizienzklasse'] ? true : false;
              } else {
                stateComplete = false;
                valid = true;
              }
            }
          }
        }
      }
    };

    if (valid) {
      this.tiles = this.contentService.setTileStatus(this.tiles, this.localValue, 'success', 'success');
      this.contentService.setSlideIndexState(this.slideName, false, stateComplete ? 'success' : 'warning');
      if( type === 'test') {
        this.testValid = false;
        this.inputDisabled = true;
      }
      this.setAllState('success', 'COMPLETE');
      this.disabled = false;
    } else {
      this.tiles = this.contentService.setTileStatus(this.tiles, this.localValue, 'warning', 'success');
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.setAllState('warning', 'INCOMPLETE');
      this.disabled = true;
      this.testValid = type === 'init' ? this.testValid : true;
    }

    if(this.objectEnergy && this.objectEnergy.error) {
      this.tiles = this.contentService.setTileStatus(this.tiles, this.localValue, 'error', 'success');
      this.contentService.setSlideIndexState(this.slideName, false, 'error');
      this.setAllState('error', 'ERROR');
      this.testValid = true;
      this.inputDisabled = false;
    }
    this.stateChange.emit(true);

    if (this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }
}
