<div class="da-bausteine-tile" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'isSmallest' : screen.isSmallest() }">
    <lib-header-text
    label="{{'FINANCE_ASSEMBLY.BAUSTEINE.MONATE.TITLE' | translate}}"
    info="true"
    (infoValue)="screen.openInfo(monateInfo)"
    size="20px"
    [convertMobile]="false"
    ></lib-header-text>
    <lib-toggle-vertical
        #fixedM
        [options]="switches"
        [localValue]="contentService.getNestedObject('darlehenBausteine', 'bereitstellungszinsfreieZeitInMonaten', num)"
        name="fixedM{{num}}"
        (switchValue)="setSwitch($event)"
    ></lib-toggle-vertical>
    <lib-input
        *ngIf="contentService.getNestedObject('darlehenBausteine', 'bereitstellungszinsfreieZeitInMonaten', this.num) === 'other'"
        #variableM
        class="da-tile-field"
        label="{{'FINANCE_ASSEMBLY.BAUSTEINE.MONATE.LABEL' | translate}}"
        name="variableM{{num}}"
        [error]="false"
        validationType="numbers"
        errorText="{{'COMMON.ERRORS.NUMBER' | translate}}"
        placeholder="0"
        [isCurrency]="false"
        [value]="contentService.getNestedObject('darlehenBausteine', 'bereitstellungszinsfreieZeitInMonatenVariable', num) ? contentService.getNestedObject('darlehenBausteine', 'bereitstellungszinsfreieZeitInMonatenVariable', num) : '0'"
        [touched]="contentService.getNestedObject('darlehenBausteine', 'bereitstellungszinsfreieZeitInMonatenVariable', num) && contentService.getNestedObject('darlehenBausteine', 'bereitstellungszinsfreieZeitInMonatenVariable', num) > 0"                               
        (inputValue)="validateInput($event, variable)"
    ></lib-input>
</div>

<ng-template #monateInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_ASSEMBLY.BAUSTEINE.MONATE.INFO_HEADER' | translate}}" text="{{'FINANCE_ASSEMBLY.BAUSTEINE.MONATE.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
