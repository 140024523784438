<lib-header-text
    class="da-title"
    label="{{'FINANCE_CONTINUE.PATH.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<lib-tile-text
    class="da-title"
    label="{{'FINANCE_CONTINUE.PATH.SUBTITLE' | translate}}"
    info="true"
    (infoValue)="screen.openInfo(pathInfo)"
    size="20px"
    [convertMobile]="true"
    [ngStyle]="{'display': 'flex', 'justify-content': 'center'}"
></lib-tile-text>
<lib-tile-group
    class="da-tiles-wrapper"
    [tiles]="tiles"
    [localValue]="localValue"
    (tileValue)="setValue($event)"
    ></lib-tile-group>
    
<ng-template #pathInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_CONTINUE.PATH.INFO_HEADER' | translate}}" text="{{'FINANCE_CONTINUE.PATH.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
