import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { LayoutService } from 'src/app/services/layout.service';
import { DaClickpathService } from 'src/app/state/click-path/da-clickpath.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
import { shortcutData, shortcutDataPartner } from 'src/assets/content/shortcut';
import { ContentService } from 'src/app/services/content.service';
import { DataStore } from 'src/app/state/data/data.store';

@Component({
  selector: 'app-start-b',
  templateUrl: './start-b.component.html',
  styleUrls: ['./start-b.component.scss']
})
export class StartBComponent implements OnInit {
  public slideName: string = 'start';
  grafik!: string;
  shortcut = shortcutData;
  shortcutPartner = shortcutDataPartner;

  @Output() public slideNext: EventEmitter<any> = new EventEmitter<string>();

  constructor(
    private daClickpathService: DaClickpathService,
    private daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    private configurationQuery: ConfigurationQuery,
    public contentService: ContentService,
    private dataStore: DataStore,
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit() {
    this.daService.setCurrentSlide(this.slideName);

    this.configurationQuery.configuration.subscribe((res: any) => {
      this.grafik = res.bildmaterial_da_b?.linkgrafikstart;
    });
  }

  goTo(type: string) {
    const participants = this.dataQuery.getEntity(1)?.['participants'];
    const projectPlan = this.dataQuery.getEntity(1)?.['projectPlan'];

    const excludedItems = [
      'object-category',
      'object-detail',
      'object-nutzung',
      'condition-estate',
      'bauweise',
      'modernization',
      'energy',
      'parking',
      'erbbaurecht',
      'personal',
      'children',
      'miete',
      'health-insurance',
      'vehicles'];

      if (!!projectPlan) {
        if (projectPlan !== 'NEUBAU') {
          excludedItems.push('object-category');
          excludedItems.push('modernization');
        } else {
          excludedItems.push('house-category');
        }
      }

      if (!!participants && participants !== 'allein') {
        excludedItems.push('additional-income');
        excludedItems.push('additional-income-partner');
        excludedItems.push('personal-partner');
      } else {
        excludedItems.push('additional-income-single')
      }

    if (type === '3') { // is shortcut
      if (!!participants && participants !== 'allein') {
        this.daService.update(1, this.shortcutPartner);
      } else {
        this.daService.update(1, this.shortcut);
      }

      this.contentService.setBulkSlideIndex(excludedItems, true);
    } else { // is NOT shortcut

      if(!!participants && participants !== 'allein') {
        this.daService.resetValues({
          shortcut: false,
          haushaltPerson: '',
          geburtsdatum: '',
          staatsangehoerigkeit: '',
          familienstand: '',
          Beschaeftigung: '',
          probezeit: '',
          beschaeftigtSeit: '',
          beschaeftigungsstatus: '',
          anzahlGehaelterProJahr: '',
          gehalt: '',
          haushaltPersonPartner: '',
          geburtsdatumPartner: '',
          staatsangehoerigkeitPartner: '',
          familienstandPartner: '',
          BeschaeftigungPartner: '',
          probezeitPartner: '',
          beschaeftigtSeitPartner: '',
          beschaeftigungsstatusPartner: '',
          anzahlGehaelterProJahrPartner: '',
          gehaltPartner: '',
          objectType: !!projectPlan && projectPlan === 'NEUBAU' ? 'Haus' : '',
          objectUnitType: '',
          objectGesamtflaeche: '',
          objectNutzungwohnflaeche: '',
        });
      } else {
        this.daService.resetValues({
          shortcut: false,
          haushaltPerson: '',
          geburtsdatum: '',
          staatsangehoerigkeit: '',
          familienstand: '',
          Beschaeftigung: '',
          probezeit: '',
          beschaeftigtSeit: '',
          beschaeftigungsstatus: '',
          anzahlGehaelterProJahr: '',
          gehalt: '',
          objectType: !!projectPlan && projectPlan === 'NEUBAU' ? 'Haus' : '',
          objectUnitType: '',
          objectGesamtflaeche: '',
          objectNutzungwohnflaeche: '',
        });
      }

      this.contentService.setBulkSlideIndex(excludedItems, false);
    }
    this.daNextSlideService.update(1, { next: 'estate-status', timestamp: new Date });
    this.daClickpathService.updateClickPath(this.slideName);
    this.slideNext.emit();
  }

  getInfo(type: any) {
    this.screen.openInfo(type)
  }
}
