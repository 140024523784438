export interface Root {
    "dametadata": DametaData
    "europacedata": EuropaceData
    "internaldata": InternalData
    "documents"?: DocumentsData[]
}

export interface EuropaceData {
    "kundenangaben": Kundenangaben
    "zusaetzlicherereignistext": string
}
export interface DametaData {
    "dataformat": string
    "datatimestamp"?: string
    "kundenids"?: string[]
    "referenceuuid"?: string
    "precheckstore": boolean
}

export interface InternalData {
    "schufascorepositive": boolean
}

export interface DocumentsData {
    "filename": string
}

export interface Kundenangaben {
    "haushalte": Haushalt[],
    "finanzierungsobjekt": Finanzierungsobjekt,
    "finanzierungsbedarf": Finanzierungsbedarf,
    "bankverbindung"?: {}
}
// HAUSHALT
export interface Haushalt {
    "kunden": Kunde[],
    "erfahrungImmobilienFinanzierung": boolean,
    "kinderErfassung": {
        "@type": string,
        "kinder"?: Kind[]
    },
    "finanzielleSituation": FinanzielleSituation,
    "zusatzangaben"?: ZusatzangabenHaushalt
}
export interface FinanzielleSituation {
    "bestehendeImmobilienErfassung": {
        "@type"?: string,
        "bestehendeImmobilien"?: BestehendeImmobilie[]
    },
    "vermoegen": Haushaltsvermoegen,
    "einnahmen": Haushaltseinnahmen,
    "ausgaben": Haushaltsausgaben,
    "verbindlichkeiten": Haushaltsverbindlichkeiten

}
export interface ZusatzangabenHaushalt {
    "kfzAnzahl"?: number,
}
export interface Kunde {
    "referenzId": string,
    "personendaten": Personendaten,
    "finanzielles": Finanzielles,
    "wohnsituation": Wohnsituation,
    "kontakt": Kontakt,
    "zusatzangaben": ZusatzangabenKunde,
}
export interface ZusatzangabenKunde {
    "branche"?: string,
}
export interface Wohnsituation {
    "anschrift"?: Anschrift,
}
export interface Finanzielles {
    "beschaeftigung"?: Beschaeftigung,
    "einkommenNetto"?: number
}
export interface Beschaeftigung {
    "@type"?: string,
    "beruf"?: string,
    "beschaeftigungsverhaeltnis"?: Beschaeftigungsverhaeltnis,
    "beschaeftigungsstatus"?: string,
    "taetigkeit"?: SelbststaendigeTaetigkeit,
}
export interface BeschaeftigungDetails {
    "beruf"?: string,
    "beschaeftigungsverhaeltnis"?: Beschaeftigungsverhaeltnis,
    "beschaeftigungsstatus"?: string,
}
export interface Beschaeftigungsverhaeltnis {
    "arbeitgeber"?: Arbeitgeber,
    "probezeit"?: boolean,
    "anzahlGehaelterProJahr"?: string,
    "beschaeftigtSeit"?: string
}
export interface Arbeitgeber {
    "inDeutschland"?: boolean,
}
export interface Taetigkeit {
    "beruf"?: string,
    "taetigkeit"?: SelbststaendigeTaetigkeit,
}
export interface SelbststaendigeTaetigkeit {
    "taetigSeit"?: string,
}
export interface Kontakt {
    "telefonnummer"?: Telefonnummer,
    "email"?: string,
    "weitereKontaktmoeglichkeiten"?: string
}
export interface Telefonnummer {
    "vorwahl"?: string,
    "nummer"?: string
}
export interface Personendaten {
    "person": Person,
    "geburtsdatum"?: string,
    "staatsangehoerigkeit": string,
    "nichtEuBuerger"?: Aufenthalt,
    "familienstand"?: Familienstand,
}
export interface Familienstand {
    "@type"?: string,
}
export interface Aufenthalt {
    "inDeutschlandSeit": string,
    "aufenthaltstitel": Aufenthaltstitel,
}
export interface Aufenthaltstitel {
    "@type"?: string,
    "befristetBis"?: string,
    "arbeitserlaubnis"?: Arbeitserlaubnis
}
export interface Arbeitserlaubnis {
    "@type"?: string,
    "befristetBis"?: string,
}
export interface Person {
    "titel"?: Titel,
    "anrede"?: string,
    "vorname"?: string,
    "nachname"?: string
}
export interface Titel {
    "prof"?: boolean,
    "dr"?: boolean,
}
export interface Kind {
    "name"?: string,
    "geburtsdatum"?: string,
    "kindergeldWirdBezogen"?: boolean,
    "unterhalt"?: number,
}
export interface BestehendeImmobilie {
    "bezeichnung"?: string,
    "immobilie"?: Immobilie,
    "marktwert"?: number,
    "einsatz"?: string,
    "maximalEinzusetzenderBetragBeiVerkauf"?: number,
    "darlehenslisteErfassung"?: {
        "@type"?: string,
        "darlehensliste"?: BestehendesImmobilienDarlehen[],
    }

}
export interface Haushaltsvermoegen {
    "summeBankUndSparguthaben"?: Guthaben,
    "summeDepotvermoegen"?: Depotvermoegen,
    "bausparvertraege"?: BausparvertragAlsVermoegen[],
    "lebensOderRentenversicherungen"?: LebensOderRentenversicherung[],
    "summeSonstigesVermoegen"?: SonstigesVermoegen,
}
export interface BestehendesImmobilienDarlehen {
    '@type'?: string,
    "darlehensbetrag"?: number,
    "grundschuld"?: number,
    "rateMonatlich"?: number,
    "restschuld"?: Restschuld
}
export interface SonstigesVermoegen {
    "aktuellerWert"?: number,
    "verwendung"?: {
        "@type": string,
        "maximalEinzusetzenderBetrag"?: number,
        "kommentar"?: string,
    }
}
export interface LebensOderRentenversicherung {
    "rueckkaufswert"?: number,
    "verwendung"?: {
        "@type": string,
        "maximalEinzusetzenderBetrag"?: number,
    }
}
export interface BausparvertragAlsVermoegen {
    "angesparterBetrag"?: number,
    "zuteilungsdatum"?: string,
    "bausparsumme"?: number,
    "verwendung"?: {
        "@type": string,
        "maximalEinzusetzenderBetrag"?: number,
    }
}
export interface Restschuld {
    "aktuell"?: number,
    "zumAbloeseTermin"?: number
    "zumAbloesetermin"?: number
}
export interface Guthaben {
    "guthaben"?: number,
    "verwendung"?: {
        "@type": string,
        "maximalEinzusetzenderBetrag"?: number,
    }
}
export interface Depotvermoegen {
    "depotwert"?: number,
    "verwendung"?: {
        "@type": string,
        "maximalEinzusetzenderBetrag"?: number,
    }
}
export interface Haushaltseinnahmen {
    "summeVariablerEinkuenfteMonatlich"?: number,
    "einkuenfteAusNebentaetigkeit"?: EinkuenfteAusNebentaetigkeit[]
    "summeUnbefristeteZusatzrentenMonatlich"?: KommentierterBetrag,
    "summeSonstigeEinnahmenMonatlich"?: KommentierterBetrag

}
export interface EinkuenfteAusNebentaetigkeit {
    "betragMonatlich"?: number,
    "beginnDerTaetigkeit"?: string,
}
export interface KommentierterBetrag {
    "betrag"?: number,
    "kommentar"?: string,
}
export interface Haushaltsausgaben {
    "summeMietausgaben"?: Mietausgaben,
    "summePrivaterKrankenversicherungenMonatlich"?: number,
    "unterhaltsverpflichtungenMonatlich"?: number[],
    "summeSonstigerVersicherungsausgabenMonatlich"?: KommentierterBetrag,
    "summeSonstigerAusgabenMonatlich"?: KommentierterBetrag
}
export interface Mietausgaben {
    "betragMonatlich"?: number,
    "entfaelltMitFinanzierung"?: boolean
}
export interface Haushaltsverbindlichkeiten {
    "ratenkredite"?: Verbindlichkeit[],
    "privatdarlehen"?: Verbindlichkeit[],
    "sonstigeVerbindlichkeit"?: Verbindlichkeit,
}
export interface Verbindlichkeit {
    "rateMonatlich"?: number,
    "restschuld"?: number,
    "wirdAbgeloest"?: boolean,
    "kommentar"?: string
}
// FINANZIERUNGSBEDARF
export interface Finanzierungsbedarf {
    "finanzierungszweck": {
        "@type": string,
        "modernisierung"?: ModernisierungsKostenErfassung;
        "bereitsBeglicheneKosten"?: number,
        "modernisierungskostenErfassung"?: ModernisierungsKostenErfassung,
        "zuBeschaffendesKapitalErfassung"?: ZuBeschaffendesKapitalErfassung,
        "marktwertFinanzierungsobjekt"?: number,
        "abloesungDarlehenDesFinanzierungsobjektes"?: AbloesungBestehendesdarlehen[],
        "weitereKosten"?: WeitereKosten,
        "nebenkosten"?: Nebenkosten,
        "kaufpreis"?: number,
        "eigenleistungErfassung"?: EigenleistungErfassung,
        "grundstueckskosten"?: Grundstueckskosten,
        "herstellungskostenInklusiveEigenleistungen"?: number,
        "aussenanlagen"?: number,
        "baunebenkosten"?: number,
    },
    "finanzierungsbausteine": Finanzierungsbaustein[]
}
export interface Grundstueckskosten {
    "grundstueckkaufpreis"?: number,
    "grundstueckBereitsBezahlt"?: boolean,
    "erschliessungskosten"?: number,

}
export interface EigenleistungErfassung {
    "@type": string, // VorhandeneEigenleistung, KeineEigenleistung
    "eigenleistung"?: number
}
export interface Nebenkosten {
    "maklergebuehr"?: WertInEuroOderProzent,
    "notargebuehr"?: WertInEuroOderProzent,
    "grunderwerbsteuer"?: WertInEuroOderProzent

}
export interface WertInEuroOderProzent {
    "wert"?: number,
    "einheit"?: string //EURO, PROZENT
}
export interface WeitereKosten {
    "mobiliarkosten"?: number,
    "sonstigeKosten"?: KommentierterBetrag,
    "beglicheneKosten"?: number
}
export interface AbloesungBestehendesdarlehen {
    "referenzIdAbzuloesendesDarlehen"?: string
    "sondertilgungZumZinsbindungsende"?: number
}
export interface ZuBeschaffendesKapitalErfassung {
    "@type": string, // VorhandenesZuBeschaffendesKapital, KeinZuBeschaffendesKapital
    "zuBeschaffendesKapital"?: KommentierterBetrag
}
export interface ModernisierungsKostenErfassung {
    "@type": string, // VorhandeneModernisierungskosten, KeineModernisierungskosten
    "modernisierungskostenInklEigenleistungen"?: number,
    "renovierungskosten"?: number,
    "eigenleistungErfassung"?: EigenleistungErfassung,
}
export interface Finanzierungsbaustein {
    "@type": string,
    "darlehensbetrag"?: number,
    "annuitaetendetails"?: Annuitaetendetails,
    "bereitstellungszinsfreieZeitInMonaten"?: number,
    "tilgungswunsch"?: Tilgungswunsch,
    "programm"?: KfwProgramm,
    "laufzeitInMonaten"?: number,
    "maximaleLaufzeitInMonaten"?: number,
    "verwendungszweck"?: string
}
export interface KfwProgramm {
    "@type": string, // KfwProgramm124
    "laufzeitInJahren"?: number,
    "zinsbindungInJahren"?: number,
    "tilgungsfreieAnlaufjahre"?: number,
}
export interface Tilgungswunsch {
    "@type": string, // TilgungInProzent, Rate
    "tilgungssatzInProzent"?: number,
    "rate"?: number,
    "tilgungsbeginn"?: string,
}
export interface Annuitaetendetails {
    "zinsbindungInJahren"?: number,
    "tilgungswunsch"?: Tilgungswunsch,
    "sondertilgungJaehrlich"?: number, //prozent
    "auszahlungszeitpunkt"?: string //date
}

// FINANZIERUNGSOBJEKT
export interface Finanzierungsobjekt {
    "immobilie": Immobilie,
    "darlehenslisteErfassung"?: { 
        "@type"?: string, // KEINE_BESTEHENDE_DARLEHEN_DES_FINANZIERUNGSOBJEKTES, VORHANDENE_BESTEHENDE_DARLEHEN_DES_FINANZIERUNGSOBJEKTES
        "darlehensliste"?: BestehendesDarlehenDesFinanzierungsobjektes[],
    }
}
export interface BestehendesDarlehenDesFinanzierungsobjektes {
    "referenzId"?: string
    "darlehen"?: {
        "@type"?: string,
        "restschuld"?: Restschuld,
        "grundschuld"?: number,
        "darlehensbetrag"?: number,
        "zinsbindungBis"?: string,
        "laufzeitende"?: string
    }
}
export interface Immobilie {
    "adresse": Anschrift,
    "typ": ImmobilienTyp,
    "stellplaetzeErfassung"?: {
        "@type"?: string,
        "stellplaetze"?: Stellplatz[],
    }
    "erbbaurechtErfassung"?: {
        "@type"?: string,
        "erbbauzinsJaehrlich"?: number,
        "grundstueckseigentuemer"?: string,
        "laufzeitBis"?: number
    },
}
export interface ImmobilienTyp {
    "@type"?: string,
    "grundstuecksgroesse"?: number,
    "grundstuecksart"?: string,
    "gebaeude"?: Gebaeude,
    "einliegerwohnungVorhanden"?: boolean,
    "nutzflaechen"?: Nutzflaechen,
    "fertighaus"?: boolean,
    "anzahlDerWohneinheitenImObjekt"?: number,
    "anzahlDerGewerbeeinheitenImObjekt"?: number,
    "haustyp"?: string,
    "anzahlDerWohneinheiten"?: number,
    "anzahlDerGewerbeeinheiten"?: number
}
export interface Gebaeude {
    "baujahr"?: number,
    "bauweise"?: string,
    "anzahlGeschosse"?: number,
    "zustand"?: string,
    "ausstattung"?: string,
    "nutzung": Nutzung,
    "modernisierungErfassung"?: {
        "@type"?: string,
        "jahr"?: number,
        "grad"?: string,
    },
    "energieEffizienzAusweis"?: EnergieEffizienzAusweis,
}
export interface EnergieEffizienzAusweis {
    "ausweistyp"?: string,
    "endEnergie"?: number,
    "energieEffizienzklasse"?: string,
}
export interface Nutzung {
    "wohnen"?: Flaechennutzung,
    "gewerbeErfassung"?: {
        "@type"?: string,
        "gewerbe"?: Flaechennutzung,
    }
}
export interface Flaechennutzung {
    "gesamtflaeche"?: number,
    "nutzungsart"?: {
        '@type': string,
        "mieteinnahmenNettoKaltMonatlich"?: number,
        "vermieteteFlaeche"?: number
    }
}
export interface Nutzflaechen {
    "dachgeschoss"?: string,
    "unterkellerung"?: string
}
export interface Anschrift {
    "strasse"?: string,
    "hausnummer"?: string,
    "plz"?: string,
    "ort"?: string,
}
export interface Stellplatz {
    "typ"?: string,
}

export const KundendataOne: Kunde = {
    referenzId: 'jsonRefi',
    personendaten: {
        person: {
            titel: undefined,
            anrede: '',
            vorname: '',
            nachname: ''
        },
        geburtsdatum: '',
        staatsangehoerigkeit: '',
        nichtEuBuerger: undefined,
        familienstand: {
            '@type': ''
        },
    },
    wohnsituation: {
        anschrift: undefined
    },
    finanzielles: {
        beschaeftigung: {
            '@type': '',
            beruf: '',
            beschaeftigungsverhaeltnis: {
                arbeitgeber: {
                    inDeutschland: true
                },
                probezeit: false,
                anzahlGehaelterProJahr: '',
                beschaeftigtSeit: ''
            },
            beschaeftigungsstatus: '',
        },
        einkommenNetto: 0
    },
    kontakt: {
        telefonnummer: {
            vorwahl: '',
            nummer: ''
        },
        email: '',
        weitereKontaktmoeglichkeiten: ''
    },
    zusatzangaben: {}
}

export const KundendataTwo: Kunde = {
    referenzId: 'jsonRefi',
    personendaten: {
        person: {
            titel: undefined,
            anrede: '',
            vorname: '',
            nachname: ''
        },
        geburtsdatum: '',
        staatsangehoerigkeit: '',
        nichtEuBuerger: undefined,
        familienstand: {
            '@type': ''
        },
    },
    wohnsituation: {
        anschrift: undefined
    },
    finanzielles: {
        beschaeftigung: {
            '@type': '',
            beruf: '',
            beschaeftigungsverhaeltnis: {
                arbeitgeber: {
                    inDeutschland: true
                },
                probezeit: false,
                anzahlGehaelterProJahr: '',
                beschaeftigtSeit: ''
            },
            beschaeftigungsstatus: '',
        },
        einkommenNetto: 0
    },
    kontakt: {
        telefonnummer: {
            vorwahl: '',
            nummer: ''
        },
        email: '',
        weitereKontaktmoeglichkeiten: ''
    },
    zusatzangaben: {}
}

export const requestData: Kundenangaben = {
    haushalte: [
        {
            kunden: [KundendataOne],
            erfahrungImmobilienFinanzierung: false,
            kinderErfassung: {
                '@type': 'KEINE_KINDER',
                kinder: undefined,
            },
            finanzielleSituation: {
                einnahmen: {
                    summeVariablerEinkuenfteMonatlich: undefined,
                    einkuenfteAusNebentaetigkeit: undefined,
                    summeUnbefristeteZusatzrentenMonatlich: undefined,
                    summeSonstigeEinnahmenMonatlich: undefined
                },
                ausgaben: {
                    summeMietausgaben: undefined,
                    summePrivaterKrankenversicherungenMonatlich: undefined,
                    unterhaltsverpflichtungenMonatlich: [],
                    summeSonstigerAusgabenMonatlich: undefined,
                    summeSonstigerVersicherungsausgabenMonatlich: undefined
                },
                verbindlichkeiten: {
                    ratenkredite: undefined,
                    privatdarlehen: undefined,
                    sonstigeVerbindlichkeit: undefined
                },
                vermoegen: {
                    summeBankUndSparguthaben: undefined,
                    summeDepotvermoegen: undefined,
                    bausparvertraege: undefined,
                    lebensOderRentenversicherungen: undefined,
                    summeSonstigesVermoegen: undefined
                },
                bestehendeImmobilienErfassung: {
                    '@type': 'KEINE_BESTEHENDEN_IMMOBILIEN',
                    bestehendeImmobilien: undefined,
                }
            },
            zusatzangaben: {
                kfzAnzahl: 0
            }
        }],
    finanzierungsobjekt: {
        immobilie: {
            adresse: {
                strasse: undefined,
                hausnummer: undefined,
                plz: undefined,
                ort: undefined
            },
            typ: {
                '@type': '',
                grundstuecksgroesse: 0,
                grundstuecksart: undefined,
                gebaeude: undefined,
                einliegerwohnungVorhanden: undefined,
                nutzflaechen: undefined,
                fertighaus: undefined,
                anzahlDerGewerbeeinheiten: undefined,
                anzahlDerGewerbeeinheitenImObjekt: undefined,
                anzahlDerWohneinheiten: undefined,
                anzahlDerWohneinheitenImObjekt: undefined,
                haustyp: undefined
            },
            stellplaetzeErfassung: undefined,
            erbbaurechtErfassung: undefined
        }
    },
    finanzierungsbedarf: {
        finanzierungszweck: {
            '@type': '',
        },
        finanzierungsbausteine: []
    }
}

export const requestDataTwo: Kundenangaben = {
    haushalte: [
        {
            kunden: [KundendataOne, KundendataTwo],
            erfahrungImmobilienFinanzierung: false,
            kinderErfassung: {
                '@type': 'KEINE_KINDER',
                kinder: undefined,
            },
            finanzielleSituation: {
                einnahmen: {
                    summeVariablerEinkuenfteMonatlich: undefined,
                    einkuenfteAusNebentaetigkeit: undefined,
                    summeUnbefristeteZusatzrentenMonatlich: undefined,
                    summeSonstigeEinnahmenMonatlich: undefined
                },
                ausgaben: {
                    summeMietausgaben: undefined,
                    summePrivaterKrankenversicherungenMonatlich: undefined,
                    unterhaltsverpflichtungenMonatlich: [],
                    summeSonstigerAusgabenMonatlich: undefined,
                    summeSonstigerVersicherungsausgabenMonatlich: undefined
                },
                verbindlichkeiten: {
                    ratenkredite: undefined,
                    privatdarlehen: undefined,
                    sonstigeVerbindlichkeit: undefined
                },
                vermoegen: {
                    summeBankUndSparguthaben: undefined,
                    summeDepotvermoegen: undefined,
                    bausparvertraege: undefined,
                    lebensOderRentenversicherungen: undefined,
                    summeSonstigesVermoegen: undefined
                },
                bestehendeImmobilienErfassung: {
                    '@type': 'KEINE_BESTEHENDEN_IMMOBILIEN',
                    bestehendeImmobilien: undefined,
                }
            },
            zusatzangaben: {
                kfzAnzahl: 0
            }
        }],
    finanzierungsobjekt: {
        immobilie: {
            adresse: {
                strasse: undefined,
                hausnummer: undefined,
                plz: undefined,
                ort: undefined
            },
            typ: {
                '@type': '',
                grundstuecksgroesse: 0,
                grundstuecksart: undefined,
                gebaeude: undefined,
                einliegerwohnungVorhanden: undefined,
                nutzflaechen: undefined,
                fertighaus: undefined,
                anzahlDerGewerbeeinheiten: undefined,
                anzahlDerGewerbeeinheitenImObjekt: undefined,
                anzahlDerWohneinheiten: undefined,
                anzahlDerWohneinheitenImObjekt: undefined,
                haustyp: undefined
            },
            stellplaetzeErfassung: undefined,
            erbbaurechtErfassung: undefined
        }
    },
    finanzierungsbedarf: {
        finanzierungszweck: {
            '@type': '',
        },
        finanzierungsbausteine: []
    }
}

