import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-modernization',
  templateUrl: './modernization.component.html',
  styleUrls: ['./modernization.component.scss']
})
export class ModernizationComponent implements OnInit {
  public slideName: string = 'modernization';
  next: string = 'bauweise';
  localValue!: string;
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  tiles = tilesContent['MODERNIZATION'].items;
  year: string = '';
  errorYear: string = 'COMMON.ERRORS.YEAR_NEUTRAL';
  rating: number = 0; 
  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public slideTo:EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('content') content!: TemplateRef<any>;
  @ViewChild('objectYear') objectYear!: any;
  @ViewChild('objectGrad') objectGrad!: any;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public contentService: ContentService,
  ) {}

  ngOnInit() {
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.setStatus();
    });
  }

  setStatus() {
    this.disabled = true;
    const parentType = this.dataQuery.getEntity(1)?.['objectModernisierungErfassung'];
    const year = this.dataQuery.getEntity(1)?.['objectModernisierungJahr'];
    const grad = this.dataQuery.getEntity(1)?.['objectModernisierungGrad'];

    if (!!parentType) {
      this.localValue = parentType;
      if(parentType === 'VORHANDENE_MODERNISIERUNG') {
        this.disabled = (!!this.year && !!grad) ? false : true;
        this.tiles = this.contentService.setTilesContent(this.tiles, parentType, this.content);
        if(!!year) {
          setTimeout(() => {this.validateYear(year, this.objectYear);});
        } else {
          this.contentService.setSlideIndexState(this.slideName, false, 'warning');
          this.stateChange.emit(true);
        }
        if(!!grad) {
          switch (grad) {
            case 'GERING': this.rating = 1; break;
            case 'MITTEL': this.rating = 2; break;
            case 'HOCH': this.rating = 3; break;
          }
        } else {
          this.contentService.setSlideIndexState(this.slideName, false, 'warning');
        }

      } else {
        this.contentService.setSlideIndexState(this.slideName, false, parentType === 'unbekannt' ? 'warning' : 'success');
        this.stateChange.emit(true);
        this.disabled = false;
      }
      this.stateChange.emit(true);

      this.tiles.map(tile => {
        if (tile.value === parentType) {
            tile.state = 'success';
            tile.checked = true;
            if (tile.value === 'VORHANDENE_MODERNISIERUNG') {
              tile.state = (!!this.year && !!grad) ? 'success' : 'warning';
            }
        } else {
          tile.state = 'warning';
          tile.checked = false;
        }
      });
    }
    this.daNextSlideService.update(1, { disabled: this.disabled});
  }

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'next') {
        if (this.allState !== 'success') {
          this.showState = true;
          this.setDisabled();
        } else {
          this.setDisabled('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 100)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  setValue(e: any) {
    this.localValue = e;
      this.tiles = this.contentService.setTileChecked(this.tiles, e);
      this.tiles = this.contentService.setTileStatus(this.tiles, e, 'success', 'warning');
      this.daService.update(1, { objectModernisierungErfassung: e });

      if (e === "VORHANDENE_MODERNISIERUNG") {
        this.tiles = this.contentService.setTileStatusChecked(this.tiles, 'VORHANDENE_MODERNISIERUNG', 'warning', true);
        this.setType(e);
        return
      } else {
        this.setType(e, true);
      }
  }

  setType(e: string, removeFields?: boolean) {
    if (removeFields) { 
      this.disabled = false;
      this.daService.resetValues({ objectModernisierungJahr: '' }); 
      this.daService.resetValues({ objectModernisierungGrad: '' }); 
      this.contentService.setSlideIndexState(this.slideName, false, e === 'unbekannt' ? 'warning' : 'success');
      this.setAllState(e === 'unbekannt' ? 'warning' : 'success', e === 'unbekannt' ? 'INCOMPLETE' : 'COMPLETE');
      this.contentService.setNav('next', 'modernization', this.slideTo, this.next);
    } else { 
      this.disabled = true;
      this.tiles = this.contentService.setTilesContent(this.tiles, e, this.content);
    }
    this.stateChange.emit(true);
    this.daNextSlideService.update(1, { disabled: this.disabled});
  }

  validateYear(year: any, element: any) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const minMax = {min: currentYear - 20, max: currentYear}
    setTimeout(() => {
      this.objectYear.value = year.target ? year.target.value : year;

      this.objectYear = this.contentService.validateYearValue(year.target ? year.target.value : year, element, minMax); 
      if (!this.objectYear.error) {
        this.year = year;
        this.daService.update(1, { objectModernisierungJahr: element.value })
      } else {
        const yearError = this.contentService.minMax(year.target.value, minMax.min, minMax.max) as unknown as any;
        this.errorYear = yearError?.tooLow ?'COMMON.ERRORS.MODERNIZATION_TOO_OLD' : yearError?.tooHigh ? 'COMMON.ERRORS.MODERNIZATION_FUTURE' : 'COMMON.ERRORS.YEAR_NEUTRAL';
        this.tiles = this.contentService.setTileStatusChecked(this.tiles, this.localValue, 'error', true);
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.setAllState('error', 'ERROR');
        this.stateChange.emit(true);
      }

      setTimeout(() => { this.setDisabled() });
    }, 100);
  }

  setGrad(e: any) {
    let rating = '';
    switch (e.rating) {
      case 1: rating = 'GERING'; break;
      case 2: rating = 'MITTEL'; break;
      case 3: rating = 'HOCH'; break;
    }
      this.daService.update(1, { objectModernisierungGrad: rating });
      this.rating = e.rating;
      this.setDisabled();
  }

  setDisabled(type: string = 'default') {
    if(this.rating > 0 && (!!this.year && !this.objectYear.error)) {
      this.disabled = false;
      if (type === 'test') {
        this.inputDisabled = true;
        this.testValid = false;
      }
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.contentService.setTileChecked(this.tiles, this.localValue);
      this.contentService.setTileStatusChecked(this.tiles, this.localValue, 'success', true);
      this.setAllState('success', 'COMPLETE');
    } else {
      this.disabled = true;
      this.testValid = true;
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.contentService.setTileStatusChecked(this.tiles, this.localValue, 'warning', true);
      this.setAllState('warning', 'INCOMPLETE');
    }

    if(this.objectYear.error) {
      this.contentService.setSlideIndexState(this.slideName, false, 'error');
      this.contentService.setTileStatusChecked(this.tiles, this.localValue, 'error', true);
      this.setAllState('error', 'ERROR');
      this.testValid = true;
      this.inputDisabled = false;
    }

    this.stateChange.emit(true);
    if(this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
    this.daNextSlideService.update(1, { next: this.next, timestamp: new Date });
  }
}
