import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { LayoutService } from 'src/app/services/layout.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
import { footerMenuItems } from 'src/assets/content/menu';

@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss']
})
export class FooterMenuComponent {
  menuItems = footerMenuItems;

  @Output() public gender:EventEmitter<any> = new EventEmitter<string>();
  @Output() public cookies:EventEmitter<any> = new EventEmitter<string>();
  constructor(
    private configurationQuery: ConfigurationQuery,
    public screen: LayoutService
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
    onWindowResize() {
      this.screen.updateScreenSizes();
  }

  openLink(type: string) {
    let link = 'https://';
    this.configurationQuery.configuration.subscribe({
      next: (store) => {
        link = link + store.unternehmenseinstellungen[type];
      },
      error: (err) => {
        console.warn(type, 'da-info: no link information found.', err);
      }
    });
    window.open(link);
  }

  getLinkInfo(type: string) {
    let link = '';
    this.configurationQuery.configuration.subscribe({
      next: (store) => {
        link = store.unternehmenseinstellungen[type];
      },
      error: (err) => {
        console.warn(type, 'da-info: no link information found.', err);
      }
    });
    return link
  }

}
