import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { switchesContent } from 'src/assets/content/switches';

@Component({
  selector: 'app-anlaufjahre',
  templateUrl: './anlaufjahre.component.html',
  styleUrls: ['./anlaufjahre.component.scss']
})
export class AnlaufjahreComponent implements OnInit {
  switches = switchesContent['ANLAUF'].items;
  @Input() num!: number;
  @Output() public done: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('fixedJ') fixed!: any;

  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private daService: DataService,
    private dataQuery: DataQuery,
  ) { }

  ngOnInit(): void {
  }

  setSwitch(e: string) {
    const darlehen = this.contentService.updateNestedObject('darlehenBausteine', 'tilgungsfreieAnlaufjahre', e, this.num);
    this.daService.update(1, { darlehenBausteine: darlehen });
    this.done.emit(this.num);
  }
}
