import { AfterViewInit, Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { switchesContent } from 'src/assets/content/switches';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-miete',
  templateUrl: './miete.component.html',
  styleUrls: ['./miete.component.scss']
})
export class MieteComponent implements AfterViewInit {
  public slideName: string = 'miete';
  next: string = 'health-insurance';
  localValue!: string;
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  title: string = 'MIETE.TITLE';
  tiles = tilesContent['MIETE'].items;
  tabs = switchesContent['MIETE_HEALTH'].items;
  selectedTab = switchesContent['MIETE_HEALTH'].items[0];
  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('content') content!: TemplateRef<any>;
  @ViewChild('count') count!: any;
  @ViewChild('rentOne') rentOne!: any;
  @ViewChild('rentTwo') rentTwo!: any;
  @ViewChild('abloese1') abloese1!: any;
  @ViewChild('abloese2') abloese2!: any;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public contentService: ContentService,
  ) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setStatus();
    });
  }

  setStatus() {

    const participants = this.dataQuery.getEntity(1)?.['participants'];

    if (!!participants && participants !== 'allein') {
      this.title = 'MIETE.TITLE_PARTNER';
    }
    this.disabled = true;
    const parent = this.dataQuery.getEntity(1)?.['haushaltPersonMiete'];
    const status = this.dataQuery.getEntity(1);

    if (!!parent) {
      this.localValue = parent;
      this.tiles = this.contentService.setTileChecked(this.tiles, parent);
      if (parent === 'VorhandeneMiete') {
        this.tiles = this.contentService.setTilesContent(this.tiles, parent, this.content);
        this.setSwitch(status?.['countMiete'], 'count');
        this.setTileStatus();
      } else {
        this.disabled = false;
        this.contentService.setSlideIndexState(this.slideName, false, 'success');
        this.stateChange.emit(true);
      }
    }
    this.daNextSlideService.update(1, { disabled: this.disabled });
  };

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'next') {
        if (this.allState !== 'success') {
          this.showState = true;
          this.setTileStatus();
        } else {
          this.setTileStatus('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 50)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  setValue(e: string) {
    this.localValue = e;
    this.daService.update(1, { haushaltPersonMiete: e });
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
    if (e === 'VorhandeneMiete') {
      this.contentService.setTilesContent(this.tiles, e, this.content);
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.setTileStatus();
    } else {
      this.disabled = false;
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.contentService.setNav('next', this.slideName, this.slideTo, this.next);
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
    this.stateChange.emit(true);
  }

  validateInputValue(e: any, element: any) {
    setTimeout(() => {
      let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
      element.touched = !!value ? true : false;
      const isValid = this.contentService.validatePattern(value, element.validationType);
      const isValidAmount: any = this.contentService.minMax(value, 0, 9999);
      element.error = isValid && isValidAmount === true ? false : true;

      if (!element.error) {
        if (element.name === 'rent2') {
          this.daService.update(1, { miete2: value });
        } else {
          this.daService.update(1, { miete1: value });
        }
      } else {
        this.disabled = true;
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.setAllState('error', 'ERROR');
        this.stateChange.emit(true);
      }
      this.setTileStatus();
    }, 100);
  };

  setSwitch(e: string, type: string) {
    switch (type) {
      case 'count': {
        this.daService.update(1, { countMiete: e });
      }
        break;
      case 'tabs': {
        if (e === 'number1') {
          this.selectedTab = this.tabs[0];
        } else {
          this.selectedTab = this.tabs[1];
        }
      }
        break;
      case 'abloese1': {
        this.daService.update(1, { abloeseMiete1: e });
      }
        break;
      case 'abloese2': {
        this.daService.update(1, { abloeseMiete2: e });
      }
        break;
    }
    this.setTileStatus();
  }

  setTabsStatus() {
    const status = this.dataQuery.getEntity(1);
    if (!!status) {
      if (!!status['abloeseMiete1'] && !!status['miete1']) {
        this.tabs = this.contentService.setTileStatusCheckbox(this.tabs, 'number1', 'success');
      } else {
        this.tabs = this.contentService.setTileStatusCheckbox(this.tabs, 'number1', 'warning');
      }
      if (!!status['abloeseMiete2'] && !!status['miete2']) {
        this.tabs = this.contentService.setTileStatusCheckbox(this.tabs, 'number2', 'success');
      } else {
        this.tabs = this.contentService.setTileStatusCheckbox(this.tabs, 'number2', 'warning');
      }
    }

    if (this.rentOne?.error) {
      this.tabs = this.contentService.setTileStatusCheckbox(this.tabs, 'number1', 'error');
    }
    if (this.rentTwo?.error) {
      this.tabs = this.contentService.setTileStatusCheckbox(this.tabs, 'number2', 'error');
    }
    if (this.selectedTab.value === 'number1') {
      this.selectedTab = this.tabs[0];
    } else {
      this.selectedTab = this.tabs[1];
    }
  }

  setTileStatus(type: string = 'default') {
    this.setTabsStatus();
    const status = this.dataQuery.getEntity(1);
    let valid = false;

    if (!!status) {
      if (!!status['countMiete']) {
        if (status['countMiete'] === 'one') {
          valid = !!status['miete1'] && !!status['abloeseMiete1'] ? true : false;
        } else {
          valid = this.tabs[0].state === 'success' && this.tabs[1].state === 'success' ? true : false;
        }
      }
    };

    if (valid) {
      this.tiles = this.contentService.setTileStatus(this.tiles, this.localValue, 'success', 'success');
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.setAllState('success', 'COMPLETE');
      this.stateChange.emit(true);
      this.disabled = false;
    } else {
      if (!!status?.['countMiete']) {
        if (status?.['countMiete'] === 'one') {
          this.tiles = this.contentService.setTileStatus(this.tiles, this.localValue, this.rentOne?.error ? 'error' : 'warning', 'success');
          this.contentService.setSlideIndexState(this.slideName, false, this.rentOne?.error ? 'error' : 'warning');
          this.setAllState(this.rentOne?.error ? 'error' : 'warning', this.rentOne?.error ? 'ERROR' : 'INCOMPLETE');
        } else {
          this.tiles = this.contentService.setTileStatus(this.tiles, this.localValue, this.rentOne?.error || this.rentTwo?.error ? 'error' : 'warning', 'success');
          this.contentService.setSlideIndexState(this.slideName, false, this.rentOne?.error || this.rentTwo?.error ? 'error' : 'warning');
          this.setAllState(this.rentOne?.error || this.rentTwo?.error ? 'error' : 'warning', this.rentOne?.error || this.rentTwo?.error ? 'ERROR' : 'INCOMPLETE');
        }
      }
      this.disabled = true;
      this.stateChange.emit(true);
    }

    if(!this.disabled) {
      if (type === 'test') {
        this.inputDisabled = true;
        this.testValid = false;
      }
    } else {
      this.testValid = true;
      this.inputDisabled = false;
    }

    if(this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }
}
