<div class="da-lightbox" [ngClass]="{'smallest' : screen.isSmallest(), 'mobile' : screen.isMobile(), 'is-tablet' : screen.isMobileOrSmallTablet(), 'is-L-size' : screen.isBiggerThanMinWidth()}">
    <div class="da-close-icon" [ngClass]="{'mobile' : screen.isSmallest()}" (click)="dismiss()">
        <svg-icon
            src="assets/images/chevronRight.svg"
        >
        </svg-icon>
    </div>
    <div class="da-modal-body">
        <div class="da-header-icon">
            <svg-icon [src]="webappType !== 'a' ? 'assets/images/barsProgress.svg' : 'assets/images/fileCertificate.svg'"></svg-icon>
        </div>
        <lib-header-text
            *ngIf="screenType !== 'screen3' && webappType === 'a'"
            class="da-lightbox-header"
            size="38px"
            [convertMobile]="true"
            [light]="true"
            label="{{'UEBERGABE_A.TITLE' | translate}}"
        ></lib-header-text>
        <lib-header-text
            *ngIf="screenType === 'screen3' && webappType === 'a'"
            class="da-lightbox-header"
            size="38px"
            [convertMobile]="true"
            [light]="true"
            label="{{'UEBERGABE_A.TITLE2' | translate}}"
        ></lib-header-text>
        <lib-header-text
            *ngIf="webappType !== 'a'"
            class="da-lightbox-header"
            size="38px"
            [convertMobile]="true"
            [light]="true"
            label="{{'UEBERGABE_B.TITLE' | translate}}"
        ></lib-header-text>
        <app-bubble-nav 
            *ngIf="webappType === 'a'"
            [buttons]="bubbles" 
            (buttonValue)="getBubbleNav($event)"
        ></app-bubble-nav>
        <div class="da-lightbox-content">

            <!-- SCREEN 1 START -->
            <ng-container *ngIf="screenType === 'screen1'">
                <!-- PRODUCT -->
                <app-product-info [type]="screenType" [free]="free" *ngIf="webappType === 'a'"></app-product-info>
                <div class="da-content-wrapper">
                    <div class="da-content-wrapper-row headline" [ngClass]="{ 'mobile' : screen.isMobile() }">
                        <lib-header-text
                            class="da-lightbox-header"
                            size="20px"
                            [convertMobile]="false"
                            [light]="true"
                            label="{{'UEBERGABE_A.FORM.SCREEN1.TITLE' | translate}}"
                        ></lib-header-text>
                    </div>
                    <!-- PEOPLE SWITCH -->
                    <div class="da-content-wrapper-row headline" [ngClass]="{ 'mobile' : screen.isSmallest() }" *ngIf="contentService.getValue('participants') !=='allein'">
                        <button class="da-person-button" [ngClass]="{ 'active' : person === 'person1' }" (click)="switchPeople('person1')">
                            <svg-icon class="da-person-button-img" src="assets/images/person.svg" *ngIf="contentService.getValue('haushaltPerson') === 'MANN' || !contentService.getValue('haushaltPerson')"></svg-icon>
                            <svg-icon class="da-person-button-img" src="assets/images/personDress.svg" *ngIf="contentService.getValue('haushaltPerson') === 'FRAU'"></svg-icon>
                            <svg-icon class="da-person-button-img" src="assets/images/personHalfDress.svg" *ngIf="contentService.getValue('haushaltPerson') === 'DIVERS'"></svg-icon>
                            <lib-state-icon [state]="person1State"></lib-state-icon>
                        </button>
                        <button class="da-person-button" [ngClass]="{ 'active' : person === 'person2' }" (click)="switchPeople('person2')">
                            <svg-icon class="da-person-button-img" src="assets/images/person.svg" *ngIf="contentService.getValue('haushaltPersonPartner') === 'MANN' || !contentService.getValue('haushaltPerson')"></svg-icon>
                            <svg-icon class="da-person-button-img" src="assets/images/personDress.svg" *ngIf="contentService.getValue('haushaltPersonPartner') === 'FRAU'"></svg-icon>
                            <svg-icon class="da-person-button-img" src="assets/images/personHalfDress.svg" *ngIf="contentService.getValue('haushaltPersonPartner') === 'DIVERS'"></svg-icon>
                            <lib-state-icon [state]="person2State"></lib-state-icon>
                        </button>
                    </div>
                    <!-- PERSON 1 - BOX 1 -->
                    <ng-container *ngIf="person === 'person1'">
                        <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                            <lib-header-text
                                class="da-lightbox-header"
                                size="20px"
                                [convertMobile]="false"
                                [light]="true"
                                label="{{'UEBERGABE_A.FORM.SCREEN1.TEXT1' | translate}}"
                            ></lib-header-text>
                        </div>
                        <!-- GENDER -->
                        <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                            <div class="da-content-form-wrapper">
                                <lib-toggle-horizontal
                                    #gender1
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL1' | translate}}"
                                    [options]="peopleItems"
                                    [light]="true"
                                    name="gender1"
                                    [elementWidth]="screen.isSmallest() ? '102px' : '120px'"
                                    [localValue]="contentService.getValue('haushaltPerson')"
                                    (switchValue)="setSwitch($event, gender1)">
                                </lib-toggle-horizontal>
                            </div>
                            <div class="da-content-form-wrapper title">
                                <lib-header-text
                                    class="da-lightbox-header"
                                    size="14px"
                                    [convertMobile]="false"
                                    [light]="true"
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL2' | translate}}"
                                ></lib-header-text>
                                <div class="da-content-form-row">
                                    <lib-toggle-checkbox
                                        #prof1
                                        label="{{'UEBERGABE_A.FORM.LABELS.LABEL3' | translate}}"
                                        [light]="true"
                                        [checked]="contentService.getValue('haushaltPersonTitleProf')"
                                        (change)="setSwitch($event)"
                                        name="prof1">
                                    </lib-toggle-checkbox>
                                    <lib-toggle-checkbox
                                        #dr1
                                        label="{{'UEBERGABE_A.FORM.LABELS.LABEL4' | translate}}"
                                        [light]="true"
                                        [checked]="contentService.getValue('haushaltPersonTitleDr')"
                                        (change)="setSwitch($event)"
                                        name="dr1">
                                    </lib-toggle-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- NAME -->
                        <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                            <div class="da-content-input-wrapper">
                                <lib-input  
                                    #firstName1
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL5' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('haushaltPersonFirstName') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="firstName1"
                                    placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL5' | translate}}"
                                    [touched]="!!contentService.getValue('haushaltPersonFirstName')"
                                    [error]="false"
                                    validationType="string"
                                    inputmode=""
                                    errorText="{{'LOGIN_LIGHT.ERRORS.FIRST_NAME' | translate}}"
                                    (inputValue)="validateInput($event, firstName1)">
                                </lib-input>
                            </div>
                            <div class="da-content-input-wrapper">
                                <lib-input  
                                    #lastName1
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL6' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('haushaltPersonLastName') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="lastName1"
                                    placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL6' | translate}}"
                                    [touched]="!!contentService.getValue('haushaltPersonLastName')"
                                    [error]="false"
                                    validationType="string"
                                    inputmode=""
                                    errorText="{{'LOGIN_LIGHT.ERRORS.LAST_NAME' | translate}}"
                                    (inputValue)="validateInput($event, lastName1)">
                                </lib-input>
                            </div>
                        </div>
                    </ng-container>
                    <!-- PERSON 2 - BOX 1 -->
                    <ng-container *ngIf="person === 'person2'">
                        <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                            <lib-header-text
                                class="da-lightbox-header"
                                size="20px"
                                [convertMobile]="false"
                                [light]="true"
                                label="{{'UEBERGABE_A.FORM.SCREEN1.TEXT2' | translate}}"
                            ></lib-header-text>
                        </div>
                        <!-- GENDER -->
                        <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                            <div class="da-content-form-wrapper">
                                <lib-toggle-horizontal
                                    #gender2
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL1' | translate}}"
                                    [options]="peopleItems"
                                    [light]="true"
                                    name="gender2"
                                    [elementWidth]="screen.isSmallest() ? '102px' : '120px'"
                                    [localValue]="contentService.getValue('haushaltPersonPartner')"
                                    (switchValue)="setSwitch($event, gender2)">
                                </lib-toggle-horizontal>
                            </div>
                            <div class="da-content-form-wrapper title">
                                <lib-header-text
                                    class="da-lightbox-header"
                                    size="14px"
                                    [convertMobile]="false"
                                    [light]="true"
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL2' | translate}}"
                                ></lib-header-text>
                                <div class="da-content-form-row">
                                    <lib-toggle-checkbox
                                    #prof2
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL3' | translate}}"
                                    [light]="true"
                                    [checked]="contentService.getValue('haushaltPersonTitleProfPartner')"
                                    (change)="setSwitch($event)"
                                    name="prof2">
                                </lib-toggle-checkbox>
                                <lib-toggle-checkbox
                                    #dr2
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL4' | translate}}"
                                    [light]="true"
                                    [checked]="contentService.getValue('haushaltPersonTitleDrPartner')"
                                    (change)="setSwitch($event)"
                                    name="dr2">
                                </lib-toggle-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- NAME -->
                        <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                            <div class="da-content-input-wrapper">
                                <lib-input  
                                    #firstName2
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL5' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('haushaltPersonFirstNamePartner') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="firstName2"
                                    placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL5' | translate}}"
                                    [touched]="!!contentService.getValue('haushaltPersonFirstNamePartner')"
                                    [error]="false"
                                    validationType="string"
                                    inputmode=""
                                    errorText="{{'LOGIN_LIGHT.ERRORS.FIRST_NAME' | translate}}"
                                    (inputValue)="validateInput($event, firstName2)">
                                </lib-input>
                            </div>
                            <div class="da-content-input-wrapper">
                                <lib-input  
                                    #lastName2
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL6' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('haushaltPersonLastNamePartner') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="lastName2"
                                    placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL6' | translate}}"
                                    [touched]="!!contentService.getValue('haushaltPersonLastNamePartner')"
                                    [error]="false"
                                    validationType="string"
                                    inputmode=""
                                    errorText="{{'LOGIN_LIGHT.ERRORS.LAST_NAME' | translate}}"
                                    (inputValue)="validateInput($event, lastName2)">
                                </lib-input>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="da-content-wrapper">
                    <!-- PERSON 1 - BOX 2 -->
                    <ng-container *ngIf="person === 'person1'">
                        <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                            <lib-header-text
                                class="da-lightbox-header"
                                size="20px"
                                [convertMobile]="false"
                                [light]="true"
                                label="{{'UEBERGABE_A.FORM.SCREEN1.TEXT3' | translate}}"
                            ></lib-header-text>
                        </div>
                        <!-- TELEFON -->
                        <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                            <!-- <div> -->
                                <div class="da-content-input-wrapper land">
                                    <lib-input  
                                        label="{{'UEBERGABE_A.FORM.LABELS.LABEL7' | translate}}"
                                        [autoWidth]="true"
                                        value="+49"
                                        [light]="true"
                                        [isCurrency]="false"
                                        name="land1"
                                        [touched]="false"
                                        [inputDisabled]="true"
                                        [error]="false">
                                    </lib-input>
                                </div>
                                <div class="da-content-input-wrapper vorwahl">
                                    <lib-dropdown 
                                        #vorwahl1
                                        name="vorwahl1"
                                        [selectItems]="vorwahlItems" 
                                        [selectedItem]="vorwahl1.selectedItem ?? selectedVorwahlItem"
                                        label="{{'UEBERGABE_A.FORM.LABELS.LABEL8' | translate}}"
                                        (selectValue)="getSelectedValue($event, vorwahl1)"
                                        [light]="true"
                                        [autoWidth]="true">
                                    </lib-dropdown>
                                </div>
                            <!-- </div> -->
                            <div class="da-content-input-wrapper">
                                <lib-input  
                                    #number1
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL9' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('haushaltPersonTelefon') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="number1"
                                    placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL9' | translate}}"
                                    [touched]="!!contentService.getValue('haushaltPersonTelefon')"
                                    [error]="false"
                                    validationType="numbers"
                                    errorText="{{'COMMON.ERRORS.TELEFON' | translate}}"
                                    (inputValue)="validateInput($event, number1)">
                                </lib-input>
                            </div>
                        </div>
                        <!-- E-MAIL -->
                        <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                            <div class="da-content-input-wrapper email">
                                <lib-input  
                                    #email1
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL10' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('haushaltPersonEmail') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    [doneTypingInterval]="5000"
                                    name="email1"
                                    placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL10' | translate}}"
                                    [touched]="!!contentService.getValue('haushaltPersonEmail')"
                                    [error]="false"
                                    validationType="email"
                                    inputmode="email"
                                    errorText="{{'LOGIN_LIGHT.ERRORS.EMAIL' | translate}}"
                                    (inputValue)="validateInput($event, email1)">
                                </lib-input>
                            </div>
                        </div>
                    </ng-container>
                    <!-- PERSON 2 - BOX 2 -->
                    <ng-container *ngIf="person === 'person2'">
                        <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                            <lib-header-text
                                class="da-lightbox-header"
                                size="20px"
                                [convertMobile]="false"
                                [light]="true"
                                label="{{'UEBERGABE_A.FORM.SCREEN1.TEXT4' | translate}}"
                            ></lib-header-text>
                        </div>
                        <!-- TELEFON -->
                        <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                            <!-- <div> -->
                                <div class="da-content-input-wrapper land">
                                    <lib-input  
                                        label="{{'UEBERGABE_A.FORM.LABELS.LABEL7' | translate}}"
                                        [autoWidth]="true"
                                        value="+49"
                                        [light]="true"
                                        [isCurrency]="false"
                                        name="land1"
                                        [touched]="false"
                                        [inputDisabled]="true"
                                        [error]="false">
                                    </lib-input>
                                </div>
                                <div class="da-content-input-wrapper vorwahl">
                                    <lib-dropdown 
                                        #vorwahl2
                                        name="vorwahl2"
                                        [selectItems]="vorwahlItems" 
                                        [selectedItem]="vorwahl2.selectedItem ?? selectedVorwahlItem"
                                        label="{{'UEBERGABE_A.FORM.LABELS.LABEL8' | translate}}"
                                        (selectValue)="getSelectedValue($event, vorwahl2)"
                                        [light]="true"
                                        [autoWidth]="true">
                                    </lib-dropdown>
                                </div>
                            <!-- </div> -->
                            <div class="da-content-input-wrapper">
                                <lib-input  
                                    #number2
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL9' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('haushaltPersonTelefonPartner') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="number2"
                                    placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL9' | translate}}"
                                    [touched]="!!contentService.getValue('haushaltPersonTelefonPartner')"
                                    [error]="false"
                                    validationType="numbers"
                                    errorText="{{'COMMON.ERRORS.TELEFON' | translate}}"
                                    (inputValue)="validateInput($event, number2)">
                                </lib-input>
                            </div>
                        </div>
                        <!-- E-MAIL -->
                        <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                            <div class="da-content-input-wrapper email">
                                <lib-input  
                                    #email2
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL10' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('haushaltPersonEmailPartner') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="email2"
                                    placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL10' | translate}}"
                                    [touched]="!!contentService.getValue('haushaltPersonEmailPartner')"
                                    [doneTypingInterval]="5000"
                                    [error]="false"
                                    validationType="email"
                                    inputmode="email"
                                    errorText="{{'LOGIN_LIGHT.ERRORS.EMAIL' | translate}}"
                                    (inputValue)="validateInput($event, email2)">
                                </lib-input>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="da-content-wrapper">
                    <!-- PERSON 1 - BOX 3 -->
                    <ng-container *ngIf="person === 'person1'">
                        <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                            <lib-header-text
                                class="da-lightbox-header"
                                size="20px"
                                [convertMobile]="false"
                                [light]="true"
                                label="{{'UEBERGABE_A.FORM.SCREEN1.TEXT5' | translate}}"
                            ></lib-header-text>
                        </div>
                        <!-- ANSCHRIFT 1 -->
                        <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                            <div class="da-content-input-wrapper plz">
                                <lib-input  
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL11' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('wohnsituationPlz') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="plz1"
                                    validationType="numbers"
                                    [placeholder]="!!contentService.getValue('wohnsituationPlz') ? '' : 'UEBERGABE_A.FORM.LABELS.LABEL11' | translate"
                                    [touched]="!!contentService.getValue('wohnsituationPlz')"
                                    [inputDisabled]="!!contentService.getValue('wohnsituationPlz')"
                                    [error]="false">
                                </lib-input>
                            </div>
                            <div class="da-content-input-wrapper ort">
                                <lib-input  
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL12' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('wohnsituationOrt') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="ort1"
                                    validationType="city"
                                    inputmode=""
                                    [placeholder]="!!contentService.getValue('wohnsituationOrt') ? '' : 'UEBERGABE_A.FORM.LABELS.LABEL12' | translate"
                                    [touched]="!!contentService.getValue('wohnsituationOrt')"
                                    [inputDisabled]="!!contentService.getValue('wohnsituationOrt')"
                                    [error]="false">
                                </lib-input>
                            </div>
                        </div>
                        <!-- ANSCHRIFT 2 -->
                        <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                            <div class="da-content-input-wrapper ort">
                                <lib-input  
                                    #street1
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL13' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('haushaltPersonStrasse') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="street1"
                                    placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL13' | translate}}"
                                    [touched]="!!contentService.getValue('haushaltPersonStrasse')"
                                    [error]="false"
                                    validationType="street"
                                    inputmode=""
                                    errorText="{{'ADDRESS.ERRORS.STREET' | translate}}"
                                    (inputValue)="validateInput($event, street1)">
                                </lib-input>
                            </div>
                            <div class="da-content-input-wrapper plz">
                                <lib-input  
                                    #nr1
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL14' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('haushaltPersonStrasseNr') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="nr1"
                                    placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL14' | translate}}"
                                    [touched]="!!contentService.getValue('haushaltPersonStrasseNr')"
                                    [error]="false"
                                    validationType="streetNr"
                                    inputmode=""
                                    errorText="{{'ADDRESS.ERRORS.NR' | translate}}"
                                    (inputValue)="validateInput($event, nr1)">
                                </lib-input>
                            </div>
                        </div>
                    </ng-container>
                    <!-- PERSON 3 - BOX 3 -->
                    <ng-container *ngIf="person === 'person2'">
                        <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                            <lib-header-text
                                class="da-lightbox-header"
                                size="20px"
                                [convertMobile]="false"
                                [light]="true"
                                label="{{'UEBERGABE_A.FORM.SCREEN1.TEXT6' | translate}}"
                            ></lib-header-text>
                        </div>
                        <!-- ANSCHRIFT 1 -->
                        <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                            <div class="da-content-input-wrapper plz">
                                <lib-input  
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL11' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('wohnsituationPlzPartner') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="plz2"
                                    validationType="numbers"
                                    [placeholder]="!!contentService.getValue('wohnsituationPlzPartner') ? '' : 'UEBERGABE_A.FORM.LABELS.LABEL11' | translate"
                                    [touched]="!!contentService.getValue('wohnsituationPlzPartner')"
                                    [inputDisabled]="!!contentService.getValue('wohnsituationPlzPartner')"
                                    [error]="false">
                                </lib-input>
                            </div>
                            <div class="da-content-input-wrapper ort">
                                <lib-input  
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL12' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('wohnsituationOrtPartner') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="ort2"
                                    validationType="city"
                                    inputmode=""
                                    [placeholder]="!!contentService.getValue('wohnsituationOrtPartner') ? '' : 'UEBERGABE_A.FORM.LABELS.LABEL12' | translate"
                                    [touched]="!!contentService.getValue('wohnsituationOrtPartner')"
                                    [inputDisabled]="!!contentService.getValue('wohnsituationOrtPartner')"
                                    [error]="false">
                                </lib-input>
                            </div>
                        </div>
                        <!-- ANSCHRIFT 2 -->
                        <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                            <div class="da-content-input-wrapper ort">
                                <lib-input  
                                    #street2
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL13' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('haushaltPersonStrassePartner') ?? contentService.getValue('haushaltPersonStrasse')"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="street2"
                                    placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL13' | translate}}"
                                    [touched]="true"
                                    [error]="false"
                                    validationType="street"
                                    inputmode=""
                                    errorText="{{'ADDRESS.ERRORS.STREET' | translate}}"
                                    (inputValue)="validateInput($event, street2)">
                                </lib-input>
                            </div>
                            <div class="da-content-input-wrapper plz">
                                <lib-input  
                                    #nr2
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL14' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('haushaltPersonStrasseNrPartner') ?? contentService.getValue('haushaltPersonStrasseNr')"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="nr2"
                                    placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL14' | translate}}"
                                    [touched]="true"
                                    [error]="false"
                                    validationType="streetNr"
                                    inputmode=""
                                    errorText="{{'ADDRESS.ERRORS.NR' | translate}}"
                                    (inputValue)="validateInput($event, nr2)">
                                </lib-input>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <!-- PERSON 1 - BOX 4 -->
                <div class="da-content-wrapper" *ngIf="person === 'person1' && webappType === 'a'">
                    <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                        <lib-header-text
                            class="da-lightbox-header"
                            size="20px"
                            [convertMobile]="false"
                            [light]="true"
                            label="{{'UEBERGABE_A.FORM.SCREEN1.TEXT7' | translate}}"
                        ></lib-header-text>
                    </div>
                    <!-- SENDTO -->
                    <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                        <div class="da-content-input-wrapper email">
                            <div class="da-content-input-checkbox">
                                <lib-toggle-checkbox
                                    #zustell
                                    label=""
                                    [light]="true"
                                    [checked]="contentService.getValue('sendCertificate')"
                                    (change)="setSwitch($event)"
                                    name="zustell">
                                </lib-toggle-checkbox>
                                <lib-header-text
                                    class="da-lightbox-header checkbox"
                                    size="16px"
                                    [convertMobile]="false"
                                    [light]="true"
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL15' | translate}}"
                                ></lib-header-text>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="contentService.getValue('sendCertificate') === true">
                        <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                            <div class="da-content-input-wrapper">
                                <div class="da-content-form-wrapper">
                                    <lib-toggle-horizontal
                                        #zustellType
                                        label="{{'UEBERGABE_A.FORM.LABELS.LABEL16' | translate}}"
                                        [options]="zustellItems"
                                        [light]="true"
                                        name="zustellType"
                                        elementWidth="120px"
                                        [localValue]="contentService.getValue('sendCertificateTo')"
                                        (switchValue)="setSwitch($event, zustellType)">
                                    </lib-toggle-horizontal>
                                </div>
                            </div>
                        </div>
                        <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                            <div class="da-content-input-wrapper email">
                                <lib-input  
                                    #emailZustell
                                    label="{{'UEBERGABE_A.FORM.LABELS.LABEL10' | translate}}"
                                    [autoWidth]="true"
                                    [value]="contentService.getValue('sendCertificateToEmail') ?? ''"
                                    [light]="true"
                                    [isCurrency]="false"
                                    name="emailZustell"
                                    placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL10' | translate}}"
                                    [touched]="!!contentService.getValue('sendCertificateToEmail')"
                                    [doneTypingInterval]="5000"
                                    [error]="false"
                                    validationType="email"
                                    inputmode="email"
                                    errorText="{{'LOGIN_LIGHT.ERRORS.EMAIL' | translate}}"
                                    (inputValue)="validateInput($event, emailZustell)">
                                </lib-input>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="da-content-wrapper-row headline" [ngClass]="{ 'mobile' : screen.isSmallest() }" *ngIf="person === 'person1' && webappType !== 'a'">
                    <div class="da-content-checkbox-wrapper">
                        <lib-toggle-checkbox
                            #datenschutz
                            [dynLabelClassName]="toggleCheckboxLabelClass + ' wrap'"
                            [light]="true"
                            [checked]="contentService.getValue('datenschutzZertifikat')"
                            (change)="setSwitch($event)"
                            name="datenschutz">
                        </lib-toggle-checkbox>
                        <lib-toggle-checkbox
                            #agb
                            [dynLabelClassName]="toggleCheckboxLabelClass + ' wrap'"
                            [light]="true"
                            [checked]="contentService.getValue('agbZertifikat')"
                            (change)="setSwitch($event)"
                            name="agb">
                        </lib-toggle-checkbox>
                    </div>
                </div>
                <div class="da-content-wrapper-row headline" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <div class="da-content-button-wrapper" *ngIf="showButtons">
                        <div class="da-sidebar-button-bg">
                            <lib-button 
                                *ngIf="webappType === 'a'"
                                name="weiter button"
                                value="{{'UEBERGABE_A.FORM.BUTTONS.SCREEN1' | translate}}" 
                                (buttonValue)="goTo('screen2')"
                                width="250"
                                [light]="true"
                                [disabled]="screen1Disabled"
                                iconRight="assets/images/cartShopping.svg"
                            ></lib-button>
                            <lib-button 
                                *ngIf="webappType !== 'a'"
                                name="weiter button"
                                value="{{'UEBERGABE_B.BUTTON' | translate}}" 
                                (buttonValue)="processRequest()"
                                width="320"
                                [light]="true"
                                [disabled]="screen1Disabled"
                                iconRight="assets/images/barsProgress.svg"
                            ></lib-button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- SCREEN 1 ENDE -->


            <!-- SCREEN 2 START -->
            <ng-container *ngIf="screenType === 'screen2'">
                <!-- PRODUCT -->
                <app-product-info [type]="screenType" [free]="free"></app-product-info>
                <div class="da-content-wrapper">
                    <div class="da-content-wrapper-row headline" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                        <lib-header-text
                            class="da-lightbox-header"
                            size="20px"
                            [convertMobile]="false"
                            [light]="true"
                            label="{{'UEBERGABE_A.FORM.SCREEN2.TITLE' | translate}}"
                        ></lib-header-text>
                    </div>
                    <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                        <lib-header-text
                            class="da-lightbox-header"
                            size="20px"
                            [convertMobile]="false"
                            [light]="true"
                            label="{{'UEBERGABE_A.FORM.SCREEN2.TEXT1' | translate}}"
                        ></lib-header-text>
                    </div>
                    <!-- GENDER -->
                    <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                        <div class="da-content-form-wrapper">
                            <lib-toggle-horizontal
                                #zahlendePerson
                                label="{{'UEBERGABE_A.FORM.LABELS.LABEL1' | translate}}"
                                [options]="peopleItems"
                                [light]="true"
                                name="zahlendePerson"
                                [elementWidth]="screen.isSmallest() ? '102px' : '120px'"
                                [localValue]="contentService.getValue('zahlendePerson')"
                                (switchValue)="setSwitch($event, zahlendePerson)">
                            </lib-toggle-horizontal>
                        </div>
                        <div class="da-content-form-wrapper title">
                            <lib-header-text
                                class="da-lightbox-header"
                                size="14px"
                                [convertMobile]="false"
                                [light]="true"
                                label="{{'UEBERGABE_A.FORM.LABELS.LABEL2' | translate}}"
                            ></lib-header-text>
                            <div class="da-content-form-row">
                                <lib-toggle-checkbox
                                #zahlendePersonProf
                                label="{{'UEBERGABE_A.FORM.LABELS.LABEL3' | translate}}"
                                [light]="true"
                                [checked]="contentService.getValue('zahlendePersonTitleProf')"
                                (change)="setSwitch($event)"
                                name="zahlendePersonProf">
                            </lib-toggle-checkbox>
                            <lib-toggle-checkbox
                                #zahlendePersonDr
                                label="{{'UEBERGABE_A.FORM.LABELS.LABEL4' | translate}}"
                                [light]="true"
                                [checked]="contentService.getValue('zahlendePersonTitleDr')"
                                (change)="setSwitch($event)"
                                name="zahlendePersonDr">
                            </lib-toggle-checkbox>
                            </div>
                        </div>
                    </div>
                    <!-- NAME -->
                    <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                        <div class="da-content-input-wrapper">
                            <lib-input  
                                #zahlendePersonFirstName
                                label="{{'UEBERGABE_A.FORM.LABELS.LABEL5' | translate}}"
                                [autoWidth]="true"
                                [value]="contentService.getValue('zahlendePersonFirstName') ?? ''"
                                [light]="true"
                                [isCurrency]="false"
                                name="zahlendePersonFirstName"
                                placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL5' | translate}}"
                                [touched]="!!contentService.getValue('zahlendePersonFirstName')"
                                [error]="false"
                                validationType="string"
                                inputmode=""
                                errorText="{{'LOGIN_LIGHT.ERRORS.FIRST_NAME' | translate}}"
                                (inputValue)="validateInput($event, zahlendePersonFirstName)">
                            </lib-input>
                        </div>
                        <div class="da-content-input-wrapper">
                            <lib-input  
                                #zahlendePersonLastName
                                label="{{'UEBERGABE_A.FORM.LABELS.LABEL6' | translate}}"
                                [autoWidth]="true"
                                [value]="contentService.getValue('zahlendePersonLastName') ?? ''"
                                [light]="true"
                                [isCurrency]="false"
                                name="zahlendePersonLastName"
                                placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL6' | translate}}"
                                [touched]="!!contentService.getValue('zahlendePersonLastName')"
                                [error]="false"
                                validationType="string"
                                inputmode=""
                                errorText="{{'LOGIN_LIGHT.ERRORS.LAST_NAME' | translate}}"
                                (inputValue)="validateInput($event, zahlendePersonLastName)">
                            </lib-input>
                        </div>
                    </div>
                    <!-- E-MAIL -->
                    <div class="da-content-wrapper-input-row" [ngClass]="{ 'mobile' : screen.isMobile() }">
                        <div class="da-content-input-wrapper email">
                            <lib-input  
                                #zahlendePersonEmail
                                label="{{'UEBERGABE_A.FORM.LABELS.LABEL10' | translate}}"
                                [autoWidth]="true"
                                [value]="contentService.getValue('zahlendePersonEmail') ?? ''"
                                [light]="true"
                                [isCurrency]="false"
                                name="zahlendePersonEmail"
                                placeholder="{{'UEBERGABE_A.FORM.LABELS.LABEL10' | translate}}"
                                [touched]="!!contentService.getValue('zahlendePersonEmail')"
                                [doneTypingInterval]="5000"
                                [error]="false"
                                validationType="email"
                                inputmode="email"
                                errorText="{{'LOGIN_LIGHT.ERRORS.EMAIL' | translate}}"
                                (inputValue)="validateInput($event, zahlendePersonEmail)">
                            </lib-input>
                        </div>
                    </div>
                </div>
                <div class="da-content-wrapper" *ngIf="!free">
                    <div class="da-content-wrapper-row" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                        <lib-header-text
                            class="da-lightbox-header"
                            size="20px"
                            [convertMobile]="false"
                            [light]="true"
                            label="{{'UEBERGABE_A.FORM.SCREEN2.TEXT2' | translate}}"
                        ></lib-header-text>
                    </div>
                    <div class="da-payment-tile">
                        <lib-state-icon state='success'></lib-state-icon>
                        <div class="da-payment-row">
                            <svg-icon class="da-payment-icon" src="assets/images/fileInvoice.svg"></svg-icon>
                            <lib-header-text
                                size="20px"
                                [ngStyle]="{'text-align': 'left'}"
                                [convertMobile]="true"
                                label="{{'UEBERGABE_A.FORM.SCREEN2.TEXT6' | translate}}"
                            ></lib-header-text>
                        </div>
                    </div>
                </div>
                <div class="da-content-wrapper-row headline" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <div class="da-content-checkbox-wrapper">
                        <lib-toggle-checkbox
                            #datenschutz
                            [dynLabelClassName]="toggleCheckboxLabelClass + ' wrap'"
                            [light]="true"
                            [checked]="contentService.getValue('datenschutzZertifikat')"
                            (change)="setSwitch($event)"
                            name="datenschutz">
                        </lib-toggle-checkbox>
                        <lib-toggle-checkbox
                            #agb
                            [dynLabelClassName]="toggleCheckboxLabelClass + ' wrap'"
                            [light]="true"
                            [checked]="contentService.getValue('agbZertifikat')"
                            (change)="setSwitch($event)"
                            name="agb">
                        </lib-toggle-checkbox>
                    </div>
                </div>
                <div class="da-content-wrapper-row headline" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <div class="da-content-button-wrapper" *ngIf="showButtons">
                        <div class="da-sidebar-button-bg" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                            <div [ngStyle]="{'width' : '50px'}" class="da-back-button-certificate">
                                <lib-button 
                                    name="zurueck button"
                                    [autoWidth]="true"
                                    value=""
                                    (buttonValue)="goTo('screen1')"
                                    [light]="true"
                                    iconRight="assets/images/chevronLeft.svg"
                                ></lib-button>
                            </div>
                            <lib-button 
                                name="weiter button"
                                width="270"
                                value="{{'UEBERGABE_A.FORM.BUTTONS.SCREEN2' | translate}}" 
                                (buttonValue)="processRequest()"
                                [light]="true"
                                [disabled]="screen2Disabled"
                                iconRight="assets/images/fileCertificate.svg"
                            ></lib-button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- SCREEN 2 ENDE -->


            <!-- SCREEN 3 START -->
            <ng-container *ngIf="screenType === 'screen3'">
                <div class="da-content-wrapper-row headline" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <div class="da-content-animation-wrapper" *ngIf="webappType === 'a'">
                        <div class="da-content-animation-row" *ngFor="let item of screen3Items">
                            <svg-icon *ngIf="item.state === 'success'" class="da-animation-icon green" src="assets/images/circleCheck.svg"></svg-icon>
                            <svg-icon *ngIf="item.state === 'pending'" class="da-animation-icon" src="assets/images/loader.svg"></svg-icon>
                            <div>{{item.label | translate}}</div>
                        </div>
                    </div>
                    <div class="da-content-animation-wrapper" *ngIf="webappType !== 'a'">
                        <div class="da-content-animation-row large" *ngFor="let item of screen3DaBItems">
                            <svg-icon *ngIf="item.state === 'success'" class="da-animation-icon large green" src="assets/images/circleCheck.svg"></svg-icon>
                            <svg-icon *ngIf="item.state === 'pending'" class="da-animation-icon large" src="assets/images/loader.svg"></svg-icon>
                            <div>{{item.label | translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="da-content-wrapper-row headline" [ngClass]="{ 'mobile' : screen.isSmallest() }">
                    <div class="da-content-button-wrapper" *ngIf="showButtons">
                        <div class="da-sidebar-button-bg">
                            <lib-button 
                                name="weiter button"
                                width="300"
                                value="{{ screen3Button | translate}}" 
                                (buttonValue)="dismiss()"
                                [light]="true"
                                [disabled]="screen3Disabled"
                            ></lib-button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
