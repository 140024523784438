import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Debounce } from 'lodash-decorators';
import { LayoutService } from 'src/app/services/layout.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';

@Component({
  selector: 'app-info-sidebar',
  templateUrl: './info-sidebar.component.html',
  styleUrls: ['./info-sidebar.component.scss']
})
export class InfoSidebarComponent implements OnInit {
  @Input() header!: string;
  @Input() text!: string;
  @Input() icon!: string;
  @Input() category!: string;
  @Input() modal!: NgbModalRef;
  @Input() info: boolean = true;
  @Input() isGreen: boolean = false;
  sonderText!: string;
  agreed: boolean = false;
  snippet = 'https://consent.cookiebot.com/485ac99f-ee30-467e-bab1-4716f29847dc/cd.js';

  constructor(
    public screen: LayoutService,
    private configurationQuery: ConfigurationQuery,
  ) {}

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
    this.setCustomText();
    setTimeout(() => { this.screen.scrollToElement('start-point'); });
    if(this.category === 'cookies') {
      setTimeout(() => {this.loadScript()}, 500);
    }
  }

  // sonderfaelle Erschliessungskosten und geplante Eigenleistung
  setCustomText() {
    if (this.text === 'erschliessung' || this.text === 'eigenleistung') {
      if (this.text === 'eigenleistung') {
        this.sonderText = 'Eigenleistungen beziehen sich auf Arbeiten oder Leistungen, die von Ihnen und privaten Arbeitshelfern selbst durchgeführt werden, anstatt sie an externe Dienstleister oder Fachkräfte auszulagern. Dabei können Eigenleistungen verschiedene Tätigkeiten umfassen, wie zum Beispiel:<br><br>- Selbstständige Durchführung von handwerklichen Arbeiten<br>- Erledigung von Renovierungs- oder Bauarbeiten in Eigenregie<br>- Persönlicher Einsatz bei der Umsetzung eines Projekts oder einer Aufgabe<br>- Nutzung eigener Fähigkeiten und Ressourcen zur Kostenersparnis<br><br>Eigenleistungen ermöglichen es, Arbeitskosten zu reduzieren oder die Kontrolle über bestimmte Aspekte eines Projekts zu behalten.';
      } else {
        this.sonderText = 'Erschließungskosten beziehen sich auf die finanziellen Aufwendungen, die für die Erschließung eines Grundstücks erforderlich sind. Dabei umfassen diese Kosten verschiedene bautechnische Maßnahmen. Die verkehrsmäßige Erschließung beinhaltet unter anderem:<br><br>- Errichtung von öffentlichen und privaten Gehwegen und Straßen<br>- Anbindung an öffentliche Plätze, Parkflächen und Grünanlagen<br><br>Bei der technischen Erschließung sind folgende Arbeiten erforderlich:<br><br>- Anschluss an die Energieversorgung (Strom, Gas, Erdwärme, Fernwärme)<br>- Anbindung an das Telekommunikationsnetz (Telefon, Internet, Kabelfernsehen)<br>- Anschluss an die Kanalisation, Entwässerungssysteme für Regenwasser oder öffentliche Versickerungsanlagen<br>- Anbindung an die örtliche Trinkwasserversorgung und Feuerlöschsysteme<br>- Gegebenenfalls Vermessung und Erstellung eines Bodenwertgutachten';
      }
    }
  }

  public loadScript() {
    const container = document.getElementById('da-cookies');
    let node = document.createElement('script');
    node.src = this.snippet;
    node.type = 'text/javascript';
    node.async = true;
    node.id = 'CookieDeclaration';
    // document.getElementsByTagName('body')[0].appendChild(node);
    if(container) {container.appendChild(node)}; 
}

  dismiss() {
    document.body.style.overflowY = 'auto';
    this.modal.dismiss();
    // this.modalService.dismissAll();
  }

  setDelete(e: any) {
    this.agreed = e.srcElement.checked;
  }

  deleteAccount() {

    if (this.agreed) {
      let link = 'https://';
      this.configurationQuery.configuration.subscribe({
        next: (store) => {
          link = link + store.unternehmenseinstellungen.linkwebsite;
        },
        error: (err) => {
          console.warn(link, 'da-info: no link information found.', err);
        }
      });
      window.open(link,"_self");
    }
    this.dismiss();
  }

}
