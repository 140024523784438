<lib-header-text
    class="da-title"
    label="{{'OBJECT_DETAIL.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<div class="da-tiles-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container1State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'OBJECT_DETAIL.TILES.TILE1.TITLE' | translate}}"
            >
            </lib-tile-text>
            <lib-toggle-vertical
                #objectUnitType
                [options]="switchesUnit"
                [localValue]="contentService.getValue('objectUnitType')"
                name="unitType"
                [inputDisabled]="inputDisabled"
                (switchValue)="storeInputValue(objectUnitType, $event)"
            >
            </lib-toggle-vertical>
        </div>
    </div>
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container2State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{headerSize | translate}}"
                info="true"
                (infoValue)="screen.openInfo(estateInfo)"
            >
            </lib-tile-text>
            <lib-input
                #objectEstateSize
                label="{{'COMMON.LABELS.SIZE' | translate}}"
                name="estateSize"
                [error]="false"
                placeholder="500,00"
                unit="m²"        
                [inputDisabled]="inputDisabled"
                errorText="{{ sizeError | translate}}"
                [touched]="!!contentService.getValue('objectGrundstuecksgroesse')"
                [value]="!!contentService.getValue('objectGrundstuecksgroesse') ? contentService.getValue('objectGrundstuecksgroesse') : ''"
                (change)="validateInputValue($event, objectEstateSize)"
                (click)="testValid = true"
            ></lib-input>
        </div> 
    </div>
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container3State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'OBJECT_DETAIL.TILES.TILE3.TITLE' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(yearInfo)"
            >
            </lib-tile-text>
            <lib-input
                #objectYear
                label="{{'COMMON.LABELS.YEAR' | translate}}"
                name="year"
                [error]="false"
                placeholder="1992"
                validationType="numbers"
                [inputDisabled]="inputDisabled"
                errorText="{{'COMMON.ERRORS.YEAR' | translate}}"
                [touched]="!!contentService.getValue('objectBaujahr')"
                [value]="!!contentService.getValue('objectBaujahr') ? contentService.getValue('objectBaujahr') : ''"
                (change)="validateYear($event, objectYear)"
                (click)="testValid = true"
                [isCurrency]="false"
            ></lib-input>
        </div>
    </div>
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container4State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'OBJECT_DETAIL.TILES.TILE4.TITLE' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(einheitenInfo)"
            >
            </lib-tile-text>
            <lib-counter
                #objectEinheiten
                name="einheiten"
                [value]="einheiten"
                [max]="99"
                (counterValue)="storeInputValue(objectEinheiten, $event)"
            ></lib-counter>   
        </div>    
    </div>
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="container5State"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'OBJECT_DETAIL.TILES.TILE5.TITLE' | translate}}"
                info="true"
                (infoValue)="screen.openInfo(gewerbeeinheitenInfo)"
            >
            </lib-tile-text>
            <lib-counter
                #objectGewerbeEinheiten
                name="gewerbeeinheiten"
                [value]="gewerbeeinheiten"
                [max]="99"
                (counterValue)="storeInputValue(objectGewerbeEinheiten, $event)"
            ></lib-counter>   
        </div>    
    </div>
</div>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

<ng-template #estateInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'OBJECT_DETAIL.TILES.TILE2.INFO_HEADER' | translate}}" text="{{'OBJECT_DETAIL.TILES.TILE2.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #yearInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'OBJECT_DETAIL.TILES.TILE3.INFO_HEADER' | translate}}" text="{{'OBJECT_DETAIL.TILES.TILE3.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #einheitenInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'OBJECT_DETAIL.TILES.TILE4.INFO_HEADER' | translate}}" text="{{'OBJECT_DETAIL.TILES.TILE4.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
<ng-template #gewerbeeinheitenInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'OBJECT_DETAIL.TILES.TILE5.INFO_HEADER' | translate}}" text="{{'OBJECT_DETAIL.TILES.TILE5.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>

