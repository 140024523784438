import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';
import { switchesContent } from 'src/assets/content/switches';
import { tilesContent } from 'src/assets/content/tiles';

@Component({
  selector: 'app-privatkredit',
  templateUrl: './privatkredit.component.html',
  styleUrls: ['./privatkredit.component.scss']
})
export class PrivatkreditComponent implements AfterViewInit {
  public slideName: string = 'privatkredit';
  next: string = 'miete';
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  title: string = 'PRIVATKREDIT.TITLE';
  tiles = tilesContent['PRIVATKREDIT'].items;
  switches = switchesContent['PRIVATKREDIT'].items;
  fakeTiles = [{
    "value": "Vertrag 1",
    "name": "Vertrag 1",
    "state": "warning",
    "typeState": "warning",
    "amountState": "warning",
    "abloeseState": "warning"
  }];
  localValue!: string;
  showVertrag!: boolean;
  labelEdit: boolean = false;
  labelRate: string = 'COMMON.LABELS.RATE';

  errorType: boolean = false;
  errorTypeText: string = 'PRIVATKREDIT.ERROR_KREDIT';

  selectedTile: string = 'Vertrag 1';
  tilePosition: string = '0';
  basePosition: number = 0;
  screenPosition: string = '0px';
  tileSlideFactor: number = window.innerWidth;
  tileIndex: number = 0;
  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('content') content!: TemplateRef<any>;
  @ViewChild('vertragContainer') vertragContainer!: any;
  @ViewChildren('type') typeList!: QueryList<any>;
  @ViewChildren('rate') rateList!: QueryList<any>;
  @ViewChildren('restschuld') restschuldList!: QueryList<any>;
  @ViewChildren('abloesen') abloesenList!: QueryList<any>;
  @ViewChildren('inputs') inputsList!: QueryList<any>;

  constructor(
    private daService: DataService,
    private dataQuery: DataQuery,
    public screen: LayoutService,
    public daNextSlideService: DaNextSlideService,
    public contentService: ContentService,
    private translate: TranslateService
  ) {
    this.calculateScreenPos();
  }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
    this.calculateScreenPos();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setStatus();
      const shortcut = this.dataQuery.getEntity(1)?.['shortcut'];
      if (shortcut) {
        this.next = 'financial-assets';
      }
    });
  }

  setStatus() {
    this.disabled = true;
    const state = this.dataQuery.getEntity(1)?.['VerbindlichkeitenErfassung'];
    const participants = this.dataQuery.getEntity(1)?.['participants'];

    if (!!participants && participants !== 'allein') {
      this.title = 'PRIVATKREDIT.TITLE_PARTNER';
    }

    if (!!state) {
      this.localValue = state;
      setTimeout(() => { this.setValue(state, true); }, 100)
    }
    this.daNextSlideService.update(1, { disabled: this.disabled });
  }

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'next') {
        if (this.allState !== 'success') {
          this.showState = true;
          this.setDisabled();
        } else {
          this.setDisabled('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 50)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  setValue(e: any, init?: boolean) {
    this.localValue = e;
    this.tiles = this.contentService.setTileChecked(this.tiles, e);
    this.daService.update(1, { VerbindlichkeitenErfassung: e });

    if (e === 'VorhandeneVerbindlichkeiten') {
      if (!init) {
        this.daService.update(1, { verbindlichkeitenAnzahl: 1 });
        this.setCounter(1);
      } else {
        this.setCounter(this.dataQuery.getEntity(1)?.['verbindlichkeitenAnzahl']);
        this.tiles = this.contentService.setTilesContent(this.tiles, e, this.content);
        this.showVertrag = true;
        return;
      };
      this.tiles = this.contentService.setTilesContent(this.tiles, e, this.content);
      this.showVertrag = true;
      setTimeout(() => {
        this.screen.scrollToElement('da-content-extended');
        this.setDisabled();
      }, 100);
    } else {
      this.daService.update(1, { verbindlichkeitenAnzahl: 0 });
      this.daService.update(1, { verbindlichkeiten: [] });
      this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, e, 'success');
      this.disabled = false;
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.stateChange.emit(true);
      this.daNextSlideService.update(1, { disabled: this.disabled });
      if (!init) { this.contentService.setNav('next', this.slideName, this.slideTo, this.next); }
    }
  }

  calculateScreenPos() {
    setTimeout(() => {
      if (this.screen.isSmallerThanMinWidth()) {
        this.tileSlideFactor = window.innerWidth;
      } else {
        this.tileSlideFactor = 1366;
      }
      this.getIndex(this.selectedTile);
    });
  }


  setCounter(e: number) {
    this.daService.update(1, { verbindlichkeitenAnzahl: e });
    this.fakeTiles = [];
    for (let i = 1; i <= e; i++) {
      let contractName = 'Vertrag 1';
      this.translate.get('PRIVATKREDIT.LABEL', { number: i }).subscribe((res: string) => {
        contractName = res;
      });
      this.fakeTiles.push({
        "value": "Vertrag " + i,
        "state": "warning",
        "name": this.setLocalValue(i - 1, 'name') !== '' ? this.setLocalValue(i - 1, 'name') : contractName,
        "typeState": "warning",
        "amountState": "warning",
        "abloeseState": "warning"
      })
      this.getValue(this.setLocalValue(i - 1, 'name') !== '' ? this.setLocalValue(i - 1, 'name') : contractName, 'name', i - 1, e);
    }
    setTimeout(() => { this.setDisabled() });
  }

  setLocalValue(num: number, field: string) {
    let parsedValue = this.contentService.getNestedValue('verbindlichkeiten', field, num) ?? '';
    return parsedValue;
  }

  getValue(e: any, name: string, i: number, counter?: number) {
    let value = e.target?.value ? e.target?.value : e;
    let vertraege: any[] = [];
    const status = this.dataQuery.getEntity(1)?.['verbindlichkeiten'];
    let contractName = 'Vertrag 1';
    this.translate.get('PRIVATKREDIT.LABEL', { number: i }).subscribe((res: string) => {
      contractName = res;
    })
    if (!status) {
      const count = i + 1;
      vertraege.push({
        'id': i,
        'name': name === 'name' ? value : contractName + ' ' + count,
        'verbindlichkeitTyp': name === 'type' ? value : '',
        'rate': name === 'rate' ? value : '',
        'restschuld': name === 'restschuld' ? value : '',
        'abloesen': name === 'abloesen' ? value : '',
      })
    } else {
      let count = 0;
      status.map((vertrag: any) => {
        if (vertrag.id === i) {
          count++;
          switch (name) {
            case 'name': {
              vertrag = { ...vertrag, 'name': value };
              break
            }
            case 'type': {
              vertrag = { ...vertrag, 'verbindlichkeitTyp': value };
              break
            }
            case 'rate': {
              vertrag = { ...vertrag, 'rate': value };
              break
            }
            case 'restschuld': {
              vertrag = { ...vertrag, 'restschuld': value };
              break
            }
            case 'abloesen': {
              vertrag = { ...vertrag, 'abloesen': value };
              break
            }
          }
        }
        vertraege.push(vertrag);
      });
      if (count === 0) {
        const count = i + 1;
        vertraege.push({
          'id': i,
          'name': name === 'name' ? value : contractName + ' ' + count,
          'verbindlichkeitTyp': name === 'type' ? value : '',
          'rate': name === 'rate' ? value : '',
          'restschuld': name === 'restschuld' ? value : '',
          'abloesen': name === 'abloesen' ? value : '',
        })
      }
      if (counter) {
        if (counter <= status.length) {
          vertraege = vertraege.filter((item: any) => {
            return item.id < counter
          })
        }
      }
    }
    this.daService.update(1, { verbindlichkeiten: vertraege });
    setTimeout(() => { this.setDisabled() });
  }

  validateInput(e: any, name: string, i: number) {
    setTimeout(() => {
      let value = typeof e === 'string' ? e : e.srcElement.value ? e.srcElement.value : '';
      let element: any;
      switch (name) {
        case 'type': {
          element = this.typeList.get(i);
          this.labelRate = e === 'leasing' ? 'COMMON.LABELS.LEASING' : 'COMMON.LABELS.RATE';
          element.error = this.checkVertragAmount(e);
          if (element.error) {
            this.errorType = true;
            this.errorTypeText = e === 'sonstigeVerbindlichkeit' ? 'PRIVATKREDIT.ERROR_SONSTIGES' : 'PRIVATKREDIT.ERROR_KREDIT';
            this.contentService.setSlideIndexState(this.slideName, false, 'error');
            this.stateChange.emit(true);
          } else {
            this.errorType = false;
          }
        }
          break;
        case 'rate': {
          element = this.rateList.get(i);
        }
          break;
        case 'restschuld': {
          if (this.restschuldList.length === this.fakeTiles.length) {
            element = this.restschuldList.get(i);
          } else {
            this.restschuldList.toArray().map((item: any) => {
              let number = item.name.slice(item.name.length - 1);
              number = parseInt(number);
              if (number === i) {
                element = item;
              }
            })
          }
        }
          break;
        case 'abloesen': {
          if (this.abloesenList.length === this.fakeTiles.length) {
            element = this.abloesenList.get(i);
          } else {
            this.abloesenList.toArray().map((item: any) => {
              let number = item.name.slice(item.name.length - 1);
              number = parseInt(number);
              if (number === i) {
                element = item;
              }
            })
          }
        }
          break;
      }

      if (name === 'rate' || name === 'restschuld') {
        element.touched = true;
        element.placeholder = '';
        element.value = value;
        if (element.value === '') {
          element.error = true;
          this.getValue('', name, i);
          setTimeout(() => { this.setDisabled(); });
          return
        }
        let isValidAmount = this.contentService.minMax(element.value, 1, 999999);
        let isValid = this.contentService.validatePattern(element.value, element.validationType);
        element.error = isValid && isValidAmount === true ? false : true;
      }
      if (!element.error) {
        this.getValue(value, name, i);
      } else {
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.setAllState('error', 'ERROR');
        this.stateChange.emit(true);
        setTimeout(() => { this.setDisabled(); });
      }
    }, 50);
  }

  checkVertragAmount(e: string) {
    let isTooMuch = false;
    let countVertrag = 1;
    const verbindlichkeiten = this.dataQuery.getEntity(1)?.['verbindlichkeiten'];
    if (!!verbindlichkeiten) {
      verbindlichkeiten.map((vertrag: any) => {
        if (e === 'sonstigeVerbindlichkeit') {
          if (vertrag.verbindlichkeitTyp === e) {
            countVertrag++
          }
        } else {
          if (vertrag.verbindlichkeitTyp !== 'sonstigeVerbindlichkeit') {
            countVertrag++
          }
        }
      });
    }
    if (e === 'sonstigeVerbindlichkeit') {
      isTooMuch = countVertrag > 1 ? true : false;
    } else {
      isTooMuch = countVertrag > 6 ? true : false;
    }
    return isTooMuch;
  }

  setDisabled(type: string = 'default') {
    const inputFields = this.inputsList.toArray();
    this.disabled = true;
    let count = 0;
    let restschuld;
    let abloesen;
    let rateError = false;
    let restschuldError = false;
    let typeError = false;

    for (let i = 0; i < this.fakeTiles.length; i++) {
      const type = this.typeList.get(i).localValue.target?.value ?? this.typeList.get(i).localValue;
      const rate = this.rateList.get(i).value;
      rateError = this.rateList.get(i).error;
      typeError = this.typeList.get(i).error;

      if (this.restschuldList.length === this.fakeTiles.length) {
        restschuld = this.restschuldList.get(i).value;
        restschuldError = this.restschuldList.get(i).error;
      } else {
        this.restschuldList.toArray().map((item: any) => {
          let number = item.name.slice(item.name.length - 1);
          number = parseInt(number);
          if (number === i) {
            restschuld = item.value;
            restschuldError = item.error;
          }
        })
      }

      if (this.abloesenList.length === this.fakeTiles.length) {
        abloesen = this.abloesenList.get(i).localValue.target?.value ?? this.abloesenList.get(i).localValue;
      } else {
        this.abloesenList.toArray().map((item: any) => {
          let number = item.name.slice(item.name.length - 1);
          number = parseInt(number);
          if (number === i) {
            abloesen = item.localValue;
          }
        })
      }

      if (!!type) {
        this.fakeTiles[i].typeState = typeError ? 'error' : 'success';
        if (!!rate) {
          if (type === 'leasing') {
            this.fakeTiles[i].amountState = rateError ? 'error' : 'success';
            this.fakeTiles[i].abloeseState = 'success';
          } else {

            if (!!restschuld) {
              this.fakeTiles[i].amountState = (rateError || restschuldError) ? 'error' : 'success';
            } else {
              this.fakeTiles[i].amountState = (rateError || restschuldError) ? 'error' : 'warning';
            }

            if (!!abloesen) {
              this.fakeTiles[i].abloeseState = 'success';
            } else {
              this.fakeTiles[i].abloeseState = 'warning';
            }
          }
        } else {
          this.fakeTiles[i].amountState = 'warning';
        }
      } else {
        this.fakeTiles[i].typeState = typeError ? 'error' : 'warning';
      }

      if (this.fakeTiles[i].typeState === 'success' &&
        this.fakeTiles[i].amountState === 'success' &&
        this.fakeTiles[i].abloeseState === 'success') {
        this.setIndexState(i, 'success');
        count++;
      } else if (this.fakeTiles[i].typeState === 'warning' ||
        this.fakeTiles[i].amountState === 'warning' ||
        this.fakeTiles[i].abloeseState === 'warning') {
        this.setIndexState(i, 'warning');
        this.contentService.setSlideIndexState(this.slideName, false, 'warning');
        this.stateChange.emit(true);
      } else if (this.fakeTiles[i].typeState === 'error' ||
        this.fakeTiles[i].amountState === 'error' ||
        this.fakeTiles[i].abloeseState === 'error') {
        this.setIndexState(i, 'error');
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.stateChange.emit(true);
      }
    }

    if (count === this.fakeTiles.length) {
      this.contentService.setSlideIndexState(this.slideName, false, 'success');
      this.stateChange.emit(true);
      this.setAllState('success', 'COMPLETE');
      this.disabled = false;
    } else {
      this.disabled = true;
      this.contentService.setSlideIndexState(this.slideName, false, 'warning');
      this.setAllState('warning', 'INCOMPLETE');
      this.stateChange.emit(true);
    }
    if(inputFields?.length > 0) {
      inputFields.map((item: any) => {
        if(item.error) {
          this.contentService.setSlideIndexState(this.slideName, false, 'error');
          this.setAllState('error', 'ERROR');
          this.stateChange.emit(true);
        }
      })
    }

    if(!this.disabled) {
      if (type === 'test') {
        this.inputDisabled = true;
        this.testValid = false;
      }
    } else {
      this.testValid = true;
      this.inputDisabled = false;
    }

    if(this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }
  }

  editLabel(e: any, elementName: string, i: number) {
    this.fakeTiles[i].name = e.target.value;
    this.getValue(e.target.value, elementName, i);
  }

  setIndexState(i: number, state: string) {
    const count = i + 1;
    this.fakeTiles = this.contentService.setTileStatusCheckbox(this.fakeTiles, 'Vertrag ' + count, state);
    this.tiles = this.contentService.setTileStatusCheckbox(this.tiles, 'VorhandeneVerbindlichkeiten', state);
  }

  getIndex(e: string, isIndexClick?: boolean) {
    if (isIndexClick) { this.labelEdit = false };
    this.selectedTile = e;
    this.tilePosition = this.screen.calculateNewPos(this.fakeTiles, e, this.tileSlideFactor, this.tilePosition, this.basePosition);
  };
}
