<lib-header-text
    class="da-title"
    label="{{'ADDITIONAL_INCOME_PARTNER.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>
<div class="da-tiles-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
     <!-- NEBENJOB -->
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="tiles?.[0].state"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'ADDITIONAL_INCOME_PARTNER.TILE_TEXT1' | translate}}"
            >
            </lib-tile-text>
            <lib-toggle
                #nebentaetigkeit
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="job"
                value1="NebentaetigkeitBesteht"
                value2="KeineNebentaetigkeit"
                [localValue]="contentService.getValue('nebentaetigkeitPartner')"
                (switchValue)="setSwitch($event, nebentaetigkeit)"
                [ngStyle]="{'margin-top':screen.isSmallest() ? '0' : '26px' }"
            ></lib-toggle>
            <ng-container *ngIf="contentService.getValue('nebentaetigkeitPartner') === 'NebentaetigkeitBesteht'">
                <lib-tile-text
                    size="20px"
                    [convertMobile]="true"
                    label="{{'ADDITIONAL_INCOME_PARTNER.TILE_TEXT2' | translate}}"
                ></lib-tile-text>
                <lib-input
                    #nebentaetigkeitGeld
                    #inputs
                    label="{{'COMMON.LABELS.FEE' | translate}}"
                    name="nebentaetigkeitGeld"
                    [error]="false"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="500,00"
                    unit="€"
                    [inputDisabled]="inputDisabled"
                    (click)="testValid = true"
                    [touched]="!!contentService.getValue('einkuenfteAusNebentaetigkeitPartner')"
                    [value]="!!contentService.getValue('einkuenfteAusNebentaetigkeitPartner') ? contentService.getValue('einkuenfteAusNebentaetigkeitPartner') : ''"
                    (change)="validateInput($event, nebentaetigkeitGeld, 'Nebenjob')"
                ></lib-input>
                <lib-tile-text
                    size="20px"
                    [convertMobile]="true"
                    label="{{'ADDITIONAL_INCOME_PARTNER.TILE_TEXT3' | translate}}"
                ></lib-tile-text>
                <lib-input-date
                    class="less-margin-bottom"
                    #nebentaetigkeitDate
                    #inputs
                    [maxDate]="today"
                    [minDate]="beforeDate"
                    label="{{'COMMON.LABELS.DATE' | translate}}"
                    name="nebentaetigkeitDate"
                    [value]="!!contentService.getValue('nebentaetigkeitDatePartner') ? contentService.getValue('nebentaetigkeitDatePartner') : ''"
                    [touched]="!!contentService.getValue('nebentaetigkeitDatePartner')"
                    [error]="false"
                    [inputDisabled]="inputDisabled"
                    (click)="testValid = true"
                    errorText="{{'COMMON.ERRORS.DATE' | translate}}"
                    placeholder="tt.mm.jjjj"
                    (dateValue)="validateDate($event, nebentaetigkeitDate)"
                ></lib-input-date>
            </ng-container>
        </div>
    </div>
    <!-- RENTE -->
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="tiles?.[1].state"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                [convertMobile]="true"
                label="{{'ADDITIONAL_INCOME_PARTNER.TILE_TEXT4' | translate}}"
            >
            </lib-tile-text>
            <lib-toggle
                #rente
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="rente"
                value1="RenteBesteht"
                value2="KeineRente"
                [localValue]="contentService.getValue('rentePartner')"
                (switchValue)="setSwitch($event, rente)"
            ></lib-toggle>
            <ng-container *ngIf="contentService.getValue('rentePartner') === 'RenteBesteht'">
                <lib-tile-text
                    size="20px"
                    [convertMobile]="true"
                    label="{{'ADDITIONAL_INCOME_PARTNER.TILE_TEXT5' | translate}}"
                ></lib-tile-text>
                <lib-input
                    #renteGeld
                    #inputs
                    label="{{'COMMON.LABELS.FEE' | translate}}"
                    name="renteGeld"
                    [error]="false"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="500,00"
                    unit="€"
                    [inputDisabled]="inputDisabled"
                    (click)="testValid = true"
                    [touched]="!!contentService.getValue('summeUnbefristeteZusatzrentenMonatlichPartner')"
                    [value]="!!contentService.getValue('summeUnbefristeteZusatzrentenMonatlichPartner') ? contentService.getValue('summeUnbefristeteZusatzrentenMonatlichPartner') : ''"
                    (change)="validateInput($event, renteGeld, 'Rente')"
                ></lib-input>
            </ng-container>
        </div>
    </div>
    <!-- PROVISION -->
    <div class="da-container" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-state-icon [state]="tiles?.[2].state"></lib-state-icon>
        <div class="da-tile-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
            <lib-tile-text
                size="20px"
                label="{{'ADDITIONAL_INCOME_PARTNER.TILE_TEXT6' | translate}}"
            ></lib-tile-text>
            <lib-toggle
                #provision
                label1="{{'COMMON.TRUE' | translate}}"
                label2="{{'COMMON.FALSE' | translate}}"
                name="provision"
                value1="ProvisionBesteht"
                value2="KeineProvision"
                [localValue]="contentService.getValue('provisionPartner')"
                (switchValue)="setSwitch($event, provision)"
            ></lib-toggle>
            <ng-container *ngIf="contentService.getValue('provisionPartner') === 'ProvisionBesteht'">
                <lib-tile-text
                    size="20px"
                    [convertMobile]="true"
                    label="{{'ADDITIONAL_INCOME.TILE_TEXT7' | translate}}"
                ></lib-tile-text>
                <lib-input
                    #provisionGeld
                    #inputs
                    label="{{'COMMON.LABELS.FEE' | translate}}"
                    name="provisionGeld"
                    [error]="false"
                    errorText="{{'COMMON.ERRORS.FEE' | translate}}"
                    placeholder="500,00"
                    unit="€"
                    [inputDisabled]="inputDisabled"
                    (click)="testValid = true"
                    [touched]="!!contentService.getValue('summeSonstigeEinnahmenMonatlichPartner')"
                    [value]="!!contentService.getValue('summeSonstigeEinnahmenMonatlichPartner') ? contentService.getValue('summeSonstigeEinnahmenMonatlichPartner') : ''"
                    (change)="validateInput($event, provisionGeld, 'Provision')"
                ></lib-input>
            </ng-container>
        </div>
    </div>
</div>
<div class="da-buttons-wrapper">
    <div class="da-buttons-group" [ngStyle]="{'padding-top': showState ? '0' : ''}">
        <div class="da-button-states" *ngIf="showState">
            <lib-state-icon [state]="allState"></lib-state-icon>
            <lib-tile-text
                label="{{allStateText | translate}}"
                size="16px"
                [convertMobile]="true"
            ></lib-tile-text>
        </div>
        <app-nav-buttons
            [save]="true"
            [nextValidate]="testValid"
            [nextDisabled]="daNextSlideService.getDisabled()"
            [current]="slideName"
            [next]="next"
            (validate)="runValidation($event)"
            (slideTo)="contentService.setNav($event, slideName, slideTo)"
            >
        </app-nav-buttons>
    </div>
</div>

