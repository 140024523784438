import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DaClickpathQuery } from 'src/app/state/click-path/da-clickpath.query';
import { DataQuery } from 'src/app/state/data/data.query';

@Component({
  selector: 'app-container-single',
  templateUrl: './container-single.component.html',
  styleUrls: ['./container-single.component.scss']
})
export class ContainerSingleComponent implements OnInit {

  @Input() ergebnis: any;
  @Output() public switchMeta: EventEmitter<any> = new EventEmitter<any>();
  @Output() public express: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public contentService: ContentService,
    public screen: LayoutService,
    private dataQuery: DataQuery,
    private daClickpathQuery: DaClickpathQuery
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit(): void {
    console.log(this.ergebnis)
  }

  setSwitchMeta(type: string) {
    if(type === 'reserved') {
      this.ergebnis = {... this.ergebnis, isReserved: !this.ergebnis.isReserved}
    } else if (type === 'visible'){
      this.ergebnis = {... this.ergebnis, isVisible: !this.ergebnis.isVisible}
    }
  }

  getValue(e: any) {

  }

}
