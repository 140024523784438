import { Injectable } from '@angular/core';
import { DaClickpathService } from '../state/click-path/da-clickpath.service';
import { DataService } from '../state/data/data.service';
import { DaNextSlideService } from '../state/next-slide/da-next-slide.service';
import { UserService } from '../state/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginLightService {


  constructor(
    private dataService: DataService,
    private userService: UserService,
    private daNextSlideService: DaNextSlideService,
    private daClickpathService: DaClickpathService,
  ) { }
  resetStores() {
    this.daClickpathService.remove(1);
    this.dataService.remove(1);
    this.daNextSlideService.remove(1);
    this.userService.remove(1);
  }

}
