import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';

@Component({
  selector: 'app-eigenkapital-sidebar',
  templateUrl: './eigenkapital-sidebar.component.html',
  styleUrls: ['./eigenkapital-sidebar.component.scss']
})
export class EigenkapitalSidebarComponent implements AfterViewInit {
  inputValid: boolean = true;
  errorImmobilienbetrag = 'COMMON.ERRORS.FEE';
  errorSparen = 'COMMON.ERRORS.FEE';
  errorWertpapiere = 'COMMON.ERRORS.FEE';
  errorBausparen: string = 'COMMON.ERRORS.FEE';
  errorVersicherung: string = 'COMMON.ERRORS.FEE';
  showButtons: boolean = false;

  @Input() modal!: NgbModalRef;
  @Output() public eigenkapitalDone: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('sparguthaben') sparguthaben!: any;
  @ViewChild('sparenbetrag') sparenbetrag!: any;
  @ViewChild('wertpapiere') wertpapiere!: any;
  @ViewChild('wertpapierebetrag') wertpapierebetrag!: any;
  @ViewChildren('bausparenAufloesen') bausparenAufloesenList!: QueryList<any>;
  @ViewChildren('bausparenbetrag') bausparenbetragList!: QueryList<any>;
  @ViewChildren('bausparenEinsetzen') bausparenEinsetzenList!: QueryList<any>;
  @ViewChildren('bausparenAbtreten') bausparenAbtretenList!: QueryList<any>;
  @ViewChildren('bausparenbetragPassiv') bausparenbetragPassivList!: QueryList<any>;
  @ViewChildren('versicherungAufloesen') versicherungAufloesenList!: QueryList<any>;
  @ViewChildren('versicherungbetrag') versicherungbetragList!: QueryList<any>;
  @ViewChildren('versicherungEinsetzen') versicherungEinsetzenList!: QueryList<any>;
  @ViewChildren('versicherungAbtreten') versicherungAbtretenList!: QueryList<any>;
  @ViewChildren('versicherungbetragPassiv') versicherungbetragPassivList!: QueryList<any>;
  @ViewChildren('immobilieVerkaufen') immobilieVerkaufenList!: QueryList<any>;
  @ViewChildren('verkaufserloes') verkaufserloesList!: QueryList<any>;
  @ViewChildren('immobiliebetragPassiv') immobiliebetragPassivList!: QueryList<any>;
  @ViewChildren('immobiliebetragAktiv') immobiliebetragAktivList!: QueryList<any>;

  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    private daService: DataService,
    private dataQuery: DataQuery,
  ) { }

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngAfterViewInit(): void {
    this.getGesamtbetrag('passiv');
    this.getGesamtbetrag('aktiv');
    setTimeout(() => {this.showButtons = true}, 1100);
  }

  validateInput(e: any, name: string, i: number = 0, parent: string = '') {
    setTimeout(() => {
      let value = e.target ? e.target.value : e;
      value = typeof value === 'string' ? value : value[0];

      switch (name) {
        case 'sparenbetrag': {
          this.validateNumbers(value, this.sparenbetrag, 'sparen', i);
          break;
        }
        case 'wertpapierebetrag': {
          this.validateNumbers(value, this.wertpapierebetrag, 'wertpapiere', i);
          break;
        }
        case 'bausparenAufloesen': {
          this.updateBausparen(i + 1, 'bausparenAufloesen', value);
          break;
        }
        case 'bausparenAbtreten': {
          this.updateBausparen(i + 1, 'bausparenAbtreten', value);
          break;
        }
        case 'versicherungAufloesen': {
          this.updateVersicherung(i + 1, 'versicherungAufloesen', value);
          break;
        }
        case 'versicherungAbtreten': {
          this.updateVersicherung(i + 1, 'versicherungAbtreten', value);
          break;
        }
        case 'bausparenEinsetzen': {
          let element: any;
          this.bausparenEinsetzenList.toArray().map((item: any) => {
            let number = item.name.slice(item.name.length - 1);
            number = parseInt(number);
            if (number === i) {
              element = item;
            }
          });

          this.validateNumbers(value, element, 'bausparen', i);
          break;
        }
        case 'versicherungEinsetzen': {
          let element: any;
          this.versicherungEinsetzenList.toArray().map((item: any) => {
            let number = item.name.slice(item.name.length - 1);
            number = parseInt(number);
            if (number === i) {
              element = item;
            }
          });

          this.validateNumbers(value, element, 'versicherung', i);
          break;
        }
        case 'erloesVerwenden': {
          this.updateImmobilien(i + 1, 'erloesVerwenden', value);
          break;
        }
        case 'immobiliebetragAktiv': {
          let element: any;
          this.immobiliebetragAktivList.toArray().map((item: any) => {
            let number = item.name.slice(item.name.length - 1);
            number = parseInt(number);
            if (number === i) {
              element = item;
            }
          });

          this.validateNumbers(value, element, 'immobilien', i);
          break;
        }
        case 'zusatzsicherheit': {
          this.updateImmobilien(i + 1, 'zusatzsicherheit', value);
          break;
        }
        case 'immobilieVerkaufen': {
          this.updateImmobilien(i + 1, 'change', value);
          break;
        }
      }
    }, 500)
  }

  validateNumbers(e: string, element: any, type: string, i: number) {

    setTimeout(() => {
      element.touched = false;
      let isValidAmount: any = true;
      let isValid = this.contentService.validatePattern(e, element.validationType);

      if (isValid) {
        if (element.name.includes('immobiliebetragAktiv')) {
          const realValue = this.contentService.convertStringNumber(this.setLocalValue(i, 'realValue', 'bestehendeImmobilien', 'immobiliebetrag'));
          isValidAmount = this.contentService.minMax(e, 0, realValue);
          this.errorImmobilienbetrag = isValidAmount?.tooHigh ? 'COMMON.ERRORS.BETRAG_EINSETZEN' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.FEE';
        }

        if (element.name.includes('bausparen')) {
          const baseValue = this.contentService.convertStringNumber(this.setLocalValue(i, 'angesparterBetrag', 'bausparvertraege', 'bausparenbetrag'));
          isValidAmount = this.contentService.minMax(e, 0, baseValue);
          this.errorBausparen = isValidAmount?.tooHigh ? 'COMMON.ERRORS.BAUSPAREN_EINSETZEN' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.FEE';
        }

        if (element.name.includes('versicherung')) {
          const baseValue = this.contentService.convertStringNumber(this.setLocalValue(i, 'rueckkaufswert', 'versicherungsvertraege', 'versicherungbetrag'));
          isValidAmount = this.contentService.minMax(e, 0, baseValue);
          this.errorVersicherung = isValidAmount?.tooHigh ? 'COMMON.ERRORS.VERSICHERUNG_EINSETZEN' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.FEE';
        }

        if (element.name === 'sparenbetrag') {
          const familieSparenTotal = this.contentService.getValue('familienvermoegenBetrag');
          const sparenTotal = !!familieSparenTotal ? this.contentService.convertStringNumber(this.getSparen()) : this.contentService.convertStringNumber(this.dataQuery.getEntity(1)?.['sparguthabenBetrag']);
          isValidAmount = this.contentService.minMax(e, 0, sparenTotal);
          this.errorSparen = isValidAmount?.tooHigh ? 'COMMON.ERRORS.SPAREN_EINSETZEN' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
        }

        if (element.name === 'wertpapierebetrag') {
          const wertpapiereTotal = this.contentService.convertStringNumber(this.dataQuery.getEntity(1)?.['wertpapiereBetrag']);
          isValidAmount = this.contentService.minMax(e, 0, wertpapiereTotal);
          this.errorWertpapiere = isValidAmount?.tooHigh ? 'COMMON.ERRORS.WERTPAPIERE_EINSETZEN' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
        }
      }

      element.error = isValid && isValidAmount === true ? false : true;
      if (!element.error) {
        switch (type) {
          case 'sparen': {
            this.daService.update(1, { sparenMaximalEinzusetzenderBetrag: e });
            this.getGesamtbetrag('aktiv');
            break;
          }
          case 'wertpapiere': {
            this.daService.update(1, { wertpapiereMaximalEinzusetzenderBetrag: e });
            this.getGesamtbetrag('aktiv');
            break;
          }
          case 'bausparen': {
            this.updateBausparen(i + 1, 'maximalEinzusetzenderBetrag', e);
            break;
          }
          case 'versicherung': {
            this.updateVersicherung(i + 1, 'maximalEinzusetzenderBetrag', e);
            break;
          }
          case 'immobilien': {
            this.updateImmobilien(i + 1, 'betragEinsetzen', e);
            break;
          }
        }
      }
      element.touched = true;
      this.setDisabled();
    }, 300);
  }

  updateVersicherung(i: number, field: string, value: string) {
    let vertraege: any = [];
    const status = this.dataQuery.getEntity(1)?.['versicherungsvertraege'];
    if (status) {
      status.map((vertrag: any) => {
        if (vertrag.id === i) {
          if (field === 'versicherungAufloesen') {
            vertrag = { ...vertrag, 'verwendung': value === 'true' ? 'AUFLOESUNG_ALS_VERWENDUNG' : 'ABTRETEN_ALS_VERWENDUNG' };
          } else if (field === 'versicherungAbtreten') {
            vertrag = { ...vertrag, 'verwendung': value === 'true' ? 'ABTRETEN_ALS_VERWENDUNG' : 'KEINE_VERWENDUNG'};
          } else {
            vertrag = { ...vertrag, 'maximalEinzusetzenderBetrag': value };
          }
        }
        vertraege.push(vertrag);
      });
      this.daService.update(1, { versicherungsvertraege: vertraege });
      this.getGesamtbetrag('aktiv');
      this.getGesamtbetrag('passiv');
    }
  }

  updateBausparen(i: number, field: string, value: string) {
    let vertraege: any = [];
    const status = this.dataQuery.getEntity(1)?.['bausparvertraege'];
    if (status) {
      status.map((vertrag: any) => {
        if (vertrag.id === i) {
          if (field === 'bausparenAufloesen') {
            vertrag = { ...vertrag, 'verwendung': value === 'true' ? 'AUFLOESUNG_ALS_VERWENDUNG' : 'ABTRETEN_ALS_VERWENDUNG' };
          } else if (field === 'bausparenAbtreten') {
            vertrag = { ...vertrag, 'verwendung': value === 'true' ? 'ABTRETEN_ALS_VERWENDUNG' : 'KEINE_VERWENDUNG'};
            vertrag = { ...vertrag, 'maximalEinzusetzenderBetrag': value === 'true' ? vertrag.angesparterBetrag : ''};
          } else {
            vertrag = { ...vertrag, 'maximalEinzusetzenderBetrag': value };
          }
        }
        vertraege.push(vertrag);
      });
      this.daService.update(1, { bausparvertraege: vertraege });
      this.getGesamtbetrag('aktiv');
      this.getGesamtbetrag('passiv');
    }
  }

  updateImmobilien(i: number, field: string, value: string) {
    let immobilien: any[] = [];
    const status = this.dataQuery.getEntity(1)?.['bestehendeImmobilien'];
    if (status) {
      status.map((immobilie: any) => {
        if (immobilie.id === i) {
          switch (field) {
            case 'change': {
              if (value === 'true') {
                immobilie = { ...immobilie, 'change': 'VERKAUFEN' };
              } else {
                immobilie = { ...immobilie, 'change': 'KEIN_EINSATZ' };
              }

              break;
            }
            case 'erloesVerwenden': {
              immobilie = { ...immobilie, 'erloesVerwenden': value };
              break;
            }
            case 'betragEinsetzen': {
              immobilie = { ...immobilie, 'betragEinsetzen': value };
              break;
            }
            case 'zusatzsicherheit': {
              immobilie = { ...immobilie, 'zusatzsicherheit': value };
              break;
            }
          }
        }
        immobilien.push(immobilie);
      });
      this.daService.update(1, { bestehendeImmobilien: immobilien });
      this.getGesamtbetrag('aktiv');
      this.getGesamtbetrag('passiv');
    }
  }

  setLocalValue(num: number, field: string, type: string, input: string) {
    let parsedValue = this.contentService.getNestedValue(type, field, num + 1) ?? '';
    let returnValue = '';
    switch (type) {
      case 'bausparvertraege': {
        if (input === 'aufloesen') {
          if (parsedValue === 'AUFLOESUNG_ALS_VERWENDUNG') {
            returnValue = 'true';
          } else {
            returnValue = 'false';
          }
        }

        if (input === 'abtreten') {
          if (parsedValue === 'ABTRETEN_ALS_VERWENDUNG') {
            returnValue = 'true';
          } else {
            returnValue = 'false';
          }
        }

        if (input === 'bausparenbetrag' || input === 'bausparenEinsetzen') {
          returnValue = parsedValue;
        }
        break;
      }
      case 'versicherungsvertraege': {
        if (input === 'aufloesen') {
          if (parsedValue === 'AUFLOESUNG_ALS_VERWENDUNG') {
            returnValue = 'true';
          } else {
            returnValue = 'false';
          }
        }

        if (input === 'abtreten') {
          if (parsedValue === 'ABTRETEN_ALS_VERWENDUNG') {
            returnValue = 'true';
          } else {
            returnValue = 'false';
          }
        }

        if (input === 'versicherungbetrag' || input === 'versicherungEinsetzen') {
          returnValue = parsedValue;
        }
        break;
      }
      case 'bestehendeImmobilien': {
        if (input === 'verkaufen') {
          if (parsedValue === 'VERKAUFEN') {
            returnValue = 'true';
          } else {
            returnValue = 'false';
          }
        }

        if (input === 'immobiliebetrag' || input === 'erloesVerwenden' || input === 'zusatzsicherheit') {
          returnValue = parsedValue;
        }
        break;
      }
    }
    return returnValue;
  }

  getLabel(i: number, field: string, parent: string, type: string) {
    const state = this.setLocalValue(i, field, parent, type);

    if (state === 'true') {
      return 'EIGENKAPITAL.LABELS.LABEL13';
    } else {
      return 'EIGENKAPITAL.LABELS.LABEL11';
    }
  }

  getSparen() {
    const sparenBetrag = this.contentService.getValue('sparguthabenBetrag').replaceAll('.', '').replaceAll(',', '.');
    const familienSparenbetrag = this.contentService.getValue('familienvermoegenBetrag').replaceAll('.', '').replaceAll(',', '.');
    let sparenBetragTotal = 0.00;
    if (!!sparenBetrag) {
      if (!!familienSparenbetrag && (this.contentService.getValue('familienSparvermoegen') && this.contentService.getValue('familienSparvermoegen') === 'familienSparvermoegenVorhanden')) {
        sparenBetragTotal = parseFloat(sparenBetrag) + parseFloat(familienSparenbetrag);
      } else {
        sparenBetragTotal = parseFloat(sparenBetrag);
      }
    }

    return sparenBetragTotal?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  }

  getImmobilienbetragAktiv(num: number) {
    const einsetzen = this.contentService.getNestedValue('bestehendeImmobilien', 'erloesVerwenden', num + 1);
    let betrag = '0,00';
    if (!!einsetzen && einsetzen === 'true') {
      if (!!this.contentService.getNestedValue('bestehendeImmobilien', 'betragEinsetzen', num + 1)) {
        betrag = this.contentService.getNestedValue('bestehendeImmobilien', 'betragEinsetzen', num + 1);
      } else {
        betrag = this.contentService.getNestedValue('bestehendeImmobilien', 'realValue', num + 1);
      }

    }
    return betrag
  }

  getImmobilienbetragPassiv(num: number) {
    const einsetzen = this.contentService.getNestedValue('bestehendeImmobilien', 'zusatzsicherheit', num + 1);
    let betrag = '0,00';
    if (!!einsetzen && einsetzen === 'ja') {
      betrag = this.contentService.getNestedValue('bestehendeImmobilien', 'realValue', num + 1);
    }
    return betrag
  }

  getGesamtbetrag(type: string) {
    setTimeout(() => {
      let gesamt: number = 0;
      if(type === 'passiv') {
        if(this.bausparenbetragPassivList){
          this.bausparenbetragPassivList.toArray().map((item: any) => {
            gesamt = gesamt + this.contentService.convertStringNumber(item.value, 2);
          });
        }
        if(this.versicherungbetragPassivList) {
          this.versicherungbetragPassivList.toArray().map((item: any) => {
            gesamt = gesamt + this.contentService.convertStringNumber(item.value, 2);
          });
        }
        if(this.immobiliebetragPassivList) {
          this.immobiliebetragPassivList.toArray().map((item: any) => {
            gesamt = gesamt + this.contentService.convertStringNumber(item.value, 2);
          });
        }    
        this.daService.update(1, { eigenkapitalPassiv: gesamt.toLocaleString('de-DE', {maximumFractionDigits: 2, minimumFractionDigits: 2}) });
      } else {
        if(this.bausparenEinsetzenList) {
          this.bausparenEinsetzenList.toArray().map((item: any) => {
            gesamt = gesamt + this.contentService.convertStringNumber(item.value, 2);
          });
        }
        if(this.versicherungEinsetzenList) {
          this.versicherungEinsetzenList.toArray().map((item: any) => {
            gesamt = gesamt + this.contentService.convertStringNumber(item.value, 2);
          });
        }
        if(this.immobiliebetragAktivList) {
          this.immobiliebetragAktivList.toArray().map((item: any) => {
            gesamt = gesamt + this.contentService.convertStringNumber(item.value, 2);
          });
        }
        
        gesamt = this.sparenbetrag ? gesamt + this.contentService.convertStringNumber(this.sparenbetrag.value, 2) : gesamt;
        gesamt = this.wertpapierebetrag ? gesamt + this.contentService.convertStringNumber(this.wertpapierebetrag.value, 2) : gesamt;
        this.daService.update(1, { eigenkapitalAktiv: gesamt.toLocaleString('de-DE', {maximumFractionDigits: 2, minimumFractionDigits: 2}) });
      }
      this.setDisabled();
    }, 100);
  }

  setDisabled() {
    this.inputValid = true;
    let eigenkapitalInValid = false;
    if(this.bausparenEinsetzenList) {
      this.bausparenEinsetzenList.toArray().map((item: any) => {
        if(item.error) {this.inputValid = false; eigenkapitalInValid = true;}
      });
    }
    if (this.versicherungEinsetzenList) {
      this.versicherungEinsetzenList.toArray().map((item: any) => {
        if(item.error) {this.inputValid = false; eigenkapitalInValid = true;}
      });
    }
    if(this.immobiliebetragAktivList) {
      this.immobiliebetragAktivList.toArray().map((item: any) => {
        if(item.error) {this.inputValid = false; eigenkapitalInValid = true;}
      });
    }
    if(this.immobilieVerkaufenList) {
      this.immobilieVerkaufenList.toArray().map((item: any) => {
        let idVerkaufen = item.name.slice(item.name.length - 1);
        if(item.localValue === 'true') {
          this.verkaufserloesList.toArray().map((subItem: any) => {
            let idErloes = subItem.name.slice(subItem.name.length - 1);
            if(idVerkaufen === idErloes) {
              if(!subItem.localValue) {this.inputValid = false}
            }
          });
        }
      });
    }
    if(this.sparenbetrag && this.sparenbetrag.error) {this.inputValid = false; eigenkapitalInValid = true;}
    if(this.wertpapierebetrag && this.wertpapierebetrag.error) {this.inputValid = false; eigenkapitalInValid = true;}
    this.daService.update(1, { eigenkapitalInValid: eigenkapitalInValid });
  }

  getAddress(i: number) {
    let address = this.contentService.getNestedObject('bestehendeImmobilien', 'adresse', i);
    const addressHtml = `<span>${address.strasse} ${address.hausnummer},</span><br><span>${address.plz} ${address.ort}</span>`;
    return addressHtml
  }

  finishInput() {
    this.eigenkapitalDone.emit();
    this.dismiss();
  }

  dismiss() {
    document.body.style.overflowY = 'auto';
    this.modal.dismiss();
  }

}
