import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { DaNextSlideService } from 'src/app/state/next-slide/da-next-slide.service';

@Component({
  selector: 'app-market-value',
  templateUrl: './market-value.component.html',
  styleUrls: ['./market-value.component.scss']
})
export class MarketValueComponent implements OnInit {
  public slideName: string = 'market-value';
  next: string = 'info-success-70';
  disabled: boolean = true;
  testValid: boolean = false;
  inputDisabled: boolean = false;
  wertError: string = 'COMMON.ERRORS.NUMBER';

  containerState: string = 'warning';

  showState: boolean = false;
  allState: string = 'warning';
  allStateText: string = 'PROGRESS.STATES.BUTTONPANEL.INCOMPLETE';

  @Output() public slideTo: EventEmitter<any> = new EventEmitter<string>();
  @Output() public stateChange: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild('objectMarktwert') objectMarktwert!: any;

  constructor(
    public daNextSlideService: DaNextSlideService,
    private daService: DataService,
    private dataQuery: DataQuery,
    public contentService: ContentService,
    public screen: LayoutService
  ) {}

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit() {
    this.daService.setCurrentSlide(this.slideName);
    setTimeout(() => {
      this.setContainerState();
    });
  }

  runValidation(e: string) {
    setTimeout(() => {
      if (e === 'next') {
        if (this.allState !== 'success') {
          this.showState = true;
          this.setContainerState();
        } else {
          this.setContainerState('test');
        }
      } else {
        this.contentService.setNav(e, this.slideName, this.slideTo);
      }
    }, 50)
  }

  setAllState(icon: string, text: string) {
    this.allState = icon;
    this.allStateText = `PROGRESS.STATES.BUTTONPANEL.${text}`;
  }

  validateInputValue(e: any, element: any) {
    setTimeout(() => {
      let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
      element.touched = !!value ? true : false;
      element.placeholder = '';
      const isValid = this.contentService.validatePattern(value, element.validationType);
      const isValidAmount: any = this.contentService.minMax(value, 25000, 9000000);
      this.wertError = isValidAmount?.tooHigh ? 'COMMON.ERRORS.TOO_HIGH' : isValidAmount?.tooLow ? 'COMMON.ERRORS.TOO_LOW' : 'COMMON.ERRORS.NUMBER';
      element.error = isValid && isValidAmount === true ? false : true;

      if (!element.error) {
        this.daService.update(1, { objectMarkwert: value });
      } else {
        this.contentService.setSlideIndexState(this.slideName, false, 'error');
        this.setAllState('error', 'ERROR');
        this.stateChange.emit(true);
      }

      setTimeout(() => {this.setContainerState();});
    }, 50);
  }

  setContainerState(type: string = 'default') {
    this.disabled = true;
    let isNotValid = true;
    const status = this.dataQuery.getEntity(1);
    let stateText = 'INCOMPLETE';

    this.containerState = !!status?.['objectMarkwert'] ? 'success' : 'warning';
    this.containerState = this.objectMarktwert.error ? 'error' : this.containerState;
    stateText = !!status?.['objectMarkwert'] ? 'COMPLETE' : 'INCOMPLETE';
    stateText = this.objectMarktwert.error ? 'ERROR' : stateText;
    isNotValid = !!status?.['objectMarkwert'] && !this.objectMarktwert.error ? false : true;
    this.contentService.setSlideIndexState(this.slideName, false, this.containerState);
    this.setAllState(this.containerState, stateText);
    this.stateChange.emit(true);

    if (!isNotValid) {
      this.disabled = false;
      if (type === 'test') {
        this.inputDisabled = true;
        this.testValid = false;
      }
    } else {
      this.disabled = true;
      this.testValid = true;
    }

    if(this.testValid) {
      this.daNextSlideService.update(1, { disabled: false });
    } else {
      this.daNextSlideService.update(1, { disabled: this.disabled });
    }

  }
}
