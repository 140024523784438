<div class="da-header" [ngClass]="{'mobile' : screen.isSmallest()}">
    <app-circle-button 
        name="schliessen button"
        class="da-circle-button left"
        orientation="top-left" 
        svg="assets/images/xmark.svg"
        type="close"
        [background]="background"
        (buttonValue)="getButtonValue($event)"
        (click)="onSave(false, true)"
    ></app-circle-button>
    <div class="da-header-wrapper" [ngClass]="{'mobile' : screen.isSmallest()}">
        <div class="da-header-buttons left">
            <lib-header-button
                type="de"
                value="DE"
                [active]="isDE"
                [inactive]="!isDE"
                (buttonValue)="getLanguage($event)"
            ></lib-header-button>
            |
            <lib-header-button
                type="en"
                value="EN"
                [active]="!isDE"
                [inactive]="isDE"
                (buttonValue)="getLanguage($event)"
            ></lib-header-button>
        </div>
        <div class="da-header-logo" *ngIf="!screen.isSmallest()">
            <img *ngIf="logo && logo.length > 0" src="{{logo}}" />
            <div class="da-header-tagline">{{ name }} <sup *ngIf="((logo && logo.length > 0) || (name && name.length > 0)) && wortmarke">®</sup></div>
        </div>
        <div class="da-header-buttons">
            <lib-header-button
                *ngIf="environment.buildEnv === 'local' || environment.buildEnv === 'development'"
                type="request"
                icon="assets/images/paperPlane.svg"
                (click)="processRequest()"
            ></lib-header-button>
            <lib-header-button
                type="safety"
                icon="assets/images/userShield.svg"
                (click)="screen.openInfo(safetyInfo)"
            ></lib-header-button>
            |
            <lib-header-button
                *ngIf="userService.isLoggedInForLoginLight() || userHasAccess"
                type="login"
                value="{{ 'BUTTONS.SAVE_HEADER' | translate }}"
                [disabled]="!userCanSaveProgress"
                (click)="onSave()"
            ></lib-header-button>
            <lib-header-button
                *ngIf="!userService.isLoggedInForLoginLight() && !userHasAccess"
                type="login"
                value="{{ 'BUTTONS.REGISTER_HEADER' | translate }}"
                [disabled]="!userCanSaveProgress"
                (click)="onSave(true)"
            ></lib-header-button>
        </div>
    </div>
    <div class="da-header-logo mobile" *ngIf="screen.isSmallest()">
        <img *ngIf="logo && logo.length > 0" src="{{logo}}" />
        <div class="da-header-tagline">{{ name }} <sup *ngIf="((logo && logo.length > 0) || (name && name.length > 0)) && wortmarke">®</sup></div>
    </div>
    <app-circle-button 
        name="hilfe button"
        class="da-circle-button right"
        orientation="top-right" 
        svg="assets/images/userHeadset.svg"
        type="contact"
        [background]="background"
        (click)="screen.openInfo(contact)"
    ></app-circle-button>
    <app-progression 
        [daType]="daType" 
        [progressItems]="stateChange"
        [accessChange]="accessChange"
        [currentSlide]="currentSlide" 
        (progressValue)="progressValue.emit($event)" 
        [ngClass]="{'mobile' : screen.isSmallest()}">
    </app-progression>
</div>
<app-progress-bar [currentSlide]="currentSlide"></app-progress-bar>

<ng-template #loginLightSidebar let-modal>
    <app-login-light-sidebar [hasAccess]="accessChange"
                             [showLoginLightForm]="showLoginLightForm"
                             [showLoginLightExit]="showLoginLightExit"
                             [updateLoginLightProgress]="updateLoginLightProgress"
                             [modal]="modal">
    </app-login-light-sidebar>
</ng-template>
<ng-template #safetyInfo let-modal>
    <app-info-sidebar 
    [modal]="modal" 
    category="{{'HEADER.SECURITY.HEADER' | translate}}" 
    text="sicherheit"
    [info]="false"
    icon="assets/images/userShield.svg"
    ></app-info-sidebar>
</ng-template>
<ng-template #contact let-modal>
    <app-contact 
    [modal]="modal" 
    ></app-contact>
</ng-template>