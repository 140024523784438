import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ConfigurationStore, ConfigurationState } from './configuration.store';

@Injectable({ providedIn: 'root' })
export class ConfigurationQuery extends Query<ConfigurationState> {

  configuration = this.select('configuration');
  tenantuuid = this.select('tenantuuid');

  constructor(
    protected override store: ConfigurationStore
  ) {
    super(store);

  }
}
