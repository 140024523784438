<div class="da-bausteine-tile" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'isSmallest' : screen.isSmallest() }">
    <lib-header-text
        label="{{'FINANCE_ASSEMBLY.BAUSTEINE.JAHRE.TITLE' | translate}}"
        info="true"
        (infoValue)="screen.openInfo(jahreInfo)"
        size="20px"
        [convertMobile]="false"
    ></lib-header-text>
    <lib-toggle-vertical
        #fixedJ
        [options]="switches"
        [localValue]="contentService.getNestedObject('darlehenBausteine', 'tilgungsfreieAnlaufjahre', num)"
        name="fixedJ{{num}}"
        (switchValue)="setSwitch($event)"
    ></lib-toggle-vertical>
</div>

<ng-template #jahreInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_ASSEMBLY.BAUSTEINE.JAHRE.INFO_HEADER' | translate}}" text="{{'FINANCE_ASSEMBLY.BAUSTEINE.JAHRE.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
