import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnChanges, AfterViewInit {

  @Input() tiles: any;
  @Input() selectedTile!: string;
  @Input() slideFactor!: number;
  @Input() slidePos!: string;

  @Input() isNumber: boolean = false;

  tileIndex: number = 0;
  arrowRight!: boolean;
  arrowLeft!: boolean;

  @Output() public indexValue: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public screen: LayoutService
  ) { }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.setArrows();
  }
  
  ngAfterViewInit() {
    setTimeout(() => {this.setArrows();},100);
  }

  getValue(e: string) {
    this.selectedTile = e;
    if(this.isNumber) {
      this.tiles.map((item: any) => {
        if(item.value === e) {
          this.indexValue.emit(item.id);
        }
      })
    } else {
      this.indexValue.emit(e);
    }

    this.setArrows();
  }

  moveOne(type: string) {
    if (!!this.selectedTile && this.tiles) {
      this.tileIndex = this.screen.getTileIndex(this.tiles, this.selectedTile) as unknown as number;
    }
    let newIndex = type === 'right' ? this.tileIndex + 1 : this.tileIndex - 1;

    this.tiles.map((tile: { value: any; }) => {
      if (this.tiles.indexOf(tile) === newIndex) {
        this.selectedTile = tile.value;
      }
    });
    this.setArrows();
    if(this.isNumber) {
      this.tiles.map((item: any) => {
        if(item.value === this.selectedTile) {
          this.indexValue.emit(item.id);
        }
      })
    } else {
      this.indexValue.emit(this.selectedTile);
    }
  }

  setArrows() {
    this.tileIndex = this.screen.getTileIndex(this.tiles, this.selectedTile) as unknown as number;
    this.arrowLeft = this.tileIndex <= 0 ? false : true;
    this.arrowRight = this.tileIndex >= this.tiles.length - 1 ? false : true;
    // this.arrowLeft = this.tileIndex <= this.tiles.length / 2 ? false : true;
    // this.arrowRight = this.tileIndex >= (this.tiles.length / 2) + 1 ? false : true;

    if (this.screen.isSmallest()) {
      this.arrowLeft = this.tileIndex <= 0 ? false : true;
      this.arrowRight = this.tileIndex >= this.tiles.length - 1 ? false : true;
    }
  }

}
