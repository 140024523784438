<lib-header-text
    class="da-title"
    label="{{'PARKING.TITLE' | translate}}"
    size="38px"
    [convertMobile]="true"
></lib-header-text>

<lib-tile-text
    class="da-title"
    label="{{'PARKING.SUB_TITLE' | translate}}"
    size="20px"
    [convertMobile]="true"
    [ngStyle]="{'display': 'flex', 'justify-content': 'center'}"
></lib-tile-text>
<div class="da-index">
    <app-index 
        [tiles]="tiles" 
        [selectedTile]="selectedTile"
        [slideFactor]="tileSlideFactor"
        [slidePos]="tilePosition"
        (indexValue)="getIndex($event)">
    </app-index>
</div>
<div class="da-content-extended">
    <div class="da-content-extended-wrapper" [ngClass]="{ 'mobile' : screen.isSmallest() }">
        <lib-tile-checkbox-group
            class="da-tiles-wrapper"
            [tiles]="tiles"
            [withIndex]="true"
            (tileValue)="setValue($event)"
            [ngStyle]="{'right': 'calc('+ screenPosition +' + '+ tilePosition +'px)'}"
            [ngClass]="{ 'mobile' : screen.isSmallest() }"
        >
            <ng-template #contentStellplatz>
                <lib-counter
                    #objectAnzahlStellplatz
                    [value]="contentService.getValue('objectAnzahlStellplatz', true)"
                    name="anzahlStellplatz"
                    (counterValue)="getCounterValue($event, 'STELLPLATZ', true)"
                    [light]="true"
                ></lib-counter>
            </ng-template>
            <ng-template #contentCarport>
                <lib-counter
                    #objectAnzahlStellplatz
                    [value]="contentService.getValue('objectAnzahlCarport', true)"
                    name="anzahlCarport"
                    (counterValue)="getCounterValue($event, 'CARPORT', true)"
                    [light]="true"
                ></lib-counter>
            </ng-template>
            <ng-template #contentGarage>
                <lib-counter
                    #objectAnzahlGarage
                    [value]="contentService.getValue('objectAnzahlGarage', true)"
                    name="anzahlGarage"
                    (counterValue)="getCounterValue($event, 'GARAGE', true)"
                    [light]="true"
                ></lib-counter>
            </ng-template>
            <ng-template #contentDoppelgarage>
                <lib-counter
                    #objectAnzahlDoppelgarage
                    [value]="contentService.getValue('objectAnzahlDoppelgarage', true)"
                    name="anzahlDoppelgarage"
                    (counterValue)="getCounterValue($event, 'DOPPEL_GARAGE', true)"
                    [light]="true"
                ></lib-counter>
            </ng-template>
            <ng-template #contentTiefgarage>
                <lib-counter
                    #objectAnzahlTiefgarage
                    [value]="contentService.getValue('objectAnzahlTiefgarage', true)"
                    name="anzahlTiefgarage"
                    (counterValue)="getCounterValue($event, 'TIEFGARAGEN_STELLPLATZ', true)"
                    [light]="true"
                ></lib-counter>
            </ng-template>
        </lib-tile-checkbox-group>
    </div>
</div>
<div class="da-buttons-wrapper">
    <app-nav-buttons
        class="da-buttons-group"
        [save]="true"
        [nextDisabled]="daNextSlideService.getDisabled()"
        [current]="slideName"
        [next]="next"
        (slideTo)="contentService.setNav($event, slideName, slideTo)"
        >
    </app-nav-buttons>
</div>

