import { Component, Input, Output, EventEmitter, HostListener, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { Debounce } from 'lodash-decorators';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { switchesContent } from 'src/assets/content/switches';

@Component({
  selector: 'app-darlehen-forward',
  templateUrl: './darlehen-forward.component.html',
  styleUrls: ['./darlehen-forward.component.scss']
})
export class DarlehenForwardComponent implements OnInit {
  @Input() count: any;
  @Input() inputDisabled!: boolean;
  @Input() testValid!: boolean;
  @Output() sum: EventEmitter<string> = new EventEmitter();
  @Output() state: EventEmitter<any> = new EventEmitter();
  error: boolean = false;
  isVisible: boolean = true;
  containerState: string = 'warning';
  today: Date = new Date();
  maxDate!: any;
  minDate!: any;
  errorSparen: string = 'COMMON.ERRORS.FEE';
  darlehenItems = switchesContent['DARLEHEN_FORWARD'].items;

  @ViewChild('abloesedate') abloesedate!: any;
  @ViewChild('restschuldBetrag') restschuldBetrag!: any;
  @ViewChild('sparenBetrag') sparenBetrag!: any;
  @ViewChild('tilgungBetrag') tilgungBetrag!: any;
  @ViewChild('darlehenBetrag') darlehenBetrag!: any;

  constructor(
    public screen: LayoutService,
    public contentService: ContentService,
    public dataQuery: DataQuery,
    public dataService: DataService
  ) {
    this.minDate = this.contentService.addDay(new Date(), 31);
    this.maxDate = this.contentService.addYears(new Date(), 5);
  }

  @HostListener('window:resize', ['$event'])
  @Debounce(300)
  onWindowResize() {
    this.screen.updateScreenSizes();
  }

  ngOnInit() {
    setTimeout(() => {
      this.setValid();
    }, 200);
  }

  setLocalValue(num: number, field: string, isDate: boolean = false) {
    let parsedValue: any = this.contentService.getNestedValue('darlehenForward', field, num) ?? '';
    return parsedValue;
  }

  setSwitch(e: string, element: any, overlay?: any) {
    this.inputDisabled = false;
    this.testValid = true;
    this.storeValue(e, element.name, overlay);
    setTimeout(() => {
      if(e === 'abloesenNein' || e === 'keineRestschuld') {
        this.calculateSum(true);
      } else {
        this.calculateSum();
      }

    }, 100)
    setTimeout(() => {
      this.setValid();
    }, 400)
  }

  validateDate(e: any, element: any, isInput?: boolean) {
    let isDone = false;
    element.error = e !== null ? false : true;
    element.touched = false;

    if (isInput) {
      if (e._i.length >= 8 && e._i.substring(e._i.length - 5, e._i.length - 4) === '.') {
        isDone = true;
      } else {
        isDone = false;
      }
    } else {
      isDone = true;
    }
    if (isDone) {
      const inputDate = e !== null ? e.toDate() : null;
      let baseTooEarly = inputDate > this.maxDate;
      let baseTooLate = inputDate < this.minDate;

      element.error = baseTooEarly || baseTooLate ? true : false;
      element.touched = true;
      if (!element.error) {
        this.storeValue(inputDate, element.name);
      }
      setTimeout(() => {
        this.setValid();
      }, 400)
    }

  }

  validateInput(e: any, element: any, element2?: any) {
    this.errorSparen = 'COMMON.ERRORS.FEE';
    let value = e[0] ? e[0] : e.srcElement.value ? e.srcElement.value : '';
    element.touched = value ? true : false;
    let restschuld = this.setLocalValue(this.count, 'restschuldBetrag');
    let sparen = this.setLocalValue(this.count, 'sparenBetrag');
    element.placeholder = '';
    let minAmount = element.name.includes('tilgungBetrag') ? 0 : 5;
    let maxAmount = element.name.includes('sparenBetrag') && !!restschuld ? this.contentService.convertStringNumber(restschuld) : 999999;

    const isValid = this.contentService.validatePattern(value, element.validationType);
    const isValidAmount: any = this.contentService.minMax(value, minAmount, maxAmount);
    element.error = isValid && isValidAmount === true ? false : true;

    if(element.name.includes('restschuldBetrag')) {
      if(!!sparen && element2) {
        const isSparenValid = this.contentService.convertStringNumber(sparen) <= this.contentService.convertStringNumber(value);
        if(!isSparenValid) {
          element2.error = true;
          this.errorSparen = 'COMMON.ERRORS.SPAREN_FORWARD';
        }
      }
    }

    if(element.name.includes('sparenBetrag') && isValidAmount.tooHigh) {
      this.errorSparen = 'COMMON.ERRORS.SPAREN_FORWARD';
    }

    if (!element.error) {
      this.storeValue(value, element.name);
      setTimeout(() => {
        this.calculateSum();
      }, 100)
    }

    setTimeout(() => {
      this.setValid();
    }, 400)
  }

  calculateSum(notAbloesen: boolean = false) {
    let restschuld = !!this.contentService.getNestedValue('darlehenForward', 'restschuldBetrag', this.count) ? this.contentService.getNestedValue('darlehenForward', 'restschuldBetrag', this.count) : '';
    let sparen = !!this.contentService.getNestedValue('darlehenForward', 'sparenBetrag', this.count) ? this.contentService.getNestedValue('darlehenForward', 'sparenBetrag', this.count) : '';
    let tilgung = !!this.contentService.getNestedValue('darlehenForward', 'tilgungBetrag', this.count) ? this.contentService.getNestedValue('darlehenForward', 'tilgungBetrag', this.count) : '';
    let sum = 0;

    if (!!restschuld && !notAbloesen) {
      const startSum = this.contentService.convertStringNumber(restschuld, 2);
      const sparAbzug = !!sparen ? this.contentService.convertStringNumber(sparen, 2) : 0;
      const tilgungAbzug = !!tilgung ? this.contentService.convertStringNumber(tilgung, 2) : 0;

      sum = startSum - sparAbzug - tilgungAbzug;
    }

    setTimeout(() => {
      this.darlehenBetrag.touched = true;
      if(sum >= 0) {
        this.darlehenBetrag.error = false;
      } else {
        this.darlehenBetrag.error = true;
      }
      this.storeValue(this.contentService.convertNumberString(sum), `darlehenBetrag${this.count}`);
      this.sum.emit();
    }, 200)
  }

  storeValue(e: any, name: string, overlay?: any) {
    let value = e.target?.value ? e.target?.value : e;
    let darlehens = [];
    if(name.includes('restschuldVorhanden')) {
      if(value === 'keineRestschuld') {
        this.screen.openInfo(overlay);
      }
    }
    const status = this.dataQuery.getEntity(1)?.['darlehenForward'];
    if (!status) {
      darlehens.push({
        'id': this.count,
        'darlehenType': name.includes('darlehenType') ? value : '',
        'abloesedate': name.includes('abloesedate') ? value : '',
        'restschuld': name.includes('restschuldVorhanden') ? value : '',
        'restschuldBetrag': name.includes('restschuldBetrag') ? value : '',
        'sparenBetrag': name.includes('sparenBetrag') ? value : '',
        'tilgungBetrag': name.includes('tilgungBetrag') ? value : '',
        'abloesen': name.includes('abloesen') ? value : '',
        'darlehenBetrag': name.includes('darlehenBetrag') ? value : '',
        'valid': name === 'state' ? value : false,
      })
    } else {
      let count = 0;
      status.map((darlehen: any) => {
        if (darlehen.id === this.count) {
          count++;
          switch (name) {
            case `darlehenType${this.count}`: {
              if(value === 'BESTEHENDES_IMMOBILIENDARLEHEN') {
                darlehen = { ...darlehen, 'darlehenType': value, 'sparenBetrag': '' };
              } else {
                darlehen = { ...darlehen, 'darlehenType': value };
              }
              break
            }
            case `abloesedate${this.count}`: {
              darlehen = { ...darlehen, 'abloesedate': value };
              break
            }
            case `restschuldVorhanden${this.count}`: {
              darlehen = { ...darlehen, 'restschuld': value };
              break
            }
            case `restschuldBetrag${this.count}`: {
              darlehen = { ...darlehen, 'restschuldBetrag': value };
              break
            }
            case `sparenBetrag${this.count}`: {
              darlehen = { ...darlehen, 'sparenBetrag': value };
              break
            }
            case `tilgungBetrag${this.count}`: {
              darlehen = { ...darlehen, 'tilgungBetrag': value };
              break
            }
            case `abloesen${this.count}`: {
              darlehen = { ...darlehen, 'abloesen': value };
              break
            }
            case `darlehenBetrag${this.count}`: {
              darlehen = { ...darlehen, 'darlehenBetrag': value };
              break
            }
            case 'state': {
              darlehen = { ...darlehen, 'valid': value };
              break
            }
          }
        }
        darlehens.push(darlehen);
      });
      if (count === 0) {
        darlehens.push({
          'id': this.count,
          'darlehenType': name.includes('darlehenType') ? value : '',
          'abloesedate': name.includes('abloesedate') ? value : '',
          'restschuld': name.includes('restschuldVorhanden') ? value : '',
          'restschuldBetrag': name.includes('restschuldBetrag') ? value : '',
          'sparenBetrag': name.includes('sparenBetrag') ? value : '',
          'tilgungBetrag': name.includes('tilgungBetrag') ? value : '',
          'abloesen': name.includes('abloesen') ? value : '',
          'darlehenBetrag': name.includes('darlehenBetrag') ? value : '',
          'valid': name === 'state' ? value : false,
        });
      }
    }
    this.dataService.update(1, { darlehenForward: darlehens });
  }

  setValid() {
    const data = this.dataQuery.getEntity(1)?.['darlehenForward'];
    let warnings = 0;
    let errors = 0;
    if(data) {
      data.map((item: any) => {
        if(item.id === this.count) {
          if(item.darlehenType === '' || 
             item.abloesedate === '' ||
             item.restschuld === '' ||
             item.tilgungBetrag === '' ||
             item.abloesen === '') {
              warnings++
          }
          if(this.tilgungBetrag.error || this.darlehenBetrag.error) {
            errors++;
          }
          if (item.darlehenType === 'BESTEHENDES_BAUSPARDARLEHEN') {
            if(item.sparenBetrag === '') {
              warnings++
            }
            if(this.sparenBetrag.error) {
              errors++
            }
          }
          if (item.restschuld === 'restschuldVorhanden') {
            if(item.restschuldBetrag === '') {
              warnings++
            }
            if(this.restschuldBetrag.error) {
              errors++
            }
          }
        }
      });
    } else {
      warnings++;
    }

    this.containerState = errors > 0 ? 'error' : warnings > 0 ? 'warning' : 'success';
    this.storeValue( this.containerState === 'success' ? true : false, 'state');
    setTimeout(() => {
      this.state.emit({type: 'darlehen', state: this.containerState});
    }, 200)
  }

}
