<div class="da-bausteine-tile" [ngClass]="{ 'mobile' : screen.isMobileOrSmallTablet(), 'isSmallest' : screen.isSmallest() }">
    <lib-header-text
        label="{{'FINANCE_ASSEMBLY.BAUSTEINE.RATE.TITLE' | translate}}"
        info="true"
        (infoValue)="screen.openInfo(rateInfo)"
        size="20px"
        [convertMobile]="false"
    ></lib-header-text>
    <lib-input
        #rate
        class="da-tile-field"
        label=""
        name="rate{{num}}"
        [error]="false"
        errorText="{{'COMMON.ERRORS.FEE' | translate}}"
        placeholder="0,00"
        [value]="contentService.getNestedObject('darlehenBausteine', 'rate', num)"
        unit="€"
        [touched]="false"                               
        (inputValue)="validateInput($event, rate)"
    ></lib-input>
</div>

<ng-template #rateInfo let-modal>
    <app-info-sidebar [modal]="modal" header="{{'FINANCE_ASSEMBLY.BAUSTEINE.RATE.INFO_HEADER' | translate}}" text="{{'FINANCE_ASSEMBLY.BAUSTEINE.RATE.INFO_TEXT' | translate}}"></app-info-sidebar>
</ng-template>
